import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import classNames from "classnames";
import { SessionContext } from "../context/SessionContext";
import styles from "./Login.module.css";
import LoginForm from "./LoginForm";
import LoginLennox from "./LoginLennox";
import LoginContent from "./LoginContent";
import { dataLayerPush } from '../components/Global/helpers';
import BrandInformation from "./ICP/BrandInformation";
import CustomerBenefits from "./ICP/CustomerBenefits";
import GetStartedToday from "./ICP/GetStartedToday";
import SalesOptions from "./ICP/SalesOptions";

const Login = () => {
  const sessionContext = useContext(SessionContext);
  const { accountName } = sessionContext;
  const accountNameToLower = accountName?.toLowerCase();

  if (accountNameToLower === 'lennox') {
    return <LoginLennox accountName={accountName} />
  }

  dataLayerPush('Pageview', {
    pageTitle: "Home",
    pagePath: "/home"
  })

  return (
    <>
      <div className={styles.vendorLogin}>
        <div className={accountNameToLower === 'ge' ? styles.vendorLoginGE
          : accountNameToLower === 'rheem' ? styles.vendorLoginRheem
            : accountNameToLower === 'carrier' ? styles.vendorLoginCarrier
              : accountNameToLower === 'icp' ? styles.vendorLoginICP : styles.vendorLoginRheem}>
          {(accountNameToLower !== 'ge' && accountNameToLower !== 'icp') &&
            <Container className={styles.mainContainer}>
              <Col md="12" lg="12">
                <Col lg="4" md="5" className="p-0 pb-3 pt-2">
                  <LoginForm />
                </Col>
              </Col>
            </Container>
          }
          {accountNameToLower === 'ge' &&
            <>
              <Container fluid>
                <Container>
                  <Row>
                    <Col xl="5" lg="5" md="7" sm="7" xs="12" className="p-0 pb-3 pt-2">
                      <div className={styles.loginFormGE}>
                        <LoginForm />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </>
          }
          {accountNameToLower === 'icp' && (
            <Container fluid>
              <Row>
                <Col sm="12" md="7" lg="6" className={styles.loginRowContainer}>
                  <Row className={styles.loginRowSection}>
                    <Col sm="12" md="8" lg="7" xl={{ size: 6, offset: 3 }} className={styles.loginFormContainer}>
                      <LoginForm />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
      {accountNameToLower !== 'icp' && (
        <LoginContent />
      )}
      {accountNameToLower === "icp" && (
        <>
          <BrandInformation />
          <CustomerBenefits />
          <GetStartedToday />
          <SalesOptions />
        </>
      )}
    </>
  );
};

export { Login };
