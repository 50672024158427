import React, { useContext, useEffect, useState } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import { useClaimInvoiceStore } from "../lib/state";
import styles from "./Table.module.css";
import { AddRowButton } from "./AddRowButton";
import { Currency } from "../../shared/Currency";
import { SessionContext } from '../../../context/SessionContext';
import OrderInfoProvider from '../../../context/OrderInfoProvider';
import { CaptionComponent, CurrencyField, PriceInput, widthFormatter } from '../../../components/InvoiceTables/Formatters';
import { getHeadingName } from '../../../lib/util';
import { getFooterTaxName } from '../../../lib/util';
import { getDuplicateRepairs, getLaborErrorColumns, getPartsErrorColumns, getTripErrorColumns, getRefrigerantErrorColumns } from '../lib/InvoiceTableUtils';
import { DuplicateRepairModal } from '../../Modals/DuplicateRepairModal/DuplicateRepairModal';
import { AIGInverseAlert } from '../../../components/shared/Alerts/AIGInverseAlert';
import classNames from 'classnames';

//Function to format the exceeding tax errors with 'and' in between when multiple types are returned 
function createString(elements) {
    const strings = elements.filter(Boolean);
    if (strings.length === 0) return '';
    if (strings.length === 1) {
        return strings[0];
    } else if (strings.length === 2) {
        return strings.join(' and ');
    } else {
        const lastElement = strings.pop();
        return `${strings.join(', ')} and ${lastElement}`;
    }
}


export const InvoiceController = ({ inValidationMode, isViewOnly, tableObjects, claimData }) => {

    console.log("claimData", claimData)
    const { tabDispatch, getCurrentTabAndSubtab } = useContext(SessionContext)
    const { getProfile } = useContext(SessionContext)
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const { contractNumber } = getCurrentTabAndSubtab().tab;
    const currentInvoice = useClaimInvoiceStore((state) => state[invoiceId]);
    const { showRepairError } = currentInvoice?.['labor']?.data?.length > 0 ? currentInvoice?.['labor']?.data?.[0] : false;
    const { showMarkupError } = currentInvoice?.['partsMarkup']?.data?.length > 0 ? currentInvoice?.['partsMarkup']?.data?.[0] : false;
    const contract = JSON.parse(sessionStorage.getItem(contractNumber));
    const [errorCount, setErrorCount] = useState(0);
    const [isOpen, setIsOpen] = useState(true);
    const repairSelected = currentInvoice?.['labor']?.data?.[0]?.repair?.selected ?? [];
    const allRepairs = (currentInvoice?.['labor']?.data || currentInvoice?.['laborSelectRate']?.data) &&
        (currentInvoice?.['labor']?.data.length || currentInvoice?.['laborSelectRate']?.data.length) > 0 &&
        (currentInvoice?.['labor'] || currentInvoice?.['laborSelectRate'])?.data?.reduce((acc, e) => {
            acc = [...acc, ...e.repair.selected];
            return acc;
        }, [])
    const isUnitSelected = allRepairs && allRepairs?.some(e => e.display === 'Unit Replaced');
    const { isDuplicateSelected } = allRepairs?.length > 0 ? getDuplicateRepairs(allRepairs) : false;

    const closeModel = () => {
        setIsOpen(false);
    };

    const allDataCombined = Object.entries(currentInvoice || [])?.reduce((acc, e) => {
        acc = [...acc, ...e?.[1]?.data]
        return acc;
    }, []);

    const allTotal = (allDataCombined || []).reduce((acc, lineItem) => acc + parseFloat(lineItem?.total || 0), 0);
    const staticTotal = claimData?.invoiceTotal;

    const invoiceDataTobeUsed = isViewOnly ? tableObjects : currentInvoice;
    let tableData = Object.entries(invoiceDataTobeUsed || []);
    const refrigerantTable = tableData.filter(invoice => invoice[0] === 'refrigerant');

    //Add Refrigerant Table if exists
    if (refrigerantTable.length && !isViewOnly) {
        const partsTableIndex = tableData.findIndex(invoice => invoice[0].includes("part"));
        const refrigerantIndex = partsTableIndex === -1 ? 1 : partsTableIndex + 1;
        tableData.splice(refrigerantIndex, 0, refrigerantTable[0]);
        tableData.pop();
    }


    return (
        <OrderInfoProvider
            dealerProfile={getProfile()}>
            {invoiceDataTobeUsed &&
                <div className={styles.tableWrapperContainer}>
                    {tableData?.map((e) => {
                        return <InvoiceTable isViewOnly={isViewOnly} tableObjectData={tableObjects} currentInvoice={currentInvoice} setErrorCount={setErrorCount} invoiceId={invoiceId} tableType={e[0]} inValidationMode={inValidationMode} contract={contract} />;
                    })}
                    <div className={styles.footerRow}>
                        <div className={styles.footerTotalContainer}>
                            <span className={styles.totalLable}><b>Invoice Total</b></span>
                            &nbsp;
                            <div className={styles.total}><Currency value={allTotal || staticTotal} /></div>
                        </div>
                    </div>

                    {errorCount > 0 && <p className="errorMsg mt-4 mb-1">The highlighted fields above are required.</p>}
                </div>
            }
        </OrderInfoProvider>
    );
};

// Wrapping each table in its own component allows us to access state
// for JUST that table specifically, while performing the map in the parent.

const InvoiceTable = ({ invoiceId, tableType, inValidationMode, contract, setErrorCount, isViewOnly, tableObjectData, currentInvoice }) => {
    const state = useClaimInvoiceStore((state) => {
        return state;
    });

    const { getProfile } = useContext(SessionContext)
    const reclaimNAAccounts = [18, 201, 202]; //for Lennox,GE reclaim not available
    const accountId = getProfile()?.accountId;

    const { columns, data } = isViewOnly ? tableObjectData[tableType] : currentInvoice[tableType];
    const { tabDispatch, getCurrentTabAndSubtab } = useContext(SessionContext)

    let customColumns = columns;
    if (tableType === 'labor' || tableType === 'laborSelectRate') {
        const { cols } = getLaborErrorColumns(columns, inValidationMode);
        customColumns = cols;
    }

    if (tableType === 'parts' || tableType === 'partsMarkup') {

        const { cols } = getPartsErrorColumns(columns, inValidationMode);
        customColumns = cols;
    }

    if (tableType === 'trip') {
        const { cols } = getTripErrorColumns(columns, inValidationMode);
        customColumns = cols;
    }

    if (tableType === 'refrigerant') {
        const { cols } = getRefrigerantErrorColumns(columns, inValidationMode);
        customColumns = cols;
    }

    useEffect(() => {
        const count = document.querySelectorAll('[title="error-col"]');
        setErrorCount(count?.length)
    }, [document, customColumns])

    const total = (data || []).reduce((acc, lineItem) => acc + parseFloat(lineItem.total), 0);
    const totalPrice = (data || []).reduce((acc, lineItem) => acc + parseFloat(lineItem.price), 0);
    const totalMarkup = (data || []).reduce((acc, lineItem) => acc + parseFloat(lineItem.markup), 0);

    const laborTotal = (data || []).reduce((acc, val) => acc + (parseFloat(val.rate) * parseFloat(val.hours)), 0);
    const taxError = state.getTaxError();
    const currentTaxErrorTable = state.getCurrentTaxErrorTable();
    const allRepairs = (currentInvoice?.['labor']?.data || currentInvoice?.['laborSelectRate']?.data) &&
        (currentInvoice?.['labor']?.data.length || currentInvoice?.['laborSelectRate']?.data.length) > 0 &&
        (currentInvoice?.['labor'] || currentInvoice?.['laborSelectRate'])?.data?.reduce((acc, e) => {
            acc = [...acc, ...e.repair.selected];
            return acc;
        }, [])
    const { isDuplicateSelected } = allRepairs?.length > 0 ? getDuplicateRepairs(allRepairs) : false;
    state.setIsDataDuplicateExists(isDuplicateSelected)
    const showRepairError = currentInvoice?.['labor']?.data?.length > 0 ? currentInvoice?.['labor']?.data?.some(e => e.showRepairError) : false;
    const repairNonCovered = currentInvoice?.['labor']?.data?.length > 0 ? currentInvoice?.['labor']?.data?.some(e => e.repairNonCovered) : false;
    const showDuplicateRepairError = 
        currentInvoice?.['labor']?.data?.length > 0 ? currentInvoice?.['labor']?.data?.some(e => e.showDuplicateRepairError) :
        currentInvoice?.['laborSelectRate']?.data?.length > 0 ? currentInvoice?.['laborSelectRate']?.data?.some(e => e.showDuplicateRepairError) : false;
   
    state.setIsRepairErrorExists(showRepairError)
    state.setIsNCRepairErrorExists(repairNonCovered)

    const modifyTaxTableName = (tableType) => {
        switch (tableType?.toLowerCase()) {
            case 'laborselectrate':
            case 'labor':
                return ' labor';
            case 'partsallowance':
            case 'partsmarkup':
            case 'parts':
                return ' parts';
            case 'refrigerant':
                return ' refrigerant';
            case 'trip':
                return ' trip';
            default:
                return tableType
        }
    }
    const handleOnViewRepairHours = (e) => {
        e.preventDefault()
        tabDispatch('ADD_TAB', {
            title: "Resources",
            tabType: "resources",
            activeResourceTab: ({ data: 'claims', display: "Claims" })
        });
    }

    const handleOnViewPlanOptions = (e) => {
        e.preventDefault()
        tabDispatch('ADD_TAB', {
            title: "Resources",
            tabType: "resources",
            activeSubtab: 3,
            activeResourceTab: ({ data: 'salesAids', display: "Sales Aids" })
        });
    }

    const showTaxError = taxError?.some(e => e.error);
    const errorString = createString(taxError?.filter(e => e.error)?.map(e => modifyTaxTableName(e.name)));
    const taxString = taxError?.filter(e => e.error).length > 1 ? 'totals' : 'total';
    const showMarkupError = currentInvoice?.['partsMarkup']?.data?.length > 0 ? currentInvoice?.['partsMarkup']?.data?.some(e => e.showMarkupError) : false;
    const showMaxPriceError = currentInvoice?.['refrigerant']?.data?.length > 0 ? currentInvoice?.['refrigerant']?.data?.some(e => e.showMaxPriceError) : false;
    state.setIsMarkupErrorExists(showMarkupError)
    state.setMaxPriceErrorExists(showMaxPriceError)

    return (
        <div className={styles.tableWrapper}>
            <BootstrapTable
                bootstrap4
                condensed
                columns={customColumns}
                data={Array.isArray(data) ? data : []}
                keyField={`${invoiceId}_${tableType}`}
                classes={[
                    'mb-0',
                    styles.w100,
                    styles.CustomTable,
                    styles.topTable,
                    styles.removeBottomBorder,
                    `${isViewOnly ? styles.disableClickActions : ''}`
                ]}
                caption={<>
                    <CaptionComponent title={getHeadingName(tableType)} />
                    {showTaxError && tableType === 'tax' &&
                        <AIGInverseAlert
                            color="warning"
                            reason={`The following tax totals cannot exceed 15% of their section total: ${errorString}.`}
                        />}
                    {showDuplicateRepairError && (tableType === 'labor' || tableType === 'laborSelectRate') &&
                        <AIGInverseAlert
                            color="warning"
                            reason='The repair selected has already been added to the claim. Please update the repair or remove the line item.'
                        />}
                    {showRepairError && (tableType === 'labor') &&
                        <AIGInverseAlert
                            color="warning"
                            reason={<>The number of hours entered exceeds the allowable repair hours for this failure as outlined in the {<a className={classNames(styles.linkStyle)} onMouseDown={(e) => handleOnViewRepairHours(e)}>Repair Hours</a>} document.</>}
                        />}
                    {repairNonCovered && (tableType === 'labor') &&
                        <AIGInverseAlert
                            color="warning"
                        reason={<>The selected repair is not covered. Please remove the repair or set the hours to 0.</>}
                        />}
                    {showMarkupError && (tableType.toLowerCase() === 'partsmarkup') &&
                        <AIGInverseAlert
                            color="warning"
                            reason={<>The markup entered exceeds the allowable amount based on the markup percentage to part price. Please reference the {<a className={classNames(styles.linkStyle)} onMouseDown={(e) => handleOnViewPlanOptions(e)}>Plan Options</a>} resource for details.</>}
                        />}
                    {showMaxPriceError && (tableType.toLowerCase() === 'refrigerant') &&
                        <AIGInverseAlert
                            color="warning"
                            reason={<>The price entered exceeds the max amount allowed for this refrigerant type.</>}
                        />}
                </>}
                footerClass={styles.tableFooter}
                wrapperClasses={[styles.AdjudicationTableContainer]}
            />
            {/* THE FOLLOWING WOULD BE THE FOOTER OF THE TABLE */}
            {!(tableType === 'partsAllowance' || tableType === "tax") &&
                <div className={styles.footerRow}>
                    <AddRowButton
                        tableType={tableType}
                        invoiceId={invoiceId}
                        contract={contract}
                        hideRow={
                            tableType === 'refrigerant' && 
                            (currentInvoice?.['refrigerant']?.data?.length === 2 || reclaimNAAccounts.includes(accountId))
                        }
                    />
                    <div className={styles.footerTotalContainer}>
                        <span className={styles.totalLable}><b>{getFooterTaxName(tableType)} Total </b></span>
                        &nbsp;
                        {(tableType === 'parts' || tableType?.toLowerCase() === 'partsmarkup') && <div className={isViewOnly ? styles.totalStaticPrice : styles.totalPrice}><Currency value={totalPrice} /></div>}
                        {(tableType?.toLowerCase() === 'partsmarkup') && <div className={isViewOnly ? styles.totalStaticPrice : styles.totalPrice}><Currency value={totalMarkup} /></div>}
                        <div className={isViewOnly ? styles.totalStatic : styles.total}><Currency value={parseFloat(total)} /></div>
                    </div>
                </div>
            }

            {tableType === "tax" &&
                <div className={styles.footerRow}>
                    <div className={styles.footerTotalContainer}>
                        <span className={styles.totalLable}><b>Tax Total </b></span>
                        &nbsp;
                        <div className={styles.total}><Currency value={parseFloat(total).toFixed(2)} /></div>
                    </div>
                </div>
            }
        </div>
    );
};
