import React from "react";
import styles from './DealerHome.module.css';
import {  CardBody } from "reactstrap";
import { ReactComponent as LinkIcon } from '../../assets/images/box-arrow-out.svg';

export default function LowerCard(props) {
  const {cardTitle = "", description="", children, bgColor, isManageClaims} = props;
  return (
    <CardBody type="button" className={styles.lowerCard}>
      {isManageClaims && <a className={styles.linkIcon}><LinkIcon /></a>}
      <div className={styles.lowerCardtitle}>
        <div className={styles.stickDesign} style={{ backgroundColor: `${bgColor}`}}/>
        {children}
        <h3>{cardTitle}</h3>
      </div>
      <p className={isManageClaims ? styles.heightAdjust: ""}>{description}</p>
    </CardBody>
  );
};