import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { AIGStatusBadge } from '../../shared/AIGStatusBadge';
import {ReactComponent as FacelessAvatar} from '../../../assets/images/profile.svg';
import styles from './DealerHeader.module.css';
import AdvantageEnrollment from './AdvatageEnrollment/AdvantageEnrollment'

const DealerHeader = (props) => {
    PropTypes.checkPropTypes(DealerHeader.propTypes, props, 'props', 'DealerHeader')
    const {
        dealerName,
        dbaName, 
        dealerStatus, 
        advantageStatus, 
        dealerId,
        displayAdvantageStatus,
        programName
    } = props
    return (
    <Card className='card'>
        <CardBody className='card-body'>
            <Row className={styles.headerRow}>
                <Col xs='12' md='4'>
                    <div className={styles.flex}>
                        <FacelessAvatar className={styles.avatar}/>
                        <div className={styles.columnFlex}>
                                <h1 className={`h4 m-0 ${styles.companyName}`}>{dealerName}</h1>
                                {typeof dealerName === 'string' && typeof dbaName === 'string' &&
                                    dealerName.toUpperCase() != dbaName.toUpperCase() &&
                                    <h4 className={styles.companyName}>{dbaName}</h4>
                                }
                            <h4 style={{margin: 0}}>
                                <AIGStatusBadge statusId={dealerStatus} removeMargin/>
                            </h4>
                        </div>
                    </div>
                </Col>

                {/* (VENDOR === RHEEM | CARRIER | LENNOX) && DISPLAY THIS COLUMN */}
                {displayAdvantageStatus &&

                    <Col xs='12' md='4' className={styles.flex}>
                        <div>
                            <AdvantageEnrollment programName={programName} advantageStatus={advantageStatus} />
                        </div>
                    </Col>
                }
                
                <Col xs="12" md='4' className={styles.flex}>
                    <div className={styles.dealerId}>
                        <h3 className={`h6 ${styles.smallHeading}`}>Dealer ID:</h3>
                        <span>{dealerId}</span>
                    </div>
                </Col>
            </Row>
        </CardBody>
    </Card>
)}

DealerHeader.propTypes = {
    dealerName: PropTypes.string.isRequired,
    dbaName: PropTypes.string.isRequired,
    dealerStatus: PropTypes.number.isRequired,
    advantageStatus: PropTypes.oneOf([
        'ENROLLED_ACTIVE', 
        'ENROLLED_PENDING', 
        'ENROLLED_NO_CAMPAIGN',
        'UNENROLLED',
    ]).isRequired,
    dealerId: PropTypes.string.isRequired,
}

export default DealerHeader