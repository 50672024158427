import React, { Component } from 'react';
import { SessionContext } from '../../context/SessionContext';
import { PlanSearch } from '../PlanSearch/PlanSearch';
import {dataLayerPush} from '../../components/Global/helpers'

var comKey = "";

export class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contractNumber: '',
            contracts: [],
            tabId: this.props.tabId
        }
        console.log(this.props.tabId);
        comKey = "comState".concat(this.props.tabId);
        console.log(comKey);
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            sessionStorage.setItem(comKey, JSON.stringify(this.state));
            console.log(sessionStorage.getItem(comKey));
        });
    }

    getComState() {
        return JSON.parse(sessionStorage.getItem(comKey)) || [];
    }

    UNSAFE_componentWillMount() {
        dataLayerPush('Pageview', {
            pageTitle: "Search",
            pagePath: "/search"
        })
        const rememberedState = JSON.parse(sessionStorage.getItem(comKey)) || {};
        this.setState(rememberedState);
        console.log(comKey);
        console.log(this.state);
    }

    componentDidUpdate() {
        sessionStorage.setItem(this.comKey, JSON.stringify(this.state));
        console.log(this.getComState());
    }

    render() {
        return ( 
            <div
                aria-labelledby="search-tab">
                <div className="row secondary-nav-wrapper">
                    <PlanSearch history={this.props.history} tabId={this.props.tabId}/>
                </div>
            </div>
        );
    }
}

Search.contextType = SessionContext