export const PaymentOptionType = {
    saved: 1,
    preferred: 2,
    advantagePreferred: 3
}

export const PaymentEntityType = {
    NA: 0,
    Contract: 1,
    Claim: 2
}

export const PaymentSource = {
    NA: 0,
    PaymentGateway: 1,
    SalesPath: 2,
    AdvantagePage: 3
}