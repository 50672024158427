import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import {CampaignDetails} from './CampaignDetails';
import styles from "./ViewCampaign.module.css";
import SpinnerButton from "../shared/SpinnerButton/SpinnerButton";

export const CreateCampaignModal = ({ isOpen, toggle, centered, selectedRow, tableData, updateTableData,
    handleCreateUpdate, blnPencil, updateRow, updatedRowData, isCanada, errBeginDate,
    errRnc, errLabor, errCoverage, errTerm, updateErrors, blnCopy, showTermsText, updateShowTermsText,
    advantageName, updateEndDateChecker, errEndDate, updateBeginDateErr, possibleBeginDate, accountId, saveCampaign }) => {

  return(
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      modalClassName={styles.createCampaignModal}
      contentClassName={styles.createCampaignModalContent}
      centered={centered}
      size="md"
    >
      <div className={styles.modalHeader}>
        <h1 className={"h4 mb-0"}>{advantageName} Campaign</h1>
      </div>

      <ModalBody>      
        <CampaignDetails
            selectedRow={selectedRow}
            tableData={tableData}
            updateTableData={updateTableData}
            blnPencil={blnPencil}
            updateRow={updateRow}
            updatedRowData={updatedRowData}
            isCanada={isCanada}
            errBeginDate={errBeginDate}
            errRnc={errRnc}
            errLabor={errLabor}
            errCoverage={errCoverage}
            errTerm={errTerm}
            updateErrors={updateErrors}
            blnCopy={blnCopy}
            showTermsText={showTermsText}
            updateShowTermsText={updateShowTermsText}
            updateEndDateChecker={updateEndDateChecker}
            errEndDate={errEndDate}
            updateBeginDateErr={updateBeginDateErr}
            possibleBeginDate={possibleBeginDate}
            accountId={accountId}
        />
      </ModalBody>

      <ModalFooter>
        <div className="btnWrapper">
          <button className="btnLink" onClick={toggle}>Cancel</button>
          <SpinnerButton
            onClick={handleCreateUpdate}
            disabled={saveCampaign}
            spinning={saveCampaign}
            styleType="primary"
            className="btn-primary"
            text={"Continue"}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}