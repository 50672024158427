import React, { useState, forwardRef, useEffect, useImperativeHandle, useRef, useContext } from 'react';
import { CardBody, Row, Col } from 'reactstrap';
import { AdvantageCampaigns } from '../AdvantageCampaigns/AdvantageCampaigns';
import { getBrandContent } from "../BrandingWhiteLabel/BrandContent";
import { SessionContext } from "../../context/SessionContext";

const advSessionStorage = "advSessionStorage";
const countries = [{ display: 'USA', data: 1 }, { display: 'Canada', data: 2 }]
const AdvantageCampaign = forwardRef((props, ref) => {
  const { validator, errors, toggleContinue } = props;
  const [forceUpdate, setForceUpdate] = useState(0);

  const childRef = useRef();

  const { accountName } = useContext(SessionContext);
  let advantageProgramName = getBrandContent(accountName).advantageProgramName;
  let vendorDetails = JSON.parse(sessionStorage.getItem("vendorDetails"));

  useEffect(() => {
    if (errors) {
      if (Object.keys(errors).length > 0) {
        validator.showMessages();
        setForceUpdate(1)
      }
    }
  }, [errors, validator]);

  useImperativeHandle(ref, () => ({
    validateInputs() {
      return childRef.current.validationCheck();
    }
  }));

  const updateAdvSessionStorage = (stateValue) => {
    sessionStorage.setItem(advSessionStorage, JSON.stringify({ stateValue }));
  }

  const handleToggleContinue = (toggle, src) => {
    toggleContinue(toggle, src);
  }


  return (
    <>
      <Col xs="12" md="12" className={`titleRow p-4`}>
        <h2 className="h4 m-0">{advantageProgramName} Enrollment</h2>
      </Col>
      <hr className="m-0 w-100" />
      <CardBody className="pt-0 pl-2 pb-0">
        <Row className={'multiColWrapper relative'}>
          <AdvantageCampaigns
            advantageName={advantageProgramName}
            accountId={props.accountId === 0 ? 99 : props.accountId}
            isVendorCreation={true}
            ref={childRef}
            termsAndConditionsPath={true}
            profile={{ isLennoxDealer: true }}
            updateAdvSessionStorage={updateAdvSessionStorage}
            toggleContinue={handleToggleContinue}
            country={vendorDetails?.country === 2 ? 'Canada' : 'US'}
          />
        </Row>
      </CardBody>
    </>
  );
});

export default AdvantageCampaign;