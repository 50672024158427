import React, { Component } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { SessionContext } from "../../context/SessionContext";
import classNames from "classnames";
import { getBrandContent } from "../BrandingWhiteLabel/BrandContent";
import styles from "./ErrorPasswordExceeded.module.css";
import BrandedIcon from './../BrandingWhiteLabel/BrandedIcon';


export default class ErrorPasswordExceeded extends Component {

	render() {

		return (
			<SessionContext.Consumer>
				{session =>
					<Container className="contentWrapper">
						<Card>
							<CardBody>
								<div className={classNames(styles.Container, "py-5")}>
									<span className={styles.profileIcon}>
										<BrandedIcon
											accountName={session.accountName.toLowerCase()}
											iconType={'profilealert'}
										/>
									</span>
									<h1 className="h1 py-2">We couldn't log you in.</h1>
									<p className={styles.paraWrapper}>
										We are unable to log you in due to an authentication error.
									</p>
									<p>
										For help, contact Dealer Support at {getBrandContent(session.accountName).programPhone}.
									</p>
								</div>
							</CardBody>
						</Card>
					</Container>
				}
			</SessionContext.Consumer>
		);
	}
}
ErrorPasswordExceeded.contextType = SessionContext;