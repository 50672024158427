//Calculation for different coverage types 
export const calculatePartsMarkup = (tempRow, coverageType) => {
    const { qty: q, price: p, oem, markup } = tempRow;
    const qty = q ?? 0;
    const price = p ?? 0;
    if (oem) return 0;
    if ((coverageType === "10" || coverageType === "29")) {
        if (oem) {
            return 0;
        } else {
            return parseFloat(markup);
        }
    }
    if (coverageType === "19" || coverageType === "26" || coverageType === "27") {
        const markTotal = parseFloat(qty) * (parseFloat(price) + parseFloat(markup));
        if (markup) {
            if (oem) {
                return 0;
            } else {
                return markTotal
            }
        }
        const preTotal = parseFloat(qty) * parseFloat(price);
        if (oem) {
            return 0;
        } else {
            return preTotal;
        }
    }
};
