import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import TagManager from 'react-gtm-module'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const gtmSetup = async () => {
    let gtmArgs

    await fetch('helper/getenvironment', {
        method: 'get',
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then(res => res.json())
        .then(envRes => {

            // console.log('=== gtmenv ===', envRes.environment)
            const gtmId = 'GTM-MG6ZPGJ';
            switch(envRes.environment.toLowerCase()) {
                case 'development':
                    gtmArgs = {
                        gtmId,
                        auth: 'NkXAD1o3ar-aRbPMzjjqAA',
                        preview: 'env-3'
                    }
                    break
                case 'staging':
                    gtmArgs = {
                        gtmId,
                        auth: 'IC123xvi-3kfjxqu6nH4rQ',
                        preview: 'env-4'
                    }
                    break
                case 'production':
                    gtmArgs = {
                        gtmId,
                        auth: '0WxdulMdU9bdQTRCl_ug7g',
                        preview: 'env-1'
                    }
                    break;
                case 'local': 
                default: 
                    gtmArgs = {
                        gtmId,
                        auth: null,
                        preview: null
                    } 
            }
        })
    // console.log('=== gtmArgs ===', gtmArgs)
    TagManager.initialize(gtmArgs)
}

gtmSetup()


ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>,
    rootElement,
)

