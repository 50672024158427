import { useEffect, useState } from 'react';
import { accounts } from './BrandStyles';
import { usingAIGBranding } from '../../components/BrandingWhiteLabel/WhiteLabelProvider';

export const useWhiteLabel = (account) => {
  const [brandingComplete, setBrandingComplete] = useState(false);

  useEffect(() => {
    if (usingAIGBranding.includes(account)) {
      setBrandingComplete(true);
      return;
    };

    const root = document.getElementsByTagName("html")[0];

    // deconstruct all values from selected brand
    const { colors, type, h, btn, input, miscInput, table, tabs, sideNav, mainNav, scriptBox } = accounts[account];

    // Primary Branding Values
    if (colors) {
      colors.primaryBrand && root.style.setProperty("--PrimaryBrandColor", colors.primaryBrand);
      colors.secondaryBrand && root.style.setProperty("--SecondaryBrandColor", colors.secondaryBrand);
      colors.primaryText && root.style.setProperty("--PrimaryTextColor", colors.primaryText);
      colors.error && root.style.setProperty("--ErrorColor", colors.error);
      colors.focus && root.style.setProperty("--FocusColor", colors.focus);
      colors.advantageAccent && root.style.setProperty("--AdvantageAccentColor", colors.advantageAccent);
      colors.advantageText && root.style.setProperty("--Advantage__TextColor", colors.advantageText);
      colors.neutral1 && root.style.setProperty("--Color__Neutral1", colors.neutral1);
      colors.neutral2 && root.style.setProperty("--Color__Neutral2", colors.neutral2);
      colors.neutral3 && root.style.setProperty("--Color__Neutral3", colors.neutral3);
      colors.neutral4 && root.style.setProperty("--Color__Neutral4", colors.neutral4);
      colors.neutral5 && root.style.setProperty("--Color__Neutral5", colors.neutral5);
      colors.accent1 && root.style.setProperty("--Color__Accent1", colors.accent1);
      colors.accent2 && root.style.setProperty("--Color__Accent2", colors.accent2);
      colors.accent3 && root.style.setProperty("--Color__Accent3", colors.accent3);
      colors.accent4 && root.style.setProperty("--Color__Accent4", colors.accent4);
    }
    // Typography
    if (type) {
      type.textFont && root.style.setProperty("--TextFont", type.textFont);
      type.fontSize && root.style.setProperty("--FontSize", type.fontSize);
      type.lineHeight && root.style.setProperty("--LineHeight", type.lineHeight);
      type.linkColor && root.style.setProperty("--LinkColor", type.linkColor);
      type.iconColor && root.style.setProperty("--IconColor", type.iconColor);
      type.letterSpacing && root.style.setProperty("--LetterSpacing", type.letterSpacing);
      type.iconColorYes && root.style.setProperty("--IconColorYes", type.iconColorYes);
      type.iconColorNo && root.style.setProperty("--IconColorNo", type.iconColorNo);
    }
    // Headings
    if (h) {
      h.headingFont && root.style.setProperty("--Heading__Font", h.headingFont);
      h.headingFontWeight && root.style.setProperty("--Heading__FontWeight", h.headingFontWeight);
      h.headingColor && root.style.setProperty("--Heading__Color", h.headingColor);
      h.h1Size && root.style.setProperty("--H1__Size", h.h1Size);
      h.h1LineHeight && root.style.setProperty("--H1__LineHeight", h.h1LineHeight);
      h.h2Size && root.style.setProperty("--H2__Size", h.h2Size);
      h.h2LineHeight && root.style.setProperty("--H2__LineHeight", h.h2LineHeight);
      h.h3Size && root.style.setProperty("--H3__Size", h.h3Size);
      h.h3LineHeight && root.style.setProperty("--H3__LineHeight", h.h3LineHeight);
      h.h4Size && root.style.setProperty("--H4__Size", h.h4Size);
      h.h4LineHeight && root.style.setProperty("--H4__LineHeight", h.h4LineHeight);
      h.h5Size && root.style.setProperty("--H5__Size", h.h5Size);
      h.h5LineHeight && root.style.setProperty("--H5__LineHeight", h.h5LineHeight);
      h.h5Weight && root.style.setProperty("--H5__Weight", h.h5Weight);
      h.h6Size && root.style.setProperty("--H6__Size", h.h6Size);
      h.h6LineHeight && root.style.setProperty("--H6__LineHeight", h.h6LineHeight);
      h.h6Weight && root.style.setProperty("--H6__Weight", h.h6Weight);
    }
    // Buttons
    if (btn) {
      btn.borderRadius && root.style.setProperty("--Btn__BorderRadius", btn.borderRadius);
      btn.padding && root.style.setProperty("--Btn__Padding", btn.padding);
      btn.andInputFontSize && root.style.setProperty("--BtnAndInput__FontSize", btn.andInputFontSize);
      btn.lineHeight && root.style.setProperty("--Btn__LineHeight", btn.lineHeight);
      btn.primaryBg && root.style.setProperty("--BtnPrimary__Bg", btn.primaryBg);
      btn.primaryText && root.style.setProperty("--BtnPrimary__Text", btn.primaryText);
      btn.primaryBorder && root.style.setProperty("--BtnPrimary__Border", btn.primaryBorder);
      btn.primaryHoverBg && root.style.setProperty("--BtnPrimary__HoverBg", btn.primaryHoverBg);
      btn.primaryHoverBorder && root.style.setProperty("--BtnPrimary__HoverBorder", btn.primaryHoverBorder);
      btn.primaryHoverText && root.style.setProperty("--BtnPrimary__HoverText", btn.primaryHoverText);
      btn.secondaryBg && root.style.setProperty("--BtnSecondary__Bg", btn.secondaryBg);
      btn.secondaryText && root.style.setProperty("--BtnSecondary__Text", btn.secondaryText);
      btn.secondaryBorder && root.style.setProperty("--BtnSecondary__Border", btn.secondaryBorder);
      btn.secondaryHoverBg && root.style.setProperty("--BtnSecondary__HoverBg", btn.secondaryHoverBg);
      btn.secondaryHoverBorder && root.style.setProperty("--BtnSecondary__HoverBorder", btn.secondaryHoverBorder);
      btn.secondaryHoverText && root.style.setProperty("--BtnSecondary__HoverText", btn.secondaryHoverText);
      btn.linkText && root.style.setProperty("--BtnLink__Text", btn.linkText);
      btn.disabledBg && root.style.setProperty("--BtnDisabled__Bg", btn.disabledBg);
      btn.disabledText && root.style.setProperty("--BtnDisabled__Text", btn.disabledText);
      btn.disabledBorder && root.style.setProperty("--BtnDisabled__Border", btn.disabledBorder);
    }
    // Input Values
    if (input) {
      input.labelColor && root.style.setProperty("--Input__LabelColor", input.labelColor);
      input.labelLineHeight && root.style.setProperty("--Input__LabelLineHeinputLabelLineHeight", input.labelLineHeight);
      input.labelMarginBottom && root.style.setProperty("--Input__LabelMarginBottom", input.labelMarginBottom);
      input.labelWeight && root.style.setProperty("--Input__LabelWeightinputLabelWeight", input.labelWeight);
      input.placeholder && root.style.setProperty("--Input__Placeholder", input.placeholder);
      input.requiredAsterisk && root.style.setProperty("--Input__RequiredAsterisk", input.requiredAsterisk);
      input.lineHeight && root.style.setProperty("--Input__LininputLineHeight", input.lineHeight);
      input.borderRadius && root.style.setProperty("--Input__BorderRadius", input.borderRadius);
      input.border && root.style.setProperty("--Input__Border", input.border);
      input.entered && root.style.setProperty("--Input__Entered", input.entered);
      input.focusBorder && root.style.setProperty("--InputFocus__Border", input.focusBorder);
      input.errorColor && root.style.setProperty("--InputError__Color", input.errorColor);
      input.errorBg && root.style.setProperty("--InputError__Bg", input.errorBg);
      input.disabledBg && root.style.setProperty("--InputDisabled__Bg", input.disabledBg);
      input.disabledText && root.style.setProperty("--InputDisabled__Text", input.disabledText);
      input.disabledBorder && root.style.setProperty("--InputDisabled__Border", input.disabledBorder);
      input.icon && root.style.setProperty("--Input__Icon", input.icon);
      input.iconHeight && root.style.setProperty("--Input__IconHeight", input.iconHeight);
      input.iconWidth && root.style.setProperty("--Input__IconWidth", input.iconWidth);
    }
    // Checkboxes
    if (miscInput) {
      miscInput.toggleColor && root.style.setProperty("--InputToggle__Color", miscInput.toggleColor);
      miscInput.checkboxBorder && root.style.setProperty("--Checkbox__Border", miscInput.checkboxBorder);
      miscInput.checkboxHeight && root.style.setProperty("--Checkbox__Height", miscInput.checkboxHeight);
      miscInput.checkboxWidth && root.style.setProperty("--Checkbox__Width", miscInput.checkboxWidth);
      miscInput.checkboxCheckedBg && root.style.setProperty("--Checkbox__CheckedBg", miscInput.checkboxCheckedBg);
      miscInput.checkboxCheckColor && root.style.setProperty("--Checkbox__CheckColor", miscInput.checkboxCheckColor);
      // Drobdowns
      miscInput.dropdownArrowColor && root.style.setProperty("--Drop__ArrowColor", miscInput.dropdownArrowColor);
      miscInput.dropdownOptionHoverBg && root.style.setProperty("--DropOption__HoverBg", miscInput.dropdownOptionHoverBg);
      // Radios
      miscInput.radioButtonBorder && root.style.setProperty("--RadioButton__Border", miscInput.radioButtonBorder);
      miscInput.radioButtonBg && root.style.setProperty("--RadioButton__Bg", miscInput.radioButtonBg);
      miscInput.radioButtonCheckedBorder && root.style.setProperty("--RadioButton__CheckedBorder", miscInput.radioButtonCheckedBorder);
      miscInput.radioButtonCheckedBg && root.style.setProperty("--RadioButton__CheckedBg", miscInput.radioButtonCheckedBg);
      // Radios with Background
      miscInput.radioWithBgBorder && root.style.setProperty("--RadioWithBg__Border", miscInput.radioWithBgBorder);
      miscInput.radioWithBgBg && root.style.setProperty("--RadioWithBg__Bg", miscInput.radioWithBgBg);
      miscInput.radioWithBgText && root.style.setProperty("--RadioWithBg__Text", miscInput.radioWithBgText);
      miscInput.radioWithBgCheckedBorder && root.style.setProperty("--RadioWithBg__CheckedBorder", miscInput.radioWithBgCheckedBorder);
      miscInput.radioWithBgCheckedBg && root.style.setProperty("--RadioWithBg__CheckedBg", miscInput.radioWithBgCheckedBg);
      miscInput.radioWithBgCheckedText && root.style.setProperty("--RadioWithBg__CheckedText", miscInput.radioWithBgCheckedText);

      miscInput.activeSideTabBg && root.style.setProperty("--ResourceSideTab__ActiveBg", miscInput.activeSideTabBg);
      miscInput.activeSideTabBorder && root.style.setProperty("--ResourceSideTab__ActiveBorder", miscInput.activeSideTabBorder);
    }
    // Table
    if (table) {
      table.rowBg && root.style.setProperty("--Table__RowBg", table.rowBg);
    }
    if (tabs) {
      tabs.activeTabColor && root.style.setProperty("--ActiveTab", tabs.activeTabColor);
      tabs.activeTabText && root.style.setProperty("--ActiveTab__Text", tabs.activeTabText);
    }
    // Side Navigation Bar
    if (sideNav) {
      sideNav.bg && root.style.setProperty("--SideNav__Bg", sideNav.bg);
      sideNav.textColor && root.style.setProperty("--SideNav__TextColor", sideNav.textColor);
      sideNav.activeSideLine && root.style.setProperty("--SideNav__ActiveSideLine", sideNav.activeSideLine);
      sideNav.activeBg && root.style.setProperty("--SideNav__ActiveBg", sideNav.activeBg);
      sideNav.activeText && root.style.setProperty("--SideNav__ActiveText", sideNav.activeText);
    }
    // Main Tab Navigation Bar
    if (mainNav) {
      mainNav.icon && root.style.setProperty("--MainNav__Icon", mainNav.icon);
      mainNav.Color && root.style.setProperty("--MainNav__Color", mainNav.Color);
      mainNav.activeColor && root.style.setProperty("--MainNav__ActiveColor", mainNav.activeColor);
      mainNav.textColor && root.style.setProperty("--MainNav__TextColor", mainNav.textColor);
    }
    // Script Box
    if (scriptBox) {
      scriptBox.border && root.style.setProperty("--ScriptBox__Border", scriptBox.border);
      scriptBox.bg && root.style.setProperty("--ScriptBox__Bg", scriptBox.bg);
    }

    setBrandingComplete(true)
  }, [account]);

  return brandingComplete;
}