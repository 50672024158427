import React, { useContext, useState, useEffect } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { useClaimInvoiceStore } from '../InvoiceTables/lib/state';
import { randomized } from '../../lib/util';
import { useConfigureTable } from '../InvoiceTables/lib/useConfigureInvoiceTables';
import styles from "./ContractInfo.module.css";
import classNames from "classnames";

export const FileClaim = ({ coverageType, contract, reImbursementType, clientId, profile, handleFirstCallMessage, selectedAsset, actionDisabled, assetNotSelected }) => {
    const init = useClaimInvoiceStore(state => state.init)
    const { tabDispatch } = useContext(SessionContext)
    const configData = useConfigureTable(coverageType, contract, profile, reImbursementType, selectedAsset);
    const tableConfig = configData?.prepared;
    const taxData = configData?.tax;
    const [fileClaimNotAllowed, setFileClaimNotAllowed] = useState(false)

    useEffect(() => {
        if (selectedAsset.length > 0) {
            setFileClaimNotAllowed(false);
        }
    }, [selectedAsset])
    const handleSubmit = () => {
        if (selectedAsset.length <= 0) {
            setFileClaimNotAllowed(true);
            return;
        }
        let hasFirstCall = true;
        let firstCallMessage
        var riskFirstCall = contract?.riskProfile?.riskProfileConfigurationModel?.filter(y => y.pricingAttributeId === 31, val => val.value === 1);
        if (riskFirstCall === null || riskFirstCall[0]?.value === '0' || riskFirstCall.length === 0) {
            hasFirstCall = false
        }

        if (hasFirstCall === false) {
            const invoiceId = `invoice_${randomized()}`;
            init(invoiceId, tableConfig, clientId, taxData, contract, profile, selectedAsset);
            tabDispatch('ADD_SUBTAB', {
                subtabType: 'claiminvoice',
                invoiceId: invoiceId,
                invoiceConfigType: coverageType,
                reImbursementType,
                profile
            })
            firstCallMessage = false;
            handleFirstCallMessage(firstCallMessage)
        }

        if (hasFirstCall === true) {
            firstCallMessage = true;
            handleFirstCallMessage(firstCallMessage)
        }
    };

    return (
        //Keep it commented for future reimbursement type wireup
        //<div>
        //    <select onChange={handleChange} value={invoiceConfiguration}>
        //        {Object.entries(display).map(([key, display]) => (
        //            <option key={key} value={key}>
        //                {display}
        //            </option>
        //        ))}
        //    </select>
        //</div>

        <div className={styles.footerAlign}>
            {fileClaimNotAllowed && <div className={classNames("errorMsg", styles.alignText)}>No product selected.Please select a product to file a claim and try again.</div>}
            <div className={styles.buttonAlign}>
                <button disabled={actionDisabled} className={classNames("btn-primary", styles.fileClaim)} onClick={handleSubmit}>File Claim</button>
            </div>
        </div>
    )
}