import React from 'react';
import { Input, Label } from 'reactstrap';
import styles from './NotesField.module.css';
import InfoToolTip from "../InfoTooltip/InfoTooltip";
import classNames from 'classnames';

const MIN_TEXTAREA_HEIGHT = 32;

const NotesField = ({
    // onChange takes event value from field as arg, and should CONTROL value prop in the parent.
    value,          // value for field.
    onChange,       // function that takes VALUE from event as arg.
    maxChars = 1000, // number, default is 1000
    placeholder,    // OPTIONAL for placeholder in field before entry.
    label = "Additional Notes", // optional; default is "Additional Notes"
    ariaForAndId = 'notes',
    formItemName,
    fullWidth = false,  //OPTIONAL; make wrapper occupy whole width ; Can be passed as PROP in required scenarios
    disabled,
    required = false,
    validator,
    hideLabel, // OPTIONAL; To hide Label
    autoHeight = false,
    customStyle = '',
    infoMessage = ''

}) => {
    const textareaRef = React.useRef(null);

    const notesValidator = required && validator?.current ?
        validator?.current?.message(formItemName, value, "required") :
        validator?.message?.(formItemName, value, "required") || "";
    React.useLayoutEffect(() => {
        if (value) {
            // Reset height - important to shrink on delete
            textareaRef.current.style.height = "inherit";
            // Set height
            textareaRef.current.style.height = `${Math.max(
                textareaRef.current.scrollHeight,
                MIN_TEXTAREA_HEIGHT
            )}px`;
        } else {
        //If there are not any values set height
        textareaRef.current.style.height = "inherit";
    }
    }, [value]);

    const handleChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <div className={`${fullWidth ? styles.fullWidthNotesWrapper : styles.specialNotesWrapper} ${notesValidator && 'form-error'}`}>
            {hideLabel ? '' : <Label className={`${styles.notesLabel} form-label`} htmlFor={ariaForAndId}>{label}{required && <span className="required-indicator">*</span>}</Label>}
            {infoMessage != '' &&
                <span>
                    <InfoToolTip iconType="info" message={infoMessage} />
                </span>
            }
            <Input
                innerRef={textareaRef}
                placeholder={placeholder && placeholder}
                className={classNames(autoHeight ? styles.autoHeight : styles.notesField, customStyle)}
                type="textarea"
                name={formItemName}
                id={ariaForAndId}
                onChange={handleChange}
                maxLength={maxChars}
                value={value}
                disabled={disabled}
                style={{
                    minHeight: autoHeight ? MIN_TEXTAREA_HEIGHT : '',
                    resize: "none"
                }}
            />
            <p>{`${value ? value.length : 0}/${maxChars}`}</p>
            {notesValidator}
        </div>
    )
}

export default NotesField;
