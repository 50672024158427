import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import classnames from 'classnames';
import styles from "./Resources.module.css";
import { DropDownInput } from "../../components/inputs/DropDownInput";

const MainTabContent = ({ sideNavTabs, mainTab, activeTab, onTabChange, navigationCheck, hideContent, handleMobileContent }) => {

  const sideNav = mainTab.data;
  const sideNavTitle = mainTab.display;
  // const [subMenuOptions, setSubMenuOptions] = useState([]);
  let subMenuOptions = [];

  const getComponent = () => {
    if (activeTab < sideNavTabs[sideNav].length) {
      return sideNavTabs[sideNav][activeTab].component;
    }
    else {
      onTabChange(0);
      return sideNavTabs[sideNav][0].component;
    }
  }
  let tempArr = [];
  sideNavTabs[sideNav].map((tab, index) => (
    tempArr.push({ display: tab.title, data: index })
  ));
  subMenuOptions = tempArr;

  const onSubMenuChange = (value) => {
    onTabChange(value);
    handleMobileContent();
  }

  return (
    <>
      <Row className={classnames("mr-0", styles.showSm)}>
        <Col lg="3" md="3" className={classnames(styles.sideNav)}>
          <ul >
            {sideNavTabs[sideNav].map((tab, index) => (
              <li
                className={classnames(index === activeTab ? styles.activeSideTab : "")}
                key={index}
                onClick={() => onTabChange(index)}>{tab.title}
              </li>
            ))}
          </ul>
        </Col>
        <Col md="9" className="p-4 ml-2 ml-md-0">
          {getComponent()}
        </Col>
      </Row>
      {navigationCheck && <Row className="mr-0 d-block d-sm-none">
        <Col className={classnames("py-1 px-4 ml-2 ml-md-0", hideContent ? "pb-4" : "")}>
          <h1 className="h6">
            Types of {sideNavTitle}
            <span aria-hidden="true" className="required-indicator">*</span>
          </h1>
          <DropDownInput
            name="subMenu"
            options={subMenuOptions}
            className="form-control"
            defaultValue=""
            onChangedValue={onSubMenuChange}
            placeholder="Please Select..."
          />
        </Col>
        {!hideContent &&
          <Col className="p-4 ml-2 ml-md-0">
            {getComponent()}
          </Col>
        }
      </Row>}
    </>
  )
}
export default MainTabContent;
