import React from 'react'
import { Input, Label } from 'reactstrap';
import styles from './CheckboxInput.module.css'
import classNames from 'classnames';

const CheckboxInput = ({
  checked,
  handleCheck,
  clientId = "",
  id = "",
  customClass,
  textCustomClass,
  children,          // INSERT THE CHECKBOX "STATEMENT" OR "TEXT" INSIDE THE COMPONENT ON IMPLEMENTATION IN PARENT.
  name,
  defaultChecked
}) => (
    <div className={classNames(styles.checkbox, customClass)}>
      <Input
        className="mr-2"
        type="checkbox"
        id={id}
        onChange={handleCheck}
        checked={checked}
        name={name}
        defaultChecked={defaultChecked}
      />
      <Label htmlFor={id} className={classNames(styles.checkboxLabel, styles[clientId])}>
        <span className={textCustomClass}>
          {children}
        </span>
      </Label>
    </div>
  )

export default CheckboxInput;