import styles from './DistributorDetails.module.css';

const IndustryList = [];
const radiousList = [
  {
    display: "20 miles",
    data: "20"
  },
  {
    display: "30 miles",
    data: "30"
  },
  {
    display: "40 miles",
    data: "40"
  },
  {
    display: "50 miles",
    data: "50"
  }
]

export const DQuestions = {
  type: "distributor",
  questions: [
    {
      question: " Will you be selling Extended Service Agreements? ",
      answer: "",
      name: "isSellingESA",
      show: false,
      options: [
        {
          option: "Yes",

          questions: [
            {
              question: "Will you be selling to homeowners who live in Florida? ",
              name: "isSellingHome",
              isCheckState: true,
              states: ["FL", "AL", "GA"],
              answer: "",
              options: [
                { option: "Yes" },
                { option: "No" }
              ]
            },
            {
              question: "What is your preferred payment method?",
              name: "peferedpayment",
              answer: "",
              options: [
                { option: "Invoice" },
                { option: "COD" }
              ]
            },
            {
              question: "Will you be the responsible party for sales invoices?",
              name: "salesInvoice",
              answer: "",
              options: [
                { option: "Yes" },
                { option: "No" }
              ]
            },
          ]
        },
        {
          option: "No"
        }
      ]
    }
  ]
};

export const DeQuestions = {
  type: "dealer",
  questions: [
    {
      question: " Will you be selling to homeowners who live in Florida? ",
      name: "deSellingHome",
      isCheckState: true,
      states: ["FL", "AL", "GA"],
      answer: "",
      options: [
        {
          option: "Yes",
        },
        {
          option: "No"
        }
      ]
    },
    {
      question: " Will you be completing repairs when your customers need service? ",
      name: "isCNS",
      show: false,
      answer: "",
      titleEnabled: true,
      options: [
        {
          option: "Yes",
          title: "Servicing Information",
          seperator: true,
          questions: [
            {
              question: "Are you interested in receiving service calls outside of your customers?",
              name: "isIntertestedcall",
              options: [
                { option: "Yes" },
                { option: "No" }
              ]
            },
            {
              question: "Select all the  Industries that you service",
              answer: [{ data: 8, display: "HVAC (HA)", value: "HA" }],
              type: "multiselect",
              required: true,
              name: "industries",
              options: IndustryList
            },
            {
              question: "ZIP/Postal Code",
              type: "cleave",
              name: "zip",
              required: true,
              className: styles.verticalAlign,
            },
            {
              question: "Coverage Radius",
              type: "singleselect",
              required: true,
              className: styles.verticalAlign,
              name: "coverageRadius",
              options: radiousList
            }
          ]
        },
        {
          option: "No",
          title: "Do you have a preferred servicer?",
          questions: [
            {
              question: "Preferred Servicer Name",
              type: "input",
              name: "servicerName",
              className: `col-5ths ${styles.preferredServicerStyle} ${styles.alignLabel}`
            },
            {
              question: "Preferred Servicer Phone",
              type: "cleave",
              required: false,
              validator: true,
              blocks: [0, 3, 3, 4],
              delimiters: ["(", ") ", "-"],
              name: "servicerPhone",
              valueType: "phone",
              className: `col-5ths ${styles.preferredServicerStyle} ${styles.alignLabel} ${styles.inputWrapper}`
            },
            {
              question: "Preferred Servicer Email",
              type: "input",
              name: "servicerEmail",
              className: `col-5ths ${styles.preferredServicerStyle} ${styles.alignLabel}`
            }
          ]
        }
      ]
    }
  ]
};

export const SQuestions = {
  type: "servicer",
  questions: [
    {
      question: "Are you interested in receiving service calls outside of your customers?",
      name: "isInterestedCall",
      options: [
        { option: "Yes" },
        { option: "No" }
      ]
    },
    {
      question: "Select all the  Industries that you service",
      answer: [{ data: 8, display: "HVAC (HA)", value: "HA" }],
      type: "multiselect",
      required: true,
      name: "industries",
      options: IndustryList
    },
    {
      question: "ZIP/Postal Code",
      type: "cleave",
      name: "zip",
      className: styles.verticalAlign,
      required: true,
    },
    {
      question: "Coverage Radius",
      type: "singleselect",
      required: true,
      className: styles.verticalAlign,
      name: "coverageRadius",
      options: radiousList
    }
  ]
};

export const labelDetails = {
  USA: {
    description: "Please provide your Federal Tax Identification Number (FEIN) or Employer Identification Number (EIN). Due to potential risks, compliance requirements, and security, we cannot accept social security numbers in lieu of an FEIN. Please visit www.irs.gov if you need to obtain a free FEIN or apply for an EIN.",
    taxForm: "FEIN/EIN",
    taxStatus: [
      {
        display: "Incorporated",
        data: "INC",
      },
      {
        display: "Unincorporated",
        data: "UNC",
      }
    ],
    formName: "W9"
  },
  Canada: {
    description: "Please provide your Business Number (BN). Due to potential risks, compliance requirements, and security, we cannot accept social insurance numbers in lieu of a BN. Please contact Canada Revenue Agency if you need to obtain a BN.",
    taxForm: "TD1",
    taxStatus: [
      {
        display: "Good & Services Tax (GST)",
        data: "GST",
      },
      {
        display: "Harmonized Sales Tax (HST)",
        data: "HST",
      },
      {
        display: "Provincial Sales Tax (PST)",
        data: "PST",
      },
      {
        display: "Quebec Sales Tax (QST)",
        data: "QST",
      }
    ],
    formName: "TD1"
  }
};

export const countries = [
  {
    display: 'USA',
    data: 1
  },
  {
    display: 'Canada',
    data: 2
  }
];

export const language = [
  {
    display: 'English',
    data: 'english'
  },
  {
    display: 'French',
    data: 'french'
  }
];