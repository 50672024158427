import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./QueueReport.module.css";
import { ReactComponent as SearchIcon } from '../../../src/assets/images/search-button.svg';
import { ReactComponent as Close } from '../../../src/assets/images/close.svg';
import classNames from 'classnames';

const CustomSearch = ({ searchText, onSearch, onClear, onTableSearch, searchKey, setSearchText }) => {
  const inputContainer = useRef(null);
  const [searchInput, setSearchInput] = useState('');

  const handleClick = () => {
    if(inputContainer?.current?.value.trim().length > 0) {
      setSearchText(inputContainer?.current?.value);
      onTableSearch ? onTableSearch(1, inputContainer?.current?.value) : onSearch(inputContainer?.current?.value);
    }    
  };

  const clearSearch = () => {

    inputContainer.current.value = "";    
    setSearchText(inputContainer?.current?.value);
    setSearchInput(inputContainer?.current?.value);
    if(onTableSearch) {
        onTableSearch(1, inputContainer?.current?.value, true) 
    } else {
      onClear();
    }   
  };

  const handleInputChange = (event) => {
    const {value} = event.target;
    setSearchInput(value);
  }

  return (
    <>
      <label for="search-bar-0" class="search-label">
        <span id="search-bar-0-label" class="sr-only">Filter Records</span>
        <input
          id="search-bar-0"
          aria-labelledby="search-bar-0-label"
          className={classNames("form-control", styles.customSearchBorder)}
          ref={inputContainer}
          type="text"
          name="searchInput"
          onChange={handleInputChange}
          value={searchInput}
          placeholder={searchKey ? searchKey : "Filter Records"}
        />
      </label>
      
        {(searchText || searchKey || searchInput) && (
            <span onClick={clearSearch}>
              <Close className={styles.filterCloseIcon} />
            </span>
        )}
        <span className={styles.searchIconContainer}>
          <SearchIcon
            className={styles.filterSearchIcon}
            onClick={handleClick}
          />
        </span>      
    </>
  );
};

export default CustomSearch;