import React, { Component } from 'react';


export default class LegacyAutoLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileClaimClick: this.props.fileClaimClick,
            url: this.props.url,
            formData: this.props.formData,
            legacyPageLoad: this.props.legacyPageLoad
        }
    }
    componentDidMount() {
        if (this.state.fileClaimClick) {
            document.forms[0].action = this.state.url + "Authentication/Login";
            document.forms[0].submit();
        }
    }
    
    render() {
        return (
            <form target="" method="post" name="HvacForm" id="HvacForm" action={this.state.url + "Authentication/Login"}>
                <input type="hidden" name="dealerId" id="dealerId" value={this.state.formData.dealerID} />
                <input type="hidden" name="DealerBrand" id="DealerBrand" />
                <input type="hidden" name="ClaimsOnly" id="ClaimsOnly" value="false" />
                <input type="hidden" name="DateTime" id="DateTime" value={this.state.formData.dateTime} />
                <input type="hidden" name="hash" id="hash" value={this.state.formData.hash} />
                <input type="hidden" name="SNUserName" id="SNUserName" value={this.state.formData.snUserName} />
                <input type="hidden" name="IsAdmin" id="IsAdmin" value="false" />
                <input type="hidden" name="IsDraftDocAdmin" id="IsDraftDocAdmin" value="false" />
                <input type="hidden" name="accountId" id="accountId" value={this.state.formData.accountID} />
                <input type="hidden" name="SORG" id="SORG" value={this.state.formData.sorg} />
                <input type="hidden" name="LegacyPageLoad" id="LegacyPageLoad" value={this.state.legacyPageLoad} />
                    <input type="submit" id="btnSubmit"/>
                </form>
         );
    }
}
