import React, { Component, useEffect, useState, useContext, createContext, useReducer } from "react";
import Adjudication from '../SubmitInvoice/InvoiceAdjudication';
import { historyPush } from '../../components/Global/helpers.jsx';

let rfsData = null;
export class SubmitInvoice  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: typeof props.match.params.channelId == "undefined" ? props.location.state.eventId : props.match.params.eventId,
            clientId: typeof props.match.params.channelId == "undefined"  ? props.location.state.clientId : props.match.params.clientId,
            eventNumber: typeof props.match.params.channelId == "undefined"  ? props.location.state.eventNumber : "",
            modelNumber: typeof props.match.params.channelId == "undefined"  ? props.location.state.modelNumber : "",
            serialNumber: typeof props.match.params.channelId == "undefined"  ? props.location.state.serialNumber : "",
            brandName: typeof props.match.params.channelId == "undefined"  ? props.location.state.brandName : "",
            servicerPhone: typeof props.match.params.channelId == "undefined"  ? props.location.state.servicerPhone : "",
            preauthorizedAmount: typeof props.match.params.channelId == "undefined" ? props.location?.state?.preauthorizedAmount : null,
            currentClaimOverviewId: 0,
            isRequestedFromOtherSite: typeof props.match.params.channelId == "undefined"  ? false : true,
            isLoaded: false,
            rfsData: typeof props.match.params.channelId == "undefined" ? props.location.state.rfsData : {}
        }
    }

    componentWillMount()
    {
        historyPush(this.props?.location?.state, "requestforservice", null, this.state.clientId, this.callGetRFSData, false);
    }

    async GetRFSFormData(urlParams)
    {
        fetch(`claim/GetRFSSummaryByEventId/${this.state.clientId}?${urlParams?.toString()}`)
            .then(res => res.json())
            .then(data => {
                rfsData = data;
                this.setState({
                    isLoaded: true,
                    eventNumber: rfsData.eventNumber,
                    modelNumber: rfsData.modelNumber,
                    serialNumber: rfsData.serialNumber,
                    brandName: rfsData.brand,
                    rfsData
                });
            });
    }

    callGetRFSData = (urlParams) => {
        this.setState({ urlParams });

        if (this.state.isRequestedFromOtherSite)
            this.GetRFSFormData(urlParams);
        else
            this.setState({ isLoaded: true });

    }

    render() {
        console.log(this.state, this.props)
        return (this.state.isLoaded && <Adjudication eventNumber={this.state.eventNumber}
            eventId={this.state.eventId}
            claimId={this.state.currentClaimOverviewId}
            clientId={this.state.clientId}
            modelNumber={this.state.modelNumber}
            serialNumber={this.state.serialNumber}
            brandName={this.state.brandName}
            preauthorizedAmount={this.state.preauthorizedAmount}
            servicerPhone={this.state.servicerPhone}
            rfsData={this.state.rfsData }
        />);
    }
}