import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col } from "reactstrap";
import classNames from "classnames";
import styles from "./Enrollment.module.css";
import { ReactComponent as CanadaIcon } from '../../../assets/images/Canada_Icon.svg';
import { ReactComponent as USAIcon } from '../../../assets/images/USA_Icon.svg';
import { ReactComponent as LaptopIcon } from '../../../assets/images/laptop.svg';
import { ReactComponent as EmailIcon } from '../../../assets/images/envelope.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/send.svg';
import { ReactComponent as FaxIcon } from '../../../assets/images/fax_new.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/phone.svg';
import { getBrandContent } from "../../BrandingWhiteLabel/BrandContent";
import { stripPhoneNumber } from "../../shared/util";
import { withRouter } from 'react-router-dom';

const EnrollmentModal = ({ isOpen, toggle, centered, accountName }) => {
  const accountNameToLower = accountName.toLowerCase();
  const currentBrand = getBrandContent(accountName);

  const [formData, setFormData] = useState({
    email: "",
    termsAndConditions: null,
  });
  const [, forceUpdate] = useState();

  const handleFormDownload = async (country) => {
    fetch(`document/getdealerenrollmentdocument/${country}`)
      .then(res => {
        if (res.status === 200) {
          return res.arrayBuffer()
        }
        else {
          return undefined;
        }
      })
      .then(data => {
        if (data !== undefined) {
          var file = new Blob([data], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);

          window.open(fileURL);
        }
        else {
          console.log("Error fetching file.");
        }
      })
  }

  const enroll = () => {
    window.open(`${window.location.origin}/enroll`, "_blank");
  }

  const phone = stripPhoneNumber(currentBrand.programPhone);

  return (
    <Modal 
      isOpen={isOpen}
      toggle={toggle}
      centered={centered}
      size="md"
      aria-labelledBy="Dealer Enrollment"
      className="md" >

      <ModalHeader className={styles.saveCardHeader}>
        <h1 className='modalTitle h5 mb-0'>
          {accountNameToLower === 'ge' && currentBrand.programName+' '}Dealer - Enrollment
        </h1>
      </ModalHeader>

      <ModalBody>
        <div>
          <Row>
            <Col md="12" className="mb-3 mr-3">
              <p className={classNames('mb-1', styles.headingColor, styles.headingFontStyle)}>
                Enroll to become an AIG Dealer:
              </p>
              Enroll online or download, complete and submit the dealer agreement form using one of the methods below.
            </Col>
          </Row>
          <Row>
             <Col md="12" className="mb-3"> 
              <Row className={`ml-0 ${styles.alignVertical}`}>
                {accountNameToLower !== 'ge' && 
                  <button className={classNames("btn-secondary", styles.downloadFormContainer, "mb-3", "pl-0", "mr-3")} onClick={enroll}>
                    <div className={`${styles.countryIcon} ${styles.lapIcon}`}>
                      <LaptopIcon className='ml-1' /><span>Enroll Online</span>
                    </div>
                  </button>
                }
                <button className={classNames("btn-secondary", styles.downloadFormContainer, "mb-3", "pl-0", "mr-3")} onClick={() => handleFormDownload('US')}>
                  <div className={styles.countryIcon}>
                    <USAIcon /><span>Download US</span>
                  </div>
                </button>
                <button className={classNames("btn-secondary", styles.downloadFormContainer, "mb-3", "pl-0", "mr-3")} onClick={() => handleFormDownload('CA')}>
                  <div className={styles.countryIcon}>
                    <CanadaIcon /><span>Download Canada</span>
                  </div>
                </button>
              </Row>
            </Col>
          </Row>
          <Row className="ml-0">
            <Col md="12" className="mb-0">
              <Row>
                <Col md="5" className={classNames('mb-3', 'pl-0', styles.emailContainer)}>
                  <div className="d-flex">
                    <div className={styles.iconContainer}><EmailIcon /></div>
                    <div>
                      <ul className={classNames("pl-3")}>
                        <li className={classNames(styles.headingColor, styles.headingFontStyle)}>Email</li>
                        <li><a href="mailto:hvacenrollments@aig.com">hvacenrollments@aig.com</a></li>
                      </ul>
                    </div>
                  </div>                  
                </Col>
                <Col md={{ size: 5, offset: 1 }} className={classNames('mb-3 pl-0', styles.faxContainer)}>                  
                  <div className="d-flex">
                    <div className={styles.iconContainer}><FaxIcon /></div>
                    <div>
                      <ul className="pl-3">
                        <li className={classNames(styles.headingColor, styles.headingFontStyle)}>Fax</li>
                        <li>(866) 212-3750</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" className={classNames('mb-0', 'pl-0', styles.mailContainer)}>                  
                  <div className="d-flex">
                    <div className={styles.iconContainer}><MailIcon /></div>
                    <div>
                      <ul className={classNames("pl-3")}>
                        <li className={classNames(styles.headingColor, styles.headingFontStyle)}>Mail</li>
                        <li>AIG</li>
                        <li>Attn: Dealer Support</li>
                        <li>P.O. Box 928</li>
                        <li>Jeffersonville, IN 47130</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col md={{ size: 5 }} className={classNames('mb-3 pl-0', styles.phoneContainer)}>
                  <div className="d-flex">
                    <div className={styles.iconContainer}><PhoneIcon /></div>
                    <div>
                      <ul className="pl-3">
                        <li className={classNames(styles.headingColor, styles.headingFontStyle)}>Questions</li>
                        <li><a className="callLink" href={`tel:${phone}`}>{currentBrand.programPhone}</a></li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

        </div>
      </ModalBody >

      <ModalFooter>
        <div className={styles.paymentBtnWrapper}>
          <button className={classNames("btnLink")} onClick={toggle}>Close</button>
        </div>
      </ModalFooter>
    </Modal >
  );
};

export default withRouter(EnrollmentModal);