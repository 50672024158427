import React, { useState, useEffect, useContext } from 'react';
import styles from './QueueReport.module.css';
import classNames from 'classnames';
import { DropdownWithNumber } from '../../components/inputs/DropdownWithNumber';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { ReportContext } from './ReportsContext';
import { SessionContext } from "../../context/SessionContext";

const stateOptions = [
  { display: 'All Tasks', data: "alltasks" },
  { display: 'My Tasks', data: "mytasks" }
];
const contractTypeOptions = [
  { display: 'All Contracts', data: "allcontracts" },
  { display: 'Active Contracts', data: "active" },
  { display: 'Wait Period Contracts', data: "wait" },
  { display: 'Cancelled Contracts', data: "cancel" },
  { display: 'Expired Contracts', data: "expire" }
];
const ReportTypeOptions = [
  { display: 'Claims', data: "claims" },
  { display: 'Contracts', data: "contracts" },
  { display: 'Pending Contracts', data: "pendingcontracts" },
  { display: 'Incomplete Sales', data: "incompletesales" },
  { display: 'Payment History', data: "paymenthistory" },
  { display: 'Service Request', data: "servicerequest" }
];
const ReportInvoiceTypeOptions = [
  { display: 'Contracts', data: "contracts" },
  { display: 'Pending Contracts', data: "pendingcontracts" },
  { display: 'Incomplete Sales', data: "incompletesales" },
  { display: 'Service Request', data: "servicerequest" },
  { display: 'Claims', data: "claims" }
];

function ReportsFilter(props) {
  const [state, dispatch] = useContext(ReportContext);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [dropValue, setDropValue] = useState(null);
  const sessionContext = useContext(SessionContext);
  const accountName = sessionContext?.accountName.toLowerCase();
  const fileClaimFlag = sessionContext?.getAccountFeatureFlag("hvac claim");

  useEffect(() => {
	  const { type } = state;
	  const dropDownValue = ['contracts', 'activecontracts', null];
	  const defaultText = dropDownValue?.some(val => val === type) || !fileClaimFlag ? "contracts" : type;

	  setDropValue(defaultText)
  }, [state])

  const toggle = () => {
    setDropDownOpen(!dropdownOpen)
	}

	const ReportTypeOptions = fileClaimFlag ?
		[
			{ display: 'Claims', data: "claims" },
			{ display: 'Contracts', data: "contracts" },
			{ display: 'Pending Contracts', data: "pendingcontracts" },
			{ display: 'Incomplete Sales', data: "incompletesales" },
			{ display: 'Payment History', data: "paymenthistory" },
			{ display: 'Service Request', data: "servicerequest" }
		] :
		[
			{ display: 'Contracts', data: "contracts" },
			{ display: 'Pending Contracts', data: "pendingcontracts" },
			{ display: 'Incomplete Sales', data: "incompletesales" },
			{ display: 'Payment History', data: "paymenthistory" },
		];

	const ReportInvoiceTypeOptions = fileClaimFlag ?
		[
			{ display: 'Claims', data: "claims" },
			{ display: 'Contracts', data: "contracts" },
			{ display: 'Pending Contracts', data: "pendingcontracts" },
			{ display: 'Incomplete Sales', data: "incompletesales" },
			{ display: 'Payment History', data: "paymenthistory" },
			{ display: 'Service Request', data: "servicerequest" }
		] :
		[
			{ display: 'Contracts', data: "contracts" },
			{ display: 'Pending Contracts', data: "pendingcontracts" },
			{ display: 'Incomplete Sales', data: "incompletesales" },
			{ display: 'Payment History', data: "paymenthistory" }
		];

	return <>
		{sessionContext.getProfile().isInvoiceDealer ? (
			<div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
				<DropdownWithNumber
					name='installationState'
					options={ReportInvoiceTypeOptions}
					//className="form-control"
					defaultValue="contracts"
					text={dropValue === 'contracts' || dropValue == null || dropValue === 'activecontracts' ? "contracts" : dropValue}
					length={state && ((state.totalContracts) ? state.totalContracts : (state.data && state.data.length))}
					onChangedValue={val => {
						console.log({ val })
						console.log(dropValue)
						setDropValue(val)
						sessionContext.pendingReportTrack.pendingBackFlag = false;
						dispatch({
							type: 'SET_TYPE',
							payload: val
						})
					}}
				/>
			</div>
		) : (
				<div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
					<DropdownWithNumber
						name='installationState'
						options={ReportTypeOptions}
						className={(accountName == "rheem" || accountName == "carrier") ? styles.reportsCounterClient : ''}
						defaultValue="contracts"
						text={dropValue === 'contracts' || dropValue == null || dropValue === 'activecontracts' ? "contracts" : dropValue}
						length={(state.totalContracts) ? state.totalContracts : (state.data && state.data.length)}
						onChangedValue={val => {
							console.log({ val })
							console.log(dropValue)
							setDropValue(val)
							sessionContext.pendingReportTrack.pendingBackFlag = false;
							dispatch({
								type: 'SET_TYPE',
								payload: val
							})
						}}
					/>
				</div>
			)}
	</>
}

export default ReportsFilter