import React from 'react'
import AdjudicationDisplay from './AdjudicationDisplay'
import { AdjudicationContextProvider } from './util/store/context'

const Adjudication = (props) =>
{
   
    return (
        <AdjudicationContextProvider>
            <AdjudicationDisplay setAuthorizeStatus={props.setAuthorizeStatus}
                eventNumber={props.eventNumber}
                eventId={props.eventId}
                claimId={props.claimId}
                rfsData={props.rfsData}
                clientId={props.clientId}
                preauthorizedAmount={props.preauthorizedAmount} />
        </AdjudicationContextProvider>
    )
}

export default Adjudication;