import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const VerifyProductError = (props) => {
    const {
        buttonLabel,
        className,
        showVerifyModal,
        addProducts,
        updateModal
    } = props;

    //const [modal, setModal] = useState(false);
    const [modal, setModal] = useState(showVerifyModal);

    const toggle = () => setModal(!modal);

    const handleAddProduct = (e) => {
        setModal(false);
        props.addProducts(false);
    }

    const handleCancel = (e) => {
        setModal(false);
        props.updateModal(false);
    }

    return (
        <div>
            {/*<button onClick={toggle}>{buttonLabel}</button> */}
            <Modal isOpen={modal} toggle={toggle} className="modal-md">
                <ModalHeader toggle={toggle}>
                    <h4 className="modal-title">Product Verification Error</h4>
                </ModalHeader>
                <ModalBody>
                    <p>We are unable to verify this product with the manufacturer due to connectivity issues. Would you like to continue adding this product?</p>
                </ModalBody>
                <ModalFooter>
                    <div className="btnWrapper">
                        <button className="btnLink" onClick={handleCancel}>Cancel</button>{' '}
                        <button className="btn-primary" onClick={handleAddProduct}>Add Product</button>
                        {/*<button className="btnLink" onClick={toggle}>Cancel</button>{' '}*/}
                        {/*<button className="btn-primary" onClick={toggle}>Add Product</button>*/}
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default VerifyProductError;