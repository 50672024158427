import React, { useState, useEffect, useRef, createRef } from 'react';
import { useClickOutsideElement } from '../../../lib/CustomReactHooks/useClickOutside.js'
import { randomized } from '../../../lib/util';
import styles from './MultiDrop.module.css'

/**
 * ============================================
 * ============== START COMPONENT =============
 * ============================================
 */

const MultiDropDown = ({
  options,            // 1. pass options from parent (array of objects with {value, display})
  onOptionClick,      // 2. parent controls what to do with selections
  selected,           // 3. and passes selections back to component. (Array of each selected option.value string)
  hasSearch = false,  // allow searchable options
  borderless = false, // for use in a table where cell provides border
  disabled = false,
  placeholder = "Please select...",
  allSelectedOption = '', // string to be shown when all options are selected
  searchInputPlaceholder = 'Search...',
  clientId = ''
}) => {
  const [drop, setDrop] = useState(false);
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState([])
  let searchRef = useRef();
  let optionRefs = useRef({});
  const [activeIndex, setActiveIndex] = useState("");


  useEffect(() => {
    const results = options?.filter(o => {
      if (isNaN(o.display)) {
        return o.display.toLowerCase().includes(searchValue)
      } else {
        return o.display.includes(searchValue)
      }

    });
    setSearchResult(results);
  }, [searchValue]);

  useEffect(() => {
    if (drop) {
      Object.keys(groupingOptions).map((key, index) => {
        groupingOptions[key].map((v, i) => {
          optionRefs.current[`${index}${i}`] = createRef();
        })
      })
      if (optionRefKeys.length && !hasSearch) setActiveIndex(optionRefKeys[0]);
    }
    if (hasSearch && drop) {
      searchRef = searchRef?.current?.focus();
    }
  }, [drop]);

  useEffect(() => {
    if (activeIndex && drop) {
      optionRefs = optionRefs.current?.[activeIndex]?.current?.focus();
    }
  }, [activeIndex]);

  // unique ids necessary to allow multiple dropdowns to operate on a single page.
  const idKey = ['multidrop', randomized()].join('_')
  useClickOutsideElement(idKey, () => setDrop(false))

  const arrayToFilter = !!searchValue ? searchResult : options
  let groupingOptions = {};
  const optionRefKeys = [];

  function sortObj(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
  }
  arrayToFilter.forEach((el) => {
    if (el.group === undefined) el.group = '';

    if (groupingOptions[el.group]) {
      groupingOptions[el.group].push({ display: el.display, value: el.value })
    } else {
      groupingOptions[el.group] = [{ display: el.display, value: el.value }]
    }
  })

  groupingOptions = sortObj(groupingOptions);


  Object.keys(groupingOptions).map((key, index) => {
    groupingOptions[key].map((v, i) => {
      optionRefKeys.push(`${index}${i}`)
    })
  })


  const handleSearch = (val) => {
    setSearchValue(val.toLowerCase());
  }

  const getButtonText = () => {
    const length = selected?.length ?? 0;
    if (length === 0) {
      return (placeholder);
    }
    else if (length === 1) {
      const getDisplayName = options.find(o => o.value === selected[0].value || o.value == selected[0])
      return (getDisplayName?.display)
    }
    else if ((allSelectedOption !== '') && (selected.length === options.length)) {
      return allSelectedOption;
    }
    else {
      return `${length} selected`;
    }
  }


  const onSelectOption = (value, display, key, index) => {
    setActiveIndex(index);;
    onOptionClick(value, display, key)
  }
  
 
  const searchMouseDownEvent = (e) => {
   if (e.key === "ArrowDown") {
      if (optionRefKeys.length) setActiveIndex(optionRefKeys[0]);
    }
  }

  const handleArrowKeys = (e, key) => {
    const keyValue = e.target.id;
    if (e.key === "ArrowUp") {
      const currentIndex = optionRefKeys.indexOf(keyValue);
      if (currentIndex - 1 !== -1) {
        setActiveIndex(optionRefKeys[currentIndex - 1]);
      } else {
        searchRef = searchRef?.current?.focus();
        setActiveIndex("")
      }
    }
    if (e.key === "ArrowDown") {
      const currentIndex = optionRefKeys.indexOf(keyValue);
      if (currentIndex + 1 !== optionRefKeys.length) {
        setActiveIndex(optionRefKeys[currentIndex + 1]);
      }
    }

    if (e.key === "Enter") {
      onSelectOption(e.target.children[0]?.defaultValue, e.target.innerText, key, keyValue)
    }
  }


  return (
    <div id={idKey} style={{ position: 'relative' }} className={`${styles.wrapper} ${!!borderless && 'queueDropdown'}`}>
      <button className="dropbtn dropbtn-placeholder" onClick={() => setDrop(!drop)}>{getButtonText()}</button>
      {drop &&
        <div className={styles.optionsContent}>
          {hasSearch &&
            <div className="search-input"><label>
              <input
                className={`${styles.searchInput} form-control`}
                value={searchValue}
                ref={searchRef}
                type='text'
                placeholder={searchInputPlaceholder}
                onKeyDown={searchMouseDownEvent}
                onChange={(e) => handleSearch(e.target.value)}
                autoFocus
              />
            </label>
              {<img alt="search" src={!searchValue ? require("../../../assets/images/search.svg") : require("../../../assets/images/close.svg")} onClick={() => searchValue && handleSearch('')} />}
            </div>
          }
          {Object.keys(groupingOptions).map((key, index) => {
            return (
              <>
                {key !== '' && <div className={styles.groupName}>{key}</div>}
                {groupingOptions[key].map((o, i) => {
                  return (
                    <div
                      tabIndex={`${index}${i}`}
                      onKeyDown={(e) => handleArrowKeys(e, key)}
                      id={`${index}${i}`}
                      ref={optionRefs?.current?.[`${index}${i}`]}
                      className={`${styles.dropOption} checkbox m-0`}
                      onClick={() => onSelectOption(o.value, o.display, key, `${index}${i}`)}
                      key={`${index}${i}`}
                    >
                      <input
                        className="mr-2"
                        type='checkbox'
                        value={o.value}
                        disabled={disabled}
                        checked={selected.filter(s => s.value == o.value || s == o.value).length > 0}
                      />
                      <label className={`checkboxLabel ${clientId}`}>{o.display}</label>
                    </div>
                  )
                })}
              </>
            )
          })}
        </div>
      }
    </div>
  )
}

export default MultiDropDown;