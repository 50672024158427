import React, { useContext } from "react";
import { Container, Card, CardBody, Col } from "reactstrap";
import styles from "./InvoiceConfirmation.module.css";
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { SessionContext } from '../../../src/context/SessionContext';
import { getBrandContent } from '../BrandingWhiteLabel/BrandContent';

export const ConfirmationReview = (props) => {
    const history = useHistory();
    const accountName = typeof props.accountName == "undefined" || props.accountName == null ? '' : props.accountName;
    const claimNumber = typeof props.claimNumber == "undefined" || props.claimNumber == null ? '' : props.claimNumber;
    const { tabDispatch, getCurrentTabAndSubtab } = useContext(SessionContext)
    const { invoiceId } = getCurrentTabAndSubtab().subtab;

    const handleClaimClick = () => {
        history.push('/', {})
        tabDispatch('ADD_SUBTAB', {
            subtabType: 'staticclaiminvoice',
            invoiceId: invoiceId,
            claimNumber: claimNumber
        })
    };

    const getMailId = () => {
        return getBrandContent(accountName.toLowerCase()).programEmail;
    }

    const getPhoneNumber = () => {
        return getBrandContent(accountName.toLowerCase()).programPhone;
    }
    return (
        <Container className={"contentWrapper"}>
            <Card>
                <CardBody>
                    <div  className={classNames(styles.thanksConfirmation, styles.reviewConfirmation )}>
                        <BrandedIcon accountName={accountName} iconType={'review'} />
                    </div>
                    <Col md="10" lg="8" className={styles.invMessage}>
                        <h1 className="h3">Claim in Review</h1>
                        <p>
                            This claim requires further review. Please allow up to 15 business days for
                             our team to review and process your invoice submission. If approved, 
                             you will receive payment via the payment method on file. If delays 
                             occur in processing your payment, all providers are paid on NET 30 Terms. 
                        </p>

                        <p>
                            If you'd like to speak with one of our representatives, call {getPhoneNumber()} or
                            email {getMailId()}.
                        </p>
                        <p>Claim Number: {claimNumber}</p> 
                        <div>
                            <a onClick={handleClaimClick} className={classNames(styles.redirectLink, "btnLink" )}>
                                View Claim Details
                            </a>
                        </div>
                    </Col>
                </CardBody>
            </Card>
        </Container>
    );
}
