import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import styles from "../Contract/ContractInfo.module.css";
import { ReactComponent as DownArrow } from "../../assets/images/arrow-cheveron-down.svg";

export class DropDownInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      selectedValue: '',
      selectedValueText: '',
      dropped: false,
      defaultValue: props.defaultValue,
      isFloatingDropdown: props.isFloatingEnabled
    };

    this.getDropDownValue = this.getDropDownValue.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleDropClick, false);
  }

  componentDidMount() {
    if (typeof this.state.defaultValue !== "undefined") {
      this.setState({
        selectedValue: this.state.defaultValue,
        selectedValueText: this.state.defaultValue === "" ? '' : this.state.options.filter(o => o.data === this.state.defaultValue)?.[0]?.display
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDropClick, false);
  }

  handleDropClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }

    this.setState({ dropped: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.Value && this.props.Value != prevProps.Value) {
      this.setState({
        selectedValue: this.props.Value,
        selectedValueText: this.state.options.filter(s => s.data === this.props.Value)?.[0]?.display
      });
    }
  }

  getDropDownValue = (value, e) => {
    this.setState({
      selectedValue: value,
      selectedValueText: this.state.options.filter(s => s.data === value)?.[0]?.display,
      dropped: false
    }, () => this.props.onChangedValue(value));
  }

  render() {
    return (
      <>
        <div className={this.state.isFloatingDropdown ? "has-float-label" : ""}>
          <div className="dropdown" id="drpdown" ref={node => this.node = node}>
            <button
              className="dropbtn dropbtn-placeholder form-control reportsOtherDropdown"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ dropped: !this.state.dropped })
              }}
            >
              {this.state.selectedValue === '' ? this.props.placeholder : this.state.selectedValueText}
              <DownArrow className="dropbtnIcon" />
            </button>
            <div className="dropdown-content" style={{ display: this.state.dropped ? 'block' : 'none' }}>
              {this.state.options.map((opt, index) => (
                <a key={index}
                  onClick={this.getDropDownValue.bind(this, opt.data)}
                  className={opt.disable === true && styles.pppOptionDisabled}
                >
                  {opt.display}
                </a>
              ))}
            </div>
          </div>
          {this.state.isFloatingDropdown &&
            <label htmlFor="drpdown" >{!!this.state.selectedValue && this.props.placeholder}</label>
          }
        </div>
      </>
    );
  }
}