export const ContractStatusId = {
    Active: 1,
    Inactive: 2,
    Fulfilled: 3,
    Cancelled: 4,
    Deleted: 5,
    Suspended: 6,
    Expired: 7,
    Pending: 8,
    Incomplete: 9,
    WaitingPeriod: 10,
    All: 0,
}