import React, { Component } from "react";
import OrderInfoContext from "./OrderInfoContext"

export class OrderInfoProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            installationType: 'Residential',
            installationDate: "",
            stateId: 0,
            state: "",
            zip: "",
            accountName: this.props.accountName,
            dealerProfile: this.props.dealerProfile,
            setInstallationType: (type) => {
                this.setState({ installationType: type });
            },
            setInstallationDate: (date) => {
                this.setState({ installationDate: date });
            },
            setField: (fieldId, value) => {
                this.setState({ [fieldId]: value });
            }
        }
    }


    render() {
        return (
            <OrderInfoContext.Provider value={this.state}>
                {this.props.children}
            </OrderInfoContext.Provider>
        );
    }
    
} 

export default OrderInfoProvider 