import React, { Component } from "react";
import { FormGroup } from "reactstrap";
import Calendar from 'react-calendar';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg';
import classNames from "classnames";
import styles from './CalendarInput.module.css';
import SimpleReactValidator from 'simple-react-validator';

export default class CalendarInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showCalendar: null,
            date: "",
            disabled: false
        }

        this.validator = new SimpleReactValidator({
            element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
            messages: {
                required: ':attribute is required.',
                min: ':attribute is required.',
            },
            validators: {
                isTrue: {
                    message: ':attribute is required.',
                    rule: (val) => {
                        return val === true;
                    }
                }
            }
        });
    }

    componentDidMount() {
        const { value, disabled } = this.props;
        this.setState({ date: value });
        
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            console.log({ value: nextProps.value })
            this.setState({ date: nextProps.value });
        }
    }

    

    handleCalendarDate = (e) => {
        const { onChange = () => { } } = this.props;

        this.setState({
            date: moment(e).format(this.props.placeHolder),
            showCalendar: null
        });
        onChange && onChange(moment(e).format(this.props.placeHolder));
    }

    openCalendar = () => {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }

    handleCleaveDate = e => {
        const { onChange = () => { } } = this.props;
        let cleaveDate = e.target.value;
        let date = new Date(cleaveDate);
        
        this.setState({ date });
        onChange && onChange(cleaveDate ? moment(cleaveDate).format(this.props.placeHolder) : '');

        if (typeof this.props.updateEditDateScreen != "undefined" && cleaveDate == "") {
            this.props.updateEditDateScreen("date", cleaveDate)
        }

        if (typeof this.props.updateRow != "undefined" && cleaveDate == "") {
            if (this.props.name == "campaignStartDate") {
                let data = [...this.props.selectedRow]
                data[0].beginDate = cleaveDate
                this.props.updateRow(data)
            } else if (this.props.name == "campaignEndDate") {
                let data = [...this.props.selectedRow]
                data[0].endDate = cleaveDate
                this.props.updateRow(data)
            }
        }

        let regex = this.props.placeHolder == "DD/MM/YYYY" ? /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/ : /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;

        if (this.validator.helpers.testRegex(date, regex)) {
            this.openCalendar(null);
        }
    }

    render() {

        const { date, showCalendar } = this.state;

        var momentDate = "";
        var dateFormat = "";
        var cleavePattern = [];
        if (this.props.placeHolder == "DD/MM/YYYY") {
            momentDate = moment(date, "DD/MM/YYYY");
            dateFormat = momentDate.toDate();
            cleavePattern = ['d', 'm', 'Y']
        } else {
            momentDate = moment(date, "MM/DD/YYYY");
            dateFormat = momentDate.toDate();
            cleavePattern = ['m', 'd', 'Y']
        }

        //const tempDate = date && date.length > 0 ? new Date(moment(date).format(this.props.placeHolder)) : new Date();
        const tempDate = dateFormat && dateFormat.length > 0 ? new Date(moment(dateFormat).format(this.props.placeHolder)) : new Date();

        const { label, isRequired = false, id } = this.props;
        const minDate = this.props.minDate;
        const maxDate = this.props.maxDate;
        const placeHolder = this.props.placeHolder;
       
        return (
            <FormGroup className="mb-0">
                {label && (<label for={id} className="form-label">{label}</label>)}
                {(label && isRequired) && (<span aria-hidden="true" className="required-indicator">*</span>)}
                <div className="calendar-input" aria-required="true">
                    <Cleave onFocus={this.openCalendar} name="date" id={id || ''}
                        className={classNames("form-control", styles.dateInput)}
                        value={date || ""}
                        onChange={this.handleCleaveDate}
                        options={{
                            date: true,
                            delimiter: '/',
                            datePattern: cleavePattern
                        }}
                        placeholder={placeHolder} //"MM/DD/YYYY" 
                        disabled={this.disabled}
                    />
                    <CalendarIcon className={classNames("datePicker",styles.datePicker)} name="calendarIcon" onClick={this.openCalendar} />
                </div>
                {showCalendar &&
                    <Calendar name="date" value={tempDate} onChange={this.handleCalendarDate} minDate={minDate} maxDate={maxDate} />
                }
            </FormGroup>
        );
    }
}