import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const InvalidAddress = (props) => {
    const {
        buttonLabel,
        className,
        showInvalidAddressModal
    } = props;

    const [modal, setModal] = useState(showInvalidAddressModal);

    const toggle = () => setModal(!modal);

    const onClose = (e) => {
        setModal(false);
        props.onEditAddress();
    }

    const onContinue = (e) => {
        setModal(false);
        props.onContinueAction("enteredAddress");
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={onClose} className="modal-md">
                <ModalHeader toggle={onClose}>
                    <h4 className="modal-title">Invalid Address</h4>
                </ModalHeader>

                <ModalBody>
                    <p>The address you entered does not appear to be a valid postal address. Please update the street address or ZIP/Postal code.</p>
                </ModalBody>

                <ModalFooter>
                    <div className="btnWrapper">
                        <button className="btnLink" onClick={onContinue}>Continue with Entered Address</button>{' '}
                        <button className="btn-primary" onClick={onClose}>Edit Address</button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default InvalidAddress;