import React, { Component } from 'react';

export class DropDownQueue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options,
            selectedValue: '',
            selectedValueText: '',
            dropped: false,
            defaultValue: props.defaultValue
        };
        this.getDropDownValue = this.getDropDownValue.bind(this);
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleDropClick, false);
    }

    componentDidMount() {
        if (typeof this.state.defaultValue !== "undefined") {
            this.setState({
                selectedValue: this.state.defaultValue,
                selectedValueText: this.state.options.filter(o => o.data === this.state.defaultValue)?.[0]?.display
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDropClick, false);
    }

    handleDropClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }
        this.setState({ dropped: false });
    }

    getDropDownValue = (value, e) => {
        this.setState({
            selectedValue: value,
            selectedValueText: this.state.options.filter(s => s.data === value)?.[0]?.display,
            dropped: false
        }, () => this.props.onChangedValue(value));
    }

    render() {
        return (
            <React.Fragment>
                <div className={`${this.props.className ? this.props.className : ''} dropdown`} ref={node => this.node = node}>
                    <button className="dropbtn dropbtn-placeholder"
                        onClick={(e) => {
                            e.preventDefault(); this.setState({ dropped: !this.state.dropped })
                        }}
                    >{this.state.selectedValue === ''
                        ? this.props.placeholder
                        : this.state.selectedValueText}</button>
                    <div className="dropdown-content"
                        style={{ display: this.state.dropped ? 'block' : 'none' }}>
                        {this.state.options.map((opt, index) => (
                            <a key={index} onClick={this.getDropDownValue.bind(this, opt.data)}>{opt.display}</a>
                        ))}
                    </div>
                </div>
                { /* <label htmlFor="" className="form-label">{this.placeholder}</label> */}
            </React.Fragment>
        );
    }
}