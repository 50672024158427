import React, { Component } from "react";
import {
  Container, Card, CardBody,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { ReactComponent as PrintSVG } from '../../assets/images/printer.svg';
import classNames from "classnames";
import styles from "./SaveSaleConfirmation.module.css";
import moment from 'moment';
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon'
let tableResponsiveLg = "table-responsive-lg";
let printBtn = styles.printBtn;
let homeBtn = styles.homeBtn;

export class SaveSaleConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false
    }
  }

  handleAnotherSale = () => {
    this.props.goToTab(1)
    this.props.setSavedSaleId(null);
  }

  getLastName = () => {
    return this.props.customerLastName == "" ? this.props.customerInfo[0].customerLastName : this.props.customerLastName
  }

  render() {
    const saveSaleColumns = [
      {
        dataField: "lastName",
        text: "Last Name",
        classes: styles.columnLastName,
        //   headerClasses: styles.columnHeader,
      },
      {
        dataField: "dateTime",
        text: "Date & Time",
        // classes: styles.columnDate,
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "orderTotal",
        text: "Order total",
        // classes: styles.columnCNumber,
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "installDate",
        text: "Install Date",
        // classes: styles.size,
        // headerClasses: styles.columnHeader,
      },
    ];

    const saveSaleData = [
      {
        lastName: (<a onClick={() => this.props.goToTab(this.props.activeTab)}>{this.props.customerLastName}</a>),
        dateTime: this.props.isCanadaState ? moment(new Date()).format("DD/MM/YYYY hh:mm A") : moment(new Date()).format("MM/DD/YYYY hh:mm A"),
        orderTotal: this.props.planPrice == "" ? "" : "$" + this.props.planPrice,
        installDate: this.props.installationDate == "Invalid date" ? "" : this.props.installationDate,
      },
    ];

    return (
      <Container className="contentWrapper">
        <Card>
          <CardBody>
            <div className={styles.printConfirm}>
              <button className={classNames("btn-secondary", printBtn)} onClick={() => window.print()}>
                <span className={styles.regularP}>
                  <PrintSVG height="20" width="20" />
                </span>
                Print
                </button>
            </div>
            <div className={styles.saveSaleConfirmation}>
              <span className={styles.succuessIcon}>
                <BrandedIcon accountName={this.props.accountName} iconType={'successful'} />
              </span>
              <p>Your Incomplete Sale Has Been Saved</p>
              <p>Resume sale by searching or clicking “Incomplete Sale” within your dashboard.</p>
            </div>
            <div className={styles.finalContractsTable}>
              <BootstrapTable
                keyField="dealerPO"
                data={saveSaleData}
                columns={saveSaleColumns}
                bordered={false}
                bootstrap4
                wrapperClasses={tableResponsiveLg}
              />
            </div>
          </CardBody>
        </Card>
        <div className="d-md-flex justify-content-between">
          <div className={styles.anotherSaleBtn}>
            <button className="btn-secondary" onClick={() => this.props.handleBackToOrderInfo(false)} >Enter Another Sale</button>
            <button className="btn-secondary" onClick={() => this.handleAnotherSale()} >Enter Another Sale For This Customer</button>
          </div>
          <button className={classNames("btn-primary", homeBtn)} onClick={() => this.props.handleModalReturnHome()}>Return To Sales Home</button>
        </div>
      </Container>
    );
  }
}