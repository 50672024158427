import React, { Component } from "react";
import { Card, CardBody, FormGroup, Container, Col } from "reactstrap";
import styles from "./ContactUs.module.css";
import { SessionContext } from "../../context/SessionContext";
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon'
import { dataLayerPush } from '../../components/Global/helpers'
import { brandContent } from '../../components/BrandingWhiteLabel/BrandContent';
import { stripPhoneNumber, phoneFormat } from '../../components/shared/util';
import classNames from "classnames";
export class ContactUsConfirmation extends Component {
    state = {
        accountName: 'aig'
    }

    componentDidMount() {
        dataLayerPush('Pageview', {
            pageTitle: "Contact Confirmation",
            pagePath: "/confirmation/contact"
        })
        this.getAccountName();
    }

    getAccountName = () => {
        fetch('helper/getaccountbyurl')
            .then(res => res.json())
            .then(data => {
                this.setState({ accountName: data.accountName });
            })
    }

    render() {
        const { accountName } = this.state  

        return (
            <div className={classNames("mb-5",styles.contactUsConfirmation)} >
                <BrandedIcon accountName={accountName} iconType={'contact'} />
                <p>We’ll Contact You Soon</p>
                <p>We will contact you within 1 business day about your request.</p>
                <p>
                    If you’d like to speak with one of our representatives, call&nbsp;
                        <a href={`tel:${stripPhoneNumber(brandContent[accountName.toLowerCase()].programPhone)}`}
                        className='callLink pl-0'>{(brandContent[accountName.toLowerCase()].programPhone)}</a>.
                </p>
            </div>
        );
    }
}

ContactUsConfirmation.contextType = SessionContext;