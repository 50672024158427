import React, { useContext, useState, useEffect } from 'react';
import Cleave from 'cleave.js/react'
import moment from 'moment';
import styles from './DateField.module.css';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg'
import Calendar from 'react-calendar'
// import { useMediaQuery } from 'react-responsive'; // Commenting this line because have seen date picker issue used in mobile screens 
// So commenting above line will result in, Same date picker will be used in all screens.


const DateField = ({
    onDateChange = () => { },
    name,
    showError,
    minDate,
    calendarMargin = true,
    required = false,
    maxDate,
    value,
    emptyDate = false,
    disabled = false,
    contract
}) => {
    
    const [selectedDate, setSelectedDate] = useState();
    const [showCalendar, setShowCalendar] = useState(false);
    const [tempDate, setTempDate] = useState(value);

    const isMobile = true;
    const usaDateFormat = { string: "MM/DD/YYYY", pattern: ['m', 'd', 'Y'] }
    const foreignDateFormat = { string: "DD/MM/YYYY", pattern: ['d', 'm', 'Y'] }
    const [dateFormat, setDateFormat] = useState(usaDateFormat);

    useEffect(() => {
        const isUSA = contract?.contract?.country === 'US';
        const dateFormat = isUSA ? usaDateFormat : foreignDateFormat;
        setDateFormat(dateFormat);
    }, [contract])

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar)
    }

    const handleCalendarDate = (e) => {
        const date = moment(e).format(dateFormat.string);
        onDateChange(date);
        setTempDate(date); //for display in Cleave field only
        setSelectedDate(e)
        setShowCalendar(false)
    }

    const handleCleaveDate = e => {
        let cleaveDate = e.target.value;
        let date = new Date(cleaveDate);
        const momentCleaveDate = moment(cleaveDate).format(dateFormat.string)
        onDateChange(momentCleaveDate);
        if (!isNaN(date.getTime())) {
            setTempDate(momentCleaveDate);
            setSelectedDate(date)
        }
        else {
            setTempDate(momentCleaveDate);
            onDateChange(momentCleaveDate);
        }

        const dateRegex = new RegExp(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/)
        if (dateRegex.test(cleaveDate)) {
            setShowCalendar(false);
        }
    }

    //Prevent empty field if input is cleared and minDate is selected from Calendar
    useEffect(() => {
        if (!emptyDate) {
            if (!tempDate && minDate && !emptyDate) {
                setTempDate(minDate);
                setSelectedDate(minDate);
            }
        }
    }, [])

    useEffect(() => {
        setTempDate(value);
    }, [value])

    return (
        <div className={`datePickerField ${styles.inputWrap} ${disabled && styles.disabledDateField}`}>
            {isMobile ? (
                <>
                    <Cleave
                        onFocus={() => setShowCalendar(true)}
                        className={styles.dateInput}
                        name={name}
                        id={`${name}Input`}
                        placeholder={dateFormat.string.toLowerCase()}
                        value={tempDate}
                        onChange={handleCleaveDate}
                        options={{
                            date: true,
                            delimiter: '/',
                            datePattern: contract?.contract?.country == 'US' ? ['m', 'd', 'Y'] : ['d', 'm', 'Y'],
                            dateMax: moment(maxDate).format('YYYY-MM-DD')
                        }}
                        disabled={disabled}
                    />
                    <CalendarIcon className={`ml-3 ${styles.calendarIcon} ${disabled && styles.iconDisabled}`} onClick={!disabled && toggleCalendar} />
                    {showCalendar && (
                        <Calendar
                            className={`${styles.calendar} ${!calendarMargin && 'mt-0'}`}
                            name={`${name} calendar`}
                            autocomplete="off"
                            value={selectedDate}
                            onChange={handleCalendarDate}
                            maxDate={maxDate || new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                            minDate={minDate || new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                        />
                    )}
                </>
            ) : (
                <input
                    type="date"
                    className={'form-control'}
                    defaultValue=""
                    name={name}
                    id={`${name}Input`}
                    value={tempDate}
                    onChange={handleCleaveDate}
                    required={required}
                    max={maxDate || moment().format('YYYY-MM-DD')}
                    min={minDate || moment(new Date().setFullYear(new Date().getFullYear() - 1)).format('YYYY-MM-DD')}
                />
            )}
            {showError && (
                <p className='error-msg'>
                    Select a date to continue
                </p>
            )}
        </div>
    )
}

export default DateField;