import React, { useState, useEffect } from "react";
import Dropzone from '../../UploadDocs/DragandDropFileUpload';
import { Modal, ModalBody, ModalFooter, ModalHeader, Progress, FormGroup, Label, Row } from 'reactstrap';
import styles from '../RequestProductUpdate/UpdateRequest.module.css';
import { ReactComponent as Paper } from '../../../assets/images/paper.svg';
import NotesField from "../../shared/NotesField/NotesField";
import { DropDownAutoComplete } from "../../inputs/DropDownAutoComplete";
import { useForm, Controller } from "react-hook-form";
import { formatBytes } from "../../InvoiceTables/lib/InvoiceTableUtils";

export const UploadServiceOrderModal = ({ contract, formProps, isOpen, onViewRepairHours, centered, onEdit, ...props }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [fileUploadPercent, setFileUploadPercent] = useState();
    const [invalidDoc, setInvalidDoc] = useState(false)
    const [fileName, setFileName] = useState(null);
    const [file, setFile] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [btnDisable, setBtnDisable] = useState(true);
    const [documentTypeOptions, setDocumentTypeOptions] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [errors, setErrors] = useState({
        selectedDocument: false,
        fileError: false
    })
    
    const { control, register, setValue, trigger, watch, handleSubmit } = useForm({
        defaultValues: {
            selectedDocumentType: '',
            notes: ''
        }
    });

    const notes = watch('notes');

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch(`document/GetValidSubmitDocumentList/hvac`);
            const resultData = await result.json();
            const filteredOptions = resultData?.map(e => ({ display: e.documentTypeName, data: e.documentTypeId }))?.sort((a, b) => a?.display?.localeCompare(b?.display));
            setDocumentTypeOptions(filteredOptions);
        };
        fetchData();
    }, []);

    const checkTotalBytes = (currentFile) => {
        if (currentFile.length > 1) {
            // handle multiple files at once
            var totalPerFile = 0
            for (var i = 0; i < currentFile.length; i++) {
                var filesByte = currentFile[i].size
                for (var ii = 0; ii < file.length; ii++) {
                    totalPerFile = totalPerFile + file[ii].size;
                }
                totalPerFile = totalPerFile + filesByte;
            }

            if (totalPerFile <= 4194304) {
                return true
            } else {
                return false
            }

        } else {
            var fileByte = currentFile[0].size
            var total = 0
            for (var index = 0; index < file.length; index++) {
                total = total + file[index].size;
            }
            total = total + fileByte;

            if (total <= 4194304) {
                return true
            } else {
                return false
            }
        }
    }

    const handleRemoveFile = (row) => {
        setFile(file.filter((_, i) => i !== row));

        const cnt = file.filter((_, i) => i !== row)
        if (cnt.length == 0) {
            setInvalidDoc(false)
            setBtnDisable(true);
        }
    }

    const documentNameChange = (val) => {
        const document = documentTypeOptions.find(b => b.data === val);
        setSelectedDocumentType(document)
        setErrors({
            ...errors,
            selectedDocument: false
        })
    }

    const handleFileUpload = (fileData) => {
        if (fileData && fileData.length > 0) {
            if (checkTotalBytes(fileData) == false) {
                setInvalidDoc(true);
            } else {
                const currentFile = fileData[0];
                setFileName(currentFile.name);

                setFile(file.concat(fileData));

                const reader = new FileReader();

                reader.addEventListener("load", () => {
                    setSelectedDoc(reader.result);
                    setIsButtonDisabled(false);
                }, false)
                reader.addEventListener("abort", (ev) => {
                }, false)
                reader.addEventListener("error", (ev) => {
                }, false)
                reader.addEventListener("progress", (event) => {
                    setFileUploadPercent(
                        Math.round((event.total / event.loaded) * 100)
                    );
                })
                reader.readAsDataURL(currentFile);
                setInvalidDoc(false);
                setBtnDisable(false);
                setErrors({
                    ...errors,
                    fileError: false
                })
            }
        }
        else {
            //alert("The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf)");
            setInvalidDoc(true);
        }
    }

    const onUpload = (e) => {
        e.preventDefault();
        if (!selectedDocumentType) {
            setErrors({
                ...errors,
                selectedDocument: true
            })
            return;
        }
        if (file.length <= 0) {
            setErrors({
                ...errors,
                fileError: true
            })
            return;
        }
        let clientId = 'hvac';
        const formData = new FormData();

        const data = file.reduce((acc, val, i) => {
            const obj = {
                file: val,
                dateEntered: Date.now(),
                documentType: selectedDocumentType,
                documentName: val?.name,
                size: formatBytes(val?.size ?? 0),
                typeOfDocument: selectedDocumentType?.display,
                notes,
                partyId: contract?.partyId,
                selectedDoc
            };
            acc = [...acc, obj]
            return acc;
        }, []);

        for (var index = 0; index < file.length; index++) {
            formData.append('File', file[index]);
            formData.append('DocumentTypeId', selectedDocumentType);
            formData.append('Notes', notes);
            formData.append('PartyId', contract.partyId);
        }
        props.handleFileUpload(data);
        setFile([]);
        setValue('notes', '');
    }

    return (
        <Modal isOpen={isOpen} className='modal-md'>
            <ModalHeader>
                <h4 className='modalTitle mb-0'>
                    Upload Document
                </h4>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <FormGroup className={`col-6 ${errors.selectedDocument ? 'form-error' : ''}`}>
                        <Label htmlFor="mailingState" className="form-label">Select Type of Document</Label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <DropDownAutoComplete
                            name={'documentType'}
                            id="documentType"
                            options={documentTypeOptions}
                            defaultValue={selectedDocumentType}
                            onChangedValue={documentNameChange}
                        />
                        {errors.selectedDocument && <p className="errorMsg">The type of document is required.</p>}
                    </FormGroup>
                </Row>
                <Label htmlFor="documentUpload" className="form-label">Please select a document to upload.</Label>
                <Dropzone onFileUpload={handleFileUpload} />
                {isButtonDisabled == false && file.map((f, key) => (
                    <div className={styles.uploadProgress}>
                        <Paper className={styles.file} />
                        <div className={styles.progressWrapper}>
                            <div key={key} id={key} className={styles.fileName}>{f.name}</div>
                            <div className={styles.uploadProgressWrapper}>
                                <Progress className={styles.progress} value={fileUploadPercent} />
                                <button id={"remove-btn" + key} onClick={() => handleRemoveFile(key)} aria-label="Cancel Uploaded File" className={styles.closeButton}></button>
                            </div>
                            <div className={styles.uploadPercentage}>upload <span>{fileUploadPercent}% completed</span></div>
                        </div>
                    </div>
                ))}
                {errors.fileError && <p className="errorMsg">Please upload the document.</p>}
                {invalidDoc &&
                    <p className="errorMsg">The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf).</p>
                }
                <Controller
                    control={control}
                    defaultValue={""}
                    name="notes"
                    render={({ field, fieldState, formState }) => (
                        <FormGroup>
                            <NotesField
                                label="Additional Notes"
                                autoHeight={true}
                                name="notes"
                                placeholder={"Enter Description"}
                                maxChars={500}
                                onChange={(value) => {
                                    setValue('notes', value);
                                    trigger('notes');
                                }
                                }
                                value={notes}
                            />
                        </FormGroup>
                    )}
                />
            </ModalBody>
            <ModalFooter>
                <button
                    className='btnLink'
                    type='button'
                    onClick={() => {
                        setFile([]);
                        onEdit();
                    }}
                >
                    Cancel
                </button>
                <button
                    className='btn-primary'
                    onClick={onUpload}
                >
                    Upload
                </button>
            </ModalFooter>
        </Modal>
    );
};
