import React, {
  useReducer,
  createContext,
  Dispatch,
  PropsWithChildren,
} from 'react';



const initialState = {
  type: 'contracts',
  sortData: { sortField: 'installationDate', sortOrder: 'desc' },
  contractStatusType: null
};

export const ReportContext = createContext([
  initialState,
  () => { },
]);

export function reviewReducer(state, action) {
  switch (action.type) {

    case 'SET_STARTDATE':
      return { ...state, startDate: action.payload || '' };
    case 'SET_ENDDATE':
      return { ...state, endDate: action.payload || '' };
    case 'SET_TYPE':
      return { ...state, type: action.payload || '', contractStatusType: null };
    case 'SET_TABLEDATA':
      return { ...state, data: action.payload || [], totalContracts: action.totalContracts || 0, contractStatusType: action.contractStatusType || null };
    case 'SET_TABLECOLUMNS':
      return { ...state, columns: action.payload || [] };
    case 'SET_ORDER':
      return {
        ...state,
        sortData: action.sortData || { sortData: { sortField: 'installationDate', sortOrder: 'desc' } },
        contractStatusType: action.contractStatusType
      };

    case 'RESET':
      return { ...initialState };
    default:
      return state;
  }
}

export const ReportContextProvider = (props) => {
  if (props.reportType != null)
    initialState.type = props.reportType;

  //initialState.type = 'paymenthistory';
  const [state, dispatch] = useReducer(reviewReducer, initialState);

  return (
    <ReportContext.Provider value={[state, dispatch]}>
      {props.children}
    </ReportContext.Provider>
  );
};
