import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card, CardBody, Row, Col, Alert, FormGroup, CardHeader, Label, Container } from 'reactstrap';
import { AdjudicationContext } from '../Authorizations/util/store/context';
import PartsTable from '../Authorizations/Tables/PartsTable';
import LaborTable from '../Authorizations/Tables/LaborTable';
import MileageTable from '../Authorizations/Tables/MileageTable';
import TaxTable from '../Authorizations/Tables/TaxTable';
import { fakeData } from '../Authorizations/util/fakedata';
import EditButton from "../shared/EditButton/EditButton";
import styles from '../Authorizations/Adjudication.module.css'
import { DropDownAutoComplete } from "../inputs/DropDownAutoComplete";
import EventTotalsRow from '../Authorizations/Tables/EventTotalsRow';
import CalendarInput from "../shared/CalendarInput/CalendarInput";
import ExpandableTextarea from "../shared/ExpandableTextArea/ExpandableTextarea";
import { CancelEntry } from "../Modals/CancelAuthorizationModal/CancelEntry";
import InfoTooltip from "../shared/InfoTooltip/InfoTooltip";
import { newEntry } from '../Authorizations/util/util';
import moment from 'moment';
import { SessionContext } from '../../context/SessionContext';
import SimpleReactValidator from 'simple-react-validator';
import { Redirect, useHistory } from 'react-router-dom';
import PageLoading from "../shared/PageLoading/PageLoading";
import { historyPush } from '../../components/Global/helpers.jsx'

const AdjudicationDisplay = (props) => {
    const [cancelAuthModal, setCancelAuthModal] = useState(false);
    const [saveAuthorizationForm, setSaveAuthorizationForm] = useState(false);
    const [eventTotalZero, setEventTotalZero] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [saveInvoiceForm, setSaveInvoiceForm] = useState(false);
    const [showAlert, setshowAlert] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');
    const [eventId, setEventId] = useState(0);
    const [featureClaimId, setFeatureClaimId] = useState(0);
    const [notes, setNotes] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const { state, dispatch } = useContext(AdjudicationContext);
    const [featureClaim, setFeatureClaim] = useState(null);
    const [attributes, setAttributes] = useState(null);
    const [saved, setSaved] = useState(false);
    const [validationFired, setValidationFired] = useState(false);
    const [eventTypeId, setEventTypeId] = useState();
    var sessionContext = useContext(SessionContext);
    const history = useHistory();
    const [brandOptions, setBrandOptions] = useState([]);
    const [preauthorizedAmountTotal, setPreauthorizedAmountTotal] = useState(0);
    const [editItems, setEditItems] = useState(false);
    const [tripType, setTripType] = useState(null);
    const [details, setDetails] = useState({
        invoiceNumber: '',
        productBrand: '',
        productSerialNumber: '',
        productModelNumber: '',
        serviceDate: '',
        invoiceSentDate: '',
        onSiteDiagnostic: '',
        resolution: '',
        additionalDetails: '',
        servicerId: '',
        eventNumber: props.eventNumber,
        productBrandValue: null,
        isTechieBeenOnsite: true
    });

    const {
        tax,
        totals: {
            final: {
                authorized,
                requested
            }
        }
    } = state;

    const showEventTotalError = () => {
        if (requested <= 0) {
            setEventTotalZero(true);
            return;
        }
        setEventTotalZero(false);
    }

    const handleClick = (e) => {
        if (e.currentTarget.outerText == "Continue") {
            setEditItems(false);
            if (!validator.current.allValid()) {
                const errorFields = [];
                setSaveAuthorizationForm(false);
                setValidationFired(!validationFired);
                showEventTotalError();
                validator.current.showMessages();

                // To get the focus when validation error occur such that, error fields visible in viewport
                for (let key in validator.current.fields) {
                    if (validator.current.fields[key] === false) {
                        errorFields.push(key);
                    }
                }

                let errorElement = document.getElementById(errorFields[0]) || document.getElementsByName(errorFields[0])[0]
                if (errorElement) {
                    errorElement.focus();
                }
                return;
            }
            else {
                const { labor, parts, preApprovedAmount, totals, mileage, tax } = state;
                showEventTotalError();
                //dispatch({
                //    type: 'INITIATE_DATA',
                //    payload: {
                //        preapprovedAmount: preApprovedAmount,
                //        labor: labor,
                //        parts: parts,
                //        mileage: mileage,
                //        // WIREUP: taxRate will come at a later date when we use a tax service
                //        taxRate: tax,

                //        isReadOnly: true
                //    }
                //});
                dispatch({
                    type: 'TOGGLE_READ_ONLY_MODE',
                    payload: {
                        isTableReadOnly: true
                    }
                });
                setSaveAuthorizationForm(true);
            }
        }
        else {
            const { labor, parts, preApprovedAmount, totals, mileage, tax } = state;
            setSaveAuthorizationForm(true);
            //Invoke Rules once it is completed as a part of SEU-3283
            
            let authorizationData = {
                clientId: props.clientId,
                claimId: props.claimId,
                eventId: parseInt(props.eventId),
                assetId: props.rfsData?.assetId,
                contractId: props.rfsData?.contractId,
                laborTotal: totals?.labor?.requested,
                partsTotal: totals?.parts?.requested,
                milageTotal: totals?.mileage?.requested,
                taxTotal: totals?.tax?.authorized,
                assetRepairLimit: props.rfsData?.tcfAmount
            };
            fetch(`authorization/executeAuthorizationRules/${props.clientId}`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(authorizationData)
            }).then(res => {
                if (res.ok) {
                    res.json().then(rulesetResult => {
                        console.log(rulesetResult);
                        if (rulesetResult?.success) {
                            authorizeClaimEvent("Approved");
                        }
                        else {
                            authorizeClaimEvent("Requested");
                        }
                    });
                    //navigateToConfirmation();
                }
            });
        }
    };
    const handleClose = () => {
        historyPush(props?.rfsData, "requestforservice", history, props?.clientId, null, true);
    }

    const brandNameChange = (val) => {
        var brand = brandOptions.filter(b => b.data === val);
        setDetails({
            ...details,
            productBrand: brand[0].display,
            productBrandValue: brand[0].data ? brand[0].data.toString() : '0'
        })
    }
    const additionalDetailsChange = (e) => {
        setDetails({
            ...details,
            additionalDetails: e.target.value,
        })
    }
    const invoiceNumberChange = (e) => {
        console.log(e.target.value);
        setDetails({
            ...details,
            invoiceNumber: e.target.value,
        })
    }

    const serialNumberChange = (e) => {
        setDetails({
            ...details,
            productSerialNumber: e.target.value,
        })
    }
    const modelNumberChange = (e) => {
        setDetails({
            ...details,
            productModelNumber: e.target.value,
        })
    }
    const onSiteDiagnosticChange = (e) => {
        console.log('test');
        setDetails({
            ...details,
            onSiteDiagnostic: e.target.value,
        })
    }
    const resolutionChange = (e) => {
        setDetails({
            ...details,
            resolution: e.target.value,
        })
    }
    const setServiceCompletionDate = (e) => {
        setDetails({
            ...details,
            serviceDate: e,
        })

    }
    const setInvoicetDate = (e) => {
        setDetails({
            ...details,
            invoiceSentDate: e,
        })

    }


    const radioChange = (e) => {
        setDetails({
            ...details,
            isTechieBeenOnsite: e.target.value == "true" ? true : false,
        })
    }

    const validator = useRef(new SimpleReactValidator({
        element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
        messages: {
            required: ':attribute is required.'
        }
    }));

    const toggleEditable = () => {
        setSaveAuthorizationForm(false);
        setEditItems(true);
        dispatch({
            type: "TOGGLE_READ_ONLY_MODE",
            payload: {
                isTableReadOnly: false,
            }
        })
    }

    useEffect(() => {
        const { tripTypes } = state;
        const fetchData = async () => {
            const result = await fetch(`Brand/getAll`);
            const resultData = await result.json();
            const filteredOptions = resultData.map(e => ({ display: e.name, data: e.brandId }));
            setBrandOptions(filteredOptions);
        };
        fetchData();
        if (!saveAuthorizationForm && !editItems) {

        setPreauthorizedAmountTotal(props.preauthorizedAmount);

        fetch(`authorization/GetAuthorizationVendorDetailsByEventNumber/${props.eventNumber}/${props.clientId}`)
            .then(res => res.json())
            .then(data => {
                // set values
                setEventId(data.eventId);
                setFeatureClaimId(data.featureClaimId);
                setDetails({
                    ...details,
                    servicerId: data.servicerId.toString()
                })
                setSaved(false);
                setShowSpinner(false);
                let preApprovedAmount = data.authorizationDisplays?.reduce((accum, item) => accum + item.preAuthorizedAmount, 0);
                let claimAuthPartDetails = data.authorizationDisplays?.filter(c => c.authorizationType == "Parts");
                let claimAuthLaborDetails = data.authorizationDisplays?.filter(c => c.authorizationType == "Labor" || c.authorizationType == "Diagnosis");
                let claimAuthMileageDetails = data.authorizationDisplays?.filter(c => c.authorizationType == "Mileage");
                let claimTaxDetails = data.authorizationDisplays?.filter(c => c.authorizationType == "Tax");
                let rateModels = data.authorizationRateModels?.filter(c => c.billingCodeId !== 22 && c.billingCodeId !== 24).map(e => ({
                    display: e.billingAttributeDescription + ' ($' + e.amount + ')',
                    data: e.billingAttributeId,
                    rate: e.amount,
                    servicerRateId: e.servicerRateId,
                    billingCodeId: e.billingCodeId,
                    billingCategoryId: e.billingCategoryId,
                    billingCategoryDescription: e.billingCategoryDescription
                }));
                let coverageModels = data.coverageModels.map(e => ({
                    display: e.coverageName,
                    data: e.coverageId
                }));
                //let assets = data.assetModels.map(e => { display: e.assetName, data: e.assetName });
                let assets = [];

                for (var index = 0; index < data?.parts?.length; index++) {
                    if (data.parts[index].attributeName != null)
                        assets.push({ display: data.parts[index].attributeName, data: data.parts[index].attributeName })
                }

                if (rateModels == 'undefined' || rateModels == null) {
                    rateModels = [];
                }
                console.log(rateModels);

                    let parts = claimAuthPartDetails != null && claimAuthPartDetails.length != 0 ? claimAuthPartDetails.map(e => ({
                        id: e.authorizationId,
                        qty: e.qty,
                        unitPrice: e.unitPrice,
                        // table-specific
                        details: e.details == null ? [] : e.details.split(","),
                        partNumber: e.partNumber,
                        description: e.description,
                        coverage: coverageModels.find(c => c.data == e.coverageId),
                        billingCodeId: e.billingCodeId == null || e.billingCodeId == 0 ? 9 : e.billingCodeId,
                        billingCategoryId: e.billingCategoryId,
                        eventId: e.eventId,
                        authorized: e?.authorizedAmount ?? 0,
                        requested: e?.requestedAmount ?? 0,
                        featureClaimId: e.featureClaimId,
                        createdBy: e.createdBy
                    })) : [
                        //minimum necessary data
                        {
                            id: -62,
                            qty: 1,
                            unitPrice: 0,
                            details: [],
                            partNumber: "",
                            description: "",
                            coverage: "",
                            authorized: 0,
                            requested: 0,
                            billingCodeId: 9
                        }, {
                            id: -63,
                            qty: 1,
                            unitPrice: 0,
                            details: [],
                            partNumber: "",
                            description: "",
                            coverage: "",
                            authorized: 0,
                            requested: 0,
                            billingCodeId: 9
                        }, {
                            id: -64,
                            qty: 1,
                            unitPrice: 0,
                            details: [],
                            partNumber: "",
                            description: "",
                            coverage: "",
                            authorized: 0,
                            requested: 0,
                            billingCodeId: 9
                        }, {
                            id: -65,
                            qty: 1,
                            unitPrice: 0,
                            details: [],
                            partNumber: "",
                            description: "",
                            coverage: "",
                            authorized: 0,
                            requested: 0,
                            billingCodeId: 9
                        }];

                    let labor = claimAuthLaborDetails != null && claimAuthLaborDetails.length != 0 ? claimAuthLaborDetails.map(e => ({
                        id: e.authorizationId,
                        qty: e.qty,
                        unitPrice: e.unitPrice,
                        authorized: e?.authorizedAmount ?? 0,
                        requested: e?.requestedAmount ?? 0,
                        // table-specific
                        rateType: rateModels.find(r => r.billingCodeId == e.billingCodeId) || [],
                        coverage: coverageModels.find(c => c.data == e.coverageId),
                        billingCodeId: e.billingCodeId,
                        billingCategoryId: e.billingCategoryId,
                        eventId: e.eventId,
                        featureClaimId: e.featureClaimId,
                        eventTypeId: e.eventTypeId,
                        createdBy: e.createdBy
                    })) : [];

                setEventTypeId(labor?.[0]?.eventTypeId);

                let laborTaxAuthorization = data?.authorizationModels?.find(am => am.billingCodeModel?.billingAttributeModel?.billingAttributeDescription == "Labor Tax");
                let partsTaxAuthorization = data?.authorizationModels?.find(am => am.billingCodeModel?.billingAttributeModel?.billingAttributeDescription == "Parts Tax");

                let laborTax = claimTaxDetails?.find(lt => lt.authorizationId == laborTaxAuthorization?.authorizationId);
                let partsTax = claimTaxDetails?.find(lt => lt.authorizationId == partsTaxAuthorization?.authorizationId);

                    let taxes = [
                        {
                            id: laborTax?.authorizationId ?? 0,
                            taxType: 'Labor Tax', // need to pull the right type
                            billingCodeId: laborTax?.billingCodeId ?? 32, /*PR-SEU - 3219*/
                            requested: laborTax?.requestedAmount ?? 0,
                            qty: laborTax?.qty ?? 1,
                            unitPrice: laborTax?.unitPrice ?? 0,
                            authorized: laborTax?.authorizedAmount ?? 0,
                            authEdited: false,
                            
                        },
                        {
                            id: partsTax?.authorizationId ?? 0,
                            taxType: 'Parts Tax', // need to pull the right type
                            billingCodeId: partsTax?.billingCodeId ?? 33, /*PR-SEU - 3219*/
                            requested: partsTax?.requestedAmount ?? 0,
                            qty: partsTax?.qty ?? 1,
                            unitPrice: partsTax?.unitPrice ?? 0,
                            authorized: partsTax?.authorizedAmount ?? 0,
                            authEdited: false,
                        },
                    ];

                    let mileage = claimAuthMileageDetails != null && claimAuthMileageDetails.length != 0 ? claimAuthMileageDetails.map(e => ({
                        //minimum necessary data
                        id: e.authorizationId,
                        qty: e.qty,
                        unitPrice: e.unitPrice,
                        authorized: e?.authorizedAmount ?? 0,
                        requested: e?.requestedAmount ?? 0,
                        //table-specific
                        //tripType: (e.billingCodeId == null || e.billingCodeId == 24) ? "oneWay" : "roundTrip", /*(22 - RoundTrip)*/
                        tripType: tripTypeSelection(e.billingCodeId),
                        billingCodeId: e.billingCodeId,
                        billingCategoryId: e.billingCategoryId,
                        coverage: coverageModels.find(c => c.data == e.coverageId),
                        eventId: e.eventId,
                        featureClaimId: e.featureClaimId,
                        createdBy: e.createdBy
                    })) : [{
                        //minimum necessary data
                        id: 0,
                        qty: 0, //miles
                        unitPrice: data?.roundTripRate && data?.roundTripRate > 0 ? data?.roundTripRate : 0.75,
                        //table-specific
                        tripType: { display: 'Round Trip', data: 'roundTrip' },
                        billingCodeId: 0
                    }];

                let rateValues = {
                    roundTripRate: data?.roundTripRate && data?.roundTripRate > 0 ? data?.roundTripRate : 0.75,
                    onewayTripRate: data?.onewayTripRate && data?.onewayTripRate > 0 ? data?.onewayTripRate : 0.75,
                    servicerRadius: data?.radius
                };


                const fourRequiredPartsRowsToStart = () => {
                    const parts = [...fakeData.parts]
                    const diff = 4 - parts.length
                    if (diff > 0) {
                        for (let i = 1; i <= diff; i++) {
                            parts.push(newEntry('parts'))
                        }
                    }
                    return [...parts]
                }

                dispatch({
                    type: 'INITIATE_DATA',
                    payload: {
                        preapprovedAmount: preApprovedAmount,
                        negotiatedRateTypes: rateModels,
                        coverages: data.coverageModels.map(e => ({
                            display: e.coverageName,
                            data: e.coverageId
                        })),
                        labor: labor,
                        parts: parts,
                        mileage: mileage,
                        assets: assets,
                        // WIREUP: taxRate will come at a later date when we use a tax service
                        taxRate: taxes,
                        tax: taxes,
                        preapprovedAmount: props.preauthorizedAmount,
                        rateValues: rateValues
                    }
                })
                setTimeout(() => setShowSpinner(false), 1000)
            });
        }
        window.scrollTo(0, 0)

    }, [saved, saveAuthorizationForm]);

    useEffect(() => {
        if (requested > 0) {
            setEventTotalZero(false);
        }
    }, [requested])

    useEffect(() => {
        for (let mlIndex in state.mileage) {
            let qty = state.mileage[mlIndex].qty;
            let tripTypedata = state.mileage[mlIndex].tripType
            if ((tripTypedata?.data === 'oneWay' && qty < 30) || (tripTypedata?.data === 'roundTrip' && qty < 60)) {
                setTripType(tripTypedata)
                return;
            }
        }
    }, [state?.mileage])

    const tripTypeSelection = (val) => {
        let tripval = '';
        if (val == 24)
            return { display: 'One Way', data: 'oneWay' };
        else if (val == 22)
            return { display: 'Round Trip', data: 'roundTrip' };
        else if (val == 47)
            return { display: 'Per Mile', data: 'perMile' };
        else if (val == 46)
            return { display: 'Flat Rate', data: 'flatRate' };
        else if (val == 45)
            return { display: 'Trip', data: 'trip' };
        else
            return { display: 'Round Trip', data: 'roundTrip' };
    }

    const authorizeClaimEvent = (status) => {
        const { labor, parts, preapprovedAmount, totals, mileage, tax } = state; /*PR-SEU - 3219*/
        let claimAuthorizeRequest = [];


        for (var index = 0; index < labor.length; index++) {
            let laborAuthorizedAmount = props.rfsData.authorizationModel.filter(e => e.billingCodeId == labor[index].billingCodeId).length > 0 ? props.rfsData.authorizationModel.filter(e => e.billingCodeId == labor[index].billingCodeId)[0].preauthorizedAmount : 0
            claimAuthorizeRequest.push({
                authorizationId: parseInt(labor[index].id) > 0 ? parseInt(labor[index].id) : 0,
                authorizationType: "Labor",
                authorizationStatus: status,
                rateId: labor[index]?.rateType?.servicerRateId??0,
                rate: parseInt(labor[index]?.unitPrice??0),
                // for some reason selecting from multiple coverages was dispatching an array into the drop down instead of the object
                // in the essence of time, check if is array and pull first element, else pull data SRV - 2/26/2021
                coverageId: 1,
                qty: parseFloat(labor[index]?.qty??0),
                requestedAmount: labor[index]?.requested??0,
                authorizedAmount: laborAuthorizedAmount,
                billingCodeId: labor[index]?.billingCodeId??0,
                billingCategoryId: labor[index]?.billingCategoryId??0,
                eventId: labor[index].eventId == null || labor[index].eventId == 0 ? eventId : labor[index].eventId,
                featureClaimId: labor[index].featureClaimId == null || labor[index].featureClaimId == 0 ? featureClaimId : labor[index].featureClaimId,
                rateTypeModel: labor[index].rateType != null ? labor[index].rateType :null,
                FlatFileModel: details != null ? details:null,
                createdBy: labor[index].createdBy != null ? labor[index].createdBy :null
            });
        }

        for (var index = 0; index < parts.length; index++) {
            let description = parts[index].description;
            switch (typeof parts[index].description) {
                case "object":
                    description = description ? description.display : null;
                    break;
                case "undefined":
                    description = null;
                    break;
                default: // string
                    break;
            }

            if (parseInt(parts[index].id) > 0 || parseInt(parts[index].qty) !== 1 || (parts[index].details ? parts[index].details?.length : 0) !== 0 || (description ? description : "") !== "" || parts[index].partNumber !== "" || parseInt(parts[index].unitPrice) !== 0 || parts[index].requested !== 0 || parts[index].authorized !== 0) {
                claimAuthorizeRequest.push({
                    authorizationId: parseInt(parts[index].id) > 0 ? parseInt(parts[index].id) : 0,
                    authorizationType: "Parts",
                    authorizationStatus: status,
                    eventNumber: props.eventNumber,
                    //coverageId: parts[index].coverage[0] == null ? parts[index].coverage.data : parts[index].coverage[0].data,
                    coverageId: 1,
                    qty: parseFloat(parts[index]?.qty??0),
                    price: parseInt(parts[index]?.unitPrice??0),
                    requestedAmount: parts[index]?.requested??0,
                    authorizedAmount: parts[index]?.authorized??0,
                    partNumber: parts[index].partNumber != null ? parts[index].partNumber :null,
                    description: description != null ? description:null,
                    details: parts[index].details.length >0 ? parts[index].details.join(',') :null,
                    billingCodeId: parts[index]?.billingCodeId??0,
                    billingCategoryId: parts[index]?.billingCategoryId??0,
                    eventId: parts[index].eventId == null || parts[index].eventId == 0 ? eventId : parts[index].eventId,
                    featureClaimId: parts[index].featureClaimId == null || parts[index].featureClaimId == 0 ? featureClaimId : parts[index].featureClaimId,
                    FlatFileModel: details != null ? details : null,
                    createdBy: parts[index].createdBy != null ? parts[index].createdBy : null
                });
            }
        }

        for (var index = 0; index < mileage.length; index++) {
            console.log(mileage, mileage[index]);
            if (parseInt(mileage[index].qty) > 0 ) { // SRV - to remove minimum required data for calculator
                let tripBillingCode = 0;
                if (mileage[index].tripType.data == 'oneWay')
                    tripBillingCode = 24;
                else if (mileage[index].tripType.data == 'roundTrip')
                    tripBillingCode = 22;
                else if (mileage[index].tripType.data == 'perMile')
                    tripBillingCode = 47;
                else if (mileage[index].tripType.data == 'trip')
                    tripBillingCode = 45;
                else if (mileage[index].tripType.data == 'flatRate')
                    tripBillingCode = 46;
                else
                    tripBillingCode = 22;
                claimAuthorizeRequest.push({
                    authorizationId: parseInt(mileage[index].id) > 0 ? parseInt(mileage[index].id) : 0,
                    authorizationType: "Mileage",
                    authorizationStatus: status,
                    qty: parseInt(mileage[index]?.qty??0),
                    unitPrice: mileage[index]?.unitPrice??0,
                    requestedAmount: mileage[index]?.requested??0,
                    authorizedAmount: mileage[index]?.authorized??0,
                    tripType: mileage[index].tripType?.data != null ? mileage[index].tripType?.data :null ,
                    billingCodeId: tripBillingCode != null ? tripBillingCode :0, /*mileage[index].billingCodeId,*/
                    billingCategoryId: mileage[index].billingCategoryId != null ? mileage[index].billingCategoryId :0,
                    eventId: mileage[index].eventId == null || mileage[index].eventId == 0 ? eventId : mileage[index].eventId,
                    featureClaimId: mileage[index].featureClaimId == null || mileage[index].featureClaimId == 0 ? featureClaimId : mileage[index].featureClaimId,
                    coverageId: 1,
                    FlatFileModel: details != null ? details : null,
                    createdBy: mileage[index].createdBy != null ? mileage[index].createdBy: null
                });
            }
        }

        /*PR-SEU - 3219*/
        for (var index = 0; index < tax.length; index++) {
            claimAuthorizeRequest.push({
                authorizationId: parseInt(tax[index].id) > 0 ? parseInt(tax[index].id) : 0,
                authorizationType: "Tax",
                authorizationStatus: status,
                //rateId: null,
                //rate: parseInt(tax[index].unitPrice),
                coverageId: 1,
                qty: parseInt(tax[index]?.qty??0),
                requestedAmount: tax[index]?.requested??0,
                authorizedAmount: tax[index]?.authorized??0,
                billingCodeId: tax[index].billingCodeId != null ? tax[index].billingCodeId :0,
                billingCategoryId: tax[index].billingCategoryId != null ? tax[index].billingCategoryId :0,
                eventId: tax[index].eventId == null || tax[index].eventId == 0 ? eventId : tax[index].eventId,
                featureClaimId: tax[index].featureClaimId == null || tax[index].featureClaimId == 0 ? featureClaimId : tax[index].featureClaimId,
                rateTypeModel: tax[index].rateType != null ? tax[index].rateType :null,
                FlatFileModel: details != null ? details : null
            });
        }
        /*PR-SEU - 3219*/
        console.log(claimAuthorizeRequest);

        var endPoint = status == "Approved" ? "CreateClaimAuthorization" : "RequestForClaimAuthorization";

        fetch(`authorization/${endPoint}/${props.clientId}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(claimAuthorizeRequest)
        }).then(res => {
            if (res.ok) {
                // props.setAuthorizeStatus(status);
                // update feature claim with attribute
                // we got featureClaimId, eventId, selectedValue (attribute)
                console.log(selectedValue);
                let featureClaimAttributeId = attributes?.find(a => a.data == selectedValue)?.data;
                if (featureClaimAttributeId == null || typeof featureClaimAttributeId == "undefined") {
                    featureClaimAttributeId = 0;
                }
                if (status == "Approved")
                    history.push('/authconfirmation', { approved: true, eventId: props.eventId, eventNumber: props.eventNumber, clientId: props.clientId, rfsData: props.rfsData });
                else
                    history.push('/authconfirmation', { inReview: true, eventId: props.eventId, eventNumber: props.eventNumber, clientId: props.clientId, rfsData: props.rfsData });
            }
        })

    }

    const getCamelCaseForStr = (str) => {
        if (!str || !isNaN(str)) {
            return ''
        }
        let [firstWord, ...secondWord] = str.split(' ');
        if (!secondWord) {
            return firstWord[0].toUpperCase() + firstWord.substr(1).toLowerCase();
        }
        return firstWord[0].toUpperCase() + firstWord.substr(1).toLowerCase() + ' ' + secondWord.join(' ').toLowerCase()
    }

    let featureClaimOptions = attributes == null ? [] : attributes;
    if (showSpinner) return (<PageLoading text="Loading Authorization" />)

    var minDate = ""
    var momentDate = "";
    const dateNow = new Date()
    minDate = dateNow;
    const brandNameValidator = validator.current.message("brandName", props.rfsData?.brand && props.rfsData?.brand !== "Unknown" ? props.rfsData?.brand : details.productBrand, 'required');
    const serialNumberValidator = validator.current.message("serialNumber", props.rfsData?.serialNumber ? props.rfsData?.serialNumber : details.productSerialNumber, 'required');
    const modelNumberValidator = validator.current.message("modelNumber", props.rfsData?.modelNumber ? props.rfsData?.modelNumber : details.productModelNumber, 'required');
    const onsiteDiagnosticsValidator = validator.current.message("onsiteDiagnostic", details.onSiteDiagnostic, 'required')
    return (
        <Container className="contentWrapper">
            <Card>
                <CardHeader>
                    <div className="d-flex justify-content-between">
                        {saveAuthorizationForm === true ? <h1 className="h4 mb-0">Review Authorization Request</h1> :
                            <h1 className="h4 mb-0">Submit Authorization Request</h1>}
                        {saveAuthorizationForm && <EditButton onClick={() => { toggleEditable() }} />}
                    </div>
                </CardHeader>
                <CardBody className={styles.Adjudication}>
                    <Row className="twoColWrapper mb-0">
                        <Col xs="12" md="3" className="col-5ths">
                            <FormGroup className={`${brandNameValidator ? 'form-error' : ''}`}>
                                <label htmlFor="brandName" className="form-label">Brand</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                {saveAuthorizationForm || (!saveAuthorizationForm && props.rfsData?.brand && props.rfsData?.brand !== 'Unknown') ? (
                                    <p>{details.productBrand ? details.productBrand : props.rfsData?.brand }</p>
                                ) : (
                                    <DropDownAutoComplete
                                        name="brandName"
                                        className="form-control"
                                        id="brandName"
                                        aria-required="true" defaultValue={details.productBrandValue}
                                        onChangedValue={brandNameChange} options={brandOptions}
                                    />
                                )}
                                {brandNameValidator}
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="3" className="col-5ths">
                            <FormGroup className={`${serialNumberValidator ? 'form-error' : ''}`}>
                                <label htmlFor="serialNum" className="form-label">Serial Number</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                {saveAuthorizationForm || props.rfsData?.serialNumber ? (
                                    <p>{props.rfsData?.serialNumber ? props.rfsData?.serialNumber : details.productSerialNumber}</p>
                                ) : (
                                    <input type="text" className={`form-control`} value={details.productSerialNumber} id="serialNum" onChange={serialNumberChange} name="serialNumber" aria-required="true" />
                                )}
                                {serialNumberValidator}
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="3" className="col-5ths">
                            <FormGroup className={`${modelNumberValidator ? 'form-error' : ''}`}>
                                <label htmlFor="modelNum" className="form-label">Model Number</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                {saveAuthorizationForm || props.rfsData?.modelNumber ? (
                                    <p>{props.rfsData?.modelNumber ? props.rfsData?.modelNumber : details.productModelNumber}</p>
                                ) : (
                                    <input type="text" className="form-control" id="modelNum" value={details.productModelNumber} onChange={modelNumberChange} name="modelNumber" aria-required="true" />
                                )}{modelNumberValidator}
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="3">
                            <FormGroup className="mb-md-0">
                                <label htmlFor="technicianRadio" className="form-label">Has a Technician Been Onsite?</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                {saveAuthorizationForm ? (<p>{details.isTechieBeenOnsite ? "Yes" : "No"}</p>) :
                                    <div>
                                        <div className="radio">
                                            <input type="radio" id="technicianRadioYes" value={true} name="TechnicialCheck" aria-required="true" checked={details.isTechieBeenOnsite === true} onChange={radioChange} />
                                            <label className="controls-label" for="technicianRadioYes">Yes</label>
                                        </div>
                                        <div className="radio">
                                            <input type="radio" id="technicianRadioNo" value={false} name="TechnicialCheck" aria-required="true" checked={details.isTechieBeenOnsite === false} onChange={radioChange} />
                                            <label className="controls-label" for="technicianRadioNo">No</label>
                                        </div>
                                    </div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="twoColWrapper mb-0">
                        <Col md="5">
                            <FormGroup className={`${onsiteDiagnosticsValidator ? 'form-error' : ''}`}>
                                <label htmlFor="onsiteDiagnostic" className="form-label">Onsite Diagnostic</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <InfoTooltip iconType="info" message="Provide a detailed description of the product failure." />
                                {saveAuthorizationForm ?
                                    (<p>{details.onSiteDiagnostic}</p>) :
                                    (<ExpandableTextarea maxChars={250} id="onsiteDiagnostic" textValue={details.onSiteDiagnostic} onTextValueChange={onSiteDiagnosticChange} />)}
                                <div className={styles.errorPadding}>{onsiteDiagnosticsValidator}</div>
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <FormGroup>
                                <label htmlFor="resolution" className="form-label">Resolution</label>
                                {saveAuthorizationForm ?
                                    (<p>{details.resolution}</p>) :
                                    (<ExpandableTextarea maxChars={250} id="resolution" textValue={details.resolution} onTextValueChange={resolutionChange} />)}
                            </FormGroup>
                        </Col>
                    </Row>
                    {saveAuthorizationForm ?
                        (<div><LaborTable isReadOnly={true} />
                            <PartsTable />
                            <MileageTable />
                            <TaxTable /></div>) : (<div><LaborTable isReadOnly={false} />
                                <PartsTable />
                                <MileageTable />
                                <TaxTable /></div>)}
                    <EventTotalsRow req={requested} auth={authorized} />
                    {eventTotalZero && <p className="errorMsg  mt-2"> Event total must be greater than $0</p>}
                    <Row className="twoColWrapper mb-0 mt-3">
                        <Col md="5" >
                            <FormGroup className="mb-0">
                                <label htmlFor="additionalDetails" className="form-label">Additional Details About Requested Rates</label>
                                {saveAuthorizationForm ?
                                    (<p>{details.additionalDetails}</p>) :
                                    (<ExpandableTextarea id="additionalDetails" maxChars={250} textValue={details.additionalDetails} onTextValueChange={additionalDetailsChange} placeholder="Enter Description" />)}
                            </FormGroup>
                        </Col>
                        <Col md="5" />
                    </Row>
                </CardBody>
            </Card>
            <div>
                <div className="btnWrapper">
                    <button className="btnLink" onClick={() => setCancelAuthModal(true)}>Cancel</button>
                    <button className="btn-primary" onClick={handleClick} >{saveAuthorizationForm ? "Submit" : "Continue"}</button>
                </div>
            </div>
            <CancelEntry
                toggle={() => setCancelAuthModal(prev => !prev)}
                isOpen={cancelAuthModal}
                centered={true}
                className="modal-md"
                cancelNavigation={handleClose}
            />
        </Container>
    );
}

export default AdjudicationDisplay;