import React, { Component } from "react";
import {
  Row, Col, Label,
  Input, Popover, PopoverBody, PopoverHeader, FormGroup
} from "reactstrap";
import { DropDownAutoComplete } from '../../inputs/DropDownAutoComplete';
import BootstrapTable from "react-bootstrap-table-next";
import classNames from "classnames";
import styles from "./PlanOptions.module.css";
import SimpleReactValidator from "simple-react-validator";
import { ReactComponent as MoreInfoIcon } from '../../../assets/images/info-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/checkmark.svg';
import { dataLayerPush } from '../../Global/helpers'
import { SessionContext } from '../../../context/SessionContext';
import { getDealerDocument } from "../../Global/DocumentHelper";
import { accountDocumentTypes } from '../../Global/DocumentHelper';

let tableResponsiveLg = "table-responsive-lg";
let colLgAuto = "col-lg-auto"
let planOptions = styles.planOptions;
let coverageTerm = styles.coverageTerm;

export class PlanOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      selectedOption: "",
      radioType: 0,
      selectedYears: null,
      years: "",
      selectedTermId: null,
      showAddProduct: false,
      laborOptions: [],
      selectedLabor: null,
      selectedCoverage: null,
      laborPlans: [],
      showCoverageType: false,
      coverageTypes: [],
      showTerms: this.props?.isTermShown ?? false,
      terms: [],
      isAdvantage: this.props.isAdvantage,
      accountId: this.props.accountId,
      language: this.props.language,
      campaign: this.props.campaign,
      hasAdvantageCampaign: this.props.hasAdvantageCampaign,
      validationFired: false,

      preSelectedCoverageTypeId: -1,
      preSelectedTermId: -1,
      isLennox: false,

      lennoxPlanOptionsData: [],
      isReimbursementLinkVisible: false,
      planOptionsData: this.props.planOptionsData,
      assetPackage: this.props.assetPackage,
      selectedCoverageTerm: false,
      selectedCoveregeType: false,
      isReloading: this.props?.assetPackage?.selectedLabor != undefined ? true : false,
      reimbursementScheduleLink: null
    }

    this.setTierDropDown = this.setTierDropDown.bind(this);
    this.handleLaborDropDown = this.handleLaborDropDown.bind(this);
    this.getCoverages = this.getCoverages.bind(this);
    this.getTerms = this.getTerms.bind(this);
    this.setRembursementLink = this.setReimbursementLink.bind(this);
    this.validator = new SimpleReactValidator({
      element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
      messages: {
        required: ':attribute is required.'
      }
    });
    
  }

  async componentDidMount() {
    dataLayerPush('Pageview', {
      pageTitle: "Plan Options",
      pagePath: "/sales/planoptions"
    })
    if (this.props.dealerProfile !== undefined) {
      this.setState({ isLennox: this.props.dealerProfile.isLennoxDealer });
    }
    this.setTierDropDown();
    await this.loadProps();

    this.getReimbursementOptions();
    this.preSelectCampaignDetails();
    this.getReimbursementSchedDoc();
  }

  componentDidUpdate(prevProps) {
    if (this.props.assetPackageId !== prevProps.assetPackageId) {
      var currentTier = this.state.selectedLabor;
      var currentTerm = this.state.selectedYears;
      var currentCoverage = this.state.selectedCoverage;

      if (currentTier != this.props.assetPackage.selectedLabor)
        currentTier = this.props.assetPackage.selectedLabor;

      if (currentTerm != this.props.assetPackage.selectedTermId)
        currentTerm = this.props.assetPackage.selectedTermId;

      if (currentCoverage != this.props.assetPackage.selectedCoverage)
        currentCoverage = this.props.assetPackage.selectedCoverage;

      this.setState({
        selectedLabor: currentTier,
        selectedYears: currentTerm,
        selectedCoverage: currentCoverage,
        assetPackage: this.props.assetPackage,
        showCoverageType: currentTier > 0,
        showTerms: currentCoverage > 0
      }, () => {
        this.setTierDropDown();
        if (this.state.showCoverageType)
          this.getCoverages(this.state.selectedLabor);
        if (this.state.showTerms)
          this.getTerms(this.state.selectedLabor, this.state.selectedCoverage);
      });

    }
  }

  getSelectedCoverageType = (coverage) => {
    var selected = this.props.assetPackagesData.find(x => x.assetPackageId == this.props.assetPackageId && x.selectedCoverage == coverage.id);
    return selected != undefined
  }

  getSelectedTerm = (term) => {
    var selected = this.props.assetPackagesData.find(x => x.assetPackageId == this.props.assetPackageId && x.selectedTermId == term);
    return selected != undefined;
  }

  loadProps = async () => {
    if (typeof this.props.assetPackage.selectedLabor !== "undefined" && !this.props.isCoveragePreferences) {
      const loc = this.props.assetPackage;
      const currProps = this.props;
   
      this.setState({ showCoverageType: true })
      this.getCoverages(loc.selectedLabor);

      if (loc.blnSelectedCoverage === true) {
        this.setState({ showTerms: true });
        this.getTerms(loc.selectedLabor, loc.selectedCoverage);
      }

      if (loc.blnSelectedYears === true) {
        await this.props.onTermChange({ selectedLabor: loc.selectedLabor, selectedCoverage: loc.selectedCoverage, selectedYears: loc.selectedYears, selectedTermId: loc.selectedTermId, years: "Yr" });
      }

      if (!loc.blnSelectedYear && !loc.blnSelectedCoverage) {
        if (currProps.assetPackage?.selectedLabor != undefined && currProps.triggerLaborReimbursementEvent != undefined) {
          this.handleLaborDropDown(currProps.assetPackage.selectedLabor);
        }

        if (currProps.isTermShown != undefined && currProps.triggerLaborReimbursementEvent) {
          this.setState({
            selectedLabor: currProps.assetPackage?.selectedLabor,
            selectedYears: currProps.assetPackage?.selectedYears
          }, () => {
            this.onTypeRadioChange({ selectedCoverage: currProps.assetPackage?.selectedCoverage, showTerms: true, selectedYears: 0 });
          })
        }
      }

    }
  }

  togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen })
  };


  checkAdvantageorVertical = () => this.state.isAdvantage || this.props.verticalAlign;

  onTypeRadioChange = (newData) => {
    this.setState({ validationFired: false });
    if (newData.selectedCoverage !== this.state.preSelectedCoverageTypeId)
      this.setState({ preSelectedCoverageTypeId: -1 });

    if (this.checkAdvantageorVertical()) {
      this.getAdvantageTerms(this.state.accountId, this.state.selectedLabor, newData.selectedCoverage);
      this.setState({ selectedCoverage: newData.selectedCoverage, showTerms: newData.showTerms === undefined ? false : newData.showTerms },
        () => {
          this.props.onAdvantagePlanOptionChange({
            tierId: this.state.selectedLabor,
            coverageTypeId: this.state.selectedCoverage,
            termId: this.state.isReloading ? this.props?.assetPackage?.selectedYears : undefined
          });
        });
    }
    else {
      let packagedata = this.props.assetPackagesData.find(x => x.assetPackageId == this.props.assetPackageId);
      const selLabor = this.state.selectedLabor === null && this.props.assetPackageId != null && packagedata != null ? packagedata.selectedLabor : (this.state.selectedLabor == null ? this.props.campaign.tierId : this.state.selectedLabor)

      this.getTerms(selLabor, newData.selectedCoverage);
      this.setState({ showTerms: true, selectedCoverage: newData.selectedCoverage });

      let assetPackages = this.props.assetPackagesData;
      for (var index = 0; index < assetPackages.length; index++) {
        if (assetPackages[index].assetPackageId === this.props.assetPackageId) {
          assetPackages[index].selectedCoverage = newData.selectedCoverage;
          assetPackages[index].blnSelectedCoverage = true;
          assetPackages[index].selectedYears = "";
          assetPackages[index].selectedTermId = "";
          assetPackages[index].internalPreferred = false;
        }
      }
      this.props.updateAssetPackagesData(assetPackages);
    }

    if (this.props?.onPlanTypeSelected !== undefined) {
      this.props.onPlanTypeSelected();
    }
  }

  handleLaborDropDown = (e) => {
    this.setState({ showCoverageType: false, showTerms: false, validationFired: false, validation: false });
    if (this.checkAdvantageorVertical()) {
      this.getAdvantageCoverages(this.state.accountId, e, this.state.language);
      this.setState({ selectedLabor: e }, () => {
        this.props.onAdvantagePlanOptionChange({
          tierId: this.state.selectedLabor,
          coverageTypeId: this.state.isReloading ? this.props?.assetPackage?.selectedCoverage : undefined ,
          termId: this.state.isReloading ? this.props?.assetPackage?.selectedYears : undefined
        });
      });
    }
    else {
      this.setState({ selectedLabor: e, showCoverageType: true }, () => {
        const validation = this.validator.allValid();
        this.setState({ validation }, () => this.props.onTermChange(this.state))
      });
      this.getCoverages(e);

      let assetPackages = this.props.assetPackagesData;
      for (var index = 0; index < assetPackages.length; index++) {
        if (assetPackages[index].assetPackageId === this.props.assetPackageId) {
          assetPackages[index].selectedLabor = e;
          assetPackages[index].selectedCoverage = "";
          assetPackages[index].selectedYears = "";
          assetPackages[index].selectedTermId = "";

          assetPackages[index].blnSelectedLabor = true;
          assetPackages[index].blnSelectedCoverage = false
          assetPackages[index].blnSelectedYears = false
          assetPackages[index].internalPreferred = false;
        }
      }

      this.props.updateAssetPackagesData(assetPackages);
    }
  }

  getAdvantageCoverages = (accountId, tierId, language) => {

    fetch(`pricing/GetCoverageByAccountAndTierId/${accountId}/${tierId}/${language}`)
      .then(response => response.json())
      .then(data => {
        this.setState({ coverageTypes: data, selectedLabor: tierId, showCoverageType: true });
      });
  }


  getCoverages = (tierId) => {
    var coverages = this.state.planOptionsData?.coverages;
    var offers = this.state.assetPackage.offers;

    if (coverages != undefined) {
      var coverageTypes = coverages.filter(t => {
        var coverageFound = offers.find(offer => offer.coverageTypeId === t.id && offer.tierId === tierId)
          != undefined;

        return coverageFound;
      });

      this.setState({ coverageTypes });
    }

  }

  getTerms = (tierId, coverageTypeId) => {
    var termOptions = this.state.planOptionsData?.terms;
    var offers = this.state.assetPackage.offers;

    var terms = termOptions?.filter(t => {
      var coverageFound = offers.find(offer => offer.termId === t.id &&
        offer.tierId === tierId && offer.coverageTypeId === coverageTypeId) != undefined;

      return coverageFound;
    });

    this.setState({ terms });
  }

  getAdvantageTerms = (accountId, tierId, coverageTypeId) => {
    fetch(`contract/GetTermsByAccountIdTierIdAndCoverageTypeId/${accountId}/${tierId}/${coverageTypeId}`)
      .then(response => response.json())
      .then(data => {
        let terrms = data.map(t => {
          return {
            termYears: (t.termInDays / 365),
            termId: t.termId
          }
        });
        this.setState({ terms: terrms });
        this.props.onPlanTypeSelected();
      });
  }

  onTermRadioChange = (newData) => {
    this.setState({ preSelectedTermId: -1 });

    this.setState({ ...newData }, () => {
      const validation = newData.validation !== undefined ? newData.validation : this.validator.allValid();
      this.props.onTermChange({ ...newData, validation });
    });
    if (this.checkAdvantageorVertical()) {
      this.props.onAdvantagePlanOptionChange({
        tierId: this.state.selectedLabor,
        coverageTypeId: this.state.selectedCoverage,
        termId: newData.selectedYears
      });
    }
    else {
      let assetPackages = this.props.assetPackagesData;
      for (var index = 0; index < assetPackages.length; index++) {
        if (assetPackages[index].assetPackageId === this.props.assetPackageId) {
          assetPackages[index].selectedYears = newData.selectedYears;
          assetPackages[index].selectedTermId = newData.selectedTermId;

          assetPackages[index].blnSelectedYears = true
        }
      }

      this.props.updateAssetPackagesData(assetPackages);

      var coverage = {
        tierId: this.props.assetPackage.selectedLabor,
        coverageTypeId: this.props.assetPackage.selectedCoverage,
        termId: newData.selectedTermId
      };

      this.handlePreferred(coverage);
    }
  }

  handlePreferred = (coverage) => {

    let assetPackages = this.props.assetPackagesData;
    let offer = Array.from(this.props.assetPackage.offers).find(o => o.internalPreferred);

    if (offer != undefined &&
      coverage.tierId === offer.tierId &&
      coverage.coverageTypeId === offer.coverageTypeId &&
      coverage.termId === offer.termId) {
      for (var index = 0; index < assetPackages.length; index++) {
        if (assetPackages[index].assetPackageId === this.props.assetPackageId) {
          assetPackages[index].internalPreferred = true;
          this.setState({ selectedCoverageTerm: true });
          this.setState({ selectedCoveregeType: true });
        }
      }
    }
    else {
      for (var index = 0; index < assetPackages.length; index++) {
        if (assetPackages[index].assetPackageId === this.props.assetPackageId) {
          assetPackages[index].internalPreferred = false;
        }
      }
    }
    this.props.updateAssetPackagesData(assetPackages);
  }

  setTierDropDown = () => {
    if (this.checkAdvantageorVertical()) {
      this.getAdvantageTiersByAccount();
    }
    else {
      var tiers = this.state.planOptionsData.tiers;
      var offers = this.state.assetPackage.offers;
      const validation = this.validator.allValid();

      var dropdownData = tiers.map(t => {
        var tierFound = offers.find(offer => offer.tierId == t.id) != undefined;
        if (tierFound) {
          return {
            data: t.id,
            display: t.name,
          }
        }
        return;
      });

      var filteredDropdownData = dropdownData.filter(f => { return f != null });

      this.setState({ laborOptions: filteredDropdownData, validation }, () => this.props.onTermChange(this.state));
    }
  }

  getAdvantageTiersByAccount = () => {
    fetch(`tier/GetTierByAccountId/${this.state.accountId}`)
      .then(response => response.json())
      .then(data => {
        var dropdownData = data.map(t => {
          return {
            data: t.tierId,
            display: t.tierName,
          }
        });
        this.setState({ laborOptions: dropdownData, planOptions: data });
      });
  }


  formIsValid = () => {

    if (this.validator.allValid()) {
      return true;
    } else {
      this.setState({
        validationFired: true
      }, () => {
        this.validator.showMessages();
        this.forceUpdate();
      });
      return false;
    }
  }

  preSelectCampaignDetails = () => {
    if (this.props.isCoveragePreferences) {
      if (this.props.assetPackage.selectedLabor !== undefined) {
        let coverage = this.props.assetPackage;
        coverage.showTerms = true;

        this.getAdvantageTerms(this.state.accountId, coverage.selectedLabor, coverage.selectedCoverage);
        this.handleLaborDropDown(coverage.selectedLabor);
        this.onTypeRadioChange(coverage);
        this.onTermRadioChange(coverage);
      }
      return;
    }

    if (this.checkAdvantageorVertical()) {
      return;
    }

    if (this.props.assetPackage.selectedLabor !== undefined) {
      this.handlePreferred({
        tierId: this.props.assetPackage.selectedLabor,
        coverageTypeId: this.props.assetPackage.selectedCoverage,
        termId: this.props.assetPackage.selectedTermId
      })
      return;
    }


    let coverage = {
      selectedLabor: 0,
      selectedCoverage: 0,
      selectedYears: 0,
      selectedTermId: 0,
      assetPackageId: this.props.assetPackageId
    };

    let offer = Array.from(this.props.assetPackage.offers).find(o => o.internalPreferred);
    if (offer) {
      this.getTerms(offer.tierId, offer.coverageTypeId);
      let term = Array.from(this.state.terms).find(t => t.id === offer.termId);
      coverage.selectedLabor = offer.tierId;
      this.handleLaborDropDown(offer.tierId);
      coverage.selectedCoverage = offer.coverageTypeId;
      this.onTypeRadioChange(coverage);
      coverage.selectedYears = term.name;
      coverage.selectedTermId = term.id;
      coverage.validation = true;
      this.onTermRadioChange(coverage);
      this.setState({ selectedCoveregeType: true });
      this.setState({ selectedCoverageTerm: true });
    }
  }

  getReimbursementOptions = async () => {
    let accountId = this.props.dealerProfile.accountId ?? this.props?.accountId;
    if (accountId) {
      fetch(`tier/GetTierByAccountId/${accountId}`)
        .then(response => response.json())
        .then(data => {
          if (this.state.isLennox) {
            var tierData = data
            .filter(x => { return x.tripRate > 0 && x.laborRate > 0; })
            .map(d => {
              return {
                planType: d.tierName,
                tierName: d.tierName,
                tripRate: `$${d.tripRate}`,
                laborRate: `$${d.laborRate}`,
                refrigerant: this.state.isLennox && d.tierName.toLowerCase() !== 'basic' ? (<CheckIcon />) : " ",
                addBenefits: this.state.isLennox && d.tierName.toLowerCase() !== 'basic' ? (<CheckIcon />) : " "
              }
            });
          } else {
            var tierData = data
            .filter(x => { return x.tripRate > 0 && x.laborRate > 0; })
            .map(d => {
              return {
                planType: d.tierName,
                tierName: d.tierName,
                tripRate: `$${d.tripRate}`,
                laborRate: `$${d.laborRate}`,
                addBenefits: this.state.isLennox && d.tierName.toLowerCase() !== 'basic' ? (<CheckIcon />) : " "
              }
            });
          }
          if (this.state.isLennox) {
            tierData.push(
              {
                planType: "Refrigerant only",
                tierName: "Refrigerant only",
                tripRate: "",
                laborRate: "",
                refrigerant: <CheckIcon />,
                addBenefits: " "
              }
            )
          }
          this.setState({ lennoxPlanOptionsData: tierData });
            
        });
    }
  }

	setReimbursementLink = (res) => {
		if (res?.success) {
			this.setState({ reimbursementScheduleLink: res.documentPath, isReimbursementLinkVisible: res.success });
		}
	}

  getReimbursementSchedDoc = () => {
    let profile = this.context.getProfile();
    getDealerDocument(accountDocumentTypes.ReimbursementSchedules, false, profile, this.setReimbursementLink);
  }


  getTermElement = () => <Col xs="6" className={classNames(colLgAuto, planOptions)}>
    <FormGroup
      className={
        this.validator.message('term', this.props.assetPackage.selectedYears, 'required') && "form-error"
      }
    >
      <Label for="coverageTerm" className="form-label">Term</Label>
      <span aria-hidden="true" className="required-indicator">*</span>
      <Row id="coverageTerm-Row" className={classNames("m-0", coverageTerm)}>
        {this.state.terms.map(term => (
          <div className={styles.radioCylinder}>
            <Input
              type="radio"
              checked={this.props.assetPackage.selectedYears === term.termId}
              onChange={() => this.onTermRadioChange({ selectedYears: term.termId, years: 'Yr' })}
              name="termOfCoverage" id={term.termId + '-term'} />
            <Label
              className={classNames(
                styles.controlsLabel,
                this.validator.message('term', this.props.assetPackage.selectedYears, 'required') &&
                styles.borderCol
              )}
              for={term.termId + '-term'}
            >
              {term.termYears} Yr
            </Label>
          </div>
        ))}
      </Row>
      {this.validator.message('term', this.props.assetPackage.selectedYears, 'required')}
    </FormGroup>
  </Col>

  render() {


    const { selectedYears, radioType, years, showAddProduct } = this.state;

    const reimbursePlanColumns = [
      {
        dataField: "tierName",
        text: "Plan",
        classes: styles.reimbursePlanType,
        //   headerClasses: styles.headerStyle,
      },
      {
        dataField: "tripRate",
        text: "Trip Fee",
        //   classes: styles.columnType,
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "laborRate",
        text: "Labor Rate",
        // classes: styles.columnCNumber,
        // headerClasses: styles.columnHeader,
      },
    ];

    const lennoxPlanOptionsColumns = [
      {
        dataField: "planType",
        text: "Plan",
        classes: styles.reimbursePlanType,
        //   headerClasses: styles.headerStyle,
      },
      {
        dataField: "tripRate",
        text: "Trip Fee",
        //   classes: styles.columnType,
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "laborRate",
        text: "Labor Rate",
        // classes: styles.columnCNumber,
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "refrigerant",
        text: "Refrigerant",
        classes: `d-table-cell ${styles.addBenefits}`,
      },
      {
        dataField: "addBenefits",
        text: "Additional Benefits*",
        classes: `d-table-cell ${styles.addBenefits}`,
      },
    ];

    const plantOptionsData = this.state.lennoxPlanOptionsData;
    return (

      <div>
        <Row className="multiColWrapper">
          <Col xs="12" md="6" className={classNames(colLgAuto, planOptions)}>
            <FormGroup className={!this.validator.check(this.props.assetPackage.selectedLabor, "required") && this.state.validationFired ? "form-error mb-0" : "mb-0"}>

              <div className="d-flex">
                <Label for="typeOfInst" className="form-label">{this.props.isCanadaState ? "Labour Reimbursement" : "Labor Reimbursement"}</Label>
                <span aria-hidden="true" className="required-indicator">*</span>
                <span id={'ReimbersementInfo'+ this.props.index} className={styles.reimburseOption} onClick={() => this.togglePopover()} type="button"><MoreInfoIcon /></span>
              </div>
              <DropDownAutoComplete
                name="laborReimbursement"
                options={this.state.laborOptions} //"hello"
                placeholder="Please select..."
                onChangedValue={this.handleLaborDropDown}
                defaultValue={typeof this.props.assetPackage.selectedLabor === "undefined" ? this.state.selectedLabor : this.props.assetPackage.selectedLabor}
                value={typeof this.props.assetPackage.selectedLabor === "undefined" ? this.state.selectedLabor : this.props.assetPackage.selectedLabor}
              />
              {this.validator.message('laborReimbursement', this.props.assetPackage.selectedLabor, 'required')}
            </FormGroup>
          </Col>
          {!this.props.isAdvantage && this.state.showCoverageType && <Col xs="12" md="6" className={classNames(colLgAuto, planOptions)}>
            <FormGroup
              className={
                !this.validator.check(this.props.assetPackage.selectedCoverage, "required") &&
                this.state.validationFired &&
                "form-error"
              }
            >
              <Label for="typeCoverage" className="form-label">Type</Label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <div id="typeCoverage1" className="d-flex">
                {this.state.coverageTypes.map(coverage => (
                  <div className={`${this.state.selectedCoveregeType === true ? styles.radioCylinder : this.props.assetPackage.internalPreferred === true ? styles.disabledRadioBtn : styles.radioCylinder}`}>
                    <Input type="radio" name={'typeOfCoverage' + this.props.assetPackageId}
                      id={'typeOfCoverage' + coverage.id + 'assetPackage' + this.props.assetPackageId}
                      checked={this.getSelectedCoverageType(coverage) || (this.state.preSelectedCoverageTypeId === coverage.id)}
                      onChange={() => { this.onTypeRadioChange({ selectedCoverage: coverage.id, showTerms: true, selectedYears: 0, selectedTermId: 0 }); }} />
                    <Label className={classNames(
                      styles.controlsLabel,
                      this.validator.message(
                        'typeOfCoverage', this.props.assetPackage.selectedCoverage, 'required'
                      ) &&
                      this.state.validationFired && styles.borderCol
                    )}
                      for={'typeOfCoverage' + coverage.id + 'assetPackage' + this.props.assetPackageId}
                    >
                      {coverage.name}
                    </Label>
                  </div>))}

              </div>
              {this.state.validationFired &&
                this.validator.message('typeOfCoverage', this.props.assetPackage.selectedCoverage, 'required')
              }
            </FormGroup>
          </Col>}
          {this.props.isAdvantage && this.state.showCoverageType && <Col xs="12" md="6" className={classNames(colLgAuto, planOptions)}>
            <FormGroup
              className={
                this.validator.message('typeOfCoverage', this.props.assetPackage.selectedCoverage, 'required') &&
                this.state.validationFired && "form-error"
              }
            >
              <Label for="typeCoverage" className="form-label">Type</Label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <div id="typeCoverage1" className="d-flex">
                {this.state.coverageTypes.map(coverage => (
                  <div className={styles.radioCylinder}>
                    <Input
                      type="radio"
                      name="typeOfCoverage"
                      id={coverage.coverageTypeId}
                      checked={this.props.assetPackage.selectedCoverage === coverage.coverageTypeId}
                      onChange={() => this.onTypeRadioChange({ selectedCoverage: coverage.coverageTypeId, showTerms: true, selectedYears: 0 })} />
                    <Label
                      className={classNames(
                        styles.controlsLabel,
                        this.validator.message('typeOfCoverage', this.props.assetPackage.selectedCoverage, 'required') &&
                        styles.borderCol
                      )}
                      for={coverage.coverageTypeId}
                    >
                      {coverage.description}
                    </Label>
                  </div>))}
              </div>
              {this.validator.message('typeOfCoverage', this.props.assetPackage.selectedCoverage, 'required')}
            </FormGroup>
          </Col>}
          {!this.props.isAdvantage && this.state.showTerms && <Col xs="6" className={classNames(colLgAuto, planOptions)}>
            <FormGroup
              className={
                this.state.validationFired &&
                this.validator.message('term', this.props.assetPackage.selectedYears, 'required') &&
                "form-error"
              }
            >
              <Label for="coverageTerm" className="form-label">Term</Label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <Row id="coverageTerm-Row" className={classNames("m-0", coverageTerm)}>
                {this.state.terms.map(term => (
                  <div className={`${this.state.selectedCoverageTerm ? styles.radioCylinder : this.props.assetPackage.internalPreferred === true ? styles.disabledRadioBtn : styles.radioCylinder}`}>
                    <Input type="radio"
                      onChange={() => {
                        console.log(">>>> onTermRadioChange.");
                        this.onTermRadioChange({ selectedYears: term.name, years: 'Yr', selectedTermId: term.id, selectedCoverage: this.props.assetPackage.selectedCoverage, selectedLabor: this.props.assetPackage.selectedLabor, assetPackageId: this.props.assetPackageId });
                      }}
                      checked={this.getSelectedTerm(term.id) || (this.state.preSelectedTermId === term.id) || (this.state.isReloading && this.state.selectedYears === term.id)}
                      name={'termOfCoverage' + this.props.assetPackageId}
                      id={'termOfCoverage' + term.id + 'assetPackage' + this.props.assetPackageId} />
                    <Label
                      className={classNames(
                        styles.controlsLabel,
                        this.validator.message('term', this.props.assetPackage.selectedYears, 'required') &&
                        this.state.validationFired &&
                        styles.borderCol
                      )}
                      for={'termOfCoverage' + term.id + 'assetPackage' + this.props.assetPackageId}
                    >
                      {term.name} Yr
                    </Label>
                  </div>
                ))}
              </Row>
              {this.state.validationFired &&
                this.validator.message('term', this.props.assetPackage.selectedYears, 'required')
              }
            </FormGroup>
          </Col>}
          {this.props.verticalAlign && this.state.showTerms && this.getTermElement()}
        </Row>
        <Row className="multiColWrapper form-group">
          {this.props.isAdvantage && !this.props.verticalAlign && this.state.showTerms && this.getTermElement()}
        </Row>
        <Popover
          placement="right"
          isOpen={this.state.popoverOpen}
          target={'ReimbersementInfo'+ this.props.index}
          trigger="focus"
          toggle={() => this.togglePopover()}
          className={styles.reimburseInfoPopover}>
          <PopoverHeader>
            Labor Reimbursement Selection
          </PopoverHeader>
          <PopoverBody>
            <p>Multiple reimbursement amounts available to meet your business needs.</p>

            {this.state.isLennox ?
              (<div>
                <BootstrapTable
                  keyField="planType"
                  data={plantOptionsData}
                  columns={lennoxPlanOptionsColumns}
                  bordered={false}
                  bootstrap4
                  wrapperClasses={`text-center ${tableResponsiveLg}`}
                />
                <p>*Additional benefits include Parts Markup and Misc. Parts.</p>
              </div>) :
              (<div className={styles.reimbursePlanTable}>
                <BootstrapTable
                  keyField="dealerPO"
                  data={plantOptionsData}
                  columns={reimbursePlanColumns}
                  bordered={false}
                  bootstrap4
                  wrapperClasses={`text-center ${tableResponsiveLg}`}
                />
              </div>)}
            <div className={styles.reimbursePopFooter}>
              {this.state.isReimbursementLinkVisible ? <button className="btnLink" onClick={() => window.open(this.state.reimbursementScheduleLink, '_blank')}>View Reimbursement Schedule</button> :
                <div></div>}
              <button className="btn-primary" onClick={() => this.togglePopover()}>Close</button>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

PlanOptions.contextType = SessionContext;