import React from 'react';
import { Row, Col, Label } from "reactstrap"
import moment from 'moment';
import InfoTooltip from "../shared/InfoTooltip/InfoTooltip";

const StaticClaimInvoiceForm = ({
    claimData, contract
}) => {
    return (
        <Row>
            <Col sm="12" md="10" className="mb-4">
                <Row className="mb-4">
                    <Col sm="3" className='pr-0'>
                        <ul className="address mb-0">
                            <li>
                                <Label for="invoiceNumber" className="form-label">Invoice Number</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                            </li>
                            <li>
                                {claimData.invoiceNumber}
                            </li>
                        </ul>
                    </Col>
                    <Col sm="3" className='pr-0'>
                        <ul className="address mb-0">
                            <li>
                                <Label for="invoiceDate" className="form-label">Invoice Date</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                            </li>
                            <li>
                                {moment(claimData.invoiceDate).format(contract?.country === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
                            </li>
                        </ul>
                    </Col>
                    <Col sm="3" className='pr-0'>
                        <ul className="address mb-0">
                            <li>
                                <Label for="failureDate" className="form-label">Failure Date</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                            </li>
                            <li>
                                {moment(claimData.failureDate).format(contract?.country === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
                            </li>
                        </ul>
                    </Col>
                    <Col sm="3" className='pr-0'>
                        <ul className="address mb-0">
                            <li>
                                <Label for="serviceDate" className="form-label">Service Completion Date</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                            </li>
                            <li>
                                {moment(claimData.serviceCompletionDate).format(contract?.country === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6" className='pr-0'>
                        <ul className="address mb-0">
                            <li>
                                <Label for="onsite" className="form-label">Onsite Diagnostic</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <InfoTooltip iconType="info" message="Provide a detailed description of the product failure." />
                            </li>
                            <li>
                                {claimData.onsiteDiagnostic}
                            </li>
                        </ul>
                    </Col>
                    <Col sm="6">
                        <ul className="address mb-0">
                            <li>
                                <Label for="resolution" className="form-label">Resolution</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                            </li>
                            <li>
                                {claimData.resolution}
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default StaticClaimInvoiceForm;