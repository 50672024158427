import React, { useContext, useEffect, useState } from "react";
import {
  Container, Card, CardBody, Row, Col, Input, Button, Label, Dropdown,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap';
import classNames from "classnames";

const AddressDisplay = ({ title, items, titleClass, childclass }) => {
  return (
    <ul className={childclass}>
      {title && <li className={titleClass}>{title}</li>}
      {items.map((item) => { return (<li>{item}</li>) })}
    </ul>
  );
}

export default AddressDisplay;