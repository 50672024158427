import React, { useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import {
    CaptionComponent,
    widthFormatter,
    AuthInput,
    EditRequestedTax,
    HeaderFormatter
} from '../util/FormatterComponents'
import styles from '../Adjudication.module.css'
import { AdjudicationContext } from '../util/store/context';

const TaxTable = () => {
    const { state: { tax } } = useContext(AdjudicationContext)

    const cols = [
        {
            dataField: 'taxType',
            text: 'Tax Type',
            headerAttrs: {
                hidden: true
            },
            textAlign: 'right',
            style: { textAlign: 'right', padding: '4px 16px' }
        },
        {
            dataField: 'requested',
            text: 'Requested',
            headerAttrs: {
                hidden: true
            },
            style: widthFormatter('amountCol'),
            classes: styles.containsInput,
            headerFormatter: (col) => HeaderFormatter({ col, centered: true }),
            formatter: (_, row, rowIndex) => <EditRequestedTax row={row} rowIndex={rowIndex} />,
            align: 'right'
        }
    ]
    return (!!tax &&
        <BootstrapTable
            bootstrap4
            condensed
            data={tax}
            columns={cols}
            keyField='id'
            caption={<CaptionComponent title="Tax" />}
            classes={['mb-0', styles.padCells, styles.AdjudicationTable]}
        />
    )
}

export default TaxTable;