import React, { useState, useContext, useEffect } from "react";
import PdfContainer from "../PdfContainer";
import ContentPanelLayout from '../ContentPanelLayout';
import PremiumRepairCodesUSA from '../../../assets/Documents/Claim Repair Codes.pdf';
import { SessionContext } from "../../../context/SessionContext";

const TraneClaims = ({ title }) => {


  return (
    <ContentPanelLayout title={title} pdf={PremiumRepairCodesUSA} displayPrintAndDownload>
      <PdfContainer pdf={PremiumRepairCodesUSA} hasScroll />
    </ContentPanelLayout>
  )
}
export default TraneClaims;