import { randomized, rndmvals } from "./util";

export const fakeData = {
    taxRate: .06,
    preapprovedAmount: 1000,
    coverages: [
        {
            display: 'Prime',
            data: 'prime',
        },
        {
            display: 'Optimus Prime',
            data: 'optimusprime',
        },
        {
            display: 'Prime Rib',
            data: 'primerib',
        },
        {
            display: 'Prime Time',
            data: 'primetime',
        },
        {
            display: 'Subprime',
            data: 'subprime',
        },
    ],
    labor: [
        {
            //minimum necessary data
            id: randomized(),
            qty: 1,
            unitPrice: rndmvals(100),
            // table-specific
            rateType: null,
            coverage: null,
        },
    ],
    negotiatedRateTypes: [
        {
            display: 'House Call ($150)',
            data: 'houseCall',
            rate: 150,
        },
        {
            display: 'Carry-In Repair ($80)',
            data: 'carryInRepair',
            rate: 80,
        },
        {
            display: 'Diagnosis ($30)',
            data: 'diagnosis',
            rate: 30,
        },
    ],
    parts: [
        {
            //minimum necessary data
            id: randomized(),
            qty: 1,
            unitPrice: rndmvals(100),
            // table-specific
            details: [],
            partNumber: null,
            description: null,
            coverage: null
        },
    ],
    partsDescriptions: [
        'Dishwasher Rack',
        'Dishwasher Rollers',
        'Refrigerator Condenser',
        'HVAC Blower Fan',
        'Pool Filter',
        'Rollout Switch',
        'Timer',
        'Circuit Board',
        'Garbage Disposal',
        'Burner Coil',
        'Heating Element'
    ],
    partsTypes: null,
    mileage: [
        {
            //minimum necessary data
            id: randomized(),
            qty: rndmvals(40), //miles
            unitPrice: 0.75,
            //table-specific
            tripType: "oneWay",
        },
    ],
    // NOT CURRENTLY NEEDED. IF IN FUTURE WE HOOK UP A TAX SERVICE,
    // WE WILL NEED THE INITIAL LOAD TO FOLLOW THIS PATTERN.
    // tax: [
    //     {
    //         id: randomized(),
    //         taxType: 'Parts Tax',
    //         requested: 0,
    //         authorized: 0,
    //         authEdited: false,
    //     },
    //     {
    //         id: randomized(),
    //         taxType: 'Labor Tax',
    //         requested: 0,
    //         authorized: 0,
    //         authEdited: false,
    //     },
    // ],
    totals: {
        labor: {
            requested: null,
            authorized: null,
        },
        parts: {
            requested: null,
            authorized: null,
        },
        mileage: {
            requested: null,
            authorized: null,
        },
        tax: {
            requested: null,
            authorized: null,
        },
        final: {
            requested: null,
            authorized: null,
        }
    }
}