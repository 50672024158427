import React, { Component, Fragment } from "react";
import { Col } from "reactstrap";
import styles from "./ProgressBar.module.css";
import classNames from "classnames";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import AlertBanner from '../shared/AlertBanner/AlertBanner';

let formStep = styles.formStep;
let inProgressStep = styles.inProgressStep;
let processStepNum = styles.processStepNum;
let inProcessStepNum = styles.inProcessStepNum;
let notInProgressStep = styles.notInProgressStep;
let notInProcessStepNum = styles.notInProcessStepNum;
let completedStep = styles.completedStep;
let completedStepNum = styles.completedStepNum;

const HEADINGS = [
  "Order Information",
  "Plan Details",
  "Customer Information",
  "Information Review",
  "Payment",
  "Final page",
];

export class ProgressBar extends Component {
  state = {
      activeTab: 1,
      mobilePageName: "Order Information",
      showBanner: true,
  };

  componentDidMount() {
    const { activeTab = 1 } = this.props;
      this.setState({ activeTab });
  }

  static getDerivedStateFromProps({ activeTab = 1 }) {
    return {
      activeTab,
    };
  }

  render() {
    const { activeTab } = this.state;
    const stepNum = (num) =>
      `${activeTab === num ? inProcessStepNum : ""} ${
      activeTab > num ? completedStepNum : ""
      } ${activeTab < num ? notInProcessStepNum : ""}`;
    const formStepClass = (num) =>
      `${activeTab === num ? inProgressStep : ""} ${
      activeTab > num ? completedStep : ""
      } ${activeTab < num ? notInProgressStep : ""}`;

    let tabCount = this.props.tabCount;

    return (
      <Fragment>
        <div className={classNames(styles.processSteps, styles.progressBarPrintLayout)}>
          <Col className={classNames(formStep, formStepClass(1))}>
            <div className={classNames(processStepNum, stepNum(1))}>1</div>
            Order Information
          </Col>
          <Col className={classNames(formStep, formStepClass(2))}>
            <div className={classNames(processStepNum, stepNum(2))}>2</div>
            Plan Details
          </Col>
          <Col className={classNames(formStep, formStepClass(3))}>
            <div className={classNames(processStepNum, stepNum(3))}>3</div>
            Customer Information
          </Col>
          <Col className={classNames(formStep, formStepClass(4))}>
            <div className={classNames(processStepNum, stepNum(4))}>4</div>
            Review
          </Col>
          {tabCount == 6 && (
            <Col className={classNames(formStep, formStepClass(5))}>
              <div className={classNames(processStepNum, stepNum(5))}>5</div>
              Payment Information
            </Col>
          )}
          <Col className={classNames(formStep, formStepClass(tabCount))}>
            <div className={classNames(processStepNum, stepNum(tabCount))}>
              {tabCount}
            </div>
            Successful
          </Col>
        </div>
        <div className={styles.headerForMobile}>
          <h3>{HEADINGS[activeTab - 1]}</h3>
          <div className={styles.circularProgress}>
            <CircularProgressbar
              value={(activeTab * 100) / tabCount}
              text={
                <Fragment>
                  <tspan className={styles.currentStep}>{activeTab}</tspan>
                  <tspan
                    className={`${
                      activeTab === tabCount
                        ? styles.completedAll
                        : styles.notCompleted
                      }`}
                  >
                    /tabCount
                  </tspan>
                </Fragment>
              }
              styles={buildStyles({
                textSize: "40px",
                pathColor: `#1352DE`,
              })}
            />
          </div>
        </div>
            {activeTab === 4 && this.props.hasDuplicate && !this.props.hasDuplicateAdv && <div className="mt-1">
              <AlertBanner
                content="We found a serial number with the same coverage for this customer in our system. Please remove the product or update the plan details."
                show
                hasClose={false}
                bannerType="alert"
              />
            </div>}

            {activeTab === 4 && this.props.hasDuplicate && this.props.hasDuplicateAdv && <div className="mt-1">
                <AlertBanner
                    content={"We found an existing " + this.props.advantageProgramName + " contract for this customer and serial number with the same coverage. Please remove the product or update the plan details."}
                    show
                    hasClose={false}
                    bannerType="alert"
                />
            </div>}
      </Fragment>
    );
  }
}
