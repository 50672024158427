import * as React from "react"
import { Table } from "reactstrap"
import styles from "../ContractInfo.module.css"
import classnames from "classnames"

const AIGDetailsTable = ({ tableRows }) => (
    <Table className={classnames("table", styles.detailsTableAlignment)}>
        <tbody className="tableStriped">
            {tableRows.map((row, i) => (
                <tr>
                    <th scope="row" key={i}>
                        {row[0]}
                    </th>
                    <td>{row[1]}</td>
                </tr>
            ))}
        </tbody>
    </Table>
)

export default AIGDetailsTable
