import React, { Component, Fragment, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Input, Button, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, FormGroup } from "reactstrap";
import styles from "./AddProducts.module.css";
import classNames from "classnames";
import { DropDownAutoCompleteTable } from '../../inputs/DropDownAutoCompleteTable';
import { Link } from "react-router-dom";
import { ReactComponent as SearchSVG } from '../../../assets/images/search.svg';
import DeleteButton from '../../shared/DeleteButton/DeleteButton';
import { getBrandContent } from '../../BrandingWhiteLabel/BrandContent';
import { SessionContext } from "../../../context/SessionContext";
import { ReactComponent as CloseSVG } from '../../../assets/images/close.svg';
import { ReactComponent as EditSVG } from '../../../assets/images/Edit_Icon.svg';

let addBtn = styles.addBtn;
let margLeft = styles.margLeft;
let tableResponsiveLg = "table-responsive-lg";

export class ProductTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeEdit: this.props.allowEdit,
      allSelected: this.props.productTableData.every((value) => value.isChecked),
      //selected: [],

      dropdownOpen: false,

      currentRowClicked: -1,
      editOrDeleteTag: "",
      prodDesc: "",
      serialEditError: false,
    };
    this.handleProdDescDropDown = this.handleProdDescDropDown.bind(this);
    this.onCheckSelect = this.onCheckSelect.bind(this);
    this.enableEdit = this.enableEdit.bind(this);
  }

  componentDidMount() {
    if (this.props.assetUpdated) {
      this.handleCheckAll();
    }
  }


  handleProdDescDropDown = e => {
    const rowId = this.state.currentRowClicked.rowIndex;

    this.setState({ prodDesc: e });

    const arr = [...this.props.allAssetNameArr];
    const desc = arr.find(obj => obj.data == e);

    if (desc.length === 0) {
      this.props.handleProdProdDescDropDown(rowId, e, desc.display, true);
    } else {
      this.props.handleProdProdDescDropDown(rowId, e, desc.display, false);
    }
  }

  handleBrandNameDropDown = e => {
    const rowId = this.state.currentRowClicked.rowIndex;
    this.props.handleProdBrandNameDropDown(rowId, e);
    this.props.getProductionDescriptionFromProd(e, rowId, this.props.productTableData[rowId].dummy);

  }

  handleSizeDropDown = e => {
    const rowId = this.state.currentRowClicked.rowIndex;
    this.props.handleProdSizeDropDown(rowId, e);
  }

  handleSeriesDropDown = e => {
    const rowId = this.state.currentRowClicked.rowIndex;
    this.props.handleProdSeriesDropDown(rowId, e);
  }

  handleMfgDropDown = e => {
    const rowId = this.state.currentRowClicked.rowIndex;

    const arr = [...this.props.allOemArr];
    const desc = arr.find(obj => obj.data == e);

    if (desc.length === 0) {
      this.props.handleProdMfgDropDown(rowId, e, desc.display, true);
    } else {
      this.props.handleProdMfgDropDown(rowId, e, desc.display, false);
    }

  }

  handleModelInputChange = e => {
    const { id, value } = e.target;
    const rowId = e.target.attributes.getNamedItem('data-identifier').value;

    if (value.trim() == "") {
      this.setState({ modelError: true })
      this.props.handleProdModelInputChange(rowId, value, true);
    } else {
      this.setState({ modelError: false })
      this.props.handleProdModelInputChange(rowId, value, false);
    }

  }

  handlSerialInputChange = e => {
    const { id, value } = e.target;
    const rowId = e.target.attributes.getNamedItem('data-identifier').value;
    let existingSerial = this.props.handleProdSerialOnBlur(rowId, value, false);
    let error = existingSerial || value.trim() === "";

    this.setState({ serialError: error, serialEditError: existingSerial });
    this.props.handleProdSerialInputChange(rowId, value, error);
  }

  handleProdSerialOnBlur = e => {
    const { id, value } = e.target;
    try {
      const rowId = e.target.attributes.getNamedItem('data-identifier').value;
      this.props.handleProdSerialOnBlur(rowId, value, false);
    } catch (e) {
      //
    }
  }

  toggleDropdown = () => this.setState({ dropdownOpen: !this.state.dropdownOpen })

  getEditModelNum = (modelNumber, error) => {
    return (
      <div>
        <FormGroup className={error ? "form-error" : ""}>
          <Input
            aria-labelledby="modelNumber"
            className={error ? "form-error" : "form-control"}
            type="text"
            placeholder=""
            id="modelNum"
            data-identifier={this.state.currentRowClicked.rowIndex}
            defaultValue={modelNumber}
            onChange={this.handleModelInputChange} />
          {error && <div className="errorMsg">Model number is required.</div>}
        </FormGroup>
      </div>
    );
  }

  getEditPartsWarranty = (warrantyId, error) => {

    const h = [...this.props.allOemArr]

    return (
      <div>
        <FormGroup className={error ? "form-error" : ""}>
          <DropDownAutoCompleteTable
            name={'mfgDropDown'}
            id="mfgDropDownProduct"
            options={h}
            placeholder="Please select..."
            onChangedValue={this.handleMfgDropDown}
            defaultValue={warrantyId}
          />
          {error && <div class="errorMsg">MFG parts warranty is required.</div>}
        </FormGroup>
      </div>
    );
  }

  getEditDesc = (prodDescription, masterId, error) => {

    const e = [...this.props.allAssetNameArr];
    const f = e.filter(o => o.master === masterId);
    const g = [...new Map(f.map(o => [o.id, o])).values()];
    const h = g.sort((a, b) => a.display.localeCompare(b.display));
    var prodDescriptionId = h.find(p => p.display === prodDescription)?.data;

    return (
      <div>
        <FormGroup className={error ? "form-error" : ""}>
          <DropDownAutoCompleteTable
            name={'productDesc'}
            id="prodDescDropDownProduct"
            options={h}
            placeholder="Please select..."
            onChangedValue={this.handleProdDescDropDown}
            defaultValue={prodDescriptionId}
          //value={prodDescriptionId}
          />
          {error === true && <div class="errorMsg">Description is required.</div>}
        </FormGroup>
      </div>
    );
  }

  getEditBrand = (brandNameId, error) => {
    return (
      <div>
        <FormGroup className={error ? "form-error" : ""}>
          <DropDownAutoCompleteTable
            name={'brand'} //name={'billingState'}
            id="brandNameDropDownProduct"
            options={this.props.brandOptions} //"hello"
            placeholder="Please select..."
            defaultValue={brandNameId}
            onChangedValue={this.handleBrandNameDropDown}

          />
          {error && <div class="errorMsg">Brand is required.</div>}
        </FormGroup>
      </div>
    );
  }
  getEditSize = (sizeId, sizeList, error) => {
    return (
      <div>
        <DropDownAutoCompleteTable
          name={'size'} //name={'billingState'}
          id="sizeDropDownProduct"
          options={sizeList} //"hello"
          value={sizeId}
          defaultValue={sizeId}
          placeholder="Please select..."
          onChangedValue={this.handleSizeDropDown}

        />
        {error && <div class="errorMsg">Size is required.</div>}
      </div>
    );
  }

  getEditSeries = (seriesId, seriesList, error) => {
    return (
      <div>
        <DropDownAutoCompleteTable
          name={'series'} //name={'billingState'}
          id="seriesDropDownProduct"
          options={seriesList} //"hello"
          value={seriesId}
          defaultValue={seriesId}
          placeholder="Please select..."
          onChangedValue={this.handleSeriesDropDown}

        />
        {error === true && <div class="errorMsg">Series is required.</div>}
      </div>
    );
  }

  onCheckSelect = (row, checkValue) => {

    const chkRow = [...this.props.productTableData];
    const val = chkRow[row].isChecked;


    if (checkValue === true && this.state.allSelected === true)
      this.setState({ allSelected: false });

    let isheaderShouldChecked = true;
    chkRow[row].isChecked = !checkValue;
    for (var i = 0; i < chkRow.length; i++) {
      if (!chkRow[i].isChecked) {
        isheaderShouldChecked = false;
        break;
      }
    }

    this.setState({ allSelected: isheaderShouldChecked });
    this.props.handleProdCheckChange(row, !val, "SINGLE");

  }

  checkSelectAll() {
    const data = [...this.props.productTableData];
    const dataLength = data.length
    let cntCheck = 0

    for (let i = 0; i < dataLength; i++) {
      const chk = document.getElementById("serialNum" + data[i].serialNumBack);
      if (chk.checked === true) {
        cntCheck = cntCheck + 1
      }
    }

    if (cntCheck === dataLength) {
      this.setState({ allSelected: true });
    }


  }



  handleCheckAll = () => {
    const checkFlag = !this.state.allSelected
    this.setState({ allSelected: checkFlag })
    this.props.handleProdCheckChange(0, checkFlag, "ALL");
    this.props.checkIfCanContinue(checkFlag);
  }


  enableDisableContinue() {
    const data = [...this.props.productTableData];

    for (let i = 0; i < data.length; i++) {
      const chk = document.getElementById("serialNum" + data[i].serialNumBack);
      if (chk.checked === true) {
        this.props.checkIfCanContinue(true);
        return;
      }
    }

    this.props.checkIfCanContinue(false);
    return;

  }


  getSerial = (serial, editTag, isChecked, isCompleted, error, row) => {

    const { accountName } = this.context

    if (editTag == 0) {
      //return (<div className={styles.rowcheckbox}><Input className="mr-2" type="checkbox" onClick={() => this.onCheckSelect(serial)} checked={this.state.allSelected || this.state.selected.includes(serial)} id={`serialNum${serial}`} />
      //    <label for={`serialNum${serial}`} className={styles.rowcheckboxLabel}>{serial}</label>{isCompleted && <div className={styles.greenCheck}> </div>}</div>);
      return (
        <div>
          <div className={styles.rowcheckbox}><Input className="mr-2" type="checkbox" onChange={() => this.onCheckSelect(this.state.currentRowClicked.rowIndex, isChecked)} checked={isChecked} id={`serialNum${serial}`} onBlur={this.handleProdSerialOnBlur} />
            <label for={`serialNum${serial}`} className={classNames(styles.rowcheckboxLabel, styles[accountName])}>
              {serial}
            </label>
            {isCompleted && <div className={styles.greenCheck}> </div>}
          </div>
        </div>);
    } else {
      return (
        <div>
          <FormGroup className={!this.state.serialEditError && error === true ? "form-error" : " "}>
            <div className={styles.rowcheckbox}>
              <Input className="mr-2" type="checkbox" onChange={() => this.onCheckSelect(this.state.currentRowClicked.rowIndex, isChecked)} checked={isChecked} id={`serialNum${serial}`} />
              <label for={`serialNum${serial}`} className={classNames(styles.rowcheckboxLabel, styles[accountName])}></label>
              <Input aria-labelledby="serialNumber" className="form-control" type="text" placeholder="" id="serialNum" value={serial} data-identifier={this.state.currentRowClicked.rowIndex} defaultValue={serial} onChange={this.handlSerialInputChange} onBlur={this.handleProdSerialOnBlur} />
            </div>
            {/* {this.state.serialError === true && <div className={classNames("errorMsg", margLeft)}>Serial number is required.</div>} */}
            {!this.state.serialEditError && error === true && <div className={classNames("errorMsg", margLeft)}>Serial number is required.</div>}
            {this.state.serialEditError && error && <div className={classNames("errorMsg", margLeft)}>This serial number has already been added to the order.</div>}
          </FormGroup>
        </div>);

      //<div>
      //<Input aria-labelledby="serialNumber" className="form-control" type="text" placeholder="" id="serialNum" value={serial} data-identifier={this.state.currentRowClicked.rowIndex} defaultValue={serial} onChange={this.handlSerialInputChange}/>
      //{this.state.serialError === true && <div className="errorMsg">Serial number is required.</div>}
      //</div>

    }
  }

  onEdit = (pos) => {
    this.setState({ activeEdit: pos })
  }

  handleEdit(row, tag) {
    const ret = this.hasRowError(row.rowIndex);
    if (ret === false) {
      this.props.handleEdit(row, tag)
    }
  }

  hasRowError(row) {
    const data = [...this.props.productTableData];
    let res = false;

    if (data[row].tErrorSerial === true || data[row].tErrorDesc === true || data[row].tErrorMfg === true || data[row].tErrorModel === true ||
      ((data[row].tErrorNoPlanExist === true || data[row].tErrorPlanDetails === true) && (data[row].isChecked === true)) ||
      (this.props.installationType === 'Commercial' && data[row].tErrorSize === true)
    ) {
      res = true;
    }

    return res;
  }

  displayErrorMsg = (rowIndex) => {
    let locArr = [...this.props.productTableData];
    const { accountName } = this.context

    if (locArr[rowIndex].tErrorNoPlanExist === true) {
      return (<div><b>We are unable to find a plan to cover this product or it is not eligible for coverage.
        Please uncheck or delete this product to continue.</b>
        <br />If you have questions contact AIG at {getBrandContent(accountName).programPhone}.</div>);
    }
    else if (locArr[rowIndex].tErrorPlanDetails === true) {
      return (<div><b>We are unable to find a plan to cover this product or it it not eligible for coverage.
        &nbsp;Please uncheck or delete this product to continue.</b>
        <br />If you have questions contact AIG at {getBrandContent(accountName).programPhone}.</div>);
    }
    else {
      return null;
    }
  }

  enableEdit = () => {
    let prods = this.props.productTableData;
    for (var i = 0; i < prods.length; i++) {

      var isInvalid = false;

      if (prods[i].isFromOEM) {
        if (prods[i].tErrorMfg) {
          isInvalid = true;
        }
        if (prods[i].tErrorDesc) {
          isInvalid = true;
        }
        if (prods[i].tErrorModel) {
          isInvalid = true;
        }
        if (prods[i].tErrorSerial) {
          isInvalid = true;
        }
      }

      if (isInvalid) {
        var rowClicked = { rowIndex: i };

        this.props.handleEdit(rowClicked, 1);
      }
    }
  }

  render() {
    const locArr = [...this.props.productTableData];
    let dataArray = [];
    const { accountName } = this.context;

    const { activeEdit } = this.state;
    const masterProgramArr = [...this.props.masterProgramOptions];
    const recCount = locArr.length;

    let addProdColumns = [];
    let expandedRows = [];

    if (locArr !== 0) {

      for (let i = 0; i < locArr.length; i++) {
        let dataRow = {};

        dataRow.rowId = locArr[i].rowId;
        if (typeof locArr[i].serial === "string") {
          dataRow.serial = this.getSerial(locArr[i].serial, 0, locArr[i].isChecked, locArr[i].isVerified);
        } else {
          dataRow.serial = this.getSerial(locArr[i].serialNumBack, 0, locArr[i].isChecked, locArr[i].isVerified);
        }
        console.log(locArr[i])
        dataRow.brandName = typeof locArr[i].brandName !== "string" ? locArr[i].brandNameBack : locArr[i].brandName;
        dataRow.prodDescription = typeof locArr[i].prodDescription !== "string" ? locArr[i].prodDescriptionBack : locArr[i].prodDescription;
        dataRow.size = this.props.installationType === 'Commercial' && typeof locArr[i].size !== "string" ? locArr[i].sizeBack : locArr[i].size;
        dataRow.series = typeof locArr[i].series !== "string" ? locArr[i].seriesBack : locArr[i].series;
        dataRow.modelNumber = typeof locArr[i].modelNum === "string" ? locArr[i].modelNum : locArr[i].modelNumber;
        dataRow.MFGWarranty = typeof locArr[i].MFGWarranty !== "string" ? locArr[i].MFGWarrantyBack : locArr[i].MFGWarranty;

        if (locArr[i].editOrDeleteTag !== 0) {
          const masterId = masterProgramArr.find(obj => obj.master == locArr[i].masterProgramId);

          let a;
          if (masterId === undefined) {
            a = "";
          } else {
            a = masterId.master;
          }
          console.log("brand Product Table 459", locArr[i])
          if (locArr[i].isVerified === false) {
            console.log("brand Product Table 461", this.props.brandOptions, this.props)
            dataRow.serial = this.getSerial(locArr[i].serialNumBack, this.props.isLennox ? 0 : 1, locArr[i].isChecked, locArr[i].isVerified, locArr[i].tErrorSerial);
            dataRow.brandName = this.getEditBrand(locArr[i].brandNameId, locArr[i].tErrorBrand);
            dataRow.prodDescription = this.getEditDesc(locArr[i].prodDescription, a, locArr[i].tErrorDesc);
            if (this.props.installationType === 'Commercial')
              dataRow.size = this.getEditSize(locArr[i].size, locArr[i].sizeList, locArr[i].tErrorSize);

            dataRow.series = this.getEditSeries(locArr[i].series, locArr[i].seriesList, locArr[i].tErrorSeries);
            dataRow.MFGWarranty = this.getEditPartsWarranty(locArr[i].MFGWarrantyId, locArr[i].tErrorMfg);
            dataRow.modelNumber = this.getEditModelNum(locArr[i].modelNum, locArr[i].tErrorModel);
            dataRow.editOrDelete = ""

            dataRow.editOrDelete = (<div className={classNames(styles.editOptions, "mt-1")}>
              {/* <Input type="button" className={styles.closeIcon} onClick={() => this.props.handleEdit(this.state.currentRowClicked, 0)} /> {/* this.onEdit(1) */}
              {!locArr[i].isVerified && (
                <button
                  className={classNames(styles.editCloseButton)}
                  onClick={() => this.props.handleEdit(this.state.currentRowClicked, 0)}
                >
                  <CloseSVG className={styles.editCloseIcon} />
                </button>
              )
              } {/* this.onEdit(1) */}
              <DeleteButton onClick={() => this.props.handleDelete(this.state.currentRowClicked)} />
            </div >)

          }

          else {
            dataRow.editOrDelete = ""

            dataRow.editOrDelete = (<div className={classNames(styles.editOptions, "mt-1")}>
              {/* <Input type="button" className={styles.closeIcon} onClick={() => this.props.handleEdit(this.state.currentRowClicked, 0)} /> {/* this.onEdit(1) */}
              {!locArr[i].isVerified && (
                <button
                  className={classNames(styles.editCloseButton)}
                  onClick={() => this.props.handleEdit(this.state.currentRowClicked, 0)}
                >
                  <CloseSVG className={styles.editCloseIcon} />
                </button>
              )
              } {/* this.onEdit(1) */}
              <DeleteButton onClick={() => this.props.handleDelete(this.state.currentRowClicked)} />
            </div >)

          }

        } else {

          dataRow.editOrDelete = ""
          dataRow.editOrDelete = (<div className={styles.editOptions}>
            {!locArr[i].isVerified && <button className={classNames(styles.editCloseButton)} onClick={() => this.props.handleEdit(this.state.currentRowClicked, 1)} >
              <EditSVG className={styles.closeIconSvg} />
            </button>}{/* this.onEdit(0) */}
            <DeleteButton onClick={() => this.props.handleDelete(this.state.currentRowClicked)} />
          </div >)
        }

        if ((locArr[i].tErrorNoPlanExist === true) || (locArr[i].tErrorPlanDetails === true)) {
          expandedRows.push(locArr[i].rowId);
        }

        dataArray.push(dataRow);
      }
    }

    const expandRow = {
      renderer: (row, rowIndex) => (
        <div className="errorMsg ml-5 pl-3">
          {
            this.displayErrorMsg(rowIndex)
          }
        </div >
      ),
      expanded: [...expandedRows],
      expandColumnPosition: 'right',
      className: styles.rowTableSelected
    };

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({ currentRowClicked: { rowIndex } });
      },
      onMouseEnter: (e, row, rowIndex) => {
        this.setState({ currentRowClicked: { rowIndex } });
      }
    };


    if (recCount === 0) {
      addProdColumns = [
        { dataField: "serial" },
        { dataField: "brandName" },
        { dataField: "prodDescription" },
        { dataField: "size" },
        { dataField: "modelNumber" },
        { dataField: "MFGWarranty" },
        { dataField: "series" },
        { dataField: "editOrDelete" }
      ]

      if (this.props.installationType === 'Residential') {
        for (var i = 0; i < addProdColumns.length; i++) {
          if (addProdColumns[i].dataField === 'size') {
            addProdColumns.splice(i, 1);
            break;
          }
        }
      }
      if (!this.props.isLennox) {
        for (var i = 0; i < addProdColumns.length; i++) {
          if (addProdColumns[i].dataField === 'series') {
            addProdColumns.splice(i, 1);
            break;
          }
        }
      }

    } else {
      addProdColumns = [

        {
          dataField: "serial",
          text: (<div className={styles.serialHeader}><Input className="mr-2" type="checkbox" id="serialNumHead" checked={this.state.allSelected} onClick={() => this.handleCheckAll()} />
            <label for="serialNumHead" className={classNames(styles.serialHeaderLabel, styles[accountName])}>Serial</label></div>),
        },

        {
          dataField: "brandName",
          text: "Brand",
        },
        {
          dataField: "prodDescription",
          text: "Description",
        },
        {
          dataField: "size",
          text: "Size",
        },
        {
          dataField: "modelNumber",
          text: "Model",
        },
        {
          dataField: "MFGWarranty",
          text: "MFG Parts Warranty",
        },
        {
          dataField: "series",
          text: "Series",
        },
        {
          dataField: "editOrDelete",
          text: "",
        },

      ];
      if (this.props.installationType === 'Residential') {
        for (var i = 0; i < addProdColumns.length; i++) {
          if (addProdColumns[i].dataField === 'size') {
            addProdColumns.splice(i, 1);
            break;
          }
        }
      }
      if (!this.props.isLennox) {
        for (var i = 0; i < addProdColumns.length; i++) {
          if (addProdColumns[i].dataField === 'series') {
            addProdColumns.splice(i, 1);
            break;
          }
        }
      }

    }

    return (
      <div className={styles.addProductsTable}>
        <BootstrapTable
          keyField="rowId"
          data={dataArray}
          columns={addProdColumns}
          bordered={false}
          bootstrap4
          bodyStyle={{ border: 'none !important' }}
          wrapperClasses={tableResponsiveLg}
          rowClasses={row => this.props.selected.includes(row.rowId) || this.state.allSelected ? styles.rowTableSelected : ""}
          rowEvents={rowEvents}
          expandRow={expandRow}
        />
      </div>
    );
  }
}

ProductTable.contextType = SessionContext