import React, { Component, useState, useEffect, useContext } from 'react';
import { Card, CardHeader, CardBody, Table, Input, Label } from "reactstrap";
import moment, { now } from "moment";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import styles from "./ContractInfo.module.css";
import { Spinner } from "react-bootstrap";
import classNames from "classnames";
import SimpleReactValidator from "simple-react-validator";
import RequestUpdateModal from "../Modals/RequestProductUpdate/RequestUpdateModal";
import LegacyAutoLogin from "../LegacyAutoLogin";
import { SessionContext } from "../../context/SessionContext";
import SnackBarAlert from "../shared/SnackBar/SnackBarAlert";
import SpinnerButton from "../shared/SpinnerButton/SpinnerButton";
import { FileClaim } from "./FileClaim";
import BootstrapTable from "react-bootstrap-table-next";
import CheckboxInput from "../shared/CheckboxInput/CheckboxInput";
//import { updatePartnerCase } from '../../components/Global/helpers';

let btnPrimary = "btn-primary";
let fileClaim = styles.fileClaim;

export default class AssetInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneCarriers: [],
            contract: this.props.contract,
            clientId: this.props.clientId,
            stateInfo: this.props.stateInfo,
            selectedAsset: [],
            selectedKey: 0,
            selectedCarrier: "",
            filingClaim: false,
            requestingUpdate: false,
            showAlert: false,
            firstCallMessage: false,

            selectedProduct: {
                // replace this object with a NULL default value after products are wired up.
                product: "Furnace",
                brand: "Rheem",
                model: "NPB-750",
                serialNumber: "111222333444",
                series: "Advanced",
            },

            legacyUrls: [],

            formData: {
                dealerId: "",
                dealerBrand: "",
                claimsOnly: false,
                dateTime: "",
                hash: "",
                snUserName: "VendorPortal",
                isAdmin: false,
                isDraftDocAdmin: false,
                accountID: "",
                sorg: "",
            },

            fileClaimClick: false,
            url: "",

            brands: [],
            url: "",
            isLennox: false,
            isRheem: false,
            assetNotSelected: true,
            enableRequestButton: false,
            legacyPageLoad: "",
            setFileClaimNotAllowed: false
        };

        this.getBrandName = this.getBrandName.bind(this);
        this.getAssetName = this.getAssetName.bind(this);
        this.toggleAsset = this.toggleAsset.bind(this);
        this.fileClaim = this.fileClaim.bind(this);
        this.getEligibleIndicator = this.getEligibleIndicator.bind(this);
        this.getAssetAttributeName = this.getAssetAttributeName.bind(this);
        this.handleCarrierChange = this.handleCarrierChange.bind(this);
        this.handleFirstCallMessage = this.handleFirstCallMessage.bind(this);


        this.validator = new SimpleReactValidator({
            element: (message) => (
                <div className="errorMsg">
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
            messages: {
                required: ":attribute is required.",
            },
        });

        this.toggleRequestingUpdate = this.toggleRequestingUpdate.bind(this);
    }

    async componentDidMount() {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                dealerId: this.context.dealerId,
            },
            isLennox: this.context.getProfile().isLennoxDealer,
            isRheem: this.context.getProfile().isRheemDealer,
            firstCallMessage: false,
        }));
        this.loadBrands();
    }

    getBrandName = (brandId) => {
        var brand = this.state.contract.brands.filter((b) => b.brandId === brandId);
        return typeof brand === "undefined" ? "" : brand[0].name;
    };

    getAssetName = (assetId) => {
        var asset = this.state.contract.assets.filter((a) => a.assetId === assetId);
        return typeof asset === "undefined" ? "" : asset[0].assetName;
    };

    getSeriesName = (seriesId) => {
        if (seriesId == 0) {
            return "";
        }

        var series = this.props.series.filter((a) => a.data == seriesId);
        return typeof series === "undefined" ? "" : series[0].display;
    };

    getEligibleIndicator = (indicator, row) => {
        var contractStatus = this.props?.contract?.statusId;
        var eligibilityIndicator = "";
        var cssClassName = "";

        //EligibleIndicator is E(green)  when the contract is active and outside of wait period(enforcement is <= current date)
        //Otherwise I(Red)
        switch (contractStatus) {
            case 1:
                eligibilityIndicator = "E";
                cssClassName = "success";

                var contractEnforcementDate = row?.contractAssetCoverageModel?.[0]?.enforcementDate ?? null;

                if (contractEnforcementDate != null) {
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, "0");
                    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
                    var yyyy = today.getFullYear();
                    today = mm + "/" + dd + "/" + yyyy;
                    var current = new Date(today);
                    var compareDate = new Date(
                        moment(contractEnforcementDate).format("MM/DD/YYYY")
                    );

                    //if the contract is active, ContractAsset is active, and within wait period - i.e the enforcement date is future date.
                    if (compareDate.getTime() > current.getTime() || row?.statusId !== 1) {
                        eligibilityIndicator = "I";
                        cssClassName = "danger";
                    }
                }

                break;
            default:
                eligibilityIndicator = "I";
                cssClassName = "danger";
        }

        if (indicator) return eligibilityIndicator;
        else return cssClassName;
    };

    toggleAsset = (row) => {
        let contractAssetId = row?.contractAssetId;

        var selected = this.state.contract.contractAssetModel.find(
            (x) => x.contractAssetId == contractAssetId
        );
        var contractAssetConfigModelValue =
            selected.contractAssetConfigurationModel != null &&
                selected.contractAssetConfigurationModel[0] != null
                ? selected.contractAssetConfigurationModel[0].value
                : "";
        var toRequestUpdateModal = {
            product: this.getAssetName(selected.assetId),
            brand: this.getBrandName(selected.brandId),
            model: selected.modelNumber,
            serialNumber: selected.serialNumber,
            series:
                this.state.isLennox == true
                    ? this.getSeriesName(contractAssetConfigModelValue)
                    : "",
            brandId: selected.brandId,
            seriesId:
                this.state.isLennox == true
                    ? parseInt(contractAssetConfigModelValue)
                    : "",
        };

        this.setState({
            selectedAsset: [contractAssetId],
            selectedProduct: toRequestUpdateModal,
            assetNotSelected: false,
            firstCallMessage: false,
        });
    };

    setKey = (key) => {
        this.setState({ selectedKey: key });
    };

    toggleRequestingUpdate = () => {
        this.setState({
            requestingUpdate: !this.state.requestingUpdate,
        });
        setTimeout(() => {
            this.setState({ showAlert: false });
        }, 6000);
    };

    async componentWillMount() {
        try {
            const response = await fetch("Asset/GetLegacyAccountUrls");
            const urls = await response.json();

            this.setState({
                legacyUrls: urls,
            });
        } catch (error) {
            this.setState({
                errorMessage: "Error fetching claim overview. Try again.",
            });
        }

        if (this.state.contract.contractAssetModel.length === 1) {
            const defaultAsset = this.state.contract.contractAssetModel[0];
            let selectedCarrier = "";
            const contractAssetConfiguration =
                this.state.contract.contractAssetModel.find(
                    ({ contractAssetId }) =>
                        contractAssetId === defaultAsset.contractAssetId
                ).contractAssetConfigurationModel; //.find(({contractAssetAttribute})=>contractAssetAttribute === 1);

            if (
                typeof contractAssetConfiguration === "undefined" ||
                contractAssetConfiguration === ""
            ) {
                selectedCarrier = "";
            } else {
                let carrierConfiguration = contractAssetConfiguration.find(
                    ({ contractAssetAttributeId }) => contractAssetAttributeId === 1
                );
                selectedCarrier =
                    typeof carrierConfiguration === "undefined"
                        ? ""
                        : carrierConfiguration.value;
            }

            console.log(selectedCarrier);
            //fixed but need testing thoroughly - Leaving it commented
            //this.setState({ selectedAsset: [defaultAsset.contractAssetId] });
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (!this.props.setCanFileAClaim && nextProps.setCanFileAClaim) {
            this.fileClaim();
        }
    }

    handleCarrierChange = (e) => {
        this.setState({ selectedCarrier: e });
    };

    getAssetAttributeName = (assetId) => {
        var assets = this.state.contract.assets;
        var asset = assets.filter((a) => a.assetId === assetId);

        if (typeof asset.assetAttributeMappingModel === "undefined") return "";
        else {
            return asset.assetAttributeMappingModel.attributeModel.attributeName;
        }
    };

    getAccountName = async () => {
        let masterprogramId = this.state.contract.riskProfile.masterProgramId;
        let accountName = "";
        const response = await fetch("account/getmasterprogram/" + masterprogramId);
        const master = await response.json();

        if (master != null) {
            this.setState({ accountId: master.accountModel.accountId });
            accountName = master.accountModel.accountName;
        }

        return accountName;
    };

    getLegacyAccounts = async () => {
        let accountName = await this.getAccountName();
        const response = await fetch("account/GetLegacyAccounts/" + accountName);
        const legacy = await response.json();
        console.log(legacy)
        if (legacy != null) {
            this.setState({ legacyAccountId: legacy.accountIds });
        }

        return legacy.accounts;
    };

    handleFileClaim = async () => {
        await this.getLegacyAccounts();

        await this.setState({
            legacyPageLoad: this.props.contract.legacyUrl.slice(
                this.props.contract.legacyUrl.indexOf(".com/") + 5
            ),
        });
        let dealerPortalUrl = this.props.contract.legacyUrl.slice(
            0,
            this.props.contract.legacyUrl.indexOf(".com/") + 5
        );
        var items = this.state.legacyPageLoad.split("/");
        if (items != null && items[0].toLowerCase() == "invoice") {
            let invoiceUrl =
                this.state.legacyPageLoad +
                this.props.contract.contractNumber +
                "/" +
                this.state.selectedProduct.serialNumber;
            await this.setState({
                legacyPageLoad: invoiceUrl,
            });
        }
        console.log(this.props, dealerPortalUrl, items, this.state)
        this.fileClaim(dealerPortalUrl);
    };

    fileClaim = async (url) => {
        console.log(this.state.formData, this.context, this.state, url)
        fetch("Asset/FileAClaim", {
            method: "POST",
            body: JSON.stringify({
                DealerID: this.props.contract.clientDealerId,
                DealerBrand: this.state.formData.dealerBrand,
                ClaimsOnly: this.state.formData.claimsOnly,
                DateTime: this.state.formData.dateTime,
                Hash: this.state.formData.hash,
                SNUserName: this.state.formData.snUserName,
                IsAdmin: this.state.formData.isAdmin,
                IsDraftDocAdmin: this.state.formData.isDraftDocAdmin,
                AccountID: this.state.legacyAccountId.toString(),
            }),
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then(async (result) => {
                        let form = result;
                        console.log(result, form, url);
                        form.accountID = url && form.accountID;
                        if (typeof result.accountID == "undefined") {
                            form.accountID = this.state.legacyAccountId.toString()
                        }
                        console.log(url && form.accountID, result.accountID, result.accountId)
                        await this.setState({ formData: form, fileClaimClick: true, url: url });
                    })
                }
            })
            .then((res) => {
                this.setState({ fileClaimClick: false });
            });
    };

    loadBrands = async () => {
        const res = await fetch("Brand/getAll");
        const data = await res.json();
        let filtCol = [];

        filtCol = data.map((s) => {
            return {
                data: s.brandId,
                display: s.name,
            };
        });

        this.setState({ brands: filtCol });
    };

    canFileClaim = () => {
        var legacyUrlIsValid =
            this.props.contract != null &&
            this.props.contract.legacyUrl != null &&
            this.props.contract.legacyUrl !== "";
        var dealerIdIsValid =
            this.props.contract != null &&
            this.props.contract.clientDealerId != null &&
            this.props.contract !== "";
        return (
            legacyUrlIsValid &&
            dealerIdIsValid &&
            this.props.contract.statusId === 1 &&
            !this.state.assetNotSelected
        );
    };

    handleOnSelect = (row, isSelect) => {
        const isIdExists = this.state.selectedAsset?.includes(row.contractAssetId);
        
        if (isSelect && !isIdExists) {
            this.setState(() => ({
                selectedAsset: [...this.state.selectedAsset, row.contractAssetId]
                //Assets are duplicate in few cases, need to do additional testing in DEV
                //selectedAsset: [...this.state.selectedAsset]
            }));
        } else {
            this.setState(() => ({
                selectedAsset: this.state.selectedAsset.filter(x => x !== row.contractAssetId)
            }));
        }
    }

    handleFirstCallMessage = (firstCallMessage) => {
        if (firstCallMessage === true) {
            this.setState({
                errorMessage: "A claim cannot be submitted because service has not been authorized on the contract.",
                firstCallMessage
            });
        }

    }

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.contractAssetId);
        if (isSelect) {
            this.setState(() => ({
                selectedAsset: ids
            }));
        } else {
            this.setState(() => ({
                selectedAsset: []
            }));
        }
    }

    render() {
        let contractAssets = this.state.contract.contractAssetModel;
        let configDescriptionKey = "2";
        let seriesId = 0;
        let sessionContext = this.context
        let newClaims = this.context?.getAccountFeatureFlag("hvac claim") ?? false;

        const defaultAsset = {};
        let legacyUrl =
            this.props.contract != null &&
                this.props.contract.legacyUrl != null &&
                this.props.contract.legacyUrl.length > 0
                ? this.props.contract.legacyUrl
                : "";

        let selectedCarrier = undefined;
        const selectedContractAssetConfiguration =
            this.state.contract.contractAssetModel?.find(
                ({ contractAssetId }) =>
                    contractAssetId === defaultAsset.contractAssetId
            ); //.find(({contractAssetAttribute})=>contractAssetAttribute === 1);
        let contractAssetConfiguration =
            typeof selectedContractAssetConfiguration === "undefined"
                ? undefined
                : selectedContractAssetConfiguration.contractAssetConfigurationModel;

        if (typeof contractAssetConfiguration === "undefined") {
            selectedCarrier = undefined;
        } else {
            let carrierConfiguration = contractAssetConfiguration.find(
                ({ contractAssetAttributeId }) => contractAssetAttributeId === 1
            );
            selectedCarrier =
                typeof carrierConfiguration === "undefined"
                    ? undefined
                    : carrierConfiguration.value;
        }
        const disableRowSelection = (this.props.contract.statusId === 4 || this.props.contract.statusId === 8);

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selectedAsset,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
            selectionRenderer: ({ mode, checked, disabled }) => (
                <div className="checkbox mb-2">
                    <Input className="mr-2" type="checkbox" checked={checked} />
                    <Label className={classNames("checkboxLabel", sessionContext.accountName)}></Label>
                </div>
            ),
            selectionHeaderRenderer: ({ mode, checked, disabled }) => (
                <div className="checkbox mb-2">
                    <Input className="mr-2" type="checkbox" checked={checked} />
                    <Label className={classNames("checkboxLabel", sessionContext.accountName)}></Label>
                </div >
            )
        };

        const selectRowLegacy = {
            mode: 'radio',
            clickToSelect: true,
            selected: this.state.selectedAsset,
            onSelect: this.toggleAsset
        }

        const cols = [
            {
                dataField: 'assetId',
                text: 'Product',
                formatter: (val, row, rowIndex) => <span>{this.getAssetName(val)} </span>,
            },
            {
                dataField: 'brandId',
                text: 'Brand',
                formatter: (val, row, rowIndex) => <span>{this.getBrandName(val)}</span>,
            },
            {
                dataField: 'modelNumber',
                text: 'Model',
            },
            {
                dataField: 'serialNumber',
                text: 'Serial',
            },
            {
                dataField: 'contractAssetConfigurationModel',
                text: 'Series',
                hidden: !this.state.isLennox,
                formatter: (val) => {
                    return this.getSeriesName(
                        (seriesId =
                            val.length == 0
                                ? 0
                                : val[0].value)
                    )
                },

            },
            {
                dataField: 'oemPartsCoverage',
                text: 'MFG Parts Warranty',
                formatter: (val) => <span>{`${val} ${val === 1 ? 'Year' : 'Years'}`}</span>
            },
            {
                dataField: null,
                text: 'CE',
                formatter: (val, row) => <div
                    className={
                        "squareBadge " + this.getEligibleIndicator(false, row)
                    }
                >
                    {this.getEligibleIndicator(true, row)}
                </div>
            },
        ]
        return (
            <Card>
                <CardHeader>
                    <h1 className="h4">Product Information</h1>
                </CardHeader>

                <CardBody>
                    <BootstrapTable
                        id="productInformationTable"
                        columns={cols}
                        data={contractAssets}
                        keyField={"contractAssetId"}
                        selectRow={newClaims ? selectRow : selectRowLegacy}
                        bordered={false}
                    />
                    <div className="btnWrapper">
                        {this.state.enableRequestButton == true && (
                            <button
                                className="btn-secondary"
                                onClick={this.toggleRequestingUpdate}
                                disabled={
                                    this.props.actionDisabled ||
                                    this.state.assetNotSelected ||
                                    this.props.contract.statusId !== 1
                                }
                            >
                                Request Update
                            </button>
                        )}
                      
                        {newClaims &&
                            <FileClaim
                                contract={this.state.contract}
                                clientId={this.props.clientId}
                                repairHours={this.props.repairHours}
                                coverageType={this.props.coverageTypeDescription}
                                reImbursementType={this.props.claimReimbursementType}
                                profile={this.context.getProfile()}
                                selectedAsset={this.state.selectedAsset}
                                handleFirstCallMessage={this.handleFirstCallMessage}
                                actionDisabled={this.props.actionDisabled}
                            />}

                       

                        {/*For Now we are removing the route to the legacy portal to file claims at the moment,
                         * but now that we're going to handle that on the SEU side, 
                         * we will just need to remove all that code to route to legacy and instead just open the submit claim tab
                         * ****
                         * */}
                        {!newClaims && <SpinnerButton
                            type="primary"
                            htmlType="submit"
                            disabled={this.props.actionDisabled || !this.canFileClaim()}
                            className={classNames(btnPrimary, fileClaim)}
                            onClick={this.handleFileClaim}
                            spinning={this.state.filingClaim}
                            text={"File Claim"}
                        />}
                    </div>
                    {this.state.firstCallMessage === true && <div className={classNames("errorMsg")}> {this.state.errorMessage} </div>}
                </CardBody>



                {this.state.requestingUpdate && !!this.state.selectedProduct && (
                    <RequestUpdateModal
                        product={this.state.selectedProduct}
                        isOpen={!!this.state.requestingUpdate}
                        onClose={() => this.toggleRequestingUpdate()}
                        brands={this.state.brands}
                        isLennox={this.state.isLennox}
                        isRheem={this.state.isRheem}
                        seriesList={this.props.series}
                        updateAlert={(showAlert = false) => this.setState({ showAlert })}
                    />
                )}

                {!newClaims && this.state.fileClaimClick && (
                    <LegacyAutoLogin
                        formData={this.state.formData}
                        url={this.state.url}
                        fileClaimClick={this.state.fileClaimClick}
                        legacyPageLoad={this.state.legacyPageLoad}
                    />
                )}
                <SnackBarAlert
                    content="Product update request submitted successfully."
                    onClose={() => this.setState({ showAlert: false })}
                    showSnackBar={this.state.showAlert}
                />
            </Card>
        );
    }
}
AssetInfo.contextType = SessionContext;
