import React from 'react';
import styles from './NeutralBadge.module.css';
import NumberFormat from 'react-number-format';

const NeutralInfoBadge = ({text, amount, currency}) => {
    return (
        <span className={styles.badge}>
            {text && text}
            {amount &&
                <span className={styles.amount}>
                    <NumberFormat 
                        value={amount} 
                        displayType={'text'} 
                        thousandSeperator={true} 
                        prefix={currency} 
                        decimalScale={2} 
                        fixedDecimalScale={true} 
                    />
                </span>
            }
        </span>
    )
}

export default NeutralInfoBadge;