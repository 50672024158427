import React from 'react';
import Cleave from 'cleave.js/react';
import { FormGroup } from 'reactstrap';



const CountryFormat = ({
    label,
    name,
    validator,
    validationFired,
    handleInputChange,
    value,
    required = true,
    validate = true }) => {
    return <FormGroup
        className={validationFired && validate && !validator.current.check(value, 'required|min:14|max:14') ? 'form-error' : ''}>
        <label className="form-label" for="companyPhone">
            {label}
        </label>
        {required && <span aria-hidden="true" className="required-indicator">*</span>}
        <Cleave
            placeholder=""
            name={name}
            className="form-control"
            id={name}
            options={{
                numericOnly: true,
                blocks: [0, 3, 3, 4],
                delimiters: ["(", ") ", "-"],
            }}
            value={value}
            onChange={handleInputChange}
        />
        {validate && validator.current.message(name, value, "required")}
        {validate && name === "companyPhone" && validator.current.message(name, value, "min:14|max:14", { messages: { min: 'Please enter a valid phone number.' } })}
        {validate && name === "companyFax" && validator.current.message(name, value, "min:14|max:14", { messages: { min: 'Please enter a valid fax number.' } })}
    </FormGroup>
};

export default CountryFormat;