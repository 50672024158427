import React from 'react'
import { useState, useEffect } from "react";
import Cleave from "cleave.js/react";
import styles from "./Formatters.module.css";
import { calc } from "../Calculators";
import { useClaimInvoiceStore } from "../lib/state";
import { RepairActionModal } from '../../Modals/RepairActionModal/RepairActionModal';
import { useRef } from 'react';
import { useContext } from 'react';
import { SessionContext } from '../../../context/SessionContext';

export const QuantityInput = ({ cell, row, rowIndex, fieldName, tableType }) => {
    const [value, setValue] = useState(cell);
    const [repairHrs, setRepairHrs] = useState(null);    
    const { tabDispatch, getCurrentTabAndSubtab } = useContext(SessionContext);
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const cleaveRef = useRef();
    const invoiceStoreState = useClaimInvoiceStore((state) => state);
    const currentInvoice = useClaimInvoiceStore((state) => state[invoiceId]);
    const { showRepairError } = currentInvoice?.['labor']?.data?.length > 0 ? currentInvoice?.['labor']?.data?.[0] : false;
    const { repairNonCovered } = currentInvoice?.['labor']?.data?.length > 0 ? currentInvoice?.['labor']?.data?.[0] : false;
    const [showError, setShowError] = useState(showRepairError);
    const [showNCError, setShowNCError] = useState(repairNonCovered);
   
    const selectedRepair = currentInvoice?.['labor']?.data[0]?.repair?.selected[0]?.display;

    useEffect(() => {
        setValue(cell)
    }, [cell])

    const acceptChanges = (e) => {
        const { value } = e.target;
        const maxValue = currentInvoice?.['labor']?.data?.[rowIndex]?.repairHours;
        let num = Number(value.replace(/,/g, ""));
        if (maxValue === 0  && num > maxValue && tableType === 'labor' && fieldName === 'hours') {
            invoiceStoreState.updateLaborRow(invoiceId, tableType, rowIndex, {
                [fieldName]: num ?? '',
                total,
                repairNonCovered: true,
                hrsEdited: true
            });
            setShowNCError(true)
            return;
        }

        if (num > maxValue && tableType === 'labor') {   
            invoiceStoreState.updateLaborRow(invoiceId, tableType, rowIndex, {
                [fieldName]: num ?? '',
                total,
                showRepairError: true,
                hrsEdited: true
            });
            setShowError(true)         
            return;
        }
       
        if ((num === '0' || num <= 0) && tableType === 'labor' && fieldName === 'hours' && maxValue === 0) {
            num = '0';
            setValue('0');
        }
        if ((num === '0' || num <= 0) && tableType === 'labor' && fieldName === 'hours' && maxValue !== 0) {
            num = '0.5';
            setValue('0.5');
        }
        if ((num === '0' || num <= 0) && (tableType === 'parts' || tableType === 'partsMarkup') && fieldName === 'qty') {
            num = '1';
            setValue('1');
        }
        cleaveRef.current.element.parentElement.style.boxShadow = "none";
        const total = calc[tableType]({ ...row, [fieldName]: num ?? '' });
        if (tableType === 'labor') {
            invoiceStoreState.updateLaborRow(invoiceId, tableType, rowIndex, { 
                [fieldName]: num ?? '', 
                total, 
                showRepairError: false,
                repairNonCovered: false,
                hrsEdited: true
            });
            setShowError(false);
            setShowNCError(false)
            return;
        }

        if (tableType === 'labor') {
            invoiceStoreState.updateLaborRow(invoiceId, tableType, rowIndex, { 
                [fieldName]: num ?? '', 
                total, 
                repairNonCovered: false,
                hrsEdited: true
            });
          
            return;
        }
        if (tableType === 'partsMarkup') {
            invoiceStoreState.updatePartsMarkupRow(invoiceId, tableType, rowIndex, {
                [fieldName]: num ?? '',
                total,
            });
            return;
        }
        if (tableType === 'refrigerant') {
            invoiceStoreState.updateRefrigerantRow(invoiceId, tableType, rowIndex, {
                [fieldName]: num ?? '',
                total,
            });
            return;
        }
        invoiceStoreState.updateRow(invoiceId, tableType, rowIndex, { [fieldName]: num ?? '', total });
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        setValue(value);
        setShowError(false);
        setShowNCError(false)
    };


    useEffect(() => {
        if (row.showRepairError || row.repairNonCovered) {
            if (cleaveRef && cleaveRef.current && fieldName === 'hours') {
                cleaveRef.current.element.parentElement.style.boxShadow = "inset 0px 0px 1px 1px red";
            }
        } else {
            if (cleaveRef && cleaveRef.current && cleaveRef.current.element) {
                cleaveRef.current.element.parentElement.style.boxShadow = "none";
            }
        }
    }, [row.showRepairError, row.repairNonCovered])

    return (
        <>
            <Cleave
                ref={cleaveRef}
                name={fieldName}
                className={styles.tableInput}
                value={value}
                onChange={handleInputChange}
                onBlur={acceptChanges}
                options={{
                    numeral: true
                }}
            />
        </>
    );
};
