import React from 'react'
import { QuantityInput, CurrencyField, DeleteRowButton, DependentDropdown } from "../Formatters";
import styles from './TableConfig.module.css';
import { widthFormatter } from "../Formatters/widthFormatter";

export const TRIP_COLS = [
    {
        text: '',
        dataField: '',
        isDummyColumn: true,
        style: {
            ...widthFormatter('deleteTripCol'),
            padding: 0
        },
        formatter: (col, row, rowI) => <DeleteRowButton rowId={row.id} rowIndex={rowI} tableType='trip' />,
        align: 'center',
        footerClasses: [styles.footer, styles.noborder, styles.trashIcon]
    },
    {
        dataField: "qty",
        text: "Qty",
        headerAlign: 'center',
        align: 'center',
        style: widthFormatter('qtyTripCol'),
    },
    {
        dataField: "trip",
        text: "Trip",
        style: widthFormatter('tripCol'),
    },
    {
        dataField: "reason",
        text: "Reason",
        align: 'left',
        style: () => ({
            paddingLeft: '4px!important',
           ...widthFormatter('tripReasonCol')
        }),
        formatter: (col, row, rowI) => {
            if (rowI == 0) {
                return 'Initial Trip'
            }
            if (col?.options) return <DependentDropdown cell={col} row={row} rowIndex={rowI} tableType={'trip'} fieldName={"reason"} />
            return col;
        }
    },
    {
        text: '',
        dataField: '',
        isDummyColumn: true,
        style: widthFormatter('dummyTripCol'),
    },
    {
        dataField: "tripRate",
        text: "Trip Rate",
        headerAlign: 'left',
        align: 'right',
        style: widthFormatter('invoiceTotalCol'),
        footerStyle: widthFormatter('totalCol'),
        formatter: (col, row, i) => (
            <CurrencyField col={col} row={row} rowIndex={i} fieldName="tripRate" tableType="trip" />
        )
    },
    {
        dataField: "total",
        text: "Total",
        headerAlign: 'left',
        align: 'right',
        style: widthFormatter('invoiceTotalCol'),
        formatter: (col, row, i) => {
            const total = row.qty * row.tripRate;
            return <CurrencyField col={total} row={row} rowIndex={i} fieldName="total" tableType="trip" />
        }
    }
];
