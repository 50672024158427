import React from "react";
import classNames from 'classnames';
import DownloadButton from "../shared/IconButtons/DownloadButton";
import PrintButton from "../shared/IconButtons/PrintButton";
import styles from "../../components/Resources/Resources.module.css";


const ContentPanelLayout = ({ title, pdf, displayPrintAndDownload, children, subHeading = "" }) => {
    return (
        <>
            <div className={classNames(styles.screenHeader)}>
                <h1 className="h5">{title}</h1>
                {displayPrintAndDownload && (
                    <div className={classNames(styles.downloadHov, styles.btnDiv, "mt-n2")}>
                        <DownloadButton id="downloadPDF" onClick={() => window.open(pdf, '_blank')} />
                        <PrintButton id="printdPDF" onClick={() => window.open(pdf, '_blank').print()} />
                    </div>
                )}
            </div>
            {subHeading && <p className={classNames(styles.headDescription, "mt-2")}>{subHeading}</p>}
            {children}
        </>
    )
}
export default ContentPanelLayout;