export const getConfiguration = (accountId, keyName) => {
  return new Promise((resolve, reject) => {
    fetch(`configuration/GetConfigByName/${accountId}/${keyName}`)
      .then(res => res.json())
      .then(data => {
        if (data) {
          resolve(data)
        }
        else {
          reject("Unable to get configuration");
        }
      })
      .catch((e) => {
        reject("Unable to get configuration");
      })
  });


  
}