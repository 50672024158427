import React, { useState } from "react";
import { useClaimInvoiceStore } from "../lib/state";
import styles from "./Formatters.module.css";

export const CaptionComponent = ({ title, preapprovedAmt }) => {
    const currencyFormat = preapprovedAmt && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(preapprovedAmt)
    return (
        <div className={`${styles.sectionHeaderRow} ${preapprovedAmt && styles.topTable}`}>
            <p className={styles.capitalize}>{title}</p>
            {preapprovedAmt ?
                <p>
                    {`Pre-Authorized: ${currencyFormat}`}
                </p>
                :
                ""
            }
        </div>
    )
}

