import React, { useState, forwardRef, useEffect, useContext } from 'react';
import { CardBody, Row, Col, Progress, FormGroup } from 'reactstrap';
import styles from './InsuranceDocument.module.css';
import StandardInput from "../shared/StandardInput/StandardInput";
import CheckboxInput from '../shared/CheckboxInput/CheckboxInput';
import UploadDocs from '../shared/UploadDocs/UploadDocs';
import NumberFormat from 'react-number-format';
import { SessionContext } from '../../context/SessionContext';
import { handleFileInfo, removeUploadedFile, storeDocsToSessionStorage } from '../Global/DocumentUploadHelper';

const maxFilesAllowed = 1; // maximum number of files that can be uploaded at once
const sessionStorageBaseId = "insuranceDoc"
const insuranceDocument = "insuranceDocument"
const docFormTypes = {
  GeneralLiabilityInsurance: 'GLI',
  WorkersCompensationInsurance: 'WCI'
}

const InsuranceDocument = forwardRef((props, ref) => {
  const context = useContext(SessionContext);
  const { validator, errors } = props;
  const [forceUpdate, setForceUpdate] = useState(0);
  const sessionItem = JSON.parse(sessionStorage.getItem(insuranceDocument)) || {};
  const [fileUploadDetails, setFileUploadDetails] = useState([]);
  const [storageList, setStorageList] = useState([]);
  const [insuranceDocList, setInsuranceDocList] = useState({
    GLI: [],
    WCI: [],
  });

  const [formData, setFormData] = useState([]);
  const docTypeId = 31; // Insurance doc

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      validator.showMessages();
      setForceUpdate(1);
    }
  }, [errors, validator]);

  useEffect(() => {
    const sessionValue = JSON.parse(sessionStorage.getItem(insuranceDocument));

    if (sessionValue != undefined) {
      setStorageList([]);
      setFormData(sessionValue.formData);
      setSessionDocList(sessionValue.formData)
    }
  }, []);

  useEffect(() => {
    const sessionIds = storeDocsToSessionStorage(fileUploadDetails, sessionStorageBaseId);
    setStorageList(sessionIds);

  }, [fileUploadDetails]);

  useEffect(() => {
    sessionStorage.setItem(insuranceDocument, JSON.stringify({
      forceUpdate, formData, storageList
    }));

  }, [forceUpdate, formData, storageList]);


  const handleInputChange = (e) => {
    let { target: { name, value } } = e;
    if (name === "policyNumber") {
      value = value.replace(/[^a-z0-9]/gi, ''); // Remove non-alphanumeric characters
    }
    setFormData({ ...formData, [name]: value });
  }

  const handleCheckBoxChange = (e) => {
    let { id, checked } = e.target;
    setFormData({
      ...formData,
      [id]: checked,
    });
  }

  const handleFileChange = (files, name) => {
    setFormData({ ...formData, [name]: files });

    if (files.length == 0) {
      removeUploadedFile(undefined, fileUploadDetails, storageList);
    }
  }

  const handleFileUpload = (fileInfo, data, docFormType, docFormIndex, documentTypeId, fileUploadDetails) => {
    const uploadDetails = handleFileInfo(fileInfo, data, docFormType, docFormIndex, documentTypeId, fileUploadDetails);
    const sessionIds = storeDocsToSessionStorage(uploadDetails, sessionStorageBaseId);

    setStorageList(sessionIds);
    context.setUploadedDocs(uploadDetails, sessionStorageBaseId);
    setFileUploadDetails(uploadDetails);
    updateInsuranceDocList(uploadDetails);
  }

  const updateInsuranceDocList = (docs) => {
    setInsuranceDocList({
      GLI: docs?.filter((x) => x?.docFormType == docFormTypes.GeneralLiabilityInsurance) ?? [],
      WCI: docs?.filter((x) => x?.docFormType == docFormTypes.WorkersCompensationInsurance) ?? [],
    })
  }
  
  const setSessionDocList = (sessionVal) => {
    setInsuranceDocList({
      GLI: sessionVal?.liabilityIns?.filter((x) => x?.docFormType === docFormTypes.GeneralLiabilityInsurance) ?? [],
      WCI: sessionVal?.workersIns?.filter((x) => x?.docFormType ===  docFormTypes.WorkersCompensationInsurance) ?? [],
    })
  } 

  return (
    <>
      <Col xs="12" md="12" className={`titleRow p-4`}>
        <h2 className="h4 m-0">Insurance Documents</h2>
      </Col>
      <hr className="m-0 w-100" />
      <CardBody>
        <Row className='m-0'>
          <p className='m-0'>
            You must provide AIG with a copy of your current Certificate of Insurance, which shows workers compensation and general liability insurance of no less than $500,000 (USD).
            <span className='font-weight-bold'> Certificate can be uploaded later, but must be submitted within ninety (90) days of your enrollment.</span>
          </p>
          <Col xs="12" md="12" className="titleRow pl-0 pt-4 pb-4">
            <h2 className="h4 m-0">General Liability Insurance</h2>
          </Col>

          <Row className='w-100 multiColWrapper relative'>
            <Col xs="12" md="4" className="col-5ths">
              <FormGroup>
                <label className="form-label" for="insuredAmount">Insured Amount</label>
                <NumberFormat
                  aria-labelledby="insuredAmount"
                  className="form-control"
                  displayType={'input'}
                  prefix={"$"}
                  thousandSeparator={true}
                  placeholder=""
                  id="insuredAmount"
                  aria-required="true"
                  name="insuredAmount"
                  value={formData.insuredAmount}
                  allowNegative={false}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4" className="col-5ths">
              <StandardInput
                required={false}
                ariaLabelAndFieldId="policyNumber"
                labelCustomClass="font-weight-bold"
                label="Policy Number"
                fieldName="policyNumber"
                value={formData.policyNumber}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <p className='w-100 form-label'>Please upload a copy of your general liability insurance of no less than $500,000.</p>
          <UploadDocs
            onFileChange={(files) => handleFileChange(files, "liabilityIns")}
            handleFileInfo={handleFileUpload}
            maxFiles={maxFilesAllowed}
            prevFileUploadDetails={insuranceDocList.GLI}
            docFormType={docFormTypes.GeneralLiabilityInsurance}
            documentTypeId={docTypeId} />
          <hr className="m-0 w-100" />
          <Col xs="12" md="12" className={`titleRow pl-0 pt-4 pb-4`}>
            <h2 className="h4 m-0">Workers Compensation Insurance</h2>
          </Col>

          <p className='w-100 form-label'>Please upload a copy of your my workers' compensation insurance.</p>
          <UploadDocs
            onFileChange={(files) => handleFileChange(files, "workersIns")}
            handleFileInfo={handleFileUpload}
            maxFiles={maxFilesAllowed}
            prevFileUploadDetails={insuranceDocList.WCI}
            docFormType={docFormTypes.WorkersCompensationInsurance}
            documentTypeId={docTypeId} />
          <CheckboxInput
            id="isWorkerCompensation"
            customClass='mr-2 mb-2 w-100'
            checked={formData.distributor}
            handleCheck={handleCheckBoxChange}>
            My state does not require workers compensation by law.
          </CheckboxInput>
          {formData.isWorkerCompensation &&
            <Col xs="12" md="4" className={`col-5ths mt-0 ${styles.checkbxInpWrapper}`}>
              <StandardInput
                hasError={validator.message("NoofEmp", formData.NoofEmp, "required")}
                ariaLabelAndFieldId="NoofEmp"
                labelCustomClass="font-weight-bold"
                label="Number of Employees"
                fieldName="NoofEmp"
                value={formData.NoofEmp}
                onChange={handleInputChange}
                formGroupClassName="mb-0"
                validator={validator.message("numberOfEmployees", formData.NoofEmp, "required")}
              />
            </Col>
          }
        </Row>
      </CardBody>
    </>
  );
});

export default InsuranceDocument;