import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import classNames from "classnames";
import styles from './SimpleTable.module.css';

/**
 * Simple table for usage cases where Label is in LEFT column
 * and Value is in RIGHT column.
 * 
 * Each row of the table should be a TUPLE, where: 
 *  - the first item is the label, can be string OR JSX Element (if icon/tooltip needed)
 *  - the second item is the content, and can be any JS value, expression or JSX element.
 */


// UNCOMMENT THESE THREE LINES FOR MIGRATION TO TS
// interface Props {
//     tableRows: [string | JSX.Element, any][];
// }
// const AIGDetailsTable = ({tableRows}:Props) => (
const AIGSimpleTable = ({ tableRows, tableStyle }) => {
    PropTypes.checkPropTypes(AIGSimpleTable, AIGSimpleTable.propTypes, 'props', 'Simple Table')
  return (
    <Table className={classNames(styles.table, tableStyle)}>
            <tbody className={styles.tableStriped}>
                {tableRows.map((row, i) => (
                    <tr className={row.hover && styles.tableHover}>
                        <th scope="row" key={i}>{row.label}</th>
                        {Array.isArray(row.value) ? (
                            <td>
                                {row.value.map(e => <p>{e}</p>)}
                            </td>
                        ) : (
                            <td>
                                {!!row.value && row.value}
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}
// TS WILL REPLACE THIS
AIGSimpleTable.propTypes = {
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOf([
        PropTypes.string,
        PropTypes.element
      ]).isRequired,
      value: PropTypes.node.isRequired
    })
  )
}

export default AIGSimpleTable;