import React from 'react';
import styles from '../Adjudication.module.css'

const EventTotalsRow = ({ req, auth }) => {
    const currencyFormat = (val) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val)
    return (
        <div className={styles.EventTotalsRow}>
            <div>Invoice Total</div>
            <div>{currencyFormat(req)}</div>
            {/*<div>{currencyFormat(auth)}</div>*/}
        </div>
    )
}

export default EventTotalsRow