import React, { createContext } from "react";
import { AuthContext } from "./AuthProvider";
import { SessionContext } from "./SessionContext";

export const ContextCombined = createContext(null);

const CombinedContextProvider = props => {
    return (
        <AuthContext.Consumer>
            {authContext => (
                <SessionContext.Consumer>
                    {sessionContext => (
                        <ContextCombined.Provider value={{ authContext, sessionContext }}>
                            {props.children}
                        </ContextCombined.Provider>
                    )}
                </SessionContext.Consumer>
            )}
        </AuthContext.Consumer>
    );
};
export default CombinedContextProvider;