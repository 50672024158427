import React, { Component, Fragment } from "react";
import { Container, FormGroup, Row, Col, CardBody } from "reactstrap";
import styles from "./CoveragePreferences.module.css";
import classNames from "classnames";
import { PlanOptions } from "../../Sales/PlanDetails/PlanOptions";
import { ReactComponent as EditIcon } from '../../../assets/images/Edit_Icon.svg';

export class CoveragePreferences extends Component {
	constructor(props) {
		super(props);
		this.state = {
			profileModel: this.props.profileModel,
			savedCoveragePreferences: false,
			planState: {
				selectedLabor: undefined,
				selectedCoverage: undefined,
				selectedYears: undefined
			},
			savedCoverage: {
				selectedLabor: undefined,
				selectedCoverage: undefined,
				selectedYears: undefined
			},
			accountTierCoverageTermMappings: [],
			tiers: [],
			coverages: [],
			terms: [],
			isTermShown: false,
			coveragePreferencesConfiguration: this.props.profileModel.dealerConfigurations.find(c => c.dealerAttributeId === 1)
		}
	}

	componentDidMount = () => {
		this.getCoverageData();
		this.getSavedCoverage();
	}

	getCoverageData = () => {
		this.getTiersByAccount();
		this.getCoverages();
		this.getTerms();
	}

	getTiersByAccount = () => {
		fetch(`tier/GetTierByAccountId/${this.state.profileModel.accountId}`)
			.then(response => response.json())
			.then(data => {
				var tiers = data.map(t => {
					return {
						tierId: t.tierId,
						tierName: t.tierName,
					}
				});
				this.setState({ tiers: tiers });
			});
	}

	getCoverages = () => {
		fetch(`tier/getallcoverage`)
			.then(response => response.json())
			.then(data => {
				this.setState({ coverages: data });
			});
	}

	getTerms = () => {
		fetch(`contract/GetAllTerms`)
			.then(response => response.json())
			.then(data => {
				let terms = data.map(t => {
					return {
						termYears: (t.termInDays / 365),
						termId: t.termId
					}
				});
				this.setState({ terms: terms });
			});
	}

	getSavedCoverage = () => {
		if (this.state.coveragePreferencesConfiguration) {
			fetch(`Dealer/GetAccountTierCoverageTermMappingById/${this.state.coveragePreferencesConfiguration.value}`)
				.then(response => response.json())
				.then(data => {
					this.setState({
						savedCoverage: {
							selectedLabor: data?.tierId,
							selectedCoverage: data?.coverageTypeId,
							selectedYears: data?.termId
						},
						planState: {
							selectedLabor: data?.tierId,
							selectedCoverage: data?.coverageTypeId,
							selectedYears: data?.termId
						},
						savedCoveragePreferences: data !== undefined
					});
				});
		}
	}

	isCanada = () => {
		return this.state.profileModel.country === "Canada";
	}

	handlePlanOptionSelection = (e) => {
		this.setState({
			planState: {
				selectedLabor: e.tierId,
				selectedCoverage: e.coverageTypeId,
				selectedYears: e.termId
			}
		});
	}

	onTypeRadioChange = (newData) => {
		this.setState({ ...newData });
	}

	onTermRadioChange = (newData) => {
		this.setState({ ...newData });
	}

	onPlanTypeSelected = () => {
		this.setState({ isTermShown: true });
	}

	onSavePreferences = () => {

		if (!this.refs.planOptions.formIsValid()) {
			return;
		}

		var request = {
			accountId: this.state.profileModel.accountId,
			tierId: this.state.planState.selectedLabor,
			coverageTypeId: this.state.planState.selectedCoverage,
			termId: this.state.planState.selectedYears
		}

		fetch(`Dealer/SaveCoveragePreferences/${this.state.profileModel.dealerId}`, {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(request)
		})
			.then(response => response.json())
			.then(data => {
				this.props.updateDealerConfiguration(data);
				this.setState({
					savedCoveragePreferences: !this.state.savedCoveragePreferences,
					savedCoverage: this.state.planState
				});
			});
	}

	onCancel = () => {
		if (this.state.savedCoverage.selectedYears !== undefined) {
			this.setState({
				savedCoveragePreferences: !this.state.savedCoveragePreferences,
				planState: this.state.savedCoverage,
			});
		} else {
			this.setState({
				planState: {
					selectedLabor: undefined,
					selectedCoverage: undefined,
					selectedYears: undefined
				},
				savedCoveragePreferences: !this.state.savedCoveragePreferences
			}, () => this.setState({ savedCoveragePreferences: !this.state.savedCoveragePreferences }));
		}
	}

	render() {
		const { savedCoveragePreferences, tiers, coverages, terms, savedCoverage } = this.state;
		return (
			<CardBody className="p-0">
				<div className={styles.coveragePrefHead}>
					<h4 className="subHead">My Coverage Preferences</h4>
					{savedCoveragePreferences && <button onClick={() => this.setState({ savedCoveragePreferences: !savedCoveragePreferences })} className={styles.editBtn}>
						<EditIcon />
						Edit
					</button>}
				</div>
				<p>Choose the default coverage you would like pre-selected on all future sales{this.props.accountName !== 'ge' && ` (excluding ${this.props.advantageName})`}. Select labor reimbursement to view plan options.</p>
				<FormGroup>
					{savedCoveragePreferences ? <Row className="multiColWrapper">
						<Col xs="12" md="4" lg="2" className="col-5ths">
							<label for="typeOfInst" className="form-label">{this.isCanada() ? "Labour Reimbursement" : "Labor Reimbursement"}</label>
							<span aria-hidden="true" className="required-indicator">*</span>
							<p>{tiers.find(t => t.tierId === savedCoverage.selectedLabor)?.tierName}</p>
						</Col>
						<Col xs="12" md="4" lg="2" className="col-5ths">
							<label for="typeCoverage" className="form-label">Type</label>
							<p>{coverages.find(c => c.coverageTypeId === savedCoverage.selectedCoverage)?.description}</p>
						</Col>
						<Col xs="12" md="4" lg="2" className="col-5ths">
							<label for="typeCoverage" className="form-label">Term</label>
							<p>{terms.find(t => t.termId === savedCoverage.selectedYears)?.termYears} {terms.find(t => t.termId === savedCoverage.selectedYears)?.termYears > 1 ? "Yrs" : "Yr"}</p>
						</Col>
					</Row> :
						<FormGroup>
							<PlanOptions
								ref="planOptions"
								isAdvantage={true}
								verticalAlign={true}
								isCoveragePreferences={true}
								accountId={this.props.profileModel.accountId}
								onAdvantagePlanOptionChange={this.handlePlanOptionSelection}
								onTypeChange={this.onTypeRadioChange}
								onTermChange={this.onTermRadioChange}
								country={this.props.profileModel.country}
								onPlanTypeSelected={this.onPlanTypeSelected}
								language={this.isCanada() ? "en-CA" : "en-US"}
								assetPackage={this.state.planState}
								dealerProfile={this.props.profileModel}
								isCanadaState={this.isCanada() ? true : false}
							/>
						</FormGroup>
					}
					{!savedCoveragePreferences && <div className="btnWrapper justify-content-end d-flex">
						<button className="btnLink" onClick={this.onCancel}>Cancel</button>
						<button className="btn-primary" onClick={this.onSavePreferences}>Save</button>
					</div>}
				</FormGroup>
			</CardBody>
		);
	}
}
