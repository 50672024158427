export const accountDocumentTypes = {
    TermsAndConditions: 1,
    AdvantageTermsAndConditions: 2,
    ConsumerTermsAndConditions: 3,
    ReimbursementSchedules: 4,
    PlanPricing: 5,
    ContractTransfer: 6,
    CustomerCancellation: 7,
    CancellationAndRewritten: 8,
    ConsumerBrochure: 9,
    DealerBrochure: 10,
    WhySell: 11,
    ProgramUpdates: 12,
    ManualClaimForm: 13,
    RepairTimesMatrix: 14,
    DealerEnrollment: 15,
    ClaimRepairHours: 20,
    ClaimRepairCodes: 21,
    GettingStarted: 22

}

export const getCoCDoc = (contractNumber) => {
    fetch(`document/GetCertificateBlob/${contractNumber}`,
        {
            method: 'get',
            headers: { 'Content-Type': 'application/pdf' }
        })
        .then(res => res.blob())
        .then(data => {
            const url = window.URL.createObjectURL(data);
            window.open(url);
        });
}

export const createSCCURequest = (contractId) => {
    fetch(`document/createSCCURequest/${contractId}`)
        .then(res => res.json())
        .then(data => {
            return data;
        })
}

export const getDealerDocument = async (docType, openLink, dealerProfile, callbackMethod, key = '') => {
    var docPath = dealerProfile?.dealerDocumentLinks[docType]?.find(d => typeof d !== 'undefined' && d.toLowerCase().includes(key.toLowerCase()));

    if (docPath != null) {
        var resultModel = {
            success: false,
            message: '',
            documentPath: '',
            documentURL: ''
        }

        fetch(docPath)
            .then(res => {
                if (res.status === 200) {
                    resultModel.documentURL = res.url;
                    return res.arrayBuffer()
                }
                else {
                    return undefined;
                }
            })
            .then(data => {
                if (data !== undefined) {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    resultModel.success = true;
                    resultModel.message = '';
                    resultModel.documentPath = fileURL;

                    if (openLink) {
                        const link = document.createElement('a');
                        link.href = fileURL;
                        link.target = "_blank";
                        link.click();
                    }

                    if (callbackMethod != null)
                        callbackMethod(resultModel);
                }
                else {
                    resultModel.success = false;
                    resultModel.message = 'Error fetching file.';
                    resultModel.documentPath = '';

                    if (callbackMethod != null)
                        callbackMethod(resultModel);
                }
            });
    }
}


export async function getDocumentTypes() {
    const res = await fetch(`document/getDocumentTypes/hvac`);
    const data = await res.json();
    return data;
}

export async function getDocumentByContractNumber(contractNumber) {
    fetch(`document/getDocumentsByContractNumber/hvac/${contractNumber}`)
        .then(res => res.json())
        .then(data => {
            return data;
        })
}

export async function getDocumentsByWarrantyObject(documentTypeName, warrantyObjectTypeId, warrantyObjectValue, clientId) {
    return fetch(`document/GetDocumentsByWarrantyObject/${documentTypeName}/${warrantyObjectTypeId}/${warrantyObjectValue}/${clientId}`)
        .then(async (res) => {
            return res?.ok ? await res?.json() : null;
        }).catch((ex) => {
            return null;
        });
}