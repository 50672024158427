import React from 'react';
import { Badge } from 'reactstrap';

const AIGStatusBadge = ({
    statusId,           // NUMBER RETURNED FROM BACKEND
    statusType,         // USE WITH STATUS ID TO GET CORRECT COLOR/CONTENT. MUST BE ONE OF THE OBJECT KEYS IN THE "GET STATUS PROPS" OBJ BELOW
    alignLeft = false,
    color,        // IF BADGE NEEDED FOR CUSTOM PURPOSE. FOLLOW BOOTSTRAP COLORING PROTOCOL
    content       // IF BADGE NEEDED FOR CUSTOM PURPOSE
}) => {
    // ADD MORE STATUS SETS HERE AS NEEDED BASED ON CONTEXT
    const getStatusProps = {
        contract: () => {
            switch (statusId) {
                case 1: 
                    return { color: 'success', content: 'Active'};
                case 2: 
                    return { color: 'warning', content: 'Wait'};
                case 3: 
                    return { color: 'danger', content: 'Fulfilled'};
                case 4: 
                    return { color: 'danger', content: 'Cancelled'};
                case 6: 
                    return { color: 'danger', content: 'Suspended'};
                case 7: 
                    return { color: 'danger', content: 'Expired'};
                case 8: 
                    return { color: 'success', content: 'Authorized'};
                default:
                    console.error(`STATUS BADGE ERROR: Contract Status ID ${statusId} has no mapped value`)
                    return { color: 'danger', content: 'Status Unknown'}
            }
            // return { color: 'primary', contnet: 'Status Unknown'}
        },
        claim: () => {
            switch (statusId) {
                case 1:
                    return {color: 'success', content: 'Open'};
                case 2:
                    return {color: 'danger', content: 'Closed'};
                case 3:
                    return {color: 'warning', content: 'Pending'};
                case 4:
                    return {color: 'success', content: 'Saved'};
                default:
                    console.error(`STATUS BADGE ERROR: Claim Status ID ${statusId} has no mapped value`)
                    return { color: 'danger', content: 'Status Unknown'}
            }
            // return { color: 'primary', contnet: 'Status Unknown'}
        },
        event: () => {
            switch (statusId) {
                case 1: 
                    return { color: 'success', content: 'Open'};
                case 2: 
                    return { color: 'danger', content: 'Closed'};
                case 3: 
                    return { color: 'warning', content: 'Pending'};
                case 4: 
                    return { color: 'danger', content: 'Saved'};
                case 5: 
                    return { color: 'danger', content: 'Cancelled'};
                case 6: 
                    return { color: 'success', content: 'Completed'};
                case 7: 
                    return { color: 'danger', content: 'Denied' };
                case 8:
                    return { color: 'warning', content: 'Abandoned' }
                case 9:
                    return { color: 'success', content: 'Paid' }
                case 10:
                    return { color: 'warning', content: 'Partial' }
                case 11:
                    return { color: 'warning', content: 'Pending Settlement' }
                case 12:
                    return { color: 'danger', content: 'Closed No Activity' }
                case 13:
                    return { color: 'success', content: 'Accepted' }
                case 14:
                    return { color: 'danger', content: 'Rejected' }
                case 15:
                    return { color: 'warning', content: 'Open/Unaccepted' }
                case 16:
                    return { color: 'danger', content: 'Deleted'}
                default:
                    return { color: 'danger', content: 'Status Unknown'}
            }
        },
        notification: () => {
            switch (statusId) {
                case 1: 
                    return { color: 'danger', content: 'Alert'};
                case 2: 
                    return { color: 'success', content: 'Success'};
                case 3: 
                    return { color: 'warning', content: 'Warning'};
                case 4: 
                    return { color: 'info', content: 'Info'};
                default:
                    console.error(`STATUS BADGE ERROR: Contract Status ID ${statusId} has no mapped value`)
                    return { color: 'danger', content: 'Status Unknown'}
            }
        }
    }

    return (
        <Badge 
            color={color || getStatusProps[statusType]().color} 
            className={alignLeft && 'ml-0'}>
            {content || getStatusProps[statusType]().content}
        </Badge>
    )
}

export default AIGStatusBadge;
