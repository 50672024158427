import React, { useState, useCallback } from "react";
import Accordion from "../../../shared/Accordion/Accordion";
import ReimbursementRates from './ReiumbursementRates';
import CoverageType from './CoverageType';
import SelectATerm from './SelectATerm';
import styles from './PlanOptions.module.css';
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent';

const PlanOptions = () => {

  const context = useContext(SessionContext)
  const { accountName } = context
  const currentBrand = getBrandContent(accountName);
  const isGE = currentBrand.clientName.toLowerCase() === 'ge';

  const [activeAccord, setActiveAccord] = useState(-1);
  const onToggle = useCallback((id) => {
    if (id === activeAccord) {
      setActiveAccord(-1);
    }
    else {
      setActiveAccord(id);
    }
  }, [activeAccord, setActiveAccord]);

  const accordionContents = [
    { bodyContent: <ReimbursementRates />, title: `Step 1: Select a ${getBrandContent(accountName)?.pricingLevelName} (Reimbursement Rates)` },
    { bodyContent: <CoverageType />, title: "Step 2: Select a Coverage Type" },
    { bodyContent: <SelectATerm />, title: "Step 3: Select a Term" }
  ];

  return (
    <>
      <h1 className="h4">Plan Options</h1>
      <p className={styles.poDescription}>
        For each sale, you will select a <b>{getBrandContent(accountName)?.pricingLevelName}</b>,
        <b>{isGE ? " Coverage" : " Plan"} Type</b> and <b>Term</b>. Plans can be purchased up to 
        five years after the date of installation.
      </p>
      {accordionContents.map((accord, i) => <Accordion key={i} id={i} open={i === activeAccord} {...accord} onToggle={onToggle} />)}
    </>
  );
}

export default PlanOptions