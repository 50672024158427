import React, { useState, useContext } from "react";
import { useClaimInvoiceStore } from "../lib/state";
import styles from "./Formatters.module.css";
import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';
import { SessionContext } from "../../../context/SessionContext";

export const DeleteRowButton = ({ rowId, rowIndex, tableType }) => {
    const { getCurrentTabAndSubtab } = useContext(SessionContext)
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const deleteRow = useClaimInvoiceStore((state) => state.deleteRow);
    const handleDelete = () => {
        deleteRow(invoiceId, tableType, rowIndex)
    }
    return (
        <div style={{ height: '42px', paddingTop: '7px', cursor: 'pointer', width: '100%'}} onClick={handleDelete}>
            <TrashIcon className={styles.trashIcon} />
        </div>
    )
}