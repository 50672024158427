import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import classNames from 'classnames';
import { ReactComponent as DownArrow } from "../../assets/images/arrow-cheveron-down.svg";

export class DropdownWithNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      selectedValue: '',
      selectedValueText: '',
      dropped: false,
      defaultValue: props.defaultValue,
      isFloatingDropdown: props.isFloatingEnabled
    };

    this.getDropDownValue = this.getDropDownValue.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleDropClick, false);
  }

  componentDidMount() {
    if (typeof this.props.defaultValue !== "undefined") {
      this.setState({
        selectedValue: this.props.defaultValue,
        selectedValueText: this.props.defaultValue === "" ? this.props.text : this.props.options.filter(o => o.data === this.props.defaultValue)?.[0]?.display
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDropClick, false);
  }

  handleDropClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }

    this.setState({ dropped: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.Value && this.props.Value != prevProps.Value) {
      const selected = this.props.options.filter(s => s.data === this.props.Value);
      console.log(selected)
      this.setState({
        selectedValue: this.props.Value,
        selectedValueText: selected?.[0]?.display
      });
    }

  }

  getDropDownValue = (value, e) => {
    this.setState({
      selectedValue: value,
      selectedValueText: this.props.options.filter(s => s.data === value)?.[0]?.display,
      dropped: false
    }, () => this.props.onChangedValue(value));
  }

  getText = () => {
    return this.state.options?.filter(e => e.data === this.props.text)?.[0]?.display;
  }

  render() {
    return (
      <React.Fragment>
        <div className="dropdown queueDropdown" id="drpdown" ref={node => this.node = node}>
          <button className="dropbtn dropbtn-placeholder form-control reportsDropdown"
            onClick={(e) => {
              e.preventDefault(); this.setState({ dropped: !this.state.dropped })
            }}
          >{this.state.selectedValue === ''
            ? this.props.placeholder
            : this.getText()} <span className={classNames("reportsCounter", this.props.className)}>({this.props.length})</span>
            <DownArrow className="dropbtnIcon" />
          </button>
          <div className="dropdown-content"
            style={{ display: this.state.dropped ? 'block' : 'none' }}>
            {this.state.options.map((opt, index) => (
              <a key={index} onClick={this.getDropDownValue.bind(this, opt.data)}>{opt.display}</a>
            ))}
          </div>
        </div>

      </React.Fragment>
    );
  }
}