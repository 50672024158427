export const currencyInputFormatter = (value, formatCreditAmount = false) => {
    const numberValue = Number(value);
    //This allows user to input by decimal first in input when we divide the value by 100
    const val = numberValue / 100;
    const stringValue = String(val);
    const amount =
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(stringValue);

    if (formatCreditAmount) {
        return `(${amount})`;
    }
    return amount;
}