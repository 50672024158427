import React from 'react';
import { ReactComponent as InfoIcon } from '../../../assets/images/info-i-icon.svg';
import { ReactComponent as QuestionInfoIcon } from '../../../assets/images/info-icon.svg';
import styles from './InfoTooltip.module.css';

const InfoTooltip = ({ iconType, message, icon }) => {
  return (
    <span className={styles.onsiteInfo}>
      {iconType === "question" ? <QuestionInfoIcon /> : <InfoIcon />}
      {iconType && message &&
        <span className={styles.onsiteInfoTooltiptext}>
          {message}</span>
      }
      {iconType && icon && message &&
        <span className={styles.onsiteInfoTooltiptext}>
          <span className={styles.toolTipSvg}>{icon}</span><br/>
          {message}
        </span>
      }
    </span>

  )
}

export default InfoTooltip;