import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from 'classnames';
import styles from './PlanOptions.module.css';
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent';
import { ReactComponent as YesIcon } from '../../../../assets/images/checkmark.svg';

const YesIconComp = () => {
	return (
		<div className={styles.yesIcon}><YesIcon /></div>
	)
};

const ReimbursementRates = () => {
  const context = useContext(SessionContext)
  const { accountName } = context
  const currentBrand = getBrandContent(accountName);
  const isLennox = currentBrand.clientName.toLowerCase() === 'lennox';

  const [planOptions, setPlanOptions] = useState([]);
  const [profile, setProfile] = useState(context.getProfile());

  useEffect(() => {
    getReimbursementOptions();
  },[]);

  const getReimbursementOptions = async () => {
    let accountId = profile?.accountId;
    if (accountId) {
      fetch(`tier/GetTierByAccountId/${accountId}`)
        .then(response => response.json())
        .then(data => {
          if (isLennox) {
            var tierData = data
            .filter(x => { return x.tripRate > 0 && x.laborRate > 0; })
            .map(d => {
              return {
                reimbursementPlanType: d.tierName,
                tierName: d.tierName,
                tripRate: `$${d.tripRate}`,
                laborRate: `$${d.laborRate}`,
                refrigerant: isLennox && d.tierName.toLowerCase() !== 'basic' ? (<YesIconComp />) : " ",
                addBenefits: isLennox && d.tierName.toLowerCase() !== 'basic' ? (<YesIconComp />) : " "
              }
            });
          } else {
            var tierData = data
            .filter(x => { return x.tripRate > 0 && x.laborRate > 0; })
            .map(d => {
              return {
                reimbursementPlanType: d.tierName,
                tierName: d.tierName,
                tripRate: `$${d.tripRate}`,
                laborRate: `$${d.laborRate}`,
                addBenefits: isLennox && d.tierName.toLowerCase() !== 'basic' ? (<YesIconComp />) : " "
              }
            });
          }
          if (isLennox) {
            tierData.push(
              {
                reimbursementPlanType: "Refrigerant only",
                tierName: "Refrigerant only",
                tripRate: "",
                laborRate: "",
                refrigerant: <YesIconComp />,
                addBenefits: " "
              }
            )
          }
          setPlanOptions(tierData);            
        });
    }
  }

  let reimbursementColumnsAll = [
    {
      dataField: "reimbursementPlanType",
      text: "Plan",
      classes: styles.reimbursePlanType
    },
    {
      dataField: "tripRate",
      text: "Trip/Diagnostic"
    },
    {
      dataField: "laborRate",
      text: "Labor Rate"
    }
  ];

  (isLennox) && reimbursementColumnsAll.push(
    {
      dataField: "refrigerant",
      text: "Refrigerant"
    },
    {
      dataField: "addBenefits",
      text: "Additional Benefits"
    }
  );

  const reimbursementDataAll = planOptions;

  return (
    <>
      <p>
        We offer multiple reimbursement options to meet your business needs.
      </p>

      <div className={classnames(styles.reimbursementRatesTable, styles.reimbursementRatesTableCarrier)}>
        <BootstrapTable
          keyField="reimbursementType"
          data={reimbursementDataAll}
          columns={reimbursementColumnsAll}
          bordered={true}
          bootstrap4
          wrapperClasses="table-responsive"
        />
      </div>
      {isLennox && <div className="mt-3 mb-2">
        *Additional benefits include Parts Markup and Misc. Parts.
      </div>}
    </>
  );
}

export default ReimbursementRates