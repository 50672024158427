import React, { useState, useEffect, useContext } from 'react';
import styles from './QueueReport.module.css';
import classNames from 'classnames';
import { DropdownWithNumber } from '../../components/inputs/DropdownWithNumber';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { ReportContext } from './ReportsContext';
import { SessionContext } from "../../context/SessionContext";

const reportTypeOptions = [
  { display: 'Claims', data: "claims" },
  { display: 'Contracts', data: "contracts" },
  { display: 'Pending Contracts', data: "pendingcontracts" },
  { display: 'Incomplete Sales', data: "incompletesales" },
  { display: 'Payment History', data: "paymenthistory" },
  { display: 'Service Request', data: "servicerequest" }
];

function ServiceRequestFilter() {
  const [state, dispatch] = useContext(ReportContext);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [dropValue, setDropValue] = useState(null);
  const sessionContext = useContext(SessionContext);
  const accountName = sessionContext?.accountName?.toLowerCase();
  useEffect(() => {
    setDropValue(state.type)
  }, [state])

  return (
    <div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
      <DropdownWithNumber
        name="state"
        options={reportTypeOptions}
        className={styles.reportsCounterClient}
        defaultValue="servicerequest"
        text="servicerequest"
        length={state && state.totalContracts}
        onChangedValue={val => {
          setDropValue(val)
          sessionContext.pendingReportTrack.pendingBackFlag = false;
          dispatch({
            type: 'SET_TYPE',
            payload: val
          })
        }}
      />
    </div>
  )
}

export default ServiceRequestFilter