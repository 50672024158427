import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import styles from "./ContactInformation.module.css";
import classNames from "classnames";
import { ReactComponent as DeleteContactSVG } from '../../../assets/images/trash.svg';
import MultiDropDown from '../MultiDropDown/MultiDropDown';
import Cleave from 'cleave.js/react';
import SimpleReactValidator from 'simple-react-validator';

let slider = styles.slider;
let round = styles.round;
let commSwitch = styles.commSwitch;
let smsSwitch = styles.smsSwitch;
let emailSwitch = styles.emailSwitch;


const contactTypeOptions = [{
  data: 'one',
  display: 'Billing Contact',
  checked: false,
  value: 'Billing Contact'
},
{
  data: 'two',
  display: 'Call Center Contact',
  checked: false,
  value: 'Call Center Contact'
},
{
  data: 'three',
  display: 'Dispatch Service Contact',
  checked: false,
  value: 'Dispatch Service Contact'
},
{
  data: 'four',
  display: 'Owner/Manager',
  checked: false,
  value: 'Owner/Manager'
},
{
  data: 'five',
  display: 'Admin',
  checked: false,
  value: 'Admin'
}];

const ContactInformation = forwardRef(({
  onDeleteContact,
  id,
  contactRadio,
  onSelect,
  validationFired,
  accountName,
  selectedTypes,
  contact = {},
  handleContactChange
}, ref) => {

  const [formData, setFormData] = useState({
    selectedTypes: contact?.contactTypes || [],
    contactName: contact?.name || '',
    contactEmail: contact?.email || '',
    contactPhone: contact?.phoneNumber || '',
    emailPref: contact?.commPreferences?.email || true,
    phonePref: contact?.commPreferences?.phone || false,
    contactId: id,
  });
  const [forceUpdate, setForceUpdate] = useState(false);


  const validator = useRef(new SimpleReactValidator({
    element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
    messages: {
      required: ':attribute is required.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.'
    }
  }));

  const onOptionClick = (val) => {
    if (val) {
      const pos = formData.selectedTypes.indexOf(val);
      if (pos > -1) {
        selectedTypes.splice(pos, 1);
      }
      else {
        selectedTypes.push(val);
      }
      setFormData({ ...formData, selectedTypes });
    }
  }

  const updateAddress = () => {
    const contactInfo = {
      contactId: formData.contactId,
      contactName: formData.contactName,
      contactPhone: formData.contactPhone,
      contactEmail: formData.contactEmail,
      contactTypes: formData.selectedTypes,
      commPreferences: {
        emailPref: formData.emailPref,
        phonePref: formData.phonePref
      }
    };
    handleContactChange(contactInfo);
  }

  const handleInput = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  useEffect(() => {
    updateAddress()

    if(validationFired && !validator.current.allValid()){
      validator.current.showMessages()
      setForceUpdate(true);
    }

  }, [formData, validationFired]);

  useImperativeHandle(ref, () => ({
    validate() {
      if (validator.current.allValid()) {
        return true;
      } else {
        validator.current.showMessages();
        setForceUpdate(true);
        return false;
      }
    }
  }));


  const { contactName, contactPhone, contactEmail, emailPref, phonePref } = formData;

  return (
    <div className={classNames("mb-3", contactRadio ? styles.bgSelected : styles.unselectedContact)}>
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex">
          <span className={styles.contactSelection}>
            <input type="radio" id={`selectContact_${id}`} name="contactSelection" onChange={() => onSelect(id)} checked={contactRadio} />
            <label for={`selectContact_${id}`} className={styles.contactSelLabel}>Primary Contact</label>
          </span>
        </div>
        <span
          className={classNames(styles.deleteIconMob, contactRadio && styles.disabledDeleteIcon)}
          onClick={() => onDeleteContact(id, contactRadio)}
        >
          {!contactRadio && <DeleteContactSVG className={styles.trashIcon}/>}
        </span>
      </div>
      <Row className={classNames("multiColWrapper", styles.contactInfoInput, "pl-4")}>
        <Col sm="12" md="4" lg="3" className="col-5ths">
          <FormGroup className={validationFired && !validator.current.check(formData.selectedTypes, "required") ? `form-error ${styles.dropWrapper}` : styles.dropWrapper}>
            <label for={`contactType_${id}`} className="form-label">Contact Type</label>
            <span aria-hidden="true" className="required-indicator">*</span>
            <div id={`contactType_${id}`}>
              <MultiDropDown
                id={id}
                options={contactTypeOptions}
                onOptionClick={onOptionClick}
                selected={formData.selectedTypes}
                name="contactType"
                clientId={accountName}
              />
              {validationFired && validator.current.message("contactType", formData.selectedTypes, "required")}
            </div>
          </FormGroup>
        </Col>
        <Col sm="12" md="4" lg="3" className="col-5ths">
          <FormGroup className={validationFired && !validator.current.check(formData.contactName, "required|name") ? 'form-error' : ''}>
            <label for="contactName" className="form-label">Name</label>
            <span aria-hidden="true" className="required-indicator">*</span>
            <input
              type="text"
              name="contactName"
              value={contactName}
              id="contactName"
              aria-required="true"
              className="form-control"
              onChange={handleInput} />
            {validator.current.message("name", formData.contactName, "required|name")}
          </FormGroup>
        </Col>
        <Col sm="12" md="4" lg="3" className="col-5ths">
          <FormGroup className={validationFired && !validator.current.check(formData.contactPhone, "required|phone") ? 'form-error' : ''}>
            <label for="contactPhone" className="form-label">Phone</label>
            <span aria-hidden="true" className="required-indicator">*</span>
            <Cleave placeholder="" name="contactPhone" value={contactPhone} onChange={handleInput} className="form-control" id="contactPhone" options={{ numericOnly: true, blocks: [0, 3, 3, 4], delimiters: ['(', ') ', '-'] }} />
            {validator.current.message("phone", formData.contactPhone, "required|phone")}
          </FormGroup>
        </Col>
        <Col sm="12" md="4" lg="3" className="col-5ths">
          <FormGroup className={validationFired && !validator.current.check(formData.contactEmail, "required|email") ? 'form-error' : ''}>
            <label for="contactEmail" className="form-label">Email</label>
            <span aria-hidden="true" className="required-indicator">*</span>
            <Cleave
              type="email"
              name="contactEmail"
              value={contactEmail}
              id="contactEmail"
              onChange={handleInput}
              aria-required="true"
              className="form-control"
              onBlur={() => {
                validator.current.showMessageFor('contactEmail');
                setForceUpdate();
              }}
            />
            {validator.current.message("email", formData.contactEmail, "required|email")}
          </FormGroup>
        </Col>
        <Col sm="12" md="7" lg="3" className="col-5ths">
          <FormGroup>
            <label className="form-label" for="commPreference">Comm Preference</label>
            <div id="commPreference" className={styles.switchWrapper}>
              <label className={classNames(commSwitch, smsSwitch)} for={`smsPref_${id}`}>
                <input
                  type="checkbox"
                  id={`smsPref_${id}`}
                  name="smsCommEnabled"
                  value={phonePref}
                  onChange={e =>  setFormData({ ...formData, phonePref: e.currentTarget.checked }) }
                  checked={phonePref}
                />
                <span className={classNames(slider, round)}></span>
              </label>
              <label className={classNames(commSwitch, emailSwitch)} for={`emailPref_${id}`}>
                <input
                  type="checkbox"
                  id={`emailPref_${id}`}
                  name="emailCommEnabled"
                  value={emailPref}
                  onChange={e =>  setFormData({ ...formData, emailPref: e.currentTarget.checked }) }
                  checked={emailPref}
                />
                <span className={classNames(slider, round)}></span>
              </label>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
})

export default ContactInformation;
