import React from 'react';
import styles from './PageLoading.module.css';

const PageLoading = ({text = "Loading...", height = 600}) => {
    return (
        <div className={styles.pageLoading} style={{height: `${height}px`}}>
            <div className={`${styles.spinnerBorder} mb-2`} role="status"></div>
            <p>
                {text}
            </p>
        </div>
    )
}

export default PageLoading; 