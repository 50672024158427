import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

const style = {
    marginLeft: 0,
}

export const AIGStatusBadge = (props) => {
    PropTypes.checkPropTypes(AIGStatusBadge.propTypes, props, 'props', 'status badge');

    const {statusId, removeMargin} = props
    return (
    <>
            {statusId === 1 && <Badge color="success" style={removeMargin ? style : {}}>Active</Badge>}
            {statusId === 2 && <Badge color="danger" style={removeMargin ? style : {}}>Inactive</Badge>}
            {statusId === 4 && <Badge color="danger" style={removeMargin ? style : {}}>Suspended</Badge>}
    </>
    );
}

AIGStatusBadge.propTypes = {
    statusId: PropTypes.bool.isRequired,
    removeMargin: PropTypes.bool,
}

AIGStatusBadge.defaultProps = {
    removeMargin: false,
}

export default AIGStatusBadge;