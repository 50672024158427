import React, { Component } from 'react';
import {
    Container, Row, Col,
    Card, CardHeader, CardBody,
    Button, Table, Form, FormGroup, Label, Input, UncontrolledTooltip
} from 'reactstrap';
import styles from './PlanSearch.module.css';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { ZipInput } from '../inputs/ZipInput';
import { TextInput } from '../inputs/TextInput';
import { SessionContext } from '../../context/SessionContext';
import Cleave from 'cleave.js/react';
import SimpleReactValidator from 'simple-react-validator';
//import NoteModal from '../modals/NoteModal';

let mt1 = styles.mt1;
let resultsTable = styles.resultsTable;
let tableResponsiveLg = "table-responsive-lg";
let selectedSearch = "contractNumber";
let selectedClient = "vp";
let searched = false;
let result = false;
let model = null;

export class PlanSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            client: "vp",
            dealer: 0,
            selection: "contractNumber",
            selectionValue: "",
            response: null,
            searching: false,
            noPlanFound: false,
            noClaimFound: false,
            clientSpecific: false,
            errors: {
                phone: false,
                contractNumber: false,
                CCN: false,
                claim: false,
                serialNumber: false,
                email: false,
                dispatchId: false,
                lastName: false,
                zip: false,
            },
            model: {
                phone: "",
                contractNumber: "",
                CCN: "",
                claim: "",
                serialNumber: "",
                email: "",
                lastName: "",
                zip: "",
            },
            data: [],
            tabId: this.props.tabId === undefined ? 0 : this.props.tabId,
        }

        this.toggleSearch = this.toggleSearch.bind(this);
        this.search = this.search.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.loadContract = this.loadContract.bind(this);
        this.updateContext = this.updateContext.bind(this);
        this.validator = new SimpleReactValidator();
    }

    componentWillMount() {
        let currentTab = null

        if (currentTab !== undefined && currentTab !== null) {

            // load state from context
            this.setState({
                client: currentTab.client,
                selection: currentTab.selection,
                selectionValue: currentTab.selectionValue,
                response: currentTab.response,
                searching: currentTab.searching,
                noPlanFound: currentTab.noPlanFound,
                clientSpecific: currentTab.clientSpecific,
                errors: currentTab.errors,
                model: currentTab.model,
                data: currentTab.data
            });
        }
    }

    componentWillUnmount() {
        this.updateContext();
    }

    updateContext() {
        let { updateTab } = this.context;
        updateTab({
            tabId: this.state.tabId,
            title: "Plan Search",
            tabType: "search",
            contractNumber: this.state.model.contractNumber,
            client: (this.state !== undefined && this.state !== null) ? this.state.client : selectedClient,
            state: this.state
        });
    }

    handleInputChange = e => {
        const { name, value } = e.target;
        let errors = this.state.errors;
        let model = this.state.model;

        model[name] = value;

        if (value === "") {
            errors[name] = true;
        }
        else {
            errors[name] = false;
        }

        this.setState(() => ({
            errors: errors,
            selectionValue: value
        }));
    }



    toggleSearch = (e) => {
        // Clearing errors when changing selection
        let previousSelected = this.state.selection;
        let errors = this.state.errors;
        model = this.state.model;

        /*if (this.state.selection != "nameZip" && this.state.selection != "dispatchId") {
            model[previousSelected] = "";
            console.log(this.state.selection);
        }*/

        errors[selectedSearch] = false;

        selectedSearch = e.target.value;
        this.setState({
            selection: selectedSearch,
            errors: {},
            model: {},
            noPlanFound: false
        });


        if (selectedSearch === "serialNumber") {
            this.setState({ selectionValue: this.state.model.serialNumber })
        }
    }

    validateForm = (errors) => {
        let valid = true;
        valid = !errors[selectedSearch];

        if (selectedSearch === "nameZip") {
            if (errors.zip === true || errors.lastName === true) {
                valid = false
            }
        }
        //console.log(selectedSearch, errors[selectedSearch]);
        return valid;
    }

    checkErrors() {
        let errors = this.state.errors;
        if (!this.state.selectionValue) {
            errors[selectedSearch] = true;
            if (selectedSearch === "nameZip") {
                if (!this.state.model.lastName) {
                    errors["lastName"] = true;
                }
                if (!this.state.model.zip) {
                    errors["zip"] = true;
                }
            }
        }
        else {
            errors[selectedSearch] = false;
        }

        this.setState(() => ({
            errors: errors
        }));
        return errors;
    }

    loadContract = (contract, invoiceSearch = false) => {
        let { tabDispatch } = this.context;
        result = false;

        if (!invoiceSearch) {
            tabDispatch('ADD_TAB', {
                title: contract.party.firstName + ' ' + contract.party.lastName,
                tabType: "contract",
                contractNumber: contract.contractNumber,
                client: this.state.client
            });
        } else {
            tabDispatch('ADD_TAB', {
                title: contract.contractModel[0].party.firstName + ' ' + contract.contractModel[0].party.lastName,
                tabType: "claimOverview",
                contractNumber: contract.contractModel[0].contractNumber,
                client: this.state.client,
                claimOverviewModel: contract
            });
        }
    }

    search = (e) => {
        try {
            e.preventDefault();
        }
        catch (err) {
            console.log(e);
        }

        model = this.state.model;

        this.setState(prevState => ({
            searching: !prevState.searching,
            noPlanFound: false,
            noClaimFound: false,
            selection: selectedSearch
        }));

        // Cleaning data before call
        this.setState(() => ({
            data: []
        }));

        searched = true;
        // Call validateForm
        const errors = this.checkErrors();
        if (this.validateForm(errors)) {
            // Call ajax method
            // Dealer ID will be filtered in ContractSearch controller via model.Dealer
            if (this.state.selection !== "invoiceNumber") {
                fetch('ContractSearch/SearchContract', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SearchOption: this.state.selection,
                        Client: this.state.client,
                        Phone: this.state.model.phone,
                        ClientContractNumber: this.state.model.CCN,
                        ContractNumber: this.state.model.contractNumber,
                        ClaimNumber: this.state.model.claim,
                        ZipNumber: this.state.model.zip,
                        LastName: this.state.model.lastName,
                        SerialNumber: this.state.model.serialNumber,
                        Email: this.state.model.email,
                        Dealer: this.context.getProfile().dealerId

                    })
                }).then(res => {
                    if (res.status === 204) {
                        //console.log(res.status);
                        result = false;
                        this.setState({ noPlanFound: true, searching: false }, () => {
                            return;
                        });
                    }
                    else if (res.status === 200) {
                        //console.log(res.status + " else");
                        result = true;
                        this.setState({ searching: false });
                        res.json()
                            .then(contract => {
                                if (contract.length === 1) {
                                    this.loadContract(contract[0]);
                                } else if (contract.length > 1) {
                                    this.setState(() => ({
                                        data: contract
                                    }));
                                } else {
                                    result = false;
                                    this.setState({ noPlanFound: true, searching: false }, () => {
                                        return;
                                    });
                                }
                            })
                    }
                    else {
                        console.error(res.status);
                        result = false;
                        this.setState({ noPlanFound: true, searching: false }, () => {
                            return;
                        });
                    }
                })

            } else {
                fetch('InvoiceSearch/InvoiceLookUp', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SearchOption: this.state.selection,
                        EventNumber: "",
                        InvoiceNumber: this.state.model.invoiceNumber,
                        Client: "hvac",
                        DealerId: this.context.getProfile().dealerId
                    })
                }).then(res => {
                    if (res.status === 204) {
                        //console.log(res.status);
                        result = false;
                        this.setState({ noClaimFound: true, searching: false }, () => {
                            return;
                        });
                    }
                    else if (res.status === 200) {
                        //console.log(res.status + " else");
                        result = true;
                        this.setState({ searching: false });
                        res.json()
                            .then(contract => {
                                if (contract.length === 1) {
                                    this.loadContract(contract[0], true);
                                } 
                                else if (contract.length > 1) {
                                    let contracts = contract.map(c => c?.contractModel[0] ?? 0).filter(c => c !== 0)

                                    this.setState(() => ({
                                        data: contracts
                                    }));
                                }
                                else {
                                    result = false;
                                    this.setState({ noPlanFound: true, searching: false }, () => {
                                        return;
                                    });
                                }
                            })
                    }
                    else {
                        result = false;
                        this.setState({ noClaimFound: true, searching: false }, () => {
                            return;
                        });
                    }
                })

            }

            this.setState({
                response: result
            });

        }
        else {
            //console.error('Invalid Form');
            searched = false;
            this.setState(prevState => ({
                searching: !prevState.searching
            }));
            return;
        }
    }

    render() {
        const { classes } = this.props;

        let model = this.state.model;
        let contracts = this.state.data;

        let newClaims = this.context?.getAccountFeatureFlag("hvac claim") ?? false;

        return (
            <Container className="contentWrapper">
                <Card>
                    <CardHeader>Plan or Claim Lookup</CardHeader>
                    <CardBody>
                        <Form>

                            <Row>
                                <Col md="4" lg="3">
                                    <Label className={styles.searchCriteria} aria-label="Select search method from below criteria" for="searchCriteriaGroup">Search By:</Label>
                                    <FormGroup role="radiogroup" id="searchCriteriaGroup">
                                        <div className="radio">
                                            <Input
                                                type="radio"
                                                name="searchCriteria"
                                                id="cnSearch"
                                                value="contractNumber"
                                                onChange={this.toggleSearch}
                                                checked={(this.state.selection === "contractNumber" || this.state.selection === null) ? true : false}
                                            />
                                            <Label className="controls-label" for="cnSearch">Contract Number</Label>
                                        </div>
                                        <div className="radio">
                                            <Input type="radio" name="searchCriteria" id="claimSearch" value="claim" onChange={this.toggleSearch} checked={this.state.selection === "claim" ? true : false} />
                                            <Label className="controls-label" for="claimSearch">Claim Number</Label>
                                        </div>
                                        <div className="radio">
                                            <Input type="radio" name="searchCriteria" id="ccnSearch" value="CCN" onChange={this.toggleSearch} checked={this.state.selection === "CCN" ? true : false} />
                                            <Label className="controls-label" for="ccnSearch">Dealer PO#/Registration #</Label>
                                        </div>

                                        {newClaims &&
                                            <div className="radio">
                                                <Input
                                                    type="radio"
                                                    name="searchCriteria"
                                                    id="invoiceSearch"
                                                    value="invoiceNumber"
                                                    onChange={this.toggleSearch}
                                                    checked={this.state.selection === "invoiceNumber" ? true : false}
                                                />
                                                <Label className="controls-label" for="invoiceSearch">Invoice Number</Label>
                                            </div>
                                        }

                                        <div className="radio">
                                            <Input type="radio" name="searchCriteria" id="serialSearch" value="serialNumber" onChange={this.toggleSearch} checked={this.state.selection === "serialNumber" ? true : false} />
                                            <Label className="controls-label" for="serialSearch">Serial Number</Label>
                                        </div>

                                        <div className="radio">
                                            <Input type="radio" name="searchCriteria" id="nameZipSearch" value="nameZip" onChange={this.toggleSearch} checked={this.state.selection === "nameZip" ? true : false} />
                                            <Label className="controls-label" for="nameZipSearch">Last Name and Zip</Label>
                                        </div>

                                        <div className="radio">
                                            <Input type="radio" name="searchCriteria" id="phoneSearch" value="phone" onChange={this.toggleSearch} checked={this.state.selection === "phone" ? true : false} />
                                            <Label className="controls-label" for="phoneSearch">Phone Number</Label>
                                        </div>

                                        <div className="radio">
                                            <Input type="radio" name="searchCriteria" id="emailSearch" value="email" onChange={this.toggleSearch} checked={this.state.selection === "email" ? true : false} />
                                            <Label className="controls-label" for="emailSearch">Email</Label>
                                        </div>

                                    </FormGroup>
                                </Col>
                                <Col md="6" lg="4" className={styles.searchColumn}>
                                    {this.state.selection === "phone" &&
                                        <FormGroup className={this.state.errors.phone ? "form-error" : ""} id="phoneSearchGroup">
                                            <Label for="phoneSearchInput" className="form-label">Phone</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <Cleave 
                                                options={{ 
                                                    blocks: [0, 3, 3, 4], 
                                                    delimiters: ['(', ') ', '-'], 
                                                    numericOnly: true 
                                                }} 
                                                name="phone" 
                                                className="form-control" 
                                                id="phoneSearchInput" 
                                                onChange={this.handleInputChange} 
                                                value={this.state.model.phone} 
                                            />
                                            {this.state.errors.phone === true && <div className="errorMsg">Please enter a valid phone number.</div>}
                                        </FormGroup>
                                    }
                                    {this.state.selection === "CCN" &&
                                        <FormGroup className={this.state.errors.CCN ? "form-error" : ""} id="ccnSearchGroup">
                                            <Label for="ccnSearchInput" className="form-label">Dealer PO#/Registration #</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <TextInput name="CCN" className="form-control" type="number" id="ccnSearchInput" value={this.state.model.CCN} onChangedValue={this.handleInputChange} />
                                            {this.state.errors.CCN === true && <div className="errorMsg">Dealer PO#/Registration # is required.</div>}
                                        </FormGroup>
                                    }
                                    {(this.state.selection === "contractNumber" || this.state.selection === null) &&
                                        <FormGroup className={this.state.errors.contractNumber ? "form-error" : ""} id="cnSearchGroup">
                                            <Label for="cnSearchInput" className="form-label">Contract Number</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <TextInput name="contractNumber" className="form-control" type="number" id="cnSearchInput" value={this.state.model.contractNumber} onChangedValue={this.handleInputChange} />
                                            {this.state.errors.contractNumber === true && <div className="errorMsg">Contract number is required.</div>}
                                            {this.validator.message('contractNumber', this.state.model.contractNumber, 'required')}
                                        </FormGroup>
                                    }
                                    {this.state.selection === "claim" &&
                                        <FormGroup className={this.state.errors.claim ? "form-error" : ""} id="claimSearchGroup">
                                            <Label for="claimSearchInput" className="form-label">Claim Number</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <TextInput name="claim" className="form-control" type="number" id="claimSearchInput" value={this.state.model.claim} onChangedValue={this.handleInputChange} />
                                            {this.state.errors.claim === true && <div className="errorMsg">Claim number is required.</div>}
                                        </FormGroup>
                                    }
                                    {this.state.selection === "nameZip" &&
                                        <div id="nameZipSearchGroup">
                                            <FormGroup className={this.state.errors.lastName ? "form-error" : ""} id="lastname-search">
                                                <Label for="lastNameSearchInput" className="form-label">Last Name</Label>
                                                <span aria-hidden="true" className="required-indicator">*</span>
                                                <TextInput name="lastName" className="form-control" type="text" id="lastNameSearchInput" value={this.state.model.lastName} onChangedValue={this.handleInputChange} />
                                                {this.state.errors.lastName === true && <div className="errorMsg">Last name is required.</div>}
                                            </FormGroup>
                                            <FormGroup className={this.state.errors.zip ? "form-error" : ""}>
                                                <Label for="zipSearchInput" className="form-label">Zip Code</Label>
                                                <span aria-hidden="true" className="required-indicator">*</span>
                                                <ZipInput name="zip" className="form-control" type="text" id="zipSearchInput" value={this.state.model.zip} onChangedValue={this.handleInputChange} />
                                                {this.state.errors.zip === true && <div className="errorMsg">Zip code is required.</div>}
                                            </FormGroup>
                                        </div>
                                    }
                                    {this.state.selection === "invoiceNumber" &&
                                        <FormGroup className={this.state.errors.invoiceNumber ? "form-error" : ""} id="invoiceSearchGroup">
                                            <Label for="invoiceSearchInput" className="form-label">Invoice Number</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <TextInput
                                                name="invoiceNumber"
                                                className="form-control"
                                                type="number"
                                                id="invoiceSearchInput"
                                                value={this.state.model.invoiceNumber}
                                                onChangedValue={this.handleInputChange}
                                            />
                                            {this.state.errors.invoiceNumber === true && <div className="errorMsg">Invoice number is required.</div>}
                                        </FormGroup>
                                    }
                                    {this.state.selection === "serialNumber" &&
                                        <FormGroup className={this.state.errors.serialNumber ? "form-error" : ""} id="serialSearchGroup">
                                            <Label for="serialSearchInput" className="form-label">Serial Number</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <TextInput name="serialNumber" className="form-control" type="number" id="serialSearchInput" value={this.state.model.serialNumber} onChangedValue={this.handleInputChange} />
                                            {this.state.errors.serialNumber === true && <div className="errorMsg">Serial number is required.</div>}
                                        </FormGroup>
                                    }
                                    {this.state.selection === "email" &&
                                        <FormGroup className={this.state.errors.email ? "form-error" : ""} id="caseSearchGroup">
                                            <Label for="caseSearchInput" className="form-label">Email</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <TextInput name="email" className="form-control" type="number" id="caseSearchInput" value={this.state.model.email} onChangedValue={this.handleInputChange} />
                                            {this.state.errors.email === true && <div className="errorMsg">Email is required.</div>}
                                        </FormGroup>
                                    }

                                    {/*<button color="primary">Search</button>*/}
                                    <Button disabled={this.state.searching} onClick={this.search} color="primary" className={styles.search}><Spinner color="light" size="sm"
                                        animation={this.state.searching ? "border" : ""} />
                                        {this.state.searching ? "" : "Search"}
                                    </Button>
                                </Col>
                            </Row>
                            {this.state.noPlanFound &&
                                <FormGroup>
                                    <div className={classNames("validationSummary", mt1)}>A plan could not be located with the information provided. Please view the search information, try again, or select another search method.</div>
                                </FormGroup>
                            }
                            {this.state.noClaimFound &&
                                <div className={classNames("validationSummary", mt1)}>
                                    A claim could not be located with the information provided. Please review and try again.
                                </div>
                            }
                            {result &&
                                <div className={classNames(resultsTable, tableResponsiveLg)}>
                                    <Table className="table borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Contract Number</th>
                                                <th scope="col">Dealer PO #/Reg #</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Plan Type</th>
                                                <th scope="col">Valid Thru</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tableStriped">
                                            {this.state.data.map(function (item, key) {
                                                return (
                                                    <tr key={key}>
                                                        <td onClick={() => this.loadContract(item)}><span className="dummy-link"> {item.contractNumber}</span></td>
                                                        <td onClick={() => this.loadContract(item)}>{item.clientContractNumber}</td>
                                                        <td onClick={() => this.loadContract(item)}><span className={classNames(styles.toolTipInfo)}>{item.party.firstName}<span className={styles.onsiteInfoTooltiptext}>{item.party.partyAddressModel[0].address1 + ", " + item.party.partyAddressModel[0].city + ", " + item.party.partyAddressModel[0].state + ", " + item.party.partyAddressModel[0].zip}</span></span></td>
                                                        <td onClick={() => this.loadContract(item)}>{item.party.lastName}</td>
                                                        <td onClick={() => this.loadContract(item)}>
                                                            <span className={classNames(styles.toolTipInfo)}>
                                                                {item.assetPackage != null ? item.assetPackage.name : ""}
                                                                <span className={styles.onsiteInfoTooltiptext}>
                                                                    {<table className="table borderless">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Product</th>
                                                                                <th>Serial</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{item.assets.length > 0 ? item.assets[0].assetName : ""}</td>
                                                                                <td>{item.contractAssetModel.length > 0 ? item.contractAssetModel[0].serialNumber : ""}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>}
                                                                </span></span></td>
                                                        <td onClick={() => this.loadContract(item)}>{item.planExpirationDate}</td>
                                                        <td onClick={() => this.loadContract(item)}>{item.statusName}</td>
                                                    </tr>
                                                )
                                            }.bind(this))}
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
PlanSearch.contextType = SessionContext;