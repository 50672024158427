import React from 'react';
import PropTypes from 'prop-types';
import styles from './Advantage.module.css';

// TYPES
// export type AdvantageStatus = 'ENROLLED_ACTIVE' 
//     | 'ENROLLED_PENDING' 
//     | 'ENROLLED_NO_CAMPAIGN'
//     | 'UNENROLLED';

// HELPER FUNCTION FOR MAPPING STATUS TO DISPLAY ELEMENTS
const displayContent = (status) => {
    switch (status) {
        case 'ENROLLED_ACTIVE':
            return (
                <p className={styles.enrolled}>Enrolled - Active Campaign</p>
            )
        case 'ENROLLED_PENDING':
            return (
                <p className={styles.enrolled}>Enrolled - Pending Campaign</p>
            )
        case 'ENROLLED_NO_CAMPAIGN':
            return(
                <>
                    <p className={styles.enrolled}>Enrolled</p>
                    <span className={styles.highlighted}>No Active Campaigns</span>
                </>
            )
        case 'UNENROLLED':
            return (
                <span className={styles.highlighted}>Not Enrolled</span>
            )
        default:
            return (
                <></>
            )
    }
}

// COMPONENT
const AdvantageEnrollment = (props) => {
    PropTypes.checkPropTypes(AdvantageEnrollment.propTypes, props, 'props', 'AdvantageEnrollment');
    
    return (
        <div className={styles.advStatus}>
            <h3 className={`h6 ${styles.smallHeading}`}>{props.programName} Enrollment Status</h3>
            <div>
                {displayContent(props.advantageStatus)}
            </div>
        </div>
    )
}

AdvantageEnrollment.propTypes = {
    advantageStatus: PropTypes.oneOf([
        'ENROLLED_ACTIVE', 
        'ENROLLED_PENDING', 
        'ENROLLED_NO_CAMPAIGN',
        'UNENROLLED',
    ]).isRequired,
}

export default AdvantageEnrollment;
