import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import classNames from "classnames";
import styles from "../Report/QueueReport.module.css";
import NeutralInfoBadge from '../shared/NeutralInfoBadge/NeutralInfoBadge';
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import NotesField from '../shared/NotesField/NotesField';
import moment from 'moment';
import { SessionContext } from "../../context/SessionContext";
import { getBrandContent } from '../../components/BrandingWhiteLabel/BrandContent';
import { SendMailByAccount, emailModel, serviceModel, getPrimaryEmail } from '../../components/Global/helpers';

const rowSelectionStyle = { backgroundColor: "#DEE9F6" };
const rowBackgroundStyle =  "#DEE9F6";
const dateFormatter = (cell) => moment(cell).format('DD/MM/yyyy');
const priceFormatter = (price) => {
    return (
        <div>${Number.parseFloat(price).toFixed(2)}</div>
    );
};
const PendingContractsSelectionModal = ({ open, onClose, type, data }) => {
    const [reasonRowSelected, setReasonRowSelected] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [tableData, setTableData] = useState(data);
    const [tableModifiedData, setTableModifiedData] = useState(null);
    const [finalSelected, setFinalSelected] = useState([]);
    const [notesError, setNotesError] = useState(false);
    const [cancellationReasonError, setCancellationReasonError] = useState(false);
    const sessionContext = useContext(SessionContext);
    const defaultSortedBy = [{
        dataField: "lastName",
        order: "asc",
    }];
    const [tableColumns, setTableColumns] = useState([{
        dataField: 'lastName',
        text: 'Last Name',
        sort: 'true',
        headerFormatter: headerFormatter,
    }, {
        dataField: 'totalAmount',
        text: 'Plan Price',
        sort: 'true',
        headerFormatter: headerFormatter,
        formatter: priceFormatter
    }, {
        dataField: 'installationDate',
        text: 'Installation Date',
        sort: 'true',
        headerFormatter: headerFormatter,
        //formatter: dateFormatter
    }])
    const [selectedRows, setSelectedRows] = useState([])
    useEffect(() => {
        data = data.map(val => { return {...val, selected: ""}});
        setTableData(data);
        setSelectedRows(data.map((d, i) => d.contractId))
        setFinalSelected(data);
    }, [data]);
    useEffect(() => {
        setSelectedRows(data.map((d, i) => d.contractId))
    }, [open]);

    const cancelReasonsList = [
        { data: 'declined', display: 'Customer Declined Coverage' },
        { data: 'upgraded', display: 'Customer Upgraded Coverage' },
        { data: 'advselection', display: 'Equipment Application Outside ADV Selections' },
        { data: 'other', display: 'Other' }
    ];
    

    useEffect(() => {
    }, [tableModifiedData])

    function headerFormatter(column, index, { sortElement, filterElement }) {
        const { order } = sortElement.props;
        return (
            <span className={`${order === "asc" ? "ascending" : ""} ${order === "desc" ? "descending" : ""}`}>
                {column.text}
            </span>
        );
    }

    const handleNotesChange = (value, contractId) => {
        const newProducts = (tableModifiedData || tableData).map(row => {
            if (row.contractId === contractId) {
                return { ...row, notes: value };
            }
            return row;
        });
        setTableData(newProducts);
    }

    const handleChange = (contractId, new_value) => {
        const newProducts = (tableModifiedData || tableData).map(row => {
            if (row.contractId === contractId) {
                row.hideCancelRowError = true;
                return { ...row, selected: new_value };
            }
            return row;
        });
        setTableData(newProducts);
    }

    useEffect(() => {
        if (type === 'Cancel') {
            setTableColumns([{
                dataField: 'lastName',
                text: 'Last Name',
                sort: 'true',
                headerFormatter: headerFormatter,
            }, {
                dataField: 'totalAmount',
                text: 'Plan Price',
                sort: 'true',
                headerFormatter: headerFormatter,
                formatter: priceFormatter
            }, {
                dataField: 'installationDate',
                text: 'Installation Date',
                sort: 'true',
                headerFormatter: headerFormatter,
                //formatter: dateFormatter
            },
            {
                dataField: 'reason',
                text: 'Cancellation Reason',
                formatter: (cell, row) => {
                    return (
                        <div style={{ minWidth: '250px' }} className={cancellationReasonError && !row.hideCancelRowError && 'form-error'}>
                            <DropDownAutoComplete
                                name="campaignEndDate"
                                options={cancelReasonsList}
                                className="form-control"
                                placeholder="Please select..."
                                id="campaignEndDtOption"
                                Value={row.selected || 'first'}
                                onChangedValue={value => { handleChange(row.contractId, value) }}
                            />
                            {cancellationReasonError && !row.selected && <div className="errorMsg">Cancellation reason is required.</div> }
                            {(row.selected === 'other') && 
                                <div className={notesError && !row.notes && 'form-error'}>
                                    <NotesField
                                        maxChars={250}
                                        value={row.notes || ''}
                                        fullWidth={true}
                                        onChange={(notes) => handleNotesChange(notes, row.contractId)}
                                        label=""
                                    />
                                    {notesError && !row.notes && <div className="errorMsg">Cancellation reason is required.</div>}
                                </div>
                            }
                        </div>
                    );
                },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        e.stopPropagation();
                    },
                }
            }
            ])
        }

        if (type === 'Pay') {
            setTableColumns([{
                dataField: 'lastName',
                text: 'Last Name',
                sort: true,
                headerFormatter: headerFormatter,
            }, {
                dataField: 'totalAmount',
                text: 'Plan Price',
                sort: true,
                headerFormatter: headerFormatter,
                formatter: priceFormatter
            }, {
                dataField: 'installationDate',
                text: 'Install Date',
                sort: true,
                headerFormatter: headerFormatter,
                //formatter: dateFormatter
            }])
        }
    }, [type, reasonRowSelected, forceUpdate, tableData, cancellationReasonError, notesError])


    const handleOnSelectAll = (isSelect, rows) => {
        let finalSelectedTemp = [...finalSelected];
        let selectedRowsTemp = [...selectedRows];

        rows.forEach(r => {
            if (isSelect) {
                if (selectedRows.indexOf(r.contractId)) {
                    selectedRowsTemp = [...selectedRowsTemp, r.contractId]
                    finalSelectedTemp = [...finalSelectedTemp, r]
                }
            } else {
                selectedRowsTemp = [...selectedRowsTemp].filter(x => x !== r.contractId)
                finalSelectedTemp = [...finalSelectedTemp].filter(x => x.contractId !== r.contractId)
            }
        });
        setSelectedRows(selectedRowsTemp);
        setFinalSelected(finalSelectedTemp);
    }

    const handleOnSelect = (row, isSelect) => {
        const filtered = finalSelected.filter(e => e.contractId !== row.contractId)
        if (isSelect) {
            setSelectedRows([...finalSelected, row].map(e => e.contractId))
       
           
            setFinalSelected([...finalSelected, row]);
           
            return false;
        }
        setSelectedRows(filtered.map(e => e.contractId))
        setFinalSelected(filtered);
        
        // return true;
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,

        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selected: selectedRows,
        bgColor: rowBackgroundStyle,
        style: rowSelectionStyle,
        selectionRenderer: ({ mode, checked, disabled }) => (
            <div className={classNames("checkbox", "mb-0")}>
                <Input className="mr-2" type="checkbox" checked={checked} />
                <Label className={classNames("checkboxLabel", sessionContext.accountName)}></Label>
            </div>
        ),
        selectionHeaderRenderer: ({ mode, checked, disabled }) => (
            <div className={classNames("checkbox", "mb-0")}>
                <Input className="mr-2" type="checkbox" checked={checked} />
                <Label className={classNames("checkboxLabel", sessionContext.accountName)}></Label>
            </div>
        ),
    };

    const InfoPanel = ({ type }) => {
        if (type === 'Pay') {
            return <p>Please confirm your selected contracts before proceeding to payment.</p>
        }
        return (
            <p>
                Cancellation of pending contract(s) cannot be reversed and coverage will be forfeited immediately. 
                Please provide a cancellation reason for your selected contracts before submitting this request.
            </p>
        );
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing <span className={classNames(styles.bold, styles.brandColor)}>{(to - from) + 1}</span> of <span className={classNames(styles.bold, styles.brandColor)}>{size}</span> contracts
        </span>
    );

    const handleCancelContracts = () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        };
        const allCalls = [];
        const selectedCalls = [];
        const tableFilteredData = (tableModifiedData || tableData).filter(data => selectedRows.includes(data.contractId))
        let hascancellationReason = tableFilteredData.every(data => data.selected !=="");
        const hasContractFormError = !tableFilteredData.filter(value => value.selected == "other").every(data => data.notes)
        if (hascancellationReason && !hasContractFormError) {
            let selectedContract;
            finalSelected.forEach((contract) => {
                const isOtherContractSelected = tableFilteredData.find(t => t.selected === 'other');
                selectedContract = tableData.find(contractData => contract.contractId == contractData.contractId);
                if (selectedContract.selected) {
                    let cancelReason = (selectedContract.selected == "other") ? selectedContract.notes :
                        cancelReasonsList.find(item => item.data == selectedContract.selected).display;
                    selectedCalls.push(selectedContract);
                    if (isOtherContractSelected) {
                        allCalls.push(fetch(`contract/UpdateContractStatus/${contract.contractId}/${5}/${cancelReason}`, requestOptions));
                        return;
                    }
                    allCalls.push(fetch(`contract/UpdateContractStatus/${contract.contractId}/${5}/${cancelReason}`, requestOptions));
                }
            })
            
            Promise.all(allCalls).then((responses) => {
                onClose(selectedCalls, "", "cancelSuccess");
                sendCancelEmail(finalSelected);
                setNotesError(false);
                setCancellationReasonError(false);
                const selectedRowsTemp = [...selectedRows];
                setTableData([...tableData].filter(x => selectedRows.indexOf(x.contractId) == "-1"));
                setFinalSelected([...data].filter(x => selectedRowsTemp.indexOf(x.contractId) == "-1"));
                setSelectedRows(data.map((d, i) => d.contractId).filter(x => selectedRowsTemp.indexOf(x) == "-1"));
            });
        } else {
            setCancellationReasonError(true);
            setNotesError(hasContractFormError);
        }

        //setFinalSelected([])
    }

    const handleOnClose = (openTab, status) => {
        setNotesError(false);
        setCancellationReasonError(false);
        if(status === 'cancel') { 
            setTableData([...tableData].map((tableObj) => tableObj.selected ? { ...tableObj, selected: '', notes: '' } : tableObj));
        }
        onClose(finalSelected, openTab, status);
        //setFinalSelected([])
    }

    const sendCancelEmail = async (contracts) => {
        const dealerProfile = sessionContext.getProfile();
        const recipient = getPrimaryEmail(dealerProfile);

        let formattedList = '';
        contracts.map((c) => formattedList += `${c.contractNumber}, ${c.clientContractNumber}, ${c.lastName}<br>`);

        if (recipient !== null && recipient !== undefined) {
            let account = getBrandContent(sessionContext.accountName);

            let email = new emailModel();
            let service = new serviceModel();

            email.recipients = [recipient];
            email.cc = [];
            email.bcc = [];
            email.isBodyHtml = true;
            email.emailType = 0

            service.programName = account.programName;
            service.programPhoneNumber = account.programPhone;
            service.contractList = formattedList;
            service.advantageAccountName = account.advantageProgramName

            var type = 'CancelPendingContract';
            SendMailByAccount(dealerProfile.accountId, email, service, dealerProfile.accountId, type);
        }
    }

    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    let total = finalSelected.reduce((acc, val) => (acc + parseFloat(val.totalAmount)), 0);
    total = total.toLocaleString(undefined, { maximumFractionDigits: 2 });

    return (
        <div>
            <Modal isOpen={open} toggle={onClose} size={type === 'Pay' ? 'md' : 'lg'}>
                <ModalHeader toggle={onClose} className="btnAdjust">
                    <div className={styles.header}>
                        <span>{type === 'Pay' ? 'Pay Pending Contracts' : 'Pending Contracts Cancellation'}</span>
                        {type === 'Pay' && <div className={classNames(styles.headerTotal)}>
                            <NeutralInfoBadge text="Order Total:" amount={total} />
                        </div>}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <InfoPanel type={type} />
                    <BootstrapTable
                        keyField='contractId'
                        data={tableModifiedData || tableData}
                        bordered={false}
                        columns={tableColumns}
                        selectRow={selectRow}
                        pagination={paginationFactory(options)}
                        defaultSorted={defaultSortedBy}
                        classes={styles.pendingContractsModalTable}
                    />                
                </ModalBody>
                <ModalFooter>
                    <button 
                        className="btnLink" onClick={() => { handleOnClose(false, "cancel")}} 
                        disabled={finalSelected.length <= 0} 
                    >
                        Close
                    </button>
                    {type === 'Pay' ?
                        <Button color="primary" onClick={() => { handleOnClose(true, "payment") }} disabled={finalSelected.length <= 0}>Proceed to Payment</Button> :
                        <Button color="primary" disabled={!finalSelected.length} onClick={handleCancelContracts}>Cancel Contracts</Button>
                    }
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default PendingContractsSelectionModal;

