import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import classNames from "classnames";
import styles from "../Sales/PaymentInformation/PaymentMethod.module.css";

export const DeleteSale = ({ isOpen, toggle, centered, handleDelete }) => {
    return (
        <Modal isOpen={isOpen}
            toggle={toggle}
            className="deleteCard"
            centered={centered}>

            <div className={styles.deleteCardHeader}>
                <h4>Delete Saved Sale(s)</h4>
            </div>

            <ModalBody>
                <p>Are you sure you want to delete sales entry? This action cannot be undone.</p>
            </ModalBody>

            <ModalFooter>
                <button className={classNames("btnLink", styles.customBtnLink)} onClick={toggle}>
                    Close
                    </button>

                <button className="btn-primary" onClick={handleDelete}>Delete Sale</button>
            </ModalFooter>
        </Modal>
    );
};
