/**
 * THIS FILE IS FOR HELPER FUNCTIONS THAT CAN BE USED REPETETIVELY THROUGHOUT THE APP
 * FOR MORE CONSISITENT UI. 
 */




// Return random 5-7 digit key for use in mapping/keys/ids.
// IMPORTANT: Use for UI only, not for anything that needs to be secure. 
// Not "truly" random enough for security purposes.
export const randomized = () => Math.random().toString(36).substring(7);




 // takes ANY numeric string and returns a phone number formatted the correct way.
 // CORRECT: (123) 456-7890
 // INCORRECT: 848-484-4848, 939 393 9393, (848)484-4848, 2828282828
export function phoneFormat(phone) {
    //normalize string and remove all unnecessary characters
    const cleaned = phone.replace(/[^\d]/g, "");

    //check if number length equals to 10
    if (cleaned.length == 10) {
        //reformat and return phone number
        return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }

    return phone;
}

export function stripPhoneNumber (phone) {
    return phone.replace(/[^\d]/g, "");
}

export function convertToCamelCase(str) {
    return str
        .split(' ') // Split the string into words
        .map((word, index) => {
            if (index === 0) {
                return word.toLowerCase(); // Convert the first word to lowercase
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize the first letter of each subsequent word
        })
        .join(''); // Join the words back together without spaces
};