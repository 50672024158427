import React, { useState, useRef, useEffect, useContext } from "react";
import { Row, Container, Col, Label, Input, FormGroup } from "reactstrap";
import classNames from "classnames";
import styles from "./VerifyCode.module.css";
import SimpleReactValidator from 'simple-react-validator';
import { Redirect } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";

const VerifyCode = (props)=>{
    const sessionContext = useContext(SessionContext);
    const validator = useRef(new SimpleReactValidator({
        element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
        messages: {
            required: ':attribute is required.'
        },
        validators: {
            invalid: {
                message: `The verification code is invalid.`,
                rule: (val) => {
                    return val === true;
                }
            }
        }
    }));
    const [code, setCode] = useState('');
    const [valid, setValid] = useState(false)
    const [codeErr, setCodeErr] = useState(false);
    const [resetPasswordRoute, setResetPasswordRoute] = useState("");
    

    const saveRecords = (value, validStatus) => {
        sessionStorage.setItem("saveVerifyCodeData", JSON.stringify({
            verifyCode: code,
            invalid: validStatus
        }));
        window.location.reload();
    }

    const clearRecords = () => {
        sessionStorage.removeItem("saveVerifyCodeData");
    }

    useEffect(() => {
        var records = JSON.parse(sessionStorage.getItem("saveVerifyCodeData"));
        if(records !== null) {
            setCode(records.verifyCode);
            setValid(records.invalid);
        }
        return () => {
            sessionStorage.removeItem("saveVerifyCodeData");
        }
    }, [])

    const handleContinue = () => {
        
        console.log("location", props)
        if (validator.current.allValid() || code.length > 0) {
            validator.current.showMessageFor();
            const dealerId = props.location.state.dealerId;
            let route = `dealer/VerifyToken/${code}/${dealerId}`;
            fetch(route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }

            }).then(response => {
                //setIsSendEmailInProgress(false);
                if (response.status === 200) {
                    response.json().then(data => {
                        console.log(data)
                        if (data.isValid) {         
                            clearRecords();
                            sessionContext.updateVerifyPassLink(false);
                            sessionContext.updateConfirmPassLink(true);
                            props.history.push({
                                pathname: '/confirmpassword',
                                state: {
                                    dealerId: dealerId,
                                    code: code
                                },
                            });
                        } else {
                            setValid(true);
                            saveRecords(code, true);
                        }

                    });

                    //setIsDelearExists(true);

                    //
                } else if (response.status === 404) {

                    //setIsDelearExists(false);
                    //setValidationCheck(true);
                    //simpleValidator.current.showMessages();
                    //forceUpdate(1);
                
            } else if (response.status === 400) {
                    setCodeErr(false);
                    setValid(true);
                    saveRecords(code, true);
            }
            });
            


            //setResetPasswordRoute("/resetpassword");
            //props.history.push("/confirmpassword");
        } else {
            if (code.length === 0 || code === "")  {
                setCodeErr(true); 
            } else {
                setCodeErr(false);
            }
        }
       
    }

    const resendCode = () => {
        const dealerId = props.location.state.dealerId;
        let route = "dealer/ResetPasswordByDealerId/" + dealerId;
        fetch(route, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }

        }).then(response => {
            if (response.status === 200) {
            } else if (response.status === 404) {
            }
        });
    } 

    const handleInputChange = (e) => {
        
        let { value } = e.target;
        value = value.trim();
        validator.current.showMessageFor("the verification code");
        validator.current.showMessageFor("the verification code is invalid");
        var pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{4}-[89ab][0-9a-f]{4}-[0-9a-f]{12}$');
        if (!pattern.test(value) && (value.length >= 37)) {
            setValid(true);
        
        } else {
            setValid(false);
        }
         setCode(value);

        if (value.length == 0) {
            setCodeErr(true);
        
        } else {
            setCodeErr(false);
        }

    }
    
    if (resetPasswordRoute) return <Redirect to={resetPasswordRoute} />

    var records = JSON.parse(sessionStorage.getItem("saveVerifyCodeData"));
    if(!sessionContext.verifyPassClick) {
        if(records === null) {
            return <Redirect to="/login" />;
        }   
    }

    return (
        <div className={styles.vendorVerifyCode}>
            <Container className={styles.mainContainer}>
                <Col md="12" lg="12">
                    <Col lg="6" md="5" className={classNames("p-0")}>
                        <h1 className="h3">Verify It's You</h1>
                    </Col>
                    <Col lg="5" md="5" className={classNames("p-0", "pb-3", styles.infoText)}>
                        We are sending a verification code to your email. Please enter it below.
                    </Col>
                    <Col lg="4" md="5" className={classNames("p-0", "pb-3")}>
                        <FormGroup className={classNames(
                                    (validator.current.message("the verification code", code, "required") || 
                                    codeErr ||
                                    valid) && "form-error"
                                )
                            }
                        >
                            <label for="username" className="form-label">Enter Code</label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <Input name="dealerId" className="form-control"
                                defaultValue={code}
                                value={code}
                               onChange={(e)=>handleInputChange(e)}
                            />
                            {(codeErr && !valid) && <div class="errorMsg">The verification code is required.</div>}
                            {valid && <div class="errorMsg">The verification code is invalid.</div>}
                        </FormGroup>
                        <button className={classNames('btn-primary')} 
                            onClick={()=>handleContinue()}
                        >
                            CONTINUE
                        </button>
                        <Col md="12" className={styles.resendCol}>
                            <a className={styles.resendLink} onClick={() => resendCode()}>Resend Code</a>
                        </Col>
                    </Col>
                </Col>
            </Container>
        </div>
    )
}
export default VerifyCode;