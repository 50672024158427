import React, { useContext } from 'react'
import { getBrandContent } from '../../BrandingWhiteLabel/BrandContent';
import { SessionContext } from '../../../context/SessionContext';
import styles from "../ClientPhone/ClientPhone.module.css";
import { stripPhoneNumber } from '../util';
import classNames from "classnames";

const ClientPhone = ({customClass}) => {

    const {accountName} = useContext(SessionContext);

    const phone = getBrandContent(accountName).programPhone;

    const phoneStripped = stripPhoneNumber(phone);

    return <a className={classNames("callLink", customClass)} href={`tel:${phoneStripped}`}>{phone}</a>
}

export default ClientPhone;