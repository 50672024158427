import React, { forwardRef } from 'react';
import TermsAndConditions from '../shared/TermsAndConditions';


const TermsAndCondition = forwardRef((props, ref) => {
    const { validator, errors, document, viewDocument } = props;

    return (
        <TermsAndConditions
            validator={validator}
            errors={errors}
            fileUrl={(document)}
            viewPDFUrl={viewDocument}
        />
    );
});

export default TermsAndCondition;