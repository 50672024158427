import React from 'react'
// get a random ID. 
// Note: Math.random() is not suitable for SECURITY purposes, but for purposes of rowId's within UI, this works.
export const randomized = () => Math.random().toString(36).substring(7);
export const rndmvals = (multiplier) => Math.round(Math.random() * multiplier);
const blankLaborRow = {
    id: null,
    qty: 1,
    unitPrice: 0,
    rateType: {},
    coverage: '',
    isAuthDisabled: false,
}

const blankMileageRow = {
    id: null,
    miles: 0,
    tripType: { display: 'Round Trip', data: 'roundTrip' },
    ratePerMile: 0,
    mileageDescription: '',
    authorized: 0,
    billingCodeId: 0,
    qty: 0,
    requested: 0,
    unitPrice: 0.75,
    isAuthDisabled: false,
}

const blankPartsRow = {
    id: null,
    qty: 0,
    unitPrice: 0,
    billingCodeId: 9,
    details: [],
    partNumber: '',
    description: '',
    coverage: '',
    isAuthDisabled: false,
}

export const newEntry = (table,state) => {
    console.log('creating new entry')
    let uniquified;
    if (table === 'labor') {
        //overwrite with new values
        uniquified = {
            ...blankLaborRow,
            id: state.labor.length > 0 ? (state.labor[state.labor.length - 1].id > 0 ? (state.labor[state.labor.length - 1].id + 1) * -1 : state.labor[state.labor.length - 1].id - 1) : -1,
        }
    } else if (table === 'parts') {
        uniquified = {
            ...blankPartsRow,
            id: state.parts.length > 0 ? (state.parts[state.parts.length - 1].id > 0 ? (state.parts[state.parts.length - 1].id + 1) * -1 : state.parts[state.parts.length - 1].id - 1) : -1,
            description: null,
        }
    } else if (table === 'mileage') {
        uniquified = {
            ...blankMileageRow,
            id: state.mileage.length > 0 ? (state.mileage[state.mileage.length - 1].id > 0 ? (state.mileage[state.mileage.length - 1].id + 1) * -1 : state.mileage[state.mileage.length - 1].id - 1) : -1,
            unitPrice: state.rateValues?.roundTripRate > 0 ? state.rateValues?.roundTripRate : blankMileageRow.unitPrice
        }
    }
    const multiply = uniquified.unitPrice * uniquified.qty
    const aggregated = {
        ...uniquified,
        requested: multiply,
        authorized: multiply,
        authEdited: false
    }
    return aggregated
}

export function amountHeaderFormatter(column, colIndex) {
    return (
        <div style={{ textAlign: 'center' }}>
            {column.text}
        </div>
    );
}

export const tallyColumn = (dataset) => {
    dataset.reduce((acc, itm) => acc + itm)
}

export const calculateTotals = (rows, priceKey) => {
    const albert = rows.map(r => {
        const overwrite = !r.isAuthEdited
        r.requested = r[priceKey] * r.qty;
        if (overwrite) {
            r.authorized = r[priceKey] * r.qty;
        }
    })
    return albert;
}

export const currencyFormat = (val) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val)
