import React, { Component, Fragment } from "react";
import {
	Container, Card, CardBody, Row, Col, Label,
	Input, FormGroup, Progress, Badge
} from "reactstrap";
import classNames from "classnames";
import styles from "./PlanDetails.module.css";
import { AddProductInputs } from '../OrderInformation/AddProductInputs';
import { PlanOptions } from './PlanOptions';
import SimpleReactValidator from "simple-react-validator";
import AlertBanner from '../../shared/AlertBanner/AlertBanner';
import moment from 'moment';
import { ReactComponent as AddIcon } from "../../../assets/images/plus.svg";
import InfoTooltip from '../../shared/InfoTooltip/InfoTooltip';
import DeleteButton from '../../shared/DeleteButton/DeleteButton';
import { getBrandContent } from '../../../components/BrandingWhiteLabel/BrandContent';
import OrderInfoContext from '../../../context/OrderInfoContext';
import { dataLayerPush } from '../../../components/Global/helpers';
import { getCurrencySymbol } from '../../../lib/util';

let OEMCov = styles.OEMCov;
let circle = styles.circle;
let planCov = styles.planCov;
let progressBars = styles.progressBars;
let productList = styles.productList;
let preTextInput = styles.preTextInput;
let amountNum = styles.amountNum;
let activePrice = styles.activePrice;
let inactivePrice = styles.inactivePrice;
let totalAmount = styles.totalAmount;
let currencySym = styles.currencySym;

const AmountError = () => <p className="errorMsg">Please enter price greater than $0.</p>;

export class PlanDetails extends Component {

	constructor(props) {
		super(props);

		this.state = {
			popoverOpen: false,
			radioType: 0,
			selectedYears: 0,
			years: "",
			showAddProduct: false,
			packages: [],
			equipmentRetailPrice: null,
			dealerPo: this.props.dealerPo === undefined ? null : this.props.dealerPo,
			planRetailPrice: null,
			planRetailTax: null,
			planRetailPriceHST: null,
			planRetailPriceGST: null,
			stateTaxes: [],
			showBanner: false,
			hasAdvantageCampaign: false,
			profileModel: this.props.profileModel,
			campaign: {},
			planOptionsData: {},
			validationFired: false,
			bannerContent: "",
			bannerType: "",
			installDate: this.getInstallDate(),
			validation: false,
			advPreferred: false,
			isCampaign: true,
			assetPackagesData: this.props.assetPackagesData,
			errors: {
				equipmentRetailPrice: false,
				planRetailPrice: false,
				planRetailPriceHST: false,
				planRetailPriceGST: false,
				planRetailPricePST: false,
				planRetailPriceQST: false
			},
			partsOptionsMax: 10,
			laborOptionsMax: 10
		}

		this.loadAssetPackages = this.loadAssetPackages.bind(this);
		this.handleOptionChange = this.handleOptionChange.bind(this);
		this.validator = new SimpleReactValidator({
			element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
			messages: {
				required: ':attribute is required.'
			},
			validators: {
				amountCheck: {
					message: 'Please enter price greater than $0',
					rule: (val) => {
						return val > 0;
					}
				}
			}
		});

		this.optionRefs = []
		this.setOptionRef = (option, i) => {
			this.optionRefs[i] = option
		}
	}

	getInstallDate = () => {
		let installationDate = moment(this.props.installationDate, this.props.profileModel.countryId === 2 ? "DD/MM/YYYY" : "MM/DD/YYYY");
		let installDate = moment(installationDate).format("YYYY-MM-DD");

		return installDate;
	}

	async componentDidMount() {
		this.loadAssetPackages();
		this.loadTaxes();
		this.getAdvantageCampaign();
		this.loadWarrantyOptions();
		//console.log({ pros: this.props, state: this.state });

	}

	getAdvantageCampaign = async () => {
		const { profileModel, installDate } = this.state;
		const dealerId = profileModel.dealerId;
		const advantageProgramName = getBrandContent(this.context.accountName).advantageProgramName;

		try {
			let route = `dealer/GetActiveCampaignsByDealerId/${dealerId}/${installDate}`;
			let selectedYears = 0;
			let showBanner = profileModel.isLennoxDealer ? false : true;

			let campaign = await fetch(route).then(response => {
				if (response.status === 200) {
					return response.json();
				}
				this.setState({ 'isCampaign': false });
				throw new Error(`Network response was not ok ${response.status}`);
			});

			if (campaign) {
				let terms = await this.getTerms(campaign.tierId, campaign.coverageTypeId);
				if (typeof terms !== 'undefined') {
					terms = terms.filter(t => { return t.termId === campaign.termId });
					if (typeof terms[0] !== 'undefined') {
						selectedYears = terms[0].termYears;
					}
				}
				//SEU-7796
				showBanner = campaign.endDate !== null ? (installDate >= moment.utc(campaign.startDate).format("YYYY-MM-DD") && installDate <= moment.utc(campaign.endDate).format("YYYY-MM-DD")) ? true : false : (installDate >= moment.utc(campaign.startDate).format("YYYY-MM-DD") ? true : false)

				this.setState({
					campaign: campaign,
					hasAdvantageCampaign: campaign !== null,
					selectedYears: selectedYears,
					bannerType: 'info',
					bannerContent: showBanner ? `No action needed for ${advantageProgramName} Plan Creation.` : "",
					showBanner: showBanner ? true : false,
				});
			}
		} catch (error) {
			this.setState({ 'isCampaign': false });
			console.error(error);
		}
	}

	getTerms = async (tierId, coverageTypeId) => {
		let terrms = []
		try {
			const response = await fetch(`tier/gettermsbytieridandcoveragetypeid/${tierId}/${coverageTypeId}`);
			const data = await response.json();
			terrms = data.map(t => {
				return {
					termYears: (t.termInDays / 365),
					termId: t.termId
				}
			});
			this.setState({ terms: terrms });
		} catch (error) {
			this.setState({ errorMessage: 'Error fetching terms. Try again.' });
		}
		return terrms;
	}

	loadTaxes = () => {
		fetch(`tax/gettaxdetailsbystateid/${this.props.installationStateId}/arw`)
			.then(response => response.json())
			.then(data => {
				if (data !== null) {
					this.setState({ stateTaxes: data.map(st => st.taxTypeModel.taxTypeName) });
				}
				console.log(this.state.stateTaxes);
			});
	}

	handleGenericError = (responseText) => {
		let validResponse = ['100', '200', '300'];
		this.setState({ unknownError: false });

		if (validResponse.includes(responseText.toString()) === false)
			this.setState({ unknownError: true });
	}

	packagesAreTheSame = (packageA, packageB) => {
		let packageIdListA = packageA.map(a => a.assetPackageId);
		let packageIdListB = packageB.map(a => a.assetPackageId);

		if (packageIdListA.length !== packageIdListB.length) return false;

		for (let i = 0; i < packageIdListA.length; i++) {
			if (packageIdListB.find(a => a === packageIdListA) === undefined)
				return false;
		}

		return true;
	}

	async loadAssetPackages() {
		let offerDetails = this.props.offerDetails;
		let returnedPackages = offerDetails.packages;
		let packages = (this.props.assetPackagesData !== null && this.props.assetPackagesData.length !== 0 &&
			(this.packagesAreTheSame(returnedPackages, this.props.assetPackagesData) || this.props.savedSale))
			? this.props.assetPackagesData
			: returnedPackages;

		if (this.state.hasAdvantageCampaign || packages.length !== 0) {
			for (let index = 0; index < packages.length; index++) {
				packages[index].selectedLabor = packages.length !== 0 ? packages[index].selectedLabor : this.state.campaign.tierId;
				packages[index].blnSelectedCoverage = true;
				packages[index].selectedCoverage = packages.length !== 0 ? packages[index].selectedCoverage : this.state.campaign.coverageTypeId;
				packages[index].selectedYears = packages.length !== 0 ? packages[index].selectedYears : this.state.selectedYears;
				packages[index].selectedTermId = packages.length !== 0 ? packages[index].selectedTermId : this.state.campaign.termId;
				packages[index].blnSelectedYears = true;
				packages[index].dealerPo = packages.length !== 0 ? packages[index].dealerPo === undefined ? this.state.dealerPo : packages[index].dealerPo : "";
				packages[index].equipmentRetailPrice = packages.length !== 0 ? packages[index].equipmentRetailPrice : null;
				packages[index].planRetailPrice = packages.length !== 0 ? packages[index].planRetailPrice : null;
				packages[index].planRetailPriceQST = packages.length !== 0 ? packages[index].planRetailPriceQST : null;
				packages[index].planRetailPriceGST = packages.length !== 0 ? packages[index].planRetailPriceGST : null;
				packages[index].planPrice = packages.length !== 0 ? packages[index].planPrice : null;
				packages[index].internalPreferred = packages.length !== 0 ? packages[index].internalPreferred : false;
				packages[index].dispOptProdBtn = packages.length !== 0 ? await this.showOptionalProductButton(packages[index]) : false
			}
		}
		const assetPackagesForGtm = [];
		packages.map((value, i) => assetPackagesForGtm.push({
			item_name: value?.name,
			quantity: packages.length,
			product_qty: value?.assets?.length,
			products: value.assets?.map(e => ({
				brand: e?.brand,
				prod_description: this.props.productTableData.find(
					x => x.assetId == e.assetId
				).prodDescription
			}))
		}))
		dataLayerPush(null, { ecommerce: null }) // clear last ecommerce data
		dataLayerPush('select_item', {
			ecommerce: {
				items: [
					...assetPackagesForGtm
				]
			}
		})
		this.setState({
			packages: packages,
			advPreferred: packages.find(p => p.offers.find(o => o.internalPreferred)) ? true : false,
			planOptionsData: {
				coverages: offerDetails.coverages,
				tiers: offerDetails.tiers,
				terms: offerDetails.terms
			}
		});
		this.onValidatePriceChange(packages);
		this.props.updateAssetPackagesData(packages)
	}

	onTypeRadioChange = (newData) => {
		this.setState({ ...newData });
	}

	onTermRadioChange = async (newData) => {
		this.setState({ ...newData });

		let assetPackages = this.props.assetPackagesData;
		for (let index = 0; index < assetPackages.length; index++) {
			if (newData.assetPackageId === assetPackages[index]?.assetPackageId) {
				let offers = assetPackages[index]?.offers;
				let currentOffer = offers.find(offer =>
					offer.coverageTypeId === newData.selectedCoverage &&
					offer.tierId === newData.selectedLabor &&
					offer.termId === newData.selectedTermId);

				assetPackages[index].selectedYears = newData.selectedYears;
				assetPackages[index].planPrice = currentOffer.planPrice;
				assetPackages[index].offerId = currentOffer.offerId;
				assetPackages[index].planDesc = await this.getRiskProfile(currentOffer.offerId)
			}
		}
		this.props.updateAssetPackagesData(assetPackages);
		this.onValidatePriceChange(assetPackages);
	}

	onValidatePriceChange = (packages) => {
		this.validator.purgeFields();
	}

	getRiskProfile = async (offerId) => {
		{
			const ret = await fetch('pricing/getriskprofilebyofferid/' + offerId);
			const data = await ret.json();
			return data.description;
		}
	}

	formIsValid = () => {
		if (this.validator.allValid()) {
			return true;
		} else {
			this.validator.showMessages();
			this.setState({
				validationFired: true
			});
			return false;
		}
	}

	validatePackageData = () => {
		let planValidationStatus = true;
		for (let i = 0; i < this.optionRefs.length; i++) {
			if (this.optionRefs[i] != null && !this.optionRefs[i].formIsValid()) {
				planValidationStatus = false;
				break;
			}
		}
		const packageDetailsStatus = this.validatePackageDetailsData(this.props.assetPackagesData);

		if (this.validator.allValid() && packageDetailsStatus && planValidationStatus) {
			return true;
		} else {
			this.validator.showMessages();
			this.setState({ validationFired: true });
			this.forceUpdate();
			return false;
		}
	}

	validatePackageDetailsData = (assetPackages) => {
		for (let index = 0; index < assetPackages.length; index++) {

			if (assetPackages[index]?.equipmentRetailPrice === "" ||
				assetPackages[index]?.equipmentRetailPrice === undefined) {
				return false;
			}

			if (this.props.isCanadaState) {
				for (let j = 0; j < this.state.stateTaxes.length; j++) {
					if (this.state.stateTaxes[j] === "HST") {
						if (assetPackages[index]?.planRetailPriceHST === "" ||
							assetPackages[index]?.planRetailPriceHST === undefined) {
							return false;
						}
					}
					if (this.state.stateTaxes[j] === "GST") {
						if (assetPackages[index]?.planRetailPriceGST === "" ||
							assetPackages[index]?.planRetailPriceGST === undefined) {
							return false;
						}
					}
					if (this.state.stateTaxes[j] === "PST") {
						if (assetPackages[index]?.planRetailPricePST === "" ||
							assetPackages[index]?.planRetailPricePST === undefined) {
							return false;
						}
					}
					if (this.state.stateTaxes[j] === "QST") {
						if (assetPackages[index]?.planRetailPriceQST === "" ||
							assetPackages[index]?.planRetailPriceQST === undefined) {
							return false;
						}
					}
				}
				if (assetPackages[index]?.planRetailPrice === "" ||
					assetPackages[index]?.planRetailPrice === undefined) {
					return false;
				}
			}
		}

		return true;
	}

	handleRetailePrice = async (e, assetPackageId) => {
		const { errors } = this.state;
		this.setState({ equipmentRetailPrice: e.target.value });
		let assetPackages = this.props.assetPackagesData;

		const newValue = (e.target.value === undefined || e.target.value <= 0);
		this.formIsValid();
		this.setState({
			errors: {
				...errors,
				equipmentRetailPrice: newValue
			}
		});

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				assetPackages[index].equipmentRetailPrice = e.target.value;
			}
		}

		await this.props.updateAssetPackagesData(assetPackages);
		this.onValidatePriceChange(assetPackages);
	}

	validatePackagePriceDetailsData = () => {
		let assetPackages = this.props.assetPackagesData;
		for (let index = 0; index < assetPackages.length; index++) {
			if (Number(assetPackages[index]?.equipmentRetailPrice) === 0) {
				return true;
			}

			if (this.props.isCanadaState) {
				for (let j = 0; j < this.state.stateTaxes.length; j++) {
					if (this.state.stateTaxes[j] === "HST") {
						if (Number(assetPackages[index]?.planRetailPriceHST) === 0) {
							return true;
						}
					}
					if (this.state.stateTaxes[j] === "GST") {
						if (Number(assetPackages[index]?.planRetailPriceGST) === 0) {
							return true;
						}
					}
					if (this.state.stateTaxes[j] === "PST") {
						if (Number(assetPackages[index]?.planRetailPricePST) === 0) {
							return true;
						}
					}
					if (this.state.stateTaxes[j] === "QST") {
						if (Number(assetPackages[index]?.planRetailPriceQST) === 0) {
							return true;
						}
					}
				}
				if (Number(assetPackages[index]?.planRetailPrice) === 0) {
					return true;
				}
			}
		}

		return false;
	}

	getRetailePrice = (assetPackageId) => {
		let assetPackages = this.props.assetPackagesData

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				return assetPackages[index]?.equipmentRetailPrice
			}
		}
	}

	handlePlanRetailePrice = async (e, assetPackageId) => {
		const { errors } = this.state;
		this.setState({ planRetailPrice: e.target.value });
		let assetPackages = this.props.assetPackagesData;
		const newValue = (e.target.value === undefined || e.target.value <= 0);
		this.setState({
			errors: {
				...errors,
				planRetailPrice: newValue
			}
		})

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				assetPackages[index].planRetailPrice = e.target.value;
			}
		}

		await this.props.updateAssetPackagesData(assetPackages);
		this.onValidatePriceChange(assetPackages);
	}

	getPlanRetailePrice = (assetPackageId) => {
		let assetPackages = this.props.assetPackagesData

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				return assetPackages[index]?.planRetailPrice
			}
		}
	}

	handlePlanRetaileTax = async (e, assetPackageId) => {
		this.setState({ planRetailTax: e.target.value });
		let assetPackages = this.props.assetPackagesData

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				assetPackages[index].planRetailTax = e.target.value;
			}
		}

		this.props.updateAssetPackagesData(assetPackages);
		await this.onValidatePriceChange(assetPackages);
	}

	handlePlanRetaileGST = (e, assetPackageId) => {
		this.setState({ planRetailPriceGST: e.target.value });
		let assetPackages = this.props.assetPackagesData

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				assetPackages[index].planRetailPriceGST = e.target.value;
			}
		}
		this.onValidatePriceChange(assetPackages);
		this.props.updateAssetPackagesData(assetPackages);
	}

	getTaxValue = (stateTax, assetPackageId) => {
		let assetPackages = this.props.assetPackagesData

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {

				if (stateTax === "GST")
					return assetPackages[index]?.planRetailPriceGST;
				else if (stateTax === "HST")
					return assetPackages[index]?.planRetailPriceHST;
				else if (stateTax === "PST")
					return assetPackages[index]?.planRetailPricePST;
				else if (stateTax === "QST")
					return assetPackages[index]?.planRetailPriceQST;
			}
		}
	}

	setTaxValue = async (e, stateTax, assetPackageId) => {
		let assetPackages = this.props.assetPackagesData
		const { errors } = this.state;

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				const newValue = (e.target.value === undefined || e.target.value <= 0);

				if (stateTax === "GST") {
					this.setState({
						errors: {
							...errors,
							planRetailPriceGST: newValue
						}
					})
					assetPackages[index].planRetailPriceGST = e.target.value;
				}
				if (stateTax === "HST") {
					this.setState({
						errors: {
							...errors,
							planRetailPriceHST: newValue
						}
					})
					assetPackages[index].planRetailPriceHST = e.target.value;
				}
				if (stateTax === "PST") {
					this.setState({
						errors: {
							...errors,
							planRetailPricePST: newValue
						}
					})
					assetPackages[index].planRetailPricePST = e.target.value;
				}
				if (stateTax === "QST") {
					this.setState({
						errors: {
							...errors,
							planRetailPriceQST: newValue
						}
					})
					assetPackages[index].planRetailPriceQST = e.target.value;
				}
			}
		}

		await this.props.updateAssetPackagesData(assetPackages);
		this.onValidatePriceChange(assetPackages);
	}

	handlePlanRetaileHST = (e, assetPackageId) => {
		this.setState({ planRetailPriceHST: e.target.value });
		let assetPackages = this.props.assetPackagesData

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				assetPackages[index].planRetailPriceHST = e.target.value;
			}
		}

		this.props.updateAssetPackagesData(assetPackages);
		this.onValidatePriceChange(assetPackages);
	}

	handleUpdateAssets = (assetPackageId, assetId, brandName, serialNum, brandId, modelNumb, series) => {
		let assetPackages = this.props.assetPackagesData;

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				assetPackages[index].assetIds.push(assetId);

				let existingAsset = assetPackages[index]?.assets.find(a => a.assetId == assetId && a.brand == brandName);
				if (typeof existingAsset == 'undefined') {
					assetPackages[index].assets.push({ assetId: assetId, brand: brandName, serialNumber: serialNum, brandId: brandId, modelNumber: modelNumb, series: series, count: 1 });
				} else {
					existingAsset.count += 1;
					assetPackages[index].assets.push({ assetId: assetId, brand: brandName, serialNumber: serialNum, brandId: brandId, modelNumber: modelNumb, series: series, count: existingAsset.count });
				}
			}
		}
		this.props.updateAssetPackagesData(assetPackages);
		this.setState({ assetPackages: assetPackages });
	}

	handleDeleteAssets = (assetPackageId, assetId) => {
		let assetPackages = this.props.assetPackagesData;
		const assetPackagesForGtm = [];
		(this.props.assetPackagesData || []).map((value, i) => {
			if (value.assetId === assetId) {
				assetPackagesForGtm.push({
					item_name: value?.name,
					quantity: this.props.assetPackagesData.length ?? 0,
					product_qty: value?.assets?.length,
					price: value?.planPrice,
					retail: value?.equipmentRetailPrice,
					reimbursement: '',
					type: '',
					term: value?.selectedYears,
					products: value.assets?.map(e => ({
						brand: e?.brand,
						prod_description: this.props.productTableData.find(
							x => x.assetId == e.assetId
						).prodDescription
					}))
				})

			}
		})
		dataLayerPush('remove-item', {
			ecommerce: {
				items: [
					{
						...assetPackagesForGtm
					}
				]
			}
		})
		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				assetPackages[index].assetIds = assetPackages[index]?.assetIds.filter(a => a != assetId);
			}
		}
		this.props.updateAssetPackagesData(assetPackages);
		this.setState({ assetPackages: assetPackages });

	}

	handleDealerPo = (e, assetPackageId) => {
		this.setState({ dealerPo: e.target.value });
		let assetPackages = this.props.assetPackagesData

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				assetPackages[index].dealerPo = e.target.value;
			}
		}
		this.props.updateAssetPackagesData(assetPackages);
	}

	getDealerPo = (assetPackageId) => {
		let assetPackages = this.props.assetPackagesData

		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				return assetPackages[index]?.dealerPo
			}
		}
	}

	handleDelete = (assetPackageId, i) => {
		this.optionRefs.splice(i, 1);
		let packages = this.state.packages.filter(ap => ap.assetPackageId != assetPackageId);

		let deletedAssets = this.state.packages.find(ap => ap.assetPackageId == assetPackageId)?.assets?.map(a => a.serialNumber);

		let productTableData = this.props.productTableData;
		for (var product of productTableData) {
			if (deletedAssets.includes(product.serial) || deletedAssets.includes(product.serialNumBack)) {
				product.isChecked = false;
			}
		}

		this.setState({
			packages: packages
		}, () => {
			this.props.updateAssetPackagesData(this.state.packages);
			this.props.updateProductTable(productTableData);

			let offerDetails = this.props.offerDetails;
			let packages = offerDetails.packages;
			packages = packages.filter(ap => ap.assetPackageId != assetPackageId);
			offerDetails.packages = packages;
			let offerLookupRequest = this.props.offerLookupRequest;
			let assets = offerLookupRequest.assets.filter(a => !deletedAssets.includes(a.serialNumber));;
			offerLookupRequest.assets = assets;

			this.props.setOffers(offerDetails, offerLookupRequest);
		});


		this.onValidatePriceChange(this.state.packages);
	}

	handleShowProduct = async (assetPackageId, status) => {
		let assetPackages = this.props.assetPackagesData;
		for (let index = 0; index < assetPackages.length; index++) {
			if (assetPackages[index]?.assetPackageId === assetPackageId) {
				assetPackages[index].showOptionalProducts = status;
				if (!status) {
					assetPackages[index].dispOptProdBtn = await this.showOptionalProductButton(assetPackages[index]);
				}

			}
		}
		this.props.updateAssetPackagesData(assetPackages);
		this.setState({ assetPackages: assetPackages });
	}

	handleOptionChange(advantage = false) {
		this.setState(prevState => {
			return {
				...prevState,
				hasAdvantageCampaign: advantage
			};
		});
	}

	getBrandMapping = (assets) => {

		let brandMap = [];

		assets.forEach((asset) => {
			let existingMapping = brandMap.find((a) => a.assetId == asset.assetId && a.brand == asset.brand);
			if (existingMapping === undefined) {
				brandMap.push({
					assetId: asset.assetId,
					brand: asset.brand,
					count: 1
				});
			} else {
				existingMapping.count = existingMapping.count + 1;
			}
		});

		return brandMap;
	}

	stateTaxClasses = (stateTax, assetPackage) => {
		if (stateTax == "HST" && (!assetPackage.planRetailPriceHST || Number(assetPackage.planRetailPriceHST) === 0)) return true;
		if (stateTax == "GST" && (!assetPackage.planRetailPriceGST || Number(assetPackage.planRetailPriceGST) === 0)) return true;
		if (stateTax == "PST" && (!assetPackage.planRetailPricePST || Number(assetPackage.planRetailPricePST) === 0)) return true;
		if (stateTax == "QST" && (!assetPackage.planRetailPriceQST || Number(assetPackage.planRetailPriceQST) === 0)) return true;
	}

	stateTaxErrors = (stateTax, assetPackage) => {
		return (
			this.validator.message(stateTax, assetPackage, 'required') && (
				<div className="errorMsg">
					{(assetPackage === undefined || assetPackage === '') && `Plan price ${stateTax} is required.`}
				</div>
			)
		);
	}

	showOptionalProductButton = async (assetPackage) => {
		const packageRes = await fetch('AssetPackage/' + assetPackage.packageId);
		const packageData = await packageRes.json();
		if (assetPackage.assetIds.length >= (packageData?.mandatoryProducts + packageData?.optionalProducts)) {
			return false;
		}
		const res = await fetch('AssetPackage/getassetsbyassetpackageid/' + assetPackage.packageId)
		const optionalData = await res.json();

		if (optionalData.length > 0) {

			const masterId = this.props.dealerProfile.masterProgramId;

			if (masterId !== undefined) {
				const resAll = await fetch('Asset/GetAssetsByMasterProgramId/' + masterId)
				const dataAsset = await resAll.json();

				for (let index = 0; index < dataAsset.length; index++) {
					let option = optionalData.filter(
						(a) => a.assetId == dataAsset[index].assetId && a.maxAllowed > assetPackage.assetIds.filter(ai => ai === a.assetId).length
					);
					if (option.length !== 0) {
						return true;
					}
				}
			}
		}
		return false;
	}

	isLaborCoverage = (coverageType) => {
		const laborTypes = [1, 10, 3, 4, 6, 13, 8, 9];
		return laborTypes.includes(coverageType);
	}

	isPartsCoverage = (coverageType) => {
		const partsTypes = [5, 6, 13, 8, 9]
		return partsTypes.includes(coverageType);
	}

	loadWarrantyOptions = () => {
		const { profileModel } = this.state;
		let accountId = profileModel?.accountId;

		fetch("Account/getWarrantyOptions/" + accountId).then((res) => {
			if (res.ok) {
				res.json().then((warrantyOptions) => {
					const sortedMfg = warrantyOptions?.partsOptions?.sort(
						(a, b) => parseFloat(b) - parseFloat(a)
					);

					const sortedMfgLabor = warrantyOptions?.laborOptions?.sort(
						(a, b) => parseFloat(b) - parseFloat(a)
					);

					this.setState({
						partsOptionsMax: sortedMfg?.[0] ?? 10,
						laborOptionsMax: sortedMfgLabor?.[0] ?? 10
					});
				});
			}
		});
	};

	render() {
		const { selectedYears, radioType, years, showAddProduct, hasAdvantageCampaign = true } = this.state;
		const distinct = (value, index, self) => {
			return self.indexOf(value) === index;
		}
		const { accountName } = this.context
		const { partsOptionsMax, laborOptionsMax } = this.state;
		const currency = getCurrencySymbol(this.props.dealerProfile?.countryId);

        return (
            <div>
                {hasAdvantageCampaign && !this.state.isLennox && this.state.advPreferred && (
                    <AlertBanner
                        content={this.state.bannerContent}
                        onClose={() => this.setState({ showBanner: false })}
                        show={this.state.showBanner}
                        bannerType={this.state.bannerType}
                    />
                )}
                <Container className="contentWrapper">
                    <h1 className={`h3 ${styles.pageHeader}`}>Plan Details</h1>
                    {this.state.packages.map((assetPackage, i) => (
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg="7">
                                        <div className={styles.planHeader}>
                                            <h2>{assetPackage.name}</h2>
                                            <span>
                                                {`${assetPackage.assets.length} Product${assetPackage.assets.length == "1" ? "" : "s"}`}
                                            </span>
                                        </div>
                                        {assetPackage.internalPreferred && this.state.isCampaign===true && <div type="button" className={styles.sideTab}>
                                            {getBrandContent(accountName).advantageProgramName} Plan
                                        </div>}
                                        <div className={classNames(totalAmount, (assetPackage.selectedYears > 0) ? activePrice : inactivePrice)}>
                                            <p className={classNames(currencySym, (assetPackage.selectedYears > 0) ? activePrice : inactivePrice)}>{currency}</p>
                                            <div>
                                                <p
                                                    className={classNames(
                                                        amountNum,
                                                        assetPackage.selectedYears > 0 ? activePrice : inactivePrice
                                                    )}
                                                >
                                                    {assetPackage.planPrice == 0 || typeof assetPackage.planPrice == "undefined"
                                                        ? "0.00"
                                                        : Number.parseFloat(assetPackage.planPrice).toFixed(2)}
                                                </p>
                                                 <p>Plan Price</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="5">
                                        <div className={styles.measureheader}>
                                            <h4 className='h5'>Product Coverage</h4>
                                            <div className={styles.circleLabel}>
                                                <span className={classNames(OEMCov, circle)}></span>
                                                <p>OEM</p>
                                            </div>
                                            <div className={styles.circleLabel}>
                                                <span className={classNames(planCov, circle)}></span>
                                                <p>{getBrandContent(accountName).programName}</p>
                                            </div>
                                            <span className={styles.deleteCard}><DeleteButton onClick={() => this.handleDelete(assetPackage.assetPackageId, i)} /></span>

										</div>

										<div className={styles.progressBars}>
											<div className={styles.oemSubHead}>
												{assetPackage.coverage.labor > 0 &&
													(<>
														<p className={styles.planName}>OEM</p>
														{(!this.isLaborCoverage(assetPackage.selectedCoverage) || assetPackage.coverage.labor > assetPackage.selectedYears) && <p> - {assetPackage.coverage.labor}Yr </p>}
													</>)}
												{assetPackage.selectedYears > 0 && this.isLaborCoverage(assetPackage.selectedCoverage) && assetPackage.coverage.labor > 0 &&
													(<>
														<p> + {getBrandContent(accountName).programName}</p>
														<p> - {assetPackage.selectedYears}Yr</p>
													</>)}
												{assetPackage.selectedYears > 0 && this.isLaborCoverage(assetPackage.selectedCoverage) && !assetPackage.coverage.labor > 0 &&
													(<>
														<p>{getBrandContent(accountName).programName}</p>
														<p> - {assetPackage.selectedYears}Yr</p>
													</>)}
											</div>
											{assetPackage.coverage.labor !== null && !this.isLaborCoverage(assetPackage.selectedCoverage) ?
												(<>
													<div className={`${styles.aigSubH}`} style={{ width: `${assetPackage.coverage.labor / laborOptionsMax * 100}%` }}>
														<span className={styles.progressDesignTop}></span>
													</div>
													<Progress value={100 * (assetPackage.coverage.labor / laborOptionsMax)} className={styles.progress}
														id="progress" barClassName={styles.oemProgress}></Progress>
													<div className={`${styles.aigSubH}`} style={{ width: `${assetPackage.coverage.labor / laborOptionsMax * 100}%` }}>
														<span className={styles.progressDesignBottom}></span>
													</div>
												</>) :
												this.isLaborCoverage(assetPackage.selectedCoverage) && assetPackage.coverage.labor < assetPackage.selectedYears ?
													(<>
														<div className={`${styles.aigSubH}`}
															style={{ width: `${(assetPackage.selectedYears) > laborOptionsMax ? 100 : (assetPackage.selectedYears) / laborOptionsMax * 100}%` }}>
															<span className={styles.progressDesignTop}></span>
														</div>
														<Progress multi className={styles.progress}>
															<Progress bar value={100 * ((assetPackage.coverage.labor) / laborOptionsMax)}
																id="progress" barClassName={styles.oemProgress} />
															<Progress bar value={100 * ((assetPackage.selectedYears - assetPackage.coverage.labor) / laborOptionsMax)}
																id="progress" barClassName={styles.planProgress} />
														</Progress>
														<div className={`${styles.aigSubH}`}
															style={{ width: `${(assetPackage.selectedYears) > laborOptionsMax ? 100 : (assetPackage.selectedYears) / laborOptionsMax * 100}%` }}>
															<span className={styles.progressDesignBottom}></span>
														</div>
													</>) :
													this.isLaborCoverage(assetPackage.selectedCoverage) && assetPackage.coverage.labor > assetPackage.selectedYears ?
														(<>
															<div className={`${styles.aigSubH}`} style={{ width: `${assetPackage.coverage.labor / laborOptionsMax * 100}%` }}>
																<span className={styles.progressDesignTop}></span>
															</div>
															<Progress value={100 * (assetPackage.coverage.labor / laborOptionsMax)} className={styles.progress}
																id="progress" barClassName={styles.oemProgress}></Progress>
															<div className={`${styles.aigSubH}`} style={{ width: `${assetPackage.coverage.labor / laborOptionsMax * 100}%` }}>
																<span className={styles.progressDesignBottom}></span>
															</div>
														</>) :
														(<>
															{assetPackage.selectedYears > 0 && this.isLaborCoverage(assetPackage.selectedCoverage) && <div className={`${styles.aigSubH}`} style={{ width: `${assetPackage.selectedYears / laborOptionsMax * 100}%` }}>
																<span className={styles.progressDesignTop}></span>
															</div>}
															<Progress value={assetPackage.selectedYears !== null && this.isLaborCoverage(assetPackage.selectedCoverage) ? 100 * (assetPackage.selectedYears / laborOptionsMax) : 0} className={styles.progress} id="progress" color="aigProgressBar" barClassName={styles.planProgress}></Progress>
															{assetPackage.selectedYears > 0 && this.isLaborCoverage(assetPackage.selectedCoverage) && <div className={`${styles.aigSubH}`} style={{ width: `${assetPackage.selectedYears / laborOptionsMax * 100}%` }}>
																<span className={styles.progressDesignBottom}></span>
															</div>}
														</>)}
											<div className={styles.progressTimeline}>
												<p className={'h5'}>{this.props.isCanadaState ? "Labour" : "Labor"}</p>
												<p className={'h5'}>{laborOptionsMax + "Yrs"}</p>
											</div>
										</div>

										<div className={classNames(progressBars, "mt-0")}>
											<div className={styles.oemSubHead}>
												{assetPackage.coverage.parts > 0 &&
													(<>
														<p className={styles.planName}>OEM</p>
														{(!this.isPartsCoverage(assetPackage.selectedCoverage) || assetPackage.coverage.parts > assetPackage.selectedYears) && <p> - {assetPackage.coverage.parts}Yr </p>}
													</>)}
												{assetPackage.selectedYears > 0 && this.isPartsCoverage(assetPackage.selectedCoverage) &&
													(<>
														<p> + {getBrandContent(accountName).programName}</p>
														<p> - {assetPackage.selectedYears}Yr</p>
													</>)}
											</div>
											{assetPackage.coverage.parts !== null && !this.isPartsCoverage(assetPackage.selectedCoverage) &&
												(<>
													<div className={`${styles.aigSubH}`} style={{ width: `${assetPackage.coverage.parts / partsOptionsMax * 100}%` }}>
														<span className={styles.progressDesignTop}></span>
													</div>
													<Progress value={100 * (assetPackage.coverage.parts / partsOptionsMax)} className={styles.progress}
														id="progress" barClassName={styles.oemProgress}></Progress>
													<div className={`${styles.aigSubH}`} style={{ width: `${assetPackage.coverage.parts / partsOptionsMax * 100}%` }}>
														<span className={styles.progressDesignBottom}></span>
													</div>
												</>)}
											{this.isPartsCoverage(assetPackage.selectedCoverage) && assetPackage.coverage.parts < assetPackage.selectedYears &&
												(<>
													<div className={`${styles.aigSubH}`}
														style={{ width: `${(assetPackage.selectedYears) > partsOptionsMax ? 100 : (assetPackage.selectedYears) / partsOptionsMax * 100}%` }}>
														<span className={styles.progressDesignTop}></span>
													</div>
													<Progress multi className={styles.progress}>
														<Progress bar value={100 * ((assetPackage.coverage.parts) / partsOptionsMax)}
															id="progress" barClassName={styles.oemProgress} />
														<Progress bar value={100 * ((assetPackage.selectedYears - assetPackage.coverage.parts) / partsOptionsMax)}
															id="progress" barClassName={styles.planProgress} />
													</Progress>
													<div
														className={`${styles.aigSubH}`}
														style={{ width: `${(assetPackage.selectedYears) > partsOptionsMax ? 100 : (assetPackage.selectedYears) / partsOptionsMax * 100}%` }}
													>
														<span className={styles.progressDesignBottom}></span>
													</div>
												</>)}
											{this.isPartsCoverage(assetPackage.selectedCoverage) && assetPackage.coverage.parts > assetPackage.selectedYears &&
												(<>
													<div className={`${styles.aigSubH}`} style={{ width: `${assetPackage.coverage.parts / partsOptionsMax * 100}%` }}>
														<span className={styles.progressDesignTop}></span>
													</div>
													<Progress value={100 * (assetPackage.coverage.parts / partsOptionsMax)} className={styles.progress}
														id="progress" barClassName={styles.oemProgress}></Progress>
													<div
														className={`${styles.aigSubH}`}
														style={{ width: `${assetPackage.coverage.parts / partsOptionsMax * 100}%` }}
													>
														<span className={styles.progressDesignBottom}></span>
													</div>
												</>)}

											<div className={styles.progressTimeline}>
												<div className={`h5 ${styles.partsSubhead}`}>
													Parts
                          <div className={styles.infoIcon}>
														<InfoTooltip iconType="Info" message="Factory parts warranty assumes equipment is eligible for parts extension with the equipment OEM and registration is done within the OEM guidelines." />
													</div>
												</div>
												<p className="h5">{this.state.partsOptionsMax + "Yrs"}</p>
											</div>
										</div>
									</Col>
								</Row>

								<hr className="cardSectionHR" />
								<div className={classNames("row", "multiColWrapper", productList)}>
									{this.getBrandMapping(assetPackage.assets).map(asset => (
										<div class="col-5ths col-sm-6 col-md-4 mb-3">
											<div className={styles.product}>
												<div className="d-flex flex-column">
													<div className="d-flex">
														<p className={styles.productName}>
															{
																this.props.productTableData.find(
																	x => x.assetId == asset.assetId
																).prodDescription
															}
														</p>
														{asset.count > 1 && (
															<div className="ml-2 d-flex justify-content-end">
																<div className={styles.noOfProd}>{asset.count}</div>
															</div>
														)}
													</div>
													<p>{asset.brand}</p>
												</div>
											</div>
										</div>
									))}
									{assetPackage.dispOptProdBtn == true && (
										<Col md="4" sm="6" className="col-5ths mb-3">
											<button
												className={styles.addProduct}
												onClick={() => this.handleShowProduct(assetPackage.assetPackageId, true)}
											>
												<AddIcon />
												<p>Add optional product</p>
											</button>
										</Col>
									)}
								</div>
								<hr className="cardSectionHR" />
								{assetPackage.showOptionalProducts === true &&
									<div>
										<AddProductInputs
											checkIfCanContinue={this.props.checkIfCanContinue}
											updateProductTable={this.props.updateProductTable}
											fromPlanDetails={true}
											productTableData={this.props.productTableData}
											assetPackageId={assetPackage.assetPackageId}
											packageId={assetPackage.packageId}
											handleUpdateAssets={this.handleUpdateAssets}
											handleDeleteAssets={this.handleDeleteAssets}
											handleGenericError={this.handleGenericError}
											assetPackage={this.state.assetPackage}
											handleAddClose={true}
											onCancel={() => this.handleShowProduct(assetPackage.assetPackageId, false)}
											updateOrderParty={this.props.updateOrderParty}
											serialNum={this.props.serialNum}
											modelNum={this.props.modelNum}
											brandNameDropDown={this.props.brandNameDropDown}
											prodDescDropDown={this.props.prodDescDropDown}
											mfgPartsDropDown={this.props.mfgPartsDropDown}
											screen="planDetails"
										/> <hr></hr></div>}
								<div>
									<h3 className='h5'>Select {this.props.isCanadaState ? "Labour" : "Labor"} Reimbursement to View Plan Options</h3>
									<FormGroup>
										<PlanOptions ref={option => this.setOptionRef(option, i)}
											assetPackageId={assetPackage.assetPackageId}
											assetPackage={assetPackage}
											isCanadaState={this.props.isCanadaState}
											onTermChange={this.onTermRadioChange}
											onTypeChange={this.onTypeRadioChange}
											updateAssetPackagesData={this.props.updateAssetPackagesData}
											assetPackagesData={this.props.assetPackagesData}
											hasAdvantageCampaign={this.state.hasAdvantageCampaign}
											campaign={this.state.campaign}
											installationDate={this.props.installationDate}
											onHandleOptionChange={this.handleOptionChange}
											dealersActiveCampaigns={this.props.dealersActiveCampaigns}
											dealerProfile={this.props.dealerProfile}
											installType={this.props.installType}
											planOptionsData={this.state.planOptionsData}
											index={i}
										/>
									</FormGroup>
								</div>
								<hr className="cardSectionHR" />
								<h3 className="h5">Customer Retail Price</h3>
								<Row className="multiColWrapper">
									<Col md="4" xs="12" className="col-5ths mb-2 mb-md-0">
										<Label for="dealerPO" className="form-label">Dealer PO # </Label>
										<Input type="text" value={this.getDealerPo(assetPackage.assetPackageId)}
											className="form-control"
											id="dealerPO" placeholder="" onChange={(e) => this.handleDealerPo(e, assetPackage.assetPackageId)}
										/>
									</Col>
									<Col md="4" xs="12" className="col-5ths">
										<FormGroup className={
											((this.validator.message(
												'equipment Retail Price', assetPackage.equipmentRetailPrice, 'required'
											) &&
												this.state.validationFired &&
												this.state.errors.equipmentRetailPrice) ||
												assetPackage.equipmentRetailPrice && assetPackage.equipmentRetailPrice <= 0) &&
											"form-error"
										}
										>
											<div className="d-flex  align-items-center">
												<Label for="equipmentRetailPrice" className="form-label">Equipment Retail Price</Label>
												<span aria-hidden="true" className="required-indicator">*</span>
											</div>
											<div className={styles.retailPriceWrapper}>
												<span className={classNames(
													styles.preText,
													((this.validator.message(
														'equipment Retail Price', assetPackage.equipmentRetailPrice, 'required'
													) &&
														this.state.validationFired) ||
														assetPackage.equipmentRetailPrice && assetPackage.equipmentRetailPrice <= 0) ?
														styles.borderCol :
														""
												)}
												>{currency}</span>
												<Input
													type="number"
													value={this.getRetailePrice(assetPackage.assetPackageId)}
													className={classNames("form-control", preTextInput)}
													id="equipmentRetailPrice"
													placeholder=""
													onChange={(e) => this.handleRetailePrice(e, assetPackage.assetPackageId)}
												/>
												<div className={styles.retailPriceInfoIcon}>
													<InfoTooltip iconType="Info" message="Enter the retail amount the customer paid for all equipment that will be covered by this plan." />
												</div>
											</div>
											{this.state.validationFired && this.state.errors.equipmentRetailPrice &&
												this.validator.message('equipment Retail Price', assetPackage.equipmentRetailPrice, 'required')}
											{assetPackage.equipmentRetailPrice && assetPackage.equipmentRetailPrice <= 0 && <AmountError />}
										</FormGroup>
									</Col>
									{this.props.isCanadaState &&
										<Fragment>
											<Col md="4" xs="12" className="col-5ths">
												<FormGroup className={
													((this.validator.message(
														'plan Retail Price',
														assetPackage.planRetailPrice,
														'required'
													) &&
														this.state.validationFired) ||
														assetPackage.planRetailPrice && assetPackage.planRetailPrice <= 0) ?
														"form-error" :
														""
												}
												>
													<div className="d-flex  align-items-center">
														<Label for="planRetailPrice" className="form-label">Plan Retail Price</Label>
														<span aria-hidden="true" className="required-indicator">*</span>
													</div>
													<div className={styles.retailPriceWrapper}>
														<span className={classNames(
															styles.preText,
															((this.validator.message(
																'plan Retail Price',
																assetPackage.planRetailPrice,
																'required') &&
																this.state.validationFired) ||
																assetPackage.planRetailPrice && assetPackage.planRetailPrice <= 0) ?
																styles.borderCol :
																""
														)}
														>{currency}</span>
														<Input
															type="number"
															value={this.getPlanRetailePrice(assetPackage.assetPackageId)}
															className={classNames("form-control", preTextInput)}
															id="planRetailPrice"
															placeholder=""
															onChange={(e) => this.handlePlanRetailePrice(e, assetPackage.assetPackageId)} />
													</div>
													{this.state.validationFired && this.validator.message('plan Retail Price', assetPackage.planRetailPrice, 'required')}
													{assetPackage.planRetailPrice && assetPackage.planRetailPrice <= 0 && <AmountError />}
												</FormGroup>
											</Col>
											{this.state.stateTaxes.map(stateTax => (
												<Col md="4" xs="12" className="col-5ths">
													<FormGroup className={((
														!this.getTaxValue(stateTax, assetPackage.assetPackageId) &&
														this.state.validationFired
													) || (
															this.getTaxValue(stateTax, assetPackage.assetPackageId) &&
															this.getTaxValue(stateTax, assetPackage.assetPackageId) <= 0
														)) ?
														"form-error" :
														""
													}
													>
														<div className="d-flex  align-items-center">
															<Label for={'planRetailPrice' + stateTax} className="form-label">Plan Price {stateTax}</Label>
															<span aria-hidden="true" className="required-indicator">*</span>
														</div>
														<div className={styles.retailPriceWrapper}>
															<span className={classNames(
																styles.preText, ((
																	!this.getTaxValue(stateTax, assetPackage.assetPackageId) &&
																	this.state.validationFired
																) || (
																		this.getTaxValue(stateTax, assetPackage.assetPackageId) &&
																		this.getTaxValue(stateTax, assetPackage.assetPackageId) <= 0
																	)) ?
																styles.borderCol :
																"")}
															>
																{currency}
                              </span>
															<Input
																type="number"
																value={this.getTaxValue(stateTax, assetPackage.assetPackageId)}
																className={classNames("form-control", preTextInput)}
																id={'planRetailPrice' + stateTax}
																placeholder=""
																onChange={(e) => this.setTaxValue(e, stateTax, assetPackage.assetPackageId)} />
														</div>
														{(stateTax == "HST" && this.state.validationFired) && this.stateTaxErrors(stateTax, assetPackage.planRetailPriceHST)}
														{(stateTax == "GST" && this.state.validationFired) && this.stateTaxErrors(stateTax, assetPackage.planRetailPriceGST)}
														{(stateTax == "PST" && this.state.validationFired) && this.stateTaxErrors(stateTax, assetPackage.planRetailPricePST)}
														{(stateTax == "QST" && this.state.validationFired) && this.stateTaxErrors(stateTax, assetPackage.planRetailPriceQST)}
														{this.getTaxValue(stateTax, assetPackage.assetPackageId) && (this.getTaxValue(stateTax, assetPackage.assetPackageId) <= 0) && <AmountError />}
													</FormGroup>
												</Col>
											))}

											{/* <Col>
                                                <div className="d-flex  align-items-center">
                                                    <Label for="planRetailPriceGST" className="form-label">Plan Price GST</Label>
                                                    <span aria-hidden="true" className="required-indicator">*</span>
                                                </div>
                                                <div className={styles.retailPriceWrapper}>
                                                    <span className={styles.preText}>$</span>
                                                    <Input type="number" value={assetPackage.planRetailPriceGST} className={classNames("form-control", preTextInput)} id="planRetailPriceGST"
                                                        placeholder="" onChange={(e) => this.handlePlanRetaileGST(e, assetPackage.assetPackageId)} />
                                                    {this.validator.message('Plan Price GST', assetPackage.planRetailPriceGST, 'required')}
                                                </div>
                                            </Col><Col md="4" xs="12" className="col-5ths">
                                                <Label for="planRetailTax" className="form-label">Plan Retail Tax</Label>
                                                <span aria-hidden="true" className="required-indicator">*</span>
                                                <Input type="text" className="form-control" id="planRetailTax" value={assetPackage.planRetailTax}
                                                    placeholder="" onChange={(e) => this.handlePlanRetaileTax(e, assetPackage.assetPackageId)} />
                                                {this.validator.message('Plan Retail Tax', assetPackage.planRetailTax, 'required')}
                                            </Col>*/}
										</Fragment>
									}
								</Row>
							</CardBody>
						</Card>
					))}

				</Container>
			</div >
		);
	}
}
PlanDetails.contextType = OrderInfoContext