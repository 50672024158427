import React, { Component } from 'react';
import styles from "./PaymentMethod.module.css";
import {ReactComponent as VisaCC} from '../../../assets/images/cc-visa.svg';
import {ReactComponent as AmexCC} from '../../../assets/images/cc-amex.svg';
import {ReactComponent as MasterCC} from '../../../assets/images/cc-mastercard.svg';
import {ReactComponent as DiscoverCC} from '../../../assets/images/cc-discover.svg';

export class CreditCardImage extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
            { this.props.vendor == "visa" && <VisaCC className={styles.cardTypeVisa}/>}
            { this.props.vendor == "amex" && <AmexCC className={styles.cardTypeAmex}/>}
            { this.props.vendor == "mastercard" && <MasterCC className={styles.cardTypeMaster}/> }
            { this.props.vendor == "discover" && <DiscoverCC className={styles.cardTypeDiscover}/> }
            </>
        )
    }
}