import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HeaderNavWrapper } from '../components/Nav/HeaderNavWrapper';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <>
                <HeaderNavWrapper />
            </>
        );
    }
}
