import React, { Component, Fragment } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import classNames from "classnames";
import CalendarInput from '../shared/CalendarInput/CalendarInput';
import DropDownWrapper from '../shared/DropdownWrapper';
import moment from 'moment';
import { SessionContext } from "../../context/SessionContext";

const UFN = "Until Further Notice";

export class EditCampaignEndDate extends Component {

    constructor(props) {
        super(props);

        const { endDate = "" } = this.props.selectedRow;

        this.state = {
            createCampaign: false,
            startDate: "",
            endDate: endDate === UFN ? "" : endDate,
            modalOpen: false,
            tempDate: '',
            showCalendar: null,
            campaignEndDate: endDate === UFN || endDate == '' ? "first" : "second",
            showEndDate: endDate === UFN || endDate == '' ? false : true,
            wordCount: 0,
            showNotes: false,
            defaultReasonList: [],
            showReason: false,
            endDateCheck: this.props.selectedRow.endDate,
            hasActiveRow: false
        }
    }

    componentDidMount() {
        this.getDefaultReason();
    }

    disableDropDown = () => {
        
        console.log(this.props.tableData)
        const activeRow = this.props.tableData.find(x => x.isScheduled == true)
        if (typeof activeRow != 'undefined') {
            if (this.props.selectedRow.endDate == UFN) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }


    getDefaultReason = async () => {
        try {
            var route = `dealer/getdefaultendreason`;
            let resp = await fetch(route)
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        defaultReasonList: data.map(s => {
                            return {
                                data: s.campaignEndReasonId,
                                display: s.reason,
                            }
                        })
                    })
                })

        } catch (error) {
            console.error(error);
        }
    }

    handleStateDropDown = (value, name) => {


        if (value === 'second') {
            this.setState({ showEndDate: true, campaignEndDate: "second" })
            this.props.updateEditDateScreen("state", true)
        } else if (value === 'first') {
            this.setState({ showEndDate: false, campaignEndDate: "first" })
            this.props.updateEditDateScreen("state", false)
        }
        this.props.updateErrEditDateScreen("state", false)

        if (this.state.endDateCheck === 'Until Further Notice' || this.state.endDateCheck == '') {
            if (value === 'second') {
                this.setState({ showReason: true })
                this.props.updateShowReason(true)

            } else if (value === 'first') {
                this.setState({ showReason: false })
                this.props.updateShowReason(false)
            }
            this.props.updateErrEditDateScreen("reason", false)
            this.props.updateErrEditDateScreen("notes", false)
        }

    }

    handleReasonDropDown = (value) => {

        if (value == "4") {
            this.setState({ showNotes: true, wordCount: 0 })
        } else {
            this.setState({ showNotes: false })
        }
        this.props.updateEditDateScreen("reason", value)
        this.props.updateErrEditDateScreen("reason", false)
        this.props.updateErrEditDateScreen("notes", false)
    }


    handleOnChange(e) {
        const count = e.target.value;
        const wordCount = count.split("").length;

        if (wordCount > 0) {
            this.props.updateErrEditDateScreen("notes", false)
        }

        this.setState({ wordCount, nextSteps: e.target.value });
        this.props.updateEditNotes(count)
    };


    handleEndDateChange = (e) => {
        this.setState({ endDate: e })
        this.props.updateErrEditDateScreen("state", false)
        this.props.updateEditDateScreen("date", e)
    }

    handleCheck = (e) => {
        this.props.updateSalesCheck(e.target.checked)
    }

    dateFormat = () => {
        return this.props.isCanada == true ? "DD/MM/YYYY" : "MM/DD/YYYY"
    }

    possibleMaxEndDate = () => {

        const scheduledRow = this.props.tableData.find(x => x.status == "scheduled");
        if (typeof scheduledRow != "undefined") {
            const formatDate = this.props.isCanada == true ? moment(scheduledRow.beginDate, "DD/MM/YYYY") : moment(scheduledRow.beginDate, "MM/DD/YYYY");
            const retDate = moment(formatDate).subtract(1, "days").toDate();
            return retDate;
        } else {
            return null;
        }


    }

    render() {
        const { endDate, showCalendar, showEndDate, campaignEndDate } = this.state;
        console.log(campaignEndDate, showEndDate);

        var minDate = ""
        var momentDate = "";
        const dateNow = new Date()

        if (this.props.isCanada == true) {
            momentDate = moment(this.props.selectedRow.beginDate, "DD/MM/YYYY");
            minDate = moment(momentDate).add(30, "days").toDate();
        } else {
            momentDate = moment(this.props.selectedRow.beginDate, "MM/DD/YYYY");
            minDate = moment(momentDate).add(30, "days").toDate();
        }
        
        if (minDate < dateNow) {
            minDate = dateNow;
        }
        

        return (

            <Fragment>
                <p>{this.props.advantageName} sales will continue to be processed for all registrations that have an installation date prior to the campaign end date.
          Campaign end date must be 30 days after the begin date.
        </p>
                <label class="form-label" for="campaignEndDtOption">Campaign End Date</label><span aria-hidden="true" class="required-indicator">*</span>
                <Row className="twoColWrapper">
                    <Col md="5">
                        <FormGroup className="mb-0">
                            <DropDownWrapper
                                options={[{ data: 'first', display: UFN }, { data: 'second', display: 'Select a Date' }]}
                                className="form-control"
                                aria-required="true"
                                onChangedValue={this.handleStateDropDown}
                                onBlur={this.handleStateDropDown}
                                placeholder="Please select..."
                                value={campaignEndDate}
                                id="campaignEndDtOption"
                                defaultValue={'second'}
                                label=""
                                name="endCampaignOption"
                                isRequired
                                disabled={this.disableDropDown()}
                            />
                        </FormGroup>
                    </Col>
                    {showEndDate && (<Col md="5">
                        <FormGroup className={this.props.errEndDateEdit === true ? "form-error" : ""} >
                            <CalendarInput
                                onChange={this.handleEndDateChange}
                                minDate={minDate} value={endDate}
                                maxDate={this.possibleMaxEndDate()}
                                updateEditDateScreen={this.props.updateEditDateScreen}
                                id="campaignEndDate" placeHolder={this.dateFormat()}
                            />
                            {this.props.errEndDateEdit === true && <div className="errorMsg">Campaign end date is required.</div>}
                        </FormGroup>
                    </Col>)}
                </Row>
                {this.state.showReason &&
                    <Row className="twoColWrapper mb-0">
                        <Col md="5">
                            <FormGroup className={this.props.errReasonEdit === true ? "form-error" : ""} >
                                <DropDownWrapper
                                    options={this.state.defaultReasonList}
                                    onChangedValue={this.handleReasonDropDown}
                                    onBlur={this.handleDefaultDropDown}
                                    className="col-md-11"
                                    placeholder="Select One..."
                                    id="campaignEndDtOption"
                                    label="Reason for Ending Campaign"
                                    aria-required="true"
                                    isRequired
                                />
                            </FormGroup>
                            {this.props.errReasonEdit === true && <div className="errorMsg">Reason for ending campaign is required.</div>}
                        </Col>
                        <Col md="5"></Col>
                    </Row>}


                {this.state.showNotes &&
                    <FormGroup className={this.props.errNotesEdit === true ? "form-error" : ""} >
                        <Col lg="7" md="7" className="p-0" >
                            <Label for="endDateNotes" className="form-label">Additional Notes</Label>
                            <textarea className="form-control" id="endDateNotes" rows="5" maxlength="250" spellcheck="" onChange={(e) => this.handleOnChange(e)}></textarea>
                            <p className="maxLength">{this.state.wordCount}/250</p>
                        </Col>
                        {this.props.errNotesEdit === true && <div className="errorMsg">Additional notes are required.</div>}
                    </FormGroup>}

                <div className="checkbox mb-0">
                    <input className="mr-2" type="checkbox" id="stopSalesConfirm" onChange={this.handleCheck} checked={this.props.chkSalesEdit} />
                    <label for="stopSalesConfirm" className={classNames("checkboxLabel", this.context.accountName)}>
                        I want to stop sales processing on future registrations with install dates prior to the campaign end date.
                    </label>
                </div>

            </Fragment>
        );
    }
}

EditCampaignEndDate.contextType=SessionContext