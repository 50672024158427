import React from "react";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import styles from './Accordion.module.css';
import { ReactComponent as Chevron } from '../../../assets/images/arrow-cheveron-down.svg';

export default function Accordion({ title, bodyContent, open = false, onToggle = () => { }, id }) {

  return (
    <Card className={styles.accordionStyle}>
      <CardHeader className={styles.accordionHeader} onClick={() => onToggle(id)}>
        <div className={styles.accordionTitle} title="Opens an accordion" role="button">
          <div>
            {title}
          </div>
          <div className={open ? styles.active : styles.closed}>
            <Chevron />
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={open} role="region" className={styles.accordionBody}>
        <CardBody>
          {bodyContent}
        </CardBody>
      </Collapse>
    </Card>
  );
}