import React, { useState, useEffect } from "react";
import {  Col, Row  } from "reactstrap";
import classNames from 'classnames';
import styles from "./Resources.module.css";
import  pdfstyles  from "../../components/DealerTerms/DealerTerms.module.css";
import { Viewer, Worker} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfContainer = ({ pdf, hasScroll }) => {
    return (<Row className={classNames("multiColWrapper mt-3", styles.iframeShow)}>
                <Col>
                    <div className={styles.pdfDiv}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.js">
                            <div className={hasScroll && pdfstyles.pdfDoc}>
                                <Viewer fileUrl={pdf}
                                    plugins={[
                                        // defaultLayoutPluginInstance,
                                    ]}
                                />
                            </div>
                        </Worker>
                    </div>
                </Col>  
         </Row>)
}
export default PdfContainer;
