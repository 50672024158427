import React, { useState, useEffect } from "react";
import LoginForm from "./LoginForm";
import { Container, Col } from "reactstrap";
import classNames from "classnames";
import styles from "./DealerLogin.module.css";
import { ReactComponent as CloseIcon } from '../assets/images/close.svg';


const DealerLogin = () => {
    const [showLoginForm, setShowLoginForm] = useState(false);

    useEffect(() => {
        //componentDidMount
        document.getElementsByTagName('main')[0].classList.add('flexContainer');
        //componentWillUnMount
        return () => {
            document.getElementsByTagName('main')[0].classList.remove('flexContainer');
        }
    }, [])

    return (
        <div className={classNames(styles.dealerContainer, showLoginForm ? styles.dealerLogin : "")}>
            <Container className={classNames(styles.mainContainer, showLoginForm ? styles.mainContainerLogin : "")}>
                {showLoginForm && (
                    <div className={styles.dealerLoginFormContainer}>
                        <div
                            className={styles.closeIconContainer}
                            onClick={() => { setShowLoginForm(false) }}>
                            <CloseIcon className={styles.closeIcon} />
                        </div>
                        <div className={styles.dealerLoginForm}>
                            <LoginForm />
                        </div>
                    </div>
                )}
                <div className={showLoginForm ? styles.dealerText : `mt-5 ${styles.dealerButtonScreen}`}>
                    <Col className={classNames(showLoginForm ? "col-lg-8 col-md-11" : "col-lg-6 col-md-6 col-sm-12 col-xs-12")}>
                        {!showLoginForm && (
                            <button
                                className={classNames("btn-secondary", "pl-5", "pr-5", "pt-2", "pb-2", "mb-5")}
                                onClick={() => { setShowLoginForm(true) }}
                            >
                                Dealer Login
                            </button>
                        )}
                        <h1 className="mt-3">
                            We Recommend Adding Labor Protection to Your Product
                        </h1>
                        <p className="mb-4">
                            Your manufacturer’s warranty covers parts, but does
                            not cover the cost of labor for repairs. Our Labor
                            Protection Plans help you avoid out-of-pocket
                            expenses on any covered repairs.
                        </p>
                        <h2 className="h5">Contact your installing dealer today!</h2>
                    </Col>
                </div>
            </Container>
        </div>
    );
};

export { DealerLogin };
