import create from 'zustand'
export const useClaimStoreForm = create(set => ({
    invoiceNumber: '',
    setInvoiceNumber: (invoiceNumber) => set({ invoiceNumber }),
    invoiceDate: '',
    setInvoiceDate: (invoiceDate) => set({ invoiceDate }),
    failureDate: '',
    setFailureDate: (failureDate) => set({ failureDate }),
    failureDateCheck: '',
    setFailureDateCheck: (failureDateCheck) => set({ failureDateCheck }),
    serviceCompletionDate: null,
    setServiceCompletionDate: (serviceCompletionDate) => set({ serviceCompletionDate }),
    onsiteTextAreaValue: '',
    setOnsiteTextAreaValue: (onsiteTextAreaValue) => set({ onsiteTextAreaValue }),
    resolutionTextAreaValue: '',
    setResolutionTextAreaValue: (resolutionTextAreaValue) => set({ resolutionTextAreaValue }),
    additionalRequestedRates: '',
    setAdditionalRequestedRates: (additionalRequestedRates) => set({ additionalRequestedRates }),
}))