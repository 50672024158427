import React from 'react';
import styles from './CustomerBenefits.module.css';
import { Row, Col, Container } from 'reactstrap';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/Vendor/ICP/benefits-checkmark.svg';

const CustomerBenefits = () => {

    const serviceBenefits = () => {
        const serviceInfo = [
            'Eliminates Unexpected Repair Costs',
            'Service by Installing Dealer',
            'Transferable at No Cost',
            'No deductibles or service fees'
        ]
        return (
            serviceInfo.map((benefit, index) => {
                return <div key={index}>
                    <div><CheckmarkIcon /></div>
                    <div>{benefit}</div>
                </div>
            })
        );
    };

    return (
        <Container fluid>
            <Row className={styles.customerBenefitsContainer}>
                <Col sm='12' md='12' lg='6' className='p-0'>
                    <div className={styles.vendorLoginICP}></div>
                </Col>
                <Col sm='12' md='12' lg='6' className='p-0'>
                    <div className={styles.benefitsContainer}>
                        <div className={styles.colorBar}></div>
                        <h1>Customer Benefits</h1>
                        <p>
                            A Comfort Promise ESA protects your customer from expensive labor repair costs and
                            provides them professionally-serviced equipment by the installing dealer they trust - you!
                        </p>
                        <div className={styles.serviceBenefits}>
                            {serviceBenefits()}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default CustomerBenefits;