import React, { useReducer, createContext } from 'react';
import { initialState } from './state';
import reducer from './reducer'

const AdjudicationContext = createContext(null);

const AdjudicationContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <AdjudicationContext.Provider value={{ state, dispatch }}>
            {children}
        </AdjudicationContext.Provider>
    )
}

export { AdjudicationContextProvider, AdjudicationContext }