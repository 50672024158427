import React, { Component, Fragment } from "react";
import { FormGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Form } from "reactstrap";
import { DropDownAutoComplete } from '../../inputs/DropDownAutoComplete';
import styles from "./ContactUsModal.module.css";
import classNames from "classnames";
import { ContactUsConfirmation } from "../../../Screens/ContactUs/ContactUsConfirmation";
import Cleave from 'cleave.js/react';
import { ContactUsInfo } from "../../../Screens/ContactUs/ContactUsInfo";
import { stripPhoneNumber, phoneFormat } from '../../../components/shared/util';
import { brandContent } from '../../../components/BrandingWhiteLabel/BrandContent';

export class ContactUsModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            wordCount: 0,
            contactUsConfirmed: false,
            isOpen: this.props.isOpen,
            validationFired: false,
            helpOption: 0,
            dealerName: '',
            dealerId: '',
            dealerPhoneNum: '',
            dealerEmailAdd: '',
            dealerComments: '',
            notes: '',
            accountName: 'aig',
            itemValue: 0,
        }
    }

    handleComments = (e) => {
        const count = e.target.value;
        const wordCount = count.split("").length;
        this.setState({ wordCount });
        this.setState({ notes: e.target.value });
    }

    toggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    updateContactInfo = (id, value) => {
        this.setState({ [id]: value });

        if (id === "dealerId") {
            fetch(`dealer/GetProfileByClientDealerId/${value}`)
                .then(res => res.json())
                .then(data => {
                    this.setState({ itemValue : data.partyId });
                })
        }
    }

    handleSubmit = () => {
        this.setState({ validationFired: true });
    }

    handleSave = (helpOption) => {
        let model = this.state;
        let contactUsConfirmed = false;

        model.helpOption = model.helpOption == 0 ? helpOption : model.helpOption;

        try {
            fetch('queue/savequeueitem/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    AgentQueueId: 33,
                    Name: model.dealerName,
                    ItemValue: model.itemValue,
                    EnqueueReasonId: model.helpOption,
                    Email: model.dealerEmailAdd,
                    Phone: model.dealerPhoneNum,
                    Note: model.dealerComments,
                    ContractPreference: 1
                })
            }).then(response => {
                if (response.status === 200) {
                    contactUsConfirmed = true;
                }

                this.setState({ contactUsConfirmed: contactUsConfirmed });
            });
        }
        catch (error) {
            this.setState({contactUsConfirmed: false });
        }
    }

    getAccountName = () => {
        fetch('helper/getaccountbyurl')
            .then(res => res.json())
            .then(data => {
                this.setState({ accountName: data.accountName });
            })
    }

    componentDidMount = () => {
        this.getAccountName();
    }

    render() {
        const { accountName, contactUsConfirmed } = this.state;
        const { toggle, centered } = this.props;
        const { isOpen } = this.state

        return (
            <Modal isOpen={isOpen}
                toggle={toggle}
                className="modal-md"
                centered={centered}>
                {!contactUsConfirmed && <ModalHeader>
                    <h4 className="modal-title">Contact Us</h4>
                </ModalHeader>}
                <ModalBody>
                    {!contactUsConfirmed ?
                        <Fragment>
                            <p className={styles.cardDesc}>Email us and we will respond to all inquiries within 1 business day. For a quicker response, you can reach our support team by calling <a href={`tel:${stripPhoneNumber(brandContent[accountName.toLowerCase()].programPhone)}`} className="callLink">{(brandContent[accountName.toLowerCase()].programPhone)}</a>.</p>
                            <Form className={styles.contactUsform}>
                                <Col md="7" className="p-0">
                                    <ContactUsInfo
                                        updateContactInfo={ this.updateContactInfo }
                                        validationFired={this.state.validationFired}
                                        handleSave={ this.handleSave }
                                        isModal={true}
                                    />
                                </Col>
                            </Form>
                        </Fragment> : <Fragment className="pb-5"><ContactUsConfirmation /></Fragment>}
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-end">
                        {!contactUsConfirmed ?
                            <div className="btnWrapper">
                                <button className="btnLink" onClick={() => { this.toggleModal() }}>Cancel</button>
                                <button className="btn-primary" onClick={() => this.handleSubmit() }>Submit</button>
                            </div> :
                            <button className="btnLink" onClick={() => { this.toggleModal() }}>Close</button>}
                    </div>
                </ModalFooter>
            </Modal>);
    }
}

export default ContactUsModal;