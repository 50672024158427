import React from "react";
import styles from './SnackBar.module.css';

const SnackBar = (
  {
    numOfNotifications,
    showSnackBar = false,
    onClickView = () => { }
  }) => {

  if (showSnackBar) {
    return (
      <div className={styles.Snackbar}>
        <div>You have {numOfNotifications} new notifications.</div>
        <button className={styles.viewNotification} onClick={onClickView}>View Now</button>
      </div>
    );
  }
  return null;
}
export default SnackBar