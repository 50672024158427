import React, { Component, Fragment } from "react";
import { Card, CardBody, FormGroup, Container, Col } from "reactstrap";
import Cleave from 'cleave.js/react';
import { DropDownAutoComplete } from '../../components/inputs/DropDownAutoComplete';
import styles from "./ContactUs.module.css";
import { ContactUsConfirmation } from './ContactUsConfirmation';
import SimpleReactValidator from 'simple-react-validator';
import { SessionContext } from '../../context/SessionContext';
import { dataLayerPush } from '../../components/Global/helpers';
import ClientPhone from "../../components/shared/ClientPhone/ClientPhone";
import classNames from "classnames";

export class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wordCount: 0,
            contactUsConfirmed: false,
            reasonOptions: [{ data: 0, queueReasonCategory: 0, display: '' }],
            reason: 0,
            defaultReason: '',
            dealerProfile: {},
            dealerName: '',
            dealerId: '',
            clientDealerId: '',
            partyId: 0,
            dealerPhoneNum: '',
            dealerEmailAdd: '',
            notes: '',
            errorSaveUnsuccessful: false,
            isLennoxDealer: true,
            showValidationErrors: false,
            legacyDealerId: ''
        }
        this.handleQueueDropDown = this.handleQueueDropDown.bind(this);
        this.save = this.save.bind(this);
        this.validator = new SimpleReactValidator({
            element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1).replace(" val2", "")}</div>,
            messages: {
                phone: 'Please enter a valid phone number.',
                required: ':attribute is required.',
                min: ':attribute is required.'
            }
        });
    }

    camalize = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w|)/g, function (match, index) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }

    componentDidMount() {
        const { updateTab } = this.context;
        
        dataLayerPush('Pageview', {
            pageTitle: "Contact",
            pagePath: "/contact"
        })
        try {
            var dealerProfile = this.context.getProfile();
            if (dealerProfile != null) {
                this.setState({
                    dealerProfile: dealerProfile,
                    dealerId: dealerProfile.dealerId,
                    isLennoxDealer: dealerProfile.isLennoxDealer,
                    clientDealerId: dealerProfile.clientDealerId,
                    legacyDealerId: dealerProfile.legacyDealerId
                });
                if (dealerProfile.contacts.filter(c => c.isPrimary == true)[0] != undefined) {
                    this.setState({
                        dealerPhoneNum: dealerProfile.contacts.filter(c => c.isPrimary == true)[0].phoneNumber,
                        dealerEmailAdd: dealerProfile.contacts.filter(c => c.isPrimary == true)[0].email
                    });
                    this.formatTelephone("dealerPhoneNum", dealerProfile.contacts.filter(c => c.isPrimary == true)[0].phoneNumber);
                }
            }
            this.pullQueueOptions();
        } catch (error) {
            //console.error(error);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { updateTab, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        
        if(this.props.state === undefined) {
            updateTab({
                tabId: this.props.tabId,
                tabType: "contactus",
                state: this.state
            })
        }
    }

    pullQueueOptions = async => {
        try {
            fetch('queue/queueReasons/')
                .then(res => res.json())
                .then(data => {
                    var contactUsReasons = data.map(s => {
                        return {
                            data: s.queueReasonId,
                            display: s.reason,
                            queueReasonCategoryId: s.queueReasonCategoryId
                        }
                    }).sort((a, b) => {
                        if (a.data < b.data) { return -1; }
                        if (a.data > b.data) { return 1; }
                        return 0;
                    }).filter((a) => a.queueReasonCategoryId === 2);

                    this.setState({
                        reasonOptions: contactUsReasons
                    });
                    this.forceUpdate();
                });
        }
        catch (error) {
        }
    }

    handleQueueDropDown = e => {
        const { updateTab, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        updateTab({
            tabId: this.props.tabId,
            tabType: "contactus",
            state: {
                ...tab.state,
                reason: e
            }
        });

        this.setState({ reason: e });
    };

    handlePhoneChange = e => {
        const { id, value, rawValue } = e.target;

        const temp = this.formatTelephone("dealerPhoneNum", value);

        const { updateTab, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        updateTab({
            tabId: this.props.tabId,
            tabType: "contactus",
            state: {
                ...tab.state,
                dealerPhoneNum: temp
            }
        });

        this.setState(prevState => ({
            id: prevState.dealerPhoneNum
        }));
    }

    handleEmailChange = e => {
        const { id, value } = e.target;

        let temp = this.formatEmail("dealerEmailAdd", value);

        const { updateTab, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        updateTab({
            tabId: this.props.tabId,
            tabType: "contactus",
            state: {
                ...tab.state,
                dealerEmailAdd: temp
            }
        });

        this.setState(prevState => ({
            dealerEmailAdd: prevState.dealerEmailAdd
        }));
    }

    handleInputChange = (e) => {
        const { id, value } = e.target;
        document.getElementById(id).Value = value;

        const { updateTab, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        updateTab({
            tabId: this.props.tabId,
            tabType: "contactus",
            state: {
                ...tab.state,
                dealerName: value
            }
        });

        this.setState({
            dealerName: value
        });
    };

    formatTelephone = (field, text) => {

        const mobile_in = text.replace(/\D/g, '').substring(0, 10);

        let mobile_out = mobile_in;

        const zip = mobile_in.substring(0, 3);
        const middle = mobile_in.substring(3, 6);
        const last = mobile_in.substring(6, 10);

        if (mobile_in.length > 6) { mobile_out = `(${zip}) ${middle}-${last}`; }
        else if (mobile_in.length > 3) { mobile_out = `(${zip}) ${middle}`; }
        else if (mobile_in.length > 0) { mobile_out = `(${zip}`; }

        if (field === 'dealerPhoneNum') {
            this.setState({ dealerPhoneNum: mobile_out });
        }

        return mobile_out;
    };

    formatEmail = (field, text) => {

        const mobile_in = text;

        let mobile_out = mobile_in;

        if (field === 'dealerEmailAdd') {
            this.setState({ dealerEmailAdd: mobile_out });
        }
        return mobile_out;
    };

    handleComments = (e) => {
        const count = e.target.value;
        const wordCount = count.split("").length;

        const { updateTab, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        updateTab({
            tabId: this.props.tabId,
            tabType: "contactus",
            state: {
                ...tab.state,
                wordCount: wordCount,
                notes: e.target.value
            }
        });

        this.setState({ wordCount });
        this.setState({ notes: e.target.value });
    }

    save = async e => {
        const { getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        var route = `queue/savequeueitem/`;
        var model = tab.state;
        this.setState({ showValidationErrors: false });
        if (this.validator.allValid()) {
            //if no reason selected default to Other reason
            if (model.reason == 0) {
                model.reason = tab.state.reasonOptions.filter(c => c.display == "Other")[0].data;
            }
            try {
                fetch(route, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        AgentQueueId: 33,
                        ItemValue: model.dealerProfile.partyId,
                        Name: model.dealerName,
                        EnqueueReasonId: model.reason,
                        Email: model.dealerEmailAdd,
                        Phone: model.dealerPhoneNum,
                        Note: model.notes,
                        ContractPreference: 1
                    })
                }).then(response => {
                    if (response.status === 200) {
                        response.json().then(data => {
                            //console.log(data);
                        });
                        this.setState({ contactUsConfirmed: true });
                    } else {
                        this.setState({
                            errorSaveUnsuccessful: true,
                            contactUsConfirmed: false
                        });
                    }
                });
            }
            catch (error) {
                this.setState({
                    errorSaveUnsuccessful: true,
                    contactUsConfirmed: false
                });
            }
        }
        else {
            this.setState({ showValidationErrors: true });
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

render() {
    const { contactUsConfirmed } = this.state;
    const dealerProfile = this.context.getProfile();
    const { getCurrentTabAndSubtab } = this.context;
    const { tab } = getCurrentTabAndSubtab();
    let reasonOptions = this.state.reasonOptions;
    return (
        <SessionContext.Consumer>
            {session =>
                <Container className="contentWrapper">
                    {!contactUsConfirmed && <h2 className={styles.contactUsHeading}>Contact Us</h2>}
                    <Card>
                        <CardBody>
                            {!contactUsConfirmed ? (
                                <Fragment>
                                    <p className={styles.cardDesc}>
                                        Contact us and we will respond to all inquiries within 1 business day. For a quicker response, 
                                        you can reach our support team by calling <br className={"d-none d-xl-block"}/>
                                        <ClientPhone/>.
                                    </p>
                                    <Col md="4" className="p-0">
                                        <FormGroup>
                                            <label htmlFor="helpingOptions" className="form-label">How can we help?</label>
                                            <DropDownAutoComplete
                                                name="reasonOptions"
                                                id="helpingOptions"
                                                className="form-control"
                                                placeholder="Please select..."
                                                options={reasonOptions}
                                                onChangedValue={this.handleQueueDropDown}
                                                onBlur={this.handleQueueDropDown}
                                                value={this.props.state === undefined ? this.state.defaultReason : tab.state.reason}
                                                defaultValue={this.props.state === undefined ? this.state.defaultReason : tab.state.reason}
                                            />
                                        </FormGroup>
                                        <FormGroup className={!this.validator.check(tab?.state?.dealerName, "required") && this.state.showValidationErrors ? "form-error" : ""}>
                                            <label htmlFor="dealerName" className="form-label">Name</label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <input 
                                                className="form-control" 
                                                id="dealerName" 
                                                type="text" 
                                                aria-required="true" 
                                                aria-labelledby="Name" 
                                                name="name"
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputChange}
                                                maxLength={150}
                                                value={tab?.state?.dealerName}
                                            />
                                            {this.validator.message("Name", tab?.state?.dealerName, "required")}
                                        </FormGroup>
                                        <FormGroup>
                                            <label htmlFor="dealerId" className="form-label">Dealer ID</label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <input 
                                                className="form-control" 
                                                id="dealerId" 
                                                disabled="disabled" 
                                                type="text" 
                                                aria-required="true" 
                                                aria-labelledby="DealerID" 
                                                name="dealerId"
                                                value={dealerProfile.isLennoxDealer ? dealerProfile.clientDealerId : dealerProfile.legacyDealerId} 
                                            />
                                        </FormGroup>
                                        <FormGroup className={!this.validator.check(tab?.state?.dealerPhoneNum, "required|phone") && this.state.showValidationErrors ? "form-error" : ""}>
                                            <label htmlFor="dealerPhoneNum" className="form-label">Phone Number</label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <Cleave 
                                                options={{
                                                blocks: [0, 3, 3, 4],
                                                delimiters: ['(', ') ', '-'], 
                                                numericOnly: true
                                                }} 
                                                className="form-control" 
                                                id="dealerPhoneNum"
                                                name="dealerPhoneNumber" 
                                                value={this.state.dealerPhoneNum} 
                                                onChange={this.handlePhoneChange}
                                                onBlur={this.handlePhoneChange}
                                                aria-required="true" 
                                            />
                                            {this.validator.message("Phone Number", tab?.state?.dealerPhoneNum, "required|phone")}
                                        </FormGroup>
                                        <FormGroup className={!this.validator.check(tab?.state?.dealerEmailAdd, "required|email") && this.state.showValidationErrors ? "form-error" : ""}>
                                            <label htmlFor="dealerEmailAdd" className="form-label">Email Address</label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <input 
                                                className="form-control" 
                                                id="dealerEmailAdd" 
                                                type="email" 
                                                aria-required="true" 
                                                aria-labelledby="DealerEmailAddress" 
                                                name="dealerEmailAddress"
                                                onChange={this.handleEmailChange}
                                                onBlur={this.handleEmailChange}
                                                value={tab?.state?.dealerEmailAdd}
                                                maxLength={150}
                                            />
                                            {this.validator.message("Email Address", tab?.state?.dealerEmailAdd, "required|email")}
                                        </FormGroup>
                                        <FormGroup className={!this.validator.check(tab?.state?.notes, "required") && this.state.showValidationErrors ? "form-error mb-0" : "mb-0"}>
                                            <label htmlFor="dealerComments" className="form-label">Comment</label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <textarea 
                                                className="form-control" 
                                                id="dealerComments" 
                                                rows="5" 
                                                maxLength="750" 
                                                aria-required="true" 
                                                aria-labelledby="DealerComments"
                                                onChange={this.handleComments}
                                                value={tab?.state?.notes}
                                            />
                                            <div className="maxLength">
                                                {tab?.state?.wordCount}/750
                                            </div>
                                            {this.validator.message("Comment", tab?.state?.notes, "required") && 
                                                <span className={classNames("errorMsg", styles.commentArea)}> 
                                                    Comment is required. 
                                                </span>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Fragment> 
                            ) : (
                                <ContactUsConfirmation />
                            )}
                        </CardBody>
                    </Card>
                    <div className="d-flex justify-content-end">
                        {!contactUsConfirmed && <button className="btn-primary" onClick={this.save}>Submit</button>}
                    </div>
                </Container>
            }
            </SessionContext.Consumer>
        );
    }
}
ContactUs.contextType = SessionContext;