import React from 'react'
import styles from "./Table.module.css";
import { useClaimInvoiceStore } from "../lib/state";
import classNames from 'classnames';

const addVisualText = {
    labor: 'Additional Repair',
    partsMarkup: 'Part',
    partsmarkup: 'Part',
    parts: 'Part',
    trip: 'Additional Trip',
    laborSelectRate: 'Additional Repair',
    refrigerant: 'Reclaim'
}

export const AddRowButton = ({ tableType, invoiceId, contract, hideRow = false }) => {
    const [addTableRow, getTableData, currentInvoice] = useClaimInvoiceStore((state) => {
        return [state.addTableRow, state.getTableData, state.currentInvoice]
    });

    const data = getTableData(invoiceId, tableType);

    const handleAddRow = (e) => {
        e.preventDefault();
        addTableRow(invoiceId, tableType, data, contract);
    };
    return (
        !hideRow && (
            <button
                className={classNames(
                    styles.addRowBtn,
                    (data?.length > 4 && tableType === "trip")
                        ? styles.disableBtn
                        : data?.length > 24
                            ? styles.disableBtn
                            : "",
                )}
                onClick={handleAddRow}
            >
                + {addVisualText[tableType] ?? "Row"}
            </button>
        )
    );
};
