import React, { Component } from 'react';
import { PlanSearch } from '../components/PlanSearch/PlanSearch'


    
export class ContractSearch extends Component {

    render() {
        return (
            <PlanSearch history={this.props.history}/>
        );
    }

}