import React from 'react';
import { Alert } from 'reactstrap';
import styles from './AIGInverseAlert.module.css';

export function AIGInverseAlert({ color, errorCode, reason, authorityAlert }) {
    return (
        <Alert color={color} className={authorityAlert ? styles.customAuthAlert : styles.customAlert}>
            {reason}
        </Alert>
    )
}