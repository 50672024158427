import React, { useEffect } from "react";
import styles from './SnackBarAlert.module.css';
import { ReactComponent as CloseIcon } from '../../../assets/images/close_med.svg';

const SnackBarAlert = ({
  showSnackBar = false,
  onClose,                 
  background = "#13AA41",
  color = "#001871",
  content = "",
  hasClose = false
}) => {

  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, 6000)
  }, [showSnackBar])

  if (showSnackBar) {
    return (
      <div className={styles.Snackbar} style={{ background, color }}>
        <div className="mr-4">{content}</div>
        {hasClose &&
          <div
            className={styles.closeBanner}
            onClick={onClose}
          >
            <CloseIcon style={{ fill: color, stroke: color }} />
          </div>
        }
      </div>
    );
  }
  return null;
}
export default SnackBarAlert;