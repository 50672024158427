import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Col } from "reactstrap";
import styles from "./RFSForm.module.css";
import classNames from "classnames";
import NotesField from "../shared/NotesField/NotesField";
import { DropDownAutoComplete } from "../inputs/DropDownAutoComplete";

export const RFSAcceptActionModal = ({
    isOpen,
    toggle,
    handleReasonChange,
    handlerejectSubmit,
    reasonSelected,
    isValidationFired,reasonOptions
    , handleNotesChange,notes,validator

}) => {
    
    const [error, setError] = useState(false);
  
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-md">
            <ModalHeader>
                <h4 className={classNames("mt-2 mb-2 modal-title")}>Reject Service Request</h4>
            </ModalHeader>
            <ModalBody>
                <Col md="7" id="reasonWrapper" className={styles.reasonWrapper}>
                    <FormGroup
                        className={` ${isValidationFired && reasonSelected === "" ? "form-error" : ""}`}
                    >
                        <label for="reason" className="form-label">Reason</label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <DropDownAutoComplete
                            id="reason"
                            className="form-control"
                            aria-required="true"
                            name="reason"
                            value={reasonSelected}
                            options={reasonOptions}
                            placeholder="please select"
                            onChangedValue={handleReasonChange}
                            defaultValue={reasonSelected}>
                        </DropDownAutoComplete>
                        {validator.message("reason", reasonSelected, "required")}
                    </FormGroup>
                </Col>
                <div className="mt-3">
                    <NotesField
                        onChange={handleNotesChange}
                        value={notes} >
                    </NotesField>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="btnWrapper">
                    <button className="btnLink" onClick={toggle}>
                        Cancel
                    </button>
                    <button className={classNames("btn-primary ml-2")}
                        onClick={handlerejectSubmit}>Submit</button>
                </div>

            </ModalFooter>
        </Modal>
    )
}
