import React, { useState, useContext, useRef, useEffect } from 'react';
import styles from './ExpandableTextarea.module.css';
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';

const ExpandableTextarea = ({ placeholder, textValue, onTextValueChange, maxChars, id }) => {
  const [text, setText] = useState("");
  return (
    <div className={styles.expandableTextarea}>
      <TextareaAutosize id={id} className="form-control" onChange={onTextValueChange} maxLength={maxChars} value={textValue} placeholder={placeholder || "Enter Description"} />
      <div className={styles.charCount}>
        {textValue ? textValue.length : 0}/{maxChars}
      </div>
      {/* {textValue.length > 0 && <span type="button" onClick={() => setText('')} className={styles.emptyTextArea}><CloseIcon /></span>}*/}
    </div>
  );
}

export default ExpandableTextarea;