import React, { useContext } from "react";
import { Container, Card, CardBody, Col } from "reactstrap";
import styles from "./InvoiceConfirmation.module.css";
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { SessionContext } from '../../../src/context/SessionContext';
import { getBrandContent } from '../BrandingWhiteLabel/BrandContent';

export const ClaimApproved = (props) => {
    const history = useHistory();
    const accountName = typeof props.accountName == "undefined" || props.accountName == null ? '' : props.accountName;
    const claimNumber = typeof props.claimNumber == "undefined" || props.claimNumber == null ? '' : props.claimNumber;
    const { tabDispatch, getCurrentTabAndSubtab } = useContext(SessionContext)
    const { invoiceId } = getCurrentTabAndSubtab().subtab;

    const getMailId = () => {
        return getBrandContent(accountName.toLowerCase()).programEmail;
    }
    
    const handleClaimClick = () => {
        history.push('/', {})
        tabDispatch('ADD_SUBTAB', {
            subtabType: 'staticclaiminvoice',
            invoiceId: invoiceId,
            claimNumber: claimNumber
        })
    };

    const getPhoneNumber = () => {
        return getBrandContent(accountName.toLowerCase()).programPhone;
    }
    return (
        <Container className={"contentWrapper"}>
            <Card>
                <CardBody>
                    <div className={classNames(styles.thanksConfirmation, styles.reviewConfirmation )}>
                        <BrandedIcon accountName={accountName} iconType={'approved'} />
                    </div>
                    <Col md="10" lg="8" className={styles.invMessage}>
                        <h1 className="h3">Claim Approved</h1>
                        <p>
                            Thank you for submitting a claim. You will receive payment within 30 business
                            days via the payment method on file.
                        </p>
                        <p>
                            If you'd like to speak with one of our representatives, call {getPhoneNumber()} or 
                            email {getMailId()}.
                        </p>
                        <p>Claim Number: {claimNumber}</p>
                        <div>
                            <a className={classNames(styles.redirectLink, "btnLink")} onClick={handleClaimClick}>
                                View Claim Details
                            </a>
                        </div>
                    </Col>
                </CardBody>
            </Card>
        </Container>
    );
}
