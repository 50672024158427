import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { Row, Col, Input, FormGroup } from "reactstrap";
import classNames from "classnames";
import styles from "./Login.module.css";
import { ReactComponent as CheckIcon } from '../assets/images/checkmark.svg';
import SimpleReactValidator from 'simple-react-validator';
import { SessionContext } from '../context/SessionContext';
import SpinnerButton from '../components/shared/SpinnerButton/SpinnerButton';

let loginContainer = styles.loginContainer;

const ChangePassword = (props) => {
  const [formData, setFormData] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: ''
  });
  const [validationCheck, setValidationCheck] = useState(false);
  const [passLengthCheck, setPassLengthCheck] = useState(false);
  const [passNumberCheck, setPassNumberCheck] = useState(false);
  const [passCharCheck, setPassCharCheck] = useState(false);
  const [passCaseCheck, setPassCaseCheck] = useState(false);
  const [newPassCheck, setNewPassCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [, forceUpdate] = useState();
  const [currentPassError, setCurrentPassError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  var sessionContext = useContext(SessionContext);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        required: ':attribute is required.',
        in: 'Passwords do not match.',
        min: 'Password length should be minimum 8 characters.'
      },
      element: (message) => (
        <div
          className="errorMsg"
          aria-live="polite"
        >
          {message.charAt(0).toUpperCase() + message.slice(1)}
        </div>
      ),
      validators: {
        valid_pass: {
          message: 'The :attribute is invalid.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/) && params.indexOf(val) === -1
          },
          required: true
        }
      }
    })
  );

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === "currentPassword") {
      setCurrentPassError(false);
    }

    if (name === "password") {
      setPassLengthCheck((value.length >= 8));
      setPassNumberCheck(((/\d/).test(value)));
      setPassCharCheck(((/\W/).test(value)));
      setPassCaseCheck(((/^(?=.*[a-z])/).test(value) && (/^(?=.*[A-Z])/).test(value)));
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setNewPassCheck(validationChecker(formData.password));
  }, [formData])

  const validationChecker = (value) => (
    (value.length >= 8)
    && (/\d/).test(value)
    && (/\W/).test(value)
    && (/^(?=.*[a-z])/).test(value)
    && (/^(?=.*[A-Z])/).test(value)
  );

  const handleChangePass = async (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setValidationCheck(false);
      setIsSubmitting(true);
      const success = await changePassword()

      if (success == true) {
        handleCancel();
      } else {
        setValidationCheck(true);
        simpleValidator.current.showMessages();
        forceUpdate(1);
      }
      setIsSubmitting(false);

    } else {
      setValidationCheck(true);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const changePassword = async () => {
    var model = {
      "DealerId": props.dealerId,
      "ClientDealerId": props.clientDealerId,
      "OldPassword": formData.currentPassword,
      "NewPassword": formData.confirmPassword
    };

    var response = await fetch("dealer/dealerchangepassword/", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    });

    if (response.ok) {
      return true
    } else {
      if (response.status == 401) {
        setCurrentPassError(true);
      }
    }
    return false;
  }

  const handleCancel = (e) => {
    formData.currentPassword = "";
    formData.password = "";
    formData.confirmPassword = "";
    props.setPasswordScreen();
  }

  return (
    <>
      <div className={styles.companyInfoHead}>
        <h2 className="h5">Password</h2>
      </div>
      <Row className="multiColWrapper">
        <Col xs="12" md="3" className="col-5ths">
          <FormGroup className={(currentPassError || simpleValidator.current.message("currentPassword", formData.currentPassword, "required")) ? "form-error" : ""}>
            <label for="currentPassword" className="form-label">Current Password</label>
            <span aria-hidden="true" className="required-indicator">*</span>
            <Input name="currentPassword" type="password" className="form-control" defaultValue={formData.currentPassword} onChange={handleInputChange} />
            {simpleValidator.current.message("currentPassword", formData.currentPassword, "required")}
            {currentPassError && !simpleValidator.current.message("currentPassword", formData.currentPassword, "required") &&
              <div className="errorMsg">Incorrect Password</div>
            }
          </FormGroup>
        </Col>
        <Col xs="12" md="3" className="col-5ths">
          <FormGroup className={!simpleValidator.current.check(formData.password, "required|min:8|valid_pass") && validationCheck && "form-error"}>
            <label for="password" className="form-label">New Password</label>
            <span aria-hidden="true" className="required-indicator">*</span>
            <Input name="password" type="password" className="form-control" defaultValue={formData.password} onChange={handleInputChange} />
            {simpleValidator.current.message("password", formData.password, "required|min:8|valid_pass")}
          </FormGroup>
        </Col>
        <Col xs="12" md="3" className="col-5ths">
          <FormGroup className={!simpleValidator.current.check(formData.confirmPassword, `required|in:${formData.password}`) && validationCheck && "form-error"}>
            <label for="confirmPassword" className="form-label">Retype New Password</label>
            <span aria-hidden="true" className="required-indicator">*</span>
            <Input name="confirmPassword" type="password" className="form-control" defaultValue={formData.confirmPassword} onChange={handleInputChange} />
            {simpleValidator.current.message("Retype new password", formData.confirmPassword, `required|in:${formData.password}`, { messages: { required: "Retype new password is required." } })}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4" className={classNames("pt-3", "pb-3")}>
          <div className={classNames("mb-3", styles.confirmPassHeadText, styles.brandColor)}>Password Requirements</div>
          <div className={classNames("mb-1", styles.confirmPassCheckContainer)}>
            {passLengthCheck &&
              <div className={styles.confirmPassCheck}>
                <CheckIcon />
              </div>
            }
            <div>At least 8 characters long</div>
          </div>
          <div className={classNames("mb-1", styles.confirmPassCheckContainer)}>
            {passNumberCheck &&
              <div className={styles.confirmPassCheck}>
                <CheckIcon />
              </div>
            }
            <div>Include one number</div>
          </div>
          <div className={classNames("mb-1", styles.confirmPassCheckContainer)}>
            {passCharCheck &&
              <div className={styles.confirmPassCheck}>
                <CheckIcon />
              </div>
            }
            <div>Include one special character</div>
          </div>
          <div className={classNames("mb-1", styles.confirmPassCheckContainer)}>
            {passCaseCheck &&
              <div className={styles.confirmPassCheck}>
                <CheckIcon />
              </div>
            }
            <div>One upper and one lower case character</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12" sm="12" className="mt-2">
          <div className="btnWrapper">
            <button className="btnLink" onClick={handleCancel}>Cancel</button>
            <SpinnerButton
              type='primary'
              spinning={isSubmitting}
              disabled={!newPassCheck || !formData.currentPassword || !formData.confirmPassword || isSubmitting}
              onClick={handleChangePass}
              text={'Save Password'}
            />
          </div>

          <div class="errorMsg" aria-live="polite">{errorMessage}</div>
        </Col>
      </Row>
    </>
  );
};

export default ChangePassword;