import React, { Component } from 'react';
import {
    Row, Col
} from 'reactstrap';
import classNames from 'classnames';
import styles from './NeedHelp.module.css';
import { Link } from 'react-router-dom';
import { SessionContext } from './../../../context/SessionContext'
import { getBrandContent } from '../../BrandingWhiteLabel/BrandContent'

export default class NeedHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popUp: false
        }
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    open = e => {
        this.context.setShowHelp(!this.context.showHelp);
        /*
        this.setState({
            popUp: !this.state.popUp
        });
        */
    }

    close = e => {
        this.context.setShowHelp(false);
        /*
        this.setState({
            popUp: false
        });
        */
    }

    componentDidMount() {
    }

    render() {
        const session = this.context
        const { accountName } = session

        return (session.isAuthenticated() && 
            <div className={styles.helpBtnWrapper}>
                <button 
                    className={styles.helpBtn} 
                    aria-label="Need Help?" 
                    onClick={this.open}
                >
                    Help
                </button>
                {session.showHelp &&
                    <div className={styles.helpTipText}>
                        <h1 className={`h4 mb-0 ${styles.helpHeading}`} >Contact Support</h1>
                            <div className='mb-3'>
                                <div>
                            <a href="tel:{getBrandContent(accountName).programPhone}" className='callLink pl-0'>{getBrandContent(accountName).programPhone}</a>
                                </div>
                                <Link 
                                    onClick={() => session.tabDispatch('ADD_TAB', {
                                        title: "Contact Us",
                                        tabType: "contactus"
                                    })} 
                                    className={styles.moreHelp} 
                                    aria-label="View contact us opens a new details window"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        <h1 className={`h4 mb-0 ${styles.helpHeading}`}>Have Questions?</h1>
                        <Link 
                            onClick={() => session.tabDispatch('ADD_TAB', {
                                title: "Resources",
                                tabType: "resources"
                            })}
                            className={styles.moreHelp}
                            aria-label="View resource center opens a new details window"
                        >
                            View Resource Center
                        </Link>
                        <button 
                            type="button" 
                            className={styles.close} 
                            aria-label="Close" 
                            onClick={this.close}
                        />
                    </div>
                }
            </div>
        );
    }
}
NeedHelp.contextType = SessionContext;