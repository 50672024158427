import React, { useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import {
    QtyInput,
    UnitPriceInput,
    CaptionComponent,
    widthFormatter,
    amountHeaderFormatter,
    AuthInput,
    TripType,
    HeaderFormatter,
    DeleteRowButton,
    AddRowButton
} from '../util/FormatterComponents'
import { currencyFormat } from '../util/util'
import styles from '../Adjudication.module.css'
import { AdjudicationContext } from '../util/store/context';

const MileageTable = ({ data }) => {
    const { state } = useContext(AdjudicationContext);
    const { mileage, totals } = state;

    const emptyFooter = () => <span></span>;

    const cols = [{
        text: '',
        dataField: '',
        isDummyColumn: true,
        style: widthFormatter('deleteCol'),
        headerStyle: widthFormatter('deleteCol'),
        formatter: (col, row, rowIndex) => <DeleteRowButton rowId={row.id} rowIndex={rowIndex} table='mileage' />,
        footer: <></>,
        footerClasses: styles.noborder
    },
    {
        dataField: 'miles',
        text: 'Miles',
        style: widthFormatter('deleteCol'),
        headerStyle: widthFormatter('deleteCol'),
        classes: styles.containsInput,
        headerFormatter: (col) => HeaderFormatter({ col }),
        formatter: (_, row, rowIndex) => <QtyInput row={row} rowIndex={rowIndex} table={'mileage'} />,
        align: 'center',
        footer: emptyFooter,
        footerClasses: styles.noborder
    },
    {
        dataField: 'tripType',
        text: 'Trip',
        style: widthFormatter('tripType'),
        headerStyle: widthFormatter('tripType'),
        classes: styles.containsInput,
        formatter: (_, row, rowIndex) => <TripType row={row} rowIndex={rowIndex} table={'mileage'} />,
        headerFormatter: (col) => HeaderFormatter({ 
            col,
            toolTipRequired: true,
            toolTipMessage: "Mileage charges may be approved if the trip exceeds 30 miles one way or 60 round trip."
        }),
        style: widthFormatter('tripCol'),
        footer: () => <AddRowButton table='mileage' />,
        footerClasses: [styles.addRow, styles.milegeRow, styles.noborder]
    },
    {
        dataField: 'df2',
        text: '',
        isDummyField: true,
        footer: 'Mileage Total',
        footerClasses: styles.noborder
    },
    {
        dataField: 'ratePerMile',
        text: 'Rate/Mile',
        style: widthFormatter('amountCol'),
        headerStyle: widthFormatter('amountCol'),
        classes: styles.containsInput,
        headerFormatter: (col) => HeaderFormatter({ col, centered: true }),
        align: 'right',
        formatter: (col, row, rowIndex) => <UnitPriceInput row={row} rowIndex={rowIndex} table={'mileage'} />,
        footer: currencyFormat(totals.mileage.reqPrice),
    },
    {
        dataField: null,
        text: 'Total',
        style: { ...widthFormatter('amountCol'), padding: '4px 8px' },
        headerStyle: { ...widthFormatter('amountCol'), padding: '4px 16px' },
        headerFormatter: (col) => HeaderFormatter({ col, centered: true }),
        formatter: (col, row, rowIndex) => currencyFormat(state.mileage[rowIndex].requested),
        align: 'right',
        footer: currencyFormat(totals.mileage.requested),
    },
    ]
    return (!!mileage &&
        <BootstrapTable
            bootstrap4
            condensed
            data={mileage}
            columns={cols}
            keyField='id'
            caption={<CaptionComponent title="Mileage" />}
            classes={['mb-0', styles.padCells, styles.AdjudicationTable, styles.mileageTable]}
            wrapperClasses={[styles.AdjudicationTableContainer]}
        />
    )
}

export default MileageTable;