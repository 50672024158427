import React, { Component } from "react";
import {
    Row, Col, Label,
    Input, Popover, PopoverBody, PopoverHeader, FormGroup
} from "reactstrap";
import { DropDownAutoComplete } from '../../inputs/DropDownAutoComplete';
import BootstrapTable from "react-bootstrap-table-next";
import classNames from "classnames";
import styles from "./PlanOptions.module.css";
import SimpleReactValidator from "simple-react-validator";
import { ReactComponent as InfoSVG } from '../../../assets/images/info-icon.svg'
import { ReactComponent as CheckIcon } from '../../../assets/images/checkmark.svg';
import { getDealerDocument } from "../../Global/DocumentHelper";
import { accountDocumentTypes } from '../../Global/DocumentHelper';
import { SessionContext } from "../../../context/SessionContext";

let tableResponsiveLg = "table-responsive-lg";
let colLgAuto = "col-lg-auto"
let planOptions = styles.planOptions;
let coverageTerm = styles.coverageTerm;


export class PlanOptionsCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false,
            selectedOption: "",
            radioType: 0,
            selectedYears: 0,
            years: "",
            showAddProduct: false,
            laborOptions: [],
            selectedLabor: null,
            selectedCoverage: null,
            laborPlans: [],
            showCoverageType: false,
            coverageTypes: [],
            selectedCoverage: 0,
            showTerms: false,
            terms: [],
            isAdvantage: true,
            selectedRow: this.props.selectedRow[0],
            tableData: this.props.tableData,
            accountId: this.props.accountId,
            language: this.props.isCanada == true ? "en-CA" : "en-US",
            isReimbursementLinkVisible: false,
            reimbursementScheduleLink: null
        }

        this.getAllTiers = this.getAllTiers.bind(this);
        this.handleLaborDropDown = this.handleLaborDropDown.bind(this);
        this.getCoverages = this.getCoverages.bind(this);
        this.getTerms = this.getTerms.bind(this);
        this.setRembursementLink = this.setReimbursementLink.bind(this);
        this.validator = new SimpleReactValidator({
            element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
            messages: {
                required: ':attribute is required.'
            }
        });
    }

    componentDidMount() {

        this.getAdvantageTiersByAccount();

        if (this.props.blnPencil == true || this.props.blnCopy == true) {
            this.loadProps();
        }

        this.getReimbursementSchedDoc();

    }

    loadProps = () => {

        if (typeof this.props.selectedRow[0].tierId !== "undefined") {
            const loc = this.props.selectedRow[0];

            this.setState({ showCoverageType: true })
            this.getAdvantageCoverages(this.state.accountId, loc.tierId, this.state.language);

            this.setState({ showTerms: true });
            this.props.updateShowTermsText(true);
            this.getAdvantageTerms(this.state.accountId, loc.tierId, loc.coverageTypeId);
            this.props.onTermChange({ selectedLabor: loc.tierId, selectedCoverage: loc.coverageTypeId, selectedYears: loc.termId, years: "Yr" });

        }
    }

    togglePopover = () => {
        this.setState({ popoverOpen: !this.state.popoverOpen })
    };

    onTypeRadioChange = (newData) => {

        const selLabor = this.props.selectedRow[0].tierId;

        let data = [...this.props.selectedRow]
        data[0].coverageTypeId = newData.selectedCoverage;
        data[0].termId = "";

        this.props.updateRow(data)
        this.props.updateErrors("coverage")
        this.getAdvantageTerms(this.state.accountId, selLabor, newData.selectedCoverage);
        this.setState({ showTerms: true, selectedCoverage: newData.selectedCoverage });
        this.props.updateShowTermsText(true);
    }

    handleLaborDropDown = (e) => {

        this.setState({ selectedLabor: e, showCoverageType: true, terms: [], showTerms: false });
        this.getAdvantageCoverages(this.state.accountId, e, this.state.language);

        let data = [...this.props.selectedRow]
        data[0].tierId = e
        data[0].coverageTypeId = "";
        data[0].termId = "";
        this.props.updateRow(data)
        this.props.updateErrors("labor")
        this.props.updateShowTermsText(false);

        if (e == "5") {
            this.setState({ showCoverageType: false })
        }
    }

    getAdvantageCoverages = (accountId, tierId, language) => {

        fetch(`pricing/GetCoverageByAccountAndTierId/${accountId}/${tierId}/${language}`)
            .then(response => response.json())
            .then(data => {
                this.setState({ coverageTypes: data, selectedLabor: tierId, showCoverageType: true });
            });
    }


    getCoverages = (tierId) => {

        const country = this.props.isCanada == true ? "CA" : "US";

        fetch(`tier/getcoveragesbytierid/${tierId}/${country}`)
            .then(response => response.json())
            .then(data => {
                this.setState({ coverageTypes: data });
            });
    }

    getTerms = (tierId, coverageTypeId) => {
        fetch(`tier/gettermsbytieridandcoveragetypeid/${tierId}/${coverageTypeId}`)
            .then(response => response.json())
            .then(data => {
                let terrms = data.map(t => {
                    return {
                        termYears: (t.termInDays / 365),
                        termId: t.termId
                    }
                });
                this.setState({ terms: terrms });
            });
    }


    getAdvantageTerms = (accountId, tierId, coverageTypeId) => {
        fetch(`contract/GetTermsByAccountIdTierIdAndCoverageTypeId/${accountId}/${tierId}/${coverageTypeId}`)
            .then(response => response.json())
            .then(data => {
                let terrms = data.map(t => {
                    return {
                        termYears: (t.termInDays / 365),
                        termId: t.termId
                    }
                });
                this.setState({ terms: terrms, showTerms: true });
                this.props.updateShowTermsText(true);
            });
    }

    onTermRadioChange = (newData) => {

        this.setState({ ...newData });

        let data = [...this.props.selectedRow]
        data[0].termId = newData.selectedYears
        this.props.updateRow(data)
        this.props.updateErrors("term")
    }

    getAllTiers = () => {
        fetch(`tier/getbypartyid/654`)
            .then(response => response.json())
            .then(data => {
                var dropdownData = data.map(t => {
                    return {
                        data: t.tierId,
                        display: t.tierName,
                    }
                });
                this.setState({ laborOptions: dropdownData, planOptions: data });
            });
    }

    getAdvantageTiersByAccount = () => {
        const { accountName } = this.context;

        fetch(`tier/GetTierByAccountId/${this.props.accountId}`)
            .then(response => response.json())
            .then(data => {
                var dropdownData = data.map(t => {
                    return {
                        data: t.tierId,
                        display: t.tierName,
                    }
                });
                let tierData = {};
                if(accountName?.toLowerCase() === 'lennox') {
                    tierData = data
                        .filter(tierItem => { return tierItem.tripRate > 0 && tierItem.laborRate > 0; })
                        .map(d => {
                            return {
                                planType: d.tierName,
                                tierName: d.tierName,
                                tripRate: `$${d.tripRate}`,
                                laborRate: `$${d.laborRate}`,
                                refrigerant: d.tierName.toLowerCase() !== 'basic' ? (<CheckIcon />) : " ",
                                addBenefits: d.tierName.toLowerCase() !== 'basic' ? (<CheckIcon />) : " "
                            }
                        });
                } else {
                    tierData = data
                        .filter(tierItem => { return tierItem.tripRate > 0 && tierItem.laborRate > 0; })
                        .map(d => {
                            return {
                                planType: d.tierName,
                                tierName: d.tierName,
                                tripRate: `$${d.tripRate}`,
                                laborRate: `$${d.laborRate}`,
                                addBenefits: d.tierName.toLowerCase() !== 'basic' ? (<CheckIcon />) : " "
                            }
                        });
                }
                if (accountName?.toLowerCase() === 'lennox') {
                    tierData.push(
                      {
                        planType: "Refrigerant only",
                        tierName: "Refrigerant only",
                        tripRate: "",
                        laborRate: "",
                        refrigerant: <CheckIcon />,
                        addBenefits: " "
                      }
                    )
                  }
                
                this.setState({ laborOptions: dropdownData, planOptions: tierData });
            });
    }


    validatePlanInfoData = () => {

        if (this.validator.allValid()) {
            return true;
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
    }

    getSelectedCoverageType = (coverage) => {

        let selected = this.props.selectedRow.find(x => x.coverageTypeId == coverage.coverageTypeId);

        if (typeof selected == 'undefined') {
            selected = false;
        }

        return selected //!= undefined
    }

    getSelectedTerm = (term) => {
        let selected = this.props.selectedRow.find(x => x.termId == term);

        if (typeof selected == 'undefined') {
            selected = false;
        }

        return selected;
    }

    setReimbursementLink = (res) => {
        if (res?.success) {
            this.setState({ reimbursementScheduleLink: res.documentPath, isReimbursementLinkVisible: res.success });
        }
    }

    getReimbursementSchedDoc = () => {
        let profile = this.context.getProfile();
        getDealerDocument(accountDocumentTypes.ReimbursementSchedules, false, profile, this.setReimbursementLink);
    }

    priceFormatter = (price, tableData) => {
        return (
            <div className="text-left">${Number.parseFloat(price).toFixed(2)}</div>
        );
    };

    render() {
        const { selectedYears, radioType, years, showAddProduct } = this.state;
        const { accountName } = this.context;
        let reimbursePlanColumns = [
            {
                dataField: "tierName",
                text: "Plan",
                classes: styles.reimbursePlanType,
            },
            {
                dataField: "tripRate",
                text: "Trip Fee",
            },
            {
                dataField: "laborRate",
                text: "Labor Rate",
            },
        ];

        if (accountName && accountName.toLowerCase() === 'lennox') {
            reimbursePlanColumns.push(
                {
                    dataField: "refrigerant",
                    text: "Refrigerant",
                    classes: `d-table-cell ${styles.addBenefits}`
                },
                {
                    dataField: "addBenefits",
                    text: "Additional Benefits*",
                    classes: `d-table-cell ${styles.addBenefits}`
                }
            )
        }
        return (

            <div>
                <Row className="multiColWrapper">
                    <Col xs="12" md="6" className={classNames(colLgAuto, planOptions)}>
                        <FormGroup className={this.props.errLabor === true ? "form-error" : ""}>
                            <div className="d-flex">
                                <Label for="typeOfInst" className="form-label">Labor Reimbursement</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <span id="ReimbersementInfoCampaign"
                                    className={styles.reimburseOption}
                                    onClick={() => this.togglePopover()}
                                    type="button">
                                    <InfoSVG className={styles.reimburseInfoIcon} />
                                </span>
                            </div>
                            <DropDownAutoComplete
                                name="laborReimbursement"
                                options={this.state.laborOptions} //"hello"
                                placeholder="Please select..."
                                onChangedValue={this.handleLaborDropDown}
                                defaultValue={typeof this.props.selectedRow[0].tierId === "undefined" ? this.state.selectedLabor : this.props.selectedRow[0].tierId}
                                value={typeof this.props.selectedRow[0].tierId === "undefined" ? this.state.selectedLabor : this.props.selectedRow[0].tierId}
                            />
                            {this.props.errLabor === true && <div className="errorMsg">Labor reimbursement is required.</div>}
                        </FormGroup>
                    </Col>

                    {this.state.showCoverageType && <Col xs="12" md="6" className={classNames(colLgAuto, planOptions)}>
                        <FormGroup className={this.props.errCoverage === true ? "form-error" : ""}>
                            <Label for="typeCoverage" className="form-label">Type</Label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <div id="typeCoverage1" className="d-flex">
                                {this.state.coverageTypes.map((coverage, index) => (
                                    <div className={styles.radioCylinder}>
                                        <Input type="radio" name={'typeOfCoverage'}
                                            id={'typeOfCoverage' + coverage.coverageTypeId + index}
                                            checked={this.getSelectedCoverageType(coverage)}
                                            onClick={() => { this.onTypeRadioChange({ selectedCoverage: coverage.coverageTypeId, showTerms: true, selectedYears: 0 }); }} />
                                        <Label className={classNames(styles.controlsLabel, 'frmRadioError')} for={'typeOfCoverage' + coverage.coverageTypeId + index}>{coverage.description}</Label>
                                    </div>))}
                            </div>
                            {this.props.errCoverage === true && <div className="errorMsg">Type is required.</div>}
                        </FormGroup>
                    </Col>}


                </Row>
                <Row className="multiColWrapper form-group">
                    {this.state.showTerms && <Col xs="12" className={classNames(colLgAuto, planOptions)}>
                        <FormGroup className={this.props.errTerm === true ? "form-error" : ""}>
                            <Label for="coverageTerm" className="form-label">Term</Label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <Row id="coverageTerm-Row" className={classNames("m-0", coverageTerm)}>
                                {this.state.terms.map((term, index) => (
                                    <div className={styles.radioCylinder}>
                                        <Input type="radio"
                                            onClick={() => { this.onTermRadioChange({ selectedYears: term.termId, years: 'Yr', selectedCoverage: this.state.selectedCoverage, selectedLabor: this.state.selectedLabor }); }}
                                            checked={this.getSelectedTerm(term.termId)}
                                            name={'coverageTerm'}
                                            id={'termOfCoverage' + term.termId + index} />
                                        <Label className={classNames(styles.controlsLabel, 'frmRadioError')} for={'termOfCoverage' + term.termId + index}>{term.termYears} Yr</Label>
                                    </div>
                                ))}
                            </Row>
                            {this.props.errTerm === true && <div className="errorMsg">Term is required.</div>}
                        </FormGroup>
                    </Col>}
                </Row>
                <Popover
                    placement="right"
                    isOpen={this.state.popoverOpen}
                    target="ReimbersementInfoCampaign"
                    trigger="focus"
                    toggle={() => this.togglePopover()}
                    className={styles.reimburseInfoPopover}>
                    <PopoverHeader>
                        Labor Reimbursement Selection
                    </PopoverHeader>
                    <PopoverBody>
                        <p>Multiple reimbursement amounts available to meet your business needs.</p>
                        <div className={styles.reimbursePlanTable}>
                            <BootstrapTable
                                keyField="dealerPO"
                                data={this.state.planOptions}
                                columns={reimbursePlanColumns}
                                bordered={false}
                                bootstrap4
                                wrapperClasses={`text-center ${tableResponsiveLg}`}
                            />
                            {accountName && accountName.toLowerCase() === "lennox" && (
                                <p>
                                    *Additional benefits include Parts Markup and Misc. Parts.
                                </p>
                            )}
                        </div>
                        <div className={styles.reimbursePopFooter}>
                            {this.state.isReimbursementLinkVisible ? <button className="btnLink" onClick={() => window.open(this.state.reimbursementScheduleLink, '_blank')}>View Reimbursement Schedule</button> :
                                <div></div>}
                            <button className="btn-primary" onClick={() => this.togglePopover()}>Close</button>
                        </div>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}

PlanOptionsCampaign.contextType = SessionContext;