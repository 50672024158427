import React from 'react'
import InvoiceAdjudicationDisplay from './InvoiceAdjudicationDisplay'
import { AdjudicationContextProvider } from '../Authorizations/util/store/context'

const InvoiceAdjudication = (props) => {

    return (
        <AdjudicationContextProvider>
            <InvoiceAdjudicationDisplay setAuthorizeStatus={props.setAuthorizeStatus}
                eventNumber={props.eventNumber}
                claimId={props.claimId}
                clientId={props.clientId} eventId={props.eventId} brandName={props.brandName}
                modelNumber={props.modelNumber}
                serialNumber={props.serialNumber} servicerPhone={props.servicerPhone}
                preauthorizedAmount={props.preauthorizedAmount}
                rfsData={props.rfsData}
                  />
        </AdjudicationContextProvider>
    )
}

export default InvoiceAdjudication;