import React from 'react';
import {ReactComponent as AddIcon} from "../../../assets/images/plus.svg"
import styles from './IconButtons.module.css';
import classNames from 'classnames';

const AddButton = ({
    id = "", 
    onClick, 
    btnName, 
    disabled = false, 
    ariaLabel,
    customClass = ""
}) => {    

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            ariaLabel={ariaLabel}
            className={classNames(styles.iconBtn,`btn-secondary ${customClass}`)}
        >
            <span>
                <AddIcon className={styles.addIcon}/>
            </span>
            <span>{btnName}</span>
        </button>   
    )
}

export default AddButton;