import React from "react";
import styles from './AlertBanner.module.css';
import { ReactComponent as CloseIcon } from '../../../assets/images/close_med.svg';
import classNames from 'classnames';
import { NavLink } from "react-bootstrap";

export default function AlertBanner(props) {
  const {content = "",
      hasClose= true, 
      onClose= () => {}, 
      bgColor, 
      color, 
      show=false, 
      bannerType, 
      linkText="",
      linkClick = () => { },
      hasLink=false} = props;

  const getBanner = (bannerType) => { 
    switch (bannerType) {
      case 'alert': 
          return { bgColor: '#E81944', color: '#fff'};
      case 'warning': 
          return { bgColor: '#FFBF3F', color: '#001871' };
      case 'info':
          return { bgColor: '#031d85', color: '#fff' };
      default:  console.error("Banner type has no mapped value");
    }
  }  

  if(show){
    return (
      <div
        className={classNames(styles.AIGBanner, "row")}
        style={{ background: bgColor || getBanner(props.bannerType).bgColor, color: color || getBanner(bannerType).color }}>
        <div> {content}
          {hasLink && <a onClick={props.linkClick}>{linkText}</a>}
        </div>
        {hasClose && (
          <div className={styles.closeBanner} onClick= {onClose}>
            <CloseIcon style={{fill: color || getBanner(bannerType).color, stroke: color || getBanner(bannerType).color}} /> 
          </div>
        )}
      </div>
    );
  }
  return null;
}
