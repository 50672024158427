import React, { Component } from 'react';
import { ReactComponent as UserAltIcon } from '../assets/images/user-alt.svg';
import { ReactComponent as BellIcon } from '../assets/images/bell.svg';
import { ReactComponent as CloseIcon } from '../assets/images/close.svg';
import { ReactComponent as AIGLogo } from '../assets/images/Vendor/AIG/aig-logo.svg';
import { ReactComponent as DownArrow } from '../assets/images/arrow-cheveron-down.svg';
import {
  Col, Row, Popover, PopoverBody, PopoverHeader, Dropdown, DropdownItem, DropdownToggle, DropdownMenu
} from "reactstrap";
import styles from '../components/Nav/NavHeader.module.css';
import classNames from 'classnames';
import { ReactComponent as LennoxLogo } from '../assets/images/Vendor/Lennox/lennox-logo.svg';
import { ReactComponent as ComfortShieldLogo } from '../assets/images/Vendor/Lennox/comfort-shield-logo.svg';
import CarrierLogo from '../assets/images/Vendor/Carrier/climate-shield-top-logo.png';
import RheemLogo from '../assets/images/Vendor/Rheem/protection-plus-logo.png';
import TraneLogo from '../assets/images/Vendor/Trane/comforsure-horz.png';
import GELogo from '../assets/images/Vendor/GE/comfort-protect-logo.png';
import ICPLogo from '../assets/images/Vendor/ICP/comfort-promise-logo.svg';
import StatusBadge from '../components/shared/StatusBadge/StatusBadge';
import NotificationInfo from './Notification/NotificationInfo';
import SnackBar from './shared/SnackBar/SnackBar';
import { SessionContext } from '../context/SessionContext';
import { DropDownInput } from '../components/inputs/DropDownInput';
import { Link } from 'react-router-dom';
import { getBrandContent } from '../components/BrandingWhiteLabel/BrandContent'
import ContactUsModal from "../components/Modals/ContactUsModal/ContactUsModal";
import { PaymentSource } from '../components/Global/PaymentConstants';

let mxAuto = "mx-auto";
let profileWrapper = styles.profileWrapper;

export class Header extends Component {

  state = {
    openProfileMenu: false,
    openNotifications: false,
    showNotification: [1, 2, 3, 4, 5],
    showSnackBar: true,
    dealer: 0,
    accountId: 0,
    showModal: false,
  };

  togglePopover = () => {
    this.setState({ openNotifications: !this.state.openNotifications })
  };

  toggleProfileMenu = () => {
    this.setState({ openProfileMenu: !this.state.openProfileMenu })
  };

  linkToProfile = (activeProfileTab, session) => {
    session.tabDispatch("ADD_TAB", {
      title: "Profile",
      tabType: "profile",
      activeProfileTab
    });
    this.toggleProfileMenu();
  };

  // linkToProfile = (activeProfileTab, session) => {
  //   session.tabDispatch("ADD_TAB", {
  //     title: "Profile",
  //     tabType: "profile",
  //     activeProfileTab
  //   });
  //   this.toggleProfileMenu();
  // };

  handleNotification = (notificationID) => {
    const { showNotification } = this.state;
    const pos = showNotification.indexOf(notificationID);
    if (pos > -1) {
      showNotification.splice(pos, 1);
    }
    else {
      showNotification.push(notificationID);
    }
    this.setState({ showNotification });
  }
  getLogos = (session) => {
    const account = this.context.accountName.toLowerCase()
    const {translationsHeader} = session;
    if (account === "lennox") {
      return <div className={styles.logoBar}>
        <div className={styles.leftWrapper}>
          {!session.isAuthenticated() ? (
            <>
              <a href="/login"><ComfortShieldLogo className={styles.csLogo} /></a>
              <a href="/login"><LennoxLogo className={styles.lennoxIcon} /></a>
            </>
          ) : (
              <>
                <ComfortShieldLogo className={styles.csLogo} />
                <LennoxLogo className={styles.lennoxIcon} />
              </>
            )
          }
          <span className={styles.portalName}>{translationsHeader?.poweredByAig || "Powered by AIG"}</span>
        </div>
      </div>
    }
    else if (account === "rheem") {
      return <div className={styles.logoBar}>
        <div className={styles.leftWrapper}>
          {!session.isAuthenticated() ?
            <a href="/login"><img src={RheemLogo} className={styles.rheemIcon} /></a>
            :
            <img src={RheemLogo} className={styles.rheemIcon} />
          }
          <span className={styles.portalName}>Powered by AIG</span>
        </div>
      </div>
    }
    else if (account === "carrier") {
      return <div className={styles.logoBar}>
        <div className={styles.leftWrapper}>
          {!session.isAuthenticated() ?
            <a href="/login"><img src={CarrierLogo} className={styles.carrierIcon} /></a>
            :
            <img src={CarrierLogo} className={styles.carrierIcon} />
          }
          <span className={styles.portalName}>{translationsHeader?.poweredByAig || "Powered by AIG"}</span>
        </div>
      </div>
    }
    else if (this.context.accountName.toLowerCase() === "trane") {
      return <div className={styles.logoBar}>
        <div className={styles.leftWrapper}>
          {!session.isAuthenticated() ?
            <a href="/login"><img src={TraneLogo} className={styles.traneIcon} /></a>
            :
            <img src={TraneLogo} className={styles.traneIcon} />
          }
          <span className={styles.portalName}>Powered by AIG</span>
        </div>
      </div>
    }
    else if (this.context.accountName.toLowerCase() === "ge") {
      return <div className={styles.logoBar}>
        <div className={styles.leftWrapper}>
          {!session.isAuthenticated() ?
            <a href="/login"><img src={GELogo} className={styles.geIcon} /></a>
            :
            <img src={GELogo} className={styles.geIcon} />
          }
          <span className={styles.portalName}>Powered by AIG</span>
        </div>
      </div>
    }
    else if (this.context.accountName.toLowerCase() === "icp") {
      return <div className={styles.logoBar}>
        <div className={styles.leftWrapper}>
          {!session.isAuthenticated() ?
            <a href="/login"><img src={ICPLogo} className={styles.icpIcon} /></a>
            :
            <img src={ICPLogo} className={styles.icpIcon} />
          }
          <span className={styles.portalName}>Powered by AIG</span>
        </div>
      </div>
    }
    else {
      return <div className={styles.logoBar}>
        <div className={styles.leftWrapper}>
          <span className={styles.iconWrapper}>
            {!session.isAuthenticated() ?
              <a href="/login"><AIGLogo className={styles.aigIcon} /></a>
              :
              <AIGLogo className={styles.aigIcon} />
            }
          </span>
          <span className={styles.portalName}>{translationsHeader?.servicePlanHub || "Service Plan Hub"}</span>
        </div>
      </div>
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showSnackBar: false })
    }, 6000)
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  handleContactUsClick = (session) => {
    if (session.isAuthenticated() && !this.context.needsToReacceptFlag()) {
      session.tabDispatch('ADD_TAB', {
        title: "Contact Us",
        tabType: "contactus"
      })
    } else {
      this.toggleModal();
    }
    
  }

  handleLogout = async () => {
    const { accountName, dangerouslyUpdateAuthState } = this.context
    const accountNameLower = accountName.toLowerCase();
    
    await fetch('authentication/logout');
    dangerouslyUpdateAuthState({
      isAuthenticated: false,
      dealerId: null
    });

    // List of brands who want to BLOCK login screen upon logging out
    const brandsUsingLogout = ['lennox']
    const redirectToLogout = brandsUsingLogout.indexOf(accountNameLower) > -1

    if (redirectToLogout) {
      window.location.href = '/logout'
    } else {
      window.location.href = '/login'
    }
  }

  render() {

    const { openNotifications, openProfileMenu, showNotification } = this.state;
    const { accountName, paymentGatewayStatus } = this.context;
    const accountNameToLower = accountName.toLowerCase();
    const session = this.context
    const profile = session.getProfile();

    return (
      <>
        <div className={classNames("container-fluid", "p-0")}>
          <div className={styles.header} role="banner">
            <Col className={styles.removeLogosPadd}>
              {this.getLogos(this.context)}
            </Col>
            <>
              <Col className={classNames(styles.profileWrapper, "pr-1")}>
                <div className="d-flex align-items-center" >
                  {this.context.isAuthenticated() && !this.context.needsToReacceptFlag() &&
                    <span id="notificationInfo" className={styles.notification} type="button" onClick={() => this.togglePopover()} >
                      <BellIcon className={styles.iconBell} />
                      {this.context.notifications.length > 0 && <StatusBadge color="danger" content={this.context.notifications.length.toString()} />}
                    </span>
                  }
                  {!paymentGatewayStatus && 
                    <Link className={styles.contactUsLink} to="#" onClick={() => this.handleContactUsClick(this.context)}>
                      Contact Us
                    </Link>
                  }
                  {this.state.showModal && <ContactUsModal isOpen={true} />}
                </div>
                {this.context.isAuthenticated() && !this.context.needsToReacceptFlag() &&
                  <Dropdown isOpen={openProfileMenu} toggle={this.toggleProfileMenu}>
                    <DropdownToggle
                      tag="span"
                      data-toggle="dropdown"
                      aria-expanded={openProfileMenu}
                    >
                      <div id="dropDownArrowDiv" className="d-flex align-items-center">
                        <div id="userIcon">
                          <UserAltIcon className={styles.iconUser} />
                        </div>
                        <div id="dealerName" className={styles.fontsizeDiv}>
                          <span className={styles.userName}>
                            {this.context.getProfile().dealerName}
                          </span>
                        </div>
                        <div id="dropArrowIcon">
                          <DownArrow className={classNames(styles.iconArrow)} />
                        </div>
                      </div>
                    </DropdownToggle>
                    <div className={styles.dropDownDiv}>
                      <DropdownMenu className={styles.dropdownDisplay} >
                        <DropdownItem onClick={() => this.linkToProfile(1, this.context)}>My Profile</DropdownItem>
                        {accountNameToLower !== "ge" && <DropdownItem onClick={() => this.linkToProfile(2, this.context)}>
                          {`${getBrandContent(accountName).advantageProgramName} Preferences`}
                        </DropdownItem>}
                        <DropdownItem onClick={() => this.linkToProfile(3, this.context)}>Coverage Preferences</DropdownItem>
                        <DropdownItem onClick={() => this.linkToProfile(4, this.context)}>Billing Preferences</DropdownItem>
                        {accountNameToLower !== "lennox" && <DropdownItem onClick={() => this.linkToProfile(5, this.context)}>Communications</DropdownItem>}
                        {accountNameToLower !== "lennox" && <DropdownItem onClick={() => this.linkToProfile(6, this.context)}>Security</DropdownItem>}
                        {/*<DropdownItem onClick={this.toggleProfileMenu}>Update Password</DropdownItem>*/}
                        <DropdownItem onClick={this.handleLogout}>Logout</DropdownItem>
                      </DropdownMenu>
                    </div>
                  </Dropdown>
                }
              </Col>
              {this.context.isAuthenticated() && !this.context.needsToReacceptFlag() &&
                <div>
                  <SnackBar numOfNotifications={this.context.notifications.length} showSnackBar={this.state.showSnackBar && this.context.notifications.length > 1} onClickView={() => this.setState({ openNotifications: true, showSnackBar: false })} />
                  <Popover
                    placement="bottom"
                    isOpen={openNotifications}
                    toggle={() => this.togglePopover()}
                    target="notificationInfo"
                    trigger="focus"
                    hideArrow={true}
                    className={styles.notificationsPop}
                  >
                    <PopoverHeader className={styles.notificationPopHeader}>
                      <h3>Notifications</h3>
                      <span className={styles.closeNotification} type="button" onClick={() => this.setState({ openNotifications: false })}>
                        <CloseIcon />
                      </span>
                    </PopoverHeader>
                    <div className={styles.notificationBodyCover}>
                      {this.context.notifications.length == 0 ? <div className={styles.noNotifications}>You have no new notifications</div> :
                        <PopoverBody className={styles.notificationBody}>
                          {this.context.notifications.map((notif, key) => (
                            <NotificationInfo
                              key={key}
                              finalNotification={this.context.notifications.length == key + 1}
                              message={notif.message}
                              title={notif.title}
                              dropTime={notif.created}
                              hasClose={notif.hasClose}
                              hasLink={notif.hasLink &&
                                (typeof this.context.tabs[this.context.activeTab - 1] === 'undefined' ||
                                  (typeof this.context.tabs[this.context.activeTab - 1] !== 'undefined' &&
                                    this.context.tabs[this.context.activeTab - 1].title !== notif.linkDestinationTitle
                                     && session.tabs[session.activeTab - 1].tabType !== "serviceRequest"))
                              }
                              linkText={notif.linkText}
                              show={true}
                              badgeStatusID={notif.badgeStatusId}
                              onLinkClick={() => this.context.handleLinkClick(notif.linkDestinationTitle, notif.linkDestination, notif.linkText, notif.claimNumber, profile)}
                              onDismiss={() => this.context.handleDismissNotification(notif.id)}
                            />
                          ))}
                        </PopoverBody>
                      }
                    </div>
                  </Popover>
                </div>
              }
            </>
          </div>
        </div>
      </>
    );
  }
}
Header.contextType = SessionContext;