import React from "react";
import styles from './DealerHome.module.css';
import { CardBody } from "reactstrap";
import { ReactComponent as InfoIcon } from '../../assets/images/info-icon.svg';
import { getBrandContent } from '../../components/BrandingWhiteLabel/BrandContent'
import { SessionContext } from "../../context/SessionContext";
import { useContext } from "react";

// card bg icons
import ContractActive from '../../assets/images/Vendor/AIG/contract-active.svg';
import ContractPending from '../../assets/images/Vendor/AIG/contract-pending.svg'
import SaleIncomplete from '../../assets/images/Vendor/AIG/sale-incomplete.svg'
import AdvantageIcon from '../../assets/images/Vendor/AIG/shield-check.svg'
import LennoxContractActive from '../../assets/images/Vendor/Lennox/contract-active.svg'
import LennoxContractPending from '../../assets/images/Vendor/Lennox/contract-pending.svg'
import LennoxSaleIncomplete from '../../assets/images/Vendor/Lennox/sale-incomplete.svg'
import LennoxAdvantageIcon from '../../assets/images/Vendor/Lennox/shield-check.svg'
import RheemContractActive from '../../assets/images/Vendor/Rheem/contract-active-pp.svg'
import RheemContractPending from '../../assets/images/Vendor/Rheem/contract-pending-pp.svg'
import RheemSaleIncomplete from '../../assets/images/Vendor/Rheem/sale-incomplete-pp.svg'
import RheemAdvantageIcon from '../../assets/images/Vendor/Rheem/shield-check-pp.svg'
import CarrierContractActive from '../../assets/images/Vendor/Carrier/contract-active-CSC.svg'
import CarrierContractPending from '../../assets/images/Vendor/Carrier/contract-pending-CSC.svg'
import CarrierSaleIncomplete from '../../assets/images/Vendor/Carrier/sale-incomplete-CSC.svg'
import CarrierAdvantageIcon from '../../assets/images/Vendor/Carrier/shield-check-CSC.svg'
import GEContractActive from '../../assets/images/Vendor/GE/contract-active_home.svg'
import GEPaymentHistory from '../../assets/images/Vendor/GE/payment-history_home.svg'
import GESaleIncomplete from '../../assets/images/Vendor/GE/sale-incomplete_home.svg'
import ICPContractActive from '../../assets/images/Vendor/ICP/contract-active-icp.svg';
import ICPPaymentHistory from '../../assets/images/Vendor/ICP/contract-pending-icp.svg';
import ICPSaleIncomplete from '../../assets/images/Vendor/ICP/sale-incomplete-icp.svg';
import ICPAdvantageIcon from '../../assets/images/Vendor/ICP/shield-check-icp.svg';


const UpperCard = ({
  amount = "0",
  category = "",
  image,          // WHICH ICON TO USE: active | pending | incomplete | advantage
  isPendingPayment,
  isAdvatageCard,
  onCardClick
}) => {
  const context = useContext(SessionContext)
  const { accountName } = context

  const getCardIcon = () => {
    const accountNameLower = accountName.toLowerCase();

    // REPEAT THIS "IF" FOR EACH NEW BRAND
    if (accountNameLower === "lennox") {
      if (image.toLowerCase() === 'active') return LennoxContractActive
      if (image.toLowerCase() === 'pending') return LennoxContractPending
      if (image.toLowerCase() === 'incomplete') return LennoxSaleIncomplete
      if (image.toLowerCase() === 'advantage') return LennoxAdvantageIcon
    }
    if (accountNameLower === "rheem") {
      if (image.toLowerCase() === 'active') return RheemContractActive
      if (image.toLowerCase() === 'pending') return RheemContractPending
      if (image.toLowerCase() === 'incomplete') return RheemSaleIncomplete
      if (image.toLowerCase() === 'advantage') return RheemAdvantageIcon
    }
    if (accountNameLower === "carrier") {
      if (image.toLowerCase() === 'active') return CarrierContractActive
      if (image.toLowerCase() === 'pending') return CarrierContractPending
      if (image.toLowerCase() === 'incomplete') return CarrierSaleIncomplete
      if (image.toLowerCase() === 'advantage') return CarrierAdvantageIcon
    }
    if (accountNameLower === "ge") {
      if (image.toLowerCase() === 'active') return GEContractActive
      if (image.toLowerCase() === 'history') return GEPaymentHistory
      if (image.toLowerCase() === 'incomplete') return GESaleIncomplete
    }

    if (accountNameLower === "icp") {
      if (image.toLowerCase() === 'active') return ICPContractActive
      if (image.toLowerCase() === 'history') return ICPPaymentHistory
      if (image.toLowerCase() === 'incomplete') return ICPSaleIncomplete
      if (image.toLowerCase() === 'advantage') return ICPAdvantageIcon
    }

    // FALLTHROUGH IF CLIENT NOT DEFINED OR NO MATCH FOUND
    if (image.toLowerCase() === 'active') return ContractActive
    if (image.toLowerCase() === 'pending') return ContractPending
    if (image.toLowerCase() === 'incomplete') return SaleIncomplete
    if (image.toLowerCase() === 'advantage') return AdvantageIcon

    // FALLTHROUGH IF NO MATCH ON CLIENT OR TYPE.
    return null;
  }


  return (
    <CardBody type="button" className={styles.upperCard} style={{ backgroundImage: `url(${getCardIcon()})` }} onClick={onCardClick}>
      <p className={isAdvatageCard ? styles.advCard : styles.numberOf}>
        {amount}
      </p>
      <p className={styles.categoryName} >
        {category}
        {isPendingPayment &&
          <span className={styles.pendingInfoTooltip} >
            <InfoIcon />
            <span className={styles.pendingInfoTooltiptext}>
              {`${getBrandContent(accountName).advantageProgramName} contracts pending that require payment.`}
            </span>
          </span>
        }
      </p>
    </CardBody>
  );
};


export default UpperCard