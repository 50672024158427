import React from "react";
import styles from "./Dots.module.css";
import classNames from "classnames";

const ThreeDotLoading = ({ message = "" }) => (
    <div className={styles.wrap}>
        <div className={classNames(styles.dot)} />
        <div className={classNames(styles.dot)} />
        <div className={classNames(styles.dot)} />
        &nbsp; {message}
    </div>
);

export default ThreeDotLoading;
