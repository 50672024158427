import { calculateLabor } from "./labor";
import { calculateParts } from "./parts";
import { calculateLaborSpecialRate } from "./laborSpecialRate";
import { calculateTrip } from "./trip";
import { calculateTax } from "./tax";
import { calculatePartsMarkup } from "./partsMarkup";
import { calculateRefrigerant } from "./refrigerant"

export const calc = {
    labor: calculateLabor,
    parts: calculateParts,
    partsMarkup: calculatePartsMarkup,
    laborSpecialRate: calculateLaborSpecialRate,
    trip: calculateTrip,
    tax: calculateTax,
    refrigerant: calculateRefrigerant
};
