import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";


export const DuplicateValidationModal = ({ isOpen, toggle, duplicateFields }) => {
    return (
        <Modal isOpen={isOpen} className="md" aria-modal="true" role="dialog" aria-labelledBy="cancel">
            <ModalHeader className="noLine">
                <h4 className="modal-title" id="cancel"> Call to Complete Your Enrollment</h4>
            </ModalHeader>
            <ModalBody>
                <div className="mb-4">We noticed this {duplicateFields?.[0]} is associated with another extended service agreement program backed by AIG.</div>
                <div>To expedite enrollment for this program, contact dealer support at hvacenrollments@sndirect.com or (888) 562-5715.</div>
            </ModalBody>
            <ModalFooter className="multiBtnLayout ">
                <div className="btnWrapper">
                    <button className="btnLink" onClick={toggle} >Close</button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
