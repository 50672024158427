import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, input } from 'reactstrap';
import { DropDownInput } from '../inputs/DropDownInput';
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import SimpleReactValidator from 'simple-react-validator';
import { ReactComponent as PencilIcon } from '../../assets/images/pencil-outline.svg';
import { Spinner } from 'react-bootstrap';
import Cleave from 'cleave.js/react';
import NumberFormat from 'react-number-format';
import SuggestedAddress from '../Modals/SuggestedAddress';
import InvalidAddress from '../Modals/InvalidAddress';
import { verifyAddress, VerificationResponseCodes } from '../../api/addressverification';
import { getBrandContent } from '../BrandingWhiteLabel/BrandContent';
import { SessionContext } from "../../context/SessionContext";
import classNames from 'classnames'

export class CustomerInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            party: this.props.party,
            states: null,
            selectedState: null,
            name: '',
            mobile: '',
            primaryPhone: '',
            home: '',
            email: '',
            mailingAddress: '',
            mailingAddress2: '',
            city: '',
            zip: '',
            editing: false,
            saving: false,
            clientId: this.props.clientId,
            addressType: 'billing',
            shippingAddress: '',
            shippingCity: '',
            shippingZip: '',
            shippingState: '',
            isShippingasMailing: false,
            mailingState: '',
            shippingAddress2: '',
            showSuggestedAddressValidationModal: false,
            verifiedAddress: null,
            addressToVerify: null,
            showInvalidAddressModal: false,
            useInstallAddress: true,
            disableControls: true,
            pristineState: false,
            showError: false,
            errors: {
                name: false,
                email: false,
                mobile: false,
                home: false,
                primaryPhone: false,

                mailingAddress: false,
                mailingCity: false,
                mailingState: false,
                mailingZip: false,
                invalidMailingZip: false,

                shippingAddress: false,
                shippingCity: false,
                shippingState: false,
                shippingZip: false,
                invalidShippingPostal: false,
                duplicateMobile: false,
                duplicateHome: false,
                repeatingNumMobile: false,
                repeatingNumHome: false
            }
        }

        this.shippingCityRef = React.createRef();
        this.handleStateChange = this.handleStateChange.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);

        this.validator = new SimpleReactValidator({
            element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
            messages: {
                required: ':attribute is required.'
            }
        });


        this.save = this.save.bind(this);
        this.handlePhonePreference = this.handlePhonePreference.bind(this);
        this.sameasMailingChangeEvent = this.sameasMailingChangeEvent.bind(this);
        this.onAddressVerified = this.onAddressVerified.bind(this);
        this.onContinueSaveAction = this.onContinueSaveAction.bind(this);
        this.verifyAddress = this.verifyAddress.bind(this);
    }

    componentDidMount() {
        console.log('customerInfoProps-onmount', this.props)

        if (this.props.clientId === "apple") {
            this.toggleEdit();
        }
        fetch('party/states')
            .then(res => res.json())
            .then(data => {
                this.setState({
                    states: data.map(s => {
                        return {
                            data: s.stateId,
                            display: s.name,
                            abbreviation: s.abbreviation
                        }
                    }).sort((a, b) => {
                        if (a.display < b.display) { return -1; }
                        if (a.display > b.display) { return 1; }
                        return 0;
                    })
                });
            })

        //var newParty = this.retrievePartyInfo(this.props.party.partyId)[0];
        //console.log(newParty);

        this.setInitialState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.showErrorMessages && !prevProps.showErrorMessages) {
            if (this.validator.allValid()) {
                this.verifyAddress();
                return;
            } else {
                this.validator.showMessages();
                this.props.resetCanFileAClaim();
                return;
            }
        }

        if (prevProps.party !== this.props.party) {
            //console.log(`LOG>> CustomerInfo:componentDidUpdate this.props.party updated..`);

            this.setState({
                party: this.props.party
            }, () => this.setInitialState());
        }
    }

    checkForCustomerErrors = () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return false;
        }
        else {
            return true;
        }
    }

    setInitialState() {
        let party = this.state.party;

        console.log('initial', party, party.firstName === null, party.firstName === "null")

        let billingAddress = party?.partyAddressModel?.filter(pam => pam.addressTypeId === 1)[0];
        let shippingAddress = party?.partyAddressModel?.filter(pam => pam.addressTypeId === 2)[0];
        let mailingAddres = party?.partyAddressModel?.filter(pam => pam.addressTypeId === 3)[0];
        let emailAddress = party?.partyEmailModel?.length > 0 ? party?.partyEmailModel[0]?.address : undefined;
        let mobilePhone = party.partyPhoneModel.filter(pm => pm.phoneTypeId === 2)[0];
        let homePhone = party.partyPhoneModel.filter(hp => hp.phoneTypeId === 1)[0];

        let primaryPhone = (function () {
            if (typeof mobilePhone !== "undefined") {
                if (mobilePhone.isPrimary)
                    return "mobile";
            }

            if (typeof homePhone !== "undefined") {
                if (homePhone.isPrimary)
                    return "alternate";
            }
        })();

        const partyFullName = `${party.firstName === null ? '' : party.firstName} ${party.lastName === null ? '' : party.lastName}`;
        console.log('fname', partyFullName)
        console.log('state', billingAddress, shippingAddress);

        this.setState({
            name: party.partyName !== null ? party.partyName.trim() : partyFullName.trim(),
            mobile: typeof mobilePhone === "undefined" ? "" : mobilePhone.number,
            home: typeof homePhone === "undefined" ? "" : homePhone.number,
            email: typeof emailAddress === "undefined" ? "" : emailAddress,
            mailingAddress: typeof mailingAddres === "undefined" ? "" : mailingAddres.address1,
            mailingAddress2: typeof mailingAddres === "undefined" ? "" : mailingAddres.address2,
            city: typeof mailingAddres === "undefined" ? "" : mailingAddres.city,
            mailingState: typeof mailingAddres === "undefined" ? "" : mailingAddres.stateId,
            zip: typeof mailingAddres === "undefined" ? "" : mailingAddres.zip,
            shippingAddress: typeof shippingAddress === "undefined" ? "" : shippingAddress.address1,
            shippingCity: typeof shippingAddress === "undefined" ? "" : shippingAddress.city,
            shippingState: (typeof shippingAddress === "undefined" || shippingAddress.stateId === null) ? "" : shippingAddress.stateId,
            shippingZip: typeof shippingAddress === "undefined" ? "" : shippingAddress.zip,
            shippingAddress2: typeof shippingAddress === "undefined" ? "" : shippingAddress.address2,
            primaryPhone: primaryPhone,
        });

        // Checking both address to see if they are the same or not.

        if (this.state.shippingCity === this.state.city &&
            this.state.shippingAddress === this.state.mailingAddress &&
            this.state.shippingState === this.state.mailingState &&
            this.state.shippingZip === this.state.zip &&
            this.state.shippingAddress2 === this.state.mailingAddress2) {
            this.setState({ isShippingasMailing: true });
        } else {
            this.setState({ isShippingasMailing: false });
        }

        let error = this.state.errors;
        error.name = false;
        error.email = false;
        error.mobile = false;
        error.mailingAddress = false;
        error.mailingCity = false;
        error.mailingZip = false;
        error.invalidMailingZip = false;
        error.shippingAddress = false;
        error.shippingCity = false;
        error.shippingZip = false;
        error.invalidShippingPostal = false;

        this.setState({
            errors: error
        });
        this.forceUpdate();
    }

    ppp = [
            { data: "mobile", display: "Mobile", disable:false, mhfield:false }, 
            { data: "alternate", display: "Alternate", disable:false, mhfield:false}
          ];
    changePPPOptions = (mobile,home,mflag,hflag,mobFlag,homeFlag)=>{
        if(mflag && mobile.length < 14 && mobile.length != 10){
            mobFlag = true;
        }
        if(hflag && home.length < 14 && home.length != 10){
            homeFlag = true;
        }
        this.ppp = this.ppp.map((val)=>{
            if(!mobile && val.data === "mobile" || !home && val.data === "alternate"){
                val['disable'] = true;
                val['mhfield'] = true;
                return val;
            }else{
                if(mobile.length && mobFlag && val.data === "mobile"){
                    val['disable'] = true;
                    val['mhfield'] = false;
                    return val;
                }
                if(home.length && homeFlag && val.data === "alternate"){
                    val['disable'] = true;
                    val['mhfield'] = false;
                    return val;
                }
                else{
                    val['disable'] = false;
                    val['mhfield'] = false;
                    return val;
                }
            }
        });
        if(!mobFlag || !homeFlag){
            if(!this.ppp[0].mhfield && !mflag && !this.ppp[1].mhfield && !hflag){
                this.setState({primaryPhone:this.state.primaryPhone})
            }else if(!this.ppp[0].mhfield && !mflag){
                this.setState({primaryPhone:this.ppp[0].data});
            }else if(!this.ppp[1].mhfield && !hflag){
                this.setState({primaryPhone:this.ppp[1].data});
            }else{
                this.setState({primaryPhone:this.state.primaryPhone});
            }
        }
    }

    toggleEdit = () => {
        if (this.props.clientId === "apple") {
            this.setState({ editing: true });
        } else {
            this.setState({ editing: !this.state.editing });
        }

        // Checking both address to see if they are the same or not.

        if (this.state.shippingCity === this.state.city &&
            this.state.shippingAddress === this.state.mailingAddress &&
            this.state.shippingState === this.state.mailingState &&
            this.state.shippingZip === this.state.zip &&
            this.state.shippingAddress2 === this.state.mailingAddress2) {
            this.setState({ isShippingasMailing: true });
        } else {
            this.setState({ isShippingasMailing: false });
        }
        if (this.state.editing) {
            this.setInitialState();
        }

        this.changePPPOptions(this.state.mobile,this.state.home,false,false,false,false);

        let error = this.state.errors;
        error.name = false;
        error.email = false;
        error.mobile = false;
        error.mailingAddress = false;
        error.mailingCity = false;
        error.mailingZip = false;
        error.invalidMailingZip = false;
        error.shippingAddress = false;
        error.shippingCity = false;
        error.shippingZip = false;
        error.invalidShippingPostal = false;

        this.setState({
            errors: error,
            pristineState: false
        });
        this.validator.hideMessages();
        this.forceUpdate();
    }

    handleInputChange = e => {
        const { name, value, isSameCheckbox } = e.target;
        let error = this.state.errors;
        let fieldName = name;
        let mflag=false;
        let hflag=false;

        switch (fieldName) {
            case "name":
                if (value.trim() === "") {
                    error.name = true;
                }
                else {
                    this.setState({ name: value });
                    error.name = false;
                }

                this.setState({
                    errors: error
                });

                break;

            case "email":
                this.validator.showMessageFor('email');
                if (value.trim() === "" || this.validator.message("email", value, "required|email")) {
                    error.email = true;

                }
                else {
                    this.setState({ email: value });
                    error.email = false;
                }

                this.setState({
                    errors: error
                });

                break;

            case "mobile":
                if (value.trim() === "") {
                    error.mobile = true;
                    this.validator.hideMessageFor('mobile');
                }
                else {
                    this.setState({ mobile: value });
                    error.mobile = false;
                    this.validator.showMessageFor('mobile');
                    if(value === this.state.home) {
                        error.duplicateMobile = true;
                    } else {
                        error.duplicateMobile = false;
                    }
                }
                if(value.length < 14) {
                    mflag = true;
                    if(this.state.primaryPhone === "alternate") {
                        error = {
                            ...error,
                            mobile: false,
                            repeatingNumMobile: false
                        }; 
                    }
                } else {
                    const firstChar = value[1];
                    let checkFlag = false;
                    for(let index = 2; index < value.length; index++) {
                        if(value[index] !== "(" && value[index] !== "-" && value[index] !== " " && value[index] !== ")") {
                            if(firstChar === value[index]) {
                                checkFlag = true;
                            } else {
                                checkFlag = false;
                                break;
                            }
                        }                        
                    }
                    if(checkFlag) {
                        error = {
                            ...error,
                            mobile: true,
                            repeatingNumMobile: true
                        }; 
                        this.validator.showMessageFor('mobile');
                    } else {
                        error = {
                            ...error,
                            mobile: false,
                            repeatingNumMobile: false
                        };  
                    }
                }
                    
                if(this.state.home.length <14)
                    hflag = true;  
                this.changePPPOptions(value,this.state.home,mflag,hflag,false,false);
                this.setState({
                    errors: error
                });

                break;
            case "home": 
                if (value.trim() !== "") {
                    this.validator.showMessageFor('home');
                    error.home = false;
                    if(value === this.state.mobile) {
                        error.duplicateHome = true;
                    } else {
                        error.duplicateHome = false;
                        error.repeatingNumHome = false;
                    }
                }
                else{
                    error.home = true; 
                }
                this.setState({ home: value });
                if(value.length < 14) {
                    hflag = true;
                    if(this.state.primaryPhone !== "alternate") {
                        error = {
                            ...error,
                            home: false,
                            repeatingNumHome: false
                        }; 
                    }
                } else {
                    const firstChar = value[1];
                    let checkFlag = false;
                    for(let index = 2; index < value.length; index++) {
                        if(value[index] !== "(" && value[index] !== "-" && value[index] !== " " && value[index] !== ")") {
                            if(firstChar === value[index]) {
                                checkFlag = true;
                            } else {
                                checkFlag = false;
                                break;
                            }
                        }                        
                    }
                    if(checkFlag) {
                        error = {
                            ...error,
                            home: true,
                            repeatingNumHome: true
                        };                        
                        this.validator.showMessageFor('home');
                    } else {
                        error = {
                            ...error,
                            home: false,
                            repeatingNumHome: false
                        };    
                    }
                }
                if(this.state.mobile.length <14 && this.state.mobile.length !== 10)
                    mflag = true;  
                this.changePPPOptions(this.state.mobile,value,mflag,hflag,false,false);
                this.setState({
                    errors: error
                });

                break;

            case "mailingAddress":
                if (value.trim() === "") {
                    error.mailingAddress = true;
                }
                else {
                    this.setState({ mailingAddress: value });
                    error.mailingAddress = false;
                }

                this.setState({
                    errors: error
                });

                break;

            case "mailingAddress2":
                this.setState({ mailingAddress2: value });
                break;

            case "city":
                if (value.trim() === "") {
                    error.mailingCity = true;
                }
                else {
                    this.setState({ city: value });
                    error.mailingCity = false;
                }

                this.setState({
                    errors: error
                });

                break;

            case "zip":
                //if (isNaN(value)) {
                //    error.invalidMailingZip = true;
                //}
                if (value.trim() === "") {
                    error.mailingZip = true;
                }
                else {
                    this.setState({ mailingZip: value });
                    error.mailingZip = false;
                    error.invalidMailingZip = false;
                }

                break;

            case "shippingAddress":
                if (value.trim() === "") {
                    error.shippingAddress = true;
                }
                else {
                    this.setState({ shippingAddress: value });
                    error.shippingAddress = false;
                }

                this.setState({
                    errors: error
                });

                break;

            case "shippingAddress2":
                this.setState({ shippingAddress2: value });
                break;

            case "shippingCity":
                if (value.trim() === "") {
                    error.shippingCity = true;
                }
                else {
                    this.setState({ shippingCity: value });
                    error.shippingCity = false;
                }

                this.setState({
                    errors: error
                });

                break;

            case "shippingZip":
                if (isNaN(value)) {
                    error.invalidShippingPostal = true;
                }
                else if (value.trim() === "") {
                    error.shippingZip = true;
                }
                else {
                    this.setState({ shippingZip: value });
                    error.shippingZip = false;
                    error.invalidShippingPostal = false;
                }

            default:
                break;
        }

        console.log(e);

        this.setState({
            [name]: value,
            pristineState: true
        });

        if (!isSameCheckbox) {
            this.setState({
                isShippingasMailing: false,
            });
        }
    }

    handleStateChange = (value, name) => {
        this.setState({ [name]: value, isShippingasMailing: false, pristineState: true });
    }

    handlePhonePreference = e => {
        this.setState({ primaryPhone: e, pristineState: true });
    }

    sameasMailingChangeEvent = e => {
        this.setState({ isShippingasMailing: !this.state.isShippingasMailing });

        if (e.target.checked) {
            document.getElementById('mailingAddress').value = this.state.shippingAddress;
            document.getElementById('mailingAddress2').value = this.state.shippingAddress2;
            document.getElementById('mailingCity').value = this.state.shippingCity;
            this.setState({ mailingState: this.state.shippingState })
            document.getElementById('mailingPostal').value = this.state.shippingZip;
        }
        else {
            document.getElementById('mailingAddress').value = this.state.mailingAddress;
            document.getElementById('mailingAddress2').value = this.state.mailingAddress2;
            document.getElementById('mailingCity').value = this.state.city;
            this.setState({ mailingState: this.state.mailingState })
            document.getElementById('mailingPostal').value = this.state.zip;
        }
        this.verifyErrorMessages(["mailingAddress", "mailingAddress2", "mailingCity", "mailingPostal"]);
    }

    verifyErrorMessages = (list) => {
        list.forEach((value) => {
            let name = value;
            if (name == "mailingCity") name = "city";
            if (name == "mailingPostal") name = "zip";
            this.handleInputChange({
                target: {
                    name: name,
                    value: document.getElementById(value).value,
                    isSameCheckbox: true
                }
            })
        })
    }

    validateFeilds = () => {
        const errorMessages = this.validator.getErrorMessages();
        let filtered = Object.keys(errorMessages).filter((key, index) => {
            if (key == "mobile" || key == "home") {
                if (!!this.validator.message(key, this.state[key], "phone") || this.state.errors[key]) {
                    return true;
                } else {
                    return false;
                }
            }
            else {
                return !!errorMessages[key];
            }
        })
        return filtered.length == 0
    }

    verifyAddress = async () => {
        let error = this.state.errors;
        if (this.state.email.trim() === "") {
            error.email = true;
            this.setState({
                errors: error
            });
        }
        const list = ["mailingAddress", "city", "zip"];
        for (let name of list) {
            await new Promise((resolve, reject) => {
                if (!this.state[name]) {
                    this.setState((state) => {
                        if (name == "city") name = "mailingCity";
                        if (name == "zip") name = "mailingZip";
                        state.errors[name] = true;
                        return state;
                    }, resolve())
                } else resolve();
            })
        }
        
        const errors = { ...this.state.errors }
        delete errors.mobile;
        delete errors.home;
        
        if ((this.validateFeilds()
            && !Object.values(errors).filter(Boolean).length)
            && !(this.state.mobile.trim() == "" && this.state.home.trim() == "")
        ) {
            let mailState = this.state.mailingState

            var stateInfo = this.state.states.filter(function (el) {
                return el.data === mailState;
            });

            let addressToVerify = {
                address1: this.state.mailingAddress,
                address2: this.state.mailingAddress2,
                city: this.state.city,
                state: stateInfo.length === 1 ? stateInfo[0].display : "",
                zip: this.state.zip
            }

            this.setState({
                addressToVerify: addressToVerify,
                showError: false
            });
            if (this.state.isShippingasMailing) {
                this.onSaveAddress();
            } else {
                verifyAddress(addressToVerify, '', this.onAddressVerified);
            }
        } else {
            this.setState({
                showError: true
            });
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onAddressVerified = (result) => {
        if (result.code === VerificationResponseCodes.addressVerified) {
            this.setState({
                showSuggestedAddressValidationModal: false
            }, () => this.onSaveAddress());
        } else if (result.code === VerificationResponseCodes.addressChanged) {
            this.setState({
                showSuggestedAddressValidationModal: true,
                verifiedAddress: result.returnedAddress
            });
        } else {
            this.setState({
                showInvalidAddressModal: true
            });
        }
    }

    onSaveAddress = () => {
        this.props.setNoErrors(true);
        this.save();
    }

    onContinueSaveAction = (selectedAddress) => {
        console.log(`LOG>> onContinueSaveAction: SelectedAddress: ${selectedAddress}`);

        if (selectedAddress === "suggestedAddress") {
            let newAddress = this.state.verifiedAddress;
            let suggestedState = this.state.states.find(state => state.display === newAddress.state);
            let state = this.state.mailingState;
            if (suggestedState) {
                state = suggestedState.data;
            }

            this.setState({
                showSuggestedAddressValidationModal: false,
                mailingAddress: (`${newAddress.address1} ${newAddress.suite}`).trim(),
                mailingAddress2: newAddress.address2,
                mailingState: state,
                city: newAddress.city,
                zip: newAddress.zip
            }, () => this.onSaveAddress());
        } else {
            this.setState({
                showSuggestedAddressValidationModal: false,
                showInvalidAddressModal: false
            }, () => this.onSaveAddress());
        }
    }

    onCloseAddressModal = () => {
        this.setState({
            showSuggestedAddressValidationModal: false,
            showInvalidAddressModal: false
        }, () => this.props.resetCanFileAClaim());
    }

    savePromise = () => {
        return new Promise((resolve, reject) => {
            const userName = this.state.name.trim();

            let [first, last] = userName.split(/ (.*)/);

            var data = this.state;
            data.state = this.state.mailingState;
            data.firstName = first;
            data.lastName = last;
            data.party.firstName = first;
            data.party.lastName = last;
            data.party.partyId = this.state.party.partyId;

            console.log('saving before resume');

            // no validation errors, let's save customer information

            fetch('party/'.concat(this.state.party.partyId).concat('/').concat(this.state.clientId),
                {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data.party)
                }).then(res => {
                    if (res.ok) {
                        res.json().then(newParty => {
                            resolve({ success: true, data: newParty });
                        });
                    }
                    else {
                        resolve({ success: false });
                    }
                });
        });
    }

    save = () => {
        // any validation errors

        if (this.validateFeilds()) {
            this.setState((prev) => {
                return { saving: !prev.saving }
            });

            console.log(this.state);
            var dealerProfile = this.context.getProfile();
            const userName = this.state.name.trim();
            let [first, last] = userName.split(/ (.*)/);

            var data = this.state;
            let party = data.party;
            let mobilePhone = party.partyPhoneModel.filter(pm => pm.phoneTypeId === 2)[0];
            let homePhone = party.partyPhoneModel.filter(hp => hp.phoneTypeId === 1)[0];

            if (this.state.mobile && typeof mobilePhone === "undefined") {
                mobilePhone = {
                    phoneTypeId: 2,
                    partyPhoneId: 0,
                    number: this.state.mobile,
                    partyId: this.state.party.partyId,
                }
                party.partyPhoneModel.push(mobilePhone);
            }

            if (this.state.home && typeof homePhone === "undefined") {
                homePhone = {
                    phoneTypeId: 1,
                    partyPhoneId: 0,
                    number: this.state.home,
                    partyId: this.state.party.partyId,
                }
                party.partyPhoneModel.push(homePhone);
            }


            if (typeof mobilePhone !== "undefined") {
                mobilePhone.number = this.state.mobile;
                mobilePhone.modifiedBy = dealerProfile.dealerName;
                if (this.state.primaryPhone === "mobile")
                    mobilePhone.isPrimary = true;
                else
                    mobilePhone.isPrimary = false;
            }


            if (typeof homePhone !== "undefined") {
                homePhone.number = this.state.home;
                homePhone.modifiedBy = dealerProfile.dealerName;
                if (this.state.primaryPhone === "alternate")
                    homePhone.isPrimary = true;
                else
                    homePhone.isPrimary = false;
            }

            if (typeof data.party.partyEmailModel[0] === "undefined") {
                data.party.partyEmailModel = [{
                    address: this.state.email,
                    partyId: this.state.party.partyId,
                    isPrimary: true
                }]
            }

            data.state = this.state.mailingState;
            data.firstName = first;
            data.lastName = last;
            data.party.modifiedBy = dealerProfile.dealerName;
            data.party.firstName = this.state?.party?.firstName;
            data.party.lastName = this.state?.party?.lastName;
            data.party.partyId = this.state.party.partyId;
            data.party.partyEmailModel[0].address = data.party.partyEmailModel.length > 0 ? this.state.email : data.party.partyEmailModel[0].address;
            data.party.partyEmailModel[0].modifiedBy = dealerProfile.dealerName;

            let billingAddress = data.party.partyAddressModel.filter(pam => pam.addressTypeId === 1)[0];
            if (typeof billingAddress === 'undefined') {
                billingAddress = {
                    partyAddressId: 0,
                    partyId: this.state.party.partyId,
                    addressTypeId: 1,
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    stateId: null,
                    zip: "",
                    zip4: null,
                    stateModel: null
                }
                data.party.partyAddressModel.push(billingAddress);
            } else {
                billingAddress.modifiedBy = dealerProfile.dealerName;
            }
            if (this.state.isShippingasMailing) {
                billingAddress.address1 = this.state.shippingAddress;
                billingAddress.address2 = this.state.shippingAddress2;
                billingAddress.city = this.state.shippingCity;
                billingAddress.stateId = this.state.shippingState;
                billingAddress.zip = this.state.shippingZip;
            } else {
                billingAddress.address1 = data.mailingAddress;
                billingAddress.address2 = data.mailingAddress2;
                billingAddress.city = data.city;
                billingAddress.stateId = data.mailingState;
                billingAddress.zip = data.zip;
            }
            console.log(data, '------------');

            // no validation errors, let's save customer information

            fetch('party/'.concat(this.state.party.partyId).concat('/').concat(this.state.clientId),
                {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data.party)
                }).then(res => {
                    if (res.ok) {
                        res.json().then(data => {
                            this.setState((prev) => {
                                return {
                                    party: data,
                                    editing: prev.clientId === 'apple' ? true : !prev.editing,
                                    saving: !prev.saving
                                }
                            });
                        });
                    }
                }).then(() => {
                    this.retrievePartyInfo(this.props.party.partyId);
                })
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    retrievePartyInfo = async (partyId) => {
        fetch('party/partydetails/'.concat(partyId).concat('/').concat(this.state.clientId))
            .then(res => res.json())
            .then(data => {
                this.setState({ party: data });
            });
    }

    handleAddressFormat = e => {
        const { name, value } = e.target;
        let fieldName = name;
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        let tempValue = value.trim();

        switch (fieldName) {
            case "name":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('customerName').value = tempValue;
                break;

            case "mailingAddress":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('mailingAddress').value = tempValue;
                break;

            case "mailingAddress2":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('mailingAddress2').value = tempValue;
                break;

            case "city":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('mailingCity').value = tempValue;
                break;

            case "shippingAddress":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('shippingAddress').value = tempValue;
                break;

            case "shippingAddress2":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('shippingAddress2').value = tempValue;
                break;

            case "shippingCity":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('shippingCity').value = tempValue;
                break;

            default:
                break;
        }
    }

    render() {
        const { clientId } = this.props;
        const { accountName } = this.context

        if (typeof this.state.party === "undefined") {
            return <div />
        }

        let chk = this.state.isShippingasMailing;

        let partyName = `${this.state.party.firstName === null ? '' : this.state.party.firstName} ${this.state.party.lastName === null ? '' : this.state.party.lastName}`;
        let billingAddress = this.state.party.partyAddressModel.filter(pam => pam.addressTypeId === 1)[0];
        let shippingAddress = this.state.party.partyAddressModel.filter(pam => pam.addressTypeId === 2)[0];
        let emailAddress = this.state.party.partyEmailModel.length > 0 ? this.state.party.partyEmailModel[0].address : "";
        let mailingAddress = this.state.party.partyAddressModel.filter(pam => pam.addressTypeId === 3)[0];
        let mobilePhone = this.state.party.partyPhoneModel.filter(pm => pm.phoneTypeId === 2)[0];
        let homePhone = this.state.party.partyPhoneModel.filter(hp => hp.phoneTypeId === 1)[0];
        let serviceAddress = this.state.party.partyAddressModel.filter(pam => pam.addressTypeId === 2)[0];
        let primaryPhone = this.state.primaryPhone;

        if (!this.state.states) {
            return <div />
        }
        return (
            <Card>
                <CardHeader>Customer Information
                    {!this.state.editing && <a onClick={this.toggleEdit}><PencilIcon alt="edit" className="edit" /></a>}
                </CardHeader>

                <CardBody>
                    {!this.state.editing &&
                        <Row className="customerData">
                            <Col sm="6" lg="3">
                                <ul className="address mb-0">
                                    <li>{partyName}</li>
                                    <li>{emailAddress}</li>
                                </ul>
                            </Col>

                            <Col sm="6" lg="3">
                                <ul className="noBullets  mb-0">
                                    <li>Mobile: <NumberFormat format="(###) ###-####" value={typeof mobilePhone === "undefined" ? "" : mobilePhone.number} displayType="text" /></li>
                                    <li>Alternate: <NumberFormat format="(###) ###-####" value={typeof homePhone === "undefined" ? "" : homePhone.number} displayType="text" /></li>
                                </ul>
                            </Col>

                            <Col sm="6" lg="3">
                                <ul className="address  mb-0">
                                    <li>Mailing Address</li>
                                    {typeof billingAddress !== "undefined" ?
                                        <>
                                            <li>{billingAddress.address1}</li>
                                            <li>{billingAddress.address2}</li>
                                            <li>{billingAddress.city}, {billingAddress.state} {billingAddress.zip}</li>
                                        </> :
                                        <>{typeof mailingAddress !== "undefined" ?
                                                <>
                                                    <li>{mailingAddress.address1}</li>
                                                    <li>{mailingAddress.address2}</li>
                                                    <li>{mailingAddress.city}, {mailingAddress.state} {mailingAddress.zip}</li>
                                                </>
                                                : null
                                            }
                                        </>
                                    }

                                </ul>
                            </Col>

                            <Col sm="6" lg="3">
                                <ul className="address">
                                    <li>Installation Address</li>

                                    {typeof serviceAddress !== "undefined" ?
                                        <>
                                            <li>{serviceAddress.address1}</li>
                                            <li>{serviceAddress.address2}</li>
                                            <li>{serviceAddress.city}, {serviceAddress.state} {serviceAddress.zip}</li>
                                        </>
                                        : null}
                                </ul>
                            </Col>
                        </Row>
                    }
                    {this.state.editing &&
                        <div className="customerForm">
                            <Form>
                                <Row className="fiveColWrapper">
                                    <FormGroup className="col-6 col-lg">
                                        <FormGroup className={this.state.errors.name ? "form-error" : ""}>
                                            <Label for="customerName" className="form-label">Name</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>

                                            <input type="text"
                                                defaultValue={partyName}
                                                onChange={this.handleInputChange}
                                                name="name"
                                                className="form-control"
                                                id="customerName"
                                                placeholder=""
                                                onBlur={this.handleAddressFormat}
                                                readOnly={true}
                                            />

                                            {this.state.errors.name === true && <div className="errorMsg">Name is required.</div>}
                                        </FormGroup>
                                    </FormGroup>

                                    <FormGroup className="col-6 col-lg">
                                        <FormGroup className={this.state.errors.email ? "form-error" : ""}>
                                            <Label for="customerEmail" className="form-label">Email</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>

                                            <input type="email"
                                                defaultValue={emailAddress}
                                                onChange={this.handleInputChange}
                                                name="email"
                                                className="form-control"
                                                id="customerEmail"
                                                placeholder=""
                                            />

                                        {this.state.errors.email && 
                                            (
                                                <div className="errorMsg">
                                                    Please enter a valid email address.
                                                </div>
                                            )
                                        }
                                    </FormGroup>
                                    </FormGroup>

                                    <FormGroup className="col-6 col-lg form-group">
                                        <FormGroup className={this.state.pristineState && this.state.showError && 
                                            ((this.state.errors.mobile && this.state.home.trim() === "") || 
                                            (this.state.errors.home && this.state.mobile.trim() === "" && this.state.errors.repeatingNumMobile) || 
                                            (this.state.mobile.trim() != "" && this.validator.message("mobile", this.state.mobile, "phone")) || 
                                            this.state.errors.duplicateMobile ||                                             
                                            (this.state.errors.mobile && this.state.errors.repeatingNumMobile)) ? 
                                            "form-error" : ""}>
                                            <Label for="customerMobile" className="form-label">Mobile Phone</Label>
                                            <Cleave 
                                                placeholder=""
                                                name="mobile"
                                                onChange={this.handleInputChange}
                                                value={typeof mobilePhone === "undefined" ? "" : mobilePhone.number}
                                                className="form-control"
                                                id="customerMobile"
                                                options={{ 
                                                    blocks: [0, 3, 3, 4], 
                                                    delimiters: ['(', ') ', '-'], 
                                                    numericOnly: true 
                                                }}
                                            />
                                            {this.state.pristineState && this.state.showError && 
                                                (
                                                    (this.state.primaryPhone !== undefined && 
                                                        (this.state.primaryPhone !== "alternate" && 
                                                            this.state.mobile.trim() === "" && this.state.home.trim() === "")) &&
                                                        <div className="errorMsg" >
                                                            Please enter at least one phone number.
                                                        </div>
                                                )
                                            }
                                            {this.state.pristineState && this.state.showError && 
                                                this.state.errors.mobile === false && 
                                                (this.state.mobile.length === 10 || this.state.mobile.length < 14) &&
                                                this.validator.message("mobile", this.state.mobile, "phone") && (
                                                    <div className="errorMsg">
                                                        Please enter a valid phone number.
                                                    </div>
                                                )
                                            }
                                            {(this.state.errors.duplicateMobile || (this.state.errors.mobile && this.state.errors.repeatingNumMobile)) && this.state.showError && 
                                                <div className="errorMsg">
                                                    Please enter a valid phone number.
                                                </div>
                                            }
                                        </FormGroup>
                                    </FormGroup>

                                    <FormGroup className="col-6 col-lg form-group">
                                        <FormGroup className={this.state.pristineState && this.state.showError && 
                                            (this.state.primaryPhone !== undefined && 
                                                (this.state.primaryPhone === "alternate" && 
                                                    this.state.mobile.trim() === "" && this.state.home.trim() === "") ? "form-error" : 
                                                    (this.state.errors.duplicateHome ? "form-error" : 
                                                    !this.validator.check(this.state.home, "phone") ? "form-error" : 
                                                    (this.state.errors.home && this.state.errors.repeatingNumHome) ? "form-error" : ""
                                            ))}
                                        >
                                            <Label for="customerPhone" className="form-label">Alternate Phone</Label>

                                            <Cleave 
                                                placeholder=""
                                                name="home"
                                                onChange={this.handleInputChange}
                                                value={typeof homePhone === "undefined" ? "" : homePhone.number}
                                                className="form-control"
                                                id="customerPhone"                                            
                                                options={{ 
                                                    blocks: [0, 3, 3, 4], 
                                                    delimiters: ['(', ') ', '-'], 
                                                    numericOnly: true 
                                                }}
                                            />
                                            {this.state.pristineState && this.state.showError && 
                                                (
                                                    (this.state.primaryPhone !== undefined && 
                                                        (this.state.primaryPhone === "alternate" && 
                                                            this.state.mobile.trim() === "" && this.state.home.trim() === "")) &&
                                                        <div className="errorMsg" >
                                                            Please enter at least one phone number.
                                                        </div>
                                                )
                                            }
                                            {this.validator.message("home", this.state.home, "phone") && this.state.showError && (
                                                    <div className="errorMsg">
                                                        Please enter a valid phone number.
                                                    </div>
                                                )
                                            }
                                            {(this.state.errors.duplicateHome || (this.state.errors.home && this.state.errors.repeatingNumHome)) && this.state.showError && 
                                                <div className="errorMsg">
                                                    Please enter a valid phone number.
                                                </div>
                                            }
                                        </FormGroup>
                                    </FormGroup>

                                    <FormGroup className="col-6 col-lg form-group">
                                        <Label for="phonePreference" className="form-label">Primary Phone Preference</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>

                                        <DropDownInput name="state1"
                                            options={this.ppp}
                                            className="form-control"
                                            Value={this.state.primaryPhone}
                                            onChangedValue={this.handlePhonePreference}
                                            onBlur={this.handlePhonePreference}
                                            placeholder="Please select..."
                                            defaultValue={primaryPhone}
                                        />

                                        {this.validator.message("primaryPhone", this.state.primaryPhone, "required")}
                                    </FormGroup>
                                </Row>

                                <Row>
                                    <h3 className="col">Address Information</h3>
                                </Row>

                                <Row className="fiveColWrapper">
                                    <FormGroup className="col-6 col-lg mb-0">
                                        <FormGroup className={this.state.errors.mailingAddress ? "form-error" : ""}>
                                            <Label for="mailingAddress" className="form-label">Mailing Address</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>

                                            <input type="text"
                                                className="form-control"
                                                onChange={this.handleInputChange}
                                                name="mailingAddress"
                                                defaultValue={typeof billingAddress !== "undefined" ? billingAddress.address1 : typeof mailingAddress !== "undefined" ? mailingAddress.address1 : ""}
                                                id="mailingAddress"
                                                maxLength={150}
                                                onBlur={this.handleAddressFormat}
                                                placeholder=""
                                            />

                                            {this.state.errors.mailingAddress === true && <div className="errorMsg">Mailing address is required.</div>}
                                        </FormGroup>
                                    </FormGroup>

                                    <FormGroup className="col-6 col-lg form-group mb-0">
                                        <Label for="mailingAddress2" className="form-label">Apt., Suite, Building</Label>
                                        <input type="text"
                                            className="form-control"
                                            onChange={this.handleInputChange}
                                            name="mailingAddress2"
                                            id="mailingAddress2"
                                            maxLength={50}
                                            onBlur={this.handleAddressFormat}
                                            defaultValue={typeof billingAddress !== "undefined" ? billingAddress.address2 : typeof mailingAddress !== "undefined" ? mailingAddress.address2 : ""}
                                        />
                                    </FormGroup>

                                    <FormGroup className="col-6 col-lg form-group mb-0">
                                        <FormGroup className={this.state.errors.mailingCity ? "form-error" : ""}>
                                            <Label for="mailingCity" className="form-label">City</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>

                                            <input type="text"
                                                defaultValue={typeof billingAddress !== "undefined" ? billingAddress.city : typeof mailingAddress !== "undefined" ? mailingAddress.city : ""}
                                                name="city"
                                                onChange={this.handleInputChange}
                                                className="form-control"
                                                id="mailingCity"
                                                maxLength={50}
                                                onBlur={this.handleAddressFormat}
                                                placeholder=""
                                            />

                                            {this.state.errors.mailingCity === true && <div className="errorMsg">City is required.</div>}
                                        </FormGroup>
                                    </FormGroup>

                                    <FormGroup className="col-6 col-lg form-group mb-0">
                                        <Label for="mailingState" className="form-label">State</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>

                                        <DropDownAutoComplete 
                                            options={this.state.states}
                                            className="form-control"
                                            name={'mailingState'}
                                            onChangedValue={this.handleStateChange}
                                            onBlur={this.handleStateChange}
                                            placeholder="Please select..."
                                            defaultValue={(typeof billingAddress !== "undefined" && billingAddress.stateId !== null) ? this.state.mailingState : (typeof mailingAddress !== "undefined" && mailingAddress.stateId !== null) ? mailingAddress.stateId : undefined}
                                            value={this.state.mailingState}
                                        />

                                        {this.validator.message("state", this.state.mailingState, "required")}
                                        { /* <div className="errorMsg">State is required.</div> */}
                                        </FormGroup>

                                    <FormGroup className="col-6 col-lg form-group mb-0">
                                        <FormGroup className={(this.state.errors.mailingZip || this.state.errors.invalidMailingZip) ? "form-error" : ""}>
                                            <Label for="mailingPostal" className="form-label">Zip/Postal</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>

                                            <input type="text"
                                                defaultValue={typeof billingAddress !== "undefined" ? billingAddress.zip : typeof mailingAddress !== "undefined" ? mailingAddress.zip : ""}
                                                onChange={this.handleInputChange}
                                                name="zip"
                                                className="form-control"
                                                id="mailingPostal"
                                                maxLength={10}
                                                placeholder=""
                                            />

                                            {this.state.errors.mailingZip === true && <div className="errorMsg">Zip/Postal is required.</div>}
                                            {this.state.errors.invalidMailingZip === true && <div className="errorMsg">Zip/Postal is invalid.</div>}
                                        </FormGroup>
                                    </FormGroup>
                                </Row>

                                <Row className="col">
                                    <div className="checkbox">
                                        <input className="mr-2"
                                            checked={this.state.isShippingasMailing}
                                            name="isShippingasMailing"
                                            type="checkbox"
                                            id="shippingSameMailing"
                                            onChange={this.sameasMailingChangeEvent}
                                        />

                                        <Label for="shippingSameMailing" className={classNames("checkboxLabel", accountName)}>Same as Installation Address</Label>
                                    </div>
                                </Row>

                                    <div>
                                        <Row>
                                            <Col sm="6" lg="3">
                                                <ul className="address">
                                                    <li>Installation Address</li>

                                                {typeof serviceAddress !== "undefined" ?
                                                    <>
                                                        <li>{serviceAddress.address1}</li>
                                                        <li>{serviceAddress.address2}</li>
                                                        <li>{serviceAddress.city}, {serviceAddress.state} {serviceAddress.zip}</li>
                                                    </>
                                                    : null}
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                             
                            <Row className="col">
                                <p>The installation address cannot be updated.  Please contact {getBrandContent(accountName).programName} support at <a href="tel:{getBrandContent(accountName).programPhone}" className="callLink">{getBrandContent(accountName).programPhone}</a> to make a correction.</p>
                            </Row>

                                <Row>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col></Col>
                                </Row>
                            </Form>
                        </div>
                    }
                </CardBody>

                {this.state.editing && clientId !== 'apple' &&
                    <CardFooter>
                        <button className="btnLink" onClick={this.toggleEdit}>Cancel</button>
                        <button className="btn-primary btnSave" onClick={this.verifyAddress}>
                            <Spinner color="light" size="sm" animation={this.state.saving ? "border" : ""} />
                            {this.state.saving ? "" : "Save"}
                        </button>
                    </CardFooter>
                }

                {this.state.showSuggestedAddressValidationModal &&
                    <SuggestedAddress
                        showSuggestedAddressValidationModal={this.state.showSuggestedAddressValidationModal}
                        enteredAddress={this.state.addressToVerify}
                        suggestedAddress={this.state.verifiedAddress}
                        onContinueAction={this.onContinueSaveAction}
                        onEditAddress={this.onCloseAddressModal} />}

                {this.state.showInvalidAddressModal &&
                    <InvalidAddress
                        showInvalidAddressModal={this.state.showInvalidAddressModal}
                        onEditAddress={this.onCloseAddressModal}
                        onContinueAction={this.onContinueSaveAction} />}
            </Card>
        );
    }
}

CustomerInfo.contextType = SessionContext