/**
 * USE THIS NEW BRAND TEMPLATE TO CREATE NEW BRANDS.
 * COPY AND PASTE INTO 'BRANDS' OBJECT BELOW, THEN 
 * UPDATE ANY NEEDED VALUES BASED ON STYLE GUIDE.
 */
const newAccountTemplate = { 
    accoumntName: {
        colors:{
            primaryBrand: null,
            secondaryBrand: null,
            primaryText: null,
            error: null,
            focus: null,
            advantageAccent: null,
            advantageText: null,
            neutral1: '#f5f5f5',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: '#dee9f6',        // DO NOT ALLOW CUSTOMIZATION
            neutral3: '#b9c6d4',        // DO NOT ALLOW CUSTOMIZATION
            neutral4: '#5d738d',
            neutral5: '#616160',        // DO NOT ALLOW CUSTOMIZATION
            accent1: null,
            accent2: null,
            accent3: null,
            accent4: null
        },
        type: {
            textFont: null,
            fontSize: null,
            lineHeight: null,
            linkColor: null,                        // Controlled by other variable
            iconColor: null,                        // Controlled by other variable
            letterSpacing: null,
        },
        h: {
            headingFont: null,
            headingFontWeight: null,
            headingColor: null,                     // Controlled by other variable
            h1Size: null,
            h1LineHeight: null,
            h1FontWeight: null,
            h2Size: null,
            h2LineHeight: null,
            h3Size: null,
            h3LineHeight: null,
            h4Size: null,
            h4LineHeight: null,
            h5Size: null,
            h5LineHeight: null,
            h5Weight: null,
            h6Size: null,
            h6LineHeight: null,
            h6Weight: null,
        },
        btn: {
            andInputFontSize: null,              // Currently Static
            borderRadius: null,
            padding: null,
            lineHeight: null,                    // Currently Static
            primaryBg: null,
            primaryText: null,
            primaryBorder: null,
            primaryHoverBg: null,
            primaryHoverBorder: null,
            primaryHoverText: null,
            secondaryBg: null,
            secondaryBorder: null,
            secondaryText: null,
            secondaryHoverBg: null,
            secondaryHoverBorder: null,
            secondaryHoverText: null,
            linkText: null,
            disabledBg: null,
            disabledBorder: null,
            disabledText: null,
        }, 
        input: {
            labelColor: null,                  // Currently Static
            labelLineHeight: null,             // Currently Static
            labelMarginBottom: null,           // Currently Static
            placeholder: null,
            requiredAsterisk: null,
            borderRadius: null,                    // Currently Static
            lineHeight: null,                  // Currently Static
            border: null,  
            LabelWeight: null,
            entered: null,                     // Controlled by other variable
            focusBorder: null,
            errorColor: null,                  // Controlled by other variable
            errorBg: null,
            disabledBg: null,      
            disabledText: null,
            disabledBorder: null,
            icon: null,                        // Controlled by other variable
            iconHeight: null,                  // Currently Static
            iconWidth: null, 
        },
        miscInput: {
            toggleColor: null,
            checkboxBorder: null,    // Controlled by other variable
            checkboxHeight: null,                   // Currently Static
            checkboxWidth: null,                    // Currently Static
            checkboxCheckedBg: null,                // Controlled by other variable
            checkboxCheckColor: null,
    
            dropdownArrowColor: null,               // Controlled by other variable
            dropdownOptionHoverBg: null,            // Controlled by other variable

            radioButtonBorder: null,
            radioButtonBg: null,
            radioButtonCheckedBorder: null,
            radioButtonCheckedBg: null,
        },
        table: {
            rowBg: null,  
        },
        tabs: {
            activeTabColor: null,
            activeTabText: null,
        },
        sideNav: {
            bg: null,                        // Controlled by other variable
            textColor: null,
            activeSideLine: null,                  // Controlled by other variable
            activeBg: null,
            activeText: null,
        },
        mainNav: {
            icon: null,
            color: null,
            textColor: null,
        }
    },
} 

export const accounts = {
    // AIG Values are defined with variable definitions. useWhiteLabelHook returns before overrides if brand === 'AIG'
    AIG: null,
    lennox: {
        colors:{
            primaryBrand: '#666666',
            secondaryBrand: '#222222',
            primaryText: 'var(--PrimaryBrandColor)',
            advantageAccent: '#c60c35',
            advantageText: 'white',
            neutral1: '#efefef',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: '#e7e7e7',        // DO NOT ALLOW CUSTOMIZATION
            neutral3: '#dcdcdc',        // DO NOT ALLOW CUSTOMIZATION
            neutral4: '#b1b1b1',        // DO NOT ALLOW CUSTOMIZATION
            neutral5: '#616160',
            accent1: 'var(--AdvantageAccentColor)',
            accent2: 'var(--AdvantageAccentColor)',
            accent3: 'var(--AdvantageAccentColor)',
            accent4: 'var(--AdvantageAccentColor)'
        },
        type: {
            linkColor: '#1352de',
            iconColor: 'var(--AdvantageAccentColor)',
            iconColorYes: '#4EA54F',
            iconColorNo: '#E81944'
        },
        h: {
            headingColor: 'var(--SecondaryBrandColor)',
            h5Weight: 'var(--Heading__FontWeight)',
            h6Weight: 'var(--Heading__FontWeight)',

        },
        btn: {
            borderRadius: '0px',
            primaryBg: 'var(--AdvantageAccentColor)',
            primaryBorder: '1px solid var(--AdvantageAccentColor)',
            primaryHoverBg: 'white',
            primaryHoverText: 'var(--AdvantageAccentColor)',
            primaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            secondaryBg: 'white',
            secondaryBorder: '1px solid var(--SecondaryBrandColor)',
            secondaryText: 'var(--AdvantageAccentColor)',
            secondaryHoverBg: 'var(--AdvantageAccentColor)',
            secondaryHoverText: 'white',
            secondaryHoverBorder: '1px solid var(--AdvantageAccentColor',
            linkText: 'var(--LinkColor)',
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
            disabledText: 'var(--Color__Neutral4)',
        }, 
        input: {
            labelColor: 'var(--PrimaryBrandColor)',                  // Currently Static
            placeholder: 'var(--Color__Neutral3)',
            border: 'var(--Color__Neutral4)',  
            borderRadius: 'var(--Btn__BorderRadius)',                    // Currently Static
            icon: 'var(--advantageAccent)',                        // Controlled by other variable
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral4)',
        },
        miscInput: {
            toggleColor: 'var(--AdvantageAccentColor)',
            checkboxBorder: 'var(--Input__Border)',    // Controlled by other variable
            checkboxCheckedBg: 'white',                // Controlled by other variable
            checkboxCheckColor: 'var(--AdvantageAccentColor)',
    
            dropdownArrowColor: 'var(--AdvantageAccentColor)',               // Controlled by other variable
            dropdownOptionHoverBg: 'var(--AdvantageAccentColor)',            // Controlled by other variable

            radioButtonBorder: 'var(--Input__Border)',
            radioButtonBg: 'white',
            radioButtonCheckedBorder: 'var(--AdvantageAccentColor)',
            radioButtonCheckedBg: 'var(--AdvantageAccentColor)',

            radioWithBgBorder: 'var(--Input__Border)',
            radioWithBgBg: 'white',
            radioWithBgText: 'var(--PrimaryTextColor)',
            radioWithBgCheckedBorder: 'var(--SecondaryBrandColor)',
            radioWithBgCheckedBg: 'var(--Color__Neutral2)',
            radioWithBgCheckedText: 'var(--SecondaryBrandColor)',
            activeSideTabBg: 'var(--Color__Neutral2)',
            activeSideTabBorder: 'var(--SecondaryBrandColor)',
        },
        table: {
            rowBg: 'var(--Color__Neutral1)',  
        },
        tabs: {
            activeTabColor: 'var(--AdvantageAccentColor)',
            activeTabText: 'var(--PrimaryTextColor)'
        },
        sideNav: {
            bg: 'white',                        // Controlled by other variable
            textColor: 'var(--PrimaryBrandColor)',
            activeSideLine: 'var(--AdvantageAccentColor)',                  // Controlled by other variable
            activeBg: 'white',
            activeText: 'var(--AdvantageAccentColor)',
        },
        mainNav: {
            icon: 'var(--PrimaryBrandColor)', 
            color: 'var(--PrimaryBrandColor)',
            activeColor: 'var(--Heading__Color)',
        },
        scriptBox: { 
          border: 'var(–-SecondaryBrandColor)',
          bg: 'white'
        }
    },
    homeDepot: {
        // ...
    }, 
    bestBuy: {
        // ...
    },
    rheem: {
      colors:{
          primaryBrand: '#093266',
          secondaryBrand: '#807F83',
          primaryText: '#63666A',
          advantageAccent: '#FECB8B',
          advantageText: 'var(--PrimaryBrandColor)',
          neutral1: '#efefef',        // DO NOT ALLOW CUSTOMIZATION
          neutral2: '#e7e7e7',        // DO NOT ALLOW CUSTOMIZATION
          neutral3: '#dcdcdc',        // DO NOT ALLOW CUSTOMIZATION
          neutral4: '#b1b1b1',        // DO NOT ALLOW CUSTOMIZATION
          neutral5: '#616160',
          accent1: 'var(--PrimaryBrandColor)',
          accent2: 'var(--PrimaryBrandColor)',
          accent3: 'var(--PrimaryBrandColor)',
          accent4: 'var(--PrimaryBrandColor)'
      },
      type: {
          linkColor: '#1352de',
          iconColor: 'var(--PrimaryBrandColor)',
          iconColorYes: '#4EA54F',
          iconColorNo: '#E81944'
      },
      h: {
          headingColor: 'var(--PrimaryBrandColor)',
          h5Weight: 'var(--Heading__FontWeight)',
          h6Weight: 'var(--Heading__FontWeight)',
      },
      btn: {
          borderRadius: '3px',
          primaryBg: 'var(--PrimaryBrandColor)',
          primaryBorder: '1px solid var(--PrimaryBrandColor)',
          primaryHoverBg: '#336DB5',
          primaryHoverText: 'white',
          primaryHoverBorder: '1px solid #336DB5',
          secondaryBg: 'white',
          secondaryBorder: '1px solid var(--SecondaryBrandColor)',
          secondaryText: 'var(--PrimaryBrandColor)',
          secondaryHoverBg: 'var(--SecondaryBrandColor)',
          secondaryHoverText: 'white',
          secondaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
          linkText: 'var(--LinkColor)',
          disabledBg: 'var(--Color__Neutral1)',
          disabledBorder: '1px solid var(--Color__Neutral3)',
          disabledText: 'var(--Color__Neutral4)',
      }, 
      input: {
          labelColor: 'var(--PrimaryBrandColor)',                  // Currently Static
          placeholder: 'var(--Color__Neutral3)',
          border: '#807F83',  
          borderRadius: '3px',                    // Currently Static
          icon: 'var(--IconColor)',                        // Controlled by other variable
          disabledBg: 'var(--Color__Neutral1)',
          disabledBorder: '1px solid var(--Color__Neutral3)',
      },
      miscInput: {
          toggleColor: 'var(--PrimaryBrandColor)',
          checkboxBorder: 'var(--Input__Border)',    // Controlled by other variable
          checkboxCheckedBg: 'var(--PrimaryBrandColor)',                // Controlled by other variable
          checkboxCheckColor: 'white',
  
          dropdownArrowColor: 'var(--PrimaryBrandColor)',               // Controlled by other variable
          dropdownOptionHoverBg: 'var(--PrimaryBrandColor)',            // Controlled by other variable

          radioButtonBorder: 'var(--Input__Border)',
          radioButtonBg: 'white',
          radioButtonCheckedBorder: 'var(--PrimaryBrandColor)',
          radioButtonCheckedBg: 'var(--PrimaryBrandColor)',
          radioWithBgBorder: 'var(--Input__Border)',
          radioWithBgBg: 'var(--Color__Neutral1)',
          radioWithBgText: 'var(--PrimaryTextColor)',
          radioWithBgCheckedBorder: 'var(--SecondaryBrandColor)',
          radioWithBgCheckedBg: 'var(--Color__Neutral3)',
          radioWithBgCheckedText: 'var(--PrimaryBrandColor)',
          activeSideTabBg: 'var(--Color__Neutral2)',
          activeSideTabBorder: 'var(--SecondaryBrandColor)',
      },
      table: {
          rowBg: 'var(--Color__Neutral1)',
      },
      tabs: {
          activeTabColor: 'var(--PrimaryBrandColor)',
          activeTabText: 'var(--PrimaryTextColor)',

      },
      sideNav: {
          bg: 'var(--PrimaryBrandColor)',                        // Controlled by other variable
          textColor: 'white',
          activeSideLine: 'var(--SecondaryBrandColor)',                  // Controlled by other variable
          activeBg: '#002553',
          activeText: 'white',
      },
      mainNav: {
          icon: 'var(--PrimaryBrandColor)', 
          color: 'var(--PrimaryBrandColor)',
          activeColor: 'var(--Heading__Color)',
      },
      scriptBox: {
        border: 'var(–-SecondaryBrandColor)',
        bg: 'var(--Color__Neutral1)'
      }
    },
    carrier: {
      colors:{
          primaryBrand: '#224694',
          secondaryBrand: '#AF2025',
          primaryText: '#63666A',
          advantageAccent: 'var(--SecondaryBrandColor)',
          advantageText: 'white',
          neutral1: '#efefef',
          neutral2: '#e7e7e7',
          neutral3: '#dcdcdc',
          neutral4: '#b1b1b1',
          neutral5: '#616160',
          accent1: 'var(--PrimaryBrandColor)',
          accent2: 'var(--PrimaryBrandColor)',
          accent3: 'var(--PrimaryBrandColor)',
          accent4: 'var(--PrimaryBrandColor)'
      },
      type: {
          linkColor: '#1352de',
          iconColor: 'var(--SecondaryBrandColor)',
          iconColorYes: '#13AA41',
          iconColorNo: '#E81944'
      },
      h: {
          headingColor: 'var(--PrimaryBrandColor)',
          h5Weight: 'var(--Heading__FontWeight)',
          h6Weight: 'var(--Heading__FontWeight)',
      },
      btn: {
          borderRadius: '0px',
          primaryBg: 'var(-- PrimaryBrandColor)',
          primaryBorder: '1px solid var(--PrimaryBrandColor)',
          primaryHoverBg: '#1352DE',
          primaryHoverText: 'white',
          primaryHoverBorder: '1px solid #1352DE',
          secondaryBg: 'white',
          secondaryBorder: '1px solid var(--Color__Neutral4)',
          secondaryText: 'var(—PrimaryBrandColor)',
          secondaryHoverBg: '#1352DE',
          secondaryHoverText: 'white',
          secondaryHoverBorder: '1px solid #1352DE',
          linkText: 'var(--LinkColor)',
          disabledBg: 'var(--Color__Neutral1)',
          disabledBorder: '1px solid var(--Color__Neutral3)',
          disabledText: 'var(--Color__Neutral4)',
      }, 
      input: {
          labelColor: 'var(--PrimaryBrandColor)',                  // Currently Static
          placeholder: 'var(--PrimaryTextColor)',
          border: 'var(--Color__Neutral4)',
          borderRadius: 'var(--Btn__BorderRadius)',                    // Currently Static
          icon: 'var(--IconColor)',                        // Controlled by other variable
          disabledBg: 'var(--Color__Neutral1)',
          disabledBorder: '1px solid var(--Color__Neutral3)',
      },
      miscInput: {
          toggleColor: 'var(--PrimaryBrandColor)',
          checkboxBorder: 'var(--Input__Border)',    // Controlled by other variable
          checkboxCheckedBg: 'var(--SecondaryBrandColor)',                // Controlled by other variable
          checkboxCheckColor: 'white',
          dropdownArrowColor: 'var(--Color__Neutral4)',               // Controlled by other variable
          dropdownOptionHoverBg: 'var(--PrimaryBrandColor)',            // Controlled by other variable
          radioButtonBorder: 'var(--Input__Border)',
          radioButtonBg: 'white',
          radioButtonCheckedBorder: 'var(--SecondaryBrandColor)',
          radioButtonCheckedBg: 'var(--SecondaryBrandColor)',
          radioWithBgBorder: 'var(--Input__Border)',
          radioWithBgBg: 'white',
          radioWithBgText: 'var(--PrimaryTextColor)',
          radioWithBgCheckedBorder: 'var(--Color__Neutral5)',
          radioWithBgCheckedBg: 'var(--Color__Neutral3)',
          radioWithBgCheckedText: 'var(--PrimaryTextColor)',
          activeSideTabBg: 'var(--Color__Neutral3)',
          activeSideTabBorder: '#000000',
      },
      table: {
          rowBg: 'var(--Color__Neutral1)',
      },
      tabs: {
          activeTabColor: 'var(--PrimaryBrandColor)',
          activeTabText: 'var(--PrimaryTextColor)'
      },
      sideNav: {
          bg: 'var(--PrimaryBrandColor)',                        // Controlled by other variable
          textColor: 'white',
          activeSideLine: 'var(--Color__Neutral5)',                  // Controlled by other variable
          activeBg: '#173677',
          activeText: 'white',
      },
      mainNav: {
          icon: 'var(--IconColor)',
          color: 'var(--PrimaryBrandColor)',
          activeColor: 'var(--IconColor)',
      },
      scriptBox: {
        border: 'var(--PrimaryBrandColor)',
        bg: 'var(--Color__Neutral1)'
    }
    },
    trane: {
      colors:{
          primaryBrand: '#0067B1',
          secondaryBrand: '#F26649',
          primaryText: '#63666A',
          advantageAccent: 'var(--SecondaryBrandColor)',
          advantageText: 'white',
          neutral1: '#efefef',
          neutral2: '#e7e7e7',
          neutral3: '#dcdcdc',
          neutral4: '#b1b1b1',
          neutral5: '#616160',
          accent1: 'var(--PrimaryBrandColor)',
          accent2: 'var(--PrimaryBrandColor)',
          accent3: 'var(--PrimaryBrandColor)',
          accent4: 'var(--PrimaryBrandColor)',
      },
      type: {
          linkColor: '#1352de',
          iconColor: 'var(--SecondaryBrandColor)',
          iconColorYes: '#13AA41',
          iconColorNo: '#E81944'
      },
      h: {
          headingColor: 'var(--PrimaryBrandColor)',
          h5Weight: 'var(--Heading__FontWeight)',
          h6Weight: 'var(--Heading__FontWeight)',
      },
      btn: {
          borderRadius: '0px',
          primaryBg: 'var(--PrimaryBrandColor)',
          primaryBorder: '1px solid var(--PrimaryBrandColor)',
          primaryHoverBg: 'white',
          primaryHoverText: 'var(--PrimaryBrandColor)',
          primaryHoverBorder: '1px solid var(--Color__Neutral4)',
          secondaryBg: 'white',
          secondaryBorder: '1px solid var(--Color__Neutral4)',
          secondaryText: 'var(--PrimaryBrandColor)',
          secondaryHoverBg: 'var(--PrimaryBrandColor)',
          secondaryHoverText: 'white',
          secondaryHoverBorder: '1px solid var(--PrimaryBrandColor)',
          linkText: 'var(--LinkColor)',
          disabledBg: 'var(--Color__Neutral1)',
          disabledBorder: '1px solid var(--Color__Neutral3)',
          disabledText: 'var(--Color__Neutral4)',
      }, 
      input: {
          labelColor: 'var(--PrimaryBrandColor)',                  // Currently Static
          placeholder: 'var(--PrimaryTextColor)',
          border: 'var(--Color__Neutral4)',
          borderRadius: 'var(--Btn__BorderRadius)',                    // Currently Static
          icon: 'var(--IconColor)',                        // Controlled by other variable
          disabledBg: 'var(--Color__Neutral1)',
          disabledBorder: '1px solid var(--Color__Neutral3)',
      },
      miscInput: {
          toggleColor: 'var(--PrimaryBrandColor)',
          checkboxBorder: 'var(--Input__Border)',    // Controlled by other variable
          checkboxCheckedBg: 'var(--SecondaryBrandColor)',                // Controlled by other variable
          checkboxCheckColor: 'white',
          dropdownArrowColor: 'var(--Color__Neutral4)',               // Controlled by other variable
          dropdownOptionHoverBg: 'var(--PrimaryBrandColor)',            // Controlled by other variable
          radioButtonBorder: 'var(--Input__Border)',
          radioButtonBg: 'white',
          radioButtonCheckedBorder: 'var(--SecondaryBrandColor)',
          radioButtonCheckedBg: 'var(--SecondaryBrandColor)',
          radioWithBgBorder: 'var(--Input__Border)',
          radioWithBgBg: 'white',
          radioWithBgText: 'var(--PrimaryTextColor)',
          radioWithBgCheckedBorder: 'var(--Color__Neutral5)',
          radioWithBgCheckedBg: 'var(--Color__Neutral3)',
          radioWithBgCheckedText: 'var(--PrimaryTextColor)',
      },
      table: {
          rowBg: 'var(--Color__Neutral1)',
      },
      tabs: {
          activeTabColor: 'var(--PrimaryBrandColor)',
          activeTabText: 'var(--PrimaryTextColor)',
      },
      sideNav: {
          bg: 'var(--PrimaryBrandColor)',                        // Controlled by other variable
          textColor: 'white',
          activeSideLine: 'var(--Color__Neutral5)',                  // Controlled by other variable
          activeBg: '#173677',
          activeText: 'white',
      },
      mainNav: {
          icon: 'var(--IconColor)',
          color: 'var(--PrimaryBrandColor)',
          activeColor: 'var(--IconColor)',
      },
      scriptBox: { 
        // border: '1px solid var(--Color__Neutral5)', 
        border: 'var(--Color__Neutral5)', 
        bg: 'var(--Color__Neutral1)'
      },

    },
    ge: {
        colors:{
            primaryBrand: '#003B71',
            secondaryBrand: '#1352DE',
            primaryText: '#343741',
            advantageAccent: '#F1BA55',
            advantageText: 'var(--PrimaryBrandColor)',
            neutral1: '#F6F9FB',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: '#DEE9F6',        // DO NOT ALLOW CUSTOMIZATION
            neutral3: '#B9C6D4',        // DO NOT ALLOW CUSTOMIZATION
            neutral4: '#5D738D',        // DO NOT ALLOW CUSTOMIZATION
            neutral5: '#616160',
            accent1: '#92B3E0',
            accent2: 'var(--Color__Accent1)',
            accent3: 'var(--Color__Accent1)',
            accent4: 'var(--Color__Accent1)'
        },
        type: {
            linkColor: 'var(--SecondaryBrandColor)',
            iconColor: 'var(--SecondaryBrandColor)',
            iconColorYes: '#4EA54F', 
            iconColorNo: '#E81944' 
        },
        h: {
            headingColor: 'var(--PrimaryBrandColor)',
            h5Weight: 'var(--Heading__FontWeight)',
            h6Weight: 'var(--Heading__FontWeight)',

        },
        btn: {
            borderRadius: '0px',
            primaryBg: 'var(--PrimaryBrandColor)',
            primaryBorder: '1px solid var(--PrimaryBrandColor)',
            primaryHoverBg: 'var(--SecondaryBrandColor)',
            primaryHoverText: 'white',
            primaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            secondaryBg: 'white',
            secondaryBorder: '1px solid var(--Color__Neutral3)',
            secondaryText: 'var(--PrimaryBrandColor)',
            secondaryHoverBg: 'var(--SecondaryBrandColor)',
            secondaryHoverText: 'white',
            secondaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            linkText: 'var(--LinkColor)',
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
            disabledText: '#5D738D',
        }, 
        input: {
            labelColor: 'var(--PrimaryBrandColor)',                  // Currently Static
            placeholder: 'var(--Color__Neutral3)',
            border: 'var(--Color__Neutral3)',  
            borderRadius: 'var(--Btn__BorderRadius)',                    // Currently Static
            icon: 'var(--IconColor)',                        // Controlled by other variable
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
        },
        miscInput: {
            toggleColor: 'var(--SecondaryBrandColor)',
            checkboxBorder: 'var(--Input__Border)',    // Controlled by other variable
            checkboxCheckedBg: 'linear-gradient(225deg, #1352de, #001871)',                // Controlled by other variable
            checkboxCheckColor: 'white',
    
            dropdownArrowColor: 'var(--PrimaryBrandColor)',               // Controlled by other variable
            dropdownOptionHoverBg: 'var(--SecondaryBrandColor)',            // Controlled by other variable

            radioButtonBorder: 'var(--Input__Border)',
            radioButtonBg: 'white',
            radioButtonCheckedBorder: 'var(--SecondaryBrandColor)',
            radioButtonCheckedBg: 'linear-gradient(225deg, #1352de, #001871)',

            radioWithBgBorder: 'var(--Input__Border)',
            radioWithBgBg: 'white',
            radioWithBgText: 'var(--PrimaryTextColor)',
            radioWithBgCheckedBorder: 'var(--SecondaryBrandColor)',
            radioWithBgCheckedBg: 'var(--Color__Neutral2)',
            radioWithBgCheckedText: 'var(--PrimaryBrandColor)',
        },
        table: {
            rowBg: 'var(--Color__Neutral1)',  
        },
        tabs: {
            activeTabColor: 'var(--AdvantageAccentColor)',
            activeTabText: 'var(--PrimaryBrandColor'
        },
        sideNav: {
            bg: 'var(--PrimaryBrandColor)',                        // Controlled by other variable
            textColor: 'white',
            activeSideLine: 'var(--AdvantageAccentColor)',                  // Controlled by other variable
            activeBg: '#004D93',
            activeText: 'white',
        },
        mainNav: {
            icon: 'var(--IconColor)', 
            color: 'var(--PrimaryTextColor)',
            activeColor: 'var(--SecondaryBrandColor)',
            textColor: 'var(--SecondaryBrandColor)'
        },
        scriptBox: { 
          border: 'var(--SecondaryBrandColor)',
          bg: 'var(--Color__Neutral1)'
        }
    },   
    icp: {
        colors:{
            primaryBrand: '#2E8B85',
            secondaryBrand: '#D65241',
            primaryText: '#343741',
            advantageAccent: '#8CBAB8',
            advantageText: '#343741',
            neutral1: '#F5F5F5',        // DO NOT ALLOW CUSTOMIZATION
            neutral2: '#E7E7E7',        // DO NOT ALLOW CUSTOMIZATION
            neutral3: '#DCDCDC',        // DO NOT ALLOW CUSTOMIZATION
            neutral4: '#B1B1B1',        // DO NOT ALLOW CUSTOMIZATION
            neutral5: '#616160',
            accent1: 'var(--PrimaryBrandColor)',
            accent2: 'var(--Color__Accent1)',
            accent3: 'var(--Color__Accent1)',
            accent4: 'var(--Color__Accent1)'
        },
        type: {
            linkColor: 'var(--PrimaryBrandColor)',
            iconColor: 'var(--SecondaryBrandColor)',
            iconColorYes: '#4EA54F',
            iconColorNo: '#E81944'
        },
        h: {
            headingColor: 'var(--PrimaryTextColor)',
            h5Weight: 'var(--Heading__FontWeight)',
            h6Weight: 'var(--Heading__FontWeight)',
        },
        btn: {
            borderRadius: '0px',
            primaryBg: 'var(--PrimaryBrandColor)',
            primaryBorder: '1px solid var(--PrimaryBrandColor)',
            primaryHoverBg: '#8CBAB8',
            primaryHoverText: 'white',
            primaryHoverBorder: '1px solid var(--SecondaryBrandColor)',
            secondaryBg: 'white',
            secondaryBorder: '1px solid var(--Color__Neutral3)',
            secondaryText: 'var(--PrimaryTextColor)',
            secondaryHoverBg: '#8CBAB8',
            secondaryHoverText: 'white',
            secondaryHoverBorder: '1px solid #8CBAB8',
            linkText: 'var(--LinkColor)',
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
            disabledText: 'var(--Color__Neutral4)',
        }, 
        input: {
            labelColor: 'var(--PrimaryTextColor)',                  // Currently Static
            placeholder: 'var(--Color__Neutral3)',
            border: 'var(--Color__Neutral3)',  
            borderRadius: 'var(--Btn__BorderRadius)',                    // Currently Static
            icon: 'var(--IconColor)',                        // Controlled by other variable
            disabledBg: 'var(--Color__Neutral1)',
            disabledBorder: '1px solid var(--Color__Neutral3)',
        },
        miscInput: {
            toggleColor: 'var(--PrimaryBrandColor)',
            checkboxBorder: 'var(--Input__Border)',    // Controlled by other variable
            checkboxCheckedBg: 'var(--PrimaryBrandColor)',                // Controlled by other variable
            checkboxCheckColor: 'white',
    
            dropdownArrowColor: 'var(--PrimaryTextColor)',               // Controlled by other variable
            dropdownOptionHoverBg: 'var(--PrimaryBrandColor)',            // Controlled by other variable
  
            radioButtonBorder: 'var(--Input__Border)',
            radioButtonBg: 'white',
            radioButtonCheckedBorder: 'var(--PrimaryBrandColor)',
            radioButtonCheckedBg: 'var(--PrimaryBrandColor)',

            radioWithBgBorder: 'var(--Input__Border)',
            radioWithBgBg: 'white',
            radioWithBgText: 'var(--PrimaryTextColor)',
            radioWithBgCheckedBorder: 'var(--PrimaryBrandColor)',
            radioWithBgCheckedBg: 'var(--Color__Neutral2)',
            radioWithBgCheckedText: 'var(--PrimaryTextColor)',
            activeSideTabBorder: 'var(--PrimaryBrandColor)',
        },
        table: {
            rowBg: 'var(--Color__Neutral1)',
        },
        tabs: {
            activeTabColor: 'var(--PrimaryBrandColor)',
            activeTabText: 'var(--PrimaryTextColor)',
  
        },
        sideNav: {
            bg: 'var(--PrimaryBrandColor)',                        // Controlled by other variable
            textColor: 'white',
            activeSideLine: 'white',                  // Controlled by other variable
            activeBg: '#0D6A64',
            activeText: 'white',
        },
        mainNav: {
            icon: 'var(--IconColor)', 
            color: 'var(--PrimaryTextColor)',
            activeColor: 'var(--SecondaryBrandColor)',
        },
        scriptBox: {
          border: 'var(--AdvantageAccentColor)',
          bg: 'var(--Color__Neutral1)'
        }
      },
    // ...More Brands
}