import React, { useContext } from 'react';
import {
    Container
} from 'reactstrap';
import NeedHelp from './Modals/NeedHelp/NeedHelp';
import { SessionContext } from '../context/SessionContext';
import styles from '../components/Nav/NavHeader.module.css';

function FooterContainer() { 
    const session = useContext(SessionContext);
    const { languageCode, translationsFooter } = session;

    const parseHtmlEntities = (str) => {
        let temp = document.createElement('div');
        temp.innerHTML = str;
        return temp.textContent;
    }

    const copyrightText = `Copyright &copy; ${(new Date().getFullYear())}, American International Group, Inc. All rights reserved.`

    const copyrightTextEnglish = parseHtmlEntities(copyrightText);    

    return (
        <footer role="contentinfo" aria-label="footer">
            <Container>
                <ul className="footerNav">
                    <li>
                        <a 
                            href={languageCode === "fr-ca" ? "https://www.aig.ca/fr/renseignements-personnels" : "https://www.aig.com/privacy-policy"}
                            target="_blank" 
                            aria-label="Privacy Policy opens a new details window"
                        >
                            {translationsFooter?.privacyPolicy || "Privacy Policy"}
                        </a>
                    </li>
                    <li>
                        <a 
                            href={languageCode === "fr-ca" ? "https://www.aig.ca/fr/conditions-dutilisation" : "https://www.aig.com/terms-of-use"}
                            target="_blank" 
                            aria-label="Site Terms of Use opens a new details window"
                        >
                            {translationsFooter?.termsOfUse || "Terms Of Use"}
                        </a>
                    </li>
                </ul>
                <p className={styles.copyRight}>
                    {translationsFooter?.copyright || copyrightTextEnglish}
                </p>
            </Container>
            <NeedHelp />
        </footer>
    );  
}

export default FooterContainer;