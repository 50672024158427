import { phoneFormat } from '../shared/util'

export const brandContent = {
    lennox: {
        clientName: 'Lennox',
        clientSite: 'www.lennoxpros.com',
        programName: 'Comfort Shield',
        programPhone: phoneFormat('866-816-2434'),
        programEmail: 'Warranty-ComfortShieldClaims@aig.com',
        advantageProgramName: 'Auto Select',
        pricingLevelName: 'Tier',
        advantageBenefits: {
            homeScreen: [
                'Lock in and protect every customer',
                'Simple, automated process',
                'Discounted pricing opportunities',
                'Manufacturer recommended strategy',
                'Closing tool for all sales',
            ],
            preferencesScreen: [
                'Lock in and protect every customer',
                'Simple, automated process',
                'Discounted pricing opportunities',
                'Manufacturer recommended strategy',
                'Closing tool for all sales',
            ]
        }
    },
    aig: {
        clientName: '{CompanyName}',
        clientSite: '{ClientSite}',
        programName: '{ProgramName}',
        advantageProgramName: '{Advantage}',
        programPhone: phoneFormat('XXXXXXXXXX'),
        programEmail: 'XXXXXXXXXX@aig.com',
        pricingLevelName: 'Plan',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    rheem: {
        clientName: 'Rheem',
        clientSite: 'MyRheem/MyRuud',
        programName: 'Protection Plus',
        advantageProgramName: 'Advanced',
        programPhone: phoneFormat('8772764294'),
        programEmail: 'Warranty-HVACClaims@aig.com',
        porProgramName: 'Express',
        pricingLevelName: 'Plan',
        valuePlanName: 'Core',
        standardPlanName: 'Premium',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    carrier: {
        clientName: 'Carrier',
        clientSite: 'www.climateshieldcomplete.com',
        programName: 'Climate Shield Complete',
        advantageProgramName: 'Ease',
        programPhone: phoneFormat('8665388922'),
        programEmail: 'Warranty-HVACClaims@aig.com',
        porProgramName: 'Select',
        pricingLevelName: 'Plan',
        valuePlanName: 'Prime',
        standardPlanName: 'Standard',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    trane: {
        clientName: 'Trane',
        clientSite: 'http://www.ASDealerNet.com or http://www.ComfortSite.com',
        programName: 'ComfortSure',
        advantageProgramName: '',
        specialityProgramName: 'Edge',
        advantageProgramName: 'Edge',
        programPhone: phoneFormat('8665449928'),
        programEmail: 'XXXXXXXXXXXXXXXXXX@aig.com',
        porProgramName: '',
        pricingLevelName: 'Plan',
        valuePlanName: 'Value',
        standardPlanName: 'Premium',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    ge: {
        clientName: 'GE',
        clientSite: 'https://www.comfortprotectplans.com',
        programName: 'Comfort Protect',
        advantageProgramName: '',
        specialityProgramName: '',
        programPhone: phoneFormat('800-349-4020'),
        programEmail: 'ComfortProtectClaims@aig.com',
        porProgramName: '',
        pricingLevelName: 'Plan',
        advantageBenefits: {
            homeScreen: [],
            preferencesScreen: []
        }
    },
    icp: {
        clientName: 'ICP',
        clientSite: 'www.comfortpromise.com',
        programName: 'Comfort Promise',
        advantageProgramName: 'Connect',
        specialityProgramName: '',
        programPhone: phoneFormat('8665252454'),
        programEmail: 'Warranty-HVACClaims@aig.com',
        porProgramName: 'Select',
        pricingLevelName: 'Plan',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    whatscovered: {
        lennox: {
            layout: 'slider',              
            OEM_PL_PartsTerm: "7 or 10",
            OEM_PL_LaborTerm: "3",
            ESA_PL_LaborTerm: "4 or 7",
            ESA_PL_LaborTermWritten: "four or seven",
            OEM_P_PartsTerm: "10 or 12",
            ESA_P_LaborTerm: "10 or 12",
            ESA_P_LaborTermWritten: "ten or twelve"
        },
        rheem: {
            layout: 'table',
            partFlag: 'yes',
            refrigerantFlag: 'yes',
        },
        carrier: {
            layout: 'slider',              
            OEM_PL_PartsTerm: "5",
            OEM_PL_LaborTerm: "3",
            ESA_PL_LaborTerm: "2",
            ESA_PL_LaborTermWritten: "two",
            OEM_P_PartsTerm: "10",
            ESA_P_LaborTerm: "10",
            ESA_P_LaborTermWritten: "ten"
        },
        ge: {
            layout: 'table',
            partFlag: 'no',
            refrigerantFlag: 'yes',
        },
        icp: {
            layout: 'table',
            partFlag: 'no',
            refrigerantFlag: 'no',
        }
    },
    coveragetype: {
        lennox: {
            layout: 'table',
            plan1: ["Available On", "Basic Tier for Residential Products Only", "Tier 1, Tier 2, and Tier 3 for Residential Products Only", "Available for Select Products"],
            plan2: ["Trip/Diagnostic", "yes", "yes", "yes"],
            plan3: ["Labor Costs", "yes", "yes", "yes"],
            plan4: ["Parts Markup", "", "yes", "yes"],
            plan5: ["Miscellaneous Parts", "", "yes", "Available with Tier 1, Tier 2 and Tier 3"],
            plan6: ["Out-of-Warranty Parts", "", "", "yes"],
            plan7: ["Refrigerant", "", "Available with Tier 1, Tier 2 and Tier 3", "Available with Tier 1, Tier 2 and Tier 3"],
        },
        rheem: {
            layout: 'table',
            plan1: ["Trip/Diagnostic", "yes", "yes", ""],
            plan2: ["Labor Costs", "yes", "yes", ""],
            plan3: ["Parts Allowance", "", "yes", ""],
            plan4: ["Out-of-Warranty Parts", "", "", "yes"],
            plan5: ["Refrigerant", "yes", "yes", ""],
        },
        carrier: {
            layout: 'table',
            plan1: ["Trip/Diagnostic", "yes", "yes", ""],
            plan2: ["Labor Costs", "yes", "yes", ""],
            plan3: ["Parts Allowance", "", "yes", ""],
            plan4: ["Out-of-Warranty Parts", "", "", "yes"],
            plan5: ["Refrigerant", "", "yes", ""],
        },
        ge: {
            layout: 'table',
            plan1: ["Trip/Diagnostic", "yes", "yes", "yes"],
            plan2: ["Labor Costs", "yes", "yes", "yes"],
            plan3: ["Parts Markup", "", "yes", "yes"],
            plan4: ["Out-of-Warranty Parts", "", "", "yes"],
            plan5: ["Miscellaneous Parts", "", "yes", "yes"],
            plan6: ["Refrigerant", "yes", "yes", "yes"],
        },
        icp: {
            layout: 'table',
            plan1: ["Trip/Diagnostic", "yes", "yes", ""],
            plan2: ["Labor Costs", "yes", "yes", ""],
            plan3: ["Parts Markup", "", "yes", ""],
            plan4: ["Out-of-Warranty Parts", "", "", "yes"],
            plan5: ["Refrigerant", "", "yes", ""],
        }
    },
    whysell: {
        lennox: {
            heading: "Two Simple Purchase Paths",
            para1: "Lennox Comfort Shield provides the ability to seamlessly choose your desired manufacturer's warranty and Comfort Shield upgraded coverage, all while registering Lennox products.",
            para2: "The Comfort Shield portal is easy to access from {BrandSite} and provides a simple, user friendly way to purchase Comfort Shield coverage options.",
        },
        rheem: {
            heading: "Order Processing Made Easy",
            para1: "Advanced applies pre-selected labor coverage automatically every time you register equipment, eliminating the administration burden of order processing.",
            para2: "Express allows you to apply coverage to individual registrations using the Protection Plus Portal.",
            para3: "The Protection Plus Portal is easy to access from MyRheem/MyRuud. It provides a simple way to submit post registration sales (new and existing installations) and manage contracts."
        },
        carrier: {
            heading: "Order Processing Made Easy",
            para1: "Ease applies pre-selected labor coverage automatically every time you register equipment, eliminating the administration burden of order processing.",
            para2: "Select allows you to apply coverage to individual registrations using the Climate Shield Complete Portal.",
            para3: "The Climate Shield Complete Portal is easy to access from www.climateshieldcomplete.com. It provides a simple way to submit post registration sales (new and existing installations) and manage contracts."
        },
        ge: {
            heading: "Promote Business Growth and Customer Satisfaction",
            para1: "Comfort Protect's extended service agreements are a great way to add revenue to your business without requiring additional equipment inventory or overhead costs. While the systems you install provides a product to your customer, including an extended service agreement builds a lifetime relationship with them.",
            para2: "Including an extended service agreement with your original proposal helps your customer recognize it as an added benefit, rather than an added cost at the end of the sales process.",
        },
        icp: {
            heading: "Order Processing Made Easy",
            para1: "Connect applies pre-selected labor coverage automatically every time you register equipment, eliminating the administration burden of order processing.",
            para2: "Select allows you to apply coverage to individual registrations using Comfort Promise Portal.",
            para3: "The Comfort Promise Portal is easy to access from ICP product registration site. It provides a simple way to submit post registration sales (new and existing installations) and manage contracts."
        }
    }
}

export const getBrandContent = (account) => {
    const accountLower = account.toLowerCase();
    if (account && brandContent[accountLower]) {        
        return brandContent[accountLower];
    }

    return brandContent.aig;
}

