import React, { useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import {
    DeleteRowButton,
    QtyInput,
    PartNumberInput,
    DescInput,
    UnitPriceInput,
    CaptionComponent,
    widthFormatter,
    amountHeaderFormatter,
    AuthInput,
    SelectCoverage,
    PartDetails,
    HeaderFormatter,
    AddRowButton,
    PartsDescriptionDropDown
} from '../util/FormatterComponents'
import { currencyFormat, newEntry } from '../util/util'
import styles from '../Adjudication.module.css'
import { AdjudicationContext } from '../util/store/context';
import { DropDownAutoComplete } from '../../../components/inputs/DropDownAutoComplete';

const contractTypeOptions = [{ display: 'Description 1', data: "Description 1" }, { display: 'Description 2', data: "Description 2" }, { display: 'Description 3', data: "Description 3" }, { display: 'Part Description 1', data: "Part Description 1" }, { display: 'Part Description 2', data: "Part Description 2" }];

const PartsTable = (props) => {
    const { state, dispatch } = useContext(AdjudicationContext)
    const { parts, totals, assets } = state;
    const isEditableRow = (rowIndex) => {
        if (parts[rowIndex].details.length == 1 && parts[rowIndex].details[0] == "nla")
            return false
        //   else if (parts[rowIndex].details.length > 1 || parts[rowIndex].details.length == 0)
        //    return false
        else
            return true;
    }

    const cols = [
        {
            text: '',
            dataField: '',
            isDummyColumn: true,
            style: widthFormatter('deleteCol'),
            headerStyle: widthFormatter('deleteCol'),
            formatter: (col, row, rowIndex) => <DeleteRowButton rowId={row.id} rowIndex={rowIndex} table='parts' />,
            footer: <></>,
            footerClasses: styles.noborder
        },
        {
            dataField: 'details',
            text: 'Parts Exception',
            style: widthFormatter('rateTypeCol'),
            headerStyle: widthFormatter('rateTypeCol'),
            headerFormatter: (col) => HeaderFormatter({ col }),
            formatter: (_, row, rowIndex) => <PartDetails row={row} rowIndex={rowIndex} />,
            footer: () => props?.eventTypeId == 6 ? "Part" : <AddRowButton table='parts' />,
            footerClasses: [styles.addRow, styles.noborder]
        },
        {
            dataField: 'quantity',
            text: 'Qty',
            style: widthFormatter('qtyCol'),
            headerStyle: widthFormatter('qtyCol'),
            classes: styles.containsInput,
            headerFormatter: (col) => HeaderFormatter({ col, centered: true }),
            formatter: (_, row, rowIndex) => <QtyInput row={row} rowIndex={rowIndex} table='parts' />,
            footer: <></>,
            footerClasses: styles.noborder
        },
        {
            dataField: 'partNumber',
            text: 'Part Number',
            footer: <></>,
            classes: styles.containsInput,
            headerFormatter: (col) => HeaderFormatter({ col }),
            formatter: (_, row, rowIndex) => <PartNumberInput row={row} rowIndex={rowIndex} />,
            footerClasses: styles.noborder,
            style: widthFormatter('partDescription'),
            headerStyle: widthFormatter('partDescription'),
        },
        {
            dataField: 'description',
            text: 'Description',
            classes: styles.containsInput,
            headerFormatter: (col) => HeaderFormatter({ col }),
            formatter: (_, row, rowIndex) => <PartsDescriptionDropDown row={row} rowIndex={rowIndex} noDropDownBorder={true} />,
            footer: 'Parts Total',
            footerClasses: styles.noborder,   
            style: widthFormatter('partDescription'),
            headerStyle: widthFormatter('partDescription'),
        },
        //{
        //    dataField: 'coverage',
        //    text: 'Coverage',
        //    style: widthFormatter('dropdownCol'),
        //    classes: styles.containsInput,
        //    headerFormatter: (col) => HeaderFormatter({ col }),
        //    formatter: (_, row, rowIndex) => <SelectCoverage row={row} rowIndex={rowIndex} table='parts' />,
        //    footer: <></>,
        //    footerClasses: styles.noborder
        //},
        {
            dataField: 'price',
            text: 'Price',
            style: widthFormatter('amountCol'),
            headerStyle: widthFormatter('amountCol'),
            classes: styles.containsInput,
            headerFormatter: (col) => HeaderFormatter({ col, centered: true }),
            footer: currencyFormat(totals.parts.reqPrice),
            formatter: (col, row, rowIndex) => <UnitPriceInput row={row} isEdit={isEditableRow(rowIndex)} rowIndex={rowIndex} table={'parts'} />,
            footerClasses: () => styles.static
        },
        {
            dataField: null,
            isDummyColumn: true,
            text: 'Total',
            style: { ...widthFormatter('amountCol'), padding: '4px 8px' },
            headerStyle: { ...widthFormatter('amountCol'), padding: '4px 8px' },
            headerFormatter: (col) => HeaderFormatter({ col, centered: true }),
            align: 'right',
            formatter: (_, row, rowIndex) => currencyFormat(isEditableRow(rowIndex) ? row.unitPrice * row.qty : 0),
            classes: () => styles.static,
            footer: currencyFormat(totals.parts.requested),
            footerClasses: () => styles.static
        },
        //{
        //    dataField: null,
        //    text: 'Authorized',
        //    style: widthFormatter('amountCol'),
        //    headerFormatter: (col) => HeaderFormatter({ col, centered: true }),
        //    align: 'right',
        //    classes: styles.containsInput,
        //    formatter: (_, row, rowIndex) => <AuthInput row={row} rowIndex={rowIndex} table='parts' />,
        //    footer: currencyFormat(totals.parts.authorized),
        //},
    ]
    return (!!parts &&
        <BootstrapTable
        bootstrap4
        condensed
        data={parts}
        columns={cols}
        keyField='id'
        caption={<CaptionComponent title="Parts" />}
        classes={['mb-0', styles.padCells, styles.AdjudicationTable]}
        wrapperClasses={[styles.AdjudicationTableContainer]}
        />
    )
}

export default PartsTable;