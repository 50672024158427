import React, { useState, useRef } from "react";
import { Col, FormGroup } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import styles from "./SaveSaleModal.module.css";

export const SaveSaleModal = (props) => {
  const [lastNameError, setLastNameError] = useState(false);
  const { isOpen, centered } = props;
  const [customerLastName, setCustomerLastName] = useState(props.customerLastName);
  const [dealerPo, setDealerPo] = useState(props.dealerPo);

  const [state, setState] = useState({
    isOpen: props.isOpen,
    centered: props.centered,
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + " " + value);

    switch (name) {
      case "customerLastName":
        setCustomerLastName(value);
        props.setSaveSaleLastName(value);
        if (value.trim() === "") {
          setLastNameError(true);
        }
        else {
          setLastNameError(false);
        }

        break;
      case "dealerPo":
        setDealerPo(value);
        props.setSaveSaleDealerPo(value);
        break;
      default:
        break;
    }

    setState({
      [name]: value
    });
  }

  const onSave = (e) => {
    if (customerLastName.trim() === "") {
      return setLastNameError(true)
    }
    props.toggle();
    props.onSaveSale(customerLastName, dealerPo);
  }

  return (
    <Modal isOpen={isOpen}
      toggle={props.toggle}
      centered={centered}
      customerLastName={customerLastName}
      dealerPo={dealerPo}>

      <div className={styles.saveSaleHeader}>
        <h4>Save as Incomplete Sale</h4>
      </div>

      <ModalBody>
        <p>You can resume this sale by searching or clicking “Incomplete Sale” within your dashboard.</p>
        <Col md="5" className="p-0">
          <FormGroup className={lastNameError ? "form-error" : ""}>
            <label for="customerLastName" className="form-label">Last Name</label>
            <span aria-hidden="true" className="required-indicator">*</span>

            <input type="text"
              aria-required="true"
              name="customerLastName"
              className="form-control"
              id="customerLastName"
              placeholder=""
              onChange={(e) => handleChange(e)}
              defaultValue={customerLastName}
            />

            {lastNameError === true && <div className="errorMsg">Last name is required.</div>}
          </FormGroup>

          <FormGroup>
            <label for="dealerPo" className="form-label">Dealer PO #/Registration #</label>
            <input type="text"
              name="dealerPo"
              className="form-control mb-2"
              id="dealerPo"
              placeholder=""
              defaultValue={dealerPo}
              onChange={(e) => handleChange(e)}
            />
          </FormGroup>
        </Col>
      </ModalBody>

      <ModalFooter>
        <div className="btnWrapper">
          <button className="btnLink" onClick={props.toggle}>
            Cancel
                        </button>

          <button className="btn-primary" onClick={() => onSave()}>Save</button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
