import React, { useState, useEffect } from "react";
import styles from "./Formatters.module.css";
import { ReactComponent as PencilIcon } from '../../../assets/images/pencil.svg';
import { EditClaimModal } from '../../Modals/EditClaimModal/EditClaimModal';

export const EditRowButton = ({ row }) => {
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (row?.repair?.selected?.[0]?.display?.includes('Unit Replaced')) {
            setShowModal(true);
        }
    }, [row])

    const handleDelete = () => {
        setShowModal(true)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    return (
        <div className='replacementDetails'>
            <div className={styles.iconAlign} onClick={handleDelete}>
                <PencilIcon className={styles.editReplacementDetailsIcon} onClick={handleDelete} />
            </div>
            <EditClaimModal row={row} isOpen={showModal} onEdit={handleClose} handleSubmit={handleClose} />
        </div>
    )
}