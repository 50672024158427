import React, { useState, useRef } from 'react';
import { Row, Col, Label, Input, FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import styles from "./SuggestedAddress.module.css";

const TermsAndConditionsModal = ({
  toggle,
  isOpen,
  updateSnackBar,
  cutsormerEmail,
  dealerEmail,
  getCoC,
  createSCCURequest,
  accountName
}) => {
  const validator = useRef(new SimpleReactValidator({
    element: message => <div className="errorMsg ml-4">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
    messages: {
      required: ':attribute is required.',
      email: 'invalid email format.'
    }
  }));

  const [emailCustomer, setEmailCustomer] = useState(cutsormerEmail);
  const [emailDealer, setEmailDealer] = useState(dealerEmail);
  const [errCustomerEmail, setErrCustomerEmail] = useState(false);
  const [errDealerEmail, setErrDealerEmail] = useState(false);
  const [emailReceiptCustomer, setEmailReceiptCustomer] = useState(false);
  const [customerReceiptOpt, setCustomerReceiptOpt] = useState(false);
  const [downloadReceipt, setDownloadReceipt] = useState(false);
  const [mailReceipt, setMailReceipt] = useState(false);
  const [emailReceipt, setEmailReceipt] = useState(false);

  const handleSubmit = () => {
    if (emailReceiptCustomer || customerReceiptOpt || downloadReceipt || mailReceipt || emailReceipt) {
      if (emailReceipt && emailReceiptCustomer && (!errCustomerEmail || !errDealerEmail)) {
        updateSnackBar(true);
        toggle();
        stateDisableAfterSubmit();
      } else if ((emailReceipt && errDealerEmail) || (errCustomerEmail && emailReceiptCustomer)) {
        updateSnackBar(false);
      } else {
        updateSnackBar(true);
        toggle();
        stateDisableAfterSubmit();

        if (downloadReceipt) {
          getCoC();
        };

        if (emailReceipt) {
          createSCCURequest();
        }

      }
    }
  }
  const stateDisableAfterSubmit = () => {
    setEmailReceiptCustomer(false);
    setCustomerReceiptOpt(false);
    setDownloadReceipt(false);
    setMailReceipt(false);
    setEmailReceipt(false);
  }
  const btnDisable = () => {
    if (emailReceiptCustomer || customerReceiptOpt || downloadReceipt || mailReceipt || emailReceipt) {
      return false;
    } else {
      return true;
    }
  }
  const handlePrintInputChange = (e) => {
    validator.current.showMessageFor('email Certificate of Coverage to');
    validator.current.showMessageFor('mail and Email Certificate of Coverage to');
    const { id, value } = e.target;
    if (id === "emailCustomer") {
      if (emailReceiptCustomer) {
        if (value.trim() === "") {
          setErrCustomerEmail(true);
          setEmailCustomer(value);
        } else {
          setErrCustomerEmail(false);
          setEmailCustomer(value);
        }
      }
    }
    if (id === "emailDealer") {
      if (emailReceipt) {
        if (value.trim() === "") {
          setErrDealerEmail(true);
          setEmailDealer(value);
        } else {
          setErrDealerEmail(false);
          setEmailDealer(value);
        }
      }
    }
    if (id === "emailReceiptCustomer") {
      setEmailReceiptCustomer(!emailReceiptCustomer);
      if (!emailCustomer) {
        setErrCustomerEmail(true);
      }
    }
    if (id === "customerReceiptOpt") {
      setCustomerReceiptOpt(!customerReceiptOpt);
    }
    if (id === "downloadReceipt") {
      setDownloadReceipt(!downloadReceipt);
    }
    if (id === "mailReceipt") {
      setMailReceipt(!mailReceipt);
    }
    if (id === "emailReceipt") {
      setEmailReceipt(!emailReceipt);
      if (!emailDealer) {
        setErrDealerEmail(true);
      }

    }
    btnDisable();
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} modalClassName={styles.termsAndConditions}>
        <ModalHeader className="mb-0">
          <h4 className="mb-0">
            Reprint Contract
                    </h4>
        </ModalHeader>
        <ModalBody>
          <Row id="printingOptions" className="multiColWrapper">
            <Col md="12">
              <Label name="dealerReceiptOpt" className="form-label mb-2">Dealer Options</Label>
              <span aria-hidden="true" className="required-indicator"></span>
              <Row>
                <Col className="checkbox mb-2">
                  <input className="mr-2" type="checkbox" name="dealerReceiptOpt"
                    id="downloadReceipt"
                    onChange={(e) => handlePrintInputChange(e)} checked={downloadReceipt}
                  />
                  <Label
                    for="downloadReceipt"
                    className={`checkboxLabel ${accountName}`}
                  >
                    Download and Print Certificate of Coverage
                                    </Label>
                </Col>
              </Row>
              <Row>
                <Col className="checkbox mb-2">
                  <input className="mr-2" type="checkbox" name="dealerReceiptOpt"
                    id="mailReceipt"
                    onChange={(e) => handlePrintInputChange(e)} checked={mailReceipt}
                  />
                  <Label
                    for="mailReceipt"
                    className={`checkboxLabel ${accountName}`}
                  >
                    Mail Certificate of Coverage (7-10 business days)
                                    </Label>
                </Col>
              </Row>
              <FormGroup className={`mb-2 ${errDealerEmail && emailReceipt && "form-error"}`}>
                <Row>
                  <Col className="checkbox mb-2">
                    <input className="mr-2" type="checkbox" name="dealerReceiptOpt"
                      id="emailReceipt"
                      onChange={(e) => handlePrintInputChange(e)} checked={emailReceipt}
                    />
                    <Label
                      for="emailReceipt"
                      className={`checkboxLabel ${accountName}`}
                    >
                      Email Certificate of Coverage to:
                                        </Label>
                  </Col>
                </Row>
                <Input disabled={!emailReceipt}
                  type="email"
                  className="form-control ml-4 col-8 mb-3 mb-md-0"
                  id="emailDealer"
                  value={emailDealer}
                  style={{ width: "40%" }}
                  onChange={handlePrintInputChange}
                />
                {emailReceipt && validator.current.message('email Certificate of Coverage to', emailDealer, 'required|email')}
              </FormGroup>
            </Col>
            <Col md="12"><hr></hr></Col>
            <Col md="12">
              <Label name="customerReceiptOpt" className="form-label mb-2">Customer Options</Label>
              <span aria-hidden="true" className="required-indicator"></span>
              <Row>
                <Col className="checkbox mb-2">
                  <input className="mr-2"
                    type="checkbox" name="customerReceiptOpt"
                    id="customerReceiptOpt"
                    onChange={(e) => handlePrintInputChange(e)} checked={customerReceiptOpt}
                  />
                  <Label
                    for="customerReceiptOpt"
                    className={`checkboxLabel ${accountName}`}
                  >
                    Mail Certificate of Coverage (7-10 business days)
                                    </Label>
                </Col>
              </Row>
              <FormGroup className={errCustomerEmail && emailReceiptCustomer && "form-error"}>
                <Row>
                  <Col className="checkbox mb-2">
                    <input className="mr-2" type="checkbox" name="customerReceiptOpt"
                      id="emailReceiptCustomer"
                      onChange={(e) => handlePrintInputChange(e)} checked={emailReceiptCustomer}
                    />
                    <Label
                      for="emailReceiptCustomer"
                      className={`checkboxLabel ${accountName}`}
                    >
                      Mail and Email Certificate of Coverage to:
                                        </Label>
                  </Col>
                </Row>
                <Input
                  disabled={!emailReceiptCustomer}
                  type="email"
                  className="form-control ml-4 col-8"
                  id="emailCustomer"
                  value={emailCustomer}
                  style={{ width: "40%" }}
                  onChange={handlePrintInputChange}
                />
                {emailReceiptCustomer &&
                  validator.current.message('mail and Email Certificate of Coverage to', emailCustomer, 'required|email')
                }
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="btn btnLink" onClick={toggle}>Cancel</Button>
          <Button color="primary" onClick={() => { handleSubmit() }} disabled={btnDisable()}>Submit</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default TermsAndConditionsModal;