import React from 'react';
import { ReactComponent as DownloadIcon } from "../../../assets/images/download-arrow.svg"
import styles from './IconButtons.module.css';
import classNames from 'classnames';

const DownloadButton = ({ id = "download_secbtn", onClick, className = "" }) => (
    <button className={classNames("btn-secondary", styles.iconBtn, className)} id={id} onClick={onClick} >
        <DownloadIcon alt='Download' id={id} />
        Download
    </button>    
)

export default DownloadButton;