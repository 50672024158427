import React, { Component } from 'react';
import Select from 'react-select';

export class DropDownAutoCompleteTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedValueText: '',
            defaultValue: this.props.defaultValue,
            optionsList: this.configureOptionsList(props.options)  
        };

        this.handleStateChange = this.handleStateChange.bind(this);
    }

    configureOptionsList = (optionsObj) => {

        let newList = []

        if (Array.isArray(optionsObj) && optionsObj.length > 0) {
            newList = optionsObj.map(obj => ({ ...obj, label: obj.display, value: obj.data }));
        }

        return newList;
    }
        
    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.Value !== this.state.defaultValue) {
            this.setState({ defaultValue: nextProps.Value })
        }
        
        if (nextProps.options !== this.props.options) {
            console.log('nextprops', nextProps)
            const list = this.configureOptionsList(nextProps.options);
            console.log({ list })
            this.setState({ optionsList: list })
        }
    }
    
    handleStateChange = (selectedValueText, eve) => {
        console.log({ selectedValueText })
        this.setState({ selectedValueText });
        this.props.onChangedValue(selectedValueText.value, eve.name);
    }
       
    componentDidUpdate(prevProps) {
        if (this.props.Value && this.props.Value != prevProps.Value) {
            console.log(this.props);
            this.setState({
                defaultValue: this.props.Value,
            });
        }        
    }

    filterOption = (option, inputValue) => {
        return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
    }

    render() {
        console.log(this.props.name, this.props.defaultValue, this.props.options, this.state.optionsList, this.state.defaultValue)

        let val;
        if (this.props.defaultValue === "" && this.props.options.length > 0) {
            val = "";
        } else {
            let defVal = typeof this.state.defaultValue == 'undefined' ? this.props.defaultValue : this.state.defaultValue
            val = this.state.optionsList.filter(o => o.data === defVal)[0];
        }
        console.log(val)
        return (
            <React.Fragment>
                <Select
                    //value={this.props.Value === "" ? "" : this.state.optionsList.filter(o => o.data === this.state.defaultValue)[0]}
                    //defaultValue={this.state.defaultValue === "" ? "" : this.state.optionsList.filter(o => o.data === this.state.defaultValue)[0]}
                    value={this.state.optionsList.length === 0 ? "" : val}
                    label="Single select"
                    classNamePrefix='autoDrop'
                    placeholder='please select...'
                    onChange={this.handleStateChange}
                    options={this.state.optionsList}
                    filterOption={this.filterOption}
                    name={this.props.name}
                    isDisabled={this.props.disabled}
                />
            </React.Fragment>
        );
    }
}