import React from 'react';
import styles from './IconButtons.module.css';
import classNames from 'classnames';
import {ReactComponent as PrintIcon} from "../../../assets/images/printer.svg";


const PrintButton = ({id, onClick}) => (    
    <button className={classNames("btn-secondary", styles.iconBtn)} id={id && id} onClick={onClick} >
        <PrintIcon alt='print' id={id && id} />
        Print
    </button>    
)

export default PrintButton;