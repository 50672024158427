export const NotificationIds = {
  AccountSuspended: 17,
  CampaignExpiring: 18,
  NoCampaign: 19,
  PaymentPastDue: 20,
  AutoPayError: 21,
  AdvantagePaymentExpired: 22,
  PendingContracts: 23,
  PreferredPaymentExpired: 24,
  NoAdvantagePaymentMethod: 26
}