import React, { useState, forwardRef, useEffect } from 'react';
import { CardBody, Row, Col } from 'reactstrap';
import styles from './DistributorDetails.module.css';
import MultiDropDown from '../shared/MultiDropDown/MultiDropDown';
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import StandardInput from "../shared/StandardInput/StandardInput";
import Cleave from 'cleave.js/react';
import { DQuestions, DeQuestions, SQuestions } from './constant';

const questionData = "questionData";
const vendorDetails = "vendorDetails";


const QWidget = ({
    value,
    onHandleChange,
    onTextInputChange,
    onMultiDropdownChange,
    onAutoCompleteChange,
    initialIndex,
    originalData,
    validator
}) => {
    const vendorSessionData = JSON.parse(sessionStorage.getItem(vendorDetails));
    const vendorState = vendorSessionData?.stateOptions.find(x => x.data == vendorSessionData.physicalAddress.State);
    const vendorCountry = vendorSessionData.country;
    const vendorType = vendorSessionData?.vendorType;

    return value?.questions?.map((ques, index) => {
        if (((ques.isCheckState && ques.states.includes(vendorState?.abbreviation?.toUpperCase())) &&
            ((vendorType.includes('distributor') && !vendorType.includes('dealer')) ||
                (!vendorType.includes('distributor') && vendorType.includes('dealer')) ||
                (vendorType.includes('distributor') && vendorType.includes('dealer') && originalData.type === 'distributor'))) ||
            !ques.isCheckState) {
            let multiSelectOptions = ques.options;

            if (ques.type == "multiselect" && ques.name == "industries") {
                let sessionItem = JSON.parse(sessionStorage.getItem("industryList")) ?? [];

                multiSelectOptions = sessionItem.industries.map(i => {
                    return {
                        data: i.data,
                        display: i.display,
                        value: i.value
                    }
                });
            }

            return <div className={ques.className ? `${ques.className} mt-4` : "mt-4"}>
                <p className='mb-2 d-flex'>
                    <span
                        className={(ques.type && ques.required && ques.name !== "zip" || !ques.type) &&
                            validator.message(ques.name, ques.answer, "required") ||
                            ques.validator && ques.answer && validator.message(ques.name, ques.answer, `min:14|max:14`) ||
                            ques.name === "zip" && validator.message(ques.name, ques.answer, `required|size:${vendorCountry === 1 ? 5 : 6}`) ? "errorMsg" : ""}>
                        {ques.question}</span>
                    {(ques.type && ques.required || !ques.type) &&
                        <span aria-hidden="true" className="required-indicator">*</span>}
                </p>
                <>
                    <div className='d-flex'>
                        {!ques.type &&
                            ques.options.map((option) => <button
                                className={` ${styles.btnWrapper} ${ques.answer === option.option ? 'btn-primary' : 'btn-secondary'}`}
                                onClick={() => onHandleChange(index, option, initialIndex, originalData)}>{option.option}
                            </button>
                            )}
                    </div>
                    <div
                        className={(ques.required && ques.name !== "zip" && (validator.message(ques.name, ques.answer, "required")) ||
                            (ques.validator && ques.answer && ques.valueType === "phone" && validator.message(ques.name, ques.answer, `min:14|max:14`)) ||
                            (ques.name === "zip" && validator.message(ques.name, ques.answer, `required|size:${vendorCountry === 1 ? 5 : 6}`))) ? "form-error" : ""}>
                        {ques.type === "multiselect" &&
                            <MultiDropDown
                                options={multiSelectOptions}
                                onOptionClick={(value) => onMultiDropdownChange(index, value, multiSelectOptions, initialIndex, originalData)}
                                selected={ques.answer}
                                allSelectedOption={(value) => onMultiDropdownChange(index, value, multiSelectOptions, initialIndex, originalData)}
                                hasSearch={true}
                                className="form-control"
                            />}
                        {ques.type === "singleselect" &&
                            <DropDownAutoComplete
                                name={ques.name}
                                className="form-control"
                                placeholder="Please Select..."
                                aria-required="true"
                                id={ques.name}
                                options={ques.options}
                                onChangedValue={(value) => onAutoCompleteChange(index, value, initialIndex, originalData)}
                                value={ques.answer}
                                defaultValue={ques.answer}
                            />}
                        {ques.type === "cleave" && <>
                            <Cleave
                                name={ques.name}
                                className="form-control"
                                id={ques.name}
                                options={{
                                    numericOnly: vendorCountry === 1 ? true : false,
                                    uppercase: true,
                                    blocks: ques.blocks || [(vendorCountry === 1 ? 5 : 6)],
                                    delimiters: ques.delimiters || [" "],
                                }}
                                value={ques.answer}
                                onChange={(e) => onTextInputChange(index, e, initialIndex, originalData)}
                            />
                            {ques.name === "zip" && validator.message(ques.name, ques.answer, `required|size:${vendorCountry === 1 ? 5 : 6}`) &&
                                <p className='errorMsg'>
                                    {validator.message(ques.name, ques.answer, `required|size:${vendorCountry === 1 ? 5 : 6}`, { messages: { size: 'Please enter valid zip code.' } })}
                                </p>
                            }
                            {(ques.required || ques.validator) && ques.valueType === "phone" && ques.answer &&
                                validator.message(ques.name, ques.answer, `min:14|max:14`) &&
                                <p className="errorMsg">
                                    {validator.message(ques.name, ques.answer, `min:14|max:14`, { messages: { min: 'Please enter valid phone number.' } })}
                                </p>
                            }
                        </>
                        }
                        {ques.type === "input" &&
                            <StandardInput
                                required={false}
                                ariaLabelAndFieldId={ques.name}
                                labelCustomClass="font-weight-bold"
                                hasError={ques.required && validator.message(ques.name, ques.answer, "required")}
                                formGroupClassName={`mb-0 ${styles.inputLabel}`}
                                fieldName={ques.name}
                                value={ques.answer}
                                onChange={(e) => onTextInputChange(index, e, initialIndex, originalData)}
                            />
                        }
                    </div>
                    {(ques.required && ques.name !== "zip" || !ques.type) && validator.message(ques.name, ques.answer, "required") &&
                        <p className='errorMsg'>
                            {!ques.type ?
                                validator.message(ques.name, ques.answer, "required", { messages: { required: "Please select an option." } }) :
                                ques.required ? validator.message(ques.name, ques.answer, "required") : ""}
                        </p>
                    }
                </>

                {ques.show && ques.titleEnabled &&
                    <>
                        {ques.options.find(value => value.option === ques.answer)?.seperator && <hr className="mb-0 mt-4 w-100" />}
                        <Col xs="12" md="12" className='pl-0 mt-4'>
                            <h2 className="h4 mb-0">{ques.options.find(value => value.option === ques.answer)?.title}</h2>
                        </Col>
                    </>}
                {ques.show && (
                    <QWidget
                        value={ques.options.find(data => data.option === ques.answer)}
                        validator={validator}
                        initialIndex={index}
                        onHandleChange={onHandleChange}
                        onTextInputChange={onTextInputChange}
                        onMultiDropdownChange={onMultiDropdownChange}
                        onAutoCompleteChange={onAutoCompleteChange}
                        originalData={originalData}
                    />
                )}
            </div>
        }
    })
}

const DistributorDetails = forwardRef((props, ref) => {
    const { validator } = props;
    const vendorSessionData = JSON.parse(sessionStorage.getItem(vendorDetails));
    const vendorType = vendorSessionData.vendorType;
    const [questionType, setQuestionType] = useState({
        dealerQuestions: DeQuestions,
        distributorQuestions: DQuestions,
        servicerQuestions: SQuestions
    });


    useEffect(() => {
        var queData = sessionStorage.getItem(questionData);

        if (queData != null) {
            queData = JSON.parse(queData);
            setQuestionType(queData);
        }
    }, []);

    const onSelectOption = (selectedValue, options, data) => {
        const selectedOption = options.find(value => value.value === selectedValue);
        const values = data.map(value => value.value);

        if (!values.includes(selectedValue)) {
            data.push(selectedOption)
        }
        else {
            data = data.filter((el) => selectedValue !== el.value);
        }
        return data
    }

    const onHandleChange = (index, option, initialIndex, originalData) => {
        const dealerType = originalData?.type;
        if (initialIndex == undefined) {
            originalData.questions[index].show = option.hasOwnProperty("questions");

            if (option.hasOwnProperty("options")) {
                originalData.questions[index].answer = onSelectOption(option.option, option.options, originalData.questions[index].answer);
            }
            else {
                originalData.questions[index].answer = option.option;
            }
        }
        else {
            const optionIndex = originalData.questions[initialIndex].options.findIndex(value => value.option === originalData.questions[initialIndex].answer);
            originalData.questions[initialIndex].show = true;

            if (option.hasOwnProperty("options")) {
                originalData.questions[initialIndex].options[optionIndex].questions[index].answer =
                    onSelectOption(option.option, option.options, originalData.questions[initialIndex].options[optionIndex].questions[index].answer);
            }
            else {
                originalData.questions[initialIndex].options[optionIndex].questions[index].answer = option.option;
            }
        }

        validator.purgeFields()

        if (dealerType == "dealer") {
            setQuestionType({ ...questionType, dealerQuestions: originalData });
            sessionStorage.setItem(questionData, JSON.stringify({ ...questionType, dealerQuestions: originalData }));
        }
        else if (dealerType == "distributor") {
            setQuestionType({ ...questionType, distributorQuestions: originalData });
            sessionStorage.setItem(questionData, JSON.stringify({ ...questionType, distributorQuestions: originalData }));
        }
        else if (dealerType == "servicer") {
            setQuestionType({ ...questionType, servicerQuestions: { ...originalData } });
            sessionStorage.setItem(questionData, JSON.stringify({ ...questionType, servicerQuestions: originalData }));
        }
    }

    const onTextInputChange = (index, e, initialIndex, originalData) => {
        onHandleChange(index, { "option": e.target.value }, initialIndex, originalData);
    }

    const onMultiDropdownChange = (index, value, multiSelectOptions, initialIndex, originalData) => {
        onHandleChange(index, { "option": value, options: multiSelectOptions }, initialIndex, originalData);
    }

    const onAutoCompleteChange = (index, value, initialIndex, originalData) => {
        onHandleChange(index, { "option": value }, initialIndex, originalData);
    }



    return (
        <>
            <Col xs="12" md="12" className={`titleRow ${styles.header}`}>
                <h2 className="h4 m-0">Company Preferences</h2>
            </Col>
            <hr className="m-0 w-100" />
            <CardBody>
                <Row className={'multiColWrapper relative'}>
                    {vendorType.includes('distributor') &&
                        <>
                            <Col xs="12" md="12" className='titleRow'>
                                <h2 className="h4 mb-0">Distributor Information</h2>
                            </Col>
                            <Col xs="12" md="12" className='titleRow'>
                                <QWidget
                                    value={questionType.distributorQuestions}
                                    onHandleChange={onHandleChange}
                                    onTextInputChange={onTextInputChange}
                                    onMultiDropdownChange={onMultiDropdownChange}
                                    onAutoCompleteChange={onAutoCompleteChange}
                                    originalData={questionType.distributorQuestions}
                                    validator={validator}
                                />
                            </Col>
                        </>
                    }
                    {vendorType.includes('dealer') &&
                        ((vendorType.includes('distributor') && questionType.distributorQuestions?.questions?.every(value => value.answer)) ||
                            !vendorType.includes('distributor')) &&
                        <>
                            {vendorType.includes('distributor') && <hr className="mb-0 mt-4 w-100" />}
                            <Col xs="12" md="12" className={`titleRow ${vendorType.includes('distributor') ? 'mt-4' : ''}`}>
                                <h2 className="h4 mb-0">Dealer Information</h2>
                            </Col>
                            <Col xs="12" md="12" className='titleRow'>
                                <QWidget
                                    value={questionType.dealerQuestions}
                                    onHandleChange={onHandleChange}
                                    onTextInputChange={onTextInputChange}
                                    onMultiDropdownChange={onMultiDropdownChange}
                                    onAutoCompleteChange={onAutoCompleteChange}
                                    originalData={questionType.dealerQuestions}
                                    validator={validator}
                                />
                            </Col>
                        </>
                    }
                    {vendorType.includes('servicer')
                        && !vendorType.includes('dealer') &&
                        ((vendorType.includes('distributor') && questionType.distributorQuestions?.questions?.every(value => value.answer)) ||
                            !vendorType.includes('distributor')) &&

                        <>
                            {vendorType.length > 1 && <hr className="mb-0 mt-4 w-100" />}
                            <Col xs="12" md="12" className={`titleRow ${vendorType.length > 1 ? 'mt-4' : ''}`}>
                                <h2 className="h4 mb-0">Servicing Information</h2>
                            </Col>
                            <Col xs="12" md="12" className='titleRow'>
                                <QWidget
                                    value={questionType.servicerQuestions}
                                    onHandleChange={onHandleChange}
                                    onTextInputChange={onTextInputChange}
                                    onMultiDropdownChange={onMultiDropdownChange}
                                    onAutoCompleteChange={onAutoCompleteChange}
                                    originalData={questionType.servicerQuestions}
                                    validator={validator}
                                />
                            </Col>
                        </>
                    }
                </Row>
            </CardBody>
        </>
    );
});

export default DistributorDetails