import React, { Component } from "react";
import { dataLayerPush } from '../../components/Global/helpers'
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import styles from "./DealerHome.module.css";
import classNames from "classnames";
import UpperCard from './UpperCard';
import LowerCard from './LowerCard';
import { getBrandContent } from '../../components/BrandingWhiteLabel/BrandContent';
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon';
import SnackBarAlert from '../../components/shared/SnackBar/SnackBarAlert';
import moment from 'moment';
import { SessionContext } from "../../context/SessionContext";
import LegacyAutoLogin from '../../components/LegacyAutoLogin';

export class DealerHome extends Component {
    constructor(props) {
        super(props);

    this.state = {
      enrolledToCampaign: 'UNENROLLED',  
      eligibleForCampaign: false,
      dealerProfile: [],
      activeContractCount: 0,
      pendingPaymentCount: 0,
      incompleteSaleCount: 0,
      advantageProgramName: '',
      paymentHistoryCount: 0,
      serviceRequestCount: 0,

            reportType: { id: 0, title: "", type: "" },
            reportTypeList: [],

            tabId: this.props.tabId === undefined ? 0 : this.props.tabId,

            formData: {
                dealerId: "",
                dealerBrand: "",
                claimsOnly: false,
                dateTime: "",
                hash: "",
                snUserName: "VendorPortal",
                isAdmin: false,
                isDraftDocAdmin: false,
                accountID: "",
                sorg: ""
            },

            url: "",

      tabState: {
        activeTab: 2, // for the profile component
      },
      showSnackBar: false,
      cardSettings: [],
      cardsLoaded: false,
      pageUpdatesExecuted: false,  
      pageStatsLoaded: false,
      fileClaimFlag: false
    }

    this.handleUnload = this.handleUnload.bind(this);
  }

  loadTabList = () => {
    // add your tabs here.
    let reportTypeList = this.state.reportTypeList;
    reportTypeList.push(
      { tabId: 1, title: "Reports", type: "activecontracts", tabType: "reports" },
      { tabId: 2, title: "Reports", type: "pendingcontracts", tabType: "reports" },
      { tabId: 3, title: "Reports", type: "incompletesales", tabType: "reports" },
      { tabId: 4, title: "Reports", type: "contracts", tabType: "reports" },
      { tabId: 5, title: "Sale Entry", type: "orderinfo", tabType: "orderinfo" },
      { tabId: 6, title: "Profile", type: "profile", tabType: "profile" },
      { tabId: 7, title: "Resources", type: "resources", tabType: "resources" },
      { tabId: 8, title: "Profile", type: "payment", tabType: "profile" },
      { tabId: 9, title: "Reports", type: "servicerequest", tabType: "reports" },
      { tabId: 10, title: "Reports", type: "claims", tabType: "reports" },
      { tabId: 11, title: "Reports", type: "paymenthistory", tabType: "reports" }
    )
    this.setState({ reportTypeList: reportTypeList });
  }

  componentDidMount = () => {
    window.addEventListener('beforeunload', this.handleUnload);
    dataLayerPush('Pageview', {
      pageTitle: "Dashboard",
      pagePath: "/dashboard"
    })

    this.loadTabList();
    this.init();
  }

    handleUnload(e) {
        const sessionContext = this.context;
        sessionContext.logout();

    }

  // componentWillUnmount() {
  //   this.updateContext();
  // }

  // updateContext = () => {
  //   let value = this.context;
  //   value.updateTab({
  //     tabId: 1,
  //     title: "Dealer Homepage",
  //     tabType: "dealerhome",
  //     state: this.state
  //   });
  // }

    setShowSnackBar(flag) {
        this.setState({
            showSnackBar: flag
        })
    }

    loadTab = (tabId, activeProfileTab, state) => {
        try {
            if (tabId === -1) { return; }

            const tabs = this.state.reportTypeList;
            const tab = tabs.find((x) => { return x.tabId === tabId });

            if (tab) {
                const { context } = this;
                if (tab.tabType === 'reports') {
                    context.pendingReportTrack = {
                        selecteContractId: [],
                        selecteContractRows: [],
                        contractType: "",
                        pendingBackFlag: context.isExternalLogin ? context.pendingReportTrack.pendingBackFlag : false
                    };
                }

                //reset session for component coming from non report screen
                if (tab.tabType === 'orderinfo') {
                    context.setSourcePage({
                        sourcePage: {
                            pageId: "Sale Entry",
                            targetTab: 1,
                            contracts: ""
                        }
                    });
                }

                context.tabDispatch('ADD_TAB', {
                    title: tab.title,
                    reportType: tab.type,
                    tabType: tab.tabType,
                    activeProfileTab,
                    state: state
                });
            }
        } catch (e) {
            console.log("Unable to load reports.", e);
        }
    }

  init = async () => {
    var isResetPasswordSuccess = this.context.getIsResetPasswordSuccess();
    isResetPasswordSuccess == 'true' ? this.setShowSnackBar(true) : this.setShowSnackBar(false);

    var dealerProfile = this.context.getProfile();

    if (dealerProfile.status !== null) {
      this.setState({
        eligibleForCampaign: dealerProfile.isAdvantageEligible,
        dealerProfile: dealerProfile,
        dealerProfileLoaded: true
      })
    }

    this.setState({ pageUpdatesExecuted: false, pageStatsLoaded: false })

    let ctxt = this.context;

    if (ctxt) {
      ctxt.resetHomePageFlagStats();

      const fileClaimFlag = ctxt.getAccountFeatureFlag("hvac claim") ?? false;
      this.setState({fileClaimFlag});

      this.getPageUpdates(ctxt);

      if (ctxt.isExternalLogin !== undefined) {
        if (ctxt.isExternalLogin) {
          let tabList = this.state.reportTypeList;
          let targetTab = tabList.find(x => x.type === ctxt.targetTab)

                    if (targetTab !== undefined) {
                        if (targetTab.type == "payment") {
                            // let statePassed = { activeTab: 2 };
                            this.loadTab(targetTab.tabId, 2);
                        }
                        else if (ctxt.targetTab.toLowerCase() === "profile") {
                            // let statePassed = { activeTab: 2 };
                            this.loadTab(targetTab.tabId, 2);
                        }
                        else {
                            if (ctxt.targetTab.toLowerCase() === "contracts" && ctxt.targetTabFilter.toLowerCase() === "pending") {
                                ctxt.pendingReportTrack.pendingBackFlag = true;
                            }

                            this.loadTab(targetTab.tabId);
                        }

                        //this.loadTab(targetTab.tabId);
                        this.consumeToken(ctxt.token);

                        ctxt.dangerouslyUpdateAuthState({
                            isExternalLogin: false,
                            targetTab: "",
                            token: "",
                            targetTabFilter: ""
                        })
                        // ctxt.isExternalLogin = false;
                    }
                }
            }
        }
    }

  fileClaim = async (tab) => {
      try {
        const { fileClaimFlag } = this.state;
        
        if (fileClaimFlag) {
            if (tab)
                this.loadTab(tab);
        }
        else {
        const accountId = this.state.dealerProfile.accountId.toString();
        if (accountId === undefined) return;

        let url = "";
        let legacyResponse = await fetch(`Dealer/LegacyUrl/${accountId}`);

        if (legacyResponse.status !== 400) {
          var legacyData = await legacyResponse.json();

          if (legacyData !== undefined) {
            url = legacyData.url;

            fetch('Asset/FileAClaim', {
              method: 'POST',
              body: JSON.stringify({
                DealerID: this.state.dealerProfile.clientDealerId,
                DealerBrand: this.state.formData.dealerBrand,
                ClaimsOnly: this.state.formData.claimsOnly,
                DateTime: this.state.formData.dateTime,
                Hash: this.state.formData.hash,
                SNUserName: this.state.formData.snUserName,
                IsAdmin: this.state.formData.isAdmin,
                IsDraftDocAdmin: this.state.formData.isDraftDocAdmin,
                AccountID: accountId,
                sorg: this.state.formData.sorg

              }),
              headers: {
                'Content-type': 'application/json'
              }

            })
              .then(async (res) => {
                let form = await res.json();
                form.accountID = url && url.accountId;
                await this.setState({ formData: form, fileClaimClick: true, url: url });
              }).then(res => {
                this.setState({ fileClaimClick: false });
              })
          }
        }
      }
    } catch (e) {
      console.log("Error filing claim...");
    }
  }

    getAdvantageCard = () => {
        const enrolledToCampaign = this.state.enrolledToCampaign;
        const advantage = { amount: "Enrolled", category: "Pending Campaign" };
        const advProgramName = getBrandContent(this.context.accountName).advantageProgramName;

        switch (enrolledToCampaign) {
            case 'ENROLLED_ACTIVE': {
                advantage.amount = advProgramName;
                advantage.category = 'Active Campaign';
                break;
            }
            case 'ENROLLED_PENDING': {
                advantage.amount = 'Enrolled';
                advantage.category = 'Pending Campaign';
                break;
            }
            case 'ENROLLED_NO_CAMPAIGN': {
                advantage.amount = advProgramName;
                advantage.category = 'No Active Campaign';
                break;
            }
            case 'UNENROLLED': {
                advantage.amount = 'Enroll Now';
                advantage.category = advProgramName;
                break;
            }
            default: break;
        }

        return (
            <UpperCard
                onCardClick={() => { this.loadTab(6, 2) }}
                image='advantage'
                isAdvatageCard={true}
                {...advantage}
            />
        )
    }

    handleAdvantageClick = () => {
        const dealerId = this.state.dealerProfile.dealerId;

        if (dealerId !== undefined)
            this.loadTab(6, { activeTab: 2 });
        /*this.setState({ redirectInvoked: true });*/
    }

    handleFileClaim = async () => {
        this.fileClaim();
    }

    consumeToken = async (token) => {
        var res = await fetch(`token/ConsumeToken/${token}`);

        if (res.status === 200) {
            return true;
        }
        else {
            return false;
        }
    }

  plotColumns = (columns, accountName) => {
    const { homePageDetails } = this.context;
    const { fileClaimFlag } = this.state;
    let { activeContractCount,
      pendingPaymentCount,
      incompleteSaleCount,
      paymentHistoryCount,
      serviceRequestCount,
      eligibleForCampaign } = homePageDetails


    eligibleForCampaign = eligibleForCampaign ?? true;

    const columnLength = columns?.length;
    if (columnLength == 0) return;

    let newColumns = columns?.map(currentColumn => {
      let tabId = currentColumn?.tabId;
      tabId = !isNaN(tabId) ? parseInt(tabId) : -1;

      return (
        <>
          {
            currentColumn?.cardType?.toLowerCase() === "uppercard"
            && currentColumn?.visible &&
            <Col className={styles.upperCardWrapper} sm="6" lg={eligibleForCampaign ? "3" : "4"} >
              {
                currentColumn?.title.toLowerCase() !== "advantagecard" && <UpperCard
                onCardClick={() => { this.loadTab(tabId) }}
                amount={this.state[currentColumn?.title]}
                category={currentColumn?.description}
                image={currentColumn?.image}
                isPendingPayment={currentColumn?.isPendingPayment}
                />
              }
              {currentColumn?.title.toLowerCase() === "advantagecard" && this.getAdvantageCard()}
            </Col>
          }
          {
            currentColumn?.cardType?.toLowerCase() === "lowercard" && currentColumn?.visible &&
            <Col xs="12" md="6" lg="3">
              <div onClick={() => { currentColumn?.isManageClaims && !fileClaimFlag ? this.fileClaim(tabId) : this.loadTab(tabId) }} className="mb-3">
                <LowerCard
                  cardTitle={currentColumn?.title}
                  description={currentColumn?.description}
                  bgColor={currentColumn?.bgColor}
                  isManageClaims={currentColumn?.isManageClaims}>

                  <BrandedIcon
                    accountName={accountName}
                    iconType={currentColumn?.image} />
                </LowerCard>
              </div>
            </Col>
          }
        </>
      )
    })

    return newColumns;
  }

  loadCardSettings = (cards, accountName) => {
    if (cards?.length > 0) {
      var cards = cards?.map(row => {
        return (
          <>
            <Row className="multiColWrapper">
              {this.plotColumns(row.cardDetails,  accountName)}
            </Row>
          </>
        )
      })

      return cards;
    }
  }

  getPageUpdates = (context) => {
    if (context) {
      const { homePageDetails:
        {
          advantageProgramName,
          enrolledToCampaign,
          activeContractCount,
          incompleteSaleCount,
          pendingPaymentCount,
          incompleteDeletedSaleCount,
          serviceRequestCount
        }
      } = context;

      this.setState({
        advantageProgramName,
        enrolledToCampaign,
        activeContractCount,
        incompleteSaleCount,
        pendingPaymentCount,
        incompleteDeletedSaleCount,
        serviceRequestCount
      })
    }
  }


  getPageSettings = () => {
    let ctxt = this.context;

    if (ctxt) {
      const cardSettings = ctxt.getCardSettings();

      if (cardSettings) {
        const fileClaimFlag = ctxt.getAccountFeatureFlag("hvac claim") ?? false;

        this.setState({
          cardSettings: cardSettings?.cards,
          cardsLoaded: true,
          fileClaimFlag
        })

        this.getPageUpdates(ctxt);
      }
    }
  }

  getPageDetails = () => {
    let { pageUpdatesExecuted, pageStatsLoaded } = this.state;

    if (!pageUpdatesExecuted) {
      this.context.getPageDetails();
      this.setState({ pageUpdatesExecuted: true });
    }
    else {
      if (this.context.allHomePageFlagStatsUpdated() && !pageStatsLoaded) {
        this.getPageUpdates();
        this.setState({ pageStatsLoaded: true });
      }
    }
  }


  render() {
    const { cardSettings, cardsLoaded } = this.state;
    const { accountName, homePageDetails } = this.context;
    const accountNameToLower = accountName.toLowerCase();
    const benefits = getBrandContent(accountName)?.advantageBenefits?.homeScreen;
    const { enrolledToCampaign } = homePageDetails;

    if (!cardsLoaded) {
      this.getPageSettings();
    }
    else {
        this.getPageDetails();
    }

    return (
      <Container className={classNames("contentWrapper", styles.dealerHm)}>
        <h1>{`Welcome to ${getBrandContent(accountName).programName}`}</h1>
        <p className={styles.instructionText}>Choose an option to get started</p>
        {(enrolledToCampaign === 'UNENROLLED' && accountNameToLower !== 'ge') && (
          <Card>
            <CardBody className={styles.benefitsList}>
              <Row className="m-0 align-items-center">
                <Col lg="10" className="p-0">
                  <h2>{`${getBrandContent(accountName).advantageProgramName} Program Benefits`}</h2>
                  <ul className="row m-0">
                    {benefits && benefits.map((b, i) => (
                      <li key={i}>{b}</li>
                    ))}
                  </ul>
                </Col>
                <Col className={styles.joinBtn}>
                  <button
                    className="btn-primary"
                    onClick={() => { this.loadTab(6, 2) }}
                  >
                    Join
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {accountNameToLower === 'ge' && 
          <Card>
            <CardBody className={styles.benefitsList}>
              <Row className="m-0 align-items-center">
                <Col lg="10" className="p-0">
                  <h2>Exciting News for Premier and Elite Dealers</h2>
                  <p className="mb-0">
                    12-year extended service agreement offerings are now available!
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        }
        {cardsLoaded && this.loadCardSettings(cardSettings, accountName)}
        {this.state.fileClaimClick && <LegacyAutoLogin formData={this.state.formData} url={this.state.url} fileClaimClick={this.state.fileClaimClick} />}
        {this.state.showSnackBar && <SnackBarAlert content={'Password updated successfully!'} showSnackBar={true} onClose={() => this.setShowSnackBar(false)} />}
      </Container>
    );
  }
}

DealerHome.contextType = SessionContext;