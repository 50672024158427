import React, { useContext } from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import styles from './SubtabNav.module.css'
import classNames from 'classnames'
import { SessionContext } from '../../../context/SessionContext';
import { buildStyles } from 'react-circular-progressbar';

const SubtabNav = ({customStyle, handleReportType = ()=>{} }) => {
    const { tabDispatch, getCurrentTabAndSubtab, updateReportTab } = useContext(SessionContext)
    const currentTab = getCurrentTabAndSubtab().tab;
    const subtab = getCurrentTabAndSubtab().subtab;
    const { subtabs, activeSubtab } = currentTab

    const handleSubtabClick = (subtabId, reportType=null) => {
        tabDispatch('SET_ACTIVE_SUBTAB', {
            subtabId
        });
        handleReportType && handleReportType(reportType);
    }

    const closeSubtab = (e, subtabId) => {
        // Keep in mind that subtab to close MAY NOT ALWAYS be the currently active one.
        e.stopPropagation()
        // find the subtab matching the PASSED subtab id.
        const subtabToClose = subtabs.find(st => st.subtabId === subtabId)
        const { subtabType, claimId } = subtabToClose

        // ELSE remove the single subtab
        tabDispatch("CLOSE_SUBTAB", {
            subtabId,   // DO NOT USE tab.activeSubtab, since user can close inactive subtabs.
            subtabType,
        })
        if(currentTab.tabType === "reports") {
            tabDispatch('SET_ACTIVE_SUBTAB', {
                subtabId: 1
            });
            handleReportType && handleReportType(subtabs[0].reportType);
        }        
    }

    return (
        <nav className={classNames(styles.secondaryNav, ((currentTab.tabType === "serviceRequest") && styles.secondaryNavServiceRequest))} style={customStyle}>
            <ul className={classNames(styles.navPillsUl, ((subtab?.subtabType === 'contract') && styles.navPillsUlPadding))} id="pills-tab" role="tablist">
                {subtabs.map( s => {
                    const isActive = s.subtabId === activeSubtab;
                    return (
                        <li 
                            key={s.subtabId} 
                            className={classNames(styles.navItem, styles[s.color], isActive && styles.active)} 
                            onClick={() => handleSubtabClick(s.subtabId, s.reportType)}
                        >
                            <a 
                                id="sec-contract-tab" 
                                data-toggle="pill" 
                                role="tab" 
                                aria-controls="sec-contract-panel" 
                                aria-selected="true"
                            >
                                <span>{s.title}</span>
                            </a>
                            {s.closable && 
                                <span className={styles.closeTab}>
                                    <CloseIcon 
                                        className={styles.iconClose} 
                                        onClick={(e)=>closeSubtab(e, s.subtabId, s?.reportType)} 
                                    />
                                </span>
                            }
                        </li>
                    )
                }
                )}
            </ul>
        </nav>
    )
}

export default SubtabNav;