import React from "react";
import styles from './NotificationInfo.module.css';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import AIGStatusBadge from '../../components/shared/StatusBadge/StatusBadge';
import moment from 'moment';

export const getTimeDiff = (startDate, endDate = moment()) => {
  const start =  moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const minutes = duration.asMinutes();
  console.log(duration, moment);
  if(minutes < 60){
    return moment(start).format('hh:mm A');    
  }
  else if(minutes > 60 && minutes < 120){
    return `${duration.asHours().toFixed()} hour ago`;
  }
  else if(minutes > 120 && minutes < 1440){
    return `${duration.asHours().toFixed()} hours ago`;
  }
  else if(minutes > 1440 && minutes < (1440 * 2)){
    return `${duration.asDays().toFixed()} day ago`;
  }
  else if (minutes > (1440 * 2) && minutes < (1440 * 7)) {
    return `${duration.asDays().toFixed()} days ago`;
  }
  else if(minutes > (1440 * 7) && minutes < (1440 * 7 * 2)){
    return `${duration.asWeeks().toFixed()} week ago`;
  }
  else if (minutes > (1440 * 7 * 2) && minutes < (1440 * 7 * 4)) {
    return `${duration.asWeeks().toFixed()} weeks ago`;
  }
  else if (minutes > (1440 * 7 *4) && minutes < (1440 * 7 * 8)) {
    return `${duration.asMonths().toFixed()} month ago`;
  }
  else {
    return `${duration.asMonths().toFixed()} months ago`;
  }
}
const NotificationInfo = ({
  message= "",
  title="",
  dropTime="",
  hasClose= false,
  hasLink= false, 
  linkText="",
  badgeStatusID= "",
  show= true,
  finalNotification=false,
  onLinkClick = () => {},
  onDismiss = () => {}
}) => {
  // Add logic to convert time stamp to time string.

  if(!show) return null;
  
  return (
    <div className={styles.notification}>
      <div className={styles.head}>
        <p className={styles.title}>{title}</p>
        
        <div>
          <p className={styles.dropTime}>{getTimeDiff(dropTime)}</p>
          {hasClose ? (
            <span className={styles.dismiss} type="button" onClick={()=>onDismiss()}>
              <CloseIcon />
            </span>
            ) : (
            <span className={styles.dismiss}/>
          )}
        </div>
      </div>

      <AIGStatusBadge alignLeft="true" statusId={badgeStatusID} statusType={'notification'} />                  
      
      <p className={styles.message}>{message}</p>

      {hasLink && 
        <button className="btnLink mt-2" onClick={()=>onLinkClick()}>
          {linkText}
        </button>
      }

      {!finalNotification && <hr className="small" />}
      
    </div>
  );
}
export default NotificationInfo;