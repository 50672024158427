import React, { useState, useEffect, useContext } from "react";
import styles from "./Formatters.module.css";
import { ReactComponent as PencilIcon } from '../../../assets/images/pencil.svg';
import { EditClaimModal } from '../../Modals/EditClaimModal/EditClaimModal';
import { useClaimInvoiceStore } from "../lib/state";
import { SessionContext } from "../../../context/SessionContext";

export const StaticEditRowButton = ({ row, rowIndex, tableType, fieldName }) => {
    const [showModal, setShowModal] = useState(false)
    const { getCurrentTabAndSubtab } = useContext(SessionContext)
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const invoiceState = useClaimInvoiceStore(state => state);

    useEffect(() => {
        if (row?.repair?.selected?.[0]?.display?.includes('Unit Replaced') && !row.hrsEdited) {
            setShowModal(true);
        }
    }, [row])

    const handleDelete = () => {
        setShowModal(true)
    }

    const handleSubmit = () => {
        setShowModal(false)
    }

    const handleClose = () => {
        if (row?.repair?.selected?.[0]?.display?.includes('Unit Replaced')) {
            if (tableType === 'labor' || tableType === 'laborSelectRate') {
                invoiceState?.updateLaborRow(invoiceId, tableType, rowIndex, { ['repair']: {} });
            }
        }
        setShowModal(false)
    }

    return (
        <div className='replacementDetails'>
            <div className={styles.iconAlign} onClick={handleDelete}>
                <PencilIcon className={styles.editReplacementDetailsIcon} onClick={handleDelete} />
            </div>
            {(!row.hrsEdited || showModal) && 
                <EditClaimModal
                    row={row}
                    isOpen={showModal}
                    onEdit={handleClose}
                    handleSubmit={handleSubmit} 
                />
            }
        </div>
    )
}