import React, { useState, useContext } from 'react';
import { Row, Col, Container } from 'reactstrap';
import styles from './GetStartedToday.module.css';
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon';
import { SessionContext } from '../../context/SessionContext';
import EnrollmentModal from '../../components/Modals/Enrollment/Enrollment';


const GetStartedToday = () => {
    const sessionContext = useContext(SessionContext);
    const { accountName } = sessionContext;
    const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);

    const handleShowActionModal = () => {
        setShowEnrollmentModal(true);
    };

    return (
        <Container fluid className={styles.getStartedContentContainer}>
            <Container>
                <Row>
                    <Col className='text-center'>
                        <div className='d-flex justify-content-center'>
                            <div className={`${styles.colorBar}`}></div>
                        </div>
                        <h1 className={styles.getStartedHeader}>Get Started Today with Comfort Promise</h1>
                        <div className={styles.getStartedContainer}>
                            <p>
                                Our user-friendly portal makes selling and managing extended service agreements easy! Simply
                                enroll, then login to start adding extended service agreements to all your installations. Select
                                from multiple coverage, reimbursement and sales options.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg='4' md='12' sm='12' className={styles.getStartedIconContainer}>
                        <div className={styles.iconICP}>
                            <BrandedIcon
                                accountName={accountName}
                                iconType={'papercircle'}
                            />
                        </div>
                        <div>
                            <h3 className='mb-2'>Enrollment Verification</h3>
                            <div className={styles.paragraphICP}>
                                You’ll receive a verification email with a link to create your username and password.
                            </div>
                        </div>
                    </Col>
                    <Col lg='4' md='12' sm='12' className={`${styles.getStartedIconContainer} ${styles.enrollToday}`}>
                        <div className={styles.iconICP}>
                            <BrandedIcon
                                accountName={accountName}
                                iconType={'verificationpaper'}
                            />
                        </div>
                        <div>
                            <h3 className='mb-2'>Enroll Today</h3>
                            <div className={styles.paragraphICP}>
                                Submit a dealer
                                <a
                                    className={`${styles.textUnderline} ml-1 mr-1`}
                                    title='Click to open a modal to enroll'
                                    onClick={(e) => handleShowActionModal()}
                                >
                                    enrollment form
                                </a>
                                with a copy of your W-9 and COI documents.
                            </div>
                        </div>
                    </Col>
                    <Col lg='4' md='12' sm='12' className={styles.getStartedIconContainer}>
                        <div className={styles.iconICP}>
                            <BrandedIcon
                                accountName={accountName}
                                iconType={'login'}
                            />
                        </div>
                        <div>
                            <h3 className='mb-2'>
                                <a href='#' className={`${styles.headingUnderline} mr-1`}>
                                    Login
                                </a>
                                and Get Started
                            </h3>
                            <div className={styles.paragraphICP}>
                                The Comfort Protect Portal makes selling and managing your ESAs easy!
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {showEnrollmentModal && (
                <EnrollmentModal
                    isOpen={showEnrollmentModal}
                    centered={true}
                    toggle={() => setShowEnrollmentModal(!showEnrollmentModal)}
                    accountName={accountName}
                />
            )}
        </Container>
    );
}

export default GetStartedToday;