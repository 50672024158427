import React from 'react';
import {ReactComponent as EditIcon} from "../../../assets/images/pencil.svg"
import styles from './EditButton.module.css';

const EditButton = ({id, onClick}) => {
    // code/logic/vars here
    
    return (
        <button className={styles.button} id={id && id} onClick={onClick} >
            <EditIcon alt='Edit' id={id && id} className={styles.icon} />
            Edit
        </button>
    )
}

export default EditButton;