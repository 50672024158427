import React, { useState, useRef, useCallback } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Dropzone from '../../UploadDocs/DragandDropFileUpload';
import { Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input, Progress } from 'reactstrap';
import styles from './UpdateRequest.module.css';
import AIGFormInputField from './AIGFormInputField';
import {DropDownInput} from '../../inputs/DropDownInput';
import { useEffect } from 'react';
import { DropDownAutoComplete } from '../../inputs/DropDownAutoComplete';
import {ReactComponent as PaperMagnify } from '../../../assets/images/paper-magnify.svg';
const notRealBrands = [
    {name:'Rheem'},
    {name:'Carrier'},
    {name:'Lennox'},
    {name:'Other'},
]
const RequestUpdateModal = (props) => {
    const [validationFired, setValidationFired] = useState(false);
    const [brands, setBrands] = useState(notRealBrands);   // Either pass these (props.brands) or get brands in the useEffect hook below
    const [details, setDetails] = useState({
        product: props.product.product,
        brand: props.product.brand,
        model: props.product.model,
        serialNumber: props.product.serialNumber,
        series: props.product.series,
        brandId: props.product.brandId,
        seriesId: props.product.seriesId
    })
    const [fileName, setFileName] = useState(null);
    const [file, setFile] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [fileUploadPercent, setFileUploadPercent] = useState();
    const [invalidDoc, setInvalidDoc] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);
    const [btnDisable, setBtnDisable] = useState(true);

    const validator = useRef(new SimpleReactValidator({
        element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
        messages: {
            required: ':attribute is required.'
        }
    }));

    useEffect(()=>{
        // Get brand names for relevant product here.
        // setBrands([]);
        console.log(props.product)
    }, [brands])

    const { isOpen, onClose } = props;

    const brandOptions = brands.map(b => {
        return {
            data: b.name,
            display: b.name
        }
    });

    const handleDropdownChange = (val) => {
        setDetails({
            ...details,
            brand: val,
        })
    }

    const handleFileUpload = (fileData) => {
        
        console.log('is invalid', invalidDoc)
        if (fileData && fileData.length > 0) {

            if (checkTotalBytes(fileData) == false) {
                setInvalidDoc(true);
            } else {

                const currentFile = fileData[0];
                setFileName(currentFile.name);
            
                setFile(file.concat(fileData));
                //setFile(prev => [...prev, fileData]);

                const reader = new FileReader();

                reader.addEventListener("load", () => {
                    setSelectedDoc(reader.result);
                    setIsButtonDisabled(false);
                }, false)
                reader.addEventListener("abort", (ev) => {
                    console.log("file aborted", ev);
                }, false)
                reader.addEventListener("error", (ev) => {
                    console.log("file error", ev);
                }, false)
                reader.addEventListener("progress", (event) => {
                    console.log("total" + event.total);
                    console.log("progress" + event.loaded);
                    setFileUploadPercent(
                        Math.round((event.total / event.loaded) * 100)
                    );
                })
                reader.readAsDataURL(currentFile);
                setInvalidDoc(false);
                setBtnDisable(false);
            }
        }
        else {
            //alert("The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf)");
            setInvalidDoc(true);
        }
    }

    const checkTotalBytes = (currentFile) => {

        if (currentFile.length > 1) {
            // handle multiple files at once
            var totalPerFile = 0
            for (var i = 0; i < currentFile.length; i++) {
                var filesByte = currentFile[i].size

                for (var ii = 0; ii < file.length; ii++) {
                    totalPerFile = totalPerFile + file[ii].size;
                }
                totalPerFile = totalPerFile + filesByte;

            }

            if (totalPerFile <= 4194304) {
                return true
            } else {
                return false
            }

        } else {

            var fileByte = currentFile[0].size
            var total = 0
            for (var index = 0; index < file.length; index++) {
                total = total + file[index].size;
            }
            total = total + fileByte;

            if (total <= 4194304) {
                return true
            } else {
                return false
            }
        }
    }

    const handleRemoveFile = (row) => {
        setFile(file.filter((_, i) => i !== row));
        
        const cnt = file.filter((_, i) => i !== row)
        if (cnt.length == 0) {
            setInvalidDoc(false)
            setBtnDisable(true);
        }
        //setSelectedDoc('');
        //setIsButtonDisabled(true);
        //setFileUploadPercent(0);
    }

    const handleSubmit = () => {
        
        setIsProcessing(true);
        //if (!validator.allValid()) {
        //    setIsProcessing(false);
        //    return;
        //}
        setTimeout(()=>setIsProcessing(false), 2000)
        // WIRE UP SUBMISSION CALL TO API
        //.then()
        // setIsProcessing(false);
        debugger
        
        try {
            let formData;
            for (var index = 0; index < file.length; index++) {
                formData = new FormData();
                formData.append('File', file[index]);
                formData.append('DocumentTypeId', 13);
                formData.append('Notes', "");

                fetch(`document/upload/vp`, {
                    method: 'post',
                    body: formData
                }).then(res => {
                    if (res.ok) {
                        //onClose();
                        props.updateAlert(true);

                    }
                })
            }
        }
        catch (ex) {
            debugger
        }

        onClose();
    }

    const handleSerialNumberChange = (e) => {
        setDetails({
            ...details,
            serialNumber: e.target.value
        })
        validator.current.showMessageFor('serialNumber')
    }

    const handleModelChange = (e) => {
        setDetails({
            ...details,
            model: e.target.value
        })
        validator.current.showMessageFor('model')
    }

    const handleSeriesChange = (val) => {
        
        setDetails({
            ...details,
            series: val
        })
        validator.current.showMessageFor('series')
    }



    const { product, brand, model, serialNumber, series, brandId, seriesId} = details;
    console.log('ALL VALID', validator.current.allValid())
    console.log('MODEL VALID', validator.current.fieldValid('model'))

    return(
        <Modal isOpen={isOpen} className='modal-md'>
            <ModalHeader>
                <h4 className='modalTitle mb-0'>
                    Request Product Update
                </h4>
            </ModalHeader>
            <ModalBody>
                <Form className={styles.form} onSubmit={handleSubmit}>
                    <AIGFormInputField 
                        fieldName="product"
                        fieldLabel="Product"
                        required={true}
                        onChange={()=>{return null}}
                        disabled
                        defaultValue={product}
                        message={validator.current.message('product', product, 'required')}
                    />
                    <FormGroup>
                        <Label htmlFor="mailingState" className="form-label">Brand</Label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <DropDownAutoComplete
                            name={'brand'}
                            id="brandNameDropDown"
                            options={props.brands}
                            onChangedValue={handleDropdownChange}
                            defaultValue={props.product.brandId}
                        />
                            {validator.current.message('brand',  brand, 'required')}
                    </FormGroup>
                    <AIGFormInputField 
                        className={model.trim() == "" ? "form-error" : ""}
                        fieldName="model"
                        fieldLabel="Model"
                        required={true}
                        onChange={handleModelChange}
                        onBlur={()=>validator.current.showMessageFor('model')}
                        value={model}
                        defaultValue={model}
                        message={validator.current.message('model',  model, 'required')}
                    />
                    <AIGFormInputField 
                        className={serialNumber.trim() == "" ? "form-error" : ""}
                        fieldName="serialNumber"
                        fieldLabel="Serial Number"
                        required={true}
                        onChange={handleSerialNumberChange}
                        onBlur={()=>validator.current.showMessageFor('serialNumber')}
                        value={serialNumber}
                        defaultValue={serialNumber}
                        message={validator.current.message('serialNumber',  serialNumber, 'required')}
                    />

                    {props.isLennox == true &&
                        <FormGroup>
                            <Label htmlFor="mailingState" className="form-label">Series</Label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <DropDownAutoComplete
                                name={'series'}
                                id="seriesdNameDropDown"
                                options={props.seriesList}
                                onChangedValue={handleSeriesChange}
                                defaultValue={seriesId}
                            />
                            {validator.current.message('series', series, 'required')}
                        </FormGroup>}
                </Form>
                <p>
                    To process your request, we need you to upload the installation invoice.
                </p>


                <Dropzone onFileUpload={handleFileUpload} />
                {isButtonDisabled == false && file.map((f, key) => (

                    <div className={styles.uploadProgress}>
                        <PaperMagnify className={styles.file}/>
                        <div className={styles.progressWrapper}>
                            <div key={key} id={key} className={styles.fileName}>{f.name}</div>
                                <div className={styles.uploadProgressWrapper}>
                                    <Progress className={styles.progress} value={fileUploadPercent} />
                                <button id={"remove-btn" + key} onClick={() => handleRemoveFile(key)} aria-label="Cancel Uploaded File" className={styles.closeButton}></button>
                                </div> 
                            <div className={styles.uploadPercentage}>upload <span>{fileUploadPercent}% completed</span></div>
                        </div>
                    </div>
                ))}

                {invalidDoc &&
                    <p className="validationSummary">The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf)</p>
                }
            </ModalBody>
            <ModalFooter className={styles.modalFooter}>
                <button className='btnLink' type='button' onClick={onClose} disabled={isProcessing}>
                    Cancel
                </button>
                <button className='btn-primary' type='submit' onClick={handleSubmit} disabled={btnDisable}>
                    Submit Request
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default RequestUpdateModal