import React, { Component } from 'react';
import { Row, Col, Container, Alert, Card, CardHeader, CardBody } from 'reactstrap';
import { ReactComponent as Paper } from '../../assets/images/paper-magnify.svg';
import { ContractInfo } from './ContractInfo';
import styles from '../Nav/NavHeader.module.css';
import { CustomerInfo } from '../CustomerInfo/CustomerInfo';
import AssetInfo from './AssetInfo';
import classNames from 'classnames';
import { SessionContext } from '../../context/SessionContext';
import PageLoading from '../shared/PageLoading/PageLoading';
import { dataLayerPush } from '../../components/Global/helpers'
import { ReactComponent as Document } from '../../assets/images/documents.svg';
import TermsAndConditionsModal from '../Modals/TermsAndConditionsModal';
import SnackBarAlert from '../shared/SnackBar/SnackBarAlert';
import { getCoCDoc, createSCCURequest, getDocumentTypes, getDocumentByContractNumber, getDocumentsByWarrantyObject } from '../../components/Global/DocumentHelper';
import moment from 'moment';
import { ContractStatusId } from '../Global/ContractStatusId';
import { ReactComponent as Trx } from '../../assets/images/Transactions_Icon.svg';
import { Transactions } from '../Transactions/Transactions';
import HistoryTab from './HistoryTab';
import SimpleTabs from '../shared/SimpleTabs/SimpleTabs';
import ServiceRequest from './ServiceRequest';
import SubtabNav from '../Nav/Subtabs/SubtabNav';
import ThreeDotLoading from '../shared/ThreeDotLoading/ThreeDotLoading';
import { Claim } from '../ClaimInvoice/Claim';
import SampleData from '../../components/Report/SampleData';
import { StaticClaim } from '../ClaimInvoice/StaticClaim';

let sideNavLink = styles.sideNavLink;
let activeLink = styles.activeLink;
let dateFormat = "MM/DD/YYYY";

const waitPeriodInDays = 90; // days


export default class Contract extends Component {
    constructor(props) {
        super(props);

        this.state = {
            client: this.props.client,
            contractNumber: this.setPropOrDefault(this.props.contractNumber, ''),
            contract: null,
            bRulesFailureContinue: false,
            showCustomerErrorMessages: false,
            setCanFileAClaim: true,
            contractLoaded: false,
            customerErrors: false,
            checkForCustomerErrors: false,
            actionDisabled: false,
            series: [],
            showModal: false,
            showSnackBar: false,
            enableCoCReprint: false,
            isInvoiceDealer: false,
            statusId: '',
            servicerDetails: null,
            rfsFormData: null,
            repairHours: [],
            taxAttributes: [],
            markUpFlag: false,
            assetList: [],
            coverageTypeDescription: "",
            claimReimbursementType: "",
        }
        this.getContract = this.getContract.bind(this);
        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.customerComponent = React.createRef();

    }

    setAssetActionState = (statusId) => {
        let disabledStatusIds = [
            ContractStatusId.Pending
        ];

        let result = disabledStatusIds.includes(statusId);
        this.setState({ actionDisabled: result });
    }
    setPropOrDefault = (v, defaultVal) => {
        if (v === undefined || v === null)
            return defaultVal;

        return v;
    }

    setView = (v) => {
        this.setState({ view: v });
    }

    toggleActiveClaim = c => {
        console.log(c);
        this.setState({ activeClaim: c, showClaimOverview: false });
    }

    handleShowCurrentClaimOverview(id, number) {
        console.log('number', number);
        this.setState({ showClaimOverview: true, currentClaimOverviewId: id, currentClaimOverviewNumber: number })
    }

    handleAlertClose() {
        this.setState({
            showSavedClaimAlert: false
        })
    }

    setActivity = c => {
        // console.log(c);
        // this.setState({ subActivity: c });
        this.context.tabDispatch('UPDATE_TAB', {
            sideNavView: c
        })
    }

    getServicerDetails = async (dealerId) => {
        try {
            fetch(`/dealer/getservicerpartybydealerId/${dealerId}`).then(res => {
                if (res.ok) {
                    res.json().then(servicerParty => {
                        this.setState({ servicerDetails: servicerParty })
                    });
                }
            });
        } catch (error) {
            console.error("getServicerDetails -> error ===>>> ", error);
        }        
    }

    getRfsFormData = async (eventId) => {
        fetch(`/claim/GetRFSSummaryByEventId/${eventId}/${this.state.client}`).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    this.setState({ rfsFormData: data })
                });
            }
        });
    }

    setBrulesFailureContinue = val => {
        this.setState({
            bRulesFailureContinue: val
        });
        console.log('setting true: ' + val);
    }

    collectContractAssetForClaim = (createdClaim) => {
        var index = this.state.claimTabs.findIndex(ct => ct.claimId === createdClaim.claimId);
        var tabs = this.state.claimTabs;
        if (index === -1) {
            tabs = this.state.claimTabs.concat({
                claimNumber: createdClaim.claimNumber,
                claimId: createdClaim.claimId,
                clientClaimNumber: createdClaim.clientClaimNumber,
                contractAssetId: createdClaim.contractAssetId,
                // claimActivity: "entitlement",
                statusFlag: true
            });
        }

        this.setState({ clientClaimNumber: createdClaim.clientClaimNumber }); //updating client clsim number
        this.setState({ claimTabs: tabs, activity: "claim", activeClaim: createdClaim.claimId }, () => {
            sessionStorage.setItem(this.state.contractNumber, JSON.stringify(this.state.claimTabs));
        });
        console.log(JSON.parse(sessionStorage.getItem(this.state.contractNumber)));
    }

    toggleActivity = (act) => {
        console.log(JSON.parse(sessionStorage.getItem(this.state.contractNumber)));
        this.setState({ activity: act });
    }


    handleToggleSummary() {
        this.setState({ activity: 'contract', showClaimOverview: false, currentClaimOverviewId: '' });
    }


    componentDidUpdate(prevProps) {
        const { tab, subtab } = this.context.getCurrentTabAndSubtab();
        dataLayerPush('Pageview', {
            pageTitle: "Plan Overview",
            pagePath: "/planoverview"
        })

        if (prevProps.contractNumber !== this.props.contractNumber) {
            (subtab?.hideContractSubtab === false) && this.getContract();
        }
    }

    dealerEmail = "";
    async componentDidMount() {
        const profileModel = this.context.getProfile();
        const { tab, subtab } = this.context.getCurrentTabAndSubtab();
        const servicerDetails = this.getServicerDetails(profileModel.dealerId);
        if (this.props.eventData != null) {
            const rfsData = this.getRfsFormData(this.props.eventData.eventId)
        }

        this.dealerEmail = profileModel;
        this.setState({ isInvoiceDealer: profileModel.isInvoiceDealer });

        dateFormat = profileModel.countryId === 2 ? "DD/MM/YYYY" : dateFormat;

        if (this.dealerEmail) {
            if (this.dealerEmail.contacts.length > 0) {
                let last = this.dealerEmail.contacts.length - 1;
                this.dealerEmail = this.dealerEmail.contacts[last].email;
            }
        }
        if(subtab?.hideContractSubtab === undefined || subtab?.hideContractSubtab === false) {
            await this.loadSeries();
            await this.getContract();
            await this.getTax();
        }
        
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        try {
            if (window.scrollY > 99) {
                document.getElementById("mySidenav").style.top = "0px";
                document.getElementById("mySidenav").style.height = "100vh";
                document.getElementById("toggleButton").style.top = "15px";
                document.getElementById("toggleButton").style.height = "100vh";
            } else {
                document.getElementById("mySidenav").style.top = "155px";
                document.getElementById("toggleButton").style.top = "155px";
            }
        }
        catch { }
    }

    getContract() {
        const { tab } = this.context.getCurrentTabAndSubtab()
        const contractNumber = tab?.contractNumber
        const cachedContract = JSON.parse(sessionStorage.getItem(contractNumber))

        if (cachedContract) {
            this.setState({
                contractNumber: cachedContract.contractNumber,
                contract: cachedContract,
                contractLoaded: true,
                enableCoCReprint: false,
                statusId: cachedContract.statusId,
                fetchingUpdates: true
            })
        }

        return new Promise((resolve, reject) => {
            fetch('contract/GetSpecificContract/' + this.props.contractNumber + "/" + this.props.client)
                .then(res => res.json())
                .then(data => {

                    data.statusId = data.statusId === ContractStatusId.Active ? moment(data.planStartDate, 'M/D/YYYY') > moment(new Date())
                        ? ContractStatusId.WaitingPeriod : data.statusId : data.statusId;
                    const statusId = data.statusId;
                    let contractCreateDate = moment(data.createdDate).format("MM-DD-YYYY");
                    let currentDate = new Date();
                    let enableReprint = false;

                    let claimReimbursement = data?.riskProfile?.riskProfileConfigurationModel?.find(r => r.pricingAttributeId == 44)?.value;
                    let coverageTypeValue = data?.riskProfile?.riskProfileConfigurationModel?.find(r => r.pricingAttributeId == 28)?.value;
                    currentDate = moment(currentDate).format("MM-DD-YYYY");
                    if (statusId === ContractStatusId.Active || statusId === ContractStatusId.WaitingPeriod) {
                        enableReprint = true;

                        if (statusId === ContractStatusId.WaitingPeriod) {
                            contractCreateDate = moment(contractCreateDate);
                            currentDate = moment(currentDate);

                            let duration = currentDate.diff(contractCreateDate, 'days');

                            if (duration > waitPeriodInDays) {
                                enableReprint = false;
                            }
                        }
                    }

                    //Hard-coding the claimReimbursementType - Again need backend dev 
                    this.setState({
                        contractNumber: data.contractNumber,
                        contract: data,
                        contractLoaded: true,
                        enableCoCReprint: enableReprint,
                        statusId: data.statusId,
                        assetList: data.assets,
                        coverageTypeDescription: coverageTypeValue,
                        claimReimbursementType: claimReimbursement,
                        //claimReimbursementType: 2,
                        fetchingUpdates: false
                    }, () => {
                        sessionStorage.setItem(contractNumber, JSON.stringify(data))
                        this.setAssetActionState(data.statusId);
                    });
                });
        });
    }

    checkAssetInfoErrors = () => {
        this.setState({
            showCustomerErrorMessages: true
        })
    }

    canFileAClaim = () => {
        this.checkAssetInfoErrors();
        return this.state.setCanFileAClaim;
    }

    checkCustomerErrors = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                checkForCustomerErrors: true
            }, () => {
                if (this.state.customerErrors) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    setNoCustomerErrors = (val) => {
        console.log(val)
        if (val) {
            this.setState({
                //setCanFileAClaim: true,
                showCustomerErrorMessages: false
            })
        }
    }

    resetCanFileAClaim = () => {
        this.setState({
            setCanFileAClaim: false,
            showCustomerErrorMessages: false
        });
    }

    collectCustomerErrors = (errors) => {
        this.setState({
            customerErrors: errors
        })
    }

    loadSeries = async () => {
        try {
            var route = `Asset/GetAllSeries`;
            let seriesResponse = await fetch(route).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                throw new Error(`Network response was not ok ${response.status}`);
            });

            var filtered = seriesResponse.map(s => {
                return {
                    data: s.seriesId,
                    display: s.seriesDescription,
                }
            });

            this.setState({
                series: filtered,
            });

        } catch (error) {
            console.error(error);
        }
    }

    showMarkupModal = async (markupPrice, partPrice) => {
        var model = {
            "accountId": this.context.accountId,
            "contractNumber": this.state.contract.contractNumber,
            "markupPrice": markupPrice,
            "partPrice": partPrice
        }

        var response = await fetch("contract/MarkupCheck/", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        });

        if (response.ok) {
            const result = await response.json();
            return result;
        }
    }

    getTax = async (id) => {
        const profileModel = this.context.getProfile();
        fetch(`tax/GetTaxBillingCodesByState/${profileModel.mailingAddress.stateId}/hvac`).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    this.setState({ taxAttributes: data })
                });
            }
        });
    }

    toggleModal = () => {
        if (this.state.enableCoCReprint) {
            this.setState({ showModal: !this.state.showModal });
        }
    }

    updateSnackBar = (value) => {
        this.setState({ showSnackBar: value });
    }

    getCoC = () => {
        const { contractNumber } = this.state;
        getCoCDoc(contractNumber);
    }

    createSCCURequest = () => {
        const contractId = this.state.contract.contractId;
        createSCCURequest(contractId);
    }

    closeServiceRequestTab = (number) => {
        let { tabDispatch, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab()
        tabDispatch('CLOSE_TAB', { tabId: tab.tabId });
    }

    onViewContract = async () => {
        let { tabDispatch, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab()
        tab.tabType = "contract";
        tab.serviceNumber = "";
        this.context.tabDispatch('SET_ACTIVE_TAB', { activeTab: tab.tabId });
    }

    onViewServiceRequest = async () => {
        let { tabDispatch, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab()
        tab.tabType = "serviceRequest";
        tab.serviceNumber = this.props.eventData.eventNumber;
        this.context.tabDispatch('SET_ACTIVE_TAB', { activeTab: tab.tabId });
    }

    showServiceContractSubTab = () => {
        if (this.props.eventData !== undefined) {
            if (this.props.eventData.eventNumber !== undefined) {
                return true;
            }
        }
        return false;
    }

    formatToMoney = (val) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        });

        return formatter.format(val);
    }
    
    render() {
        const { tab, subtab } = this.context.getCurrentTabAndSubtab()
        const { accountName } = this.context;
        if (!this.state.contract && subtab?.hideContractSubtab === false) {
            return (
                <div className="mainContainerWrapper">
                    <PageLoading text="Contract is Loading" />
                </div>
            );
        }  

        let contractInfoCardTabs = [];

        if(subtab?.hideContractSubtab === undefined || subtab?.hideContractSubtab === false) {
            contractInfoCardTabs = [
                {
                    name: 'Details',
                    component: <ContractInfo contract={this.state?.contract} clientId={this.state?.client} />,
                    headerTitle: "CN: " + this.state?.contract?.contractNumber
                },
                {
                    name: 'History',
                    component: <HistoryTab isSaving={this?.state?.isSaved} contract={this.state?.contract} clientId={this.state?.client} dealerProfile={this.context.getProfile()} />,
                    headerTitle: "CN: " + this.state?.contract?.contractNumber
                }
            ]
        }

        return (
            <div className="tab-pane show active contractFooter" id="main-contract-panel" role="tabpanel" aria-labelledby="main-contract-tab">
                <SubtabNav  />
                <Row>                    
                    <Col lg="12" md="12" sm="12" xs="12">
                        {subtab?.subtabType === 'contract' && (subtab?.hideContractSubtab === false) && 
                            <nav className={styles.sideBar}>
                                <div className="nav flex-column navPills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a
                                        className={classNames(sideNavLink, (!tab.sideNavView || tab.sideNavView === 'details') && activeLink)}
                                        id="cn-details-tab"
                                        data-toggle="pill"
                                        href="#cn-details-panel"
                                        role="tab"
                                        onClick={() => { this.setActivity('details') }}
                                        aria-controls="cn-details-panel"
                                        aria-selected="true"
                                    >
                                        <Paper className={styles.iconOverview} />
                                        Details
                                    </a>
                                    {!this.state.isInvoiceDealer && (
                                        <a
                                            className={classNames(
                                                sideNavLink,
                                                tab.sideNavLink === "transactions" && activeLink
                                            )}
                                            href="#trx-panel"
                                            id="claim-summary-tab"
                                            data-toggle="pill"
                                            role="tab"
                                            onClick={() => {
                                                this.setActivity("transactions");
                                            }}
                                            aria-controls="trx-panel"
                                            aria-selected="false"
                                        >
                                            <Trx className={styles.iconShield} />
                                            Trx
                                        </a>
                                    )}
                                    <a
                                        className={classNames(
                                            sideNavLink,
                                            styles.tcsIcon,
                                            !this.state.enableCoCReprint && styles.disabledLink
                                        )}
                                        id="terms-and-conditions-tab"
                                        data-toggle="pill"
                                        href="#terms-and-conditions"
                                        role="tab"
                                        onClick={() => { this.toggleModal() }}
                                        aria-controls="#terms-and-conditions"
                                        aria-selected="false"
                                    >
                                        <Document className={styles.iconOverview} />
                                        Ts&Cs
                                    </a>
                                </div>
                            </nav>
                        }
                        {(this.props.serviceNumber === undefined
                            || (subtab?.subtabType !== null && subtab?.subtabType !== "serviceRequest"))
                            ? (
                                <section className="col">
                                    {/* TODO: REFACTOR THIS SIDE NAV INTO ITS OWN COMPONENT */}
                                    <Container className="contentWrapper">
                                        {subtab?.subtabType !== null && subtab?.subtabType === 'contract' &&
                                            <>
                                                {this.state.fetchingUpdates &&
                                                    <ThreeDotLoading message='Checking for updates...' />
                                                }

                                                <CustomerInfo
                                                    party={this.state?.contract?.party}
                                                    clientId={this.state.client}
                                                    showErrorMessages={this.state?.showCustomerErrorMessages}
                                                    setNoErrors={(val) => this.setNoCustomerErrors(val)}
                                                    resetCanFileAClaim={this.resetCanFileAClaim}
                                                    ref={this.customerComponent}
                                                />

                                                <Card>
                                                    <SimpleTabs tabs={contractInfoCardTabs} status={this.state.statusId} />
                                                </Card>

                                                <AssetInfo
                                                    stateInfo={this.state}
                                                    contract={this.state?.contract}
                                                    clientId={this.state.client}
                                                    repairHours={this.state?.repairHours}
                                                    onContractAssetSelect={this.collectContractAssetForClaim}
                                                    canFileAClaim={this.canFileAClaim}
                                                    setCanFileAClaim={this.state.setCanFileAClaim}
                                                    resetCanFileAClaim={this.resetCanFileAClaim}
                                                    claimIdToResume={this.state.claimIdToResume}
                                                    claimIdToResumeCreatedByCustomer={this.state.claimIdToResumeCreatedByCustomer}
                                                    actionDisabled={this.state.actionDisabled}
                                                    series={this.state?.series}
                                                    claimReimbursementType={this.state?.claimReimbursementType}
                                                    coverageTypeDescription={this.state?.coverageTypeDescription}
                                                />
                                            </>
                                        }

                                        {subtab?.subtabType !== null && subtab?.subtabType === 'claiminvoice' && (
                                            <Claim contract={this.state?.contract} />
                                        )}
                                        
                                        {subtab?.subtabType !== null && subtab?.subtabType === 'staticclaiminvoice' && (
                                            <StaticClaim contract={this.state?.contract} />
                                        )}

                                        <TermsAndConditionsModal
                                            toggle={() => { this.toggleModal() }}
                                            isOpen={this.state.showModal}
                                            updateSnackBar={(value) => { this.updateSnackBar(value) }}
                                            cutsormerEmail={
                                                this.state?.contract?.party?.partyEmailModel?.length > 0
                                                    ? this.state?.contract?.party?.partyEmailModel?.[0].address
                                                    : undefined
                                            }
                                            dealerEmail={this.dealerEmail}
                                            getCoC={this.getCoC}
                                            createSCCURequest={this.createSCCURequest}
                                            accountName={accountName}
                                        />

                                        <SnackBarAlert
                                            content="Reprint request submitted successfully."
                                            onClose={() => this.setState({ showSnackBar: false })}
                                            showSnackBar={this.state.showSnackBar}
                                        />
                                    </Container>


                                    {tab.sideNavView === "transactions" &&
                                        <div
                                            // most of this SHOULD be obsolete by moving to tab-controlled views
                                            className={classNames(
                                                "tab-pane fade",
                                                {
                                                    show: tab.sideNavView === "transactions",
                                                    active: subtab.subtabType === "contract" && tab.sideNavView === "transactions"
                                                },
                                            )}
                                            id="trx-panel"
                                            role="tabpanel"
                                            aria-labelledby="cn - trx - tab"
                                        >
                                            <Container className="contentWrapper">
                                                <Transactions contract={this.state.contract} clientId={this.state.client} />
                                            </Container>
                                        </div>
                                    }
                                </section>
                            ) : (this.state.rfsFormData &&
                                <ServiceRequest
                                    client={this.state?.client}
                                    contract={this.state?.contract}
                                    customerInfo={this.state?.contract?.party}
                                    servicerDetails={this.state?.servicerDetails}
                                    eventData={this.props?.eventData}
                                    rfsFormData={this.state?.rfsFormData}
                                    laborRate={this.state?.contract?.laborRate > 0 ? this.formatToMoney(this.state?.contract?.laborRate) : '$0.00'}
                                    tripRate={this.state?.contract?.tripRate > 0 ? this.formatToMoney(this.state?.contract?.tripRate) : '$0.00'}
                                />
                            )
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}
Contract.contextType = SessionContext;