import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

export class ZipInput extends Component {


    //render() {
    //    return (
    //        <div>
    //            <input type="zip" maxLength={5} placeholder="12345" />
    //        </div>
    //    );
    //}

    constructor(props) {
        super(props);
        this.state = {
            placeHolder: props.placeHolder
        };
    }

    render() {
        return (
            <>
                <Input type="text" maxLength={5} placeholder="" id="zipInput" className="zipCodeField form-control"
                    aria-label="Default" aria-describedby="inputGroup-sizing-default"
                    name={this.props.name} onChange={this.props.onChangedValue} value={this.props.value}
                    />
            </>
        );
    }
}