import React from 'react'
import styles from './TableConfig.module.css';
import { widthFormatter } from "../Formatters/widthFormatter";
import {
    QuantityInput,
    BasicInput,
    PriceInput,
    CurrencyField,
    DeleteRowButton,
    EditRowButton,
    DependentDropdown,
    HeaderFormatter,
} from "../Formatters";

export const REFRIGERANT_COLS = [
    {
        text: '',
        dataField: '',
        isDummyColumn: true,
        style: {
            ...widthFormatter('deleteCol'),
            padding: 0
        },
        formatter: (col, row, rowI) => <DeleteRowButton rowId={row.id} rowIndex={rowI} tableType='refrigerant' />,
        align: 'center',
        footerClasses: [styles.footer, styles.noborder, styles.trashIcon]
    },
    {
        dataField: "qty",
        text: "Lbs",
        align: "center",
        style: widthFormatter('qtyCol'),
        formatter: (col, row, rowI) => {
            if (row.refrigerantType === 'Reclaim') return 'N/A';
            return (
                <div className={styles.makeQuantityInputCenter}>
                    <QuantityInput cell={col} row={row} rowIndex={rowI} fieldName={"qty"} tableType='refrigerant' />
                </div>
            )
        }
    },
    {
        dataField: "refrigerantType",
        text: "Type",
        style: widthFormatter('tripCol'),
        formatter: (col, row, rowI) => {
            if (row.replacementDetails) return row?.asset?.selected?.[0]?.assetName;
            if (row.refrigerantType === 'Reclaim') return 'Reclaim';
            return (
                <div className={styles.assetColumn}>
                    <DependentDropdown
                        key={`${row?.asset?.selected?.[0]?.assetName}_${rowI}`}
                        cell={col}
                        row={row}
                        rowIndex={rowI}
                        tableType={'refrigerant'}
                        fieldName={"refrigerantType"}
                    />
                </div>
            )
        }
    },
    {
        text: '',
        dataField: '',
        isDummyColumn: true,
        style: widthFormatter('dummyTripCol'),
    },
    {
        dataField: "price",
        text: "Price",
        headerAlign: 'left',
        align: 'right',
        style: widthFormatter('invoiceTotalCol'),
        headerFormatter: (col) => (<HeaderFormatter col={col} centered={true} toolTipRequired={true} fieldName={'refrigerantPrice'} />),
        formatter: (c, r, rI) => (
            <PriceInput cell={c} row={r} rowIndex={rI} fieldName={"price"} tableType='refrigerant' />
        )
    },
    {
        dataField: "total",
        text: "Total",
        headerAlign: 'left',
        align: 'right',
        style: widthFormatter('invoiceTotalCol'),
        formatter: (col, row, i) => {
            const total = row.qty * row.price;
            return <CurrencyField col={total} row={row} rowIndex={i} fieldName="total" tableType="refrigerant" />
        }
    }
];
