import React, { useState, useEffect, useContext, createContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { verifyDealerAccess, getLocalDealer, getDealerProfile, updateAccessToken, getClient, verifyToken } from "./AuthHelpers"
import PageLoading from '../components/shared/PageLoading/PageLoading';

const AuthContext = createContext(null);

const AuthProvider = ({ children, directRoutes }) => {
    const { pathname } = useLocation();
    const history = useHistory();
    const shakeNeeded = pathname.includes('handshake');
    const isDirectRoute = directRoutes.find(r => pathname.includes(r)) != null;
    const [authState, setAuthState] = useState({
        isHandshaking: true,
        dealerId: null,
        isTokenError: null,
        isAuthenticated: false,
        accountName: "",
        token: "",
        isExternalLogin: false,
        targetTab: "",
        loginError: null,
        targetTabFilter: "",
    });

    const isSessionValid = () => {
        return authState.isAuthenticated && authState.dealerId != null && authState.dealerId > 0;
    }

  useEffect(() => {
    const authorize = async () => {
      
      var externalLogin = await verifyToken();
      var authModel = await verifyDealerAccess();
      var isAuthenticated = false;
      var dealerId = null;
      var tokenError = false;
      var isExternalLogin = false;
        var targetTab = "";
        var targetTabFilter = "";

        if (isDirectRoute) {
            //console.log(authModel)
            if (authModel?.accountName == null) {
                var account = await getClient();

                if (account != null) {
                    authModel.accountName = account;
                }

            }

            setAuthState({
                ...authState,
                accountName: authModel?.accountName ? authModel.accountName : "",
                isHandshaking: false
            });
            return;
        }

      if (shakeNeeded) {
        if (authModel == null || !authModel.success) {
          authModel = await getLocalDealer();
        }

                if (authModel != null && authModel.success) {
                    isAuthenticated = true;
                    dealerId = authModel.dealerId;

                } else {
                    tokenError = (authModel == null || authModel.isTokenError);
                }
            }
            else {
                if (authModel == null || !authModel.success) {
                    isAuthenticated = false;

                    if (authModel?.accountName == null) {
                        var account = await getClient();

                        if (account != null) {
                            authModel.accountName = account;
                        }

                    }
                } else {
                    dealerId = authModel.dealerId;
                    isAuthenticated = true;
                }
            }

            if (externalLogin !== null) {
                if (externalLogin.loginSuccessful) {
                    targetTab = externalLogin.targetTab;
                    targetTabFilter = externalLogin.filter;
                    isExternalLogin = true;
                }
            }

            setAuthState({
                isHandshaking: false,
                dealerId: dealerId,
                isTokenError: tokenError,
                isAuthenticated: isAuthenticated,
                accountName: authModel?.accountName ? authModel.accountName : "",
                token: authModel != null && authModel.token != null ? authModel.token : "",
                targetTab: targetTab,
                isExternalLogin: isExternalLogin,
                isFromOEMPP: authModel != null && authModel.isFromOEMPP != null && authModel.isFromOEMPP,
                errorCode: authModel != null && authModel.errorCode != null ? authModel.errorCode : null,
                targetTabFilter: targetTabFilter,
            });
        }
        authorize();
    }, []);

    const updateAuthState = (newVals) => {
        setAuthState({
            ...authState,
            ...newVals
        })
    }

    if (authState.isHandshaking)
        return <PageLoading />;

    return (
        <AuthContext.Provider value={{
            ...authState, history, isSessionValid, updateAuthState
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext }