import React from 'react';
import { Badge } from 'reactstrap';

export const ContractStatus = (props) => {
    return (
    <>
        { props.statusId === 1 && <Badge color="success">Active</Badge> }
        { props.statusId === 2 && <Badge color="danger">Inactive</Badge>}
        { props.statusId === 3 && <Badge color="danger">Fulfilled</Badge>}
        { props.statusId === 4 && <Badge color="danger">Cancelled</Badge> }
        { props.statusId === 6 && <Badge color="danger">Suspended</Badge> }
        { props.statusId === 7 && <Badge color="danger">Expired</Badge>}
        { props.statusId === 8 && <Badge color="warning">Pending</Badge>}
        { props.statusId === 10 && <Badge color="warning">Wait</Badge> }
    </>
    );
}