import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import styles from "../Sales/PaymentInformation/PaymentMethod.module.css";

export const DeleteCard = ({ isOpen, toggle, centered, onDelete }) => {
    return (
        <Modal isOpen={isOpen}
            toggle={toggle}
            className="deleteCard"
            centered={centered}>

            <div className={styles.deleteCardHeader}>
                <h4>Delete Saved Card</h4>
            </div>

            <ModalBody>
                <p>Are you sure you want to delete your saved card?</p>
            </ModalBody>

            <ModalFooter>
                <button className="btnLink" onClick={toggle}>
                    Cancel
                    </button>

                <button className="btn-primary" onClick={onDelete}>Delete Card</button>
            </ModalFooter>
        </Modal>
    );
};
