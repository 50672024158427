import React, { useState, forwardRef, useEffect, useContext, useImperativeHandle } from 'react';
import { CardBody, Row, Col, FormGroup } from 'reactstrap';
import styles from './InsuranceDocument.module.css';
import StandardInput from "../shared/StandardInput/StandardInput";
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import { ReactComponent as DeleteContactSVG } from '../../assets/images/trash.svg';
import AddButton from '../shared/IconButtons/AddButton';
import UploadDocs from '../shared/UploadDocs/UploadDocs';
import TaxFormFormat from './taxFormFormat';
import { labelDetails, countries } from './constant';
import { handleFileInfo, removeUploadedFile, storeDocsToSessionStorage, getUploadedDocs } from '../Global/DocumentUploadHelper';
import { SessionContext } from '../../context/SessionContext';
import { DuplicateValidationModal } from "./DuplicateValidationMoal";

const federalDetails = "federalDetails";
const sessionStorageBaseId = "federalDoc"
const maxFilesAllowed = 1; // maximum number of files that can be uploaded at once
const maxDocumentsAllowed = 4; // maximum number of documents that can be added.
const docFormTypes = {
    FEIN: 'FEIN/EIN',
    FLForm: 'FL252',
    CAForm: 'GST/HST'
}
const docTypeId = 30; // Insurance doc

const FederalLicensing = forwardRef((props, ref) => {
    const context = useContext(SessionContext);
    const { validator, errors } = props;
    const [forceUpdate, setForceUpdate] = useState(0);
    const [formData, setFormData] = useState({});
    const [validationFired, setValidationFired] = useState(false);
    const [taxList, setTaxList] = useState([]);
    const vendorSessionData = JSON.parse(sessionStorage.getItem('vendorDetails'));
    const { country: countryId, physicalAddress: { State: stateId }, stateOptions, vendorType = [] } = vendorSessionData;
    const country = countries.find(data => data.data === countryId).display;
    const state = stateOptions?.find(data => data.data === stateId)?.display;
    const label = labelDetails[country];
    const [storageList, setStorageList] = useState([]);
    const [fileUploadDetails, setFileUploadDetails] = useState([]);
    const [federalDocList, setFederalDocList] = useState({
        fein: [],
        flForm: [],
        caForm: [],
    });
    const [showValidationModal, setShowValidationModal] = useState(false);
    const [duplicateFields, setDuplicateFields] = useState([]);


    useEffect(() => {
        const sessionValue = JSON.parse(sessionStorage.getItem(federalDetails));

    if (sessionValue != undefined) {
      setFormData(sessionValue.formData);
      setTaxList(sessionValue.taxList);
      setStorageList([]);
      setSessionFederalDocList(sessionValue);
    }
  }, []);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            validator.showMessages();
            setValidationFired(true);
            setForceUpdate(1);
        }
    }, [errors, validator]);

    useEffect(() => {
        sessionStorage.setItem(federalDetails, JSON.stringify({
            forceUpdate, formData, validationFired, taxList, storageList
        }));
    }, [forceUpdate, formData, validationFired, taxList, storageList]);


    useEffect(() => {
        const sessionIds = storeDocsToSessionStorage(fileUploadDetails, sessionStorageBaseId);
        setStorageList(sessionIds);

    }, [fileUploadDetails]);

    useImperativeHandle(ref, () => ({
        async checkDuplicates() {
            try {
                if (!formData.taxFormName) return true
                const payload = {
                    "partyEmailId": "",
                    "partyPhone": "",
                    "partyTaxId": formData.taxFormName.match(/\d+/g).join('')
                }
                const postPayload = {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }
                var route = `party/CheckByPartyDuplicateDetails/aig`;
                let resp = await fetch(route, postPayload)
                    .then(res => res.json());

                if (resp?.response) {
                    const duplicateFieldNames = [];
                    if (resp?.response?.includes("PartyTaxId")) duplicateFieldNames.push("Tax ID")
                    setShowValidationModal(true)
                    setDuplicateFields(duplicateFieldNames)
                    return false
                }
                return true
            } catch (error) {
                console.error(error);
            }
        }
    }));


    const handleInputChange = (e, index) => {
        const { target: { name, value } } = e;
        if (!isNaN(index)) {
            taxList[index][name] = value;
            setTaxList([...taxList]);
            return;
        }
        setFormData({ ...formData, [name]: value });
    }

    const onHandleSelect = (value, name, index) => {
        if (!isNaN(index)) {
            taxList[index][name] = value;
            setTaxList([...taxList]);
            return;
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const addTax = () => {
        if (taxList.length < maxDocumentsAllowed) {
            taxList.push({});
            setTaxList([...taxList]);
        }
    }

    const handleFileChange = (files, name, index) => {
        if (!isNaN(index)) {
            taxList[index][name] = files;
            setTaxList(taxList);
            return;
        }
        setFormData({ ...formData, [name]: files });

        if (files.length == 0) {
            removeFile(undefined);
        }
    }

    const deleteTax = (index) => {
        const tax = taxList.filter((value, i) => i !== index);
        setTaxList([...tax]);

        removeFile(index);
    }

  const handleFileUpload = (fileInfo, data, docFormType, docFormIndex, documentTypeId, fileUploadDetails) => {
    const uploadDetails = handleFileInfo(fileInfo, data, docFormType, docFormIndex, documentTypeId, fileUploadDetails);
    const sessionIds = storeDocsToSessionStorage(uploadDetails, sessionStorageBaseId);
  
    setStorageList(sessionIds);
    context.setUploadedDocs( uploadDetails, sessionStorageBaseId);
    setFileUploadDetails(uploadDetails);
    updateFederalDocList(uploadDetails);
  }

    const updateFederalDocList = (docs) => {
        setFederalDocList({
            fein: docs?.filter((x) => x?.docFormType == docFormTypes.FEIN) ?? [],
            flForm: docs?.filter((x) => x?.docFormType == docFormTypes.FLForm) ?? [],
            caForm: docs?.filter((x) => x?.docFormType == docFormTypes.CAForm) ?? [],
        })
    }

  const setSessionFederalDocList = (sessionVal) => {
    const canadaSessionTaxList = sessionVal?.taxList?.filter((x) => x?.taxFormFile[1].docFormType === docFormTypes.CAForm);
    const canadaSessionDocs = canadaSessionTaxList.map((test) => test.taxFormFile[1]);
    setFederalDocList({
      fein: sessionVal?.formData?.taxFormFile?.filter((x) => x?.docFormType === docFormTypes.FEIN) ?? [],
      flForm: sessionVal?.formData?.floridaLic?.filter((x) => x?.docFormType === docFormTypes.FLForm) ?? [],
      caForm: canadaSessionDocs ?? [],
    })
  }

  const removeFile = (index) => {
    const updatedList = removeUploadedFile(index, fileUploadDetails, storageList);
    context.setUploadedDocs(updatedList, sessionStorageBaseId);
    setFileUploadDetails(updatedList);
    updateFederalDocList(updatedList)
  }

    const toggleModal = () => {
        setShowValidationModal(!showValidationModal)
    }

    const showTaxStatus = () => {
        const dealerSessionData = JSON.parse(sessionStorage.getItem('questionData'));
        const isCNS = dealerSessionData?.dealerQuestions?.questions?.find(data => data.name === "isCNS")?.answer;
        return vendorType.includes("servicer") || isCNS === "Yes"
    }


    const showFloridaSection = () => {
        const dealerSessionData = JSON.parse(sessionStorage.getItem('questionData'));
        const isSellingHomeFloria = dealerSessionData?.distributorQuestions?.questions?.find(data => data.name === "isSellingHome")?.answer;
        return vendorType.includes("dealer") || isSellingHomeFloria === "Yes"
    }

    return (
        <>
            <Col xs="12" md="12" className={`titleRow p-4`}>
                <h2 className="h4 m-0">Federal Licensing & Documentation</h2>
            </Col>
            <hr className="m-0 w-100" />
            <CardBody>
                <Row className='m-0'>
                    <p className='m-0'>
                        {label.description}
                    </p>
                    <Row className='w-100 multiColWrapper relative pt-4'>
                        <Col xs="12" md="4" className="col-5ths">
                            <StandardInput
                                // hasError={validator.message("taxForm", formData.taxForm, "required")}
                                ariaLabelAndFieldId="taxForm"
                                labelCustomClass="font-weight-bold"
                                label={`Name Shown on ${label.taxForm}`}
                                fieldName="taxForm"
                                value={formData.taxForm}
                                onChange={handleInputChange}
                                // validator={validator.message(label.taxForm, formData.taxForm, "required", { messages: { required: `Name Shown on ${label.taxForm} is required.` } })}
                                required={false}
                            />
                        </Col>
                    </Row>
                    <Row className='w-100 multiColWrapper relative'>
                        <Col xs="12" md="4" className="col-5ths">
                            <FormGroup className={/*validationFired && !validator.check(formData.taxStatus, "required") ? 'form-error' : */''}>
                                <label for="taxStatus" className="form-label">Tax Status</label>
                                {/* <span aria-hidden="true" className="required-indicator">*</span> */}
                                <DropDownAutoComplete
                                    name="taxStatus"
                                    className="form-control"
                                    placeholder="Please select..."
                                    //aria-required="true"
                                    id="taxStatus"
                                    defaultValue={formData.taxStatus}
                                    options={label.taxStatus}
                                    onChangedValue={onHandleSelect}
                                    value={formData.taxStatus}
                                />
                                {/* validator.message("taxStatus", formData.taxStatus, "required") */}
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="4" className="col-5ths">
                            <TaxFormFormat
                                taxFormName={formData.taxFormName}
                                handleInputChange={handleInputChange}
                                taxStatus={formData.taxStatus}
                                validator={validator}
                                label={label.taxForm}
                                validationFired={validationFired}
                            />
                        </Col>
                    </Row>
                    <p className='w-100 mb-2 form-label'>Please upload a copy of your {label.formName} form.</p>
                    <UploadDocs onFileChange={(files) => handleFileChange(files, "taxFormFile")} handleFileInfo={handleFileUpload} maxFiles={maxFilesAllowed} prevFileUploadDetails={federalDocList.fein} docFormType={docFormTypes.FEIN} documentTypeId={docTypeId} />
                    {((country === "USA" && (["Florida", "Alabama", "Georgia"].includes(state))) || country === "Canada") &&
                        <hr className="m-0 w-100" />
                    }
                    {(country === "USA" && (["Florida", "Alabama", "Georgia"].includes(state)) && showFloridaSection()) &&
                        <>
                            <Col xs="12" md="12" className={`titleRow pl-0 pt-4 pb-4`}>
                                <h5 className="m-0">Florida 2-52 License</h5>
                            </Col>
                            <Row className='w-100 multiColWrapper relative'>
                                <Col xs="12" md="4" className="col-5ths">
                                    <StandardInput
                                        //hasError={validator.message("licenseNo", formData.licenseNo, "required")}
                                        ariaLabelAndFieldId="licenseNo"
                                        labelCustomClass="font-weight-bold"
                                        label="License Number"
                                        fieldName="licenseNo"
                                        value={formData.licenseNo}
                                        onChange={handleInputChange}
                                        //validator={validator.message("licenseNumber", formData.licenseNo, "required")}
                                        required={false}
                                    />
                                </Col>
                            </Row>
                            {<p className='w-100 mb-2 form-label'>Please upload a copy of your Florida 2-52 License form.</p>}
                            {<UploadDocs onFileChange={(files) => handleFileChange(files, "floridaLic")} handleFileInfo={handleFileUpload} maxFiles={maxFilesAllowed} prevFileUploadDetails={federalDocList.flForm} docFormType={docFormTypes.FLForm} documentTypeId={docTypeId} />}
                        </>
                    }
                    {(country === "Canada" && showTaxStatus()) &&
                        <>
                            <Col xs="12" md="12" className={`titleRow pl-0 pt-4 pb-4`}>
                                <h5 className="m-0"> Do you sell in another province?</h5>
                            </Col>
                            <Row className="m-0 pb-4">
                                <AddButton
                                    onClick={() => addTax()}
                                    btnName="Add Additional Tax ID"
                                />
                            </Row>
                            {taxList.map((value, index) => {
                                const prevFileUploadDetails = federalDocList.caForm.filter((x) => x.docFormIndex == index)

                                return <Row className={`m-0 w-100 ${styles.taxWrapper}`} key={index} >
                                    <div className={styles.taxContainer}>
                                        <Row className='w-100 multiColWrapper relative'>
                                            <Col xs="12" md="4" className="col-5ths">
                                                <FormGroup>
                                                    {/* // className={validationFired && !validator.check(value.taxStatus, "required") ? 'form-error' : ''}> */}
                                                    <label for="taxStatus" className="form-label">Tax Status</label>
                                                    {/*<span aria-hidden="true" className="required-indicator">*</span>*/}
                                                    <DropDownAutoComplete
                                                        name="taxStatus"
                                                        className="form-control"
                                                        placeholder="Please select..."
                                                        //aria-required="true"
                                                        id="taxStatus"
                                                        defaultValue={value.taxStatus}
                                                        options={label.taxStatus}
                                                        onChangedValue={(value, name) => onHandleSelect(value, name, index)}
                                                        value={value.taxStatus}
                                                    />
                                                    {/* validator.message("taxStatus", value.taxStatus, "required") */}
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" md="4" className="col-5ths">
                                                <TaxFormFormat
                                                    taxFormName={value.taxFormName}
                                                    handleInputChange={(e) => handleInputChange(e, index)}
                                                    taxStatus={value.taxStatus}
                                                    validator={validator}
                                                    label={label.taxForm}
                                                    validationFired={validationFired}
                                                />
                                            </Col>
                                        </Row>
                                        <p className='w-100 mb-2 form-label'>Please upload a copy of your {label.formName} form.</p>
                                        <UploadDocs customClass="mb-3" onFileChange={(files) => handleFileChange(files, "taxFormFile", index)} handleFileInfo={handleFileUpload} maxFiles={maxFilesAllowed} prevFileUploadDetails={prevFileUploadDetails} docFormType={docFormTypes.CAForm} docFormIndex={index} documentTypeId={docTypeId} />
                                    </div>
                                    <div md={1} className={styles.deleteContainer}>
                                        <DeleteContactSVG className={styles.trashIcon} onClick={() => deleteTax(index)} />
                                    </div>
                                </Row>
                            })}
                        </>
                    }
                </Row>
                <DuplicateValidationModal
                    isOpen={showValidationModal}
                    toggle={toggleModal}
                    duplicateFields={duplicateFields}
                />
            </CardBody>
        </>
    );
});

export default FederalLicensing;