import React, { Component } from 'react';
import Select from 'react-select';

export class DropDownAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValueText: '',
      defaultValue: this.props.defaultValue,
      optionsList: this.configureOptionsList(this.props.options),
      inputValue: "",
    };

    this.handleStateChange = this.handleStateChange.bind(this);
  }

  configureOptionsList = (optionsObj) => {
    let newList = []
    if (Array.isArray(optionsObj) && optionsObj.length > 0) {
      newList = optionsObj.map(obj => ({ ...obj, label: obj.display, value: obj.data }));
    }
    return newList;
  }


  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.value !== this.state.defaultValue) {
      this.setState({ defaultValue: nextProps.value })
    }

    if (nextProps.options !== this.props.options) {
      const list = this.configureOptionsList(nextProps.options);
      this.setState({ optionsList: list })
    }
  }

    handleStateChange = (selectedValueText, eve) => {
        this.setState({ selectedValueText });
        this.props.onChangedValue(selectedValueText.value, eve.name);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && this.props.value != prevProps.value) {
      this.setState({
        defaultValue: this.props.value,
      });
    }
  }

  filterOption = (option, inputValue) => {
    if (option != null && option.label != null && inputValue != null)
      return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  }

  render() {
      const { stateFlag } = this.props;
     
    return (
      <React.Fragment>
        <Select
          value={this.props.value === "" ? "" : this.state.optionsList.filter(o => o.data === this.state.defaultValue)[0]}
          label="Single select"
          classNamePrefix='autoDrop'
          placeholder={this.props.placeholder || 'Please select...'}
          onChange={this.handleStateChange}
          options={this.state.optionsList}
          filterOption={this.filterOption}
          name={this.props.name}
          isDisabled={this.props.disabled}
          borderColor="red"
          styles={{
            control: (provided, state) => ({
              ...provided,
              border: this.props.noDropDownBorder && "none",
              boxShadow: this.props.noDropDownBorder && "none",

            }),
          }}
          autocomplete={(stateFlag && stateFlag !== undefined) ? "address-level1" : "off"}
        />
      </React.Fragment>
    );
  }
}