import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const CancelEntry = ({ isOpen, toggle, centered, saveAuthorizationForm,cancelNavigation }) => {
    return (
        <Modal isOpen={isOpen}
            toggle={toggle} cancelNavigation={cancelNavigation}
            className="modal-md"
            centered={centered}>
          <ModalHeader>
            <h1 className="h4 mb-0">Cancel Entry</h1>
          </ModalHeader>
          <ModalBody>
            <p className="mb-3">Are you sure you want to cancel this entry? All unsaved changes will be lost.</p>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">            
            <button className="btn-secondary" onClick={toggle} >Resume Entry</button>
            <button className="btn-primary" onClick={cancelNavigation}>Cancel Entry</button>
          </ModalFooter>
        </Modal>
    );
};
