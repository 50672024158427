import React, { useEffect, useState } from "react";
import ContentPanelLayout from './ContentPanelLayout';
import PdfContainer from "./PdfContainer";
import { getDealerDocument } from '../Global/DocumentHelper';

const DealerDocumentContainer = ({ accountDocumentType, profile, identifier = '', title, subHeading = '', hasScroll = false }) => {
	const [pdfLocation, setPdfLocation] = useState('doc');
	const [pdfViewLocation, setPdfViewLocation] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		getDealerDocument(accountDocumentType, false, profile, setDocLink, identifier);
	}, [])

	const setDocLink = (res) => {
		if (res?.success) {
			setPdfLocation(res.documentPath);
			setPdfViewLocation(res.documentURL);
			setLoading(false);
		}
	}

	return (
		<>
			{!loading &&
				<ContentPanelLayout title={title} pdf={pdfLocation} displayPrintAndDownload subHeading={subHeading}>
					<PdfContainer pdf={pdfViewLocation} hasScroll={hasScroll} />
				</ContentPanelLayout>
			}
		</>
	)
}
export default DealerDocumentContainer;