import React, { useState, useEffect, useRef, useContext } from "react";
import { Row, Col, Label, Input, FormGroup, Container } from "reactstrap";
import classNames from "classnames";
import styles from "./Login.module.css";
import SimpleReactValidator from "simple-react-validator";
import Cookies from "js-cookie";
import { SessionContext } from "../context/SessionContext";
import EnrollmentModal from "../components/Modals/Enrollment/Enrollment";
import { useHistory } from 'react-router-dom';
import { AuthErrorCodes } from '../components/Global/AuthConstants';
import { getBrandContent } from "../components/BrandingWhiteLabel/BrandContent";
import { ReactComponent as IncreaseProfit } from '../assets/images/home-warranty.svg';
import { ReactComponent as CheckMark } from '../assets/images/checkmark.svg';
import { ReactComponent as AIGLogo } from '../assets/images/Vendor/AIG/aig-logo.svg';
import { ReactComponent as GEHandshakeCircle } from '../assets/images/Vendor/GE/handshake-circle.svg';
import { ReactComponent as GEPeopleCircle } from '../assets/images/Vendor/GE/people-circle.svg';
import { ReactComponent as GECustomerCircle } from '../assets/images/Vendor/GE/customers-circle .svg';
import { ReactComponent as GEQuotation } from '../assets/images/Vendor/GE/quote-mark.svg';
import { ReactComponent as GEClose } from '../assets/images/close_med.svg';
import BrandedIcon from '../components/BrandingWhiteLabel/BrandedIcon';
import CustomerInfoImage from '../assets/images/customer-benefits.png';

const LoginContent = () => {
  const sessionContext = useContext(SessionContext);
  const { accountName } = sessionContext;
  const accountNameToLower = accountName.toLowerCase();
  const currentBrand = getBrandContent(accountName);

  const [dealerFlag, setDealerFlag] = useState(false);
  const [customerFlag, setCustomerFlag] = useState(false);  
  const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);

  const handleBenefits = (item) => {
    if(item === "dealer") {
      setDealerFlag(!dealerFlag);
      setCustomerFlag(false);
    } else {
      setCustomerFlag(!customerFlag);
      setDealerFlag(false);
    }
  }

  const handleShowActionModal = () => {
    setShowEnrollmentModal(true);
  };

  return (
    <>
      {accountNameToLower !== 'ge' && <>
        <Container fluid className={classNames(styles.loginContentContainer, "pt-5")}>
          <Container className="py-3">
            <Row className="pb-4">
              <Col lg="5" md="5" sm="5" xs="12" className={classNames("pt-3", styles.leftColumn)}>
                <h1 className="ml-3 mt-5 pr-5">We make it easy with two simple sales options.</h1>
              </Col>
              <Col lg="7" md="7" sm="7" xs="12" className={classNames(styles.loginParagraph, "pl-5")}>
                <p>With <span className={styles.textHighlight}>{currentBrand.advantageProgramName}</span>, pre-selected labor protection will automatically apply every time you register the product  — extending peace of mind and protection to every customer while eliminating the administrative burden of warranty processing.</p>
                {accountNameToLower !== "lennox" && <p>With <span className={styles.textHighlight}>{currentBrand.porProgramName}</span>, purchase one contract at a time for individual registrations using our simple warranty administration platform.</p>}
                {accountNameToLower === "lennox" && <p>With <span className={styles.textHighlight}>Select</span>, purchase one contract at a time for individual registrations using our simple warranty administration platform.</p>}
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className={classNames((accountNameToLower === "lennox" ? (styles.loginContentContainer, "pt-5") : (styles.loginContentContainerRheem, "py-5")))}>
          <Container>
            <Row className="py-4">
              <Col lg="6" md="6" sm="6" xs="12">
                <Row>
                  <Col sm="12" xs="12" className="mb-4">
                    <div className="media">
                      <span className={classNames(styles.loginIcon, "mr-3")}>
                          <BrandedIcon accountName={accountName} iconType={'graphs'} />
                      </span>
                      <div className={styles.loginParagraph}>
                        <h2 className="mb-2">Increase Profits</h2>
                        Boost revenue and close rates by offering unique service options with every sale
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" xs="12" className="mt-4">
                    <div className="media">
                      <span className={classNames(styles.loginIcon, "mr-3")}>
                          <BrandedIcon accountName={accountName} iconType={'lock'} />
                      </span>
                      <div className={styles.loginParagraph}>
                        <h2 className="mb-2">Lock in Service Revenue</h2>
                        Establish guaranteed service revenue that offers competitive reimbursement rates
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="6" md="6" sm="6" xs="12">
                <Row>
                  <Col sm="12" xs="12" className="mb-4">
                    <div className="media">
                      <span className={classNames(styles.loginIcon, "mr-3")}>
                          <BrandedIcon accountName={accountName} iconType={'ratings'} />
                      </span>
                      <div className={styles.loginParagraph}>
                        <h2 className="mb-2">Build Customer Loyalty</h2>
                        Gain a customer for life by building loyalty and trust through quality service
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" xs="12" className="mt-4">
                    <div className="media">
                      <span className={classNames(styles.loginIcon, "mr-3")}>
                          <BrandedIcon accountName={accountName} iconType={'checkboxes'} />
                      </span>
                      <div className={styles.loginParagraph}>
                        <h2 className="mb-2">Enjoy Flexible Options</h2>
                        Select from multiple coverage, reimbursement and sales options for you and your customer
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      </>}

      {accountNameToLower === "ge" && 
        <Container fluid className={classNames(styles.bodyBackground, styles.bodyBackgroundMobile)}>
          <Container className={classNames(styles.loginContentBottomGE)}>
            <Row>
              <Col className="text-center">
                <h1>
                  Grow Your Business with Labor Protection
                </h1>
                <div className={classNames(styles.loginContentTextGE)}>
                  Comfort Protect Extended Service Agreements (ESAs) are a great way to add revenue 
                  to your business without requiring additional equipment inventory or overhead costs. 
                  While the system you install provides a product to your customer, including an 
                  extended service agreement builds a lifetime relationship with them.
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" sm="6" xs="12">
                <Row>
                  <Col sm="12" xs="12" className={classNames(styles.businessProtectionIcon, "pt-5")}>
                    <div className="media">
                      <span className={classNames(styles.loginIconGE, "mr-3")}>
                          <BrandedIcon accountName={accountName} iconType={'people'} />
                      </span>
                      <div className={(accountNameToLower === "ge") ? styles.loginParagraphGE : styles.loginParagraph}>
                        <h3 className="mb-2">Increase Profits</h3>
                        Boost revenue and close rates by offering unique service options with every sale
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" xs="12" className={classNames(styles.businessProtectionIcon, "pt-5")}>
                    <div className="media">
                      <span className={classNames(styles.loginIconGE, "mr-3")}>
                          <BrandedIcon accountName={accountName} iconType={'revenuehand'} />
                      </span>
                      <div className={(accountNameToLower === "ge") ? styles.loginParagraphGE : styles.loginParagraph}>
                        <h3 className="mb-2">Lock in Service Revenue</h3>
                        Establish guaranteed service revenue that offers competitive reimbursement rates
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="6" md="6" sm="6" xs="12">
                <Row>
                  <Col sm="12" xs="12" className={classNames(styles.businessProtectionIcon, "pt-5")}>
                    <div className="media">
                      <span className={classNames(styles.loginIconGE, "mr-3")}>
                          <BrandedIcon accountName={accountName} iconType={'ratings'} />
                      </span>
                      <div className={(accountNameToLower === "ge") ? styles.loginParagraphGE : styles.loginParagraph}>
                        <h3 className="mb-2">Build Customer Loyalty</h3>
                        Gain a customer for life by building loyalty and trust through quality service
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" xs="12" className={classNames(styles.businessProtectionIcon, "pt-5")}>
                    <div className="media">
                      <span className={classNames(styles.loginIconGE, "mr-3")}>
                          <BrandedIcon accountName={accountName} iconType={'advantagecheck'} />
                      </span>
                      <div className={(accountNameToLower === "ge") ? styles.loginParagraphGE : styles.loginParagraph}>
                        <h3 className="mb-2">Enjoy Flexible Options</h3>
                        Select from multiple coverage, reimbursement and sales options for you and your customer
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      }
      {accountNameToLower !== "ge" && <>
        <Container fluid className={classNames((accountNameToLower !== "lennox" ? styles.loginContentContainer : styles.loginContentContainerRheem), "py-3")}>
          <Container className="pt-3">
            <Row className="py-4">
              <Col lg="6" md="6" sm="6" xs="12" className={styles.mobileView}>
                <img src={CustomerInfoImage} className={styles.customerInfoImage} />
              </Col>
              <Col lg="6" md="6" sm="6" xs="12" className={classNames(styles.loginParagraph, "mt-5")}>
                <h2>Customer Benefits*</h2>
                <p>Your customers receive additional benefits not available with a manufacturer’s warranty or home warranty plans</p>
                <ul>
                  <li><span className={classNames(styles.customerCheckIcon, "mr-2")}><CheckMark /></span>Cost of Labor</li>
                  <li><span className={classNames(styles.customerCheckIcon, "mr-2")}><CheckMark /></span>Diagnostic Costs</li>
                  <li><span className={classNames(styles.customerCheckIcon, "mr-2")}><CheckMark /></span>Service by Installing Contractor</li>
                  <li><span className={classNames(styles.customerCheckIcon, "mr-2")}><CheckMark /></span>Transferable at No Cost</li>
                  <li><span className={classNames(styles.customerCheckIcon, "mr-2")}><CheckMark /></span>No Deductible or Service Fee</li>
                  <li><span className={classNames(styles.customerCheckIcon, "mr-2")}><CheckMark /></span>Refrigerant Coverage</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className={classNames(styles.loginContentContainer, (accountNameToLower === "lennox" ? "py-5" : "pt-5 pb-4"))}>
          <Container>
            {accountNameToLower === "lennox" && (
              <Row>
                <Col sm="2">
                  <span className={classNames(styles.aigLogo, "mr-2")}>
                    <AIGLogo />
                  </span>
                </Col>
                <Col sm="10" className={styles.loginParagraph}>
                  Protection Plus is backed by AIG, a global market leader, providing both insurance and administration for the program. AIG’s depth of expertise and capabilities allow us to develop and maintain profitable programs that will provide the high quality of support you expect for you and your customers.
                </Col>
              </Row>
            )}
            {accountNameToLower !== "lennox" && (
              <Row>
                <Col sm="12" className={classNames(styles.footerInfoRheem, "pt-4 pb-0")}>
                  *Limitations and exclusions apply. See terms and conditions for further information. Service contract is provided by Service Net Warranty, LLC, part of AIG, or one or more of its affiliates.
                </Col>
              </Row>
            )}
          </Container>
        </Container>
      </>}
      {accountNameToLower === "ge" && <>
        <Container fluid className={classNames(styles.bodyBackground, styles.getStartedGE)}>
          <Container>
            <Row>
              <Col className="text-center">
                <h1>
                  Get Started Today with Comfort Protect
                </h1>
                <div className={classNames(styles.loginContentTextGE, styles.getStartedHeadTextGE)}>
                  Our user-friendly portal makes selling and managing extended service agreements 
                  easy! Simply enroll, then login to start adding extended service agreements to 
                  all your installations. Select from multiple coverage, reimbursement and sales 
                  options and get paid for claims within two weeks!
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="12" sm="12" className={classNames("text-center", styles.getStartedIconContainer)}>
                <div className={classNames(styles.loginIcoMiddleGE, "mb-3")}>
                  <BrandedIcon accountName={accountName} iconType={'papercircle'} />
                </div>
                <h4>Enroll Today</h4>
                <div className={classNames(styles.loginParagraphGE, styles.getStarted)}>
                  Submit a dealer <a 
                    onClick={(e) => handleShowActionModal()}
                    className={styles.textUnderlineGE}
                    title="Click to open a modal to enroll"
                  >
                    enrollment form 
                  </a> with a copy of your W-9 and COI documents.                  
                </div>
              </Col>
              <Col lg="4" md="12" sm="12" className={classNames("text-center pt-4 pt-sm-4 pt-md-4 pt-lg-0 pt-xl-0", styles.getStartedIconContainer)}>
                <div className={classNames(styles.loginIcoMiddleGE, "mb-3")}>
                  <BrandedIcon accountName={accountName} iconType={'verificationcircle'} />
                </div>
                <h4>Enrollment Verification</h4>
                <div className={classNames(styles.loginParagraphGE, styles.getStarted)}>
                  You’ll receive a verification email with a link to create your username and password.
                </div>
              </Col>
              <Col lg="4" md="12" sm="12" className={classNames("text-center pt-4 pt-sm-4 pt-md-4 pt-lg-0 pt-xl-0", styles.getStartedIconContainer)}>
                <div className={classNames(styles.loginIcoMiddleGE, "mb-3")}>
                  <BrandedIcon accountName={accountName} iconType={'peoplecircle'} />
                </div>
                <h4><a href="#" className={styles.headingUnderlineGE}>Login</a> and Get Started</h4>
                <div className={classNames(styles.loginParagraphGE, styles.getStarted)}>
                  The Comfort Protect Portal makes selling and managing your ESAs easy!
                </div>
              </Col>
            </Row>
          </Container>
          {showEnrollmentModal && (
            <EnrollmentModal
              isOpen={showEnrollmentModal}
              centered={true}
              toggle={() => setShowEnrollmentModal(!showEnrollmentModal)}
              accountName={accountName}
            />
          )}
        </Container>
        <div className={classNames(styles.laborProtectionOuterContainer, styles.bodyBackgroundMobile)}>
          <Container className={styles.tabContainer}>
            <Row className={styles.offeringRowGE}>
              <Col sm="12" md="12" lg="6" xs="12" className={styles.laborProtectionContainer}></Col>
              <Col sm="12" md="12" lg="6" xs="12" className="text-left p-2 p-sm-5 p-md-5 p-lg-5">
                <h1 className={classNames(styles.offeringColTextGE, "px-sm-5 px-md-5 px-lg-1")}>
                  Offering Labor Protection Separates You from the Competition
                </h1>
                <div className={classNames(styles.loginContentTextGE, styles.offeringColTextGE, "pb-4")}>
                  Extended service agreements protect your customer’s budget while providing them 
                  professionally-serviced equipment. Build trust, relationships and referrals 
                  through positive customer experiences. 
                </div>
                <div className={classNames(styles.loginContentTextGE, styles.laborProtectionText, "my-4 mr-3 mt-sm-0 mt-md-0 mx-sm-5 mx-md-5 mx-lg-0")}>
                  <div className={classNames(styles.quotationIcon, "mr-3 mt-1")}>
                    <GEQuotation />
                  </div>
                  <div>
                    Once I broke it down and could see the value, I knew right away applying 
                    extended service agreements to all of our projects was a good fit for our company.
                  </div>
                </div>
                <div className={classNames(styles.loginContentTextGE, styles.laborProtectionText, "my-4 mr-3 mx-sm-5 mx-md-5 mb-sm-1 mb-md-1 mx-lg-0")}>
                  <div className={classNames(styles.quotationIcon, "mr-3 mt-1")}>
                    <GEQuotation />
                  </div>
                  <div>
                    By attaching labor coverage to all our work, the consumer can see the value 
                    we’re adding by choosing us.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid className={classNames("pb-2 pt-5 py-sm-5 py-md-5 py-lg-5", styles.bodyBackground)}>
          <Container>
            <Row>
              <Col className="text-center px-2 px-sm-5 px-md-5 px-lg-5 pt-sm-4 pt-md-4 pt-lg-4">
                <h1>
                  Discover Comfort Protect Benefits
                </h1>
                <div className={classNames(styles.loginContentTextGE, styles.benefitsInnerContent, "pb-2 px-2 pb-sm-4 px-sm-2 pb-md-4 px-md-2 pb-lg-4 px-lg-5 mx-auto")}>
                  Comfort Protect Extended Service Agreements provide a new
                  opportunity for business growth and customer satisfaction.
                </div>
              </Col>
            </Row>
            {!dealerFlag && !customerFlag && <Row className="pt-3 pb-4" >              
              <Col lg="6" md="12" sm="12" xs="12" className={classNames("text-center")}>
                <div className={classNames(styles.dealerContainer, "float-lg-right p-4 mb-3 mx-md-auto mx-sm-auto")}>
                  <div className={styles.dealerHeadContainer}>
                    <span className={classNames(styles.benefitsIcon, "mr-2")}>
                      <GEHandshakeCircle />
                    </span>
                    <span className="h1">Dealers</span>
                  </div>
                  <div className={classNames(styles.loginContentTextGE, "my-5 px-4 px-sm-5 px-md-5 px-lg-4")}>
                    Earn extra revenue by adding labor protection to your quotes 
                    and gaining long-term customers.
                  </div>
                  <button className={classNames("btn-secondary", "mt-4", "mx-auto", styles.btnBenefits)} onClick={() => {handleBenefits("dealer")}}>
                    View Dealer Benefits
                  </button>
                </div>
              </Col>
              <Col lg="6" md="12" sm="12" xs="12" className="text-center mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0">
                <div className={classNames(styles.customerContainer, "float-lg-left p-4 mx-md-auto mx-sm-auto")}>
                  <div className={styles.dealerHeadContainer}>
                    <span className={classNames(styles.benefitsIcon, "mr-2")}>
                      <GECustomerCircle />
                    </span>
                    <span className={classNames("h1", styles.customerContainerFont)}>Customers</span>
                  </div>
                  <div className={classNames(styles.loginContentTextGE, styles.customerContainerFont, "my-5 px-3 px-sm-5 px-md-5 px-lg-3")}>
                    Provide homeowners peace of mind by adding labor coverage 
                    around their manufacturer’s warranty.
                  </div>
                  <button className={classNames("btn-secondary", "mt-4", "mx-auto", styles.btnBenefits)} onClick={() => {handleBenefits("customer")}}>
                    View Customer Benefits
                  </button>
                </div>
              </Col>
            </Row>}

            {dealerFlag && <Row className="pt-3 pb-5">
              <Col>
                <div className={classNames(styles.dealerContainer, "p-4", "mx-auto")}>
                  <div className={classNames(styles.dealerHeadContainer, "text-center d-flex")}>
                    <div className={classNames(styles.benefitsHead, styles.dealerBenefitMobile)}>
                      <div className={classNames(styles.benefitsIcon, "mr-2 mt-2")}>
                        <GEHandshakeCircle />
                      </div>
                      <div className={classNames("h1", "pb-0", "mb-0")}>Dealers Benefits</div>
                    </div>
                    <div className={styles.btnCloseBenefit} onClick={() => {handleBenefits("dealer")}}>
                      <GEClose />
                    </div>                     
                  </div>
                  <div className={classNames(styles.loginContentTextGE, styles.benefitsDetailContent, "p-4", "px-3", "mx-auto")}>
                    <ul>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.dealerCheckIcon, "mr-2")}><CheckMark /></div>
                          <div>Increased revenue and elimination of concessions</div>
                        </div>                        
                      </li>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.dealerCheckIcon, "mr-2")}><CheckMark /></div>
                          <div>Profit margin opportunities on all sales</div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.dealerCheckIcon, "mr-2")}><CheckMark /></div>
                          <div>Hourly reimbursement rate options</div>
                        </div>                        
                      </li>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.dealerCheckIcon, "mr-2")}><CheckMark /></div>
                          <div>Contracts active on day 31 from purchase date</div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.dealerCheckIcon, "mr-2")}><CheckMark /></div>
                          <div>Claims paid on average within two weeks</div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.dealerCheckIcon, "mr-2")}><CheckMark /></div>
                          <div>Contracts backed by an “A” rated underwriter</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <button className={classNames("btn-secondary", "mx-auto", styles.btnBenefitsClose)} onClick={() => {handleBenefits("dealer")}}>
                    Close
                  </button>
                </div>
              </Col>
            </Row>}
            {customerFlag && <Row className="pt-3 pb-5">
              <Col>
                <div className={classNames(styles.customerContainer, "p-4", "mx-auto")}>
                  <div className={classNames(styles.dealerHeadContainer, "text-center d-flex")}>
                    <div className={classNames(styles.benefitsHead, styles.customerBenefitMobile)}>
                      <div className={classNames(styles.benefitsIcon, "mr-2 mt-2")}>
                        <GECustomerCircle />
                      </div>
                      <div className={classNames("h1 pb-0 mb-0", styles.customerContainerFont)}>Customers Benefits</div>
                    </div>                    
                    <div className={classNames(styles.btnCloseCustomer)} onClick={() => {handleBenefits("customer")}}>
                      <GEClose />
                    </div>
                  </div>

                  <div className={classNames(styles.loginContentTextGE, styles.customerContainerFont, styles.benefitsDetailContent, "p-4", "px-3", "mx-auto")}>
                    <ul>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.customerCheckIconGE, "mr-2")}><CheckMark /></div>
                          <div>Budget protection from unexpected costly repairs</div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.customerCheckIconGE, "mr-2")}><CheckMark /></div>
                          <div>Service provided by you - a trusted partner</div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.customerCheckIconGE, "mr-2")}><CheckMark /></div>
                          <div>Fully transferable with no added cost</div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.customerCheckIconGE, "mr-2")}><CheckMark /></div>
                          <div>Supplements the manufacturer's warranty</div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <div className={classNames(styles.customerCheckIconGE, "mr-2")}><CheckMark /></div>
                          <div>No deductibles</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <button className={classNames("btn-secondary", "mx-auto", styles.btnBenefitsClose)} onClick={() => {handleBenefits("customer")}}>
                    Close
                  </button>
                </div>
              </Col>
            </Row>}
          </Container>
        </Container>
      </>}
    </>
  );
};

export default LoginContent;
