/**
 * USE THIS FILE FOR HELPER FUNCTIONS THAT WE WILL USE REPEATEDLY
 * THROUGHOUT THE APP.
 * -- DO NOT PUT "COMPONENTS" IN THIS FILE, BUT ONLY FUNCTIONS THAT 
 * RETURN A JAVASCRIPT PRIMATIVE TYPE.
 * -- EXPORT ALL FUNCTIONS FOR USE THROUGHOUT APP.
 */
import { countryConstants } from '../context/LanguageTranslation/CountryConstants';

// Input Amount, return formatted string for localized currency.
// Inherits text styles from parent.
export const displayCurrency = (
    amount,                 // number only
    locale = 'en-US',   // according to ISO standards. Default is "en-US"
    currency = 'USD',   // accordint to ISO standards. Default is "USD"
) => {
    if (!amount || typeof amount === 'string') return '$XXX.XX';
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount)
}


// Return random 5-7 digit key for use in mapping/keys/ids.
// IMPORTANT: Use for UI only, not for anything that needs to be secure. 
// Not "truly" random enough for security purposes.
export const randomized = () => Math.random().toString(36).substring(7);




// get Currency Symbol by country
export function getCurrencySymbol(countryId) {
    //in fuure can use a switch case to get appropiate country from constsnts
    let country = countryId === 1 ? 'US' : 'CA';
    return `${countryConstants[country]?.currency?.symbol}`;
}

//Return a table heading name in readable format
export const getHeadingName = (tableType) => {
    switch (tableType?.toLowerCase()) {
        case 'labor':
        case 'laborselectrate':
            return 'Labor';
        case 'partsallowance':
            return 'Parts Allowance';
        case 'partsmarkup':
            return 'Parts';
        default:
            return tableType
    }
}

//Return a table footer name in readable format
export const getFooterTaxName = (tableType) => {
    switch (tableType?.toLowerCase()) {
        case 'labor':
        case 'laborselectrate':
            return 'Labor';
        case 'partsallowance':
            return 'Parts ';
        case 'partsmarkup':
            return 'Parts';
        default:
            return tableType
    }
}

// ADD MORE HELPER FUNCTIONS
export function stripPhoneNumber(phone) {
    return phone.replace(/[^\d]/g, "");
}

export const getReportSubtabType = (reportType) => {
    switch (reportType) {
        case "contracts": return "contractReport"
        case "claims": return "claimReport"
        case "pendingcontracts": return "pendingContractReport"
        case "incompletesales": return "incompleteSalesReport"
        case "paymenthistory": return "paymentHistoryReport"
        case "servicerequest": return "serviceRequestReport"
        default: return "contractReport"
    }
}

export const getSubtabProps = (subtab) => {
    switch (subtab.subtabType) {
        case "rfs_form": return {
            color: 'yellow',
            closable: true,
            title: `Offer: ${subtab.offerId}`
        }
        case "serviceRequest": return {
            color: 'blue',
            closable: true,
            title: `RFS: ${subtab.eventData.eventNumber}`
        }
        case "claim": return {
            color: 'green',
            closable: true,
            title: `File Claim: ${subtab.claimConfigType || 'undefined'}`
        }
        case "claim_overview": return {
            color: 'orange',
            closable: true,
            title: `Claim: ${subtab.claimNumber}`
        }
        case "claiminvoice": return {
            color: 'orange',
            closable: true,
            title: 'Submit Claim'
        }
        case "staticclaiminvoice": return {
            color: 'blue',
            closable: true,
            title: `Claim: ${subtab.claimNumber}`
        }
        case "contract": return {
            color: 'blue',
            closable: false,
            title: `CN: ${subtab.contractNumber}`
        }
        case "contractReport": return {
            color: 'blue',
            closable: subtab.closeStatus,
            title: 'Contracts'
        }
        case "claimReport": return {
            color: 'blue',
            closable: subtab.closeStatus,
            title: 'Claims'
        }
        case "incompleteSalesReport": return {
            color: 'blue',
            closable: subtab.closeStatus,
            title: 'Incomplete Sales'
        }
        case "paymentHistoryReport": return {
            color: 'blue',
            closable: subtab.closeStatus,
            title: 'Payment History'
        }
        case "pendingContractReport": return {
            color: 'blue',
            closable: subtab.closeStatus,
            title: 'Pending Contracts'
        }
        case "serviceRequestReport": return {
            color: 'blue',
            closable: subtab.closeStatus,
            title: 'Service Request'
        }
        default: return {
            color: 'blue',
            closable: true,
            title: 'Error: SubtabType Not Known'
        }
    }
}
