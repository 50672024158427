import React, { PureComponent } from "react";
import styles from "./Transactions.module.css";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import classNames from 'classnames';

let tableResponsiveLg = "table-responsive-lg";
const DEFAULT_SORT_ORDER = { transNum: -1, transType: -1, transStatus: -1, transAmount: -1, transDate: -1, transPayment: -1 };
export class Transactions extends PureComponent {

  state = {
    sortOrder: DEFAULT_SORT_ORDER,
  }

  constructor(props) {
    super(props);
    this.state = {
      contractId: this.props.contract.contractId,
      transactionsColumnData: [],
      clientId: this.props.clientId,
      sortOrder: DEFAULT_SORT_ORDER,
    }

    this.componentDidMount = this.componentDidMount.bind(this);

  }
  handleCellClick = (column, sort, isLastSorting, colIndex) => {
    const { sortOrder } = this.state;
    console.log("datafield", column.dataField, sort, sortOrder[column.dataField]);
    if (sortOrder[column.dataField] !== sort) {

      this.setState({ sortOrder: { ...DEFAULT_SORT_ORDER, [column.dataField]: sort } });
    }
  }

  getSortIcon = (dataField) => {
    const { sortOrder } = this.state;
    if (sortOrder[dataField] === -1) {
      return "";
    }
    return sortOrder[dataField] === "asc" ? styles.ascHeader : styles.transDateHeader;
  }

  dateFormatter = (column, colIndex) => {
    return column ? moment(column).format('MM/DD/YYYY hh:mm A') : ''
  }



  componentDidMount = (props) => {
    try {
      fetch(`payment/GetTransactionsByContractId/${this.props.contract.contractId}/${this.props.clientId}`)
        .then(res => {
          if (res.ok) {
            res.json().then(transaction => {
              console.log(transaction);
              var queueRecords = [];
              let dateFormat = "MM/DD/YYYY";
              if (transaction.length > 0) {
                dateFormat = transaction[0].country === 'CA' ? "DD/MM/YYYY" : dateFormat;
              }
              for (let i = 0; i < transaction.length; i++) {
                for (let transInstance = 0; transInstance < transaction[i].transactionInstanceModel.length; transInstance++) {
                  let rowItem = [];
                  const lastTransInstanceId = transaction[i].transactionInstanceModel[transaction[i].transactionInstanceModel.length - 1].transactionInstanceId;

                  if (transaction[i]?.transactionInstanceModel[transInstance].transactionTypeId == 1 || lastTransInstanceId == transaction[i].transactionInstanceModel[transInstance].transactionInstanceId) {
                    rowItem.transactionNumber = (transaction[i]?.transactionInstanceModel[transInstance].transactionTypeId == 4 && (transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 1 || transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 3)) ? "" : transaction[i]?.transactionInstanceModel[transInstance]?.transactionInstanceId;
                    rowItem.account = (transaction[i]?.transactionInstanceModel[transInstance].transactionTypeId == 1 || transaction[i]?.transactionInstanceModel[transInstance].transactionTypeId == 3) ? "Contract Payment" : "Cancellation Refund";
                    rowItem.dateEntered = transaction[i]?.transactionInstanceModel[transInstance]?.transactionDateTime ?? "";
                    //rowItem.dateEntered = moment(transaction[i]?.transactionInstanceModel[transInstance]?.transactionDateTime,'DD/MM/YYYY').format(dateFormat) ?? "";
                    rowItem.amount = (transaction[i]?.transactionInstanceModel[transInstance].transactionTypeId == 4 && (transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 1 || transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 3)) ? "" : (transaction[i]?.transactionInstanceModel[transInstance].transactionTypeId == 4 && transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 2) ? "-" + transaction[i]?.transactionInstanceModel[transInstance]?.amount : transaction[i]?.transactionInstanceModel[transInstance]?.amount ?? "00";
                    rowItem.assetStatus = (transaction[i]?.transactionInstanceModel[transInstance].transactionStatusId == 2 && transaction[i]?.paymentType === "contract payment") ? "Processed" : (transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 3 && transaction[i]?.paymentType === "contract payment") ? "Pending" : transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 1 ? "Pending" :
                      transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 2 ? (transaction[i]?.transactionInstanceModel[transInstance].transactionTypeId == 4 && transaction[i]?.transactionInstanceModel[transInstance].transactionStatusId == 2) ? "Processed" : "Processed" :
                        (transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 3 && transaction[i]?.transactionInstanceModel[transInstance].transactionTypeId == 4) ? "Pending" : "Pending";
                    rowItem.claimId = transaction[i].transactionClaimModel[0]?.claimId ?? "";
                    rowItem.claimNumber = transaction[i].transactionClaimModel[0]?.claimId ?? "";
                    rowItem.refundRequested = transaction[i]?.transactionInstanceModel.length == 1 ? transaction[i]?.transactionClaimModel[0]?.refundRequested : transaction[i]?.transactionClaimModel[0]?.refundRequested == true ? true :
                      transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId <= 2 ? true :
                        (transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 3) ? false : false;
                    rowItem.refundError = (transaction[i].transactionInstanceModel[transaction[i].transactionInstanceModel.length - 1]?.transactionStatusId == 3 && transaction[i]?.transactionClaimModel[0]?.refundRequested != true) ? true : false;
                    rowItem.refundApporved = transaction[i]?.transactionInstanceModel?.filter(e => e.transactionTypeId == 4 && e.transactionStatusId == 2).length > 0 ? true : false;
                    rowItem.asset = (transaction[i]?.transactionInstanceModel[transInstance].transactionTypeId == 4 && (transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 1 || transaction[i]?.transactionInstanceModel[transInstance]?.transactionStatusId == 3)) ? "" : transaction[i]?.creditCardInfoModel != null ? transaction[i]?.creditCardInfoModel.creditCardTypeId == 1 ? "VISA #" + transaction[i]?.creditCardInfoModel.creditCardLastFour :
                      transaction[i]?.creditCardInfoModel.creditCardTypeId == 2 ? "AmEx #" + transaction[i]?.creditCardInfoModel.creditCardLastFour :
                        transaction[i]?.creditCardInfoModel.creditCardTypeId == 3 ? "DI #" + transaction[i]?.creditCardInfoModel.creditCardLastFour :
                          transaction[i]?.creditCardInfoModel.creditCardTypeId == 4 ? "MC #" + transaction[i]?.creditCardInfoModel.creditCardLastFour :
                            "#" + transaction[i]?.creditCardInfoModel.creditCardLastFour : '';
                    queueRecords.push(rowItem);
                  }
                }
              }
              let transactionModel = queueRecords.map(e => ({
                transNum: e.transactionNumber,
                transType: e.account,
                transDate: moment(e.dateEntered).format(dateFormat),
                transAmount: e.amount,
                transStatus: e.assetStatus,
                claimId: e.claimId,
                claimNumber: e.claimNumber,
                refundRequested: e.refundRequested,
                refundApporved: e.refundApporved,
                refundError: e.refundError,
                transPayment: e.asset
              }));
              console.log(this.state.transactionsColumnData);
              this.setState({ transactionsColumnData: transactionModel });

            });
          }
        });
    }
    catch (error) {
      console.log('Error');
    }
  }
  priceFormatter = (column, rowData) => {
    let price = rowData.transAmount || 0;
    price = Number.parseFloat(price).toFixed(2);
    return (
      <>
        {(rowData.account === "Cancellation Refund" || rowData.transType == "Cancellation Refund") && price < 0 ? (
          <span className={styles.refundAmount}>{`-$${Math.abs(price)}`}</span>
        ) : (
            column == "" ? <span></span> : <span>{`$${price}`}</span>
          )}
      </>
    );
  }

  getProperRows = (coulmn, rows) => rows.map(row => ({
    ...row,
    transAmount: this.priceFormatter(coulmn, row)
    //transAmount: row.transAmount.indexOf('-') > -1 ? <span className={styles.transAmount}>{row.transAmount}</span> : <span>{row.transAmount}</span>
  }))

  render() {
    const { sortOrder } = this.state;
    console.log("sortorder", sortOrder);
    const transactionsColumns = [
      {
        dataField: "transNum",
        text: "Transaction Number",
        headerClasses: this.getSortIcon('transNum'),
        headerSortingClasses: this.handleCellClick,
        sort: true,
      },
      {
        dataField: "transType",
        text: "Type",
        headerClasses: this.getSortIcon('transType'),
        headerSortingClasses: this.handleCellClick,
        sort: true,
      },
      {
        dataField: "transStatus",
        text: "Status",
        headerClasses: this.getSortIcon('transStatus'),
        headerSortingClasses: this.handleCellClick,
        sort: true,
      },
      {
        dataField: "transAmount",
        text: "Amount",
        headerClasses: this.getSortIcon('transAmount'),
        headerSortingClasses: this.handleCellClick,
        sort: true,
      },
      {
        dataField: "transDate",
        text: "Transaction Date",
        headerClasses: this.getSortIcon('transDate'),
        headerSortingClasses: this.handleCellClick,
        sort: true,
      },
      {
        dataField: "transPayment",
        text: "Payment",
        headerClasses: this.getSortIcon('transPayment'),
        headerSortingClasses: this.handleCellClick,
        sort: true,
      },
    ];

    const transactionsData = [
      {
        transNum: "1234567",
        transType: "Contract Payment",
        transStatus: "Processed",
        transAmount: "$100.00",
        transDate: "mm/dd/yyyy 1:20 PM",
        transPayment: "VISA #1234",
      },
      {
        transNum: "1234568",
        transType: "Contract Payment",
        transStatus: "Processed",
        transAmount: "-$100.00",
        transDate: "mm/dd/yyyy 1:20 PM",
        transPayment: "VISA #1234",
      },
    ];


    return (

      <Card>
        <CardHeader>
          <h1 className="h4">Transactions Summary</h1>
        </CardHeader>
        <CardBody>
          <div className={styles.transactionsTable}>
            <BootstrapTable
              keyField="transNum"
              data={this.getProperRows(this.state.transactionsColumns, this.state.transactionsColumnData)}
              columns={transactionsColumns}
              bordered={false}
              bootstrap4
              wrapperClasses={tableResponsiveLg}
            />
          </div>
        </CardBody>
      </Card>

    );
  }
}