import React, { useState, useEffect, useContext } from 'react';
import styles from './QueueReport.module.css';
import classNames from 'classnames';
import { DropdownWithNumber } from '../../components/inputs/DropdownWithNumber';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { ReportContext } from './ReportsContext';
import { SessionContext } from "../../context/SessionContext";


const paymentTypeOptions = [
  { display: 'All Payments', data: "allpayments" },
  { display: 'Visa', data: "visa" },
  { display: 'Discover', data: "discover" },
  { display: 'Amex', data: "amex" },
  { display: 'MC', data: "mc" }
];
const reportTypeOptions = [
  { display: 'Claims', data: "claims" },
  { display: 'Contracts', data: "contracts" },
  { display: 'Pending Contracts', data: "pendingcontracts" },
  { display: 'Incomplete Sales', data: "incompletesales" },
  { display: 'Payment History', data: "paymenthistory" },
  { display: 'Service Request', data: "servicerequest" }
];

function PaymentHistoryFilter() {
  const [state, dispatch] = useContext(ReportContext);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [dropValue, setDropValue] = useState(null);
  const sessionContext = useContext(SessionContext);
  const fileClaimFlag = sessionContext?.getAccountFeatureFlag("hvac claim");

  useEffect(() => {
    const { type } = state;
    const dropDownValue = ['contracts', 'activecontracts', null];
    const defaultText = dropDownValue?.some(val => val === type) || !fileClaimFlag ? "contracts" : type;

    setDropValue(defaultText)
  }, [state])

  const toggle = () => {
    setDropDownOpen(!dropdownOpen)
    {/* Value={dropValue || 'paymenthistory'}*/ }
  }
  // console.log("PaymentHistoryFilter -> state.data ===>>> ", state.data.length);

  const reportTypeOptions = sessionContext?.getAccountFeatureFlag("hvac claim") ?
    [
      { display: 'Claims', data: "claims" },
      { display: 'Contracts', data: "contracts" },
      { display: 'Pending Contracts', data: "pendingcontracts" },
      { display: 'Incomplete Sales', data: "incompletesales" },
      { display: 'Payment History', data: "paymenthistory" },
      { display: 'Service Request', data: "servicerequest" }
    ] :
    [
      { display: 'Contracts', data: "contracts" },
      { display: 'Pending Contracts', data: "pendingcontracts" },
      { display: 'Incomplete Sales', data: "incompletesales" },
      { display: 'Payment History', data: "paymenthistory" },
    ];

  return (
    <div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
      <DropdownWithNumber
        name="state"
        options={reportTypeOptions}
        className={styles.reportsCounterClient}
        defaultValue="paymenthistory"
        text="paymenthistory"
        length={state && state.totalContracts}
        onChangedValue={val => {
          console.log({ val })
          console.log(dropValue)
          setDropValue(val)
          sessionContext.pendingReportTrack.pendingBackFlag = false;
          dispatch({
            type: 'SET_TYPE',
            payload: val
          })
        }}
      />
    </div>
  )
}

export default PaymentHistoryFilter