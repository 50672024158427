import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, FormGroup, Label, Input } from 'reactstrap';
import styles from './SuggestedAddress.module.css';
import classNames from 'classnames';


let radioBoxWrapper = styles.radioBoxWrapper;
let noGutters = "no-gutters"

const SuggestedAddress = (props) => {
    const {
        showSuggestedAddressValidationModal,
        enteredAddress,
        suggestedAddress
    } = props;

    const [modal, setModal] = useState(showSuggestedAddressValidationModal);
    const [selectedAddress, setSelectedAddress] = useState(`suggestedAddress`);

    const toggle = () => {
        setModal(!modal);
        console.log(`LOG>> Toggling`);
    }

    const onClose = (e) => {
        setModal(false);
        props.onEditAddress();
    }

    const onContinue = (e) => {
        setModal(false);
        props.onContinueAction(selectedAddress);
    }

    const onOptionChange = (e) => {
        setSelectedAddress(e.target.id);
    }


    let suggestedZip = suggestedAddress.zip != null ? suggestedAddress.zip : '';
    if (suggestedZip !== '' && suggestedAddress.plus4 != null & suggestedAddress.plus4 != '') {
        suggestedZip += '-' + suggestedAddress.plus4;
    } 

    let suggestedState = suggestedAddress.state != null ? suggestedAddress.state : suggestedAddress.stateAbbreviation;
    
    return (
        <div>
            <Modal isOpen={modal} toggle={onClose} className="modal-md">
                <ModalHeader toggle={onClose}>
                    <h4 className="modal-title">Use Recommended Address?</h4>
                </ModalHeader>

                <ModalBody>
                    <p>We found a more precise version of the address you entered. If it looks right, please use the suggested address.</p>

                    <FormGroup className={classNames(noGutters, radioBoxWrapper)} role="radiogroup" id="addressSelectionGroup">
                        <Col md="7">
                            <p className="form-label-radio">Suggested Address</p>

                            <div className={styles.radioBox}>
                                <Input type="radio" name="addressSelection" id="suggestedAddress" checked={selectedAddress === "suggestedAddress"}
                                    onChange={onOptionChange} />

                                <Label className={styles.radioControlsLabel} for="suggestedAddress">
                                    {`${suggestedAddress.address1} ${suggestedAddress.suite}`}<br />
                                    {typeof suggestedAddress.address2 === 'string' &&
                                        suggestedAddress.address2.trim().length > 0 &&
                                        <>{suggestedAddress.address2}<br /></>
                                    }
                                    {`${suggestedAddress.city}, ${suggestedState}`}<br />
                                    {suggestedZip}
                                </Label>
                            </div>
                        </Col>

                        <Col md="7">
                            <Label className="form-label-radio" aria-label="Address Entered" for="radioButtonExampleGroup">Address Entered</Label>
                                <div className={styles.radioBox}>
                                <Input type="radio" name="addressSelection" id="enteredAddress" checked={selectedAddress === "enteredAddress"}
                                    onChange={onOptionChange} />

                                <Label className={styles.radioControlsLabel} for="enteredAddress">
                                    {`${enteredAddress.address1} ${enteredAddress.address2}`}<br />
                                    {`${enteredAddress.city}, ${enteredAddress.state}`}<br />
                                    {`${enteredAddress.zip}`}
                                </Label>
                            </div>
                        </Col>
                    </FormGroup>
                </ModalBody>

                <ModalFooter>
                    <div className="btnWrapper">
                        <button className="btnLink" onClick={onClose}>Edit Address</button>{' '}
                        <button className="btn-primary" onClick={onContinue}>
                            Continue</button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default SuggestedAddress;