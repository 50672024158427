import { rndmvals, randomized } from "../util";



// THIS IS THE DEFAULT STATE.
export const initialState = {
    preapprovedAmount: null,
    isTableEdited: false,
    labor: [], // pull from ServicePower
    negotiatedRateTypes: [], // load from account/contract
    parts: [],
    coverages: [], // load from account/contract
    mileage: [],
    isTableReadOnly: false,
    tripTypes: [
        {
            display: 'One Way',
            multiplier: 1,
            data: 'oneWay'
        },
        {
            display: 'Round Trip',
            multiplier: 2,
            data: 'roundTrip'
        },
        {
            display: 'Trip',
            multiplier: 1,
            data: 'trip'
        },
        {
            display: 'Per Mile',
            multiplier: 1,
            data: 'perMile'
        },
        {
            display: 'Flat Rate',
            multiplier: 1,
            data: 'flatRate'
        }
    ],
    // NEED TAX OBJECTS FOR TABLE CREATION,
    // BUT WE DON'T PULL ANY. FOR NOW, AGENT ENTERS TAX
    // FUTURE STORY: CALCULATE ON INITIAL_DATA AND ALL SUBSEQUENT DISPATCHES.
    tax: [
        {
            id: randomized(),
            taxType: 'Parts Tax',
            requested: 0,
            authorized: 0,
            authEdited: false,
        },
        {
            id: randomized(),
            taxType: 'Labor Tax',
            requested: 0,
            authorized: 0,
            authEdited: false,
        },
    ],
    taxRate: null,
    totals: {
        labor: {
            requested: null,
            authorized: null,
        },
        parts: {
            requested: null,
            authorized: null,
        },
        mileage: {
            requested: null,
            authorized: null,
        },
        tax: {
            requested: 0,
            authorized: 0,
        },
        final: {
            requested: null,
            authorized: null,
        }
    },
    remainingCoverage: null,
    rateValues: {
        roundTripRate: 0,
        onewayTripRate: 0,
        radius: 0
    }
}