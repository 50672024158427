import React, { Component } from 'react';
import { SessionContext } from '../../context/SessionContext';
import classNames from 'classnames';

export class DropDownInputWithCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options,
            selectedValues: props.selectedValues || [],
            selectedValueText: props.selectedText,
            dropped: false,
            defaultValue: props.defaultValue
        };
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleDropClick, false);
    }

    componentDidMount() {
        if (typeof this.state.defaultValue !== "undefined") {
            this.setState({
                selectedValue: this.state.defaultValue,
                selectedValueText: this.state.options.filter(o => o.data === this.state.defaultValue)?.[0]?.display
            })
        }
    }

    componentDidUpdate({selectedText}){
        const {selectedText: newSelectedText}= this.props;
        if(selectedText != newSelectedText){
            this.setState({selectedValueText: newSelectedText});
        }
    }


    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDropClick, false);
    }

    handleDropClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }
        this.setState({ dropped: false });
    }

    getDropDownValue = (value) => {
        const {selectedValues} = this.state;
        let pos = -1;
        selectedValues.find((option, i) => {
            if(option.value === value.value) pos = i;
            return pos > -1;
        });
        if(pos > -1){
            selectedValues.splice(pos,1);
        } else {
            selectedValues.push(value);
        }
        this.props.onChangedValue(selectedValues);
    }

    render() {
        const {selectedValues, selectedValueText} = this.state;
        const selectedOptions = selectedValues.map(option => option.value);
        const {id} = this.props;
        return (
            <React.Fragment>
                <div className="queueDropdown position-relative" ref={node => this.node = node} id={id}>
                    <span className="dropbtn dropbtn-placeholder" id={id}
                        onClick={(e) => {
                            e.preventDefault(); this.setState({ dropped: !this.state.dropped })
                        }}>
                        {selectedValueText}
                </span>
                    <div className="dropdown-content"
                        style={{ display: this.state.dropped ? 'block' : 'none' }}>
                        {this.props.options.map((opt, index) => {
                           return <a>
                                <div className="checkbox m-0" key={index}>
                                <input className="mr-2" type="checkbox" name={opt.value} id={`confirmTroubleshooting${index}${id}`}
                                        onChange={(event) => {this.getDropDownValue(opt, event) }} checked={selectedOptions.includes(opt.value)} />
                                <label for={`confirmTroubleshooting${index}${id}`} className={classNames("checkboxLabel", this.context.accountName)}> {opt.display}</label>
                                </div>
                           </a>
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

DropDownInputWithCheckbox.contextType = SessionContext