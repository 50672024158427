import React, { useContext } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { SessionContext } from '../../context/SessionContext';
import styles from './NavHeader.module.css';

import classNames from 'classnames';

const { navLink, navItem, active, iconClose } = styles

export default function NavTab ({
	isActive,
	tabNumber,
	onClick,
	title
}) {
	const { tabDispatch } = useContext(SessionContext)

	return (
		<a className={classNames(navItem, navLink, isActive ? active : "") /*+ isActive*/}
			id={"main-contract-tab-" + tabNumber}
			data-toggle="tab"
			role="tab"
			aria-controls="main-contract-panel" aria-selected="false"
			onClick={onClick}
		>
			{title}
			<span 
				className={styles.closeTab}
				onClick={() => tabDispatch(
					'CLOSE_TAB', 
					{ tabId: tabNumber }
				)}
			>
				<CloseIcon className={iconClose}/>
			</span>
		</a>
	)
}