import React from 'react'
import {
    BasicInput,
    OemCheckbox,
    PriceInput,
    QuantityInput,
    CurrencyField,
    DependentDropdown,
    DeleteRowButton
} from "../Formatters";
import { widthFormatter } from "../Formatters/widthFormatter";
import styles from './TableConfig.module.css';

export const PARTS_COLS = [
    {
        text: '',
        dataField: '',
        isDummyColumn: true,
        style: {
            ...widthFormatter('deleteCol'),
            padding: 0
        },
        formatter: (col, row, rowI) => <DeleteRowButton rowId={row.id} rowIndex={rowI} tableType='parts' />,
        align: 'center',
        footerClasses: [styles.footer, styles.noborder, styles.trashIcon]
    },
    {
        dataField: "qty",
        text: "Qty",
        align: "center",
        style: widthFormatter('qtyCol'),
        formatter: (col, row, rowI) => (
            <div className={styles.makeQuantityInputCenter}>
                <QuantityInput cell={col} row={row} rowIndex={rowI} fieldName={"qty"} tableType='parts' />
            </div>
        )
    },
    {
        dataField: "oem",
        text: "In OEM Warranty",
        style: widthFormatter('oemCol'),
        formatter: (col, row, rowI) => (
            <OemCheckbox col={col} row={row} rowIndex={rowI} fieldName={"oem"} tableType='parts' />
        )
    },
    {
        dataField: "asset",
        text: "Asset",
        style: widthFormatter('assetPartsCol'),
        classes: styles.assetWidth,
        formatter: (col, row, rowI) => (
            <DependentDropdown cell={col} row={row} rowIndex={rowI} tableType={'parts'} fieldName={"asset"} />
        )
    },
    {
        dataField: "partNumber",
        text: "Part Number",
        style: widthFormatter('partNumberCol'),
        formatter: (col, row, rowI) => (
            <BasicInput
                key={rowI}
                cell={col}
                row={row}
                rowIndex={rowI}
                fieldName={"partNumber"}
                tableType='parts'
            />
        )
    },
    {
        dataField: "description",
        text: "Description",
        style: widthFormatter('assetDescCol'),
        formatter: (col, row, rowI) => (
            <DependentDropdown cell={col} row={row} rowIndex={rowI} tableType={'parts'} fieldName={"description"} />
        )
    },
    {
        dataField: "price",
        text: "Price",
        headerAlign: 'left',
        align: 'right',
        style: widthFormatter('invoiceTotalCol'),
        formatter: (c, r, rI) => (
            <PriceInput cell={c} row={r} rowIndex={rI} fieldName={"price"} tableType='parts' />
        )
    },
    {
        dataField: "total",
        text: "Total",
        headerAlign: 'left',
        align: 'right',
        style: widthFormatter('invoiceTotalCol'),
        formatter: (col, row, i) => (
            <CurrencyField col={col} row={row} rowIndex={i} fieldName="total" tableType='parts' />
        )
    }
];
