import React, { forwardRef, useContext, useEffect } from 'react';
import { CardBody, Card } from 'reactstrap';
import styles from './Enrollment.module.css';
import { getBrandContent } from "../BrandingWhiteLabel/BrandContent";
import BrandedIcon from '../BrandingWhiteLabel/BrandedIcon';
import { countries } from './constant';
import { SessionContext } from "../../context/SessionContext";
import { useMediaQuery } from 'react-responsive';
import { phoneFormat } from '../shared/util';


const Enrollment = forwardRef((props, ref) => {
    const { accountName } = useContext(SessionContext);
    const programName = getBrandContent(accountName).programName;
    const vendorSessionData = JSON.parse(sessionStorage.getItem('vendorDetails'));
    const federalSessionData = JSON.parse(sessionStorage.getItem('federalDetails'));
    const dealerSessionData = JSON.parse(sessionStorage.getItem('questionData'));
    const isCNS = dealerSessionData?.dealerQuestions?.questions?.find(data => data.name === "isCNS")?.answer;
    const { country: countryId, physicalAddress: { State: stateId }, stateOptions, contacts, vendorType= [] } = vendorSessionData;
    const country = countries.find(data => data.data === countryId)?.display;
    const state = stateOptions.find(data => data.data === stateId)?.display;
    let isDocumentRequired = false;
    const isMobile = useMediaQuery({query: '(max-width: 640px)'})


    const handleCardUpdate = () => {
        props.backToInsurance("Insurance Docs");
    }

    if (((vendorType?.includes("dealer") && vendorType?.includes("servicer")) ||
        (vendorType?.includes("servicer") || isCNS === "Yes"))) {
        if (country === "USA" || country === "Canada") {
            if (!federalSessionData?.formData?.['taxFormFile']?.[0]?.hasOwnProperty("path")) {
                isDocumentRequired = true;
            }
        }

        if (country === "USA" && (["Florida", "Alabama", "Georgia"].includes(state))) {
            if (!federalSessionData?.formData?.['floriaLic']?.[0]?.hasOwnProperty("path")) {
                isDocumentRequired = true;
            }
        }
        if (country === "Canada") {
            if (federalSessionData?.formData?.taxList?.every(value => !value?.taxFormFile?.hasOwnProperty("path"))) {
                isDocumentRequired = true;
            }
        }
    }

    useEffect(() => {
        //fire email logic
        if (isDocumentRequired === false) {
            if (country === "USA") {
                props.sendEnrollmentEmail(props.emailTypes?.find(et => et.emailName === "Enrollment Confirmation USA")?.emailTypeId ?? 0);
            } else if (country === "Canada") {
                props.sendEnrollmentEmail(props.emailTypes?.find(et => et.emailName === "Enrollment Confirmation CAN")?.emailTypeId ?? 0);
            }
        } else if (isDocumentRequired === true) {
            if (country === "USA") {
                if ((["Florida", "Alabama", "Georgia"].includes(state))) {
                    props.sendEnrollmentEmail(props.emailTypes?.find(et => et.emailName === "Complete Enrollment FL")?.emailTypeId ?? 0);
                } else {
                    props.sendEnrollmentEmail(props.emailTypes?.find(et => et.emailName === "Complete Enrollment Non FL")?.emailTypeId ?? 0);
                }
            } else if (country === "Canada") {
                props.sendEnrollmentEmail(props.emailTypes?.find(et => et.emailName === "Complete Enrollment CAN")?.emailTypeId ?? 0);
            }
        }
    }, []);
    
    return (
        <CardBody className={styles.container}>
            <div className={styles.enrollmentIcon}>
                <BrandedIcon
                    accountName={accountName}
                    iconType={'profile'}
                />
            </div>
            <h1 className="h1 text-center">We Received Your Enrollment Request</h1>
            <p>
                        Thank you for your interest to enroll in {programName}. We have received your enrollment information{!isDocumentRequired? " and will contact you within 1-2 business days" : ''}.
                    </p>
                    {isDocumentRequired &&
                        <p>
                            <span className='font-weight-bold'>Documents Required: </span>Please follow the instructions emailed to {contacts?.[0].email} to submit the required documents within the next 30 days. Once submitted, your enrollment will be processed within 1-2 days.
                        </p>
                    }            
            <p>
                If you'd like to speak with one of our representatives, contact HVAC support via email hvacenrollments@sndirect.com or call 
                {isMobile ? 
                    <a href="tel:888-562-5715" aria-label="Call" className="callLink"> {phoneFormat('8885625715')}</a> 
                    : 
                    ` ${phoneFormat('8885625715')}`
                }.
            </p>
            <p>
                Please reference your Vendor ID: {props.vendorId}
            </p>
            {isDocumentRequired &&
                        <button className={`btn-primary mx-auto ${styles.uploadBtn}`} onClick={() => handleCardUpdate()} >
                            Upload Documents
                        </button>
                    }
        </CardBody>
    );
});

export default Enrollment;