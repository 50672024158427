import React, { useContext } from 'react';
import { Row, Col, Container } from 'reactstrap';
import styles from './SalesOptions.module.css';
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon';
import { SessionContext } from '../../context/SessionContext';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/Vendor/ICP/benefits-checkmark.svg';


const SalesOptions = () => {
    const sessionContext = useContext(SessionContext);
    const { accountName } = sessionContext;

    const dealerBenefits = () => {
        const serviceInfo = [
            'Increased revenue and elimination of concessions',
            'Profit margin opportunities on all sales',
            'Hourly reimbursement rate options',
            'Contracts active on day 31 from purchase date',
            'Easy claim submission through our online portal',
            'Contracts backed by an “A” rated underwriter'
        ]
        return (
            serviceInfo.map((benefit, index) => {
                return <div key={index}>
                    <div><CheckmarkIcon /></div>
                    <div>{benefit}</div>
                </div>
            })
        );
    };

    return (
        <Container fluid className={styles.salesOptionsContainer}>
            <Container>
                <Row>
                    <Col className='text-center'>
                        <div className='d-flex justify-content-center'>
                            <div className={`${styles.colorBar}`}></div>
                        </div>
                        <h1 className={styles.salesOptionsHeader}>Two Simple Sales Options</h1>
                        <div className={styles.paragraphContainer}>
                            <p>With <span className={styles.textHighlight}>Connect</span>, pre-selected labor protection will
                                automatically apply every time you register a product, eliminating the administrative burden of
                                warranty processing.
                            </p>
                            <p>With <span className={styles.textHighlight}>Select</span>, purchase one contract at a time for
                                individual registrations using our simple warranty administration platform.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className={styles.salesInfoContainer}>
                    <Col lg='5' md='12' sm='12' className={styles.salesIcon}>
                        <div className={styles.dealerBenefitsIcon}>
                            <BrandedIcon
                                accountName={accountName}
                                iconType='dealer'
                            />
                            <h3 className='mb-2'>Dealer Benefits</h3>
                        </div>
                        <p className='mb-0'>
                            Comfort Promise promotes business growth and customer satisfaction.
                        </p>
                    </Col>
                    <Col lg='7' md='12' sm='12' className={styles.dealerContainer}>
                        <div className={styles.dealerBenefits}>
                            {dealerBenefits()}
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default SalesOptions;