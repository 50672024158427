import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, CardHeader, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { ContractStatus } from '../../Contract/ContractStatus'
import styles from './SimpleTabs.module.css';

const SimpleTabs = ({ 
    tabs, 
    status, 
    headerTitle,        // if provided will be permanent header, not switching title with tab change 
    inputActiveTab = 1, 
    handleTabClick
}) => {

    const [activeTab, setActiveTab] = useState(inputActiveTab);

    const toggle = tab => {

        if (activeTab !== tab) setActiveTab(tab);
    }

    const getHeaderTitle = () => {
        if (!!headerTitle) return headerTitle
        
        const activeTabObj = tabs[activeTab - 1]
        return activeTabObj.headerTitle
    }

    const getTabs = () => {
        return tabs?.map(({ name }, i) => <NavItem className="navItem" key={name}>
            <NavLink
                className={classnames({ "navLink": true, active: activeTab === i + 1 })}
                onClick={() => {
                    toggle(i + 1);
                    typeof handleTabClick !== 'undefined' && handleTabClick(i+1);
                }}
            >
               {name}
            </NavLink>
        </NavItem>)
    }

    const getComponents = () => (
        tabs?.map(({ component }, i) => (
            <TabPane tabId={i + 1}>
                <Row>
                    <Col sm="12">
                        {component}
                    </Col>
                </Row>
            </TabPane>
        ))
    )

    return (
        <div>
            <Row className={styles.headerRow}>
                <h1 className="h4 col-md-6 col-sm-12">
                    {getHeaderTitle()}
                    <ContractStatus statusId={status} />
                </h1>
                <Col md="6" sm="12">
                    <Nav tabs className={classnames("navTabs", styles.customNavTabs)}>
                        {getTabs()}
                    </Nav>
                </Col>
            </Row>
            <CardBody>
                <TabContent activeTab={!activeTab ? 1 : activeTab}>
                    {getComponents()}
                </TabContent>
            </CardBody>
        </div>
    );
}

export default SimpleTabs;
