import React from 'react';
import { CurrencyField, widthFormatter } from "../Formatters";
import styles from './TableConfig.module.css';

export const PARTS_ALLOWANCE_COLS = [
    {
        dataField: 'name',
        isDummyColumn: true,
        align: 'right',
        footer: () => (<b>Allowance Total</b>),
        headerAttrs: {
            hidden: true
        }
    },
    {
        dataField: 'total',
        isDummyColumn: true,
        align: 'right',
        footerStyle: widthFormatter('totalCol'),
        style: widthFormatter('totalCol'),
        formatter: (col, row, i) => (
            <CurrencyField col={col} row={row} rowIndex={i} fieldName="total" />
        ),
        footer: (columnData, col) => {
            return columnData.reduce((acc, item) => acc + item, 0)
        },
        footerFormatter: (col, row, val) => {
            return <CurrencyField col={parseFloat(val.text)} rowIndex={row} fieldName="total" />
        },
        headerAttrs: {
            hidden: true
        }
    }
];
