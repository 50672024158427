import React, { useState, useEffect } from 'react';
import { Progress } from 'reactstrap';
import styles from './UploadDocs.module.css';
import Dropzone from '../../UploadDocs/DragandDropFileUpload';
import { ReactComponent as JpegIcon } from "../../../assets/images/file-jpeg.svg";
import { ReactComponent as JpgIcon } from "../../../assets/images/file-jpg.svg";
import { ReactComponent as PdfIcon } from "../../../assets/images/file-pdf.svg";
import { ReactComponent as PngIcon } from "../../../assets/images/file-png.svg";

const dataURLDeclaration = "base64,";

const UploadDocs = (props) => {
  const {
    onFileChange,
    handleFileInfo,
    maxFiles,
    prevFileUploadDetails,
    docFormType,
    docFormIndex,
    documentTypeId,
    customClass = ""
  } = props;

  const [formData, setFormData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [fileUploadPercent, setFileUploadPercent] = useState();
  const [invalidDoc, setInvalidDoc] = useState();

  useEffect(() => {
      if (prevFileUploadDetails.length > 0) {
        setFormData(prevFileUploadDetails);
        setIsButtonDisabled(false);
        setFileUploadPercent(100);
      }
  }, [prevFileUploadDetails]);

  const handleFileUpload = (fileData) => {
    if (fileData?.length) {
      if (checkTotalBytes(fileData) == false) {
        setInvalidDoc(true);
      }
      else {
        const currentFile = fileData[0];
        const files = formData.concat(fileData);
        setFormData(files);
        onFileChange(files)

        const reader = new FileReader();
        reader.addEventListener("load", handleEvent(currentFile, files, reader), false)

        reader.addEventListener("progress", (event) => {
          setFileUploadPercent(Math.round((event.total / event.loaded) * 100));
        })
        reader.readAsDataURL(currentFile);

        setInvalidDoc(false);
      }
    }
    else {
      setInvalidDoc(true);
    }
  }

  const handleEvent = (currentFile, files, reader) => {
    setIsButtonDisabled(false);
    if (handleFileInfo !== undefined) {
      let result = reader?.result;
      let dataURLDeclarationPosition = result?.search(dataURLDeclaration);
      dataURLDeclarationPosition = dataURLDeclarationPosition > -1 ? (dataURLDeclarationPosition + dataURLDeclaration.length) : 0;
      result = result?.substr(dataURLDeclarationPosition);
      handleFileInfo(currentFile, result, docFormType, docFormIndex, documentTypeId, files);
    }
  }

  const checkTotalBytes = (currentFile) => {

    if (currentFile.length > 1) {
      // handle multiple files at once
      let totalPerFile = 0
      for (let i = 0; i < currentFile.length; i++) {
        let filesByte = currentFile[i].size

        for (let jIndex = 0; jIndex < formData.length; jIndex++) {
          totalPerFile = totalPerFile + formData[jIndex].size;
        }
        totalPerFile = totalPerFile + filesByte;

      }

      if (totalPerFile <= 4194304) {
        return true
      } else {
        return false
      }

    } else {

      let fileByte = currentFile[0].size
      let total = 0
      for (let index = 0; index < formData.length; index++) {
        total = total + formData[index].size;
      }
      total = total + fileByte;

      if (total <= 4194304) {
        return true
      } else {
        return false
      }
    }
  }

  const handleRemoveFile = (row) => {
    const files = formData.filter((_, i) => i !== row);
    setFormData(files);
    onFileChange(files);
    if (files.length == 0) {
      setInvalidDoc(false);
      setIsButtonDisabled(true);
    }
  }


  const renderFileIcon = (name) => {
    let type = name.substr(name.lastIndexOf('.') + 1).toUpperCase();
    if (type === 'PNG') {
      return <PngIcon className={styles.file} />
    } else if (type === 'PDF') {
      return <PdfIcon className={styles.file} />
    } else if (type === 'JPEG') {
      return <JpegIcon className={styles.file} />
    } else {
      return <JpgIcon className={styles.file} />
    }
  }

  return (
    <>
      <Dropzone customClass={customClass} onFileUpload={handleFileUpload} maxFiles={maxFiles} />
      <div className='w-100'>
        {!isButtonDisabled && formData.map((f, key) => (
          <div className={styles.uploadProgress}>
            {renderFileIcon(f.name)}
            <div key={key} id={key} className={styles.fileName}>{f.name}</div>
            <div className={styles.progressWrapper}>
              <div className={styles.uploadProgressWrapper}>
                <Progress className={styles.progress} value={fileUploadPercent} />
                <button id={"remove-btn" + key} onClick={() => handleRemoveFile(key)} aria-label="Cancel Uploaded File" className={styles.closeButton}></button>
              </div>
              <div className={styles.uploadPercentage}>
                upload&nbsp;
                <span>{fileUploadPercent}% completed</span>
              </div>
            </div>
          </div>
        ))}
        {invalidDoc &&
          <p className='errorMsg' >The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf).</p>
        }
      </div>
    </>
  );
};

export default UploadDocs;
