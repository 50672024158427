import React, { Component, Fragment } from "react";
import {
  Row, Col, Label,
  Input, Modal, ModalBody, ModalFooter, PopoverBody, PopoverHeader, Container, CardBody,
  Card
} from "reactstrap";
import { ReactComponent as VisaIcon } from '../../assets/images/cc-visa.svg';
import { ReactComponent as AmexIcon } from '../../assets/images/cc-amex.svg';
import { ReactComponent as DiscoverIcon } from '../../assets/images/cc-discover.svg';
import { ReactComponent as MastercardIcon } from '../../assets/images/cc-mastercard.svg';

import { ReactComponent as EditIcon } from '../../assets/images/pencil-outline.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Copy_Icon.svg';
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import classNames from "classnames";
import styles from "./ViewCampaign.module.css";
import { FormGroup } from "react-bootstrap";
import { CreateCampaignModal } from "./CreateCampaignModal";
import { EditEndDateModal } from "./EditEndDateModal";
import { PaymentMethod } from '../Sales/PaymentInformation/PaymentMethod';
import moment from 'moment';
import { PaymentOptionType, PaymentEntityType } from '../Global/PaymentConstants';
import { SessionContext } from '../../context/SessionContext';
import { ReactComponent as AddIcon } from '../../assets/images/plus.svg';
import CheckboxInput from "../shared/CheckboxInput/CheckboxInput";
import { CardDetails } from '../../components/Sales/PaymentInformation/CardDetails';
import { NotificationIds } from '../Global/NotificationConstants';
import { getDealerDocument, accountDocumentTypes } from '../../components/Global/DocumentHelper';
import EditButton from "../shared/EditButton/EditButton";
import SpinnerButton from "../shared/SpinnerButton/SpinnerButton";


let tableResponsiveLg = "table-responsive-lg";

const UFN = "Until Further Notice";

export class ViewCampaign extends Component {

  constructor(props) {
    super(props);
    this.state = {
      saveCampaign: false,
      createCampaign: false,
      beginDate: "",
      endDate: "",
      modalOpen: false,
      tempDate: '',
      showCalendar: null,
      campaignEndDate: undefined,
      showEndDate: false,
      viewCreateCampaign: false,
      editEndDate: false,
      laborReimb: undefined,
      campaignType: null,
      campaignTerm: null,
      rncExclusion: "No",
      selectedRow: undefined,
      editPaymentInfo: false,
      advantageName: this.props.advantageProgramName,
      tierData: this.props.tierData,
      coverageData: this.props.coverageData,
      blnHasScheduled: false,
      blnNoEndDate: false,
      blnPencil: false,
      blnCopy: false,
      dealerId: this.props.dealerId,
      errBeginDate: false,
      errRnc: false,
      errLabor: false,
      errCoverage: false,
      errTerm: false,
      showTermsText: false,
      withEndDate: false,
      errEndDate: false,
      errEndDateEdit: false,
      errReasonEdit: false,
      errNotesEdit: false,
      endDateEdit: "",
      reasonEdit: "",
      notesEdit: "",
      chkSalesEdit: false,
      withEndDateEdit: false,
      showReasonEdit: false,
      isInvoiceDealer: props.isInvoiceDealer,
      partyId: props.partyId,
      allTerms: [],
      profileModel: this.props.profileModel,
      billingAddressText: "",
      preferredCard: [],
      paymentPreference: "",
      dealerTermAcceptedDate: "",
      dateFormat: this.props.isCanada === true ? "DD/MM/YYYY" : "MM/DD/YYYY",
      preferredCardLastFour: "",
      hasPreferredCard: false,
      showCardDetails: false,
      addNewCard: false,
      billingAddress: {},
      isAutoPay: false,
      autoPayCheck: false,
      advCardExist: false,
      savedCard: this.props.savedCard,//{lastFour:"4448",vendor:"Visa"}
      spinnerStatus: false
    }
  }

  async componentDidMount() {
    await this.getAllTerms();
    const ret = this.getViewCampaignData();
    this.props.updateTableData(ret);
    this.getProgramInfo();
    this.getPaymentDetails();

    const { getCurrentTabAndSubtab } = this.context;
    const { tab } = getCurrentTabAndSubtab();
    if(tab.state.viewCampaignState !== undefined) {
      this.setState({ ...tab.state.viewCampaignState });
    }
  }

  componentDidUpdate(prevProps, prevState) {    
    if(prevState.isAutoPay !== undefined && prevState.isAutoPay !== this.state.isAutoPay) {
      this.getPaymentDetails();
    }
    if(prevState.isAutoPay === true && this.state.isAutoPay === true && this.state.hasPreferredCard === false) {
      this.getPaymentDetails();      
    }
  }

  getAllTerms = async () => {

    try {
      const route = "contract/getAllTerms/";
      const response = await fetch(route);
      if (response.ok) {
        const result = await response.json();
        this.setState({ allTerms: result })
        return result;
      }
    } catch (error) {
      console.error(error);
    }
  }

  getViewCampaignData = () => {

    const data = [...this.props.campaignData];
    const retData = [];

    if (typeof data != 'undefined') {

      for (let i = 0; i < data.length; i++) {

        let status = "";
        let rowStatus = "";
        let isScheduled = false;
        let isActive = false;

        const bDate = this.props.isCanada == false ? moment(data[i].startDate).format('MM/DD/YYYY') : moment(data[i].startDate).format('DD/MM/YYYY')
        let eDate = data[i].endDate == null ? UFN : moment(data[i].endDate).format('MM/DD/YYYY');

        if (eDate != UFN) {
          eDate = this.props.isCanada == false ? moment(data[i].endDate).format('MM/DD/YYYY') : moment(data[i].endDate).format('DD/MM/YYYY');
        }

        let blnExpired = false;

        const isDateNull = data[i].endDate == null ? true : false

        const d1 = new Date(data[i].startDate);
        const d2 = new Date(data[i].endDate);
        const cd = new Date();

        //end date less than current date = Expired
        if (d2 < cd && isDateNull == false) {
          status = (<div><span className="circleBadge danger" />Expired</div>)
          rowStatus = "expired"
          blnExpired = true;
          //start date greater than current date; could have an end date or not
        } else if (d1 > cd) {
          status = (<div><span className="circleBadge warning" />Scheduled</div>)
          rowStatus = "scheduled"
          isScheduled = true;
          //start date less than current date; could have an end date or not
        } else if (d1 <= cd) {
          status = (<div><span className="circleBadge success" />Active</div>)
          rowStatus = "active"
          isActive = true;
        }

        if ((cd >= d1 && cd <= d2)) {
          rowStatus = "active"
          isActive = true;
          //isScheduled = true;
        }

        let diffDate = d2.getTime() - cd.getTime();
        diffDate = Math.round(diffDate / (1000 * 3600 * 24));

        if (diffDate <= 30 && eDate != UFN && cd.getFullYear() === d2.getFullYear() && isActive === true) {
          eDate = (<div><span className="errorMsg">{eDate}</span></div>)
        }

        const laborReimb = this.state.tierData.length == 0 ? "0" : this.state.tierData.find(x => x.tierId == data[i].tierId).tierName;
        const campaignType = this.state.coverageData.length == 0 ? "0" : this.state.coverageData.find(x => x.coverageTypeId == data[i].coverageTypeId).description;

        const termComputed = this.state.allTerms.find(x => x.termId == data[i].termId).termInDays / 365
        const campaignTerm = termComputed == "0" || termComputed == "1" ? termComputed + " Year" : termComputed + " Years";

        const rncExclusion = data[i].excludeRncflag ? "Yes" : "No";
        const tableActions = (
          <div className={styles.editCampaignInfo}>
            {blnExpired == false ?
              <span 
                type="button" 
                id={data[i].campaignId} 
                className={styles.actionBtn} 
                onClick={(e) => this.editCampaignData(e, data[i].campaignId)}
              >
                <EditIcon alt="Edit Campaign information" />
              </span> : <div>&nbsp;</div>}
            <span 
              type="button" 
              id={data[i].campaignId} 
              className={styles.copyBtn} 
              onClick={(e) => this.copyCampaignData(e, data[i].campaignId)}
            >
              <CopyIcon alt="Copy Campaign information" />
            </span>
          </div>);
        //for PlanOptionsCampaign
        const tierId = data[i].tierId;
        const coverageTypeId = data[i].coverageTypeId;
        const termId = data[i].termId;
        const stopSales = data[i].stopSalesProcess != null && data[i].stopSalesProcess;

        retData.push({
          beginDate: bDate, endDate: eDate, campaignStatus: status, laborReimb: laborReimb,
          campaignType: campaignType, campaignTerm: campaignTerm, rncExclusion: rncExclusion,
          tableActions: tableActions, id: i, isScheduled: isScheduled, isActive: isActive,
          tierId: tierId, coverageTypeId: coverageTypeId, termId: termId, campaignId: data[i].campaignId,
          dealerProgramId: data[i].dealerProgramId, dealerId: data[i].dealerId, dummy: false, status: rowStatus,
          stopSales: stopSales, beginDateUtc: d1, endDateUtc: isDateNull === true ? null : d2
        })
      }
    }

    var sortedByDate = retData.sort((a, b) => b.beginDateUtc - a.beginDateUtc)
    return sortedByDate;

  }


  editCampaignData = (e, selectedId = null) => {
    const selectedRow = this.props.tableData.find(x => x.campaignId == selectedId);

    this.setState({ blnHasScheduled: false, blnNoEndDate: false })

    if (selectedRow.isScheduled == true) {
      var temp = [];
      temp.push(selectedRow)
      this.props.updateRow(temp)
      this.setState({ selectedRow: temp, viewCreateCampaign: true, editEndDate: false, blnPencil: true })
      return;
    }

    if (selectedRow.isActive == true) {
      var temp = [];
      temp.push(selectedRow)
      this.props.updateRow(temp)

      const withEdit = selectedRow.endDate === UFN || selectedRow.endDate == null ? false : true

      this.setState({ selectedRow, viewCreateCampaign: false, editEndDate: true, chkSalesEdit: selectedRow.stopSales, endDateEdit: selectedRow.endDate, withEndDateEdit: withEdit })
      return;
    }

  }

  copyCampaignData = async (e, selectedId = null) => {

    await this.setState({ blnHasScheduled: false, blnNoEndDate: false })
    let selectedRow = this.props.tableData.find(x => x.campaignId == selectedId);

    if (this.props.tableData.some(x => x.isScheduled === true)) {
      this.setState({ blnHasScheduled: true })
      return;
    }

    if (this.props.tableData.some(x => x.endDate == UFN)) {
      this.setState({ blnNoEndDate: true })
      return;
    }

    if (this.props.tableData.some(x => x.isActive == true && x.endDate == UFN)) {
      this.setState({ blnHasScheduled: true })
      return;
    }

    if (typeof selectedRow == 'undefined') {
      var temp = [];
      temp.push({
        campaignId: 0,
        dealerProgramId: 1,
        dealerId: this.state.dealerId,
        tierId: "",
        coverageTypeId: "",
        termId: "",
        endDate: "",
        modifiedBy: "",
        modifiedDate: "",
        createdBy: "",
        createdDate: "",
        beginDate: "",
        rncExclusion: "",
        id: 999
      });
      this.setState({ selectedRow: temp, viewCreateCampaign: true })

    } else {
      var temp = [];
      temp.push({
        campaignId: 0,
        dealerProgramId: 1,
        dealerId: this.state.dealerId,
        tierId: selectedRow.tierId,
        coverageTypeId: selectedRow.coverageTypeId,
        termId: selectedRow.termId,
        endDate: "",
        modifiedBy: "",
        modifiedDate: "",
        createdBy: "",
        createdDate: "",
        beginDate: "",
        rncExclusion: selectedRow.rncExclusion,
        id: 999
      });
      this.setState({ selectedRow: temp, viewCreateCampaign: true, blnCopy: true })
    }



  }

  validateRequired = (data) => {
    let errors = [];

    if (data.beginDate == "") {
      this.setState({ errBeginDate: true })
      errors.push(true)
    } else {
      this.setState({ errBeginDate: false })
    }


    if (this.state.withEndDate == true) {
      if (data.endDate == UFN || data.endDate == "") {
        this.setState({ errEndDate: true })
        errors.push(true)
      } else {
        this.setState({ errEndDate: false })
      }
    }

    if (data.rncExclusion == "") {
      this.setState({ errRnc: true })
      errors.push(true)
    } else {
      this.setState({ errRnc: false })
    }

    if (data.tierId == "" || data.tierId == 5) {
      this.setState({ errLabor: true })
      errors.push(true)
    } else {
      this.setState({ errLabor: false })
    }

    if (data.tierId != 5) {
      if (data.coverageTypeId == "") {
        this.setState({ errCoverage: true })
        errors.push(true)
      } else {
        this.setState({ errCoverage: false })
      }

      if (data.termId == "") {
        this.setState({ errTerm: true })
        errors.push(true)
      } else {
        this.setState({ errTerm: false })
      }
    }
    return errors;
  }

  updateErrors = (name) => {
    if (name == "bdate") {
      this.setState({ errBeginDate: false })
    } else if (name == "rnc") {
      this.setState({ errRnc: false })
    } else if (name == "labor") {
      this.setState({ errLabor: false })
    } else if (name == "coverage") {
      this.setState({ errCoverage: false })
    } else if (name == "term") {
      this.setState({ errTerm: false })
    } else if (name == "edate") {
      this.setState({ errEndDate: false })
    }
  }

  handleCreateUpdate = async (session) => {

    var updatedRow = this.props.tableData.find(x => x.campaignId == this.state.selectedRow[0].campaignId);


    if (typeof updatedRow == 'undefined') {
      updatedRow = this.state.selectedRow.find(x => x.id == 999);
    }

    const err = this.validateRequired(updatedRow)

    if (err.length > 0) {
      return;
    }


    var convertedBeginDate = this.props.isCanada === true ? moment(updatedRow.beginDate, "DD/MM/YYYY").toDate() : moment(updatedRow.beginDate, "MM/DD/YYYY").toDate();
    var convertedEndDate = this.props.isCanada === true ? moment(updatedRow.endDate, "DD/MM/YYYY").toDate() : moment(updatedRow.endDate, "MM/DD/YYYY").toDate();


    var model = {
      "campaignId": this.state.blnPencil === true ? updatedRow.campaignId : 0,
      "dealerProgramId": updatedRow.dealerProgramId,
      "dealerId": updatedRow.dealerId,
      "tierId": updatedRow.tierId == "" ? 0 : updatedRow.tierId,
      "coverageTypeId": updatedRow.coverageTypeId === "" ? 0 : updatedRow.coverageTypeId,
      "termId": updatedRow.termId === "" ? 0 : updatedRow.termId,
      "excludeRncflag": updatedRow.rncExclusion === "Yes" ? true : false,
      "startDate": convertedBeginDate, //new Date(updatedRow.beginDate),
      "endDate": convertedEndDate, //new Date(updatedRow.endDate),
      "modifiedBy": this.state.blnPencil === true ? "VendorPortal" : null,
      "modifiedDate": this.state.blnPencil === true ? new Date() : null,
      "createdBy": this.state.blnPencil === false ? "VendorPortal" : null,
      "createdDate": this.state.blnPencil === false ? new Date() : null
    }

    var route = this.state.blnPencil === true ? "dealer/UpdateCampaign/" : "dealer/CreateCampaign/"
    this.setState({ saveCampaign: true });
    var response = await fetch(route, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    });
    var res = await response.json();

    if (typeof res != 'undefined') {
      var camp = await this.props.getDealerCampaigns()
      this.props.updateCampaignData(camp)
      var ret = this.getViewCampaignData()
      this.refreshTableData(!this.state.selectedRow[0].dummy)
      this.props.updateTableData(ret);

      this.setState({ saveCampaign: false, viewCreateCampaign: false, showTermsText: false, blnCopy: false, blnPencil: false })
      session.removeNotificationAndAlert(NotificationIds.NoCampaign);
      session.removeNotificationAndAlert(NotificationIds.CampaignExpiring);
    }

  }

  refreshTableData = (blnValue) => {
    var sArr = [...this.props.tableData];
    var newData = sArr.map((obj) => {
      if (obj.id == this.state.selectedRow[0].id)
        return Object.assign({}, obj, { dummy: blnValue })
      return obj
    });

    this.props.updateTableData(newData)

  }

  updateShowTermsText = (value) => {
    this.setState({ showTermsText: value })
  }

  toggle = async () => {

    var camp = await this.props.getDealerCampaigns()
    this.props.updateCampaignData(camp)
    var ret = this.getViewCampaignData()
    this.refreshTableData(!this.state.selectedRow[0].dummy)
    this.props.updateTableData(ret);

    this.setState({ viewCreateCampaign: false, showTermsText: false, blnCopy: false, blnPencil: false })
    this.setState({ errBeginDate: false, errRnc: false, errLabor: false, errCoverage: false, errTerm: false, showTermsText: false, withEndDate: false, errEndDate: false })

  }

  updateEndDateChecker = (value) => {
    this.setState({ withEndDate: value })
  }

  updateBeginDateErr = (value) => {
    this.setState({ errBeginDate: value })
  }

  toggleEditEndDate = async () => {
    this.setState({ editEndDate: false, errEndDateEdit: false, errReasonEdit: false, reasonEdit: "", endDateEdit: "", notesEdit: "", chkSalesEdit: false, showReasonEdit: false })
  }

  handleSaveEdit = async (session) => {

    const err = this.validateRequiredEdit();

    if (err === true) {
      return;
    }

    let endReasonId = 0;
    if (this.state.reasonEdit == 4) {
      endReasonId = await this.createCampaignEndReason();
    } else {
      endReasonId = this.state.reasonEdit == "" ? 0 : this.state.reasonEdit
    }

    var updatedRow = this.props.tableData.find(x => x.campaignId == this.state.selectedRow.campaignId);

    var convertedBeginDate = this.props.isCanada === true ? moment(updatedRow.beginDate, "DD/MM/YYYY").toDate() : moment(updatedRow.beginDate, "MM/DD/YYYY").toDate();
    var convertedEndDate = this.props.isCanada === true ? moment(this.state.endDateEdit, "DD/MM/YYYY").toDate() : moment(this.state.endDateEdit, "MM/DD/YYYY").toDate();
    var daysToEnd = this.props.isCanada === true ? moment(this.state.endDateEdit, "DD/MM/YYYY").diff(moment(Date.now()), 'days') : moment(this.state.endDateEdit, "MM/DD/YYYY").diff(moment(Date.now()), 'days');


    var model = {
      "campaignId": updatedRow.campaignId,
      "dealerProgramId": updatedRow.dealerProgramId,
      "dealerId": updatedRow.dealerId,
      "tierId": updatedRow.tierId,
      "coverageTypeId": updatedRow.coverageTypeId,
      "termId": updatedRow.termId,
      "excludeRncflag": updatedRow.rncExclusion === "Yes" ? true : false,
      "startDate": convertedBeginDate, //new Date(updatedRow.beginDate),
      //"endDate": this.state.withEndDateEdit == true ? new Date(this.state.endDateEdit) : null,
      "endDate": this.state.withEndDateEdit == true ? convertedEndDate : null,
      "modifiedBy": "VendorPortal",
      "modifiedDate": new Date(),
      "createdBy": null,
      "createdDate": null,
      "campaignEndReasonId": endReasonId,
      "stopSalesProcess": this.state.chkSalesEdit === true
    }

    var route = "dealer/updatecampaign/"
    var response = await fetch(route, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    });
    var res = await response.json();

    if (res != 'undefined') {
      var camp = await this.props.getDealerCampaigns()
      this.props.updateCampaignData(camp)
      var ret = this.getViewCampaignData()
      this.props.updateTableData(ret);

      this.toggleEditEndDate()
      if (daysToEnd >= 30) {
        session.removeNotificationAndAlert(NotificationIds.CampaignExpiring);
      }
    }

  }

  validateRequiredEdit = () => {

    let ret = false;

    if (this.state.withEndDateEdit == true) {
      if (this.state.endDateEdit == "" || this.state.endDateEdit == UFN) {
        this.setState({ errEndDateEdit: true })
        ret = true;
      } else {
        this.setState({ errEndDateEdit: false })
      }
    } else {
      this.setState({ errEndDateEdit: false })
    }

    if (this.state.showReasonEdit === true) {
      if (this.state.reasonEdit == "") {
        this.setState({ errReasonEdit: true })
        ret = true;
      } else if (this.state.reasonEdit == "4") {
        if (this.state.reasonEdit == "4") {
          if (this.state.notesEdit.trim() == "") {
            this.setState({ errNotesEdit: true })
            ret = true;
          }
        } else {
          this.setState({ errNotesEdit: false })
        }
      } else {
        this.setState({ errReasonEdit: false })
      }
    }

    return ret

  }

  updateEditDateScreen = (name, value) => {
    if (name == "state") {
      this.setState({ withEndDateEdit: value })
    } else if (name == "reason") {
      this.setState({ reasonEdit: value })
    } else if (name == "date") {
      this.setState({ endDateEdit: value })
    }
  }

  updateErrEditDateScreen = (name, value) => {
    if (name == "state") {
      this.setState({ errEndDateEdit: value })
    } else if (name == "reason") {
      this.setState({ errReasonEdit: value })
    } else if (name == "notes") {
      this.setState({ errNotesEdit: value })
    }
  }

  updateEditNotes = (value) => {
    this.setState({ notesEdit: value })
  }

  updateSalesCheck = (value) => {
    this.setState({ chkSalesEdit: value })
  }

  updateShowReason = (value) => {
    this.setState({ showReasonEdit: value })
  }

  createCampaignEndReason = async () => {

    var model = {
      "campaignEndReasonId": 0,
      "reason": this.state.notesEdit,
      "default": false,
      "modifiedBy": null,
      "modifiedDate": null,
      "createdBy": "VendorPortal",
      "createdDate": new Date()
    }

    var route = "dealer/createendreason/"

    var response = await fetch(route, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    });
    var res = await response.json();

    if (res != 'undefined') {
      return res.campaignEndReasonId;
    }
  }

  possibleBeginDate = () => {

    var retVal = ""

    if (this.props.tableData.find(x => x.isActive == true)) {
      var activeRow = this.props.tableData.find(x => x.isActive == true);
      var momentDate = "";
      if (typeof activeRow.endDate === 'object') {
        var newEndDate = JSON.stringify(activeRow.endDate.props.children.props.children);
        var newActiveRowDate = moment(newEndDate, this.state.dateFormat).toDate();
      }

      if (typeof activeRow != 'undefined') {
        if (activeRow.endDate == UFN || activeRow.endDate == '') {
          retVal = moment(new Date()).add(1, "days").toDate();
        } else {
          var endDate = moment(typeof activeRow.endDate === 'object' ? newActiveRowDate : activeRow.endDate, this.state.dateFormat).toDate();
          var currentDate = new Date();
          var computeDate = endDate < currentDate ? new Date() : endDate;
          momentDate = moment(computeDate, this.state.dateFormat);
          retVal = moment(momentDate).add(1, "days").toDate();

        }
        return retVal;
      } else {
        retVal = moment(new Date()).add(1, "days").toDate();
        return retVal
      }
    }
    else {
      retVal = moment(new Date()).add(1, "days").toDate();
      return retVal;
    }

  }

  showPaymentDetails = () => {
    let res = this.state.profileModel.isLennoxDealer || this.state.profileModel.isInvoiceDealer ? false : true;
    return res;
  }

  toggleCardDetails = (show) => {
    this.setState(prev => ({
      showCardDetails: show
    }, () => {
      const { getCurrentTabAndSubtab } = this.context;
      const { tab } = getCurrentTabAndSubtab();
      let { updateTab } = this.context;
      let tempState = {...tab.state};
      tempState.viewCampaignState = {...this.state};
      updateTab({
          tabId: tab.tabId,
          tabType: "profile",
          state: tempState
      });
    }));
  }

  enableSavedCard = () => {
    return (this.state.savedCard != undefined) ? Object.keys(this.state.savedCard).length !== 0 : false;
  }
  getProgramInfo = () => {
    const { profileModel, dateFormat } = this.state;

    fetch(`dealer/getdealerprogramenrollment/${profileModel.dealerId}/${profileModel.accountId}`)
      .then(res => res.json())
      .then(data => {
        if (data.campaigns.length > 0) {
          const enableSavedCard = this.enableSavedCard();
          this.setState({
            dealerTermAcceptedDate: moment(data.enrollmentDate).format(dateFormat),
            isAutoPay: data.isAutoPay,
            autoPayCheck: data.isAutoPay,
            showCardDetails: !enableSavedCard && data.isAutoPay
          });
        }
      })

  }

  getPaymentDetails = async () => {
    const { profileModel } = this.state;
    const partyId = profileModel.partyId;
    let advCardExist = false;

    var res = await fetch(`party/PaymentOptions/${partyId}`);

    if (res.status === 200) {
      var data = await res.json();

      let preferred = [];
      let selectedCard = "";
      let billingAddress = "";

      if (data.length > 0) {
        preferred = data.filter(x => x.preferredOptionType === PaymentOptionType.advantagePreferred);
        let billingAddressText = "N/A";

        if (preferred.length <= 0) {
          preferred = data;
        }
        else {
          advCardExist = true;
        }

        if (preferred.length > 0) {
          selectedCard = preferred[0];
          let transIds = [selectedCard.transactionId];

          var res2 = await fetch(`payment/GetCardInfoByTransactionIds`,
            {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(transIds)
            }
          );

          if (res2.status === 200) {
            var data2 = await res2.json();

            if (data2 != null) {
              data2 = data2[0].transactionModel;
              billingAddress = {
                address1: data2.address1 == null ? "" : data2.address1,
                address2: data2.address2 == null ? "" : data2.address2,
                city: data2.city == null ? "" : data2.city,
                state: data2.stateProvince == null ? "" : data2.stateProvince,
                stateAbbrev: data2.stateProvince == null ? "" : data2.stateProvince,
                zip: data2.zipPostalCode == null ? "" : data2.zipPostalCode,
              }

              if (billingAddress != null) {
                billingAddressText = `${billingAddress.address1}, ${billingAddress.address2} ${billingAddress.city}, ${billingAddress.state} ${billingAddress.zip}`;
              }
            }
          }
        }

        this.setState({
          preferredCard: preferred,
          preferredCardLastFour: selectedCard.lastFour,
          hasPreferredCard: true,
          billingAddressText: billingAddressText,
          billingAddress: billingAddress,
        });
      }

      this.setState({ advCardExist });
    }
  }

  getVendorIcon = () => {
    let preferred = this.state.preferredCard;

    if (preferred && preferred.length > 0) {
      const vendor = this.state.preferredCard.vendor;

      switch (vendor) {
        case "amex":
          return <AmexIcon />
          break;
        case "discover":
          return <DiscoverIcon />
          break;
        case "mastercard":
          return <MastercardIcon />
          break;
        case "visa":
          return <VisaIcon />
          break;
        default:
          return <VisaIcon />
          break;
      }
    }
  }

  openTermsAndConditions = (profileModel) => {
    getDealerDocument(accountDocumentTypes.AdvantageTermsAndConditions, true, profileModel, null);
  }

  saveCard = async () => {
    if (this.showPaymentDetails() && this.state.showCardDetails) {
      if (this.refs.creditCard != null) {

        if (this.refs.creditCard.checkForValidationErrors()) {
          this.forceUpdate()
          return;
        }

        let cc = await this.refs.creditCard.pay();
        if (cc.isSuccessful) {
          let res = this.getPaymentOptions();
          this.toggleCardDetails(false);
          this.toggleEdit();
          return true;
        }
      }
    }
  }

  getPaymentOptions = () => {
    const { optionTypeFilter } = this.state;
    fetch('party/PaymentOptions/' + this.state.partyId)
      .then(res => {
        if (res.ok) {
          res.json()
            .then(data => {
              if (optionTypeFilter !== 0 && data.length > 0) {
                this.setState({ savedCard: data.filter(x => x.preferredOptionType === PaymentOptionType.preferred) });
              }
            });
        }
      });
  }

  handleUpdatePayment = async () => {
    this.context.removeNotificationAndAlert(NotificationIds.AdvantagePaymentExpired);
    this.setState({ isAutoPay: this.state.autoPayCheck });
    var model = {
      "accountId": this.state.profileModel.accountId,
      "dealerId": this.state.profileModel.dealerId,
      "source": "VendorPortal",
      "isAutoPay": this.state.autoPayCheck
    }
    await fetch("dealer/UpdateAutoPay", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    });
    this.getPaymentDetails();
  }

  refreshCards = () => {
    if (this.refs.paymentMethod !== undefined)
      this.refs.paymentMethod.getPaymentOptions();
  }

  toggleEdit = () => {
    this.setState(
      { editPaymentInfo: !this.state.editPaymentInfo }, () => {
        const { getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        let { updateTab } = this.context;
        let tempState = {...tab.state};
        tempState.viewCampaignState = {...this.state};
        updateTab({
            tabId: tab.tabId,
            tabType: "profile",
            state: tempState
        });
      }
    );
  }

  handleCheck = () => {
    const { autoPayCheck } = this.state;
    const enableSavedCard = this.enableSavedCard();
    const autoPayCheckDetail = !autoPayCheck;
    this.setState({
      autoPayCheck: autoPayCheckDetail,
      showCardDetails: !enableSavedCard ? autoPayCheckDetail : false
    }, () => {
      const { getCurrentTabAndSubtab } = this.context;
      const { tab } = getCurrentTabAndSubtab();
      let { updateTab } = this.context;
      let tempState = {...tab.state};
      tempState.viewCampaignState = {...this.state};
      updateTab({
          tabId: tab.tabId,
          tabType: "profile",
          state: tempState
      });
    })
  }

  handleSave = () => {
    const { autoPayCheck } = this.state;
    const enableSavedCard = this.enableSavedCard();
    this.setState({ spinnerStatus: true });
    if (enableSavedCard) {
      this.toggleEdit();
      if (!autoPayCheck) this.setState({ isAutoPay: autoPayCheck }, () => {
        const { getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        let { updateTab } = this.context;
        let tempState = {...tab.state};
        tempState.viewCampaignState = {...this.state};
        updateTab({
            tabId: tab.tabId,
            tabType: "profile",
            state: tempState
        });
      })
    } else {
      if (autoPayCheck) {
        this.getPaymentDetails();
        this.saveCard();
      } else {
        this.setState({ isAutoPay: autoPayCheck }, () => {
          const { getCurrentTabAndSubtab } = this.context;
          const { tab } = getCurrentTabAndSubtab();
          let { updateTab } = this.context;
          let tempState = {...tab.state};
          tempState.viewCampaignState = {...this.state};
          updateTab({
              tabId: tab.tabId,
              tabType: "profile",
              state: tempState
          });
        });
        this.toggleEdit();
        //this.closeEdit();
      }
    }
    this.handleUpdatePayment();
    this.context.removeNotificationAndAlert(NotificationIds.NoAdvantagePaymentMethod);
    
    this.setState({ spinnerStatus: false }, () => {
        const { getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        let { updateTab } = this.context;
        let tempState = {...tab.state};
        tempState.viewCampaignState = {...this.state};
        updateTab({
            tabId: tab.tabId,
            tabType: "profile",
            state: tempState
        });
      }
    )
  }

  editPayment = () => {
    const { isAutoPay } = this.state;
    const enableSavedCard = this.enableSavedCard();
    this.setState({ editPaymentInfo: true, autoPayCheck: isAutoPay });
    const autoPayCheckDetail = this.enableSavedCard() ? isAutoPay : true;
    this.setState({ autoPayCheck: autoPayCheckDetail, showCardDetails: !enableSavedCard ? true : false }, () => {
        const { getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab();
        let { updateTab } = this.context;
        let tempState = {...tab.state};
        tempState.viewCampaignState = {...this.state};
        updateTab({
            tabId: tab.tabId,
            tabType: "profile",
            state: tempState
        });
      }
    );
    if (isAutoPay && !enableSavedCard) this.toggleCardDetails(true);
  }

  render() {
    const { viewCreateCampaign, editEndDate, selectedRow, editPaymentInfo, advCardExist, savedCard, autoPayCheck } = this.state;
    const enableSavedCard = this.enableSavedCard();

    const { getCurrentTabAndSubtab } = this.context;
    const { tab } = getCurrentTabAndSubtab();

    const viewCampaignColumns = [
      {
        dataField: "beginDate",
        text: "Begin Date",
        // classes: ,
        headerClasses: styles.campaignBeginDt,
      },
      {
        dataField: "endDate",
        text: "End Date",
        //   classes: styles.columnType,
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "campaignStatus",
        text: "Status",
        // classes: styles.size,
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "laborReimb",
        text: "Labor Reimb",
        // classes: styles.size,
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "campaignType",
        text: "Type",
        //classes: 
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "campaignTerm",
        text: "Term",
        //classes: 
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "rncExclusion",
        text: "RNC Exclusion",
        //classes: 
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: "tableActions",
        text: "",
        //classes: 
        // headerClasses: styles.columnHeader,
      },
      {
        dataField: 'id',
        text: 'id',
        hidden: true
      }
    ];

    return (
      <CardBody>
        <h2 className="h5 subHead">
          My {this.props.advantageProgramName} Campaigns
        </h2>
        <div className={styles.viewCampaignTable}>
          <BootstrapTable
            keyField="id"
            data={this.props.tableData}
            columns={viewCampaignColumns}
            bordered={false}
            bootstrap4
            wrapperClasses={tableResponsiveLg}
            pagination={paginationFactory({
              sizePerPage: 5,
              hideSizePerPage: true,
              hidePageListOnlyOnePage: true,
            })}
          />
        </div>
        <div className={styles.createCampgnBtn}>
          {this.state.blnHasScheduled == true && (
            <div className="errorMsg">
              You cannot create a New Campaign, you already have a scheduled
              campaign pending.
            </div>
          )}
          {this.state.blnNoEndDate == true && (
            <div className="errorMsg">
              Before creating a new campaign, you must add an end date to your
              active campaign.
            </div>
          )}
          <button
            onClick={(e) => this.copyCampaignData(e)}
            className={classNames(
              "btn-secondary iconBtn",
              styles.addCampaignBtn
            )}
          >
            <span className={""}>
              <AddIcon style={{ height: "11px", width: "11px" }} />
            </span>
            <span>Create New Campaign</span>
          </button>
        </div>
        {this.showPaymentDetails() && (
          <>
            <hr className="cardSectionHR" />
            <div className={styles.paymentInfoHead}>
              <h2 className="h5 subHead mb-1">Payment Details</h2>
              {!editPaymentInfo && (
                <EditButton
                  className={styles.editPayment}
                  onClick={() => this.editPayment()}
                />
              )}
            </div>

            {editPaymentInfo === false ? (
              <Fragment>
                <Row
                  className={classNames("multiColWrapper", styles.paymentRow)}
                >
                  <Col xs="12" md="4" lg="2" className="col-5ths">
                    <FormGroup>
                      <label className="form-label">Payment Preference</label>
                      <p>
                        {this.state.isAutoPay ? "Weekly Autopay" : "Manual Pay"}
                      </p>
                    </FormGroup>
                  </Col>
                  {this.state.isAutoPay && (
                    <Col xs="12" md="4" lg="2" className="col-5ths">
                      <FormGroup>
                        <label className="form-label">Payment Method</label>
                        <div className={styles.payMethod}>
                          {this.getVendorIcon()}
                          {this.state.hasPreferredCard && (
                            <p>
                              ending with {this.state.preferredCardLastFour}
                            </p>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  )}
                  {this.state.isAutoPay && (
                    <Col xs="12" md="4" lg="2" className="col-5ths">
                      <FormGroup>
                        <label className="form-label">Billing Address</label>
                        <p className="mb-0">{this.state.billingAddressText}</p>
                      </FormGroup>
                    </Col>
                  )}
                  <Col></Col>
                  <Col></Col>
                </Row>
              </Fragment>
            ) : (
              <Col className="p-0 mb-0">
                <PaymentMethod
                  ref="paymentMethod"
                  showPaymentMethod={false}
                  showCardDetails={editPaymentInfo}
                  showPaymentDetails={this.showPaymentDetails()}
                  toggleEdit={this.toggleEdit}
                  partyId={this.state.partyId}
                  preferredType={PaymentOptionType.advantagePreferred}
                  toggleCardDetails={this.toggleCardDetails}
                  updatePaymentDetails={this.handleUpdatePayment}
                  atAdvantageCampaign={true}
                  hideSavedCard={true}
                  enableSavedCard={enableSavedCard}
                  cardSelected={this.state.showCardDetails}
                ></PaymentMethod>
                <div
                  className={classNames(
                    "scriptBox",
                    "align-items-start",
                    "flex-column",
                    "mb-4"
                  )}
                >
                  <CheckboxInput
                    id={"autoPayCheck"}
                    customClass="mb-0"
                    clientId={this.context.accountName}
                    defaultChecked={
                      tab.state.viewCampaignState === undefined ||
                      tab.state.viewCampaignState === null
                        ? this.enableSavedCard()
                          ? this.state.isAutoPay
                          : true
                        : this.state.autoPayCheck
                    }
                    name={"autoPayCheck"}
                    textCustomClass="font-weight-bold"
                    handleCheck={(e) => this.handleCheck(e)}
                  >
                    Enroll in Weekly Autopay
                  </CheckboxInput>
                  <p className={styles.customParagraph}>
                    {autoPayCheck
                      ? `By checking this box you authorize us to automatically bill your preferred payment method (${savedCard[0]?.vendor?.toUpperCase()} #${
                          savedCard[0]?.lastFour
                        }) at the first of each week.`
                      : "By checking this box you authorize us to automatically bill your preferred payment method at the first of each week."}
                  </p>
                </div>
                {autoPayCheck && (
                  <p className={styles.customParagraphNote}>
                    Please provide your preferred payment method. This payment
                    method will be used for your (
                    {this.props.advantageProgramName}) sales and pre-selected
                    during manual sales entry.
                  </p>
                )}
                <p>
                  Note: Your preferred payment method can be updated on the
                  Billing Preferences tab.
                </p>
                <Card className={styles.advantageContainer}>
                  <CardBody className={"pl-0 pr-0 pt-0"}>
                    {this.showPaymentDetails() &&
                      this.state.showCardDetails &&
                      autoPayCheck && (
                        <CardDetails
                          ref="creditCard"
                          visible={this.state.showCardDetails}
                          isCanadaState={this.props.isCanada}
                          showLabel={!true}
                          amount={0}
                          billingAddress={
                            this.state.profileModel.mailingAddress
                          }
                          mailingAddress={
                            this.state.profileModel.mailingAddress
                          }
                          partyId={this.state.partyId}
                          savedCardType={PaymentOptionType.preferred}
                          showPaymentDetails={this.showPaymentDetails()}
                          accountId={this.state.profileModel.accountId}
                          hideSaveCard={true}
                          saveOnly={true}
                          chargedEntityIds={[]}
                          chargedEntityType={PaymentEntityType.NA}
                          atAdvantageCampaign={true}
                          countryId={this.state.profileModel.countryId}
                        />
                      )}
                    <div className="btnWrapper">
                      <button
                        className="btnLink"
                        onClick={() => {
                          this.toggleEdit();
                          this.toggleCardDetails(false);
                        }}
                      >
                        Cancel
                      </button>
                      <SpinnerButton
                        onClick={() => {
                          this.handleSave();
                        }}
                        disabled={this.state.spinnerStatus}
                        spinning={this.state.spinnerStatus}
                        type={"primary"}
                        text={"Save"}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </>
        )}

        <hr />
        <h2 className="h5 subHead">{this.props.advantageProgramName} Terms</h2>
        <div className={styles.advTerms}>
          <a
            className={styles.linkStyle}
            onClick={() => {
              this.openTermsAndConditions(this.state.profileModel);
            }}
          >
            {" "}
            View Terms and Conditions
          </a>
          Accepted on
                    <span>{this.state.dealerTermAcceptedDate}</span>
        </div>

        <SessionContext.Consumer>
          {(session) => (
            <>
              <CreateCampaignModal
                isOpen={viewCreateCampaign}
                toggle={this.toggle}
                modalClassName={styles.createCampaignModal}
                contentClassName={styles.createCampaignModalContent}
                centered={true}
                size="md"
                selectedRow={selectedRow}
                tableData={this.props.tableData}
                updateTableData={this.props.updateTableData}
                handleCreateUpdate={() => this.handleCreateUpdate(session)}
                blnPencil={this.state.blnPencil}
                blnCopy={this.state.blnCopy}
                updateRow={this.props.updateRow}
                updatedRowData={this.props.updatedRowData}
                isCanada={this.props.isCanada}
                errBeginDate={this.state.errBeginDate}
                errRnc={this.state.errRnc}
                errLabor={this.state.errLabor}
                errCoverage={this.state.errCoverage}
                errTerm={this.state.errTerm}
                updateErrors={this.updateErrors}
                showTermsText={this.state.showTermsText}
                updateShowTermsText={this.updateShowTermsText}
                advantageName={this.props.advantageProgramName}
                updateEndDateChecker={this.updateEndDateChecker}
                errEndDate={this.state.errEndDate}
                updateBeginDateErr={this.updateBeginDateErr}
                possibleBeginDate={this.possibleBeginDate}
                accountId={this.props.accountId}
                saveCampaign={this.state.saveCampaign}
              />

              <EditEndDateModal
                isOpen={editEndDate}
                toggle={this.toggleEditEndDate}
                modalClassName="editEndDateModal"
                contentClassName="editEndDateModalContent"
                centered={true}
                size="md"
                advantageName={this.state.advantageName}
                selectedRow={selectedRow}
                isCanada={this.props.isCanada}
                errEndDateEdit={this.state.errEndDateEdit}
                errReasonEdit={this.state.errReasonEdit}
                errNotesEdit={this.state.errNotesEdit}
                handleSaveEdit={() => this.handleSaveEdit(session)}
                endDateEdit={this.state.endDateEdit}
                reasonEdit={this.state.reasonEdit}
                notesEdit={this.state.notesEdit}
                chkSalesEdit={this.state.chkSalesEdit}
                validateRequiredEdit={this.validateRequiredEdit}
                withEndDateEdit={this.state.withEndDateEdit}
                updateEditDateScreen={this.updateEditDateScreen}
                updateEditNotes={this.updateEditNotes}
                updateErrEditDateScreen={this.updateErrEditDateScreen}
                updateSalesCheck={this.updateSalesCheck}
                updateShowReason={this.updateShowReason}
                tableData={this.props.tableData}
              />
            </>
          )}
        </SessionContext.Consumer>
      </CardBody>
    );
  }
}

ViewCampaign.contextType = SessionContext