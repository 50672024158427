import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card, CardBody, Row, Col, Alert, FormGroup, CardHeader, Label, Container } from 'reactstrap';
import { AdjudicationContext } from '../Authorizations/util/store/context';
import PartsTable from '../Authorizations/Tables/PartsTable';
import LaborTable from '../Authorizations/Tables/LaborTable';
import MileageTable from '../Authorizations/Tables/MileageTable';
import TaxTable from '../Authorizations/Tables/TaxTable';
import { fakeData } from '../Authorizations/util/fakedata';
import EditButton from "../shared/EditButton/EditButton";
import styles from '../Authorizations/Adjudication.module.css'
import { DropDownAutoComplete } from "../inputs/DropDownAutoComplete";
import EventTotalsRow from '../Authorizations/Tables/EventTotalsRow';
import CalendarInput from "../shared/CalendarInput/CalendarInput";
import ExpandableTextarea from "../shared/ExpandableTextArea/ExpandableTextarea";
import { CancelEntry } from "../Modals/CancelAuthorizationModal/CancelEntry";
import InfoTooltip from "../shared/InfoTooltip/InfoTooltip";
import PageLoading from "../shared/PageLoading/PageLoading";
import { newEntry } from '../Authorizations/util/util';
import moment from 'moment';
import { SessionContext } from '../../context/SessionContext';
import SimpleReactValidator from 'simple-react-validator';
import { Redirect, useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import SpinnerButton from '../shared/SpinnerButton/SpinnerButton';
import { historyPush } from '../../components/Global/helpers.jsx';


const InvoiceAdjudicationDisplay = (props) => {
    const [cancelAuthModal, setCancelAuthModal] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [saveInvoiceForm, setSaveInvoiceForm] = useState(false);
    const [eventId, setEventId] = useState(0);
    const [featureClaimId, setFeatureClaimId] = useState(0);
    const { state, dispatch } = useContext(AdjudicationContext);
    const [saved, setSaved] = useState(false);
    const [validationFired, setValidationFired] = useState(false);
    const [showAlert, setshowAlert] = useState(false);
    const [duplicateInvoice, setDuplicateInvoice] = useState(false);
    const [eventTypeId, setEventTypeId] = useState();
    const [isTableReadOnly, setIsTableReadOnly] = useState();
    var sessionContext = useContext(SessionContext);
    const history = useHistory();
    const [brandOptions, setBrandOptions] = useState([]);
    const [invoiceZeroError, setInvoiceZeroError] = useState(false);
    const [actionInProgress, setActionInProgress] = useState(false);
    const [mileageThresholdError, setMileageThresholdError] = useState(false);
    const [editItems, setEditItems] = useState(false);
    const [tripType, setTripType] = useState(null);
    const [invoiceDetails, setinvoiceDetails] = useState({
        invoiceNumber: '',
        productBrand: props.brandName && props.brandName !== 'Unknown' ? props.brandName : '',
        productSerialNumber: props.serialNumber ? props.serialNumber : '',
        productModelNumber: props.modelNumber ? props.modelNumber : '',
        serviceDate: '',
        invoiceSentDate: moment(new Date()).format("MM/DD/YYYY"),
        onSiteDiagnostic: '',
        resolution: '',
        AdditionalRates: '',
        servicerId: '',
        legacyServicerId:0,
        eventNumber: props.eventNumber ? props.eventNumber:'',
        servicerPhone: props.servicerPhone ? props.servicerPhone : '',
        productBrandValue: null
    });


    const handleClick = (e) => {
        setActionInProgress(true)
        if (e.currentTarget.outerText == "Continue")
        {
            setEditItems(false);
            const {
                totals: {
                    final: {
                        requested
                    }
                }
            } = state;
            if (!validator.current.allValid() || requested <= 0) {
                const errorFields = [];
                validator.current.showMessages();
                setValidationFired(!validationFired);
                setSaveInvoiceForm(false);
                requested <= 0 && setInvoiceZeroError(true);
                setActionInProgress(false);

                // To get the focus when validation error occur such that, error fields visible in viewport
                for (let key in validator.current.fields) {
                    if (validator.current.fields[key] === false) {
                        errorFields.push(key);
                    }
                }

                let errorElement = document.getElementById(errorFields[0]) || document.getElementsByName(errorFields[0])[0]
                if (errorElement) {
                    errorElement.focus();
                }
                return;
            }
            else
            {
                fetch(`accountspayable/GetServicerInvoice/${invoiceDetails.servicerId}/${invoiceDetails.invoiceNumber}/${props.clientId}`, {
                    method: 'get'
                }).then(res =>
                {
                    if (res.ok)
                    {
                        if (res.status == 204)
                        {
                            setDuplicateInvoice(false);
                            setSaveInvoiceForm(true);
                            setInvoiceZeroError(false);
                            setActionInProgress(false);
                            dispatch({
                                type: 'TOGGLE_READ_ONLY_MODE',
                                payload: {
                                    isTableReadOnly: true
                                }
                            })
                        }
                        else
                        {
                            res.json().then(data =>
                            {
                                if (data)
                                {
                                    dispatch({
                                        type: 'TOGGLE_READ_ONLY_MODE',
                                        payload: {
                                            isTableReadOnly: false
                                        }
                                    })
                                    setDuplicateInvoice(true);
                                    setSaveInvoiceForm(false);
                                }
                                else
                                {
                                    dispatch({
                                        type: 'TOGGLE_READ_ONLY_MODE',
                                        payload: {
                                            isTableReadOnly: true
                                        }
                                    })
                                    setDuplicateInvoice(false);
                                    setSaveInvoiceForm(true);
                                }
                            })
                        }
                    }
                    else
                    {
                        dispatch({
                            type: 'TOGGLE_READ_ONLY_MODE',
                            payload: {
                                isTableReadOnly: false
                            }
                        })
                        setSaveInvoiceForm(false);
                        setActionInProgress(false);
                    }
                });
              
            }
        }
        else
        {
            setSaveInvoiceForm(true);
            createInvoiceRequest("Approved");
            setActionInProgress(false);
        }
        
        
    };
    const handleClose = () =>
    {
        historyPush(props?.rfsData, "requestforservice", history, props?.clientId, null, true);
    }

    const navigateToConfirmation = () => {
        history.push('/InvoiceConfirmation', {
            eventNumber: props.eventNumber,
            eventId: props.eventId,
            clientId: props.clientId,
            servicerPhone: props.servicerPhone,
            invoiceNumber: invoiceDetails.invoiceNumber,
            rfsData: props?.rfsData
        });
    }
    const brandNameChange = (val) => {
        var brand = brandOptions.filter(b => b.data === val);
        setinvoiceDetails({
            ...invoiceDetails,
            productBrand: brand?.[0]?.display,
            productBrandValue: brand[0].data ? brand[0].data.toString() : '0'
        })
    }
    const additionalRates = (e) => {
        setinvoiceDetails({
            ...invoiceDetails,
            AdditionalRates: e.target.value,
        })
    }
    const invoiceNumberChange = (e) => {
        console.log(e.target.value);
        setinvoiceDetails({
            ...invoiceDetails,
            invoiceNumber: e.target.value,
        })
        setDuplicateInvoice(false);
    }

    const serialNumberChange = (e) => {
        setinvoiceDetails({
            ...invoiceDetails,
            productSerialNumber: e.target.value,
        })
    }
    const modelNumberChange = (e) => {
        setinvoiceDetails({
            ...invoiceDetails,
            productModelNumber: e.target.value,
        })
    }
    const onSiteDiagnosticChange = (e) => {
        setinvoiceDetails({
            ...invoiceDetails,
            onSiteDiagnostic: e.target.value,
        })
    }
    const resolutionChange = (e) => {
        setinvoiceDetails({
            ...invoiceDetails,
            resolution: e.target.value,
        })
    }
    const setServiceCompletionDate = (e) => {

        setinvoiceDetails({
            ...invoiceDetails,
            serviceDate: e,
        })
      
    }
    const setInvoiceDate = (e) => {
        setinvoiceDetails({
            ...invoiceDetails,
            invoiceSentDate: e,
        })
        
    }
    const {
        tax,
        totals: {
            final: {
                authorized,
                requested
            }
        }
    } = state;
    const validator = useRef(new SimpleReactValidator({
        element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
        messages: {
            required: ':attribute is required.'
        }
    }));

    useEffect(() => {
        for (let mlIndex in state.mileage) {
            let qty = state.mileage[mlIndex].qty;
            let tripTypedata = state.mileage[mlIndex].tripType
            if ((tripTypedata?.data === 'oneWay' && qty < 30) || (tripTypedata?.data === 'roundTrip' && qty < 60)) {
                setTripType(tripTypedata)
                setMileageThresholdError(true);
                return;
            }
        }
        setMileageThresholdError(false);
    }, [state?.mileage])


    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch(`Brand/getAll`);
            const resultData = await result.json();
            const filteredOptions = resultData.map(e => ({ display: e.name, data: e.brandId }))
            setBrandOptions(filteredOptions);
        };
        if (!saveInvoiceForm && !editItems)
        {
            fetchData();
            fetch(`authorization/GetAuthorizationVendorDetailsByEventNumber/${props.eventNumber}/${props.clientId}`)
                .then(res => res.json())
                .then(data => {
                    setEventId(data.eventId);
                    setFeatureClaimId(data.featureClaimId);
                    setinvoiceDetails({
                        ...invoiceDetails,
                        servicerId: data.servicerId.toString(),
                        legacyServicerId: data.legacyServicerId
                    })
                    setSaved(false);
                    setShowSpinner(false);
                    let preApprovedAmount = data.authorizationDisplays?.reduce((accum, item) => accum + item.preAuthorizedAmount, 0);
                    let claimAuthPartDetails = data.authorizationDisplays?.filter(c => c.authorizationType == "Parts");
                    let claimAuthLaborDetails = data.authorizationDisplays?.filter(c => c.authorizationType == "Labor" || c.authorizationType == "Diagnosis");
                    let claimAuthMileageDetails = data.authorizationDisplays?.filter(c => c.authorizationType == "Mileage");
                    let claimTaxDetails = data.authorizationDisplays?.filter(c => c.authorizationType == "Tax");
                    let rateModels = data.authorizationRateModels?.filter(c => c.billingCodeId !== 22 && c.billingCodeId !== 24).map(e => ({
                        display: e.billingAttributeDescription + ' ($' + e.amount + ')',
                        data: e.billingAttributeId,
                        rate: e.amount,
                        servicerRateId: e.servicerRateId,
                        billingCodeId: e.billingCodeId,
                        billingCategoryId: e.billingCategoryId,
                        billingCategoryDescription: e.billingCategoryDescription
                    }));
                    let coverageModels = data.coverageModels.map(e => ({
                        display: e.coverageName,
                        data: e.coverageId
                    }));
                    //let assets = data.assetModels.map(e => { display: e.assetName, data: e.assetName });
                    let assets = [];
                    console.log(data, data?.parts)
                    if (data.assetModels != null) {
                        for (var index = 0; index < data?.parts?.length; index++) {
                            if (data.parts[index].attributeName != null)
                                assets.push({ display: data.parts[index].attributeName, data: data.parts[index].attributeName })
                        }
                    }

                    if (rateModels == 'undefined' || rateModels == null) {
                        rateModels = [];
                    }
                    console.log(rateModels);

                    let parts = claimAuthPartDetails != null && claimAuthPartDetails.length != 0 ? claimAuthPartDetails.map(e => ({
                        id: e.authorizationId,
                        qty: e.qty,
                        unitPrice: e.unitPrice,
                        // table-specific
                        details: e.details == null ? [] : e.details.split(","),
                        partNumber: e.partNumber,
                        description: e.description,
                        coverage: coverageModels.find(c => c.data == e.coverageId),
                        billingCodeId: e.billingCodeId == null || e.billingCodeId == 0 ? 9 : e.billingCodeId,
                        billingCategoryId: 2,
                        eventId: e.eventId,
                        authorized: e?.authorizedAmount ?? 0,
                        requested: e?.requestedAmount ?? 0,
                        featureClaimId: e.featureClaimId
                    })) : [
                        //minimum necessary data
                        {
                            id: -62,
                            qty: 1,
                            unitPrice: 0,
                            details: [],
                            partNumber: "",
                            description: "",
                            coverage: "",
                            authorized: 0,
                            requested: 0,
                            billingCodeId: 9,
                            billingCategoryId: 2
                        }, {
                            id: -63,
                            qty: 1,
                            unitPrice: 0,
                            details: [],
                            partNumber: "",
                            description: "",
                            coverage: "",
                            authorized: 0,
                            requested: 0,
                            billingCodeId: 9,
                            billingCategoryId: 2
                        }, {
                            id: -64,
                            qty: 1,
                            unitPrice: 0,
                            details: [],
                            partNumber: "",
                            description: "",
                            coverage: "",
                            authorized: 0,
                            requested: 0,
                            billingCodeId: 9,
                            billingCategoryId: 2
                        }, {
                            id: -65,
                            qty: 1,
                            unitPrice: 0,
                            details: [],
                            partNumber: "",
                            description: "",
                            coverage: "",
                            authorized: 0,
                            requested: 0,
                            billingCodeId: 9,
                            billingCategoryId: 2
                        }];

                    let labor = claimAuthLaborDetails != null && claimAuthLaborDetails.length != 0 ? claimAuthLaborDetails.map(e => ({
                        id: e.authorizationId,
                        qty: e.qty,
                        unitPrice: e.unitPrice,
                        authorized: e?.authorizedAmount ?? 0,
                        requested: e?.requestedAmount ?? 0,
                        // table-specific
                        rateType: rateModels.find(r => r.billingCodeId == e.billingCodeId) || [],
                        coverage: coverageModels.find(c => c.data == e.coverageId),
                        billingCodeId: e.billingCodeId,
                        billingCategoryId: 1,
                        eventId: e.eventId,
                        featureClaimId: e.featureClaimId,
                        eventTypeId: e.eventTypeId
                    })) : [];

                    setEventTypeId(labor?.[0]?.eventTypeId);

                    let laborTaxAuthorization = data?.authorizationModels?.find(am => am.billingCodeModel?.billingAttributeModel?.billingAttributeDescription == "Labor Tax");
                    let partsTaxAuthorization = data?.authorizationModels?.find(am => am.billingCodeModel?.billingAttributeModel?.billingAttributeDescription == "Parts Tax");

                    let laborTax = claimTaxDetails?.find(lt => lt.authorizationId == laborTaxAuthorization?.authorizationId);
                    let partsTax = claimTaxDetails?.find(lt => lt.authorizationId == partsTaxAuthorization?.authorizationId);

                    let taxes = [
                        {
                            id: laborTax?.authorizationId ?? 0,
                            taxType: 'Labor Tax', // need to pull the right type
                            billingCodeId: laborTax?.billingCodeId ?? 32, /*PR-SEU - 3219*/
                            requested: laborTax?.requestedAmount ?? 0,
                            qty: laborTax?.qty ?? 1,
                            unitPrice: laborTax?.unitPrice ?? 0,
                            authorized: laborTax?.authorizedAmount ?? 0,
                            authEdited: false,
                        },
                        {
                            id: partsTax?.authorizationId ?? 0,
                            taxType: 'Parts Tax', // need to pull the right type
                            billingCodeId: partsTax?.billingCodeId ?? 33, /*PR-SEU - 3219*/
                            requested: partsTax?.requestedAmount ?? 0,
                            qty: partsTax?.qty ?? 1,
                            unitPrice: partsTax?.unitPrice ?? 0,
                            authorized: partsTax?.authorizedAmount ?? 0,
                            authEdited: false,
                        },
                    ];

                    let mileage = claimAuthMileageDetails != null && claimAuthMileageDetails.length != 0 ? claimAuthMileageDetails.map(e => ({
                        //minimum necessary data
                        id: e.authorizationId,
                        qty: e.qty,
                        unitPrice: e.unitPrice,
                        authorized: e?.authorizedAmount ?? 0,
                        requested: e?.requestedAmount ?? 0,
                        //table-specific
                        //tripType: (e.billingCodeId == null || e.billingCodeId == 24) ? "oneWay" : "roundTrip", /*(22 - RoundTrip)*/
                        tripType: tripTypeSelection(e.billingCodeId),
                        billingCodeId: e.billingCodeId,
                        billingCategoryId: 7,
                        coverage: coverageModels.find(c => c.data == e.coverageId),
                        eventId: e.eventId,
                        featureClaimId: e.featureClaimId
                    })) : [{
                        //minimum necessary data
                        id: 0,
                        qty: 0, //miles
                        unitPrice: 0.75,
                        //table-specific
                        tripType: { display: 'Round Trip', data: 'roundTrip' },
                        billingCodeId: 0,
                        requested: 0
                    }];




                    const fourRequiredPartsRowsToStart = () => {
                        const parts = [...fakeData.parts]
                        const diff = 4 - parts.length
                        if (diff > 0) {
                            for (let i = 1; i <= diff; i++) {
                                parts.push(newEntry('parts'))
                            }
                        }
                        return [...parts]
                    }
                    dispatch({
                        type: 'INITIATE_DATA',
                        payload: {
                            preapprovedAmount: preApprovedAmount,
                            negotiatedRateTypes: rateModels,
                            coverages: data.coverageModels.map(e => ({
                                display: e.coverageName,
                                data: e.coverageId
                            })),
                            labor: labor,
                            parts: parts,
                            mileage: mileage,
                            assets: assets,
                            // WIREUP: taxRate will come at a later date when we use a tax service
                            taxRate: taxes,
                            preapprovedAmount: props.preauthorizedAmount
                        }
                    })
                    // mock api loading. 
                    setTimeout(() => setShowSpinner(false), 1000)
                });

        }
        window.scrollTo(0, 0);

    }, [saved, saveInvoiceForm])

    const tripTypeSelection = (val) => {
        let tripval = '';
        if (val == 24)
            return { display: 'One Way', data: 'oneWay' };
        else if (val == 22)
            return { display: 'Round Trip', data: 'roundTrip' };
        else if (val == 47)
            return { display: 'Per Mile', data: 'perMile' };
        else if (val == 45)
            return { display: 'Trip', data: 'trip' };
        else if (val == 46)
            return { display: 'Flat Rate', data: 'flatRate' };
        else
            return { display: 'Round Trip', data: 'roundTrip' };
    }

    const getCamelCaseForStr = (str) => {
        if (!str || !isNaN(str)) {
            return ''
        }
        let [firstWord, ...secondWord] = str.split(' ');
        if (!secondWord) {
            return firstWord[0].toUpperCase() + firstWord.substr(1).toLowerCase();
        }
        return firstWord[0].toUpperCase() + firstWord.substr(1).toLowerCase() + ' ' + secondWord.join(' ').toLowerCase()
    }

    const toggleEditable = () => {
        setSaveInvoiceForm(false);
        setEditItems(true);
        dispatch({
            type: "TOGGLE_READ_ONLY_MODE",
            payload: {
                isTableReadOnly: false,
            }
        })
    }

    const createInvoiceRequest = (status) => {
        const { labor, parts, preapprovedAmount, totals, mileage, tax } = state; /*PR-SEU - 3219*/
        let claimAuthorizeRequest = [];

        for (var index = 0; index < labor.length; index++) {
            claimAuthorizeRequest.push({
                authorizationId: parseInt(labor[index].id) > 0 ? parseInt(labor[index].id) : 0,
                authorizationType: "Labor",
                authorizationStatus: status,
                rateId: labor[index].rateType.length > 0 ?labor[index].rateType.servicerRateId:0,
                rate: parseInt(labor[index].unitPrice),
                description: labor[index].rateType != null ? labor[index].rateType.display : '',
                // for some reason selecting from multiple coverages was dispatching an array into the drop down instead of the object
                // in the essence of time, check if is array and pull first element, else pull data SRV - 2/26/2021
                coverageId: 1,
                qty: parseInt(labor[index].qty),
                requestedAmount: labor[index].requested,
                authorizedAmount: labor[index].authorized,
                billingCodeId: labor[index].rateType != null ? labor[index].rateType.billingCodeId : 0,
                billingCategoryId: 1,
                eventId: labor[index].eventId == null || labor[index].eventId == 0 ? eventId : labor[index].eventId,
                featureClaimId: labor[index].featureClaimId == null || labor[index].featureClaimId == 0 ? featureClaimId : labor[index].featureClaimId,
                rateTypeModel: labor[index].rateType,
                ItemId:3,
                flatFileModel: invoiceDetails,
            });
        }

        for (var index = 0; index < parts.length; index++) {
            let description = parts[index].description;
            switch (typeof parts[index].description) {
                case "object":
                    description = description ? description.display : null;
                    break;
                case "undefined":
                    description = null;
                    break;
                default: // string
                    break;
            }
            if (parts[index].details.length > 0 || parts[index].partNumber != '' || (description != null && description!='')) {
                claimAuthorizeRequest.push({
                    authorizationId: parseInt(parts[index].id) > 0 ? parseInt(parts[index].id) : 0,
                    authorizationType: "Parts",
                    authorizationStatus: status,
                    eventNumber: props.eventNumber,
                    coverageId: 1,
                    qty: parseInt(parts[index].qty),
                    price: parseInt(parts[index].unitPrice),
                    requestedAmount: parts[index].requested,
                    authorizedAmount: parts[index].authorized,
                    partNumber: parts[index].partNumber,
                    description: description,
                    details: parts[index].details.join(','),
                    billingCodeId: parts[index].billingCodeId,
                    billingCategoryId: 2,
                    eventId: parts[index].eventId == null || parts[index].eventId == 0 ? eventId : parts[index].eventId,
                    featureClaimId: parts[index].featureClaimId == null || parts[index].featureClaimId == 0 ? featureClaimId : parts[index].featureClaimId,
                    ItemId: 1,
                    flatFileModel: invoiceDetails,
                });
            }
        }

        for (var index = 0; index < mileage.length; index++) {
            console.log(mileage, mileage[index]);
            if (parseInt(mileage[index].qty) > 0) { // SRV - to remove minimum required data for calculator
                let tripBillingCode = 0;
                if (mileage[index].tripType.data == 'oneWay')
                    tripBillingCode = 24;
                else if (mileage[index].tripType.data == 'roundTrip')
                    tripBillingCode = 22;
                else if (mileage[index].tripType.data == 'perMile')
                    tripBillingCode = 47;
                else if (mileage[index].tripType.data == 'trip')
                    tripBillingCode = 45;
                else if (mileage[index].tripType.data == 'flatRate')
                    tripBillingCode = 46;
                else
                    tripBillingCode = 24;
                claimAuthorizeRequest.push({
                    authorizationId: parseInt(mileage[index].id) > 0 ? parseInt(mileage[index].id) : 0,
                    authorizationType: "Mileage",
                    authorizationStatus: status,
                    qty: parseInt(mileage[index].qty),
                    unitPrice: mileage[index].unitPrice,
                    requestedAmount: mileage[index].requested,
                    authorizedAmount: mileage[index].authorized,
                    tripType: mileage[index].tripType?.data,
                    description: mileage[index].tripType?.display,
                    billingCodeId: tripBillingCode, /*mileage[index].billingCodeId,*/
                    billingCategoryId: 7,
                    eventId: mileage[index].eventId == null || mileage[index].eventId == 0 ? eventId : mileage[index].eventId,
                    featureClaimId: mileage[index].featureClaimId == null || mileage[index].featureClaimId == 0 ? featureClaimId : mileage[index].featureClaimId,
                    coverageId: 1,
                    flatFileModel: invoiceDetails,
                    ItemId: 13
                });
            }
        }

        /*PR-SEU - 3219*/
        for (var index = 0; index < tax.length; index++) {
            claimAuthorizeRequest.push({
                authorizationId: parseInt(tax[index].id) > 0 ? parseInt(tax[index].id) : 0,
                authorizationType: "Tax",
                authorizationStatus: status,
                //rateId: null,
                //rate: parseInt(tax[index].unitPrice),
                coverageId: 1,
                qty: parseInt(tax[index].qty),
                requestedAmount: tax[index].requested,
                authorizedAmount: tax[index].authorized,
                billingCodeId: tax[index].billingCodeId,
                billingCategoryId: 10,
                eventId: tax[index].eventId == null || tax[index].eventId == 0 ? eventId : tax[index].eventId,
                featureClaimId: tax[index].featureClaimId == null || tax[index].featureClaimId == 0 ? featureClaimId : tax[index].featureClaimId,
                rateTypeModel: tax[index].rateType,
                flatFileModel: invoiceDetails,
                ItemId: tax[index].billingCodeId==32 ? 15: 14
            });
        }
        /*PR-SEU - 3219*/
        console.log(claimAuthorizeRequest);
        fetch(`accountspayable/CreateInvoice/${props.clientId}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(claimAuthorizeRequest)
        }).then(res =>
        {
            if (res.ok)
            {
                navigateToConfirmation();
            }

        });
    }
    if (showSpinner) return  (<PageLoading  text="Loading Invoice"/>)
    var minDate = ""
    const dateNow = new Date()
    minDate = dateNow;
    const invoiceValidator = validator.current.message("invoiceNumber", invoiceDetails.invoiceNumber, 'required')
    const serialNumberValidator = validator.current.message("serialNumber", invoiceDetails.productSerialNumber, 'required')
    const serviceCompletionValidator = validator.current.message("serviceCompletionDate", invoiceDetails.serviceDate, 'required')
    const onsiteDiagnosticsValidator = validator.current.message("onsiteDiagnostic", invoiceDetails.onSiteDiagnostic, 'required')
    const resolutionValidator = validator.current.message("resolution", invoiceDetails.resolution, 'required')
    const brandValidator = validator.current.message("brandName", invoiceDetails.productBrand, 'required')
    const modelValidator = validator.current.message("modelNumber", invoiceDetails.productModelNumber, 'required')

    return ( 
        <Container className="contentWrapper"> 
            <Card>
                <CardHeader>
                    <div className="d-flex justify-content-between">
                        {saveInvoiceForm === true ? (
                            <h1 className="h4 mb-0">Review Invoice</h1>
                        ) : (
                            <h1 className="h4 mb-0">Submit Invoice</h1>
                        )}

                        {/* STATIC TEXT WITH BUTTON TO TURN ON EDITABLE FIELDS */}
                        {saveInvoiceForm && (
                            <EditButton onClick={() => {toggleEditable()}} />
                        )}
                    </div>
                </CardHeader>
                <CardBody className={styles.Adjudication}>
                    <Row className="multiColWrapper">
                        <Col xs="12" md="4" className="col-5ths">
                            <FormGroup className={`${invoiceValidator ? 'form-error' : ''}`}>
                                <label htmlFor="invoiceNum" className="form-label">Invoice Number</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                {saveInvoiceForm ? (
                                    <p>{invoiceDetails.invoiceNumber}</p>
                                ) : (
                                        <input type="text" className="form-control" id="invoiceNum" name="invoiceNumber" value={invoiceDetails.invoiceNumber} onChange={invoiceNumberChange} aria-required="true" />
                                )}
                                {invoiceValidator}
                                {duplicateInvoice == true && <div className="errorMsg">Duplicate InvoiceNumber</div>}
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="4" className="col-5ths">
                            <FormGroup className={`${brandValidator ? 'form-error' : ''}`}>
                                <label htmlFor="brandName" className="form-label">Brand</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                {saveInvoiceForm ? (
                                    <p>{invoiceDetails.productBrand}</p>
                                ) : (
                                    <DropDownAutoComplete
                                        name="brandName"
                                        className="form-control"
                                        id="brandName"
                                        aria-required="true"
                                        Value={invoiceDetails.productBrand}
                                        defaultValue={brandOptions.filter(e => e.display == invoiceDetails.productBrand)?.[0]?.data}
                                        onChangedValue={brandNameChange} 
                                        options={brandOptions}
                                    />
                                )}
                                {brandValidator}
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="4" className="col-5ths">
                            <FormGroup className={`${serialNumberValidator ? 'form-error' : ''}`}>
                                <label htmlFor="serialNum" className="form-label">Serial Number</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                {saveInvoiceForm ? (
                                    <p>{invoiceDetails.productSerialNumber}</p>
                                ) : (
                                        <input type="text" className="form-control" value={invoiceDetails.productSerialNumber} id="serialNum" onChange={serialNumberChange} name="serialNumber" aria-required="true" />
                                    )}
                                {serialNumberValidator}
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="4" className="col-5ths">
                            <FormGroup className={`${modelValidator ? 'form-error' : ''}`}>
                                <label htmlFor="modelNum" className="form-label">Model Number</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                {saveInvoiceForm ? (
                                    <p>{invoiceDetails.productModelNumber}</p>
                                ) : (
                                        <input type="text" className="form-control" id="modelNum" value={invoiceDetails.productModelNumber} onChange={modelNumberChange} name="modelNumber" aria-required="true" />
                                    )}
                                {modelValidator}
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="4" className="col-5ths">
                            <FormGroup className={`${serviceCompletionValidator ? 'form-error' : ''}`}>
                                <label htmlFor="serviceCompletionDate" className="form-label">Service Completion Date</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                {saveInvoiceForm ? (
                                    <p>{invoiceDetails.serviceDate}</p>
                                ) : (
                                    <CalendarInput
                                        id="serviceCompletionDate"
                                        name="serviceCompletionDate"
                                        placeHolder="MM/DD/YYYY"
                                        onChange={setServiceCompletionDate}
                                        value={invoiceDetails.serviceDate}
                                        maxDate={minDate}
                                        
                                    />
                                )}
                                {serviceCompletionValidator}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="twoColWrapper">
                        <Col xs="12" md="4" className="col-5ths">
                            <FormGroup className="">
                                <label htmlFor="invoiceDate" className="form-label">Invoice Date</label>
                                {saveInvoiceForm ? (
                                    <p>{invoiceDetails.invoiceSentDate}</p>
                                ) : (
                                    <CalendarInput
                                            id="invoiceDate"
                                            name="invoiceDate"
                                            placeHolder="MM/DD/YYYY" onChange={setInvoiceDate}
                                            value={invoiceDetails.invoiceSentDate}
                                            minDate={minDate} 
                                    />
                                )}
                            </FormGroup>
                        </Col>
                        <Col>
                            <Row className="twoColWrapper mb-0">
                                <Col md="6">
                                    <FormGroup className={`${onsiteDiagnosticsValidator ? 'form-error' : ''}`}>
                                        <label htmlFor="onSiteDiagnostic" className="form-label">Onsite Diagnostic</label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                        <InfoTooltip iconType="info" message="Provide a detailed description of the product failure." />
                                        {saveInvoiceForm ? (
                                            <p>{invoiceDetails.onSiteDiagnostic}</p>
                                        ) : (
                                            <ExpandableTextarea id="onsiteDiagnostic" maxChars={250} textValue={invoiceDetails.onSiteDiagnostic} onTextValueChange={onSiteDiagnosticChange} />
                                        )}
                                        <div className={styles.errorPadding}>{onsiteDiagnosticsValidator}</div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className={`${resolutionValidator ? 'form-error' : ''}`}>
                                        <label htmlFor="resolution" className="form-label">Resolution</label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                        {saveInvoiceForm ? (
                                            <p>{invoiceDetails.resolution}</p>
                                        ) : (
                                                <ExpandableTextarea id="resolution" maxChars={250} textValue={invoiceDetails.resolution} onTextValueChange={resolutionChange} />
                                        )}
                                        <div className={styles.errorPadding}>{resolutionValidator}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <LaborTable eventTypeId={eventTypeId} />
                    <PartsTable eventTypeId={eventTypeId} />
                    <MileageTable />
                    <TaxTable />
                    <EventTotalsRow req={requested} auth={authorized} />

                    {invoiceZeroError &&  <div className="validationSummary mt-2">Invoice total must be greater than $0.</div>}
                                        
                    <Row className="twoColWrapper mt-4">
                        <Col md="5" >
                            <FormGroup className="mb-0">
                                <label htmlFor="addDetails" className="form-label">Additional Details About Requested Rates</label>
                                {saveInvoiceForm ? (
                                    <p>{invoiceDetails.AdditionalRates}</p>
                                ) : (
                                        <ExpandableTextarea id="addDetails" maxChars={250} textValue={invoiceDetails.AdditionalRates} onTextValueChange={additionalRates} placeholder="Enter Description" />
                                )}
                            </FormGroup>
                        </Col>
                        <Col md="5" />
                    </Row>
                    </CardBody>
            </Card>
            <div className="ml-2">
                <div className={styles.submitInvoice }>
                    <button className="btnLink mr-2" onClick={() => setCancelAuthModal(true)}>Cancel</button>
                    <SpinnerButton
                        styleType="primary"
                        spinning={actionInProgress}
                        disabled={actionInProgress}
                        text={saveInvoiceForm ? "Submit" : "Continue"}
                        onClick={handleClick}
                    />
                   
                </div>
            </div>
            <CancelEntry
                toggle={() => setCancelAuthModal(prev => !prev)}
                isOpen={cancelAuthModal}
                centered={true} cancelNavigation={handleClose}
                className="modal-md"
            />
           
        </Container>
    )
}
export default InvoiceAdjudicationDisplay;