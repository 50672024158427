import React, { useState } from "react";
import { useClaimInvoiceStore } from "../lib/state";
import styles from "./Formatters.module.css";

export const BasicInput = ({ cell, row, rowIndex, fieldName, tableType }) => {
    const [value, setValue] = useState(cell || "");
    const state = useClaimInvoiceStore((state) => state);
    const invoiceData = Object.entries(state)?.filter(e => e[0].includes('invoice'));
    const invoiceId = invoiceData?.[0]?.[0];

    const handleInput = (e) => {
        setValue(e.target.value);
    };


    const acceptChanges = (e) => {
        if (tableType === 'parts') {
            state.updatePartsRow(invoiceId, tableType, rowIndex, { [fieldName]: value });
        } else if (tableType?.toLowerCase() === 'partsmarkup') {
            state.updatePartsMarkupRow(invoiceId, tableType, rowIndex, { [fieldName]: value ?? '' });
        } else {
            state.updateRow(invoiceId, tableType, rowIndex, { [fieldName]: value });
        }
    };

    return (
        <input
            name={fieldName}
            required={false}
            type="text"
            className={styles.tableInput}
            value={value}
            onChange={handleInput}
            onBlur={acceptChanges}
        />
    );
};
