const verifyDealerAccess = async () => {
    const res = await fetch(`/authentication/verify`);

    if (res.status === 200) {
        const authModel = await res.json();
        return authModel;
    }
    else {
        return null;
    }
}

const getLocalDealer = async () => {
    var dealerId = new URLSearchParams(window.location.search)
        .get('dealerid');
    if (dealerId != null && !isNaN(dealerId)) {
        const res = await fetch(`/authentication/verify/${dealerId}`);

        if (res.status === 200) {
            const authModel = await res.json();
            return authModel;
        }
    }

    return null;
}

const getDealerProfile = async (dealerId) => {
    const res = await fetch(`/dealer/getdealerprofile/${dealerId}`);

    if (res.status === 200) {
        const profile = await res.json();
        return profile;
    }
    else {
        return null;
    }
}

const getClient = async () => {
    const res = await fetch('helper/getclient');

    if (res.status === 200) {
        const client = await res.json();
        return client.accountName;
    }
    else {
        return null;
    }
}

const verifyToken = async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('auth');
    const target = params.get('page');
    var filter = params.get('filter');

    if(token == null || token === '' || target == null || target === '')
        return null;

    filter = filter ?? "";

    const res = await fetch(`authentication/ValidateDealerByToken/${token}`);

    if (res.status === 200) {
        return ({
            loginSuccessful: true,
            targetTab: target,
            filter: filter,
        });
    }
    else {
        return null;
    }
}

export { verifyDealerAccess, getLocalDealer, getDealerProfile, getClient, verifyToken }