import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import classNames from "classnames";
import styles from "../Sales/PaymentInformation/PaymentMethod.module.css";

export const DeleteDocument = ({ isOpen, toggle, centered, handleDelete, selectedDocRow }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="deleteCard"
            centered={centered}
        >
            <div className={styles.deleteCardHeader}>
                <h4>Delete Document</h4>
            </div>

            <ModalBody>
                <p>Are you sure you want to delete the {selectedDocRow.documentName} document?</p>
            </ModalBody>

            <ModalFooter>
                <button className={classNames("btnLink", styles.customBtnLink)} onClick={toggle}>
                    Close
                </button>

                <button className="btn-primary" onClick={handleDelete}>Delete</button>
            </ModalFooter>
        </Modal>
    );
};
