import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Row, Container, Col, Label, Input, FormGroup } from "reactstrap";
import classNames from "classnames";
import styles from "./Login.module.css";
import { ReactComponent as CheckIcon } from '../assets/images/Check_icon.svg';
import SimpleReactValidator from 'simple-react-validator';
import { Route, Redirect } from "react-router-dom";
import { SessionContext } from '../context/SessionContext';

let loginContainer = styles.loginContainer;

const ConfirmPassword = (props) => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
    const [validationCheck, setValidationCheck] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [passLengthCheck, setPassLengthCheck] = useState(false);
    const [passNumberCheck, setPassNumberCheck] = useState(false);
    const [passCharCheck, setPassCharCheck] = useState(false);
    const [passCaseCheck, setPassCaseCheck] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [, forceUpdate] = useState();
    var sessionContext = useContext(SessionContext);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        required: ':attribute is required.',
        in: 'Passwords do not match.',
        min: 'Password length should be minimum 8 characters'
      },
      element: (message) => (
        <div
          className="errorMsg"
          aria-live="polite"
        >
          {message.charAt(0).toUpperCase() + message.slice(1)}
        </div>
      ),
      validators: {
        valid_pass: {
          message: 'The :attribute is invalid.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/) && params.indexOf(val) === -1
          },
          required: true
        }
      }
    })
  );

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === "password") {
      setPassLengthCheck((value.length >= 8));
      setPassNumberCheck(((/\d/).test(value)));
      setPassCharCheck(((/\W/).test(value)));
      setPassCaseCheck(((/^(?=.*[a-z])/).test(value) && (/^(?=.*[A-Z])/).test(value)));
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

const handleResetPass = async (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
        setValidationCheck(false);

        const dealerId = props.location.state.dealerId;
        const token = props.location.state.code;
        let request = {
            dealerId: dealerId,
            password: formData.password,
            token: token
        }

        let route = `dealer/UpdateUserCredential`;
        fetch(route, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request)

        }).then(async response => {
            if (response.status === 200) {
                var ret = await authenticateUser(dealerId, formData.password, false);
                if (ret == false) {
                    // Login failed
                    setErrorMessage("Something went wrong");
                    sessionContext.setIsResetPasswordSuccess(false);
                } else {
                    sessionContext.setIsResetPasswordSuccess(true);
                    sessionContext.updateConfirmPassLink(false);
                    var sendEmail =  await sendChangePasswordEmail();
                    
                    if (sendEmail == true) {
                        setSendEmail(true);
                    }
                    window.location.replace(window.location.origin);
                    setErrorMessage("");
                }

            } else {
                sessionContext.setIsResetPasswordSuccess(false);
                setErrorMessage("Something went wrong");
            }
        }).catch(function () {
            sessionContext.setIsResetPasswordSuccess(false);
            console.error("error from UpdateDealerPassword" );
            setErrorMessage("Something went wrong");
        });
    } else {
      setValidationCheck(true);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
    };
    const sendChangePasswordEmail = async () => {
        const dealerId = props.location.state.dealerId;
        let route = "dealer/ChangePasswordEmail/" + dealerId;
        await fetch(route, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }

        }).then(response => {
            if (response.status === 200) {
                return true;
            } else if (response.status === 404) {
                return false;
            }
        });
    }

    const authenticateUser = async (dealerid, password, rememberMe) => {

        var model = {
            "DealerId": dealerid,
            "Password": password,
            "RememberMe": rememberMe
        }

        var response = await fetch("authentication/authenticateuser/", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        });

        if (response.ok) {
            const result = await response.json();
            if (result.succeeded == true) {
                return true;
            }

        }
        return false;
    }

  if(!sessionContext.confirmPassClick) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={styles.vendorForgotPass}>
      <Container className={styles.mainContainer}>
        <Col md="12" lg="12">
          <Col md="12" className={classNames(loginContainer, "p-0", "pb-3")}>
            <h1 className={('h3', styles.createNewPassHeader)}>Create New Password</h1>
            <p className={styles.infoText}>Enter a new password using the tips below.</p>
          </Col>
          <Col lg="4" md="5" className={classNames("p-0", "pb-3")}>
            <FormGroup className={!simpleValidator.current.check(formData.password, "required|min:8|valid_pass") && validationCheck && "form-error"}>
              <label for="password" className="form-label">New Password</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <Input name="password" type="password" className="form-control" defaultValue={formData.password} onChange={handleInputChange} />
              {simpleValidator.current.message("password", formData.password, "required|min:8|valid_pass")}
            </FormGroup>
            <FormGroup className={!simpleValidator.current.check(formData.confirmPassword, `required|in:${formData.password}`) && validationCheck && "form-error"}>
              <label for="confirmPassword" className="form-label">Confirm New Password</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <Input name="confirmPassword" type="password" className="form-control" defaultValue={formData.confirmPassword} onChange={handleInputChange} />
              {simpleValidator.current.message("confirmPassword", formData.confirmPassword, `required|in:${formData.password}`)}
            </FormGroup>
            <FormGroup>
              <button
                className={classNames('btn-primary')}
                onClick={handleResetPass}
                disabled={!(passLengthCheck && passNumberCheck && passCharCheck && passCaseCheck && formData.confirmPassword.length > 0)}
              >
                RESET PASSWORD
              </button>
                      </FormGroup>
                      <div class="errorMsg" aria-live="polite">{errorMessage}</div>
          </Col>
          <Col md="4" className={classNames("p-0", "pb-3")}>
            <div className={styles.confirmPassHeadText}>Password Requirements</div>
            <div className={classNames("mb-1", styles.confirmPassCheckContainer)}>
              <div className={passLengthCheck?styles.confirmPassCheck : styles.checkText}>{passLengthCheck && <CheckIcon />}</div>
              <div>At least 8 characters long</div>
            </div>
            <div className={classNames("mb-1", styles.confirmPassCheckContainer)}>
              <div className={passNumberCheck?styles.confirmPassCheck : styles.checkText}>{passNumberCheck && <CheckIcon />}</div>
              <div>Include one number</div>
            </div>
            <div className={classNames("mb-1", styles.confirmPassCheckContainer)}>
              <div className={passCharCheck?styles.confirmPassCheck : styles.checkText}>{passCharCheck && <CheckIcon />}</div>
              <div>Include one special character</div>
            </div>
            <div className={classNames("mb-1", styles.confirmPassCheckContainer)}>
              <div className={passCaseCheck?styles.confirmPassCheck : styles.checkText}>{passCaseCheck && <CheckIcon />}</div>
              <div>One upper and one lower case character</div>
            </div>
          </Col>
        </Col>
      </Container>
    </div>
  );
};

export { ConfirmPassword };