import React from 'react';
import Cleave from 'cleave.js/react';
import { FormGroup } from 'reactstrap';

const ZipFormat = ({
    label,
    name,
    handleInputChange,
    value,
    country,
    validator,
    required = true }) => {
    const size = country === 1? 5 : 6;
    const validation = country === 1 ? `required|size:${size}` : `required|alpha_num_space|size:${size}`;
    
    return <FormGroup
        className={validator.message(name, value, validation) ? 'form-error' : ''}>
        <label className="form-label" for="companyPhone">
            {label}
        </label>
        {required && <span aria-hidden="true" className="required-indicator">*</span>}
        {country !== 2 && (
            <Cleave
                placeholder=""
                name={name}
                className="form-control"
                id={name}
                options={{
                    numericOnly: true,
                    blocks: [5],
                }}
                value={value}
                onChange={handleInputChange}
            />)}
        {country === 2 && (
            <Cleave
                placeholder=""
                name={name}
                className="form-control"
                id={name}
                options={{
                    blocks: [6],
                }}
                value={value}
                onChange={handleInputChange}
            />)}
        {validator.message(name, value, validation, { messages: { required: `Zip/Postal is required.`, size: 'Please enter a valid Zip/Postal.', alpha_num_space: 'Zip/Postal may only contain letters, numbers and spaces.' } })}
    </FormGroup>
};

export default ZipFormat;