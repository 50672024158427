import React from "react";
import styles from './KeySellingPoints.module.css';
import BootstrapTable from "react-bootstrap-table-next";
import classnames from 'classnames';
import { ReactComponent as HvacIcon } from '../../../../assets/images/thermometer.svg';
import { ReactComponent as WasherIcon } from '../../../../assets/images/washer.svg';
import { ReactComponent as LaptopIcon } from '../../../../assets/images/laptop.svg';
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent'

import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";

const valuePropColumn = [
  {
    dataField: "product",
    text: "Product",
  },
  {
    dataField: "threeYrCoverage",
    text: "3-Year Coverage",
  },
  {
    dataField: "avgProdCost",
    text: "Average Product Cost",
  },
  {
    dataField: "valProposition",
    text: "Value Proposition",
  },
  {
    dataField: "industryRate",
    text: "% Industry Attach Rate",
  }
];

const valuePropData = [
  {
    product: (<div className={styles.prodIcon}><LaptopIcon /></div>),
    threeYrCoverage: "$400",
    avgProdCost: "$1,000",
    valProposition: "40%",
    industryRate: "30-35%"
  },
  {
    product: (<div className={styles.prodIcon}><WasherIcon /></div>),
    threeYrCoverage: "$200",
    avgProdCost: "$800",
    valProposition: "25%",
    industryRate: "20-30%"
  },
  {
    product: (<div className={styles.prodIcon}><HvacIcon /></div>),
    threeYrCoverage: "$150*",
    avgProdCost: "$8,400*",
    valProposition: "2%",
    industryRate: "<10%"
  },
];

const KeySellingPoints = ({ onClick }) => {
  const context = useContext(SessionContext)
  const { accountName } = context
  const accountNameToLower = accountName.toLowerCase();

  return (
    <>
      <h1 className="h5">Key Selling Points</h1>
      <ol className={styles.customCounter}>
        <li>
          <h2 className="h6">Peace of Mind</h2>
          <p>Your customers receive comprehensive coverage that includes additional coverage beyond the manufacturer's warranty.</p>
        </li>
        <li>
          <h2 className="h6">Reliable Parts and Service</h2>
          <p>Reliable service performed by you, their installing dealer.</p>
        </li>
        <li>
          <h2 className="h6">Valuable Savings</h2>
          <p>{getBrandContent(accountName).programName} coverage helps protect against unforeseen costs associated with service and repair. Cost of the plan is generally less expensive than one repair.</p>
        </li>
        <li>
          <h2 className="h6">Fully Transferable</h2>
          <p>Our plans are fully transferable, free of charge, to a new homeowner.</p>
        </li>
        <li>
          <h2 className="h6">No Hassle Cancellation</h2>
          <p>You can cancel a plan at any time (full refund if canceled in the first {accountNameToLower !== "lennox" ? 30 : 60} days).</p>
        </li>
      </ol>
      <h2 className="h5">Value Proposition</h2>
      <div className="scriptBox mt-0" >
        <div className="msg">While HVAC equipment costs significantly more than appliances or other consumer products, the attachment rate of warranties is the lowest.
          <b> Consumers want, and are willing to get, coverage - make sure it is offered!</b>
        </div>
      </div>
      <div className={styles.valuePropTable}>
        <BootstrapTable
          id="valuePropositionTable"
          wrapperClasses="table-responsive"
          keyField="product"
          data={valuePropData}
          columns={valuePropColumn}
          bordered={true}
        />
      </div>
      <p className={styles.note}>
        <span>*Based on approximate retail costs. See </span>
        <a
          className={classnames(styles.redirectLink)}
          onClick={onClick}>
          program pricing
          </a>
        <span> for dealer costs.</span>
      </p>
    </>
  )
}

export default KeySellingPoints