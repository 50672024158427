import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { DropDownAutoComplete } from '../../inputs/DropDownAutoComplete';
import SimpleReactValidator from 'simple-react-validator';
import ZipFormat from "./../../VendorCreation/zipFormat";

const AddressFieldRow = forwardRef((props, ref) => {
    const {
        stateOptions,
        onOptionChange,
        onInputChange,
        addressType,
        addressLine1,
        addressLine2,
        city,
        stateProvince,
        zipPostal,
        countryId,
        showContactValidationErrors,
        checkEnable = false,
        checkOnchange,
        checkBoxName,
        prefix = true
    } = props;
    let states = stateOptions;
    const namePrefix = prefix && addressType !== "Physical" ? addressType.charAt(0).toLowerCase() : ""
    const validator = useRef(new SimpleReactValidator({
        element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
        messages: {
            required: ':attribute is required.'
        },
    }));
    const [, setForceUpdate] = useState(false)
     
    useEffect(() => {
        if (showContactValidationErrors && !validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(true);
        }
    }, [addressLine1, city, stateProvince, zipPostal]);
    

    useImperativeHandle(ref, () => ({
        validate() {
            if (validator.current.allValid())
                return true;
            else {
                validator.current.showMessages();
                setForceUpdate(true);
                return false;
            }
        }
    }));

    return (
        <Row className="multiColWrapper">
            <Col sm="12" md="4" lg="3" className="col-5ths">
                <FormGroup className={!validator.current.check(addressLine1, "required") && showContactValidationErrors ? "form-error" : ""}>
                    <label for="address" className="form-label">{addressType} Address</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    {checkEnable && (
                        <div className="checkbox" id={`${checkBoxName}Id`}>
                            <input
                                className="mr-2"
                                type="checkbox"
                                id={`${checkBoxName}Check`}
                                name={checkBoxName}
                                onChange={checkOnchange}
                            />
                            <label for={`${checkBoxName}Check`} className="checkboxLabel">
                                Same as Physical Address
                            </label>
                        </div>
                    )}
                    <>
                        <input
                            type="text"
                            name={`${namePrefix}Address`}
                            id="address"
                            aria-required="true"
                            className="form-control"
                            value={addressLine1}
                            onChange={onInputChange} />
                        {validator.current.message(`${addressType.toLowerCase()}Address`, addressLine1, "required")}
                    </>
                </FormGroup>
            </Col>
            <Col sm="12" md="4" lg="3" className="col-5ths">
                <FormGroup>
                    <label for="aptNum" className="form-label">Apt., Suite, Building</label>
                    <input
                        type="text"
                        name={`${namePrefix}aptNum`}
                        id="aptNum"
                        aria-required="true"
                        className="form-control"
                        value={addressLine2}
                        onChange={onInputChange} />
                </FormGroup>
            </Col>
            <Col sm="12" md="4" lg="3" className="col-5ths">
                <FormGroup className={!validator.current.check(city, "required") && showContactValidationErrors ? "form-error" : ""}>
                    <label for="city" className="form-label">City</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <input
                        type="text"
                        name={`${namePrefix}city`}
                        id="city"
                        aria-required="true"
                        className="form-control"
                        value={city}
                        onChange={onInputChange} />
                    {validator.current.message('city', city, "required")}
                </FormGroup>
            </Col>
            <Col sm="12" md="4" lg="3" className="col-5ths">
                <FormGroup className={!validator.current.check(stateProvince, "required") && showContactValidationErrors ? "form-error" : ""}>
                    <label for="state" className="form-label">State/Province</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <DropDownAutoComplete
                        name={`${namePrefix}State`}
                        className="form-control"
                        placeholder="Please select..."
                        aria-required="true"
                        id="state"
                        options={states}
                        value={stateProvince}
                        defaultValue={stateProvince}
                        onChangedValue={onOptionChange}
                    />
                    {validator.current.message('state', stateProvince, "required", { messages: { required: "State/Province is required." } })}
                </FormGroup>
            </Col>
            <Col sm="12" md="4" lg="3" className="col-5ths">
                <ZipFormat
                    name={`${namePrefix}zip`}
                    country={countryId || 1}
                    validator={validator.current}
                    label="Zip/Postal"
                    value={zipPostal}
                    handleInputChange={onInputChange} />
            </Col>
        </Row>
    )
})

export default AddressFieldRow;