import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Container, Alert, Card, CardHeader, CardBody, Button, Table } from 'reactstrap';
import { ReactComponent as AIGLogo } from '../../assets/images/Vendor/AIG/aig-logo.svg';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import NumberFormat from 'react-number-format';
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { ContractInfo } from './ContractInfo';
import styles from '../Nav/NavHeader.module.css';
import update from 'immutability-helper';
import classNames from 'classnames';
import { SessionContext } from '../../context/SessionContext';
import PageLoading from '../shared/PageLoading/PageLoading';
import { dataLayerPush } from '../../components/Global/helpers'
import { getCoCDoc, createSCCURequest } from '../../components/Global/DocumentHelper';
import moment from 'moment';
import { ContractStatusId } from '../Global/ContractStatusId';
import AIGSimpleTable from '../shared/SimpleTable/SimpleTable';
import AddressDisplay from '../shared/AddressFieldRow/AddressDisplay';
import { incidentRecords, repairHistoryRecords } from '../Report/SampleData';
import LennoxRepairMatrix from '../../assets/Documents/WYW_CS_Repair Times Matrix.pdf';
import NonLennoxRepairMatrix from '../../assets/Documents/Claim Repair Codes Apr 2013 Update New AIG Blue_V2.pdf';
import SnackBarAlert from '../shared/SnackBar/SnackBarAlert';
import { Spinner } from 'react-bootstrap';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const ServiceRequest = (props) => {
    const context = useContext(SessionContext);
    const { accountName, getCurrentTabAndSubtab } = context;
    const { tab } = getCurrentTabAndSubtab();
    const accountNameLower = accountName.toLowerCase();
    const initialState = props.state === undefined ? {} : props.state;
    const [state, setState] = useState({
        showInfoModal: false,
        eventId: props?.eventData?.eventId,
        clientId: props?.client !== undefined ? props?.client : null,
        eventNumber: props?.rfsFormData?.eventNumber,
        showSpinner: true,
        totalAmount: 300,
        totalPreauthorizedAmount: 0,
        showNonCoveredRepairsModal: false,
        showAlert: false,
        actions: false,
        rejectedReason: [],
        rejectedNotes: '',
        showReject: false,
        hideButton: false,
        isPageRetricted: false,
        isValidationFired: false
    });
    const [incidentColumn, setIncidentColumn] = useState(null);
    const [incidentData, setIncidentData] = useState(null);
    const [historyColumn, setHistoryColumn] = useState(null);
    const [historyData, setHistoryData] = useState(null);
    const [isUSA, setIsUSA] = useState(true);
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
    const [disableAcceptBtn, setDisableAcceptBtn] = useState(false);
    const [hideAcceptActionBtn, setHideAcceptActionBtn] = useState(false);
    const [acceptActionInProgress, setAcceptActionInProgress] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const profileModel = context.getProfile();

    const docName = () => {
        switch (accountNameLower) {
            case "lennox":
                return LennoxRepairMatrix
            case "carrier":
                return NonLennoxRepairMatrix
            case "rheem":
                return NonLennoxRepairMatrix
            default:
                // console.log('Developer Error: No PDF document found for accountName:', accountName)
                return null
        }
    };

    const incidentColumns = [
        {
            dataField: "product",
            text: "Product",
            sort: false
        },
        {
            dataField: "brand",
            text: "Brand",
            sort: false
        },
        {
            dataField: "model",
            text: "Model",
            sort: false
        },
        {
            dataField: "serial",
            text: "Serial",
            sort: false
        },
        {
            dataField: "installationDate",
            text: "Installation Date",
            formatter: dateFormatter,
            sort: false
        },
        {
            dataField: "planEndDate",
            text: "Plan End Date",
            formatter: dateFormatter,
            sort: false
        },
    ];

    const repairHistoryColumns = [
        {
            dataField: "historyDate",
            formatter: dateFormatter,
            sort: false
        },
        {
            dataField: "historyEvent",
            sort: false
        },
    ];

    const authorizeTable = [
        {
            'label': 'Labor Reimbursement',
            'value': props.laborRate
        },
        {
            'label': 'Trip/Diag',
            'value': props.tripRate
        }
    ]

    const title1 = "Assigned To";
    const address1 = [
        props?.servicerDetails?.partyName,
        props?.servicerDetails?.partyAddressModel[0]?.address1 + ', ' + props?.servicerDetails?.partyAddressModel[0]?.state + ' ' + props?.servicerDetails?.partyAddressModel[0]?.zip,
        props?.servicerDetails?.partyPhoneModel?.filter(pm => pm.phoneTypeId === 3)[0].number,
        props?.servicerDetails?.partyEmailModel?.length == 0 ? "" : props?.servicerDetails?.partyEmailModel[0]?.address
    ];

    const title2 = "Customer Information";
    const address2 = [
        props?.customerInfo !== null ? props?.customerInfo?.firstName + ' ' + props?.customerInfo?.lastName : "",
        props?.customerInfo !== null ? props?.customerInfo?.billingAddress?.address1 + ', ' + props?.customerInfo?.billingAddress?.city + ', ' + props?.customerInfo?.billingAddress?.state + ' ' + props?.customerInfo?.billingAddress?.zip : "",
        props?.customerInfo !== null ? props?.customerInfo?.partyPhoneModel?.filter(pm => pm.phoneTypeId === 2)[0]?.number + " (Mobile)" : "",
        props?.customerInfo !== null ? props?.customerInfo?.partyPhoneModel?.filter(hp => hp.phoneTypeId === 1)[0]?.number + " (Alternate)" : "",
        (props?.contract?.contractNumber !== null && profileModel.dealerId === props?.contract?.dealerId) ? "Contract Number: " + props?.contract?.contractNumber : ""
    ];

    const title3 = props?.rfsFormData != null ? "Incident Date: " + moment(props?.rfsFormData?.lossDate).format('MM/DD/YYYY') : "Incident Date: " + '';
    const address3 = [
        props.rfsFormData != null ? "Priority Service: Yes" : "Priority Service: No",
        props.rfsFormData != null ? props?.rfsFormData.symptom : "",
        props.rfsFormData != null ? "Problem Description: " + props?.rfsFormData?.incidentDescription : "Problem Description: "
    ]

    const usaDateFormat = { string: "MM/DD/YYYY", pattern: ['m', 'd', 'Y'] };
    const foreignDateFormat = { string: "DD/MM/YYYY", pattern: ['d', 'm', 'Y'] };
    const dateFormat = isUSA ? usaDateFormat : foreignDateFormat;

    const dateFormatter = (cell, row) => {
        if (!cell) return "";
        if (!moment(cell, dateFormat, true).isValid())
            return "";
        return cell;
    };

    const closeServiceRequestTab = (serviceRequestNumber) => {
        console.log("serviceRequestNumber ===>>> ", serviceRequestNumber);
    }

    const handleApprove = async () => {
        setAcceptActionInProgress(true);
        let model = {
            "EventId": parseInt(props?.eventData?.eventId),
            "ServicerName": props?.rfsFormData?.servicerName,
            "IsAccepted": true,
            "ClaimNumber": props?.rfsFormData?.claimNumber,
            "RejectedReason": props?.rfsFormData?.rejectedReason,
            "RejectedDescription": props?.rfsFormData?.rejectedDescription
        }
        let response = await fetch('queue/rfsacceptorreject/' + state.clientId, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        });

        setAcceptActionInProgress(false);
        if (response.ok) {
            const result = await response.json();
            if (result?.eventStatusId == 13) {
                setState({ ...state, showAlert: true, showReject: false, isValidationFired: false });
                setDisableSubmitBtn(false);
                setHideAcceptActionBtn(true)
                sessionStorage.setItem('eventDetails', true)

            }
            else if (result?.eventStatusId == 14) {
                setState({ ...state, showAlert: false, actions: false, showReject: false, isValidationFired: false });
                props.history.push('/authconfirmation', { rejected: true, eventNumber: this.state.eventNumber });
            }
            else
                setState({ ...state, showAlert: false, actions: false, isValidationFired: false });
        }
    }

    const handleReject = () => {
        console.log("handleReject ===>>> ");
    }

    const handleAuthorization = () => {
        console.log("handleAuthorization ===>>> ");
    }

    const handleSubmitInvoice = () => {
        props.history.push('/submitinvoice', {
            eventNumber: props?.rfsFormData?.eventNumber,
            eventId: state?.eventId,
            clientId: state?.clientId,
            modelNumber: props?.rfsFormData?.modelNumber,
            serialNumber: props?.rfsFormData?.serialNumber,
            brandName: props?.rfsFormData?.brand,
            servicerPhone: props?.rfsFormData?.servicerPhone,
            preauthorizedAmount: state?.totalPreauthorizedAmount,
            rfsData: props?.rfsFormData
        });
    }

    const handleRepaireDetaile = () => {
        console.log("handleRepaireDetaile ===>>> ");
        window.open(docName());
    }

    const programName = "Lennox";

    const getIncidentData = async () => {
        try {
            setIncidentColumn(incidentColumns);
            let contractAssetsData = [];

            if(props?.contract !== null) {
                var contractAssets = (props?.contract !== null ) ? props?.contract?.contractAssetModel : "";

                contractAssetsData = contractAssets?.map((asset) => {
                    return {
                        product: getAssetName(asset?.assetId),
                        brand: getBrandName(asset?.brandId),
                        model: asset?.modelNumber,
                        serial: asset?.serialNumber,
                        installationDate: (asset == null || asset.length === 0 || asset?.assetPurchaseDate == null) ? '01/01/0000' : moment(asset?.assetPurchaseDate).local().format(props?.contract?.country == "US" ? "L" : "DD/MM/YYYY"),
                        planEndDate: moment(props?.contract?.planExpirationDate).local().format(props?.contract?.country == "US" ? "L" : "DD/MM/YYYY")
                    };
                });
            } else {
                contractAssetsData = [{
                    product: "",
                    brand: "",
                    model: "",
                    serial: "",
                    installationDate: "",
                    planEndDate: ""
                }];
            }
            setIncidentData(contractAssetsData);
        } catch(error) {
            console.error("getIncidentData -> error ===>>> ", error);
            setErrorMessage(error)
        }
        
    }

    const repairHistoryData = async () => {
        try {
            setHistoryColumn(repairHistoryColumns);
            var repairEvents = props?.rfsFormData?.repairEvents;
            var repairEventsList = [];

            if (props?.rfsFormData?.eventStatusId === 13) {
                setDisableSubmitBtn(false);
                setHideAcceptActionBtn(true);
            }

            if (repairEvents && repairEvents.length > 0) {
                repairEventsList = repairEvents.map((repair) => {
                    return {
                        historyDate: repair.createdDate ? moment(repair.createdDate).format('MM/DD/YYYY') : '',
                        historyEvent: 'Event #' + repair.eventNumber
                    };
                });

                setHistoryData(repairEventsList);
            }
        } catch (error) {
            setErrorMessage(errorMessage + " " + error);
        }        
    }

    const getBrandName = (brandId) => {
        var brand = (props?.contract !== null) ? props?.contract?.brands.filter((b) => b?.brandId === brandId) : "";
        return typeof brand === "undefined" ? "" : brand[0]?.name;
    };

    const getAssetName = (assetId) => {
        var asset = (props?.contract !== null) ? props?.contract?.assets.filter((a) => a?.assetId === assetId) : "";
        return typeof asset === "undefined" ? "" : asset[0].assetName;
    };

    useEffect(() => {
        getIncidentData();
        repairHistoryData();

        // Commented as dev need to work in future
        // if (props.eventData != 'undefined') {

        // }
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem('eventDetails')) {
            restoreSessionData();
        }
    }, [tab?.tabId])

    const restoreSessionData = () => {
        const tabDetails = sessionStorage.getItem('eventDetails');
        setDisableSubmitBtn(!tabDetails);
        setHideAcceptActionBtn(tabDetails);
    }
    return (
        <>
            <section className="col mb-5">
                <div className={styles['tab-content']} id="secondary-tabContent">
                    <div className={classNames("tab-pane fade show active")} id="sec-contract-panel" role="tabpanel" aria-labelledby="sec-contract-tab">
                        <div className="tab-content" id="contract-tabContent">
                            <div className={classNames("tab-pane fade show active")} id="cn-details-panel" role="tabpanel" aria-labelledby="cn - details - tab">
                                <Container className="contentWrapper">
                                    <Card>
                                        <CardHeader className={styles.serviceRequestHeader}>
                                            <Row className={styles.serviceRequestHeaderRow}>
                                                <Col md="7" className={classNames("mr-0", styles.serviceRequestHeaderCol)}>
                                                    <span className="mr-4">
                                                        <AIGLogo className={styles.aigLogo} />
                                                    </span>
                                                    <span className={styles.serviceHeaderMiddle}>
                                                        <h2>Service Request Details</h2>
                                                        <p>Client: {accountName}</p>
                                                    </span>
                                                </Col>
                                                <Col md="5" className=" text-right">
                                                    <ul className={classNames("address", styles.serviceHeaderRight)}>
                                                        <li>Event #{props.eventData === null ? '' : props?.eventData?.eventNumber}</li>
                                                        <li>{props.rfsFormData === null ? '' : props?.rfsFormData?.claimSetupDate ? moment(props?.rfsFormData?.claimSetupDate).format('MM/DD/YYYY') : ''}</li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md="9" className={styles.serviceRequestSeparator}>
                                                    <Row>
                                                        <Col md="4">
                                                            <AddressDisplay
                                                                title={title1}
                                                                items={address1}
                                                                titleClass={styles.subHeading}
                                                                childclass={"address mb-0"}
                                                            />
                                                        </Col>
                                                        <Col md="8">
                                                            <AddressDisplay
                                                                title={title2}
                                                                items={address2}
                                                                titleClass={styles.subHeading}
                                                                childclass={"address mb-0"}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col>
                                                            <AddressDisplay
                                                                title={title3}
                                                                items={address3}
                                                                titleClass={styles.subHeading}
                                                                childclass={"address mb-0"}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-3">
                                                        <Col>
                                                            {incidentData !== null &&
                                                                <BootstrapTable
                                                                    bootstrap4
                                                                    keyField="product"
                                                                    data={incidentData}
                                                                    columns={incidentColumn}
                                                                    bordered={false}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3 mb-0">
                                                        <Col md="5" className={styles.serviceRequestTable}>
                                                            <h6 className={styles.conditionNumber}>Repair History</h6>
                                                            {historyData !== null &&
                                                                <BootstrapTable
                                                                    bootstrap4
                                                                    condensed
                                                                    wrapperClasses={classNames("table-responsive", "mb-0", styles.tableAlignment)}
                                                                    keyField="historyEvent"
                                                                    data={historyData}
                                                                    columns={historyColumn}
                                                                    bordered={false}
                                                                />
                                                            }
                                                        </Col>
                                                        {!hideAcceptActionBtn &&
                                                            <Col md="7" className="text-right">
                                                                <button
                                                                    className={classNames("btn", "btn-secondary", styles.btnServiceReject)}
                                                                    onClick={handleReject}
                                                                >
                                                                    Reject
                                                                </button>
                                                                <button
                                                                    className={classNames("btn", "btn-primary", styles.btnServiceAccept)}
                                                                    onClick={handleApprove}
                                                                    disabled={acceptActionInProgress}
                                                                >
                                                                    <Spinner color="light" size="sm" animation={acceptActionInProgress ? "border" : ""} />
                                                                    {acceptActionInProgress ? "" : "Accept"}
                                                                </button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md="3" className={classNames("text-center", styles.serviceRequestTable, styles.authorizeTable)}>
                                                    <h6>Pre-Authorized Rates</h6>
                                                    <AIGSimpleTable tableRows={authorizeTable} />

                                                    <button className={classNames("btn btn-secondary my-2", styles.serviceRequestSideButton)} disabled={disableSubmitBtn} onClick={handleSubmitInvoice}>Submit Claim Details</button>
                                                    <button className={classNames("btn btn-secondary my-2", styles.serviceRequestSideButton)} onClick={handleRepaireDetaile}>Repair Details</button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-5 mb-3">
                                                <Col>
                                                    <ul className="address mb-0">
                                                        <li className={classNames("mb-2", styles.subHeading)}>Conditions</li>
                                                        <li>
                                                            <span className={styles.conditionNumber}>(1)</span> This Service Request validates and confirms that the consumer is requesting service on behalf of the Program Client.
                                                        </li>
                                                        <li>
                                                            <span className={styles.conditionNumber}>(2)</span> Follow the below expectations when contacting AIG customers:
                                                            <ul className="address">
                                                                <li className={classNames("ml-3", styles.serviceRequestList)}>
                                                                    <span className={styles.conditionNumber}>a.</span> If an appointment has not been scheduled and you receive this Service Request, please call the customer within 24 hours to schedule an appointment.
                                                                </li>
                                                                <li className="ml-3">
                                                                    <span className={styles.conditionNumber}>b.</span> If priority service is required, please contact the customer within 1 hour to schedule an appointment.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <span className={styles.conditionNumber}>(3)</span> As our appointed Servicer, we ask that you act on our behalf to eliminate claims of abuse, neglect, acts of God, etc.
                                                        </li>
                                                        <li>
                                                            <span className={styles.conditionNumber}>(4)</span> If you have any questions on coverage, please call our Warranty Claims Department at (866) 523-8649 for assistance.
                                                        </li>
                                                        <li>
                                                            <span className={styles.conditionNumber}>(5)</span> By acceptance of this Service Request, you as a Servicer agree to keep all personal data stated on this service request secured and protected from unauthorized or accidental access, processing, erasure and all other uses. Information transferred to any other company must have prior AIG approval.
                                                        </li>
                                                        <li>
                                                            <span className={styles.conditionNumber}>(6)</span> By acceptance of this Service Request, you are agreeing that all service technicians within your organization have been subject to proper background screening and that your organization has a valid EIN number that can be provided to AIG.
                                                        </li>
                                                        <li>
                                                            <span className={styles.conditionNumber}>(7)</span> Repairs exceeding the Total Repair Limit, where no authorization is obtained, can result in non-payment of repair.
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <SnackBarAlert
                content="Service Request accepted successfully."
                onClose={() => setState({ ...state, showAlert: false })}
                showSnackBar={state.showAlert}
            />
        </>
    );
}

export default ServiceRequest;