import React, { Component } from "react";
import {
    Container, Card, CardBody,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import styles from "./Confirmation.module.css";
import NumberFormat from "react-number-format";
import BottomButtons from "../../shared/BottomButtonWrapper/BottomButtons";
import { SessionContext } from "../../../context/SessionContext";
import BrandedIcon from '../../../components/BrandingWhiteLabel/BrandedIcon'
import moment from "moment";
import PrintButton from "../../shared/IconButtons/PrintButton";
import classNames from "classnames";
import { SendMail, SendMailByAccount, emailModel, serviceModel, getPrimaryEmail, dataLayerPush } from '../../../components/Global/helpers';
import { getBrandContent } from '../../BrandingWhiteLabel/BrandContent'
import paginationFactory from "react-bootstrap-table2-paginator";
import SpinnerButton from "../../shared/SpinnerButton/SpinnerButton";
import uuid from 'react-uuid';
import Contract from "../../Contract/Contract";
import { getCurrencySymbol } from '../../../lib/util';

let tableResponsiveLg = "table-responsive-lg";
let dateFormat = "MM/DD/YYYY";

const defaultSortedBy = [{
    dataField: "lastName",
    order: "asc",
}];

export class Confirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            contractList: [],
            totalPaid: this.props.planPriceTotalWithTax.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            contracts: this.props.contracts,
            account: [],
            countryId: this.props.dealerProfile.countryId,
        }

        dateFormat = this.props.dealerProfile.countryId === 2 ? "DD/MM/YYYY" : dateFormat;
    }

    loadContractTab = (contractNumber, index) => {
        const dealerProfile = this.props.dealerProfile;
        let partyName = '';
        let lastName = '';
        let firstName = '';
        let contractInfo = this.props.customerInfo;
        let infoIndex = this.props.pendingSuccess ? index : 0;

        lastName = Array.isArray(contractInfo) && contractInfo.length > 0 ? contractInfo[infoIndex].customerLastName : ""
        firstName = Array.isArray(contractInfo) && contractInfo.length > 0 ? contractInfo[infoIndex].customerFirstName : ""
        partyName = firstName + ' ' + lastName;

        let tabInfo = {
            title: partyName,
            tabType: "contract",
            contractNumber: contractNumber,
        }

        this.props.loadTab(tabInfo);
    }

    isSpinningUpdate = (index, isSpinning) => {
        let contractsListData = [...this.state.contracts];
        let contractsListSpinning = contractsListData[index];
        contractsListSpinning.isButtonSpinning = isSpinning;
        contractsListData = [...contractsListData, contractsListSpinning];
        this.setState({ contracts: contractsListData });
    }

    handleViewClick = (contractNumber, isBtnSpinning, index) => {
        var tokenType = 3;
        this.isSpinningUpdate(index, true);
        this.mapContracts(isBtnSpinning);
        fetch(`token/GetTokenByType/${tokenType}`)
            .then(result => {
                if (result.status === 200) {
                    result.json().then(data => {
                        const url = `certificate?contractNumber=${contractNumber}&token=${data.token}`
                        window.open(url);
                    })
                }

                this.isSpinningUpdate(index, false);
                this.mapContracts();
            });


        //fetch(`document/GetCertificateBlob/${contractNumber}`, { method: 'get', headers: { 'Content-Type': 'application/pdf' } })
        //    .then(res => res.blob())
        //    .then(data => {
        //        const url = window.URL.createObjectURL(data);
        //        window.open(url);
        //        this.isSpinningUpdate(index, false);
        //        this.mapContracts();
        //});
    }

    loadContracts = () => {
        let contractsData = this.state.contracts.map((val, index) => {
            return {
                ...val,
                isButtonSpinning: false
            }
        });
        this.setState({ contracts: contractsData });
    }

    mapContracts = () => {
        if (this.state.contracts !== undefined) {
            let tempList = [...new Map(this.state.contracts.map(item => [item["contractNumber"], item])).values()];
            let contractList = tempList.map((x, index) => {
                let formattedPlanPrice = parseFloat(x.planPrice).toFixed(2);

                return {
                    planName: x.planName,
                    lastName: x.lastName,
                    contractNumber: {
                        button: (<div className="d-inline-flex">
                            <button className="btnLink" onClick={() => { this.loadContractTab(x.contractNumber, index) }}>
                                {x.contractNumber}
                            </button>
                        </div>),
                        number: x.contractNumber
                    },
                    planPrice: `${getCurrencySymbol(this.props.dealerProfile?.countryId)}${formattedPlanPrice}`,
                    installDate: moment(x.installDate).format(dateFormat),
                    certificate: (
                        <div className="d-table-flex">
                            <SpinnerButton
                                onClick={() => {
                                    this.handleViewClick(x.contractNumber, true, index)
                                }}
                                spinning={x.isButtonSpinning}
                                styleType="secondary"
                                className="btn-secondary"
                                text={"View"}
                            />
                        </div>
                    )
                }
            })

            this.setState({ contractList: contractList });
        }
    }

    componentDidMount = () => {
        this.loadContracts();
        this.mapContracts();
        this.sendConfirmationEmail();
        const data = [];
        const allContracts = this.state.contracts.map((contractValue, i) => contractValue?.contractNumber);
        const dataLayerPurchaseExists = window?.dataLayer?.some(e => e?.ecommerce?.items?.some(a => allContracts.includes(a.item_id)));
        dataLayerPush('Pageview', {
            pagePath: '/sales/confirmation',
            pageTitle: 'Sales Confirmation'
        })

        const items = (this.props.assetPackagesData || []).map((value, index) => {
            const filtered = value?.assets?.map((e, i) => {
                const tableFiltered = this.props.productTableData?.filter(pt => pt.serial === e.serialNumber);
                return {
                    [`brand${i + 1}`]: e.brand,
                    [`prod_description${i + 1}`]: tableFiltered?.[0]?.prodDescription,
                }
            });
            const flatFiltered = Object.assign({}, ...filtered)
            return ({
                ...flatFiltered,
                item_name: value?.name,
                quantity: this.props.assetPackagesData.length ?? 0,
                product_qty: value?.assets?.length,
                price: value?.planPrice,
                retail: value?.equipmentRetailPrice,
                reimbursement: '',
                type: '',
                term: value?.selectedYears,
            });
        });
   
        dataLayerPush(null, { ecommerce: null }) // clear last ecommerce data
        dataLayerPush('purchase', {
            ecommerce: {
                transaction_id: uuid(),
                tax: this.props.taxAmount.toFixed(2),
                value: this.props.planPriceTotalWithTax.toFixed(2),
                items
            }
        })
    }

    handleNewSaleClick = (retainInfo) => {
        this.props.handleNewSaleClick(retainInfo);
    }

    formatContractList = (tokenURL, fromPendingScreen) => {
        let { contracts } = this.state;
        let newList = "";
        let listLimit = 5;

        for (var i = 0; i <= listLimit - 1; i++) {
            if (i + 1 > contracts.length) {
                break;
            }

            let c = contracts[i];
            let formattedPlanPrice = parseFloat(c.planPrice);
            formattedPlanPrice = formattedPlanPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

            if (this.state.countryId === 2) {
                formattedPlanPrice = `CAD $${formattedPlanPrice}`;
            } else {
                formattedPlanPrice = `$${formattedPlanPrice}`;
            }

            if (fromPendingScreen == true) {
                newList = newList + `${c.contractNumber}, ${formattedPlanPrice}, ${moment(Date.now()).format(dateFormat)}<br>`;
            } else {
                newList = newList + `${c.contractNumber}, ${formattedPlanPrice}, ${moment(c.installDate).format(dateFormat)}<br>`;
            }

        }

        if (contracts.length > (listLimit)) {
            newList = newList + `<a href='${tokenURL}' target='_blank'>+${contracts.length - listLimit} More</a>`;
        }

        return newList;
    }

    sendConfirmationEmail = async () => {
        if (this.state.contracts !== undefined && !this.props.confirmationEmailSent) {
            const dealerProfile = this.props.dealerProfile;
            const recipient = getPrimaryEmail(dealerProfile);
            const { fromPendingPaymentScreen } = this.context

            let tokenInfo = {
                tokenTypeId: 3,
                target: 'contracts',
                accountId: dealerProfile.accountId,
                dealerId: dealerProfile.dealerId
            }

            if (recipient !== null && recipient !== undefined) {
                let account = this.getAccountDetails();
                let programName = account.programName;
                let programPhone = account.programPhone;

                let email = new emailModel();
                let service = new serviceModel();
                let recipients = [recipient];

                let formattedContractTotal = this.props.planPriceTotalWithTax.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });;

                if (this.state.countryId === 2) {
                    formattedContractTotal = `CAD $${formattedContractTotal}`;
                } else {
                    formattedContractTotal = `$${formattedContractTotal}`;
                }

                email.recipients = recipients
                email.cc = [];
                email.bcc = [];
                email.isBodyHtml = true;
                email.emailType = 0

                service.programName = programName;
                service.programPhoneNumber = programPhone;
                service.contractList = this.formatContractList("tempToken", fromPendingPaymentScreen);
                service.reportsLink = `<a href='tempToken' target='_blank'>Contracts</a>`;
                service.partyId = dealerProfile.partyId;

                // this should always be in number format.
                //service.totalAmt = this.props.planPriceTotalWithTax;

                // use this prop to display the amount in a different format.
                service.totalAmtText = formattedContractTotal

                var type = fromPendingPaymentScreen == true ? "ADVManualPayPurchaseConfirmation" : "DealerPurchaseConfirmation";
                SendMailByAccount(dealerProfile.accountId, email, service, dealerProfile.accountId, type, tokenInfo);

                this.context.setFromPendingPaymentScreen(false);
                this.props.updateConfirmationEmail();
            }
        }
    }

    getAccountDetails = () => {
        let { accountName } = this.context;
        let account = getBrandContent(accountName);

        return account;
    }


    render() {

        const { accountName } = this.context
        const currency = getCurrencySymbol(this.props.dealerProfile?.countryId);
        const finalContractsColumns = [
            {
                dataField: "contractNumber",
                text: "Contract Number",
                headerFormatter: headerFormatter,
                sort: "true",
                formatter: (contractNumber) => contractNumber.button,
                sortFunc: (a, b, order) => {
                    if (order === "desc")
                        return b.number - a.number;
                    else if (order === "asc")
                        return a.number - b.number;
                }
            },
            {
                dataField: "planName",
                text: "Plan Name",
                headerFormatter: headerFormatter,
                sort: "true",
            },
            {
                dataField: "planPrice",
                text: "Plan Price",
                headerFormatter: headerFormatter,
                formatter: priceFormatter,
                sort: "true",
            },
            {
                dataField: "installDate",
                text: "Install Date",
                headerFormatter: headerFormatter,
                sort: "true",
            },
            {
                dataField: "certificate",
                text: "Certificate of Coverage",
            },
        ];

        const pendingContractsColumns = [
            {
                dataField: "contractNumber",
                text: "Contract Number",
                headerFormatter: headerFormatter,
                sort: "true",
                formatter: (contractNumber) => contractNumber.button,
                sortFunc: (a, b, order) => {
                    if (order === "desc")
                        return b.number - a.number;
                    else if (order === "asc")
                        return a.number - b.number;
                }
            },
            {
                dataField: "lastName",
                text: "Last Name",
                headerFormatter: headerFormatter,
                sort: "true",
            },
            {
                dataField: "planPrice",
                text: "Plan Price",
                headerFormatter: headerFormatter,
                sort: "true",
            },
            {
                dataField: "installDate",
                text: "Install Date",
                headerFormatter: headerFormatter,
                sort: "true",
            },
        ];

        function headerFormatter(column, index, { sortElement }) {
            const { order } = sortElement.props;
            return (
                <span className={`${order === "asc" ? "ascending" : ''} ${order === "desc" ? "descending" : ''}`}>
                    {column.text}
                </span>
            );
        }

        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing <span className={styles.textClr}>{(to - from) + 1}</span> of&nbsp;
                <span className={styles.textClr}>{size}</span> contracts
            </span>
        );

        const options = {
            sizePerPage: 10,
            hideSizePerPage: true,
            showTotal: true,
            paginationTotalRenderer: customTotal,
            hidePageListOnlyOnePage: true,
        };

        const priceFormatter = (price, tableData) => {
            return (
                <div className="text-right">${Number.parseFloat(price).toFixed(2)}</div>
            );
        };
        return (
            <Container className={"contentWrapper"}>
                <Card>
                    <CardBody>
                        <div className={styles.printConfirm}>
                            <PrintButton onClick={() => window.print()} />
                        </div>
                        <div className={styles.thanksConfirmation}>
                            <span className={styles.succuessIcon}>
                                <BrandedIcon
                                    accountName={accountName}
                                    iconType={"successful"}
                                />
                            </span>
                            <h1 className="h1">Thank you for your payment of {`${currency}${Number.parseFloat(this.state.totalPaid).toFixed(2)}`} </h1>
                            {this.props.pendingSuccess ? (
                                <p>
                                    We have successfully processed your payment for {this.state.contractList.length} contract{this.state.contractList.length == 1 ? "" : "s"}.
                                </p>
                            ) : (
                                    <p>You have successfully completed your order.</p>
                                )}
                        </div>
                        <div className={styles.finalContractsTable}>
                            <BootstrapTable
                                keyField="contractNumber"
                                data={this.state.contractList}
                                columns={
                                    this.props.pendingSuccess
                                        ? pendingContractsColumns
                                        : finalContractsColumns
                                }
                                bordered={false}
                                bootstrap4
                                wrapperClasses={`text-center ${tableResponsiveLg}`}
                                defaultSorted={defaultSortedBy}
                                pagination={this.props.pendingSuccess && paginationFactory(options)}
                            />
                        </div>
                    </CardBody>
                </Card>
                {this.props.showNewSaleButton && (
                    <BottomButtons
                        rightButtons={[
                            <button
                                className={classNames("btn-primary")}
                                onClick={() => {
                                    this.handleNewSaleClick(false);
                                }}
                            >
                                Enter Another Sale
              </button>,
                        ]}
                    />
                )}
            </Container>
        );
    }
}

Confirmation.contextType = SessionContext; 