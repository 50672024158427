import React, { Component } from "react";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import styles from "./InformationReview.module.css";
import BootstrapTable from "react-bootstrap-table-next";
import EditButton from '../shared/EditButton/EditButton';
import { dataLayerPush } from '../../components/Global/helpers';
import OrderInfoContext from "../../context/OrderInfoContext";
import { getCurrencySymbol } from '../../lib/util';

export class InformationReview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            taxPrice: this.props.taxAmount.toFixed(2),
            gstTotal: this.props.gstTotal.toFixed(2),
            pstTotal: this.props.pstTotal.toFixed(2),
            planPriceTotal: this.props.planPriceTotal.toFixed(2),
            planPriceTotalWithTax: this.props.planPriceTotalWithTax.toFixed(2)
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = e => {
        const { id } = e.target;
        
        if (id === "order") {
            this.props.handleInformationReviewClick(1);
        } else if (id === "plan") {
            this.props.handleInformationReviewClick(2);
        } else if (id === "customer") {
            this.props.handleInformationReviewClick(3);
        } else if (id === "product") {
            this.props.handleInformationReviewClick(1);
        }
    }

    componentDidMount() {
        //console.log({propsss: this.props})
        dataLayerPush('Pageview', {
            pageTitle: "Review Order",
            pagePath: "/sales/revieworder"
        })
        const assetPackagesForGtm = [];
        (this.props.assetPackagesData || []).map((value, i) => assetPackagesForGtm.push({
            item_name: value?.name,
            quantity: this.props.assetPackagesData.length ?? 0,
            product_qty: value?.assets?.length,
            price: value?.planPrice,
            retail: value?.equipmentRetailPrice,
            reimbursement: '',
            type: '',
            term: value?.selectedYears,
            products: value.assets?.map(e => ({
                brand: e?.brand,
                prod_description: this.props.productTableData.find(
                    x => x.assetId == e.assetId
                ).prodDescription
            }))
        }))
        //console.log({ x: this.state.assetPackagesData, assetPackagesForGtm })
        dataLayerPush(null, { ecommerce: null }) // clear last ecommerce data
        dataLayerPush('view_cart', {
            ecommerce: {
                tax: this.props.taxAmount.toFixed(2),
                value: this.props.planPriceTotalWithTax.toFixed(2),
                items: [
                    ...assetPackagesForGtm
                ]
            }
        })
    }

    render() {
        let tableResponsiveLg = "table-responsive";
        //plan details
        const temp2 = [...this.props.assetPackagesData]
        const currency = getCurrencySymbol(this.props.countryId);
        const planData = temp2.map(s => {
            return {
                planName: s.name,
                planPrice: s.planPrice == null ? currency +"0.00" : currency + s.planPrice,
                dealerPO: s.dealerPo,
                equipRetail: s.equipmentRetailPrice == null ? currency+"0.00" : currency + s.equipmentRetailPrice,
                term: s.selectedYears == null || s.selectedYears == "" ? '0 Years' : s.selectedYears + ' Years',
                planRetailPrice: s.planRetailPrice == null ? currency+"0.00" : currency + s.planRetailPrice,
                planDescription: s.planDesc,
                duplicate: this.props.duplicatePackages.includes(s.packageId)
            }
        })
 
        //product data
        let assetIds = [];
        let ids = [];
        
        if (this.props.assetPackagesData.length > 0) {
            for (var index = 0; index < this.props.assetPackagesData.length; index++) {

                for (var j = 0; j < this.props.assetPackagesData[index].assetIds.length; j++) {
                    ids.push(this.props.assetPackagesData[index].assetIds[j])
                    let details = {};
                    details.id = this.props.assetPackagesData[index].assetIds[j];
                    details.name = this.props.assetPackagesData[index].name;
                    assetIds.push(details);
                }
            }
        }

        const temp = [...(this.props.productTableData.filter(a => ids.includes(a.assetId)))]
        const productData = temp.map(s => {
            return {
                planName: assetIds.find(x => x.id == s.assetId).name,
                brandName: s.brandNameBack,
                prodDescription: s.prodDescriptionBack,
                modelNumber: s.modelNum,
                serial: s.serialNumBack,
                mfgPartsWarranty: s.MFGWarrantyBack,
                series: s.series,
                size: this.context.installationType == "Commercial" ? s.size : "",
                duplicate: this.props.duplicateSerialNumbers.includes(s.serialNumBack)
            }
        })

        //customer info
        const propCustoInfo = this.props.customerInfo;
        const propMailInfo = this.props.mailInfo;
        const propChk = this.props.chkInstallationAddress;

        let addLine1;
        let addLine2;
        let addLine3;

        if (propChk === "" || propChk === true) {
            addLine1 = this.props.installationAddress + ",";
            addLine2 = this.props.installationAddress2;
            addLine3 = this.props.installationCity + ", " + this.props.installationState + ", " + this.props.installationPostal;

        } else {
            const stateId = propMailInfo[0].customerMailingStateId ?? (this.props.stateInfo !== undefined) ? this.props.stateInfo : this.props.installationStateId;
            const mailState = (propMailInfo[0].customerMailingState === undefined) ? this.props.orderInfoStates.find(({ data }) => data === stateId).display : propMailInfo[0].customerMailingState;
            addLine1 = propMailInfo[0].customerMailingAddress + ",";
            addLine2 = propMailInfo[0].customerMailingAddress2;
            addLine3 = propMailInfo[0].customerMailingCity + ", " + mailState + ", " + propMailInfo[0].customerMailingPostal
        }


        let coveredProdColumns = null;
        if (this.context.installationType == "Commercial") {
            coveredProdColumns = [
                {
                    dataField: "planName",
                    text: "Plan Name",
                },
                {
                    dataField: "brandName",
                    text: "Brand",
                },
                {
                    dataField: "prodDescription",
                    text: "Description",
                },
                {
                    dataField: "size",
                    text: "Size",
                },
                {
                    dataField: "modelNumber",
                    text: "Model",
                },
                {
                    dataField: "serial",
                    text: "Serial",
                },
                {
                    dataField: "mfgPartsWarranty",
                    text: "MFG Parts Warranty",
                },
                {
                    dataField: "series",
                    text: "Series",
                },
            ];
        } else {
            coveredProdColumns = [
                {
                    dataField: "planName",
                    text: "Plan Name",
                },
                {
                    dataField: "brandName",
                    text: "Brand",
                },
                {
                    dataField: "prodDescription",
                    text: "Description",
                },
                {
                    dataField: "modelNumber",
                    text: "Model",
                },
                {
                    dataField: "serial",
                    text: "Serial",
                },
                {
                    dataField: "mfgPartsWarranty",
                    text: "MFG Parts Warranty",
                },
                {
                    dataField: "series",
                    text: "Series",
                },
            ];
        }

       let planDetailsColumns = null;
       if (this.props.isCanadaState == false) {
           planDetailsColumns = [
               {
                   dataField: "planName",
                   text: "Plan Name",
               },
               {
                   dataField: "planPrice",
                   text: "Plan Price",
               },
               {
                   dataField: "dealerPO",
                   text: "Dealer PO#/Reg #",
               },
               {
                   dataField: "equipRetail",
                   text: "Equip Retail",
               },
               {
                   dataField: "term",
                   text: "Term",
               },
               {
                   dataField: "planDescription",
                   text: "Plan Description",
                   classes: styles.commentCol
               },
           ];
       } else {
           planDetailsColumns = [
               {
                   dataField: "planName",
                   text: "Plan Name",
               },
               {
                   dataField: "planPrice",
                   text: "Plan Price",
               },
               {
                   dataField: "dealerPO",
                   text: "Dealer PO#/Reg #",
               },
               {
                   dataField: "equipRetail",
                   text: "Equip Retail",
               },
               {
                   dataField: "planRetailPrice",
                   text: "Plan Retail",
               },
               {
                   dataField: "term",
                   text: "Term",
               },
               {
                   dataField: "planDescription",
                   text: "Plan Description",
                   classes: styles.commentCol
               },
           ];
       }

        if (!this.props.isLennox) {
            for (var i = 0; i < coveredProdColumns.length; i++) {
                if (coveredProdColumns[i].dataField === 'series') {
                    coveredProdColumns.splice(i, 1);
                    break;
                }
            }
        }

        

        return (
            <Container className="contentWrapper">
                <h1 className={`h3 ${styles.pageHeader}`}>Review</h1>
                <div className={styles.cardsWrapper}>
                    <Row className="multiColWrapper">
                        <Col sm="12" md="6" lg="4">
                            <Card className={styles.infoReviewCards}>
                                <CardBody>
                                    <div className={styles.cardHead}>
                                        <div>
                                            <h2 className="h5 subHead">Order Information</h2>
                                        </div>
                                        <EditButton id="order" onClick={this.handleClick}/>
                                    </div>
                                    <p className="form-label">Installation Address</p>
                                    <ul className="noBullets">

                                        <li>{this.props.installationAddress},</li>
                                        <li>{this.props.installationAddress2}</li>
                                        <li>{this.props.installationCity},  {this.props.installtionStateAbb}, {this.props.installationPostal}</li>

                                    </ul>
                                    <p className="form-label">Installation Date</p>
                                    <p>{this.props.installationDate}</p>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="12" md="6" lg="4">
                            <Card className={styles.infoReviewCards}>
                                <CardBody>
                                    <div className={styles.cardHead}>
                                        <div>
                                            <h2 className="h5 subHead">Customer Information</h2>
                                        </div>
                                        <EditButton id="customer" onClick={this.handleClick}/>
                                    </div>
                                    <ul className="noBullets">
                                        <li>{propCustoInfo[0].customerFirstName} {propCustoInfo[0].customerLastName}</li>
                                        <li>{propCustoInfo[0].customerEmail}</li>
                                        {this.props.mobileInfo != "" && <li>{this.props.mobileInfo} (Mobile)</li>}
                                        {this.props.altMobileInfo != "" && <li>{this.props.altMobileInfo} (Alternate)</li>}

                                    </ul>
                                    <p className="form-label">Mailing Address</p>
                                    <ul className="noBullets">
                                        <li>{addLine1}</li>
                                        <li>{addLine2}</li>
                                        <li>{addLine3}</li>

                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="12" lg="4">
                            <Card className={styles.infoReviewCards}>
                                <CardBody>
                                    <h2 className="h5 subHead">Order Summary</h2>

                                    <div className={styles.planTotal}>
                                        <p>Plan Total</p>
                                        <p>{currency}{this.state.planPriceTotal}</p>
                                    </div>
                                    {!this.props.isCanadaState &&
                                        <div className={styles.taxTotal}>
                                            <p>Tax</p>
                                            <p>Not applicable</p>
                                        </div>
                                    }
                                    {this.props.isCanadaState &&
                                        <div>
                                            <div className={styles.planTotal}>
                                                <p>GST</p>
                                                <p>{currency}{this.state.gstTotal}</p>
                                            </div>
                                            <div className={styles.taxTotal}>
                                                <p>PST</p>
                                                <p>{currency}{this.state.pstTotal}</p>
                                            </div>
                                        </div>
                                    }
                                    <div className={styles.totalAmount}>
                                        <p>Order Total</p>
                                        <p>{!this.props.isCanadaState ?  `${currency}${this.state.planPriceTotal}` : `${currency}${this.state.planPriceTotalWithTax}`}</p> 
                                    </div>
                                    {this.props.isDealerInvoice && <p className="form-label">Payment Method</p>}
                                    {this.props.isDealerInvoice &&
                                        <p>
                                            You will receive an Invoice for this order. Please remit
                                            payment within the terms listed on the invoice.
                                    </p>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Card>
                    <CardBody>
                        <div className={styles.cardHead}>
                            <div>
                                <h2 className="h5 subHead">Plan Details</h2>
                            </div>
                            <EditButton id="plan" onClick={this.handleClick}/>
                        </div>
                        <div className={styles.planDetTable}>
                            <BootstrapTable
                                keyField="dealerPO"
                                data={planData}
                                columns={planDetailsColumns}
                                bordered={false}
                                borderless={true}
                                bootstrap4
                                wrapperClasses={tableResponsiveLg}
                                rowClasses={(row) => {
                                    return row.duplicate ? styles.errorText : "";
                                }}
                            />
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className={styles.cardHead}>
                            <div>
                                <h2 className="h5 subHead">Covered Products</h2>
                            </div>
                            <EditButton id="product" onClick={this.handleClick}/>
                        </div>
                        <p>
                            Control product including programmable thermostats must be listed
                            below to be covered.
                        </p>
                        <div className={styles.coveredProdTable}>
                            <BootstrapTable
                                keyField="serial"
                                data={productData} //{coveredProdData}
                                columns={coveredProdColumns}
                                bordered={false}
                                borderless={true}
                                bootstrap4
                                wrapperClasses={tableResponsiveLg}
                                rowClasses={(row) => {
                                    return row.duplicate ? styles.errorText : "";
                                }}
                            />

                        </div>
                    </CardBody>
                </Card>
                {/* <Row className="justify-content-between m-0">
                    <button className="btn-secondary">Back</button>
                    <div className="btnWrapper">
                        <button className="btnLink">Save For Later</button>
                        <button className="btn-primary">Continue</button>
                    </div>
                </Row> */}
            </Container>
        );
    }
}
InformationReview.contextType = OrderInfoContext;
