import React, { Component } from "react";
import { FormGroup } from "reactstrap";
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';

export default class DropdownWrapper extends Component {

  render(){
      const { label, isRequired = false, onChange = () => { }, options = [], className, id, disabled, ...remaining } = this.props;
    return( 
      <FormGroup>
        {label && (<label for={id} className="form-label">{label}</label>)}
        {(label && isRequired) && (<span aria-hidden="true" className="required-indicator">*</span>)}
            <DropDownAutoComplete
                disabled={disabled}
          options={options}
          className={`${className} dropdown-wrapper`}
          id={id}
          onChangedValue={onChange}
          onBlur={onChange}
          placeholder="Please select..."
          {...remaining}

        />
      </FormGroup>
    );
  }
}