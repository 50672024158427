export const fetchBrandsByAccountId = async (accountId) => {
    try {
        const res = await fetch(
            `Brand/GetBrandsByAccountId/${accountId}`
        );
        const data = await res.json();
        return data;
    } catch (e) {
        console.error('Error fetching Brand By AccountId', e)
        return {}
    }
}

export const getProductionDescriptionFromProd = async (assetId, masterId) => {
    try {
        if (masterId !== undefined) {
            const res = await fetch(
                "Asset/GetAssetsByMasterProgramId/" + masterId
            );
            const data = await res.json();
            return data;
        }
        return {}
    } catch (e) {
        console.error('Error fetching Description By Program ID', e)
        return {}
    }
}

export const loadWarrantyOptions = async (accountId) => {
    try {
        const res = await fetch(
            "Account/getWarrantyOptions/" + accountId);
        const warrantyOptions = await res.json();
        const filtColMfg = warrantyOptions.partsOptions.map((s) => {
            return {
                data: s,
                display: s + "",
            };
        });
        const filtColMfgLabor = warrantyOptions.laborOptions.map((s) => {
            return {
                data: s,
                display: s + "",
            };
        });


        const sortedMfg = filtColMfg.sort(
            (a, b) => parseFloat(a.display) - parseFloat(b.display)
        );

        const sortedMfgLabor = filtColMfgLabor.sort(
            (a, b) => parseFloat(a.display) - parseFloat(b.display)
        );
      
        return {
            sortedMfg,
            sortedMfgLabor
        }
    } catch (e) {
        console.error('Error fetching MFG warranty options By Account ID', e)
        return {}
    }
}


export const loadSeries = async () => {
    try {
        const res = await fetch(
            "Asset/GetAllSeries"
        );
        const data = await res.json();
        return data;
    } catch (e) {
        console.error('Error fetching Series', e)
        return {}
    }
}