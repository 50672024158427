import React, { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import styles from './UploadDoc.module.css';
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon';
import { SessionContext } from "../../context/SessionContext";
import classNames from 'classnames';

const Dropzone = (props) => {
  const {
    onFileUpload,
    maxFiles,
      customClass
  } = props;

  const maxFileCount = maxFiles ?? 0;

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: '.jpeg, .png, .pdf, .jpg',
    maxSize: 4.195e+6,
    maxFiles: maxFileCount,
    minSize: 2,
    multiple: maxFileCount != 1,
    onDrop: acceptedFiles => {
      onFileUpload(acceptedFiles);
    }
  });

  const sessionContext = useContext(SessionContext);
  const accountName = sessionContext.accountName.toLowerCase();

  return (
    <section>
      <div className={classNames(styles.dropzone, `${customClass}`)} {...getRootProps()}>
        <span className={styles.uploadImage}>
          <BrandedIcon accountName={accountName} iconType={'circleUpload'} />
        </span>
        <div className={styles.dzMessage}>
          <input {...getInputProps()} />
          Drag and Drop or&nbsp;
          <a className={styles.browseLink} onClick={open} onKeyPress={open} id="reach-skip-nav" tabIndex="0" role="button">Browse Files</a>
          <div className={styles.fileTypes}>Files Supported: PDF, PNG, JPG, JPEG</div>
          <div className={styles.fileSize}>Maximum Size: 4MB</div>
        </div>
      </div>
      <div className={styles.mobileUpload}>
        <span className={styles.uploadImage}>
          <BrandedIcon accountName={accountName} iconType={'circleUpload'} />
        </span>
        <div className={styles.dzMessage}>
          <input {...getInputProps()} />
          Upload document from your device
          <div className={styles.fileTypes}>Files Supported: PDF, PNG, JPG, JPEG</div>
          <div className={styles.fileSize}>Maximum Size: 4MB</div>
             <a className="btn btn-primary" onClick={open} onKeyPress={open} tabIndex="0" role="button">Browse Files</a>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Dropzone);