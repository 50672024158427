import React, { Component } from "react";
import { Container, Card, CardBody, Row, Col, Label, Input, FormGroup } from "reactstrap";
import { CardDetails } from '../components/Sales/PaymentInformation/CardDetails';
import { OrderSummary } from '../components/Sales/PaymentInformation/OrderSummary';
import { PaymentGateway } from './PaymentGateway'
import styles from './PaymentContainer.module.css';

import classNames from "classnames";
let customPayBtn = styles.customPayBtn;
export class PaymentGatewayContainer extends Component {
    render() {
        return (
            <Container>
                <PaymentGateway/>
            </Container>
        );
    }
}
