import React from 'react';
import Cleave from 'cleave.js/react';
import { FormGroup } from 'reactstrap';



const TaxFormFormat = ({ taxFormName = "", handleInputChange, taxStatus, validator, validationFired, label }) => {
    let validation = `size:9|numeric`
    if (!taxStatus || taxStatus === "INC" || taxStatus === "UNC") {
        validation = `size:10`
    }
    if (taxStatus === "QST") {
        validation = `size:17|regex:^[0-9]{10} [a-zA-Z]{2}[0-9]{4}`
    }
    if (taxStatus === "PST") {
        validation = `size:13|regex:^[a-zA-Z]{3}-[0-9]{4}-[0-9]{4}`
    }

    return <FormGroup className={validationFired && !validator.check(taxFormName, validation) ? 'form-error' : ''}>
        <label className="form-label" for="taxFormName">
            {label}
        </label>
        { /* <span aria-hidden="true" className="required-indicator mr-1">*</span> */}
        {(!taxStatus ||
            taxStatus === "INC" ||
            taxStatus === "UNC") &&
            <Cleave
                placeholder=""
                name="taxFormName"
                className="form-control"
                id="taxFormName"
                options={{
                    numericOnly: true,
                    blocks: [2, 7],
                    delimiters: ["-"]
                }
                }
                value={taxFormName}
                onChange={handleInputChange}
            />
        }
        {(taxStatus === "GST" ||
            taxStatus === "HST") &&
            <Cleave
                placeholder=""
                name="taxFormName"
                className="form-control"
                id="taxFormName"
                options={{
                    numericOnly: true,
                    blocks: [9]
                }
                }
                value={taxFormName}
                onChange={handleInputChange}
            />
        }
        {(taxStatus === "QST") &&
            <Cleave
                placeholder=""
                name="taxFormName"
                className="form-control"
                id="taxFormName"
                options={{
                    blocks: [10, 6],
                    delimiters: [" "]
                }
                }
                value={taxFormName}
                onChange={handleInputChange}
            />
        }

        {(taxStatus === "PST") &&
            <Cleave
                placeholder=""
                name="taxFormName"
                className="form-control"
                id="taxFormName"
                options={{
                    blocks: [3, 4, 4],
                    delimiters: ["-"]
                }
                }
                value={taxFormName}
                onChange={handleInputChange}
            />
        }
        {/* validator.message("taxStatus", taxFormName, "required", { messages: { required: `${label} is required.` } }) */}
        {/* Commented for now, will be used in the future. */}
        <p className='errorMsg'>
            {!taxStatus &&
                validator.message(
                    "taxStatus",
                    taxFormName,
                    "size:10",
                    { messages: { size: `${label} must be at least 9 characters.` } }
                )
            }
            {(taxStatus === "INC" ||
                taxStatus === "UNC") &&
                validator.message(
                    "taxStatus",
                    taxFormName,
                    taxFormName.length > 0 ? "size:10" : "",
                    { messages: { size: `${label} must be at least 9 characters.` } }
                )
            }
            {(taxStatus === "GST" ||
                taxStatus === "HST") &&
                validator.message(
                    "taxStatus",
                    taxFormName,
                    "min:9|max:9",
                    { messages: { min: `${label} must be at least 9 characters.` } }
                )
            }
            {taxStatus === "QST" &&
                validator.message(
                    "taxStatus",
                    taxFormName,
                    `min:17|max:17|regex:^[0-9]{10} [a-zA-Z]{2}[0-9]{4}`,
                    { messages: { min: `${label} must be at least 16 characters.` } }
                )
            }
            {taxStatus === "PST" &&
                validator.message(
                    "taxStatus",
                    taxFormName,
                    "min:13|max:13|regex:^[a-zA-Z]{3}-[0-9]{4}-[0-9]{4}",
                    { messages: { min: `${label} must be at least 11 characters.` } }
                )
            }
        </p>
    </FormGroup>

};

export default TaxFormFormat;