import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styles from "./RFSForm.module.css";

const nonCoveredRepairInfo = {
    "Dishwasher": [
        "Baskets",
        "Rollers",
        "Racks",
        "Cosmetic issues (e.g., scratches, dents and chipping)",
    ],
    "Range/Oven/Stove": [
        "Clocks",
        "Meat probe assemblies",
        "Rotisseries",
        "Racks",
        "Handles",
        "Knobs",
        "Cosmetic issues (e.g., scratches, dents, chipping, breakage to oven door, or breakage to glass/ceramic cooktop)",
    ],
    "Dryer": [
        "Venting",
        "Knobs",
        "Dials",
        "Damage to clothing",
        "Lint screens",
        "Dryer cabinet fragrance or humidity center",
        "Cosmetic issues (e.g., scratches, dents and chipping)",
    ],

    "Garbage Disposal": [
        "Problems and/or jams caused by bones or foreign objects other than food",
    ],
    "Electrical Systems": [
        "Fixtures",
        "Electrical panel",
        "Attic or whole house exhaust fans",
        "Doorbells",
        "Intercom systems",
        "Alarm systems",
        "Central vacuum systems",
        "Audio/video/computer wiring or cable",
        "Direct current wiring and systems",
        "Exterior wiring and components",
        "Telephone wiring",
        "Inadequate wiring capacity",
        "Power failure, shortage or surge",
        "Low voltage systems",
        "Load control devices",
        "Electrical generation systems",
        "Solar electrical systems",
        "Timers",
        "Touch pad assemblies",
        "Remote controls",
        "Failure caused by circuit overload",
    ],
    "Kitchen Exhaust Fan": [
        "Rooftop exhaust units",
        "Filters",
        "Cosmetic issues (e.g., scratches, dents, and chipping)",
    ],
    "Microwave": [
        "Countertop units",
        "Door glass",
        "Clocks",
        "Rotisseries",
        "Interior linings",
        "Cosmetic issues (e.g., scratches, dents, and chipping)",
    ],
    "Refrigerator": [
        "Ice makers and controls",
        "Food spoilage",
        "Media centers",
        "Cosmetic issues (e.g., scratches, dents, and chipping)",
    ],
    "Washer": [
        "Removable mini-tubs/buckets",
        "Soap dispensers",
        "Filter screens",
        "Knobs",
        "Dials",
        "Damage to clothing",
        "Water flow restrictions due to mineral deposits",
        "Drawers",
        "Cosmetic issues (e.g., scratches, dents, and chipping)",
    ],
    "Freezer": [
        "Ice makers and controls",
        "Food spoilage",
        "Media centers",
        "Cosmetic issues (e.g., scratches, dents and chipping)",
    ],
    "A/C System": [
        'Coolant replenishment in excess of 2 pounds if unrelated to a repair',
        'Commercial systems',
        'Gas-powered systems',
        'Geothermal/indirect coil systems',
        'Water or electric lines to/from central cooling system, including condensate pumps and related components',
        'Distribution systems such as air ducts, filters, air handlers or diverters, registers, pipes or radiators',
        'Portable units, swamp coolers, dehumidifiers or humidifiers',
        'Individual window, wall or rooftop units',
        'Chimneys, flues or vents',
        'Cosmetic coverings including doors, panels, trim, flushing, insulation or baseboard casings',
        `Electronics, internet-enabled or 'smart' components or functions (i.e. zone controllers)`,
        'Cooling units configured not to code or in line with manufacturer instructions',
        'Incidental or consequential damages',
        'Bringing up to code, upgrading or problems that existed at the time the member first enrolled',
    ],
    "ET Electrical Systems": [
        'Electric supplies over 400 amps',
        'Commercial, mixed use or any electric lines whose ownership is in doubt',
        'External fixtures and appliances',
        'Non-Co-Op/home-generation or home-storage connections',
        'Non-copper wiring',
        'Security systems',
        'Moving furniture or other objects to get to the repair site',
        'Any wiring or other electrical items located 12 feet above ground level outside the perimeter of the principal dwelling or garage',
        'Low-voltage or solar systems',
        'Data or audio cables',
        `Internet enabled or 'smart' functions`,
        'Incidental or consequential damages',
        'Bringing up to code, upgrading or problems that existed at the time the member first enrolled',
        'The meter is presumed Co-Op property and the member will be directed to call the Co-Op if it fails'
    ],
    "Electric Vehicle Charger": [
        'Electric wiring other than the point of connection to the charger, fuse boxes or other items for which the Electric Line Protection coverage is available',
        'Items not owned by the homeowner',
        'Items other than Level 2 electric vehicle chargers',
        'Items damaged outside the home',
        'Items lost or stolen',
        'Electric vehicles or components thereof',
        'Chargers used to charge items other than electric vehicles, or not in accordance with the vehicle manual',
        'Incidental or consequential damages beyond the benefits specified',
        'Bringing up to code, upgrading or problems that existed at the time the member first enrolled'
    ],
    "Surge Protection": [
        'Electric wiring, fuse boxes or other items for which the Electric Line Protection coverage is available',
        'Items not properly certified that loss was due to a surge event',
        'Items damaged outside the home such as AC systems, pools, dog fences, surveillance equipment, etc.',
        'Restoration of data, software or programming',
        'Electric vehicles and their connections',
        'Items of less than $100 value',
        'Incidental or consequential damages beyond the benefits specified',
        'Bringing up to code, upgrading or problems that existed at the time the member first enrolled',
    ]
};

export const RepairLimitInfo = ({ isOpen, toggle, centered }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="modal-md"
            centered={centered}>
            <ModalHeader>
                <h4 className="modal-title">Total Repair Limit</h4>
            </ModalHeader>
            <ModalBody>
                <p>
                    Authorization is required if the cost will exceed the Total
                    Repair Limit.
                </p>
                <p>
                    Repairs exceeding the limit where no authorization is
                    obtained, will result in non-payment of repair. To obtain
                    repair authorization, please call
                    <a href="tel:866-523-8649" className="callLink ml-1 mr-1">
                        (866) 523-8649
                    </a>
                    during business hours 8:00 AM to 6:00 PM EST Monday -
                    Friday.
                </p>
            </ModalBody>
            <ModalFooter>
                <div className="btnWrapper">
                    <button className="btnLink" onClick={toggle}>
                        Close
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export const NonCoveredRepairsInfo = ({
    isOpen,
    toggle,
    centered,
    incidentData,
}) => {

    const nonCoveredProductName = (data) => {
        return data?.client === "Energy Texas" &&
          data?.productName === "Electrical Systems"
          ? "ET Electrical Systems"
          : data?.productName;
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="modal-md"
            centered={centered}>
            <ModalHeader>
                <h4 className="modal-title">
                    {incidentData.productName} Non-Covered Repairs
                </h4>
            </ModalHeader>
            <ModalBody>
                <ul className={`${styles.nonCoveredRepairTopics} mb-1`}>
                    {nonCoveredRepairInfo[nonCoveredProductName(incidentData)]?.map(
                        (item, index) => {
                            return (
                                <li key={index}>
                                    <span>{item} </span>
                                </li>
                            );
                        },
                    )}
                </ul>
            </ModalBody>
            <ModalFooter>
                <div className="btnWrapper">
                    <button className="btnLink" onClick={toggle}>
                        Close
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
