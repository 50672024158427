import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import classNames from 'classnames';


const StandardInput = ({
    label,
    hasError,                           // PASS CONDITION TO ADD 'FORM ERROR' TO FORMGROUP                         
    fieldName,                          // FOR ARIA AND NAME ATTR ON INPUT
    required = true,                    // BOOLEAN -- PASS 'FALSE' TO TURN OFF ASTERISK
    htmlInputType = 'text',             // WHEN SPECIFIC "TYPE" ATTR NEEDED E.G. "tel" or "email"
    customClass,                        // IF CUSTOM STYLING REQUIRED, WILL MODIFY NORMAL STYLES
    autoComplete,                       // STRING, OPTIONAL
    ariaLabelAndFieldId = '',                       // IF HTML ID REQUIRED
    value,                              // VALUE FROM PARENT
    onChange,                           // CHANGE HANDLER FROM PARENT
    validator,                          // --- IMPORTANT --- PASS ENTIRE VALIDATOR REQUIREMENT (validator.current.message(...args))
    includeFormGroupWrapper = true,     // BY DEFAULT WRAPS IN FORMGROUP COMPONENT. TURN ---OFF--- IF NESTED IN COL FOR LAYOUT PURPOSES, ETC 
    placeholder = '',
    maxLength,                          // NUMBER
    disabled = false,
    onKeyUp,
    hideAsterik = false,                // PASS THIS WHEN REQUIRED FIELD IS NEEDED BUT ASTERIK IS NOT NEEDED 
    formGroupClassName = ''
}) => {

    //ADD LOGIC AS NEEDED HERE
    return (includeFormGroupWrapper ? (
        <FormGroup className={`${formGroupClassName} ${hasError ? "form-error" : ''}`}>
            {label && (
                <Label htmlFor={fieldName} className={classNames("pt-0", "form-label")}>
                {label}
                    {!hideAsterik && required && (
                        <span aria-hidden="true" className="required-indicator">*</span>
                    )}
            </Label>
            )}
            <Input
                type={htmlInputType}
                className={`form-control ${customClass}`}
                autoComplete={autoComplete && autoComplete}
                id={ariaLabelAndFieldId}
                name={fieldName}
                value={value}
                onChange={onChange}
                aria-required="true"
                placeholder={placeholder && placeholder}
                maxLength={maxLength && maxLength}
                disabled={disabled}
                onKeyUp={onKeyUp}
            />
            <p className="errorMsg">{validator}</p>
        </FormGroup>
    ) : (
            <>
                {label && (
                <Label htmlFor={fieldName} className="form-label">
                    {label}
                </Label>
                )}
                {hideAsterik && required && (
                    <span aria-hidden="true" className="required-indicator">*</span>
                )}
                <Input
                    type={htmlInputType}
                    className={`form-control ${customClass}`}
                    autoComplete={autoComplete && autoComplete}
                    id={ariaLabelAndFieldId}
                    name={fieldName}
                    value={value}
                    onChange={onChange}
                    maxLength={maxLength && maxLength}
                    aria-required="true"
                    disabled={disabled}
                />
                {validator}
            </>
        )
    )
}

export default StandardInput;