import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { ReactComponent as YesIcon } from '../../../../assets/images/checkmark.svg';
import styles from './PlanOptions.module.css';
import { Row } from "reactstrap";
import classnames from 'classnames';
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent';
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";

const coverageTypeTable = styles.coverageTypeTable;

const YesIconComp = () => {
  return (
    <div className={styles.yesIcon}><YesIcon /></div>
  )
};

const CoverageType = () => {

  const context = useContext(SessionContext)
  const { accountName } = context

  let screenRecords = getBrandContent("coveragetype");  

  const coverageTypeColumnsAll = [
    {
      dataField: "planBenefits",
      text: "Plan Benefits*"
    },
    {
      dataField: "laborOnly",
      text: "Labor Only"
    },
    {
      dataField: "laborPlus",
      text: "Labor PLUS"
    },
    {
      dataField: "partsLabor",
      text: "Parts"
    }
  ];

  const coverageTypeDataAll = [];
  (screenRecords[accountName.toLowerCase()] !== null && screenRecords[accountName.toLowerCase()]?.plan1) && 
    coverageTypeDataAll.push({
      planBenefits: (screenRecords[accountName.toLowerCase()]?.plan1[0] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan1[0],
      laborOnly: (screenRecords[accountName.toLowerCase()]?.plan1[1] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan1[1],
      laborPlus: (screenRecords[accountName.toLowerCase()]?.plan1[2] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan1[2],
      partsLabor: (screenRecords[accountName.toLowerCase()]?.plan1[3] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan1[3],
    });
  (screenRecords[accountName.toLowerCase()] !== null && screenRecords[accountName.toLowerCase()]?.plan2) && 
    coverageTypeDataAll.push({
      planBenefits: (screenRecords[accountName.toLowerCase()]?.plan2[0] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan2[0],
      laborOnly: (screenRecords[accountName.toLowerCase()]?.plan2[1] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan2[1],
      laborPlus: (screenRecords[accountName.toLowerCase()]?.plan2[2] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan2[2],
      partsLabor: (screenRecords[accountName.toLowerCase()]?.plan2[3] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan2[3],
    });
  (screenRecords[accountName.toLowerCase()] !== null && screenRecords[accountName.toLowerCase()]?.plan3) && 
    coverageTypeDataAll.push({
      planBenefits: (screenRecords[accountName.toLowerCase()]?.plan3[0] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan3[0],
      laborOnly: (screenRecords[accountName.toLowerCase()]?.plan3[1] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan3[1],
      laborPlus: (screenRecords[accountName.toLowerCase()]?.plan3[2] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan3[2],
      partsLabor: (screenRecords[accountName.toLowerCase()]?.plan3[3] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan3[3],
    });
  (screenRecords[accountName.toLowerCase()] !== null && screenRecords[accountName.toLowerCase()]?.plan4) && 
    coverageTypeDataAll.push({
      planBenefits: (screenRecords[accountName.toLowerCase()]?.plan4[0] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan4[0],
      laborOnly: (screenRecords[accountName.toLowerCase()]?.plan4[1] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan4[1],
      laborPlus: (screenRecords[accountName.toLowerCase()]?.plan4[2] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan4[2],
      partsLabor: (screenRecords[accountName.toLowerCase()]?.plan4[3] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan4[3],
    });
  (screenRecords[accountName.toLowerCase()] !== null && screenRecords[accountName.toLowerCase()]?.plan5) && 
    coverageTypeDataAll.push({
      planBenefits: (screenRecords[accountName.toLowerCase()]?.plan5[0] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan5[0],
      laborOnly: (screenRecords[accountName.toLowerCase()]?.plan5[1] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan5[1],
      laborPlus: (screenRecords[accountName.toLowerCase()]?.plan5[2] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan5[2],
      partsLabor: (screenRecords[accountName.toLowerCase()]?.plan5[3] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan5[3],
    });
  (screenRecords[accountName.toLowerCase()] !== null && screenRecords[accountName.toLowerCase()]?.plan6) && 
    coverageTypeDataAll.push({
      planBenefits: (screenRecords[accountName.toLowerCase()]?.plan6[0] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan6[0],
      laborOnly: (screenRecords[accountName.toLowerCase()]?.plan6[1] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan6[1],
      laborPlus: (screenRecords[accountName.toLowerCase()]?.plan6[2] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan6[2],
      partsLabor: (screenRecords[accountName.toLowerCase()]?.plan6[3] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan6[3],
    });
  (screenRecords[accountName.toLowerCase()] !== null && screenRecords[accountName.toLowerCase()]?.plan7) && 
    coverageTypeDataAll.push({
      planBenefits: (screenRecords[accountName.toLowerCase()]?.plan7[0] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan7[0],
      laborOnly: (screenRecords[accountName.toLowerCase()]?.plan7[1] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan7[1],
      laborPlus: (screenRecords[accountName.toLowerCase()]?.plan7[2] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan7[2],
      partsLabor: (screenRecords[accountName.toLowerCase()]?.plan7[3] === "yes") ? (<YesIconComp />) : screenRecords[accountName.toLowerCase()]?.plan7[3],
    });


  return (
    <>
      {(accountName.toLowerCase() === "ge") 
        ? <p>Plan options will vary depending on the product and {getBrandContent(accountName).pricingLevelName.toLowerCase()} selected.</p>
        : <p>Plan Options will vary depending on the product and {getBrandContent(accountName).pricingLevelName.toLowerCase()} selected.</p>
      }
      
      <div className={classnames(coverageTypeTable, "m-lg-0")}>
        <BootstrapTable
          keyField="planBenefits"
          data={coverageTypeDataAll}
          columns={coverageTypeColumnsAll}
          bordered={true}
          bootstrap4
          wrapperClasses="table-responsive-lg"
        />
      </div>
      {accountName.toLowerCase() === "lennox" ? (
        <p className="mt-3 mb-0">
          *Depending on the plan purchased, benefits will vary. Limitations and
          exclusions apply. Please see the full terms and conditions for further
          details.
        </p>
      ) : (
          <p className="mt-3 mb-0">
            *Depending on the plan purchased, benefits will vary. Limitations and
            exclusions apply. Please see the full terms and conditions for further
            details.
        </p>
        )}
    </>
  );
}

export default CoverageType