import React, { useState, useCallback, useEffect, forwardRef, useRef, useImperativeHandle, useContext } from 'react';
import { CardBody, FormGroup, Row, Col } from 'reactstrap';
import styles from './VendorDetails.module.css';
import AddressFieldRow from '../shared/AddressFieldRow/AddressFieldRow';
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import ContactInformation from '../shared/ContactInformation/ContactInformation';
import MultiDropDown from '../shared/MultiDropDown/MultiDropDown';
import AddButton from '../shared/IconButtons/AddButton';
import StandardInput from "../shared/StandardInput/StandardInput";
import CheckboxInput from '../shared/CheckboxInput/CheckboxInput';
import { ReactComponent as InfoIcon } from "../../assets/images/info-icon.svg";
import ReactTooltip from 'react-tooltip';
import { countries, language } from './constant';
import CountryFormat from './vendorCountryFormat';
import Cleave from 'cleave.js/react';
import { ReactComponent as CheckIcon } from '../../assets/images/checkmark.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { SessionContext } from "../../context/SessionContext";
import classNames from "classnames";
import { DuplicateValidationModal } from "./DuplicateValidationMoal";


const defaultContact = {
    contactId: 1,
    name: "",
    phoneNumber: "",
    email: "",
    contactTypes: [],
    commPreferences: {
        emailPref: true,
        phonePref: false
    },
    contactRadio: false,
};

const emptyAddress = {
    Address: "",
    aptNum: "",
    city: "",
    State: "",
    zip: ""
};



const MAX_CONTACTS = 10;

const CheckIconComp = () => (
    <div className={classNames(styles.confirmPassCheck, "pr-2")}>
        <CheckIcon className={styles.confirmPassColor} />
    </div>
);

const CloseIconComp = () => (
    <div className={classNames(styles.confirmPassCheck, "pr-2")}>
        <CloseIcon className={styles.closePassColor} />
    </div>
);

const VendorDetails = forwardRef((props, ref) => {
    const { validator, errors } = props;
    const sessionItem = JSON.parse(sessionStorage.getItem("vendorDetails"));
    const sessionContext = useContext(SessionContext);
    const { accountName } = sessionContext;
    const account = accountName.toLowerCase();

    const [formData, setFormData] = useState({
        companyName: sessionItem?.companyName ?? "",
        dbaName: sessionItem?.dbaName ?? "",
        distributor: sessionItem?.distributor ?? false,
        dealer: sessionItem?.dealer ?? false,
        servicer: sessionItem?.servicer ?? false,
        vendorType: sessionItem?.vendorType ?? [],
        country: sessionItem?.country ?? "",
        language: sessionItem?.language ?? [],
        companyPhone: sessionItem?.companyPhone ?? "",
        companyFax: sessionItem?.companyFax ?? "",
        stateOptions: sessionItem?.stateOptions ?? [],
        clientDealerId: sessionItem?.clientDealerId ?? "",
        physicalAddress: {
            Address: sessionItem?.physicalAddress?.Address ?? "",
            aptNum: sessionItem?.physicalAddress?.aptNum ?? "",
            city: sessionItem?.physicalAddress?.city ?? "",
            State: sessionItem?.physicalAddress?.State ?? "",
            zip: sessionItem?.physicalAddress?.zip ?? "",
        },
        mailingAddress: {
            Address: sessionItem?.mailingAddress?.Address ?? "",
            aptNum: sessionItem?.mailingAddress?.aptNum ?? "",
            city: sessionItem?.mailingAddress?.city ?? "",
            State: sessionItem?.mailingAddress?.State ?? "",
            zip: sessionItem?.mailingAddress?.zip ?? "",
        },
        billingAddress: {
            Address: sessionItem?.billingAddress?.Address ?? "",
            aptNum: sessionItem?.billingAddress?.aptNum ?? "",
            city: sessionItem?.billingAddress?.city ?? "",
            State: sessionItem?.billingAddress?.State ?? "",
            zip: sessionItem?.billingAddress?.zip ?? "",
        },
        contacts: sessionItem?.contacts ?? [{ ...defaultContact, contactRadio: true }],
        phone: sessionItem?.phone ?? "",
        email: sessionItem?.email ?? "",
        reEnterEmail: sessionItem?.reEnterEmail ?? "",
        newPassword: sessionItem?.newPassword ?? "",
        reEnterNewPassword: sessionItem?.reEnterNewPassword ?? "",
        isBillingAddPhysical: sessionItem?.isBillingAddPhysical ?? true,
        isMailingAddPhysical: sessionItem?.isMailingAddPhysical ?? true,
        blocks: sessionItem?.blocks ?? [0, 3, 3, 4],
        zipLength: sessionItem?.zipLength ?? { length: 5 },
        reference: sessionItem?.reference ?? "",
        contactName: sessionItem?.contactName ?? "",
        previouslyEnteredEmail: ""
    });
    const [contacts, setContacts] = useState(formData.contacts);
    const [validationFired, setValidationFired] = useState(false);
    const [physicalContactValidationErrors, setPhysicalContactValidationErrors] = useState(false)
    const [billingContactValidationErrors, setBillingContactValidationErrors] = useState(false)
    const [mailingContactValidationErrors, setMailingContactValidationErrors] = useState(false)
    const [vendorTypeError, setVendorTypeError] = useState(validator.current.message("vendorType", formData.vendorType, "required"));
    const contactRef = useRef(null);
    const physicalAddressRef = useRef(null);
    const mailingAddressRef = useRef(null);
    const billingAddressRef = useRef(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [referenceTypeList, setReferenceTypeList] = useState([]);
    const [isMobile, setIsMobile] = useState(window.screen.width < 600);
    const [emailValidation, setEmailValidation] = useState(false);
    const [emailValidationFired, setEmailValidationFired] = useState(false);
    const featureFlags = sessionContext.getFeatureFlags();
    const [passwordLengthCheck, setPasswordLengthCheck] = useState(false);
    const [passwordNumberCheck, setPasswordNumberCheck] = useState(false);
    const [passwordCharCheck, setPasswordCharCheck] = useState(false);
    const [passwordCaseCheck, setPasswordCaseCheck] = useState(false);
    const [showValidationModal, setShowValidationModal] = useState(false);
    const [duplicateFields, setDuplicateFields] = useState([]);

    useEffect(() => {
        window.addEventListener("resize", checkMobileView);
        return () => { window.removeEventListener('resize', checkMobileView) }
    }, []);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            validator.current.showMessages();
            setVendorTypeError(validator.current.message("vendorType", formData.vendorType, "required"));
        }
        if (sessionStorage.getItem('vendorDetails')) {
            const vendorSessionData = JSON.parse(sessionStorage.getItem('vendorDetails'))
            setFormData(vendorSessionData);
        }
    }, [errors, validator]);

    useEffect(() => {
        const { email, previouslyEnteredEmail } = formData;
        const emailValid = validator.current.check(email, "email")
        if (emailValid && email !== previouslyEnteredEmail && email !== "") {
            setEmailValidation(false);
            setEmailValidationFired(false);
        }
        else if (email === "") {
            setEmailValidation(false);
        }
    }, [formData.email])

    const checkMobileView = () => setIsMobile(window.screen.width < 600);

    const validateAddress = (address) => {
        const { Address, State, city, zip } = address;
        if (Address && State && city && zip) return true;
        return false;
    }


    useImperativeHandle(ref, () => ({
        validateInputs() {
            setValidationFired(true)
            const validatePhysicalAddress = physicalAddressRef.current.validate();
            setPhysicalContactValidationErrors(!validatePhysicalAddress);
            const addressError = checkAddressError();
            const checkPhoneError = checkInputLength(formData.companyPhone);
            const checkFaxError = checkInputLength(formData.companyFax);
            const contactResult = contactRef.current?.validate();
            return !checkPhoneError && !checkFaxError && contactResult && validatePhysicalAddress && addressError && !(emailValidationFired && emailValidation);
        },
        async checkDuplicates() {
            try {
                const payload = {
                    "partyEmailId": formData.email,
                    "partyPhone": formData.companyPhone.match(/\d+/g).join(''),
                    "partyTaxId": ""
                }
                const postPayload = {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }
                var route = `party/CheckByPartyDuplicateDetails/aig`;
                let resp = await fetch(route, postPayload)
                    .then(res => res.json());

                if (resp?.response) {
                    const duplicateFieldNames = [];
                    if (resp?.response?.includes("Partyphone")) duplicateFieldNames.push("phone number")
                    if (resp?.response?.includes("PartyEmail")) duplicateFieldNames.push("email")
                    if (resp?.response?.includes("PartyTaxId")) duplicateFieldNames.push("Tax ID")
                    setShowValidationModal(true)
                    setEmailValidation(true)
                    setDuplicateFields(duplicateFieldNames)
                    return false
                }
                return true
            } catch (error) {
                console.error(error);
            }
        },
        getVendorType() {
            return formData.vendorType;
        }
    }));

    useEffect(() => {
        const getStates = async () => {
            const res = await fetch('party/states');
            if (res.status === 200) {
                const states = await res.json();

                if (states) {
                    const stateList = states.map(s => {
                        return {
                            data: s.stateId,
                            display: s.name,
                            abbreviation: s.abbreviation,
                            countryId: s.countryId
                        }
                    }).sort((a, b) => {
                        if (a.display < b.display) { return -1; }
                        if (a.display > b.display) { return 1; }
                        return 0;
                    });
                    setStateList(stateList)
                }
            }
        }

        const getReferenceTypes = async () => {
            const res = await fetch('party/referencetype');
            if (res.status === 200) {
                const referenceTypes = await res.json();

                if (referenceTypes) {
                    const types = referenceTypes.map(r => {
                        return {
                            data: r.referenceTypeId,
                            display: r.referenceTypeName,
                            channel: r.channelId,
                            contactRequired: r.contactRequired
                        }
                    });
                    setReferenceTypeList(types.filter(r => r.channel === 3));
                }
            }
        }

        getStates().catch(console.error);
        getReferenceTypes().catch(console.error);

    }, []);

    useEffect(() => {
        if (formData.country) getState(formData.country)
    }, [formData.country, stateList]);



    const getState = async (countryId) => {
        const stateOptions = stateList.filter(s => s.countryId === countryId);
        setFormData({ ...formData, stateOptions });
        sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, stateOptions }));
    };


    const handleCheckBoxChange = (e) => {
        let { id } = e.target;
        const pos = formData.vendorType.indexOf(id);
        if (pos > -1) {
            formData.vendorType.splice(pos, 1)
        }
        else {
            formData.vendorType.push(id);
        }
        formData.vendorType.length > 0 ? setVendorTypeError(false) :
            setVendorTypeError(validator.current.message("vendorType", formData.vendorType, "required"));
        sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, vendorType: formData.vendorType }));
        setFormData({
            ...formData,
            vendorType: [...formData.vendorType],
        });
    }

    const addContact = useCallback(() => {
        let contacts = formData.contacts;
        contacts.push({ ...JSON.parse(JSON.stringify(defaultContact)), contactId: contacts[contacts.length - 1].contactId + 1 });
        setFormData({ ...formData, contacts: [...contacts] });
        console.log("contacts", contacts)
        setContacts(contacts);
    }, [formData.contacts])

    const onDeleteContact = useCallback((id) => {
        let contacts = formData.contacts.filter((contact) => contact.contactId !== id);
        setFormData({ ...formData, contacts: [...contacts] });
        setContacts(contacts);
        sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, contacts: [...contacts] }));
    }, [formData.contacts])

    const onReferenceSelect = (value) => {
        setFormData({ ...formData, reference: value });
        sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, reference: value }));
        validator.current.purgeFields();
    }
    const onCountrySelect = (value) => {
        let blocks = formData.blocks;
        let zipLength = formData.zipLength
        if (value === 2) {
            blocks[0] = 1;
            zipLength.length = 6;
        }
        else {
            blocks[0] = 0;
            zipLength.length = 5;
        }

        sessionStorage.removeItem("AdCampaign");
        sessionStorage.removeItem("AdCampaignButton");
        sessionStorage.removeItem("advSessionStorage");

        setFormData({
            ...formData,
            country: value,
            blocks: blocks,
            zipLength: zipLength,
            physicalAddress: emptyAddress,
            mailingAddress: emptyAddress,
            billingAddress: emptyAddress
        });
        sessionStorage.setItem("vendorDetails", JSON.stringify({
            ...formData,
            country: value,
            blocks: blocks,
            zipLength: zipLength,
            physicalAddress: emptyAddress,
            mailingAddress: emptyAddress,
            billingAddress: emptyAddress
        }));
    }

    const onLanguageSelect = (val) => {
        setFormData({ ...formData, language: val });
        sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, language: val }));
    }

    const collectContact = (contact) => {
        let updateContact = formData.contacts.map(c => (c.contactId === contact.contactId ? Object.assign({}, c,
            {
                name: contact.contactName,
                phoneNumber: contact.contactPhone,
                email: contact.contactEmail,
                contactTypes: contact.contactTypes,
                commPreferences: contact.commPreferences
            }) : c))
        setFormData({ ...formData, contacts: [...updateContact] });
        setContacts(updateContact);
        sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, contacts: [...updateContact] }));

    }
    const handleAddressChange = (event, addressType) => {
        const { name, value } = event.target;
        if (addressType === 'Physical') {
            setFormData({ ...formData, physicalAddress: { ...formData.physicalAddress, [name]: value } });
            sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, physicalAddress: { ...formData.physicalAddress, [name]: value } }));
        } else if (addressType === 'Mailing') {
            setFormData({ ...formData, mailingAddress: { ...formData.mailingAddress, [name]: value } });
            sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, mailingAddress: { ...formData.mailingAddress, [name]: value } }));
        } else if (addressType === 'Billing') {
            setFormData({ ...formData, billingAddress: { ...formData.billingAddress, [name]: value } });
            sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, billingAddress: { ...formData.billingAddress, [name]: value } }));
        }

    }


    const MailingAddSameAsPhysical = (e) => {
        const { checked } = e.target;
        if (checked) {
            setFormData({ ...formData, isMailingAddPhysical: true, mailingAddress: formData.physicalAddress })
            sessionStorage.setItem("vendorDetails", JSON.stringify({
                ...formData,
                isMailingAddPhysical: true,
                mailingAddress: formData.physicalAddress
            }));
        } else {
            setFormData({ ...formData, isMailingAddPhysical: false, mailingAddress: [] });
            sessionStorage.setItem("vendorDetails", JSON.stringify({
                ...formData,
                isMailingAddPhysical: false,
                mailingAddress: []
            }));
        }
    }

    const checkAddressError = () => {
        const validateMailingAddress = !formData.isMailingAddPhysical ? mailingAddressRef.current.validate() : true;
        const validateBillingAddress = !formData.isBillingAddPhysical ? billingAddressRef.current.validate() : true;
        setMailingContactValidationErrors(!validateMailingAddress);
        setBillingContactValidationErrors(!validateBillingAddress);
        return validateMailingAddress && validateBillingAddress
    }

    useEffect(() => {
        if (validationFired) {
            checkAddressError();
        }
    }, [formData.mailingAddress, formData.billingAddress])

    const BillingAddSameAsPhysical = (e) => {
        const { checked } = e.target;
        if (checked) {
            setFormData({ ...formData, isBillingAddPhysical: true, billingAddress: formData.physicalAddress })
            sessionStorage.setItem("vendorDetails", JSON.stringify({
                ...formData,
                isBillingAddPhysical: true,
                billingAddress: formData.physicalAddress
            }));
        } else {
            setFormData({ ...formData, isBillingAddPhysical: false, billingAddress: [] });
            sessionStorage.setItem("vendorDetails", JSON.stringify({
                ...formData,
                isBillingAddPhysical: false,
                billingAddress: []
            }));
        }
    }


    const onSelect = useCallback((primaryContactIndex) => {
        const newContacts = formData.contacts.map((contact) => {
            if (contact.contactId === primaryContactIndex) {
                contact.contactRadio = !contact.contactRadio;
            }
            else {
                contact.contactRadio = false;
            }
            return contact;
        })
        setFormData({ ...formData, contacts: [...newContacts] });
        setContacts([...newContacts]);
        sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, contacts: [...newContacts] }));
    }, [formData.contacts])

    const checkInputLength = (input) => input && input.length < 10 ? true : false;

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (name === "email" && emailValidationFired) {
            setEmailValidationFired(false);
            !validationFired && validator.current.hideMessageFor("email");
        }
        if (name === 'companyName') {
            setFormData({
                ...formData,
                dbaName: value,
                [name]: value,
            });
        }
        else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }

        if (name === "newPassword") {
            setPasswordLengthCheck((value.length >= 8));
            setPasswordNumberCheck(((/\d/).test(value)));
            setPasswordCharCheck(((/\W/).test(value)));
            setPasswordCaseCheck(((/^(?=.*[a-z])/).test(value) && (/^(?=.*[A-Z])/).test(value)));
        }

        sessionStorage.setItem("vendorDetails", JSON.stringify({ ...formData, [name]: value }));
    };

    const toggleModal = () => {
        setShowValidationModal(!showValidationModal)
    }


    return (
        <>
            <Col xs="12" md="12" className={`titleRow ${styles.header}`}>
                <h2 className="h4 m-0">Company Information</h2>
            </Col>
            <hr className="m-0 w-100" />
            <CardBody>
                <Row className={'multiColWrapper relative '}>
                    <Col xs="12" md="4" className="col-5ths">
                        <FormGroup>
                            <StandardInput
                                hasError={validationFired && !validator.current.check(formData.companyName, "required")}
                                ariaLabelAndFieldId="companyName"
                                labelCustomClass="font-weight-bold"
                                label="Company Name"
                                fieldName="companyName"
                                value={formData.companyName}
                                onChange={(e) => handleInputChange(e)}
                                validator={validator.current.message("companyName", formData.companyName, "required")}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4" className="col-5ths">
                        <StandardInput
                            hasError={validationFired && !validator.current.check(formData.dbaName, "required")}
                            ariaLabelAndFieldId="dbaName"
                            labelCustomClass="font-weight-bold"
                            label="DBA Name"
                            fieldName="dbaName"
                            value={formData.dbaName}
                            onChange={(e) => handleInputChange(e)}
                            validator={validator.current.message("dbaName", formData.dbaName, "required",
                                { messages: { required: "DBA name is required." } }
                            )}
                        />
                    </Col>
                    <Col xs="12" md="4" className="col-5ths">
                        <FormGroup className={vendorTypeError ? "form-error" : ""}>
                            <label for="vendorType" className="form-label">Vendor Type</label>
                            <span aria-hidden="true" className="required-indicator mr-1">*</span>
                            <InfoIcon data-tip data-for='tooltip' className={`mb-1 ${styles.iconStyle}`} width={14} height={14} />
                            <ReactTooltip
                                id='tooltip'
                                tabIndex="0"
                                clickable="true"
                                aria-haspopup='true'
                                className={styles.tooltiptext}
                                effect="solid"
                                type="light"
                                place={"bottom"}
                                border={true}
                            >
                                <br className="show-md-only" aria-hidden="true" />
                                <p>Select the vendor type(s) that apply.</p>
                                <p>
                                    Distributor - means a person or entity that markets and sells HVACR equipment or AIG service contracts business-to-business (i.e., to its third-party network of Dealers or Servicers).
                                </p>
                                <p>
                                    Dealer - means a person or entity that sells eligible HVACR equipment to end users and provides installation and repair services for the equipment.
                                </p>
                                <p>
                                    Servicer - means a person or entity that performs repair services for eligible equipment.
                                </p>
                            </ReactTooltip>
                            <div className="checkbox mb-2" id="vendorType">
                                <CheckboxInput
                                    id="distributor"
                                    customClass='mr-2 mb-2'
                                    checked={formData.vendorType.includes('distributor')}
                                    handleCheck={handleCheckBoxChange}
                                    clientId={props.accountName}>
                                    Distributor
                                </CheckboxInput>
                            </div>
                            <div className="checkbox mb-2" id="vendorType">
                                <CheckboxInput
                                    id="dealer"
                                    customClass='mr-2 mb-2'
                                    checked={formData.vendorType.includes('dealer')}
                                    handleCheck={handleCheckBoxChange}
                                    clientId={props.accountName}>
                                    Dealer
                                </CheckboxInput>
                            </div>
                            <div className="checkbox mb-2" id="vendorType">
                                <CheckboxInput
                                    id="servicer"
                                    customClass='mr-2 mb-2'
                                    checked={formData.vendorType.includes('servicer')}
                                    handleCheck={handleCheckBoxChange}
                                    clientId={props.accountName}>
                                    Servicer
                                </CheckboxInput>
                            </div>
                            {validator.current.message("vendorType", formData.vendorType, "required")}
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4" className="col-5ths mt-0">
                        <FormGroup className={validationFired && !validator.current.check(formData.country, "required") ? 'form-error' : ''}>
                            <label for="country" className="form-label">Country</label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <DropDownAutoComplete
                                name="country"
                                className="form-control"
                                placeholder="Please select..."
                                aria-required="true"
                                id="country"
                                defaultValue={formData.country}
                                options={countries}
                                onChangedValue={onCountrySelect}
                                value={formData.country}
                            />
                            {validator.current.message("country", formData.country, "required")}
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4" className="col-5ths m-0">
                        <FormGroup className={validationFired && !validator.current.check(formData.language, "required") ? `form-error` : ''}>
                            <label for="language" className="form-label">Language</label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <DropDownAutoComplete
                                name="language"
                                className="form-control"
                                placeholder="Please select..."
                                aria-required="true"
                                id="language"
                                defaultValue={formData.language}
                                options={language}
                                onChangedValue={onLanguageSelect}
                                value={formData.language}
                            />
                            {validator.current.message("language", formData.language, "required")}
                        </FormGroup>
                    </Col>
                </Row>
                <Row className='multiColWrapper'>
                    <Col xs="12" md="4" className="col-5ths">
                        <CountryFormat
                            name="companyPhone"
                            label={'Company Phone'}
                            handleInputChange={handleInputChange}
                            value={formData.companyPhone}
                            validator={validator}
                            validationFired={validationFired}
                        />
                    </Col>
                    <Col xs="12" md="4" className="col-5ths">
                        <CountryFormat
                            name="companyFax"
                            required={false}
                            validate={formData.companyFax ? true : false}
                            label={'Company Fax'}
                            handleInputChange={handleInputChange}
                            value={formData.companyFax}
                            validator={validator}
                            validationFired={validationFired}
                        />
                    </Col>
                    {account === "carrier" && (formData.vendorType.includes('dealer') || formData.vendorType.includes('distributor')) && (
                        <Col xs="12" md="4" className="col-5ths">
                            <FormGroup className={validationFired && !validator.current.check(formData.clientDealerId, 'required') && 'form-error'}>
                                <label className="form-label" for="clientDealerId">
                                    Service Bench Acct. #
                                </label>
                                <span aria-hidden="true" className="required-indicator mr-1">*</span>
                                <InfoIcon data-tip data-for='servicetooltip' className={`mb-1 ${styles.iconStyle}`} width={14} height={14} />
                                <ReactTooltip
                                    id='servicetooltip'
                                    tabIndex="0"
                                    clickable="true"
                                    aria-haspopup='true'
                                    className={styles.tooltiptext}
                                    effect="solid"
                                    type="light"
                                    place={"right"}
                                    border={true}
                                >
                                    <br className="show-md-only" aria-hidden="true" />
                                    <p>To find your Service Bench Account Number log in to <a href="https://web.servicebench.com/" target='_blank'>https://web.servicebench.com/</a>.</p>
                                    <p>
                                        The Account Number is located under the Admin tab, then click the Clients link.
                                    </p>
                                </ReactTooltip>
                                <Cleave
                                    placeholder=""
                                    name="clientDealerId"
                                    className="form-control"
                                    options={{
                                        numericOnly: true
                                    }}
                                    value={formData.clientDealerId}
                                    onChange={handleInputChange}
                                />
                                {validator.current.message("serviceBenchAccountNumber", formData.clientDealerId, "required")}
                            </FormGroup>
                        </Col>
                    )}
                </Row>
                <hr className="mt-0 w-100" />
                <h2 className="h4">Address Information</h2>
                <>
                    <AddressFieldRow
                        prefix={false}
                        countryId={formData.country}
                        zipLength={formData.zipLength.length}
                        addressType="Physical"
                        onInputChange={(e) => handleAddressChange(e, "Physical")}
                        onOptionChange={(value) => handleAddressChange({ target: { name: 'State', value } }, "Physical")}
                        address={formData.physicalAddress}
                        stateOptions={formData.stateOptions}
                        ref={physicalAddressRef}
                        showContactValidationErrors={physicalContactValidationErrors}
                        addressLine1={formData.physicalAddress.Address}
                        addressLine2={formData.physicalAddress.aptNum}
                        city={formData.physicalAddress.city}
                        zipPostal={formData.physicalAddress.zip}
                        stateProvince={formData.physicalAddress.State}
                        validator={validator}
                    />
                    {formData.isMailingAddPhysical ? (
                        <>
                            <label for="mailingAddress" className="form-label">
                                Mailing Address
                            </label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <div className="checkbox" id="billingAddgress">
                                <input
                                    className="mr-2"
                                    type="checkbox"
                                    id="mailingAddressCheck"
                                    name="mailingAddress"
                                    onChange={MailingAddSameAsPhysical}
                                    checked
                                />
                                <label for="mailingAddressCheck" className={`checkboxLabel ${props.accountName}`}>
                                    Same as Physical Address
                                </label>
                            </div>
                        </>
                    ) : (
                        <>
                            <AddressFieldRow
                                prefix={false}
                                checkEnable={isMobile}
                                checkOnchange={MailingAddSameAsPhysical}
                                checkboxName="mailingAddress"
                                countryId={formData.country}
                                zipLength={formData.zipLength.length}
                                addressType="Mailing"
                                address={formData.mailingAddress}
                                onInputChange={(e) => handleAddressChange(e, "Mailing")}
                                onOptionChange={(value) => handleAddressChange({ target: { name: 'State', value } }, "Mailing")}
                                stateOptions={formData.stateOptions}
                                ref={mailingAddressRef}
                                showContactValidationErrors={mailingContactValidationErrors}
                                addressLine1={formData.mailingAddress.Address}
                                addressLine2={formData.mailingAddress.aptNum}
                                city={formData.mailingAddress.city}
                                zipPostal={formData.mailingAddress.zip}
                                stateProvince={formData.mailingAddress.State}
                                validator={validator}
                            />
                            {!isMobile && (
                                <div className="checkbox" id="billingAddress">
                                    <input
                                        className="mr-2"
                                        type="checkbox"
                                        id="mailingAddressCheck"
                                        name="mailingAddress"
                                        onChange={MailingAddSameAsPhysical}
                                    />
                                    <label for="mailingAddressCheck" className="checkboxLabel">
                                        Same as Physical Address
                                    </label>
                                </div>
                            )}
                        </>
                    )}

                    {formData.isBillingAddPhysical ? (
                        <>
                            <label for="billingAddress" className="form-label">
                                Billing Address
                            </label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <div className="checkbox" id="billingAddgress">
                                <input
                                    className="mr-2"
                                    type="checkbox"
                                    id="billingAddressCheck"
                                    name="billingAddress"
                                    onChange={BillingAddSameAsPhysical}
                                    checked
                                />
                                <label for="billingAddressCheck" className={`checkboxLabel ${props.accountName}`}>
                                    Same as Physical Address
                                </label>
                            </div>
                        </>
                    ) : (
                        <>
                            <AddressFieldRow
                                prefix={false}
                                checkEnable={isMobile}
                                checkOnchange={BillingAddSameAsPhysical}
                                checkboxName="billingAddress"
                                zipLength={formData.zipLength.length}
                                addressType="Billing"
                                address={formData.billingAddress}
                                countryId={formData.country}
                                onInputChange={(e) => handleAddressChange(e, "Billing")}
                                onOptionChange={(value) => handleAddressChange({ target: { name: 'State', value } }, "Billing")}
                                stateOptions={formData.stateOptions}
                                ref={billingAddressRef}
                                showContactValidationErrors={billingContactValidationErrors}
                                addressLine1={formData.billingAddress.Address}
                                addressLine2={formData.billingAddress.aptNum}
                                city={formData.billingAddress.city}
                                zipPostal={formData.billingAddress.zip}
                                stateProvince={formData.billingAddress.State}
                                validator={validator}

                            />
                            {!isMobile && (
                                <div className="checkbox" id="billingAddgress">
                                    <input
                                        className="mr-2"
                                        type="checkbox"
                                        id="billingAddressCheck"
                                        name="billingAddress"
                                        onChange={BillingAddSameAsPhysical}
                                    />
                                    <label for="billingAddressCheck" className="checkboxLabel">
                                        Same as Physical Address
                                    </label>
                                </div>
                            )}
                        </>
                    )}
                </>

                <hr className="mt-0 w-100" />
                <h2 className="h4">Contact Information</h2>

                <>
                    {contacts.map((contact) => (
                        <ContactInformation
                            onDeleteContact={onDeleteContact}
                            validationFired={validationFired}
                            onSelect={onSelect}
                            id={contact.contactId}
                            contact={contact}
                            handleContactChange={collectContact}
                            ref={contactRef}
                            contactRadio={contact.contactRadio}
                            contactName={contact.name}
                            contactPhone={contact.phoneNumber}
                            contactEmail={contact.email}
                            commPreferences={contact.commPreferences}
                            selectedTypes={contact.contactTypes}
                            accountName={props.accountName}
                        />
                    ))}
                    {/* 20/9/2022- commented due to show with dealers will be reverted once after that */}
                    <AddButton
                        onClick={addContact}
                        btnName="Contact"
                        disabled={contacts.length >= MAX_CONTACTS}
                        ariaLabel="Add Contact"
                    />
                </>
                <hr className=" w-100" />
                <Row className="multiColWrapper">
                    <Col xs="12" md="4" className="col-5ths">
                        <FormGroup>
                            <label for="reference" className="form-label">How did you hear about us? </label>
                            <DropDownAutoComplete
                                name="reference"
                                className="form-control"
                                placeholder="Please select..."
                                aria-required="true"
                                id="reference"
                                defaultValue={formData.reference}
                                options={referenceTypeList}
                                onChangedValue={onReferenceSelect}
                                value={formData.reference}
                            />
                        </FormGroup>
                    </Col>
                    {referenceTypeList.find(r => r.data === formData.reference)?.contactRequired &&
                        <Col xs="12" md="4" className="col-5ths">
                            <FormGroup>
                                <StandardInput
                                    formGroupClassName="mb-0"
                                    aria-required={false}
                                    required={false}
                                    hasError={false}
                                    ariaLabelAndFieldId="contactName"
                                    labelCustomClass="font-weight-bold"
                                    label="Contact Name"
                                    fieldName="contactName"
                                    value={formData.contactName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </FormGroup>
                        </Col>
                    }
                </Row>
                {featureFlags?.find(value => value.featureFlagName === "Dealer Enroll Account")?.active && <>
                    <hr className="mt-0 w-100" />
                    <h2 className="h4">Create Account</h2>
                    <p className='mb-2'>
                        Please enter a valid email address and new password to create an account.
                    </p>
                    <Row className={'multiColWrapper'}>
                        <Col className={styles.passwordEmailWrapper}>
                            <Row>
                                <Col xs="12" md="6">
                                    <FormGroup className={(validationFired || emailValidationFired) && (!validator.current.check(formData.email, "required|email") || emailValidation) ? `form-error` : ''}>
                                        <StandardInput
                                            formGroupClassName="mb-0"
                                            hasError={(validationFired || emailValidationFired) && (!validator.current.check(formData.email, "required|email") || emailValidation)}
                                            ariaLabelAndFieldId="email"
                                            labelCustomClass="font-weight-bold"
                                            label="Email"
                                            fieldName="email"
                                            value={formData.email}
                                            htmlInputType="email"
                                            onChange={(e) => handleInputChange(e)}
                                            validator={validator.current.message("email", formData.email, "required|email", { messages: { email: "Please enter a valid email." } })}
                                        />
                                        <p className='errorMsg'>
                                            {emailValidation &&
                                                "This email address is already associated with an account. Contact dealer support at 888-562-5715."
                                            }
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" >
                                    <FormGroup className={validationFired && !validator.current.check(formData.reEnterEmail, `required|in:${formData.email}`) ? `form-error` : ''}>
                                        <StandardInput
                                            formGroupClassName="mb-0"
                                            hasError={validationFired && !validator.current.check(formData.reEnterEmail, `required|in:${formData.email}`)}
                                            ariaLabelAndFieldId="reEnterEmail"
                                            labelCustomClass="font-weight-bold"
                                            label="Re-enter Email"
                                            fieldName="reEnterEmail"
                                            value={formData.reEnterEmail}
                                            onChange={(e) => handleInputChange(e)}
                                            validator={validator.current.message("reenteremail", formData.reEnterEmail, `required|in:${formData.email}`, { messages: { in: "Emails do not match.", required: "Re-enter email is required." } })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="6">
                                    <FormGroup className={validationFired && !validator.current.check(formData.newPassword, "required|min:8|valid_pass") ? `form-error` : ''}>
                                        <StandardInput
                                            formGroupClassName="mb-0"
                                            hasError={validationFired && !validator.current.check(formData.newPassword, "required|min:8|valid_pass")}
                                            ariaLabelAndFieldId="newPassword"
                                            labelCustomClass="font-weight-bold"
                                            label="New Password"
                                            fieldName="newPassword"
                                            maxLength={20}
                                            htmlInputType="password"
                                            value={formData.newPassword}
                                            onChange={(e) => handleInputChange(e)}
                                            validator={validator.current.message("newPassword", formData.newPassword, "required|min:8|valid_pass")}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="6" >
                                    <FormGroup className={validationFired && !validator.current.check(formData.reEnterNewPassword, `required|in:${formData.newPassword}`) ? `form-error` : ''}>
                                        <StandardInput
                                            formGroupClassName="mb-0"
                                            hasError={validationFired && !validator.current.check(formData.reEnterNewPassword, `required|in:${formData.newPassword}`)}
                                            ariaLabelAndFieldId="reEnterNewPassword"
                                            labelCustomClass="font-weight-bold"
                                            label="Re-enter New Password"
                                            fieldName="reEnterNewPassword"
                                            maxLength={20}
                                            htmlInputType="password"
                                            value={formData.reEnterNewPassword}
                                            onChange={(e) => handleInputChange(e)}
                                            validator={validator.current.message("password", formData.reEnterNewPassword, `required|in:${formData.newPassword}`, { messages: { in: "Passwords do not match.", required: "Re-enter new password is required." } })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='w-100 ml-3'>
                            <div className={classNames("mb-2", styles.confirmPassHeadText, styles.brandColor)}>Password Requirements</div>
                            <div className={classNames("mb-1", "d-flex")}>
                                {formData.newPassword && passwordLengthCheck ? (
                                    <CheckIconComp />
                                ) : (
                                    validationFired && <CloseIconComp />
                                )}
                                <div>At least 8 characters long</div>
                            </div>
                            <div className={classNames("mb-1", "d-flex")}>
                                {formData.newPassword && passwordNumberCheck ? (
                                    <CheckIconComp />
                                ) : (
                                    validationFired && <CloseIconComp />
                                )}
                                <div>Include one number</div>
                            </div>
                            <div className={classNames("mb-1", "d-flex")}>
                                {formData.newPassword && passwordCharCheck ? (
                                    <CheckIconComp />
                                ) : (
                                    validationFired && <CloseIconComp />
                                )}
                                <div>Include one special character</div>
                            </div>
                            <div className={classNames("mb-1", "d-flex")}>
                                {formData.newPassword && passwordCaseCheck ? (
                                    <CheckIconComp />
                                ) : (
                                    validationFired && <CloseIconComp />
                                )}
                                <div>One upper and one lower case character</div>
                            </div>
                        </Col>
                    </Row>
                </>}
                <DuplicateValidationModal
                    isOpen={showValidationModal}
                    toggle={toggleModal}
                    duplicateFields={duplicateFields} />
            </CardBody>
        </>
    );
});

export default VendorDetails