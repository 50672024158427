import React, { useEffect, useState } from "react";
import {
    FAKE_DATA,
    repairRates,
    repairCodes
} from "../fakeData/fake-assets";
import { useClaimInvoiceStore } from "../lib/state";
import { SessionContext } from "../../../context/SessionContext";
import { useContext } from "react";
import { useRef } from "react";
import Select, { components } from 'react-select';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';

export const DependentDropdown = ({
    cell,
    row,
    rowIndex,
    fieldName,
    dependentDropdownField,
    tableType,
    placeholder = 'Please Select...',
    isClearable = false,
    ...props
}) => {
    const [dropped, setDropped] = useState(false);
    const { getCurrentTabAndSubtab } = useContext(SessionContext)
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const dropDownRef = useRef()

    const [
        currentTableData,
        selectedOption,
        fieldOptions,
        updateRow,
        updateLaborRow,
        updateTripRow,
        updateLaborSelectRateTable,
        updatePartsRow,
        updatePartsMarkupRow,
        updateRefrigerantRow
    ] = useClaimInvoiceStore((state) => {

        const isDataExists = state?.[invoiceId]?.[tableType]?.data;
        
        const rowData = isDataExists ? state?.[invoiceId]?.[tableType]?.data : [row];
        const options = isDataExists ? rowData?.[rowIndex]?.[fieldName]?.options : rowData?.[0]?.[fieldName]?.options;
        const selectedOption = isDataExists ? rowData?.[rowIndex]?.[fieldName]?.selected?.filter(Boolean) : rowData?.[0]?.[fieldName]?.selected?.filter(Boolean) ;

        const fieldOptions = Array.isArray(options) ? options : [options];
        return [
            rowData,
            selectedOption,
            fieldOptions,
            state.updateRow,
            state.updateLaborRow,
            state.updateTripRow,
            state.updateLaborSelectRateTable,
            state.updatePartsRow,
            state.updatePartsMarkupRow,
            state.updateRefrigerantRow
        ];
    });

    // anytime selection changes, update options for next field.
    // in case of select rate, adjust percentages on table.
    // this fires AFTER selection state change
    useEffect(() => {
        // NOT IDEAL. Woule be better to keep everything generic and avoid specific cases,
        // especially inside formatter components and inside tables.

        if (dependentDropdownField) {
            // fetch options for next field and update row.
            const optionsKey = `${dependentDropdownField}Options`;
            const nextOptions = FAKE_DATA[dependentDropdownField][selectedOption];
            updateRow(invoiceId, tableType, rowIndex, {
                [optionsKey]: nextOptions
            });
        }
    }, [selectedOption]);

    const handleDropSelection = (value, event, selectedValue) => {
        if (tableType === 'labor') {
            let duplicateRepair = false
            if (fieldName === 'repair') {
                const isPrevSelected = currentTableData.some(e => 
                    e.asset.selected?.[0]?.assetId === value.assetId &&
                    e.repair.selected?.[0]?.display === value.display
                );
                if (isPrevSelected) {
                    dropDownRef.current.parentElement.style.boxShadow = "0px 0px 1px 1px red";
                    dropDownRef.current.focus();
                    duplicateRepair = true
                } else {
                    dropDownRef.current.parentElement.style.boxShadow = "none";
                    document.querySelectorAll("[class^='TableConfig_repairColumn']").forEach(el => el.style.boxShadow = 'none');
                    duplicateRepair = false
                }
            }

            updateLaborRow(invoiceId, tableType, rowIndex, { [fieldName]: value, showDuplicateRepairError: duplicateRepair });
            setDropped(!dropped)
            return;
        }

        if (tableType === 'laborSelectRate') {
            let duplicateRepair = false
            if (fieldName === 'repair') {
                const isPrevSelected = currentTableData.some(e => 
                    e.asset.selected?.[0]?.assetId === value.assetId &&
                    e.repair.selected?.[0]?.display === value.display
                );
                if (isPrevSelected) {
                    dropDownRef.current.parentElement.style.boxShadow = "inset 0px 0px 1px 1px red";
                    dropDownRef.current.focus();
                    duplicateRepair = true
                } else {
                    dropDownRef.current.parentElement.style.boxShadow = "none";
                    duplicateRepair = false
                }
            }
            updateLaborSelectRateTable(invoiceId, tableType, rowIndex, { [fieldName]: value, showDuplicateRepairError: duplicateRepair });
            setDropped(!dropped)
            return;
        }

        if (tableType === 'parts') {
            updatePartsRow(invoiceId, tableType, rowIndex, { [fieldName]: value });
            setDropped(!dropped)
            return;
        }

        if (tableType === 'partsMarkup') {
            updatePartsMarkupRow(invoiceId, tableType, rowIndex, { [fieldName]: value });
            setDropped(!dropped)
            return;
        }

        if (tableType === 'refrigerant') {
            updateRefrigerantRow(invoiceId, tableType, rowIndex, { [fieldName]: value });
            setDropped(!dropped)
            return;
        }

        if (tableType === 'trip') {
            updateTripRow(invoiceId, tableType, rowIndex, { [fieldName]: value });
            setDropped(!dropped)
            return;
        }
    }

    const handleOnClear = () => {
        if (tableType === 'laborSelectRate') {
            updateLaborSelectRateTable(invoiceId, tableType, rowIndex, { handleClear: true });
            return;
        }
        if (tableType === 'labor') {
            updateLaborRow(invoiceId, tableType, rowIndex, { handleClear: true });
            return;
        }
    }

    const handleSelection = (e) => {
        const { value } = e.target;
        // NOT IDEAL. Woule be better to keep everything generic and avoid specific cases,
        // especially inside formatter components and inside tables.
        if (tableType === "laborSelectRate" && fieldName === "repair") {
            // IF selectRate table AND on the repair selection step, fill the rest
            const repair = value;
            const repairCode = repairCodes[repair];
            const repairRate = repairRates[repairCode];
            updateRow(invoiceId, tableType, rowIndex, {
                repair,
                repairCode,
                repairRate: repairRate ?? 0
            });
        } else {
            updateRow(invoiceId, tableType, rowIndex, { [fieldName]: value });
        }
    };

    const keyValuePair = {
        asset: { display: 'assetName', value: 'assetId', assetId: 'assetId' },
        component: { display: 'attributeName', value: 'attributeId', assetId: 'assetId' },
        repair: { display: 'repairAction', value: 'repairActionId', assetId: 'assetId' },
        description: { display: 'attributeName', value: 'attributeId' },
        reason: {display: 'display', value: 'value'},
        refrigerantType: { display: 'display', value: 'value' },
    }

    const getUniqueListBy = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    const filteredOptions = fieldOptions?.map(e => {
        return {
            value: e?.[keyValuePair?.[fieldName]?.value],
            label: e?.[keyValuePair?.[fieldName]?.display],
            assetId: e?.[keyValuePair?.[fieldName]?.assetId]
        }
    }
    )

    const allOptions = ['asset', 'component', 'repair', 'reason', 'description', 'refrigerantType'].includes(fieldName) ? filteredOptions : fieldOptions;
    const selectedDisplayName = selectedOption?.[0]?.display ?? selectedOption?.[0]?.assetName ?? selectedOption?.[0]?.repairAction;

    const uniqueOptions = getUniqueListBy(allOptions, 'label');
    const customSelected = selectedOption?.some(e => e.hasOwnProperty('value')) ?
        selectedOption?.map(e => ({ value: e.value, label: e.display })) :
        selectedOption?.map(e => {
            return {
                value: e?.[keyValuePair?.[fieldName]?.value],
                label: e?.[keyValuePair?.[fieldName]?.display]
            }
        });
    const ControlComponent = (props) => (
        <components.Control {...props} />
    );

    const CustomClearText = () => <><CloseIcon /></>;
    const ClearIndicator = (props) => {
        const {
            children = <CustomClearText />,
            innerProps: { ref, ...restInnerProps },
        } = props;
        return (
            <div {...restInnerProps} ref={ref}>
                <div> {children}</div>
            </div>
        );
    };

    return (
        <div ref={dropDownRef}>
            <Select
                placeholder={'Please select...'}
                classNamePrefix='autoDrop'
                className={fieldName}
                isSearchable={true}
                name={fieldName}
                options={uniqueOptions?.sort((a, b) => a?.label?.localeCompare(b?.label))}
                defaultValue={customSelected}
                value={customSelected}
                isClearable={isClearable}
                onChange={(val, { action }) => {
                    if (action === 'clear') {
                        handleOnClear();
                        return;
                    }
                    handleDropSelection({
                        display: val?.label,
                        value: val?.value,
                        assetId: val?.assetId
                    })
                }}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        border: "none",
                        boxShadow: "none",
                    }),
                }}
            />
        </div>
    );
};
