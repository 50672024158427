//Need parts description 
export const calculateParts = (tempRow) => {
    const { qty: q, price: p, oem, markup } = tempRow;
    const qty = q ?? 0;
    const price = p ?? 0;
    const preTotal = parseFloat(qty) * parseFloat(price);
    return preTotal;

};

