
import React, { useState, useEffect, useContext } from "react";
import {
  Container, Card, CardBody,
} from "reactstrap";
import classNames from "classnames";
import styles from "./Logout.module.css";
import { SessionContext } from "../context/SessionContext";
import BrandedIcon from '../components/BrandingWhiteLabel/BrandedIcon';
import { getBrandContent } from "../components/BrandingWhiteLabel/BrandContent";

const Logout = () => {
  const sessionContext = useContext(SessionContext);
  const { accountName } = sessionContext;
  const clientSite = getBrandContent(accountName).clientSite;

  return (
    <Container className={"contentWrapper"}>
      <Card>
        <CardBody>
          <div className={classNames(styles.logoutContainer, "py-5")}>
            <span className={styles.logoutIcon}>
              <BrandedIcon
                accountName={accountName}
                iconType={'profile'}
              />
            </span>
            <h1 className="h1 py-2">You've been logged out.</h1>
            <p className="pb-4">
              Please visit <a className="btnLink" href={`http://${clientSite}`}>{clientSite}</a> to login.
            </p>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
}

export default Logout;