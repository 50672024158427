import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    useCallback,
} from "react";
import { Table, CardBody, CardHeader, Card } from "reactstrap";
import classNames from "classnames";
import { ContractStatus } from './ContractStatus';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';

function headerFormatter(column, index, { sortElement, filterElement }) {
    const { order } = sortElement?.props;
    return (
        <span className={`${order === "asc" ? "ascending" : ''} ${order === "desc" ? "descending" : ''}`}>
            {column.text}
        </span>
    );
}


const HistoryTab = (props) => {

    const [historyData, setHistoryData] = useState([]);

    const dateFormat = props.dealerProfile.countryId === 2 ? 'DD/MM/YYYY h:mm A' : 'MM/DD/YYYY h:mm A';

    const defaultSortedBy = [{
        dataField: "createdDate",
        order: "desc",
    }];
    const getColumns = () => {

        const columns = [
            {
                dataField: 'createdDate',
                text: 'Date',
                sort: true,
                headerFormatter: headerFormatter,
                formatter: (col) => {
                    const value = `${moment(col).format(dateFormat)}`
                    return (
                        <div>
                            <span>{value}</span>
                        </div>
                    )
                },
                sortFunc: (a, b, order) => {
                    if (order === "desc")
                        return moment(b).valueOf() - moment(a).valueOf();
                    else if (order === "asc")
                        return moment(a).valueOf() - moment(b).valueOf();
                },
            },
            {
                dataField: 'property',
                text: 'Modification',
                sort: true,
                headerFormatter: headerFormatter,
            },
            {
                dataField: 'originalValue',
                text: 'Original',
                sort: true,
                headerFormatter: headerFormatter,

            },
            {
                dataField: 'newValue',
                text: 'New Value',
                sort: true,
                headerFormatter: headerFormatter,
            },
            {
                dataField: 'createdBy',
                text: 'Modifier',
                sort: true,
                headerFormatter: headerFormatter,
            },
            {
                dataField: 'comment',
                text: 'Comment',
                sort: true,
                headerFormatter: headerFormatter,

            },
        ];
        return columns;
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const response = await fetch(`/History/GetContractHistoryLogs/${props.contract.contractId}/${props.dealerProfile.partyId}/${props.dealerProfile.countryId}/${props.clientId}`);
        const historyLogs = await response.json();

        setHistoryData(historyLogs ?? []);
    }


    return (

        <>
            <BootstrapTable
                id="queueTable"
                defaultSorted={defaultSortedBy}
                keyField='id'
                data={historyData || []}
                columns={getColumns()}
                bordered={false}
            />
        </>
    )

}
export default HistoryTab