import React, { useState, useEffect, useContext, createContext } from 'react';
import { useWhiteLabel } from './useWhiteLabel';
import { SessionContext } from '../../context/SessionContext';

const WhiteLabelAccountContext = createContext(null);

/**
 * ONLY USE BRANDS HERE THAT ARE GOING TO USE AIG STYLES OR 
 * HAVE NOT YET BEEN DESIGNED. THIS ARRAY IS USED FOR 
 * BYPASSING CSS VARIABLE REASSIGNMENT. IN OTHER WORDS, IF
 * A VALUE IS ON THIS LIST THE SITE WILL NOT RE-SKIN
 */
export const usingAIGBranding = [
  null,
  undefined,
  'aig',
  'nordyne',
  'mcc',
  'home depot'
]

const WhiteLabelProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [account, setAccount] = useState('aig');

  const session = useContext(SessionContext)

  const brandingComplete = useWhiteLabel(account); // returns bool

  useEffect(() => {
    // set clientId and resolve if data present
    if (session.accountName != null) {
      if (session.accountName !== "") {
        setAccount(session.accountName.toLowerCase());
      }
    }

    // MIGHT NEED TO WRAP THIS IN A TIMEOUT TO PREVENT CSS FLASH
    setIsLoading(false)

    return
  }, [session])

  const needsSkinning = !usingAIGBranding.includes(account) && !brandingComplete

  // if (needsSkinning || isLoading) return <div>skinning...</div>    // FOR DEBUGGING
  if (needsSkinning || isLoading) return null

  return (
    <WhiteLabelAccountContext.Provider value={{ account }}>
      {children}
    </WhiteLabelAccountContext.Provider>
  )
};

export { WhiteLabelProvider, WhiteLabelAccountContext };