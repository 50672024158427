import React from 'react';
import styles from './BottomButtons.module.css';

/**
 * A "BUTTON" WILL REQUIRE THE FOLLOWING INFO
 * leftButtons and rightButtons should be arrays of jsx elements (buttons)
 * 
 * Example: 
 *  <BottomButtons
 *      leftButtons={[
 *          <button className="btn-secondary">Click Me!</button>,
 *          <button className="btn-secondary">No, Click Me!</button>,
 *      ]}
 *      rightButtons={[
 *          <button className="btn-link">A link button</button>,
 *          <button className="btn-primary">A Primary button</button>,
 *      ]}
 *  />
 */
const BottomButtons = ({leftButtons = [], rightButtons = []}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                {leftButtons.map(b=>b)}
            </div>
            <div className={styles.right}>
                {rightButtons.map(b=>b)}
            </div>
        </div>
    )
}

export default BottomButtons;