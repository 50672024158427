const getFileTypeId = (type) => {
  let fileType = 4; // image/jpeg

  switch (type) {
    case "application/pdf":
      fileType = 1;
      break;
    case "image/png":
      fileType = 3;
      break;
  }

  return fileType
}

export const storeDocsToSessionStorage = (docs, storageBaseId) => {
  if (Array.isArray(docs)) {
    let storageIDList = [];
    let i = 1;

    docs.forEach((doc) => {
      if (doc != undefined) {
        let storageId = storageBaseId.concat(i).toString();
        storageIDList.push({ storageId });

        i++;
      }
    })

    return storageIDList;
  }

  return null;
}

export const removeUploadedFile = (index, fileUploadDetails, storageList) => {
  let uploadDetails = fileUploadDetails;

  if (Array.isArray(uploadDetails)) {
    let updatedList = uploadDetails.filter((x) => x.docFormIndex == undefined && index != undefined) ?? [];

    const docs = uploadDetails
      .filter((x) => x.docFormIndex != undefined && x.docFormIndex != index)
      .map((doc, i) => {
        updatedList.push({ ...doc, docFormIndex: i });
      });

    return updatedList;
  }

  return null;
}

export const handleFileInfo = (fileInfo, data, docFormType, docFormIndex, documentTypeId, fileUploadDetails) => {
  let uploadDetails = fileUploadDetails;

  const currentUploadDetails = {
    docFormIndex: docFormIndex,
    docFormType: docFormType,
    name: fileInfo.name,
    path: fileInfo.path,
    size: fileInfo.size,
    type: fileInfo.type,
    lastModified: fileInfo.lastModified,
    lastModifiedDate: fileInfo.lastModifiedDate,
    documentTypeId: documentTypeId,
    documentFileTypeId: getFileTypeId(fileInfo.type),
    documentName: fileInfo.name,
    documentData: data,
  };

  if (Array.isArray(fileUploadDetails)) {
    const existingFileIndex = fileUploadDetails.findIndex((x) => x.docFormIndex == docFormIndex && x.docFormType == docFormType);

    if (existingFileIndex == -1) {
      uploadDetails.push(currentUploadDetails);
    }
    else {
      uploadDetails[existingFileIndex] = currentUploadDetails;
    }

    return uploadDetails;
  }
}

