import React, { Component } from 'react';
import styles from './NavHeader.module.css';
import NavTab from './NavTab';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import { ReactComponent as HouseIcon } from '../../assets/images/house.svg';
import { ReactComponent as QueueIcon } from '../../assets/images/queue.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { SessionContext } from '../../context/SessionContext';
import MainTabContent from '../Tabs/MainTabContent';
import Contract from '../Contract/Contract';
import { Search } from '../Contract/Search';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ReportsContainer from '../Report/ReportsContainer';
import { ReportContextProvider } from '../Report/ReportsContext';
import classNames from 'classnames';
import AlertBanner from '../shared/AlertBanner/AlertBanner';
import { DealerHome } from '../../Screens/DealerHome/DealerHome';
import { OrderInfoContainer } from '../../Screens/OrderInfoContainer';
import { DealerProfile } from '../DealerProfile/DealerProfile';
import ResourceContainer from '../Resources/ResourceContainer';
import { ContactUs } from '../../Screens/ContactUs/ContactUs';


let mainNavWrapper = styles.mainNavWrapper;
let mainNav = styles.mainNav;
let navTabs = "nav-tabs";
let navTabsa = styles.navTabsa;
const homeTab = "dealerhome";

export class NavWrapper extends Component {
    constructor() {
        super();
        this.clickTest = this.clickTest.bind(this);
        this.state = {
            showBanner: true,
        }
    }

    clickTest = (tab) => {
        console.log(tab);
    }

    isActiveTab = (index, activeTab) => {
        return (index === activeTab) ? "active" : "";
    }

	render() {
		const session = this.context
		const profile = session.getProfile();

		return (
			<>
				<div className="container-fluid">
					<nav className={classNames("row", mainNavWrapper)}>
						<div className={classNames(mainNav, "nav")} role="tablist">
							<button className={styles.mainNavBtn} onClick={() => session.tabDispatch('SET_ACTIVE_TAB', { activeTab: 0 })}>
								<HouseIcon className={styles.iconHome} />
							</button>
							{Array.from(session.tabs).map((tab, index) =>
								<NavTab
									title={tab.title}
									onClick={() => session.tabDispatch('SET_ACTIVE_TAB', { activeTab: tab.tabId })}
									key={index}
									isActive={tab.tabId === session.activeTab}
									tabNumber={tab.tabId}
								/>
							)}
							<button
								className={styles.search}
								onClick={() => session.tabDispatch('ADD_TAB', {
									tabId: session.tabs.length + 1,
									title: "Plan Search",
									tabType: "search"
								})}
							>
								<SearchIcon className={styles.iconSearch} />
							</button>

                            <button 
                                className={styles.queue} 
                                onClick={() => session.tabDispatch('ADD_TAB', {
                                    tabId: session.tabs.length + 1,
                                    title: "Reports",
                                    tabType: "reports",
                                    reportType: "contracts"
                                })}
                            >
                                <QueueIcon className={styles.iconQueue} />
                            </button>
                        </div>
                    </nav >
                    {session.accountName.toLowerCase() !== 'ge' && session.alerts.map((alert, key) => (
                        <AlertBanner
                            key={key}
                            content={alert.message}
                            hasClose={alert.hasClose}
                            onClose={() => session.handleCloseAlert(alert.id)}
                            show={key == 0}
                            bannerType={alert.type}
                            linkText={alert.linkText}
                            linkClick={() => session.handleLinkClick(alert.linkDestinationTitle, alert.linkDestination, alert.linkText, alert.claimNumber, profile)}
                            hasLink={alert.hasLink &&
                                (typeof session.tabs[session.activeTab - 1] === 'undefined' 
                                    || (typeof session.tabs[session.activeTab - 1] !== 'undefined' 
                                        && session.tabs[session.activeTab - 1].title !== alert.linkDestinationTitle
                                        && session.tabs[session.activeTab - 1].tabType !== "serviceRequest"
                                    )
                                )
                            }
                        />
                    ))}
                </div>

                <MainTabContent/>
            </>
        );
    }
}

NavWrapper.contextType = SessionContext