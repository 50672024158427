import React from 'react';
import {ReactComponent as DeleteIcon} from "../../../assets/images/trash.svg"
import styles from './DeleteButton.module.css';

const DeleteButton = ({id, onClick}) => {
    // code/logic/vars here
    
    return (
        <button className={styles.button} id={id && id} onClick={onClick} >
            <DeleteIcon alt='Delete' id={id && id} className={styles.icon} />
        </button>
    )
}

export default DeleteButton;