import React, { Component } from "react";
import { Row, Col, Modal, ModalBody, ModalFooter, Label } from "reactstrap";
import styles from "./ViewCampaign.module.css";
import classNames from "classnames";
import { EditCampaignEndDate } from './EditCampaignEndDate';

export const EditEndDateModal = ({
    isOpen, toggle, centered, advantageName, selectedRow,
    errEndDateEdit, errReasonEdit, errNotesEdit, handleSaveEdit,
    endDateEdit, reasonEdit, notesEdit, chkSalesEdit,
    withEndDateEdit, updateEditDateScreen, updateEditNotes,
    updateErrEditDateScreen, updateSalesCheck, updateShowReason,
    isCanada, tableData }) => {

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            modalClassName="createCampaignModal"
            contentClassName="createCampaignModalContent"
            centered={centered}
            size="md"

        >
            <div className={styles.modalHeader}>
                <h4>Select Campaign End Date</h4>
            </div>
            <ModalBody>
                <EditCampaignEndDate
                    advantageName={advantageName}
                    selectedRow={selectedRow}
                    errEndDateEdit={errEndDateEdit}
                    errReasonEdit={errReasonEdit}
                    errNotesEdit={errNotesEdit}
                    endDateEdit={endDateEdit}
                    reasonEdit={reasonEdit}
                    notesEdit={notesEdit}
                    chkSalesEdit={chkSalesEdit}
                    withEndDateEdit={withEndDateEdit}
                    updateEditDateScreen={updateEditDateScreen}
                    updateEditNotes={updateEditNotes}
                    updateErrEditDateScreen={updateErrEditDateScreen}
                    updateSalesCheck={updateSalesCheck}
                    updateShowReason={updateShowReason}
                    isCanada={isCanada}
                    className="modal-md"
                    tableData={tableData}
                />
            </ModalBody>
            <ModalFooter>
                <div className="btnWrapper">
                    <button className="btnLink" onClick={toggle}>Cancel</button>
                    <button className="btn-primary" onClick={handleSaveEdit}>Save</button>
                </div>
            </ModalFooter>
        </Modal>
    );
}