import React, { useContext } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { SessionContext } from '../../context/SessionContext';
import styles from './BrandInformation.module.css';
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon';

const BrandInformation = () => {
    const sessionContext = useContext(SessionContext);
    const { accountName } = sessionContext;

    const ProductServiceInfo = ({ iconType, title, text }) => {
        return (
            <Col xs='12' sm='12' md='6' lg='6' className={styles.infoContainerICP}>
                <div>
                    <span className={styles.loginIconICP}>
                        <BrandedIcon accountName={accountName} iconType={iconType} />
                    </span>
                    <div>
                        <h3 className='mb-2'>{title}</h3>
                        <p className={styles.loginParagraphICP}>{text}</p>
                    </div>
                </div>
            </Col>
        );
    }

    return (
        <Container fluid className={styles.contentContainerICP}>
            <Container>
                <Row>
                    <Col sm='12' md='12' lg='6' className={styles.businessInfoContainer}>
                        <div className={`${styles.headerContainer} col-md-6 col-lg-12`}>
                            <div className={styles.colorBar}></div>
                            <h1>Amplify Your Business with Comfort Promise</h1>
                        </div>
                        <div className={`${styles.paragraphContainer} col-md-6 col-lg-12`}>
                            <p>
                                Comfort Promise Extended Service Agreements (ESAs) help grow your business without adding
                                additional equipment inventory or overhead costs. When you compliment every product offering
                                with an ESA, you can increase your average sale while building trust, relationships and referrals
                                through positive customer experiences.
                            </p>
                        </div>
                    </Col>
                    <Col sm='12' md='12' lg='6'>
                        <Row className={styles.serviceInfoContainer}>
                            <ProductServiceInfo
                                iconType='people'
                                title='Increase Profits'
                                text='Boost revenue and close rates by offering unique service options with every sale'
                            />
                            <ProductServiceInfo
                                iconType='ratings'
                                title='Build Customer Loyalty'
                                text='Gain a customer for life by building loyalty and trust through quality service'
                            />
                        </Row>
                        <Row className={styles.serviceInfoContainer}>
                            <ProductServiceInfo
                                iconType='revenuehand'
                                title='Lock in Service Revenue'
                                text='Establish guaranteed service revenue that offers competitive reimbursement rates'
                            />
                            <ProductServiceInfo
                                iconType='advantagecheck'
                                title='Enjoy Flexible Options'
                                text='Select from multiple coverage, reimbursement and sales options for you and your customer'
                            />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default BrandInformation;