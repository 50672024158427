import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import styles from "../Sales/PlanDetails/PlanDetails.module.css";

export const AdvPlanSummary = ({ isOpen, toggle, centered, onModalOk, onModalReturn, assetPackagesData, advantageProgramName }) => {
    let preferredPackages = assetPackagesData.filter(ap => ap.offers?.filter(o => o.offerId == ap.offerId && o.internalPreferred == true).length > 0);
    let updatedPreferredPackages = assetPackagesData.filter(ap => ap.offers?.filter(o => o.internalPreferred == true).length > 0
        && ap.offers?.filter(o => o.offerId == ap.offerId && o.internalPreferred != true).length > 0);
    let nonPreferredPackages = assetPackagesData.filter(ap => ap.offers?.filter(o => o.internalPreferred == true).length == 0);

    return (
        <Modal isOpen={isOpen}
            toggle={toggle}
            className="deleteCard"
            centered={centered}
            >
            <div className={styles.advPlanSummaryHeader}>
                <h4>{advantageProgramName} Plan Summary</h4>
            </div>
            <ModalBody>
                {preferredPackages.length > 0 && (
                    <>
                        <b className="mr-1">No upgrades were made to the following {advantageProgramName} plan(s).</b>
                        The plan(s) will be removed from this order and created separately using your {advantageProgramName} preferences.
                        <ul className={styles.diviceList}>
                            {preferredPackages.map((assetPackage, i) => (
                                <li>{assetPackage.name}</li>
                            ))}
                        </ul>
                    </>
                )}
                {updatedPreferredPackages.length > 0 && (
                    <>
                        <b className="mr-1">Upgrades were applied to the following {advantageProgramName} plan(s).</b>
                        Payment will be included in this order and will not be processed using your {advantageProgramName} preferences.
                        <ul className={styles.diviceList}>
                            {updatedPreferredPackages.map((assetPackage, i) => (
                                <li>{assetPackage.name}</li>
                            ))}
                        </ul>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <div className="btnWrapper">
                    <button className="btnLink" onClick={toggle}>
                        Edit Plan Details
                    </button>
                    {nonPreferredPackages.length > 0 || updatedPreferredPackages.length > 0 ?
                        <button className="btn-primary" onClick={() => onModalOk(assetPackagesData.filter(p => !preferredPackages.includes(p)))}>Continue</button> :
                        <button className="btn-primary" onClick={onModalReturn}>Return to Home</button>}
                </div>
            </ModalFooter>
        </Modal>
    );
};
