import React, { Component, Fragment } from "react";
import { Card, CardBody, Col, FormGroup, Input, Button, Row, TabContent, TabPane, Nav, NavItem, NavLink, Container } from "reactstrap";
import { Spinner } from 'react-bootstrap';

import styles from "./DealerProfile.module.css";
import classNames from "classnames";
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import { DealerContactInformation } from './DealerContactInformation';
import { ReactComponent as AddIconSVG } from '../../assets/images/Add_Icon.svg';
import { ReactComponent as PDFIcon } from '../../assets/images/pdficon.svg';
import { verifyAddress, VerificationResponseCodes } from '../../api/addressverification';
import SuggestedAddress from '../Modals/SuggestedAddress';
import InvalidAddress from '../Modals/InvalidAddress';
import SimpleReactValidator from 'simple-react-validator';
import Cleave from 'cleave.js/react';
import EditButton from "../shared/EditButton/EditButton";
import { ReactComponent as AddIcon } from '../../assets/images/plus.svg';
import { SessionContext } from "../../context/SessionContext";



let contactTyp = styles.contactTyp;
let actionButtons = styles.actionButtons;
let companyInfoRow = styles.companyInfoRow;

const defaultContactModel = {
  contactId: -1,
  name: "",
  phoneNumber: "",
  email: "",
  contactTypes: [],
  isPrimary: false,
  contactPreferences: [],
  validated: false
};

const defaultOptions = {
  contactPreferenceTypeOptions: [],
  contactTypeOptions: [],
  languageOptions: [],
  stateOptions: [],
}

const preferenceTypes = {
  sms: 1,
  email: 2
}

const MAX_CONTACTS = 10;
export class DealerProfileInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProfileTab: 1,
      inEdit: false,
      usePhysicalAddress: false,
      dealerId: 503,
      initialModel: JSON.parse(JSON.stringify(props.profileModel)),
      updatedModel: JSON.parse(JSON.stringify(props.profileModel)),
      showSuggestedAddressValidationModal: false,
      verifiedAddress: null,
      addressToVerify: null,
      showInvalidAddressModal: false,
      optionsModels: defaultOptions,
      showContactValidationErrors: false,
      saveInProgress: false,
      errorSaveUnsuccessful: false,
      primaryContactIndexCheck: null,
      duplicatePhoneField: '',
      duplicatePhoneError: false,
      duplicatePhoneId: ''
    };

    this.validator = new SimpleReactValidator({
      element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1).replace(" val2", "")}</div>,
      messages: {
        phone: 'Please enter a valid phone number.',
        required: ':attribute is required.',
        min: ':attribute is required.'
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.profileLoaded !== prevProps.profileLoaded) {
      this.filterStateOptionsByCountry();

      this.setState({
        updatedModel: JSON.parse(JSON.stringify(this.props.profileModel)),
        contacts: JSON.parse(JSON.stringify(this.props.profileModel.contacts)),
        usePhysicalAddress: this.props.profileModel.addressesAreTheSame
      });
    }
    const { getCurrentTabAndSubtab } = this.context;
    const { tab } = getCurrentTabAndSubtab();
    let { updateTab } = this.context;
    updateTab({
        tabId: tab.tabId,
        tabType: "profile",
        state: this.state
    });
  }

  filterStateOptionsByCountry = () => {
    var options = this.state.optionsModels;
    let countryId = this.props.profileModel.countryId;
    if (countryId != null) {
      options.stateOptions = options.stateOptions.filter(c => c?.countryId === countryId).sort((a, b) => {
        if (a.display < b.display) { return -1; }
        if (a.display > b.display) { return 1; }
        return 0;
      });

      this.setState({
        optionsModels: options
      });
    }

  }


  onUsePhysicalAddress = () => {
    const { usePhysicalAddress } = this.state;
    this.validator.purgeFields();
    this.setState({ usePhysicalAddress: !usePhysicalAddress });
  };

  shouldDisplayLicenseNumber = () => {
    return this.state.updatedModel.isFloridaDealer;
  }

  addContact = () => {
    let model = this.state.updatedModel;
    model.contacts.push(JSON.parse(JSON.stringify(defaultContactModel)));
    let validContacts = model.contacts.filter(x => x.contactTypes != 7);
    model.contacts = validContacts;
    console.log(defaultContactModel);

    this.setState({ updatedModel: model }, () => {
      if (model.contacts.length === 1) {
        this.onSelect(0);
      }
    });
  }

  onDeleteContact = (id) => {
    let model = this.state.updatedModel;
    model.contacts.splice(id, 1);
    this.setState({ updatedModel: model });
  }

  onSelect = (primaryContactIndex) => {
    var model = JSON.parse(JSON.stringify(this.state.updatedModel));
    var updatedContacts = model.contacts.map((c, index) => {
      c.isPrimary = primaryContactIndex == index;
      return c;
    });

    model.contacts = updatedContacts;

    this.setState({
      updatedModel: { ...model },
      primaryContactIndexCheck: primaryContactIndex
    });
  }

  checkDuplicatePhone = (input_field, field_1, field_2, value, index=null) => {
    let updatedModel = this.state.updatedModel;
    this.setState({
      duplicatePhoneField: '',
      duplicatePhoneError: false,
      duplicatePhoneId: ''
    });
    if(index !== null) {
      if(value !== "" && (value === updatedModel[field_1] || value === updatedModel[field_2])) {
        this.setState({
          duplicatePhoneField: input_field,
          duplicatePhoneError: true,
          duplicatePhoneId: index
        });
      } else {
        updatedModel.contacts.map((contact, key) => {
          if(key !== index) {
            if(value !== "" && value === contact[input_field]) {
              this.setState({
                duplicatePhoneField: input_field + "_" + index,
                duplicatePhoneError: true,
                duplicatePhoneId: index
              });
            }
          }          
        })
      }
    }
    else {
      if(value !== "" && value === updatedModel[field_1]) {
        this.setState({
          duplicatePhoneField: input_field,
          duplicatePhoneError: true
        });
      } else {
        updatedModel.contacts.map((contact, key) => {
          if(value !== "" && value === contact[field_2]) {
            this.setState({
              duplicatePhoneField: input_field,
              duplicatePhoneError: true
            });
          }
        })
      }
    }    
  }


  onOptionChange = (value, name, rawValue = "") => {

    let updatedModel = this.state.updatedModel;

    switch (name) {
      case "language":
        updatedModel.languageId = value;
        break;
      case "dealerPhysicalAddState":
      case "dealerMailingAddState":
        let addressType = name === "dealerPhysicalAddState" ? "physicalAddress" : "mailingAddress";

        if (typeof (updatedModel[addressType].stateId) != "undefined") {
          const stateOptions = this.state.optionsModels.stateOptions
          var state = stateOptions.find(({ data }) => data === value).abbreviation;

          updatedModel[addressType].stateId = value;
          updatedModel[addressType].state = state;
        }

        break;
      case "companyPhone":
        updatedModel.companyPhone = rawValue;
        this.checkDuplicatePhone("companyPhone", "companyFax", "phoneNumber", rawValue, null);
        break;
      case "companyFax":
        updatedModel.companyFax = rawValue;
        this.checkDuplicatePhone("companyFax", "companyPhone", "phoneNumber", rawValue, null);
        break;
      case "address1":
        updatedModel.physicalAddress.address1 = value;
        break;
      case "address2":
        updatedModel.physicalAddress.address2 = value;
        break;
      case "city":
        updatedModel.physicalAddress.city = value;
        break;
      case "zip":
        updatedModel.physicalAddress.zip = value;
        break;
      case "mAddress1":
        updatedModel.mailingAddress.address1 = value;
        break;
      case "mAddress2":
        updatedModel.mailingAddress.address2 = value;
        break;
      case "mCity":
        updatedModel.mailingAddress.city = value;
        break;
      case "mZip":
        updatedModel.mailingAddress.zip = value;
        break;
    }

    this.setState({
      updatedModel: updatedModel
    });
  }

  handleInputChange = e => {
    let { name, value, rawValue } = e.target;
    this.onOptionChange(value, name, rawValue);
  }

  save = () => {
    try {
      var route = `dealer/updatedealer`;
      var model = this.state.updatedModel;

      model.mailingAddress = this.state.usePhysicalAddress ? this.createCopy(model.physicalAddress) :
        model.mailingAddress;

      fetch(route, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dealerId: model.dealerId,
          companyPhone: model.companyPhone,
          companyFax: model.companyFax,
          languageId: model.languageId,
          physicalAddress: model.physicalAddress,
          mailingAddress: model.mailingAddress,
          contacts: model.contacts
        })
      }).then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            model.contacts = data.updatedContacts;
            this.props.updateDealerContacts(model.contacts);
            this.setState({
              showSuggestedAddressValidationModal: false,
              showInvalidAddressModal: false,
              inEdit: false,
              updatedModel: model,
              initialModel: this.createCopy(model),
              showContactValidationErrors: false,
              saveInProgress: false
            });
          });

        } else {
          this.setState({
            errorSaveUnsuccessful: true,
            saveInProgress: false
          });
        }
      });

    } catch (error) {
      this.setState({
        saveInProgress: false
      });
    }
  }

  componentDidMount() {
    this.pullDealerProfileOptions();
    const { getCurrentTabAndSubtab } = this.context;
    const { tab } = getCurrentTabAndSubtab();

    if(tab?.state !== undefined) {
      this.setState({ ...tab.state });
    }
  }


  pullDealerProfileOptions = async () => {
    try {
      var route = `dealer/GetDealerProfileOptions`;

      let response = await fetch(route).then(response => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(`Network response was not ok ${response.status}`);
      });

      this.setState({
        optionsModels: response
      });
      this.filterStateOptionsByCountry();

    } catch (error) {
      console.error(error);
    }
  }

  createCopy = (model) => {
    return JSON.parse(JSON.stringify(model))
  }

  onSave = () => {
    if (this.contactsValidated() && this.validator.allValid() && !this.state.duplicatePhoneError) {

      let { usePhysicalAddress, updatedModel, optionsModels } = this.state;
      var address = usePhysicalAddress ? updatedModel.physicalAddress : updatedModel.mailingAddress;

      let { stateId, address1, address2, city, zip } = address;
      let states = optionsModels.stateOptions;
      let state = states.find(state => state.data === stateId);

      let addressToVerify = {
        address1: address1,
        address2: address2,
        city: city,
        state: (state != null && state.abbreviation != null) ? state.abbreviation : "",
        zip: zip
      }

      this.setState({
        addressToVerify: addressToVerify,
        errorSaveUnsuccessful: false
      });

      verifyAddress(addressToVerify, '', this.onAddressVerified);


      console.log(addressToVerify);
    } else {
      this.setState({
        showContactValidationErrors: true
      }, () => {
        this.validator.showMessages();
        this.forceUpdate();
      });
    }
  }


  contactsValidated = () => {
    let contacts = this.state.updatedModel.contacts.filter(x => x.contactTypes != 7);
    let allContractValid = contacts.findIndex(c => c.validated == null || c.validated === false) === -1
    return allContractValid;
  }

  onAddressVerified = (result) => {
    if (result.code === VerificationResponseCodes.addressVerified) {
      this.setState({
        showSuggestedAddressValidationModal: false,
        saveInProgress: true
      }, () => this.save());
    } else if (result.code === VerificationResponseCodes.addressChanged) {
      this.setState({
        showSuggestedAddressValidationModal: true,
        verifiedAddress: result.returnedAddress
      });
    } else {
      this.setState({
        showInvalidAddressModal: true
      });
    }
  }

  onCloseAddressModal = () => {
    this.setState({
      showSuggestedAddressValidationModal: false,
      showInvalidAddressModal: false
    });
  }

  onContinueSaveAction = (selectedAddress) => {

    if (selectedAddress === "suggestedAddress") {
      let newAddress = this.state.verifiedAddress;
      var model = this.state.updatedModel;
      let states = this.state.optionsModels.stateOptions;
      let stateInfo = newAddress.state != null ? newAddress.state : newAddress.stateAbbreviation != null ? newAddress.stateAbbreviation : '';

      let selectedState = states.find(s => s.display.toUpperCase() === stateInfo.toUpperCase() ||
        s.abbreviation.toUpperCase() === stateInfo.toUpperCase());

      var addressType = this.state.usePhysicalAddress ? 'physicalAddress' : 'mailingAddress';
      model[addressType] = {
        stateId: selectedState.data,
        address1: (`${newAddress.address1} ${newAddress.suite}`).trim(),
        address2: newAddress.address2,
        city: newAddress.city,
        zip: newAddress.zip
      };

      this.setState({
        updatedModel: model,
        saveInProgress: true
      }, () => this.save());
    } else {
      this.setState({
        showSuggestedAddressValidationModal: false,
        showInvalidAddressModal: false,
        saveInProgress: true
      }, () => this.save());
    }
  }

  onContactUpdated = (field, value, index) => {
    let model = this.state.updatedModel;
    var contact = model.contacts[index];

    if (field === 'smsCommEnabled' || field === 'emailCommEnabled') {
      var commId = field === 'smsCommEnabled' ? preferenceTypes.sms : preferenceTypes.email;
      let index = contact.contactPreferences.findIndex(r => r === commId);

      if (index === -1) {
        contact.contactPreferences.push(commId);
      } else {
        contact.contactPreferences.splice(index, 1);
      }
    }
    else {
      model.contacts[index][field] = value;
      (field === "phoneNumber") && this.checkDuplicatePhone("phoneNumber", "companyPhone", "companyFax", value, index);
      model.contacts[index]['validated'] = (field === "phoneNumber") && !this.validator.check(value, "phone") ? false : true;
    }

    this.setState({ updatedModel: model });
  }

  getFormattedPhoneNumber = (phone) => {
    if (phone == null)
      return '';

    var formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formattedPhone === '' ? phone : formattedPhone;
  }

  
  onCancel = () =>{
    this.validator.hideMessages();
    this.setState({
      inEdit: false,
      updatedModel: { ...this.state.initialModel },
      primaryContactIndexCheck: null,
      showContactValidationErrors: false
    })
  }

  render() {
    let { physicalAddress, mailingAddress, companyPhone, companyFax,
      country, taxId, licenseNumber, languageId,
      distributorNames, servicer, countryId, clientDealerId
    } = this.state.updatedModel;

    let contacts = this.state.updatedModel.contacts.filter(x => x.contactTypes != 7);

    const { contactPreferenceTypeOptions,
      contactTypeOptions, languageOptions, stateOptions } = this.state.optionsModels;

    let typeOptions = contactTypeOptions.filter(o => o.value != 7);

    const { activeProfileTab, inEdit, usePhysicalAddress } = this.state;

    let primaryContactIndex = this.props.profileModel.contacts.findIndex(c => c.isPrimary != null && c.isPrimary);
    if (this.state.primaryContactIndexCheck !== null && (this.state.primaryContactIndexCheck !== primaryContactIndex)) {
      primaryContactIndex = this.state.primaryContactIndexCheck;
    }

    let languageName = "";
    if (languageOptions != null && languageOptions.length > 0 && languageId != null) {
      var language = languageOptions.find(x => x.data === languageId);
      languageName = language != null ? language.display : "";
    }

    const { accountName } = this.context;

    const duplicatePhoneErrMsg = "Please enter a valid phone number.";

    return (
      <CardBody>
        <div className={styles.companyInfoHead}>
          <h2 className="h5">Company Information</h2>
          {!inEdit && <EditButton onClick={() => this.setState({ inEdit: !inEdit })} />}
          {/* {!inEdit && <button className={styles.editProfileBtn} onClick={() => this.setState({ inEdit: !inEdit })}><img src={require("../../assets/images/Edit_Icon.svg")} />Edit</button>} */}
        </div>
        <Row className="multiColWrapper">
          <Col xs="12" md="4" lg="2" className="col-5ths">
            <FormGroup className={(!this.validator.check(companyPhone, "required|phone") && this.state.showContactValidationErrors) ? "form-error" : ""}>
              <label className="form-label" htmlFor="companyPhone">Company Phone</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              {!inEdit ?
                (<p id="companyPhone">{this.getFormattedPhoneNumber(companyPhone)}</p>) :
                <>
                  <Cleave 
                    options={{
                      blocks: [0, 3, 3, 4],
                      delimiters: ['(', ') ', '-'], 
                      numericOnly: true
                    }} 
                    className="form-control" 
                    id="companyPhone"
                    name="companyPhone" 
                    value={companyPhone} 
                    onChange={this.handleInputChange} 
                    aria-required="true"
                  />
                  {(this.state.duplicatePhoneField === "companyPhone" && this.state.duplicatePhoneError) ? 
                    <div className='errorMsg'> {duplicatePhoneErrMsg} </div> :
                    this.validator.message("companyPhone", companyPhone, "required|phone")
                  }
                </>
              }
            </FormGroup>
          </Col>
          <Col xs="12" md="4" lg="2" className="col-5ths">
            <FormGroup  className={(((!this.validator.check(companyFax, "phone")) || (this.state.duplicatePhoneField === "companyFax" && this.state.duplicatePhoneError)) && this.state.showContactValidationErrors) ? "form-error" : ""}>
            
            
              <label className="form-label" htmlFor="companyFax">Company Fax</label>
              {!inEdit ? <p id="companyFax">{this.getFormattedPhoneNumber(companyFax)}</p> :
                <>
                  <Cleave 
                    options={{
                      blocks: [0, 3, 3, 4],
                      delimiters: ['(', ') ', '-'], 
                      numericOnly: true
                    }} 
                    className="form-control" 
                    id="companyFax"
                    name="companyFax" 
                    value={companyFax} 
                    onChange={this.handleInputChange} 
                  />
                  
                  {((this.state.duplicatePhoneField === "companyFax" && this.state.duplicatePhoneError) &&
                    this.state.showContactValidationErrors)? 
                      <div className='errorMsg'>{duplicatePhoneErrMsg}</div>:
                      this.validator.message("companyFax", companyFax, "phone")
                  }
                </>
              }
            </FormGroup>
          </Col>
          <Col xs="12" md="4" lg="2" className="col-5ths">
            <FormGroup className={!this.validator.check(language, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
              <label className="form-label" htmlFor="language">Language</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              {!inEdit ? <p id="language">{languageName}</p> :
                <>
                  <DropDownAutoComplete
                    name={'language'}
                    id="language"
                    options={languageOptions}
                    onChangedValue={this.onOptionChange}
                    value={this.state.updatedModel.languageId}
                    defaultValue={this.state.updatedModel.languageId}
                  />
                  {this.validator.message("language", this.state.updatedModel.languageId, "min:1,num")}
                </>
              }
            </FormGroup>
          </Col>
          <Col xs="12" md="4" lg="2" className="col-5ths">
            <FormGroup>
              <label className="form-label" htmlFor="country">Country</label>
              <p id="country">{country}</p>
            </FormGroup>
          </Col>
          {this.shouldDisplayLicenseNumber() &&
                  <Col xs="12" md="4" lg="2" className="col-5ths">
              <FormGroup>
                <label className="form-label" htmlFor="flLicenceNumber">Florida License Number</label>
                <p id="flLicenceNumber">{licenseNumber}</p>
              </FormGroup>
            </Col>
          }
        </Row>
        <Row className="multiColWrapper">
        <Col xs="12" md="4" lg="2" className="col-5ths">
            <FormGroup>
              <label className="form-label" htmlFor="flLicenceNumber">Insured Amount</label>
              <p id="flLicenceNumber">
                {servicer != null &&
                  servicer.insuranceAmount != null &&
                  `${country.toLowerCase() === "canada" ? "CAD " : ""}${
                    servicer.insuranceAmount
                  }`}
              </p>
            </FormGroup>
          </Col>
          <Col xs="12" md="4" lg="2" className="col-5ths">
            <FormGroup>
              <label className="form-label" htmlFor="taxID">Tax ID</label>
              <p id="taxID">{taxId}</p>
            </FormGroup>
          </Col>
          {accountName.toLowerCase() === "icp" && (
            <Col xs="12" md="4" lg="2" className="col-5ths">
              <FormGroup>
                <label className="form-label" htmlFor="clientDealerId">
                  Service Bench Acct. Number
                </label>
                <p id="clientDealerId">{clientDealerId} </p>
              </FormGroup>
            </Col>
          )}
          <Col xs="12" md="4" lg="2" className="col-5ths">
            <FormGroup>
              <label className="form-label" htmlFor="certOFInsuarnce">Certificate of Insurance</label>
              <a className={styles.docIcon}>
                {/* Commented out as per SEU-6018 */}
                {/* <PDFIcon />
                                <p id="certOFInsuarnce">Document 1</p> */}
              </a>
            </FormGroup>
          </Col>
          <Col xs="12" md="4" lg="2" className="col-5ths">
            <FormGroup>
              <label className="form-label" htmlFor="distributor">Distributor</label>
              <p id="distributor">
                {
                  distributorNames != null && distributorNames.map((name, index) => {
                    return (<>
                      {`${name}${distributorNames.length - 1 > index ? ', ' : ''}`}
                    </>
                    );
                  })
                }</p>
            </FormGroup>
          </Col>
        </Row>
        <hr className="cardSectionHR" />
        <h2 className="h5">Address Information</h2>
        {!inEdit ? <Row className="multiColWrapper">
          {physicalAddress != null &&
            <Col xs="12" md="4" lg="2" className="col-5ths">
              <label className="form-label">Physical Address</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <p className={classNames(styles.contactTypeAlignment)}>
                {physicalAddress.address1}
                {physicalAddress.address2 != null && physicalAddress.address2 != '' &&
                  <><br /> {physicalAddress.address2}</>
                }
                <br /> {`${physicalAddress.city != null ? physicalAddress.city + ', ' : ''} ${physicalAddress.state != null ? physicalAddress.state : ''} ${physicalAddress.zip != null ? physicalAddress.zip : ''}`}
              </p>
            </Col>
          }

          {mailingAddress != null &&
            <Col xs="12" md="4" lg="2" className="col-5ths">
              <label className="form-label">Mailing Address</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <p className={classNames(styles.contactTypeAlignment)}>
                {mailingAddress.address1}
                {mailingAddress.address2 != null && mailingAddress.address2 != '' &&
                  <><br /> {mailingAddress.address2}</>
                }
                <br /> {`${mailingAddress.city != null ? mailingAddress.city + ', ' : ''} ${mailingAddress.state != null ? mailingAddress.state : ''} ${mailingAddress.zip != null ? mailingAddress.zip : ''}`}
              </p>
            </Col>
          }
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row> :
          <><Row className="multiColWrapper">
            <Col xs="12" md="4" lg="2" className="col-5ths">
              <FormGroup className={inEdit && this.state.showContactValidationErrors && !this.validator.check(physicalAddress.address1, "required") ? "form-error" : ""}>
                <label htmlFor="dealerPhysicalAdd" className="form-label">Physical Address</label>
                <span aria-hidden="true" className="required-indicator">*</span>
                <input id="dealerPhysicalAdd" name="address1" aria-required="true" type="text" className="form-control"
                  onChange={this.handleInputChange} value={physicalAddress.address1}></input>
                {this.validator.message("physicalAddress", physicalAddress.address1, "required")}
              </FormGroup>
            </Col>
            <Col xs="12" md="4" lg="2" className="col-5ths">
              <FormGroup>
                <label className="form-label" htmlFor="dealerPhysicalAddTwo">Address Line 2</label>
                <input type="text" className="form-control" id="dealerPhysicalAddTwo" name="address2"
                  onChange={this.handleInputChange} value={physicalAddress.address2}></input>
              </FormGroup>
            </Col>
            <Col xs="12" md="4" lg="2" className="col-5ths">
              <FormGroup className={!this.validator.check(physicalAddress.city, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                <label className="form-label" htmlFor="dealerPhysicalAddCity">City</label>
                <span aria-hidden="true" className="required-indicator">*</span>
                <input type="text" aria-required="true" name="city" className="form-control" id="dealerPhysicalAddCity"
                  onChange={this.handleInputChange} value={physicalAddress.city}></input>
                {this.validator.message("city", physicalAddress.city, "required")}

              </FormGroup>
            </Col>
            <Col xs="12" md="4" lg="2" className="col-5ths">
              <FormGroup className={!this.validator.check(this.state.updatedModel.physicalAddress.stateId, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                <label className="form-label" htmlFor="dealerPhysicalAddState">State/Province</label>
                <span aria-hidden="true" className="required-indicator">*</span>
                <DropDownAutoComplete
                  name={'dealerPhysicalAddState'}
                  id="dealerPhysicalAddState"
                  aria-required="true"
                  options={stateOptions}
                  onChangedValue={this.onOptionChange}
                  value={this.state.updatedModel.physicalAddress.stateId}
                  defaultValue={this.state.updatedModel.physicalAddress.stateId}
                />
                {this.validator.message("state", this.state.updatedModel.physicalAddress.stateId, "min:1,num")}
              </FormGroup>
            </Col>
            <Col xs="12" md="4" lg="2" className="col-5ths">
              <FormGroup className={!this.validator.check(physicalAddress.zip, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                <label className="form-label" htmlFor="physicalAddZip">Zip/Postal</label>
                <span aria-hidden="true" className="required-indicator">*</span>

                <Cleave options={{
                  blocks: countryId === 1 ? [5] : [3, 3],
                  delimiters: [" "],
                  numericOnly: countryId === 1,
                  uppercase: true
                }} type="text" className="form-control" id="physicalAddZip"
                  name="zip" onChange={this.handleInputChange} value={physicalAddress.zip} aria-required="true" />

                {this.validator.message("zip", physicalAddress.zip, "required")}

              </FormGroup>
            </Col>
          </Row>
            {usePhysicalAddress ? (<>
              <label className="form-label" htmlFor="dealerMailingAdd">Mailing Address</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <div className="checkbox mb-2">
                <input className="mr-2" type="checkbox" id="usePhysicalAddress" name="AddressCheckbox" checked onChange={this.onUsePhysicalAddress} />
                <label htmlFor="usePhysicalAddress" className={`checkboxLabel ${accountName}`}>Same as physical address</label>
              </div></>) : (<><Row className="multiColWrapper">
                <Col xs="12" md="4" lg="2" className="col-5ths">
                  <FormGroup className={!usePhysicalAddress && !this.validator.check(mailingAddress.address1, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                    <label className="form-label" htmlFor="dealerMailingAdd">Mailing Address</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <input aria-required="true" name="mAddress1" type="text" className="form-control" id="dealerMailingAdd"
                      onChange={this.handleInputChange} value={mailingAddress.address1}></input>
                    {!usePhysicalAddress && this.validator.message("mailingAddress", mailingAddress.address1, "required")}
                  </FormGroup>
                </Col>
                <Col xs="12" md="4" lg="2" className="col-5ths">
                  <FormGroup>
                    <label className="form-label" htmlFor="dealerMailingAddTwo">Address Line 2</label>
                    <input type="text" name="mAddress2" className="form-control" id="dealerMailingAddTwo"
                      onChange={this.handleInputChange} value={mailingAddress.address2}></input>
                  </FormGroup>
                </Col>
                <Col xs="12" md="4" lg="2" className="col-5ths">
                  <FormGroup className={!usePhysicalAddress && !this.validator.check(mailingAddress.city, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                    <label className="form-label" htmlFor="dealerMailingAddCity">City</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <input aria-required="true" name="mCity" type="text" className="form-control" id="dealerMailingAddCity"
                      onChange={this.handleInputChange} value={mailingAddress.city}></input>
                    {!usePhysicalAddress && this.validator.message("city val2", mailingAddress.city, "required")}
                  </FormGroup>
                </Col>
                <Col xs="12" md="4" lg="2" className="col-5ths">
                  <FormGroup className={!usePhysicalAddress && !this.validator.check(this.state.updatedModel.mailingAddress.stateId, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                    <label className="form-label" htmlFor="dealerMailingAddState">State/Province</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <DropDownAutoComplete
                      name={'dealerMailingAddState'}
                      id="dealerMailingAddState"
                      aria-required="true"
                      options={stateOptions}
                      onChangedValue={this.onOptionChange}
                      value={this.state.updatedModel.mailingAddress.stateId}
                      defaultValue={this.state.updatedModel.mailingAddress.stateId}
                    />
                    {!usePhysicalAddress && this.validator.message("state val2", this.state.updatedModel.mailingAddress.stateId, "min:1,num")}
                  </FormGroup>
                </Col>
                <Col xs="12" md="4" lg="2" className="col-5ths">
                  <FormGroup className={!usePhysicalAddress && !this.validator.check(mailingAddress.zip, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                    <label className="form-label" htmlFor="dealerMailingAddZip">Zip/Postal</label>
                    <span aria-hidden="true" className="required-indicator">*</span>

                    <Cleave options={{
                      blocks: countryId === 1 ? [5] : [3, 3],
                      delimiters: [" "],
                      numericOnly: countryId === 1,
                      uppercase: true
                    }} type="text" className="form-control" id="dealerMailingAddZip"
                      name="mZip" onChange={this.handleInputChange} value={mailingAddress.zip} aria-required="true" />


                    {!usePhysicalAddress && this.validator.message("zip val2", mailingAddress.zip, "required")}
                  </FormGroup>
                </Col>
              </Row>
                <div className="checkbox">
                  <input className="mr-2" type="checkbox" id="usePhysicalAddress" name="AddressCheckbox" onChange={this.onUsePhysicalAddress} valu="" />
                  <label htmlFor="usePhysicalAddress" className={`checkboxLabel ${accountName}`}>Same as physical address</label>
                </div></>)}
          </>}
        <hr className="cardSectionHR" />
        <h2 className="h5">Contact Information</h2>
        {!inEdit ? <Row className="multiColWrapper">
          <>
            {contacts.map((contact, key) => {
              return (
                <Col xs="12" md="4" lg="2" className="col-5ths">
                  <label className={classNames("form-label", contactTyp)}>Contact Type:
                    <p >
                      <>
                        {
                          contact.contactTypes.map((id, index) => {
                            let option = contactTypeOptions.find(p => p.value === id);

                            return (<>
                              {`${option != null && option.display != null ? option.display : ''}${contact.contactTypes.length - 1 > index ? ', ' : ''}`}
                            </>
                            );
                          })
                        }
                      </>
                    </p>

                  </label>
                  <p className={classNames(styles.contactTypeAlignment)}>
                    {`${contact.name}`}
                    {
                      contact.phoneNumber &&
                      <><br /> {`${this.getFormattedPhoneNumber(contact.phoneNumber)}`}</>
                    }
                    {
                      contact.email &&
                      <><br /> {`${contact.email}`}</>
                    }
                  </p>
                </Col>
              );
            })}

          </>
        </Row> :
          <>
            {contacts.map((contact, key) => 
              <DealerContactInformation 
                contactModel={this.createCopy(contact)} 
                selectedTypes={contact.contactTypes} 
                contactTypeOptions={typeOptions} 
                key={key} 
                id={key}
                onDeleteContact={this.onDeleteContact} 
                contactRadio={primaryContactIndex === key} 
                onSelect={this.onSelect} 
                onContactUpdated={this.onContactUpdated} 
                showValidationErrors={this.state.showContactValidationErrors} 
                duplicatePhoneError={this.state.duplicatePhoneError}
                duplicatePhoneId={this.state.duplicatePhoneId}
                duplicatePhoneErrMsg={duplicatePhoneErrMsg}
              />
            )}
            <button
              onClick={this.addContact}
              className={classNames(styles.addContactBtn, "btn-secondary iconBtn")}
              disabled={contacts.length >= MAX_CONTACTS}>
              <span className={''}><AddIcon style={{ height: '11px', width: '11px' }} /></span>
              <span>Contact</span>
            </button>

            <div className={classNames("btnWrapper", actionButtons)}>
              <button className="btnLink" onClick={this.onCancel}>Cancel</button>
              <button className="btn-primary" disabled={this.state.saveInProgress} onClick={this.onSave}>
                <Spinner color="light" size="sm" animation={this.state.saveInProgress ? "border" : ""} />
                {this.state.saveInProgress ? "" : "Save"}
              </button>
            </div>
          </>
        }
        {this.state.showSuggestedAddressValidationModal &&
          <SuggestedAddress
            showSuggestedAddressValidationModal={this.state.showSuggestedAddressValidationModal}
            enteredAddress={this.state.addressToVerify}
            suggestedAddress={this.state.verifiedAddress}
            onContinueAction={this.onContinueSaveAction}
            onEditAddress={this.onCloseAddressModal} />}

        {this.state.showInvalidAddressModal &&
          <InvalidAddress
            showInvalidAddressModal={this.state.showInvalidAddressModal}
            onEditAddress={this.onCloseAddressModal}
            onContinueAction={this.onContinueSaveAction} />}

        {this.state.errorSaveUnsuccessful &&
          <div className="validationSummary">There was an issue saving your profile. Please try again.</div>
        }

      </CardBody>


    );
  }

}

DealerProfileInformation.contextType = SessionContext