import React, { Component } from 'react';
import { Header } from '../Header';
import { NavWrapper } from './NavWrapper';

export class HeaderNavWrapper extends Component {
    render() {
        return (
            <React.Fragment>
                {/*<Header />*/}
                <NavWrapper />
            </React.Fragment>
        );
    }
}