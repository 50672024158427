import React, { Component, Fragment } from "react";
import { Card, CardBody, Col, FormGroup, Input, Button, Row } from "reactstrap";
import styles from "./AddProducts.module.css";
import classNames from "classnames";
import { DropDownAutoComplete } from "../../inputs/DropDownAutoComplete";
import { ProductTable } from "./ProductTable";
import OrderInfoContext from "../../../context/OrderInfoContext";
import VerifyProductError from "../../Modals/VerifyProductError";
import moment from "moment";
import { ReactComponent as AddIcon } from "../../../assets/images/plus.svg";
import BottomButtons from "../../shared/BottomButtonWrapper/BottomButtons";
import { ReactComponent as SearchSVG } from "../../../assets/images/search.svg";
import { ReactComponent as SerialSearchInfoSVG } from "../../../assets/images/info-i-icon.svg";
import { dataLayerPush } from '../../Global/helpers'
import { fetchBrandsByAccountId } from "../../../lib/ProductInputUtils";
import { SessionContext } from "../../../context/SessionContext";

let addBtn = styles.addBtn;
let addBtnNew = styles.addBtnNew;
let serialinfoIcon = styles.serialinfoIcon;
let infoMobile = styles.infoMobile;
let infoMedDiv = styles.infoMedDiv;
let greenCheckHeight = styles.greenCheckHeight;
let greenCheck = styles.greenCheck;

export class AddProductInputs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLennox: false,
            serialCheck: true,
            gridDisplay: true,
            errorsAddProducts: {
                serialNum: false,
                brandName: false,
                prodDescription: false,
                size: false,
                modelNum: false,
                MFGWarranty: false,
                series: false,
            },

            modelAddProducts: {
                serialNum: "",
                brandName: "",
                prodDescription: "",
                size: "",
                modelNum: "",
                MFGWarranty: "",
                serialNumBack: "",
                brandNameBack: "",
                prodDescriptionBack: "",
                MFGWarrantyBack: "",
                sizeBack: "",
                series: "",
                seriesBack: "",
                accountId: 0,
            },

            //prodDataArray: [],

            productData: {
                rowId: 0,
                serialNum: "",
                brandName: "",
                brandNameId: "",
                prodDescription: "",
                prodDescriptionId: "",
                size: "",
                modelNum: "",
                MFGWarranty: "",
                MFGWarrantyId: "",
                editOrDeleteTag: 0,
                editOrDelete: "",
                serialNumBack: "",
                brandNameBack: "",
                prodDescriptionBack: "",
                sizeBack: "",
                MFGWarranty: "",
                tErrorSerial: false,
                tErrorModel: false,
                tErrorDesc: false,
                tErrorMfg: false,
                tErrorNoPlanExist: false,
                tErrorDuplicateSerial: false,
                tErrorSeries: false,
                isChecked: true,
                isVerified: false,
                dummy: false,
                masterProgramId: "",
                planName: "",
                series: "",
                seriesBack: "",
                contractNumber: "",
                tErrorPlanDetails: false,
            },

            controlState: {
                seriesEnabled: true,
                brandEnabled: true,
                descriptionEnabled: true,
                modelEnabled: true,
                MFGWarrantyEnabled: true,
                seriesEnabled: true,
                sizeEnabled: true,
            },

            brandNameDropDown: "",
            prodDescDropDown: "",
            mfgPartsDropDown: "",
            prodDescInputValue: "",
            prodSizeDropDown: "",
            seriesDropDown: "",
            serialNum: "",
            mfgLaborDropDown: "",
            disableMFGControls: true,

            assetPackageAssets: [],
            clientBrandMappingArr: [],
            clientBrandMappingArrBack: [],
            mfgArr: [],
            mfgLaborArr: [],
            masterProgramArr: [],
            masterProgramId: "",
            assetNameArr: [],
            allAssetNameArr: [],
            allOemArr: [],
            sizeArr: [],
            assetNameArrProps: [],
            seriesArr: [
                { data: "None", display: "None", id: 1 },
                { data: "Signature", display: "Signature", id: 2 },
                { data: "Elite", display: "Elite", id: 3 },
                { data: "Merit", display: "Merit", id: 4 },
                { data: "Aire - Flo", display: "Aire - Flo", id: 5 },
            ],

            showProductNotFound: false,
            showModal: false,
            traneError: false,
            traneData: [],
            carrierError: false,
            carrierData: [],
            lennoxError: true,
            lennoxData: {
                brand: "",
                description: "",
                mfgwarranty: "",
                mfglabor: 0,
                series: "",
                modelnum: "",
            },
            shouldValidateSerial: false,

            isResidential: false,
            isSizeDisabled: true,
            sizeList: [],
            descriptionList: [],

            addDisabled: true,
            disableControls: true,
            disableBrand: true,
            disableDescription: true,
            disableModel: true,
            disableParts: true,
            disableSeries: true,
            selected: [],
            noPlanExist: false,
            duplicateSerial: false,
            contractId: "",
            installationDate: this.props.installationDate,

            selectedAssets: [
                {
                    accountId: 0,
                    assets: [],
                },
            ],

            assetList: { assetId: "", count: 0 },

            allowEdit: this.props.allowEdit,

            showAddSerialError: false,
            serialEditMode: false,
            partsOptions: [],
            laborOptions: [],
            isSavedSale: false,
            savedDescriptionsLoaded: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleProdModelInputChange =
            this.handleProdModelInputChange.bind(this);
        this.handleProdSerialInputChange =
            this.handleProdSerialInputChange.bind(this);
        this.handleProdCheckChange = this.handleProdCheckChange.bind(this);
        this.handleBrandNameDropDown = this.handleBrandNameDropDown.bind(this);
        this.handleProdBrandNameDropDown =
            this.handleProdBrandNameDropDown.bind(this);
        this.handleSizeDropDown = this.handleSizeDropDown.bind(this);
        this.handleProdDescDropDown = this.handleProdDescDropDown.bind(this);
        this.handleMfgDropDown = this.handleMfgDropDown.bind(this);
        this.handleMfgLaborDropDown = this.handleMfgLaborDropDown.bind(this);
        this.getProductionDescriptionFromProd =
            this.getProductionDescriptionFromProd.bind(this);
        this.refreshProductTable = this.refreshProductTable.bind(this);

        this.handleProdProdDescDropDown =
            this.handleProdProdDescDropDown.bind(this);
        this.handleProdMfgDropDown = this.handleProdMfgDropDown.bind(this);

        this.addProducts = this.addProducts.bind(this);
        this.updateModal = this.updateModal.bind(this);
        this.checkAvailablePlans = this.checkAvailablePlans.bind(this);
        this.handleProdSerialOnBlur = this.handleProdSerialOnBlur.bind(this);
        this.validatePlan = this.validatePlan.bind(this);

        this.enableEdit = this.enableEdit.bind(this);
        this.validateProdTable = this.validateProdTable.bind(this);

        this.isNotAvailable = this.isNotAvailable.bind(this);
    }

    async loadAssetsByAssetPackageId(assetPackageId) {
        const res = await fetch(
            "AssetPackage/getassetsbyassetpackageid/" + assetPackageId
        );
        const data = await res.json();
        this.setState({ assetPackageAssets: data });
    }

    async loadBrands() {
        var context = this.context;

        const data = await fetchBrandsByAccountId(context.dealerProfile.accountId);

        let filtCol = data.map((s) => {
            return {
                data: s.brandId,
                display: s.name,
                id: context.dealerProfile.accountId,
            };
        });

        const reged = /^[0-9]+$/g;
        const reges = /^[A-Za-z_-]/g;

        let arrNum = filtCol.filter((x) => {
            return x.display.toString().match(reged);
        });
        let arrStr = filtCol.filter((x) => {
            return x.display.toString().match(reges);
        });

        const sortBrand = (a, b) => {
            const str1 = a.display.toLowerCase();
            const str2 = b.display.toLowerCase();

            return str1.localeCompare(str2);
        };

        arrNum = arrNum.sort(sortBrand);
        arrStr = arrStr.sort(sortBrand);

        filtCol = [...arrStr, ...arrNum];

        this.setState({ clientBrandMappingArr: filtCol });
    }

    loadAccounts() {
        fetch("Account/getAllMasterProgram")
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    masterProgramArr: data.map((s) => {
                        return {
                            master: s.masterProgramId,
                            display: s.masterProgramName,
                            id: s.accountId,
                        };
                    }),
                });
            });
    }

    async loadAssetNameWithCAM() {
        const res = await fetch("Asset/GetAllClientAssetMappingWithAsset");
        const data = await res.json();

        const filtColAss = data.map((s) => {
            return {
                data: s.clientAssetMappingId,
                display: s.assetModel.assetName,
                id: s.assetId,
                master: s.masterProgramId,
            };
        });

        //const filtDataAss = [...new Map(filtColAss.map(o => [o.id, o])).values()]
        //this.setState({ allAssetNameArr: filtDataAss })

        this.setState({
            allAssetNameArr: data.map((s) => {
                return {
                    data: s.clientAssetMappingId,
                    display: s.assetModel.assetName,
                    id: s.assetId,
                    master: s.masterProgramId,
                };
            }),
        });

        this.setState({
            allOemArr: data.map((s) => {
                return {
                    data: s.clientAssetMappingId,
                    display: s.oemparts + "",
                    id: s.assetId,
                    master: s.masterProgramId,
                };
            }),
        });
    }

    handleEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.validateLennox();
        }
    }

    validateLennox = async () => {
        try {
            if (this.state.serialNum.trim().length == 0) {
                this.setState({
                    shouldValidateSerial: true,
                    lennoxError: true,
                    disableMFGControls: true,
                    prodDescDropDown: "",
                    lennoxData: {
                        brand: "",
                        description: "",
                        mfgwarranty: "",
                        mfglabor: 0,
                        series: "",
                        modelnum: "",
                    }
                });
                return;
            }

            let date = "";
            if (this.context.installationDate == undefined || this.context.installationDate == "")
                date = moment(new Date()).format("YYYY-MM-DD");
            else date = this.isCanadianDealer() ? moment(this.context.installationDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                : moment(this.context.installationDate).format("YYYY-MM-DD");

            let lennoxSerial = this.state.serialNum;
            let locArr = [...this.props.productTableData];

            let result = this.checkExistingSerialFromList(locArr, lennoxSerial, -1);
            if (result) {
                return;
            }

            const res = await fetch(
                `Vendor/validateLennox/${lennoxSerial.toUpperCase()}/${date}`
            );
            const data = await res.json();
            const serialNumber = this.state.serialNum;
            if (
                data !== undefined &&
                (data.status == "MATERIAL NOT FOUND" ||
                    data.status == 400 ||
                    data.brand == "")
            ) {
                this.setState({
                    disableControls: false,
                    lennoxError: true,
                    shouldValidateSerial: true,
                    isSizeDisabled: false,
                    addDisabled: false,
                    mfgLaborArr: this.state.laborOptions,
                    mfgLaborDropDown: 0,
                    disableMFGControls: true
                });
                this.initState(false);
            } else {
                let lennox = this.state.lennoxData;
                lennox.description = ""; // clearing data to allow updates
                //lennox.series = data.tier.replace('Series', '');
                let brand = this.state.clientBrandMappingArr.filter(
                    (o) => o.display.toLowerCase() === data.brand.toLowerCase()
                );
                if (brand.length > 0) {
                    lennox.brand = brand[0].data;
                    await this.getProductionDescription(lennox.brand);

                    let desc = this.state.assetNameArr.filter(
                        (o) => o.display.toLowerCase() === data.product_Type.toLowerCase()
                    );
                    if (desc.length > 0) lennox.description = desc[0].data;

                    if (lennox.description == "") {
                        let altDesc = this.state.allAssetDescriptions.filter(
                            (o) => o.clientAssetId.toLowerCase() === data.product_Type.toLowerCase()
                        );
                        let productData = this.state.assetNameArr;
                        if (altDesc.length > 0) {
                            lennox.description = altDesc[0].data;
                            productData.push(altDesc[0]);
                            this.setState({ assetNameArr: productData })
                        }

                    }

                    const yearText = parseInt(data.parts_Std) + parseInt(data.parts_Ext) > 1 ? ' years' : ' year';

                    let mfgw = this.state.mfgArr.filter(
                        (o) =>
                            o.display == parseInt(data.parts_Std) + parseInt(data.parts_Ext) + yearText
                    );
                    if (mfgw.length > 0) lennox.mfgwarranty = mfgw[0].data;
                }

                let seriesList = this.state.seriesArr;
                let series = seriesList.find(
                    (x) => data.tier.toLowerCase().indexOf(x.data.toLowerCase()) > -1
                );
                let seriesName = "None";
                if (series != null) seriesName = series.data;

                const CLIPRes = await fetch(
                    `contract/GetCLIPLaborTerm/${lennoxSerial.toUpperCase()}`
                );
                const CLIPLaborTerm = await CLIPRes.json();

                lennox.mfglabor = CLIPLaborTerm;
                this.setState({
                    brandNameDropDown: brand[0].data,
                    prodDescDropDown: lennox.description,
                    mfgPartsDropDown: lennox.mfgwarranty,
                    mfgLaborDropDown: lennox.mfglabor,
                    seriesDropDown: seriesName,
                    modelNum: data.model,
                    lennoxError: false,
                    disableControls: true,
                    disableBrand: this.isNotAvailable(brand[0].data, "brand"),
                    disableModel: this.isNotAvailable(data.model, "model"),
                    disableDescription: this.isNotAvailable(lennox.description, "description"),
                    disableParts: this.isNotAvailable(lennox.mfgwarranty, "parts"),
                    disableSeries: this.isNotAvailable(seriesName, "series"),
                    shouldValidateSerial: true,
                    isSizeDisabled: false,
                    addDisabled: false
                });
                this.handleBrandNameDropDown(brand[0].data);
                this.handleProdDescDropDown(
                    lennox.description,
                    this.context.installationType
                );
                this.handleMfgDropDown(lennox.mfgwarranty);
                this.handleMfgLaborDropDown(lennox.mfglabor);
                this.handleInput("modelNum", data.model);
                this.handleSeriesDropDown(seriesName);
                if (this.context.installationType == "Commercial")
                    this.handleSizeDropDown("");
            }
            return true;
        } catch (error) {
            //alert("validate" + error);
            console.log(error);
            this.setState({
                lennoxError: true,
                shouldValidateSerial: true,
                disableControls: false,
            });
            return false;
        }
    };

    isNotAvailable = (field, name) => {
        const wholeString = JSON.stringify(field).replace('""', '');
        let response = false;


        if (typeof wholeString === 'string' && wholeString && wholeString.length !== 0) {
            response = true;
        }
        else {
            response = false;
        }

        console.log("Available", name, field, wholeString, typeof wholeString === 'string', wholeString.length !== 0, wholeString.length)

        return response;
    }

    validateCarrier = async (serialNumber, model) => {
        try {
            this.setState({ disableControls: true });

            const res = await fetch(
                `Vendor/validateCarrier/${serialNumber}/${model}`
            );
            const data = await res.json();

            if (data !== undefined) {
                this.setState({ carrierError: false });

                let obj = {};
                let arr = [...this.state.carrierData];

                obj.warrantyInfo = data.warrantyInfo;
                obj.model = data.model;
                obj.serialNumber = data.serial;
                obj.validUnit = data.validUnit;
                obj.errorMsg = data.msgStatus;

                if (arr.length === 0) {
                    arr.push(obj);
                    this.setState({ carrierData: arr });
                } else {
                    let newData = arr.map((obj, index) => {
                        if (index == 0)
                            return Object.assign({}, obj, {
                                warrantyInfo: data.warrantyInfo,
                                model: data.model,
                                serialNumber: data.serialNumber,
                                validUnit: data.validUnit,
                            });
                        return obj;
                    });

                    this.setState({ carrierData: newData });
                }
                this.setState({ disableControls: false });

                return true;
            }
        } catch (error) {
            console.log(error);
            this.setState({ carrierError: true });
            this.setState({ disableControls: false });
            return false;
        }
    };

    //async validateTrane(serialNumber) {
    validateTrane = async (serialNumber) => {
        this.setState({ disableControls: true });
        this.props.checkIfCanContinue(false);

        try {
            const res = await fetch(`Vendor/validateTrane/${serialNumber}`);
            const data = await res.json();

            if (data !== undefined) {
                this.setState({ traneError: false });

                let obj = {};
                let arr = [...this.state.traneData];

                obj.brand = data.brand;
                obj.description = data.description;
                obj.model = data.model;
                obj.serialNumber = data.serialNumber;
                obj.validUnit = data.validUnit;
                obj.validationCode = data.validationCodebrand;

                if (arr.length === 0) {
                    arr.push(obj);
                    this.setState({ traneData: arr });
                } else {
                    let newData = arr.map((obj, index) => {
                        if (index == 0)
                            return Object.assign({}, obj, {
                                brand: data.brand,
                                description: data.description,
                                model: data.model,
                                serialNumber: data.serialNumber,
                                valideUnit: data.validUnit,
                                validationCode: data.validationCodebrand,
                            });
                        return obj;
                    });

                    this.setState({ traneData: newData });
                }
                this.setState({ disableControls: false });
                this.props.checkIfCanContinue(true);
                return true;
            }
        } catch (error) {
            console.log(error);
            this.setState({ traneError: true });
            this.setState({ disableControls: false });
            this.props.checkIfCanContinue(true);

            return false;
        }
    };

    componentWillMount() {
        this.setState({
            isLennox: this.context.dealerProfile.isLennoxDealer,
        });
    }

    componentDidMount() {
        this.loadBrands();
        this.loadAccounts();
        this.loadAssetNameWithCAM();
        this.loadWarrantyOptions();

        if (this.isLennoxLayout() == true) {
            this.getSizeList("");
            this.setState({ disableControls: true });
        } else {
            this.setState({ disableControls: false, addDisabled: false });
        }

        if (this.props.assetPackageId != undefined) {
            this.setState({ shouldValidateSerial: false, gridDisplay: false });
            this.loadAssetsByAssetPackageId(this.props.packageId);
        }

        if (this.state.allowEdit) {
            this.enableEdit();
        }

        this.setState({ isListLoaded: true });

        if(this.props.prodDescDropDown !== "") {
            this.setState({ 
                prodDescDropDown: this.props.prodDescDropDown, 
                showProductNotFound: false,
                modelAddProducts: {
                    prodDescription: this.props.prodDescDropDown
                }
            });
        }
        if(this.props.mfgPartsDropDown !== "") {
            this.setState({ 
                mfgPartsDropDown: this.props.mfgPartsDropDown, 
                showProductNotFound: false,                
                modelAddProducts: {
                    MFGWarranty: this.props.mfgPartsDropDown
                }
            });
        }
        if(this.props.mfgLaborDropDown !== "") {
            this.setState({ 
                mfgLaborDropDown: this.props.mfgLaborDropDown,                
                modelAddProducts: {
                    MFGLabor: this.props.mfgLaborDropDown
                }
            });
        }
        if(this.props.seriesDropDown !== "") {
            this.setState({ 
                seriesDropDown: this.props.seriesDropDown,
                modelAddProducts: {
                    series: this.props.seriesDropDown
                }
            });
        }
        if(this.props.brandNameDropDown !== "") {
            this.setState({ 
                brandNameDropDown: this.props.brandNameDropDown, 
                showProductNotFound: false,
                modelAddProducts: {
                    brandName: this.props.brandNameDropDown
                }
            });
        }
        if(this.props.sizeDropDown !== "") {
            this.setState({ 
                sizeDropDown: this.props.sizeDropDown,
                modelAddProducts: {
                    size: this.props.sizeDropDown
                }
            });
        }
        if(this.props.serialNum !== 0) {
            this.setState({ 
                serialNum: this.props.serialNum,
                modelAddProducts: {
                    size: this.props.sizeDropDown
                }
            });
        }
    }

    enableEdit = () => {
        let prods = this.props.productTableData;

        for (var i = 0; i < prods.length; i++) {

            var isInvalid = false;

            if (prods[i].isFromOEM) {
                if (prods[i].tErrorMfg) {
                    isInvalid = true;
                }
                if (prods[i].tErrorDesc) {
                    isInvalid = true;
                }
                if (prods[i].tErrorModel) {
                    isInvalid = true;
                }
                if (prods[i].tErrorSerial) {
                    isInvalid = true;
                }
            }

            if (isInvalid) {
                var rowClicked = { rowIndex: i };
                this.handleEdit(rowClicked, 1);
            }
        }
    }

    validateProdTable = (prodData) => {
        let prods = prodData;
        for (var i = 0; i < prods.length; i++) {

            var isInvalid = false;

            if (prods[i].isFromOEM) {
                if (prods[i].prodDescription == "" || prods[i].prodDescriptionBack == "") {
                    prods[i].tErrorDesc = true;
                    isInvalid = true;
                    prods[i].editOrDeleteTag = 1
                }
                if (prods[i].MFGWarranty == "" || prods[i].MFGWarrantyBack == "" || prods[i].MFGWarrantyId == "") {
                    prods[i].tErrorMfg = true;
                    isInvalid = true;
                    prods[i].editOrDeleteTag = 1
                }
                if (prods[i].modelNum == "") {
                    prods[i].tErrorModel = true;
                    isInvalid = true;
                    prods[i].editOrDeleteTag = 1
                }
                if (prods[i].serial == "" || prods[i].serialNumBack == "") {
                    prods[i].tErrorSerial = true;
                    isInvalid = true;
                    prods[i].editOrDeleteTag = 1
                }
                if (prods[i].brandName == "" || prods[i].brandNameBack == "" || prods[i].brandNameId == "") {
                    prods[i].tErrorBrand = true;
                    isInvalid = true;
                    prods[i].editOrDeleteTag = 1
                }
            }

            if (isInvalid) {
                var rowClicked = { rowIndex: i }
                prods[i].isVerified = false;
                this.setState({ allowEdit: true })

            }
        }

        this.props.updateProductTable(prods);

        return prods;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.productTableData != prevProps.productTableData || this.state != prevState) {
            let prodDataValues = [...this.props.productTableData];
            if (this.state.clientBrandMappingArr != "" && this.context.dealerProfile.masterProgramId != ""
                && this.state.partsOptions != null && prodDataValues.length > 0 && prodDataValues[0].isFromOEM && !prodDataValues[0].isOEMAddProdUpd) {
                //var brandSelected = this.state.clientBrandMappingArr.find(s => s.display === prodDataValues.find(p => p.brandName != "")?.brandName);
                //if (brandSelected != null) {
                //    this.getProductionDescription(brandSelected.brandId);
                //}
                this.getProductionDescription("");
                if (this.state.allAssetDescriptions != null && this.state.allAssetDescriptions.length > 0
                    && this.state.mfgArr.length > 0) {
                    for (var itemProd of prodDataValues) {
                        var brand = this.state.clientBrandMappingArr.find(b => b.display === itemProd.brandName);
                        itemProd.brandNameId = brand?.data;

                        var assetSelected = this.state.allAssetDescriptions.find(s => s.clientAssetId === itemProd.categoryId);
                        if (assetSelected != null) {
                            itemProd.prodDescription = assetSelected.display;
                            itemProd.prodDescriptionBack = assetSelected.display;
                            itemProd.prodDescriptionId = assetSelected.data;
                            itemProd.tErrorDesc = false;
                        }

                        let mfgSelected = this.state.mfgArr.find(s => s.data == itemProd.oemWarrantyParts);
                        if (mfgSelected != null) {
                            itemProd.MFGWarranty = mfgSelected.display;
                            itemProd.MFGWarrantyBack = mfgSelected.display;
                            itemProd.MFGWarrantyId = mfgSelected.data;
                            itemProd.tErrorMfg = false;
                        }

                        itemProd.isOEMAddProdUpd = true;
                    }
                    this.validateProdTable(prodDataValues);
                }
            }
        }
    }

    isCanadianDealer = () => {
        return this.context.dealerProfile.countryId == 2
    }

    refreshProductTable = (rowId, blnValue) => {
        let sArr = [...this.props.productTableData];
        let newData = sArr.map((obj, index) => {
            if (index == rowId)
                return Object.assign({}, obj, {
                    dummy: !blnValue,
                    masterProgramId: "",
                });
            return obj;
        });

        //this.setState({ prodDataArray: newData });
        this.props.updateProductTable(newData);
        return;
    };

    async getProductionDescriptionFromProd(assetId, rowId, blnDummy) {
        const resC = await fetch("Brand/getAllClientBrandMapping");
        const dataC = await resC.json();
        const asset = dataC.find((obj) => obj.clientBrandMappingId == assetId);
        const resA = await fetch("Account/getAllMasterProgram");
        const dataA = await resA.json();
        const masterId = dataA.find((obj) => obj.accountId == asset.accountId);

        if (masterId !== undefined) {
            const res = await fetch(
                "Asset/GetAssetsByMasterProgramId/" + masterId.masterProgramId
            );
            const data = await res.json();

            this.setState({
                assetNameArrProps: data.map((s) => {
                    return {
                        data: s.clientAssetMappingId,
                        display: s.assetModel.assetName,
                        id: s.assetId,
                    };
                }),
            });

            let sArr = [...this.props.productTableData];
            let newData = sArr.map((obj, index) => {
                if (index == rowId)
                    return Object.assign({}, obj, {
                        dummy: !blnDummy,
                        masterProgramId: masterId.masterProgramId,
                    });
                return obj;
            });

            //this.setState({ prodDataArray: newData });
            this.props.updateProductTable(newData);
        } else {
            //this.setState({ allAssetNameArr: "" });
            this.setState({ assetNameArrProps: "" });
            this.refreshProductTable(rowId, !blnDummy);
        }
    }

    loadWarrantyOptions = () => {
        var dealerProfile = this.context.dealerProfile;
        let accountId = dealerProfile.accountId;

        fetch("Account/getWarrantyOptions/" + accountId).then((res) => {
            if (res.ok) {
                res.json().then((warrantyOptions) => {
                    const filtColMfg = warrantyOptions.partsOptions.map((s) => {
                        return {
                            data: s,
                            display: s + "",
                        };
                    });
                    const filtColMfgLabor = warrantyOptions.laborOptions.map((s) => {
                        return {
                            data: s,
                            display: s + "",
                        };
                    });

                    const sortedMfg = filtColMfg.sort(
                        (a, b) => parseFloat(a.display) - parseFloat(b.display)
                    );

                    const sortedMfgLabor = filtColMfgLabor.sort(
                        (a, b) => parseFloat(a.display) - parseFloat(b.display)
                    );

                    this.setState({
                        partsOptions: sortedMfg,
                        laborOptions: sortedMfgLabor
                    });
                });
            }
        });
    };
    async getProductionDescription(assetId) {
        var context = this.context;
        var dealerProfile = context.dealerProfile;
        let masterId = dealerProfile.masterProgramId;
        //console.log(context, dealerProfile, masterId)
        if (masterId != undefined) {
            const res = await fetch("Asset/GetAssetsByMasterProgramId/" + masterId);
            let data = await res.json();
            //console.log("Show me data", data, this.state)
            if (this.state.assetPackageAssets.length > 0) {
                let filteredProducts = [];
                for (var index = 0; index < data.length; index++) {
                    var option = this.state.assetPackageAssets.filter(
                        (a) => a.assetId == data[index].assetId && a.maxAllowed > this.props.assetPackage.assetIds.filter(ai => ai === a.assetId).length
                    );
                    if (option.length != 0) {
                        filteredProducts.push(data[index]);
                    }
                }



                data = filteredProducts;
            }

            const filtColAss = data.map((s) => {
                return {
                    data: s.clientAssetMappingId,
                    display: s.assetModel.assetName,
                    id: s.assetId,
                    clientAssetId: s.clientAssetId,
                };
            });
            const filtDataAss = [
                ...new Map(filtColAss.map((o) => [o.id, o])).values(),
            ];
            const sorted = filtDataAss.sort((a, b) =>
                a.display.localeCompare(b.display)
            );

            if (this.state.isLennox && data.length > 0 && this.state.prodDescDropDown != "") {

                const descriptionInSorted = sorted.find((obj) => obj.data == this.state.prodDescDropDown)

                if (typeof descriptionInSorted != undefined) {

                    let description = filtColAss.find((obj) => obj.data == this.state.prodDescDropDown);

                    if (typeof description != "undefined") {
                        sorted.push(description);
                    }
                }
            }

            //console.log(sorted, filtDataAss, filtColAss, data)
            this.setState({
                mfgArr: this.state.partsOptions,
                mfgLaborArr: this.state.laborOptions,
                masterProgramId: masterId,
                assetNameArr: sorted,
                allAssetDescriptions: filtColAss
            });
        } else {
            this.setState({ prodDescDropDown: "" });
            this.setState({ mfgArr: "" });
        }
    }

    getSizeListPerRow = (rowIndex, clientAssetMappingId) => {
        fetch("Asset/Size/" + clientAssetMappingId)
            .then((res) => res.json())
            .then((data) => {

                let sizeList = data.map((s) => {
                    return {
                        data: s.value + " " + s.attribute.attributeName,
                        display: s.value + " " + s.attribute.attributeName,
                        id: s.clientAssetConfigurationId,
                        sizeId: s.value,
                        sizeType: s.attribute.attributeId
                    };
                });

                let products = [...this.props.productTableData];
                let newData = products.map((obj, index) => {
                    if (index == rowIndex)
                        return Object.assign({}, obj, {
                            sizeList: sizeList,
                            size: "",
                            sizeBack: "",
                            tErrorSize: true,
                        });
                    return obj;
                });
                this.props.updateProductTable(newData);
            })
            .catch((error) => {
            });
    };

    getSizeList = (clientAssetMappingId) => {

        fetch("Asset/Size/" + clientAssetMappingId)
            .then((res) => res.json())
            .then((data) => {
                let sizes = data.map((s) => {
                    return {
                        data: s.value + " " + s.attribute.attributeName,
                        display: s.value + " " + s.attribute.attributeName,
                        id: s.clientAssetConfigurationId,
                        sizeId: s.value,
                        sizeType: s.attribute.attributeId
                    };
                });

                if (this.isLennoxLayout() == true)
                    sizes.splice(0, 0, { data: "None", display: "None", id: 0 });

                this.setState({
                    sizeList: sizes,
                });

                let blnErr = false;
                this.setState((prevState) => ({
                    productData: {
                        ...prevState.productData,
                        size: "",
                    },
                }));

                this.setState((prevState) => ({
                    errorsAddProducts: {
                        ...prevState.errorsAddProducts,
                        size: blnErr,
                    },
                }));
            })
            .catch((error) => {
            });
    };

    enableContinue() {
        let err = [...this.props.productTableData];

        if (err.length === 1) {
            return false;
        }

        return true;
    }

    handleProdBrandNameDropDown = (rowId, value) => {
        let sArr = [...this.props.productTableData];
        const name = this.getDropdownDescription("brand", value);
        let newData = sArr.map((obj, index) => {
            if (index == rowId)
                return Object.assign({}, obj, {
                    brandName: name,
                    brandNameBack: name,
                    brandNameId: value,
                    tErrorBrand: false
                    //prodDescription: "",
                    //prodDescriptionBack: "",
                    //prodDescriptionId: "",
                    //MFGWarranty: "",
                    //MFGWarrantyBack: "",
                    //MFGWarrantyId: "",
                    //tErrorDesc: true,
                    //tErrorMfg: true,
                });
            return obj;
        });

        //this.setState({ prodDataArray: newData });
        this.props.updateProductTable(newData);

        return;
    };

    handleProdProdDescDropDown = (rowId, value, name, error) => {
        let sArr = [...this.props.productTableData];

        let newData = sArr.map((obj, index) => {
            if (index == rowId)
                return Object.assign({}, obj, {
                    prodDescription: name,
                    prodDescriptionBack: name,
                    prodDescriptionId: value,
                    tErrorDesc: error,
                });
            return obj;
        });

        //this.setState({ prodDataArray: newData });
        this.props.updateProductTable(newData);
        this.getSizeListPerRow(rowId, value);

        return;
    };

    handleProdSizeDropDown = (rowId, value) => {
        let sArr = [...this.props.productTableData];
        //const name = this.getDropdownDescription("size", value);
        let newData = sArr.map((obj, index) => {
            if (index == rowId)
                return Object.assign({}, obj, {
                    size: value,
                    sizeBack: value,
                    tErrorSize: false,
                });
            return obj;
        });

        //this.setState({ prodDataArray: newData });
        this.props.updateProductTable(newData);

        return;
    };

    handleProdSeriesDropDown = (rowId, value) => {
        let sArr = [...this.props.productTableData];
        let newData = sArr.map((obj, index) => {
            if (index == rowId)
                return Object.assign({}, obj, {
                    series: value,
                    seriesBack: value,
                    tErrorSeries: false,
                });
            return obj;
        });

        this.props.updateProductTable(newData);

        return;
    };

    handleProdMfgDropDown = (rowId, value, name, error) => {
        let sArr = [...this.props.productTableData];

        let newData = sArr.map((obj, index) => {
            if (index == rowId)
                return Object.assign({}, obj, {
                    MFGWarranty: name,
                    MFGWarrantyBack: name,
                    MFGWarrantyId: value,
                    tErrorMfg: error,
                });
            return obj;
        });

        this.setState({ prodDataArray: newData });
        this.props.updateProductTable(newData);

        return;
    };

    handleProdCheckChange = (rowId, value, updateType) => {
        switch (updateType) {
            case "SINGLE":
                let sArr = [...this.props.productTableData];

                let newData1 = sArr.map((obj, index) => {
                    if (index == rowId)
                        return Object.assign({}, obj, { isChecked: value });
                    return obj;
                });

                this.props.updateProductTable(newData1);

                for (let i = 0; i < newData1.length; i++) {
                    const chk = newData1[i];
                    if (chk.isChecked === true) {
                        this.props.checkIfCanContinue(true);
                        return;
                    }
                }

                this.props.checkIfCanContinue(false);
                return;

            case "ALL":
                let aArr = [...this.props.productTableData];

                let newData2 = aArr.map((obj, index) => {
                    //if (index == rowId)
                    return Object.assign({}, obj, { isChecked: value });
                    return obj;
                });

                //this.setState({ prodDataArray: newData2 });
                this.props.updateProductTable(newData2);

                return;
        }
    };

    handleProdModelInputChange = (rowId, value, error) => {
        let locArr = [...this.props.productTableData];

        let newData = locArr.map((obj, index) => {
            if (index == rowId)
                return Object.assign({}, obj, {
                    modelNum: value,
                    errorModel: error,
                    tErrorModel: error,
                });
            return obj;
        });

        //this.setState({ prodDataArray: newData });
        this.props.updateProductTable(newData);
    };

    handleProdSerialInputChange = (rowId, value, error) => {
        let locArr = [...this.props.productTableData];

        let newData = locArr.map((obj, index) => {
            if (index == rowId)
                return Object.assign({}, obj, {
                    serial: value,
                    serialNum: value,
                    serialNumBack: value,
                    tErrorSerial: error,
                });
            return obj;
        });

        //this.setState({ prodDataArray: newData });
        this.props.updateProductTable(newData);
    };

    handleProdSerialOnBlur = (rowId, value) => {
        let locArr = [...this.props.productTableData];
        let result = false;

        let objVal =
            value === undefined ? this.state.modelAddProducts.serialNum : value;

        if (value !== undefined) {
            result = this.checkExistingSerialFromList(locArr, value, rowId);
        }

        return result;
    };

    handleEdit = (rowId, toggle) => {
        //this.initEditTable()

        let locArr = [...this.props.productTableData];

        if (locArr[rowId.rowIndex].tErrorSerial == true) {
            return;
        }

        if (locArr[rowId.rowIndex].tErrorModel == true) {
            return;
        }

        if (locArr[rowId.rowIndex].tErrorDesc == true) {
            return;
        }

        if (locArr[rowId.rowIndex].tErrorBrand == true) {
            return;
        }

        let newData = locArr.map((obj, index) => {
            //if (obj.serialNumBack == '2345')
            if (index == rowId.rowIndex)
                return Object.assign({}, obj, { editOrDeleteTag: toggle });
            return obj;
        });

        this.props.updateProductTable(newData);
    };

    handleDelete = (rowId) => {
        const locArr = [...this.props.productTableData];
        //console.log({ prodData: locArr[rowId.rowIndex] });
        const remData = locArr[rowId.rowIndex];
        var assetId = locArr[rowId.rowIndex].assetId;
        locArr.splice(rowId.rowIndex, 1);
        //console.log({ remData})
        dataLayerPush(null, { ecommerce: null }) // clear last ecommerce data
        dataLayerPush('remove_item', {
            ecommerce: {
                item: {
                    item_id: remData?.rowId,
                    serial_number: remData?.serial,
                    item_category: 'remove_product',
                    MFG_Warranty: remData?.MFGWarranty,
                    brand: remData?.brandName,
                    prod_description: remData?.prodDescription,
                    model_num: remData?.modelNum
                }
            }
        })
        //this.setState({ prodDataArray: locArr });
        this.props.updateProductTable(locArr);
        if (this.props.handleDeleteAssets != undefined)
            this.props.handleDeleteAssets(this.props.assetPackageId, assetId);
        const bln = this.enableContinue();
        this.props.checkIfCanContinue(bln);

        //clearing the previous erorr message during deletion of already added product
        const serial = document.getElementById("serialNum").value;
        if (serial != "") {
            this.initState(true);
            this.state.showAddSerialError = false;
        }
    };

    handleInputChange = (e) => {
        const { id, value } = e.target;
        this.handleInput(id, value);
    };

    handleInput = (id, value) => {
        let errorsAddProducts = this.state.errorsAddProducts;
        let modelAddProducts = this.state.modelAddProducts;
        if (value.trim() === "") {
            errorsAddProducts[id] = true;
            if (id == "serialNum") {
                this.initState(true);
            }
        } else {
            errorsAddProducts[id] = false;
            modelAddProducts[id] = value;
        }

        if (id == "serialNum") {
            this.setState({ serialNum: value });
            this.props.updateOrderParty("serialNum", value, '', '', '', this.props.screen);
        }

        if (id == "modelNum") {
            this.setState({ modelNum: value });
            this.props.updateOrderParty("modelNum", value, '', '', '', this.props.screen);
        }

        this.setState(() => ({
            errorsAddProducts: errorsAddProducts,
            modelAddProducts: modelAddProducts,
            showProductNotFound: false,
        }));
    };

    handleProdDescDropDown = (e, type) => {
        let blnErr = false;

        if (e == "") {
            blnErr = true;
        } else {
            blnErr = false;
            this.setState((prevState) => ({
                modelAddProducts: {
                    ...prevState.modelAddProducts,
                    prodDescription: e,
                },
            }));
        }
        this.setState({ prodDescDropDown: e, showProductNotFound: false });
        this.props.updateOrderParty("prodDescDropDown", e, '', '', '', this.props.screen);
        this.setState((prevState) => ({
            errorsAddProducts: {
                ...prevState.errorsAddProducts,
                prodDescription: blnErr,
            },
        }));

        if (type === "Commercial") {
            this.setState({ isSizeDisabled: false });
            this.getSizeList(e);
        }
        //if (!this.state.isLennox) {
        //    let assetId = this.getAssetIdfromProductDescription(e);
        //    this.checkAvailablePlans(e.rowId, [assetId]);
        //}
    };

    handleMfgDropDown = (e) => {
        let blnErr = false;

        if (e == "") {
            blnErr = true;
        } else {
            blnErr = false;
            this.setState((prevState) => ({
                modelAddProducts: {
                    ...prevState.modelAddProducts,
                    MFGWarranty: e,
                },
            }));
        }

        this.setState({ mfgPartsDropDown: e, showProductNotFound: false });
        this.props.updateOrderParty("mfgPartsDropDown", e, '', '', '', this.props.screen);

        this.setState((prevState) => ({
            errorsAddProducts: {
                ...prevState.errorsAddProducts,
                MFGWarranty: blnErr,
            },
        }));
    };

    handleMfgLaborDropDown = (e) => {
        this.setState((prevState) => ({
            modelAddProducts: {
                ...prevState.modelAddProducts,
                MFGLabor: e,
            },
        }));

        this.setState({ mfgLaborDropDown: e });
    };

    handleSeriesDropDown = (e) => {
        let blnErr = false;

        if (e == "") {
            blnErr = true;
        } else {
            blnErr = false;
            this.setState((prevState) => ({
                modelAddProducts: {
                    ...prevState.modelAddProducts,
                    series: e,
                },
            }));
        }

        this.setState({ seriesDropDown: e });

        this.setState((prevState) => ({
            errorsAddProducts: {
                ...prevState.errorsAddProducts,
                series: blnErr,
            },
        }));
    };

    handleBrandNameDropDown = (e) => {
        let blnErr = false;

        if (e == "") {
            blnErr = true;
        } else {
            blnErr = false;
            this.setState((prevState) => ({
                modelAddProducts: {
                    ...prevState.modelAddProducts,
                    brandName: e,
                },
            }));
        }

        this.setState({ brandNameDropDown: e, showProductNotFound: false });
        this.props.updateOrderParty("brandNameDropDown", e, '', '', '', this.props.screen);

        this.setState((prevState) => ({
            errorsAddProducts: {
                ...prevState.errorsAddProducts,
                brandName: blnErr,
            },
        }));
        this.setState({ assetNameArr: "", prodDescDropDown: "" });
        this.setState({ mfgArr: "", mfgPartsDropDown: "" });
        this.getProductionDescription(e);
    };

    handleSizeDropDown = (e) => {
        let blnErr = false;

        if (e == "") {
            blnErr = true;
        } else {
            blnErr = false;
            this.setState((prevState) => ({
                modelAddProducts: {
                    ...prevState.modelAddProducts,
                    size: e,
                },
            }));
        }
        this.setState({ sizeDropDown: e });
        this.setState((prevState) => ({
            productData: {
                ...prevState.productData,
                size: e,
            },
        }));

        this.setState((prevState) => ({
            errorsAddProducts: {
                ...prevState.errorsAddProducts,
                size: blnErr,
            },
        }));
    };

    handleAddClick = async (e) => {
        const context = this.context;
        const { value } = e.target;

        if (this.isLennoxLayout() == false) this.setState({ addDisabled: true });
        else if (!this.state.shouldValidateSerial) return;

        this.checkError();
        const hasError = Object.values(this.state.errorsAddProducts).includes(true);

        if (hasError === false) {
            //required fields OK
            //check if carrier or icp
            let { brandList } = context?.dealerProfile?.brandList;
            brandList = brandList ?? [];

            const selectedBrandName = this.getDropdownDescription("brand", this.state.brandNameDropDown) ?? "";
            const validateBrand = brandList?.some(x => x.trim().toLowerCase() === selectedBrandName.trim().toLowerCase()) ?? false;

            //TRANE RULE
            if (selectedBrandName.toLowerCase() === "trane") {
                const ret = await this.validateTrane(
                    this.state.modelAddProducts.serialNum
                );

                let data = this.state.traneData;

                if (data.length === 0) {
                    //no value returned from API
                    this.addProducts(false);
                } else {
                    if (data[0].validUnit === "N") {
                        //with API return but not valid
                        this.addProducts(false);
                    } else if (data[0].validUnit === null) {
                        //API null == down
                        this.addProducts(false);
                    } else if (data[0].validUnit === "Y") {
                        //with API return and valid
                        this.addProducts(true);
                    }
                }

                // CARRIER and ICP RULE
            }
            else if (validateBrand)
            {
                const ret = await this.validateCarrier(this.state.modelAddProducts.serialNum, this.state.modelAddProducts.modelNum );

                let carrierData = this.state.carrierData;
                if (carrierData.length === 0) {
                    //API down
                    if (this.props.productTableData.length === 0) {
                        this.setState({ showModal: true });
                    } else {
                        this.addProducts(false);
                    }
                } else {
                    if (carrierData[0].validUnit === "N") {
                        //with API return but not valid
                        this.setState({ showProductNotFound: true });
                    } else if (carrierData[0].validUnit === null) {
                        //API returned null
                        if (carrierData[0].errorMsg === "ERROR") {
                            this.setState({ showProductNotFound: true });
                        } else {
                            if (this.props.productTableData.length === 0) {
                                this.setState({ showModal: true });
                            } else {
                                this.addProducts(false);
                            }
                        }
                    } else if (carrierData[0].validUnit === "Y") {
                        //with API return and valid
                        this.addProducts(true);
                    }
                }
            } else {
                this.addProducts(true);
            }

            if (this.isLennoxLayout() == true)
                this.setState({
                    addDisabled: true,
                    disableControls: true,
                    shouldValidateSerial: false,
                    isSizeDisabled: true,
                });
            else this.setState({ addDisabled: false, isSizeDisabled: true });
        } else {
            this.setState({ addDisabled: false });
        }
        if (this.props.handleAddClose && !hasError) this.props.onCancel();
    };

    addProducts = async (blnVerified) => {
        let tempArr = [...this.props.productTableData];
        let newRow = {};

        if (
            this.checkExistingSerialFromList(
                tempArr,
                this.state.modelAddProducts.serialNum,
                -1
            )
        ) {
            return;
        }

        if (tempArr.length === 0) {
            newRow.rowId = 0;
        } else {
            const selected = tempArr.map(function (obj) {
                return obj.rowId;
            });

            const maxRow = Math.max(...selected) + 1;

            newRow.rowId = maxRow;
        }

        newRow.serial = this.state.modelAddProducts.serialNum;
        newRow.serialNumBack = this.state.modelAddProducts.serialNum;
        newRow.brandName = this.getDropdownDescription(
            "brand",
            this.state.modelAddProducts.brandName
        );
        newRow.brandNameBack = this.getDropdownDescription(
            "brand",
            this.state.modelAddProducts.brandName
        );
        newRow.brandNameId = this.state.modelAddProducts.brandName;
        newRow.prodDescription = this.getDropdownDescription(
            "product",
            this.state.modelAddProducts.prodDescription
        );
        newRow.prodDescriptionBack = this.getDropdownDescription(
            "product",
            this.state.modelAddProducts.prodDescription
        );
        newRow.prodDescriptionId = this.state.modelAddProducts.prodDescription;
        newRow.assetId = this.getAssetIdfromProductDescription(
            this.state.modelAddProducts.prodDescription
        );
        newRow.size = this.state.modelAddProducts.size;
        newRow.sizeBack = this.state.modelAddProducts.size;
        newRow.modelNum = this.state.modelAddProducts.modelNum;
        newRow.MFGWarranty = this.getDropdownDescription(
            "mfg",
            this.state.modelAddProducts.MFGWarranty
        );
        newRow.MFGWarrantyBack = this.getDropdownDescription(
            "mfg",
            this.state.modelAddProducts.MFGWarranty
        );
        newRow.MFGWarrantyId = this.state.modelAddProducts.MFGWarranty;
        newRow.MFGLabor = this.state.modelAddProducts.MFGLabor;
        newRow.series = this.state.modelAddProducts.series;
        newRow.seriesBack = this.state.modelAddProducts.series;
        newRow.editOrDeleteTag = 0;
        newRow.editOrDelete = "";
        newRow.tErrorSerial = false;
        newRow.tErrorModel = false;
        newRow.tErrorDesc = false;
        newRow.tErrorMfg = false;
        newRow.isChecked = true;
        newRow.isVerified = this.state.isLennox
            ? !this.state.lennoxError
            : blnVerified;
        newRow.dummy = false;
        newRow.masterProgramId = this.state.masterProgramId;
        newRow.planName = "";
        newRow.sizeList = this.state.sizeList;
        newRow.seriesList = this.state.seriesArr;
        newRow.tErrorNoPlanExist = this.state.noPlanExist;
        newRow.contractNumber = this.state.contractId;
        newRow.accountId = this.getAccountId(newRow.brandNameId);
        newRow.isFromOEM = false;
        newRow.isOEMAddProdUpd = false;
        //newRow.tErrorPlanDetails = await this.validatePlan();

        tempArr.push(newRow);

        //this.setState({ prodDataArray: tempArr });

        this.props.updateProductTable(tempArr);
        this.initState(true);

        if (tempArr.length > 0) {
            this.props.checkIfCanContinue(true); //enable continue in OrderInfoContainer
        } else {
            this.props.checkIfCanContinue(false);
        }

        if (this.props.handleUpdateAssets != undefined) {
            this.props.handleUpdateAssets(
                this.props.assetPackageId,
                newRow.assetId,
                newRow.brandName,
                newRow.serial,
                newRow.brandNameId,
                newRow.modelNum,
                newRow.series
            );
        }
    };

    updateModal(value) {
        this.setState({ showModal: value });
    }

    initState(shouldClearSerial) {
        if (!shouldClearSerial) this.setState({ modelNum: "", showModal: false });
        else this.setState({ serialNum: "", modelNum: "", showModal: false });

        let err = { ...this.state.errorsAddProducts };
        err.serialNum = false;
        err.brandName = false;
        err.prodDescription = false;
        err.size = false;
        err.modelNum = false;
        err.MFGWarranty = false;
        err.series = false;
        this.setState({ errorsAddProducts: err });

        let mod = { ...this.state.modelAddProducts };
        if (shouldClearSerial) mod.serialNum = "";
        mod.brandName = "";
        mod.prodDescription = "";
        mod.size = "";
        mod.modelNum = "";
        mod.MFGWarrant = "";
        mod.MFGLabor = 0;
        mod.serialNumBack = "";
        mod.brandNameBack = "";
        this.setState({ modelAddProducts: mod });

        this.setState({
            clientBrandMappingArr: "",
            assetNameArr: "",
            mfgArr: "",
            masterProgramArr: "",
        }); 
        this.setState({
            brandNameDropDown: "",
            prodDescDropDown: "",
            mfgPartsDropDown: "",
            sizeDropDown: "",
            seriesDropDown: "",
        });

        this.loadBrands();
        this.loadAccounts();
    }

    checkError() {
        const err = this.state.errorsAddProducts;

        const serial = document.getElementById("serialNum").value;
        const model = document.getElementById("modelNum").value;
        const brand = this.state.brandNameDropDown;
        const product = this.state.prodDescDropDown;
        const mfg = this.state.mfgPartsDropDown;
        const series = this.state.seriesDropDown;
        const size = this.state.sizeDropDown;

        if (err.serialNum === true || serial.length === 0) {
            err.serialNum = true;
        }

        if (err.modelNum === true || model.length === 0) {
            err.modelNum = true;
        }

        if (err.brandName === true || brand.length === 0) {
            err.brandName = true;
        }

        if (err.prodDescription === true || product.length === 0) {
            err.prodDescription = true;
        }

        if (err.MFGWarranty == true || mfg.length === 0) {
            err.MFGWarranty = true;
        }
        if (
            this.isLennoxLayout() == true &&
            (err.series == true || series.length === 0)
        ) {
            err.series = true;
        }

        if (
            this.context.installationType == "Commercial" &&
            (err.size == true || size.length === 0)
        ) {
            err.size = true;
        }

        this.setState({ errorsAddProducts: err });
    }

    getDropdownDescription(name, id) {
        let arr = {};
        let ret = {};

        if (name === "brand") {
            arr = [...this.state.clientBrandMappingArr];
            ret = arr.find((obj) => obj.data == id);
            return ret.display;
        } else if (name === "product") {
            arr = [...this.state.allAssetNameArr];
            ret = arr.find((obj) => obj.data == id);
            return ret.display;
        } else if (name === "mfg") {
            arr = [...this.state.mfgArr];
            ret = arr.find((obj) => obj.data == id);
            return ret.display;
        } else if (name === "mfgLabor") {
            arr = [...this.state.mfgLaborArr];
            ret = arr.find((obj) => obj.data == id);
            return ret.display;
        }
    }

    getAssetIdfromProductDescription(id) {
        let arr = [...this.state.allAssetNameArr];
        let ret = arr.find((obj) => obj.data == id);
        return ret.id;
    }

    getAccountId = (brandId) => {
        const brand = this.state.clientBrandMappingArr.filter(
            (x) => x.data === brandId
        );
        return brand[0].id;
    };

    mapAssets = () => {
        let currProdData = [...this.props.productTableData];
        let assetRequestData = [];

        for (let i = 0; i < currProdData.length; i++) {
            let newAsset = {
                accountId: currProdData[i].accountId,
                assetId: currProdData[i].assetId,
                brandNameId: currProdData[i].brandNameId,
                brandName: currProdData[i].brandName,
                serialNumber: currProdData[i].serialNumBack,
                modelNumber: currProdData[i].modelNum,
                mfgLaborYears: currProdData[i].MFGWarranty,
                mfgPartsYears: 0,
            };
            assetRequestData.push(newAsset);
        }
        return assetRequestData;
    };

    getGroupedAssets = () => {
        let acctIds = [];
        let assetList = [];
        let currProdData = [...this.props.productTableData];
        let groupedAssetList = [];

        let p = this.mapAssets();
        // list down all the assets.
        for (let i = 0; i < currProdData.length; i++) {
            if (currProdData[i].isChecked) {
                let currentAsset = { assetId: 0, count: 0, accountId: 0 };

                currentAsset.assetId = currProdData[i].assetId;
                currentAsset.count = 1;
                currentAsset.accountId = currProdData[i].accountId;

                assetList.push(currentAsset);
            }
        }

        // get the unique account id's, then attach the assets.
        for (let i = 0; i < assetList.length; i++) {
            let idx = acctIds.indexOf(assetList[i].accountId);

            if (idx === -1) {
                acctIds.push(assetList[i].accountId);
            }
        }

        for (let i = 0; i < acctIds.length; i++) {
            let assetListByAcctId = assetList.filter(
                (x) => x.accountId === acctIds[i]
            );
            let groupedByAcctid = { accountId: 0, assets: [] };

            groupedByAcctid.accountId = acctIds[i];
            groupedByAcctid.assets = assetListByAcctId;

            groupedAssetList.push(groupedByAcctid);
        }

        return groupedAssetList;
    };
    updateEditBlank() {
        const data = [...this.props.productTableData];
        let newData = data.map((obj) => {
            if (obj.editOrDeleteTag !== 0)
                return Object.assign({}, obj, { editOrDeleteTag: 0, editOrDelete: "" });
            return obj;
        });

        //this.setState({ prodDataArray: newData });
        this.props.updateProductTable(newData);
    }

    validateProductTable() {
        //called as REF from OrderInfoContainer
        const data = [...this.props.productTableData];

        for (let i = 0; i < data.length; i++) {
            if (
                data[i].tErrorSerial === true ||
                data[i].tErrorDesc === true ||
                data[i].tErrorMfg === true ||
                data[i].tErrorModel === true ||
                ((data[i].tErrorNoPlanExist === true ||
                    data[i].tErrorPlanDetails === true)
                    && data[i].isChecked
                )
            ) {
                return true;
            }
        }

        //all data valid send to parent for udpate
        this.props.updateProductTable(data);

        this.updateEditBlank();
        return false;
    }

    createOfferLookupRequest = () => {
        let currProdData = [...this.props.productTableData];
        let context = this.context;

        if (currProdData[0].isFromOEM) {
            this.oemAddProdUpdate();
        }

        let filterAsset = currProdData.filter(function (obj) {
            return obj.isChecked;
        });

        var assets = filterAsset.map((asset) => {
            var series = asset.seriesList.find(
                (series) => series.display === asset.series
            );

            var size = asset.sizeList.find(
                (size) => size.display === asset.size
            );

            let assetInfo = {
                assetId: asset.assetId,
                brand: asset.brandName,
                brandId: asset.brandNameId,
                serialNumber: asset.serialNumBack,
                modelNumber: asset.modelNum,
                OEMlabor: isNaN(parseInt(asset.MFGLabor)) ? 0 : parseInt(asset.MFGLabor),
                OEMparts: isNaN(parseInt(asset.MFGWarranty)) ? 0 : parseInt(asset.MFGWarranty),
                seriesId: series != null ? series.id : null,
                sizeId: size != null ? parseInt(size.sizeId) : 0,
                sizeUnitType: size != null ? size.sizeType : 0,
            };

            return assetInfo;

        });

        var request = {
            installDate: this.isCanadianDealer() ? moment(context.installationDate, "DD-MM-YYYY").format("MM/DD/YYYY")
                : moment(context.installationDate).format("MM/DD/YYYY"),
            assets: assets,
            accountId: context.dealerProfile.accountId,
            installType: context.installationType, // Future Story
            dealerId: context.dealerProfile.dealerId,
            stateId: context.dealerProfile.dealerStateId,
            zip: context.zip,
        };

        return request;
    };

    getExistingOffer = (newRequest) => {
        let existingOfferInfo = this.props.getOffers();

        if (
            existingOfferInfo == null ||
            existingOfferInfo.offers == null ||
            existingOfferInfo.request == null ||
            existingOfferInfo.offers.length == 0
        )
            return null;

        let existingRequest = existingOfferInfo.request;

        if (existingRequest.zip !== newRequest.zip) return null;
        if (existingRequest.installType !== newRequest.installType) return null;
        if (existingRequest.stateId !== newRequest.stateId) return null;
        if (existingRequest.installDate !== newRequest.installDate) return null;

        if (existingRequest.assets.length !== newRequest.assets.length) return null;

        for (var i = 0; i < existingRequest.assets.length; i++) {
            var currentAsset = existingRequest.assets[i];
            let matchingAsset = newRequest.assets.find((asset) => {
                if (asset.assetId != currentAsset.assetId) return false;
                if (asset.brand != currentAsset.brand) return false;
                if (asset.brandId != currentAsset.brandId) return false;
                if (asset.serialNumber != currentAsset.serialNumber) return false;
                if (asset.OEMlabor != currentAsset.OEMlabor) return false;
                if (asset.OEMparts != currentAsset.OEMparts) return false;
                if (asset.seriesId != currentAsset.seriesId) return false;
                if (asset.sizeId != currentAsset.sizeId) return false;

                return true;
            });

            if (matchingAsset == undefined) return null;
        }

        return existingOfferInfo;
    };

    checkAvailablePlans = async () => {
        var offerLookupRequest = this.createOfferLookupRequest();
        let currProdData = [...this.props.productTableData];
        let offerIsCurrent = this.getExistingOffer(offerLookupRequest) != null;

        if (offerIsCurrent) return true;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(offerLookupRequest),
        };

        let res = await fetch("Pricing/GetOffers", requestOptions);
        this.props.handleGenericError(res.status);

        let resData = await res.json();

        if (
            resData != null &&
            resData.successful &&
            resData.assetsWithoutPackage.length === 0
        ) {
            this.props.setOffers(resData, offerLookupRequest);

            for (let x = 0; x < currProdData.length; x++) {
                currProdData[x].noPlanExist = false;
                currProdData[x].tErrorNoPlanExist = false;
            }

            this.props.updateProductTable(currProdData);
            return true;
        } else if (resData != null && !resData.successful) {
            if (
                Array.isArray(resData.assetsWithoutPackage) &&
                resData.assetsWithoutPackage.length > 0
            ) {
                let awp = [...resData.assetsWithoutPackage];

                for (let i = 0; i < awp.length; i++) {
                    for (let x = 0; x < currProdData.length; x++) {
                        if (
                            currProdData[x].modelNum === awp[i].modelNumber &&
                            currProdData[x].serialNumBack === awp[i].serialNumber &&
                            currProdData[x].tErrorNoPlanExist === false
                        ) {
                            currProdData[x].noPlanExist = true;
                            currProdData[x].tErrorNoPlanExist = true;
                        }
                    }
                }
            }
            this.props.updateProductTable(currProdData);
        } else {
            if (resData != null &&
                Array.isArray(resData.assetsWithoutPackage) &&
                resData.assetsWithoutPackage.length > 0
            ) {
                let awp = [...resData.assetsWithoutPackage];

                for (let i = 0; i < awp.length; i++) {
                    for (let x = 0; x < currProdData.length; x++) {
                        if (
                            currProdData[x].modelNum === awp[i].modelNumber &&
                            currProdData[x].serialNumBack === awp[i].serialNumber &&
                            currProdData[x].tErrorNoPlanExist === false
                        ) {
                            currProdData[x].noPlanExist = true;
                            currProdData[x].tErrorNoPlanExist = true;
                        }
                    }
                }
            }
            else {

                for (let x = 0; x < currProdData.length; x++) {
                    currProdData[x].noPlanExist = true;
                    currProdData[x].tErrorNoPlanExist = true;
                }
            }
            this.props.updateProductTable(currProdData);
        }

        return false;
    };

    checkExistingSerialFromList = (currentList, serialNum, rowId) => {
        let result = false;
        let serialEditMode = rowId > -1;

        if (currentList.length === 0 || serialNum === undefined) return result;

        let editMode = rowId > -1;
        const excludeFromSearch = ["na", "n/a", "unknown", "none"];

        serialNum = serialNum.toLowerCase().trim();

        if (excludeFromSearch.indexOf(serialNum) === -1) {
            let filteredList = currentList.filter((currRow) => {
                return (
                    (currRow.serialNumBack.toLowerCase().trim() === serialNum &&
                        rowId === -1) ||
                    (currRow.serialNumBack.toLowerCase().trim() === serialNum &&
                        currRow.rowId !== rowId)
                );
            });

            if (filteredList.length > 0) {
                result = true;
            }
        }

        this.setState({
            showAddSerialError: result,
            serialEditMode: serialEditMode,
        });
        return result;
    };

    validatePlan = async () => {
        let assetIds = [];
        let assetId = this.getAssetIdfromProductDescription(
            this.state.modelAddProducts.prodDescription
        );
        assetIds.push(assetId);

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(assetIds),
        };

        const res = await fetch(
            "assetpackage/getassetpackagesbyassetid",
            requestOptions
        );
        let bln = "";
        if (res.status == 200) {
            bln = false;
        } else {
            bln = true;
        }
        this.setState({ tErrorPlanDetails: bln });
        this.props.checkIfCanContinue(!bln);
        return bln;
    };

    clearControlsOnInstallTypeChange() {
        this.initState(true);
        this.state.showAddSerialError = false;
    }

    isLennoxLayout = () => {
        if (this.state.isLennox) {
            if (this.props.fromPlanDetails == true) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    getDropdownDescriptionId(name, value) {
        let arr;
        let ret;

        if (name === "brand") {
            arr = [...this.state.clientBrandMappingArr];

            ret = arr.find((obj) => obj.display == value);
            return ret.data;

        } else if (name === "product") {
            arr = [...this.state.allAssetNameArr];

            ret = arr.find((obj) => obj.display == value);
            return ret.data;

        } else if (name === "mfg") {
            arr = [...this.state.mfgArr];

            ret = arr.find((obj) => obj.display == value);
            return ret.data;

        } else if (name === "mfgLabor") {
            arr = [...this.state.mfgLaborArr];

            ret = arr.find((obj) => obj.display == value);
            return ret.data;

        }
    }


    oemAddProdUpdate() {
        let tempArr = [...this.props.productTableData];
        for (var product of tempArr) {
            if (this.state.clientBrandMappingArr.length > 0 && product.brandName != "") {
                product.brandNameId = this.getDropdownDescriptionId(
                    "brand",
                    product.brandName
                );
            }

            product.prodDescriptionId = "";
            if (this.state.allAssetNameArr.length > 0 && product.prodDescription != "") {
                product.prodDescriptionId = this.getDropdownDescriptionId(
                    "product",
                    product.prodDescription
                );
            }
            if (this.state.allAssetNameArr.length > 0 && product.prodDescriptionId != "") {
                product.assetId = this.getAssetIdfromProductDescription(
                    product.prodDescriptionId
                );
            }

            if (this.state.mfgArr.length > 0 && product.MFGWarranty != "") {
                product.MFGWarrantyId = this.getDropdownDescriptionId(
                    "mfg",
                    product.MFGWarranty
                );
            }

            product.series = this.state.modelAddProducts.series;
            product.seriesBack = this.state.modelAddProducts.series;
            product.masterProgramId = this.state.masterProgramId;
            product.sizeList = this.state.sizeList;
            product.seriesList = this.state.seriesArr;
            product.tErrorNoPlanExist = this.state.noPlanExist;
            product.contractNumber = this.state.contractId;
            product.accountId = this.getAccountId(product.brandNameId);
            product.isOEMAddProdUpd = true;
        }
        this.props.updateProductTable(tempArr);
    }

    loadSavedSaleDescriptions = () => {
        let { productTableData } = this.props;

        let newProdData = productTableData.map(x => {
            return {
                ...x,
                seriesList: this.state.seriesArr,
                sizeList: this.state.sizeList,
            }
        });

        this.props.updateProductTable(newProdData);
        this.props.updateSavedSaleTab();
    }


    render() {
        const { onCancel } = this.props;
        const toChild = [...this.props.productTableData];
        const { isLennox, gridDisplay, serialCheck } = this.state;
        const mfgLaborLabel = (this.context.dealerProfile.country === "Canada") ? "MFG Labour Warranty" : "MFG Labor Warranty";
        const mfgLaborError = (this.context.dealerProfile.country === "Canada") ? "labour" : "labor";

        const { isSavedSale, clientBrandMappingArr, allAssetNameArr } = this.state;

        if (isSavedSale) {
            if ((Object.keys(clientBrandMappingArr).length !== 0) && (Object.keys(allAssetNameArr).length !== 0)) {
                this.setState({ isSavedSale: false });
                this.loadSavedSaleDescriptions();
            }
        }


        let brandOptions;
        //let descriptionList = [...this.state.descriptionList];
        if (this.state.clientBrandMappingArr == undefined) {
            brandOptions = "";
        } else {
            brandOptions = [...this.state.clientBrandMappingArr];
        }
        let productOptions;
        if (this.state.assetNameArr == undefined) {
            productOptions = "";
        } else {
            productOptions = [...this.state.assetNameArr];
            //productOptions = [...this.state.allAssetNameArr];
        }

        let mfgOptions;
        if (this.state.mfgArr == undefined) {
            mfgOptions = "";
        } else {
            mfgOptions = [...this.state.mfgArr];
            mfgOptions = mfgOptions.map((val) => {
                val.display = `${val.data} year${val.data == "1" ? "" : "s"}`;
                return val;
            });
        }

        let mfgLaborOptions;
        if (this.state.mfgLaborArr == undefined) {
            mfgLaborOptions = "";
        } else {
            mfgLaborOptions = [...this.state.mfgLaborArr];
            mfgLaborOptions = mfgLaborOptions.map((val) => {
                val.display = `${val.data} year${val.data == "1" ? "" : "s"}`;
                return val;
            });
        }

        const selected = toChild.map(function (obj, index) {
            if (obj.isChecked === true) {
                return obj.rowId;
            }
        });
        let seriesOptions = [...this.state.seriesArr];

        return (
            <div>
                {this.state.showModal && (
                    <VerifyProductError
                        showVerifyModal="true"
                        addProducts={this.addProducts}
                        updateModal={this.updateModal}
                    />
                )}
                <h2 className={"h5 subHead"}>Add Products</h2>
                {this.isLennoxLayout() == true && (
                    <Row className="multiColWrapper">
                        <Col md="4" className="col-5ths" sm="6">
                            <FormGroup>
                                <label for="serialNum" className="form-label">
                                    Serial
                                </label>
                                <span aria-hidden="true" className="required-indicator">
                                    *
                                </span>
                                <span className={classNames(serialinfoIcon, infoMobile)}>
                                    <SerialSearchInfoSVG className={styles.serialinfoIcon} />
                                    <span className={styles.seriailInfoTooltiptext}>
                                        Enter a serial number and click the search icon to
                                        validate. Product data will populate if the serial number
                                        is found, if not found, please enter manually.
                                    </span>
                                </span>
                                <div className={styles.searchWrapper}>
                                    <Input
                                        aria-labelledby="serialNumber"
                                        className="form-control"
                                        type="text"
                                        id="serialNum"
                                        value={this.props.serialNum}
                                        onChange={this.handleInputChange}
                                        onKeyUp={(e) => this.handleEnter(e)}
                                    />
                                    <div
                                        className={styles.searchIcon}
                                        onClick={this.validateLennox}
                                    >
                                        {this.state.shouldValidateSerial &&
                                            !this.state.lennoxError &&
                                            this.state.serialNum ? (
                                            <div
                                                className={classNames(greenCheckHeight, greenCheck)}
                                            ></div>
                                        ) : (
                                            <SearchSVG />
                                        )}
                                        {/* <img className={styles.serialCheckIcon} src={require("../../../assets/images/Check_icon.svg")} /> */}
                                        {/* Checkbox symbol to show verified. */}
                                    </div>
                                </div>
                                {this.state.shouldValidateSerial &&
                                    this.state.lennoxError === true && (
                                        <div class="errorMsg">
                                            Not found, please enter product information.
                                        </div>
                                    )}
                                {this.state.shouldValidateSerial &&
                                    this.state.lennoxError === false &&
                                    this.state.serialNum.length > 0 && (
                                        <div className={styles.verifyMsg}>Verified</div>
                                    )}
                            </FormGroup>
                        </Col>
                        <div className={classNames(serialinfoIcon, infoMedDiv)}>
                            <SerialSearchInfoSVG className={styles.serialinfoIcon} />
                            <span className={styles.seriailInfoTooltiptext}>
                                Enter a serial number and click the search icon to validate.
                                Product data will populate if the serial number is found, if not
                                found, please enter manually.
                            </span>
                        </div>
                    </Row>
                )}
                <Row className="multiColWrapper">
                    {this.isLennoxLayout() == false && (
                        <Col
                            xl="2"
                            lg="3"
                            sm="6"
                            className="col-md-4 col-5ths"
                        >
                            <FormGroup
                                className={
                                    this.state.errorsAddProducts.serialNum ? "form-error" : ""
                                }
                            >
                                <label for="serialNum" className="form-label">
                                    Serial
                                </label>
                                <span aria-hidden="true" className="required-indicator">
                                    *
                                </span>
                                <Input
                                    aria-labelledby="serialNumber"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    id="serialNum"
                                    defaultValue=""
                                    value={this.props.serialNum}
                                    onChange={this.handleInputChange}
                                    disabled={this.state.disableControls}
                                    onBlur={this.handleProdSerialOnBlur}
                                />
                                {this.state.errorsAddProducts.serialNum === true && (
                                    <div className="errorMsg">Serial number is required.</div>
                                )}
                            </FormGroup>
                        </Col>
                    )}
                    <Col
                        xl="2"
                        lg="3"
                        className="col-md-4 col-5ths"
                        sm="6"
                    >
                        <FormGroup
                            className={
                                this.state.errorsAddProducts.brandName ? "form-error" : ""
                            }
                        >
                            <label for="brandName" className="form-label">
                                Brand
                            </label>
                            <span aria-hidden="true" className="required-indicator">
                                *
                            </span>
                            <DropDownAutoComplete
                                name={"brand"}
                                id="brandNameDropDown"
                                options={brandOptions} //"hello"
                                onChangedValue={this.handleBrandNameDropDown}
                                value={this.state.brandNameDropDown}
                                defaultValue={this.state.brandNameDropDown}
                                disabled={this.state.disableControls && this.state.disableBrand}
                            />
                            {this.state.errorsAddProducts.brandName === true && (
                                <div className="errorMsg">Brand is required.</div>
                            )}
                        </FormGroup>
                    </Col>
                    <Col
                        xl="2"
                        lg="3"
                        className="col-md-4 col-5ths"
                        sm="6"
                    >
                        <FormGroup
                            className={
                                this.state.errorsAddProducts.prodDescription ? "form-error" : ""
                            }
                        >
                            <label for="prodDescription" className="form-label">
                                Description
                            </label>
                            <span aria-hidden="true" className="required-indicator">
                                *
                            </span>
                            <DropDownAutoComplete
                                name={"prodDescription"}
                                id={"prodDescDropDown"}
                                options={productOptions}
                                onChangedValue={(item) => {
                                    this.handleProdDescDropDown(
                                        item,
                                        this.context.installationType
                                    );
                                }}
                                value={this.props.prodDescDropDown}
                                defaultValue={this.props.prodDescDropDown}
                                disabled={this.state.disableControls && this.state.disableDescription}
                            /*onChangedValue={(item) => {
                                        this.setState({ isSizeDisabled: false });
                                        this.getSizeList(item);
                                    }} */
                            />
                            {this.state.errorsAddProducts.prodDescription === true && (
                                <div className="errorMsg">Description is required.</div>
                            )}
                        </FormGroup>
                    </Col>
                    {this.context.installationType === "Commercial" && (
                        <Col className="col-md-4 col-5ths" sm="6" xl="2" lg="3">
                            <FormGroup
                                className={
                                    this.state.errorsAddProducts.size ? "form-error" : ""
                                }
                            >
                                <label for="prodSize" className="form-label">
                                    Size
                                </label>
                                <span aria-hidden="true" className="required-indicator">
                                    *
                                </span>
                                <DropDownAutoComplete
                                    value={this.state.sizeDropDown}
                                    defaultValue={this.state.sizeDropDown}
                                    name={"prodSize"}
                                    options={this.state.sizeList}
                                    id="prodSize"
                                    disabled={this.state.isSizeDisabled}
                                    onChangedValue={(item, id) => {
                                        this.handleSizeDropDown(item);
                                    }}
                                />
                                {this.state.errorsAddProducts.size === true && (
                                    <div className="errorMsg">Size is required.</div>
                                )}
                            </FormGroup>
                        </Col>
                    )}
                    <Col
                        sm="6"
                        xl="2"
                        lg="3"
                        md="4"
                        className="col-md-4 col-5ths"
                    >
                        <FormGroup
                            className={
                                this.state.errorsAddProducts.modelNum ? "form-error" : ""
                            }
                        >
                            <label for="modelNum" className="form-label">
                                Model
                            </label>
                            <span aria-hidden="true" className="required-indicator">
                                *
                            </span>
                            <Input
                                aria-labelledby="modelNumber"
                                className="form-control"
                                type="text"
                                placeholder=""
                                id="modelNum"
                                value={this.props.modelNum}
                                onChange={this.handleInputChange}
                                disabled={this.state.disableControls && this.state.disableModel}
                            />
                            {this.state.errorsAddProducts.modelNum === true && (
                                <div className="errorMsg">Model number is required.</div>
                            )}
                        </FormGroup>
                    </Col>
                    <Col
                        xl="2"
                        lg="3"
                        sm="6"
                        className={`col-md-4 col-5ths ${!this.isLennoxLayout() ? " mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0" : ""
                            }`}
                    >
                        <FormGroup
                            className={
                                this.state.errorsAddProducts.MFGWarranty ? "form-error" : ""
                            }
                        >
                            <label for="MFGWarranty" className="form-label">
                                MFG Parts Warranty
                            </label>
                            <span aria-hidden="true" className="required-indicator">
                                *
                            </span>
                            <DropDownAutoComplete
                                name={"MFGWarranty"}
                                id={"MFGDropdown"}
                                options={mfgOptions} //"hello"mfgOptions
                                onChangedValue={this.handleMfgDropDown}
                                value={this.state.mfgPartsDropDown}
                                defaultValue={this.state.mfgPartsDropDown}
                                disabled={this.state.disableControls && this.state.disableParts}
                            />

                            {this.state.errorsAddProducts.MFGWarranty === true && (
                                <div className="errorMsg">MFG parts warranty is required.</div>
                            )}
                        </FormGroup>
                    </Col>
                    {this.isLennoxLayout() &&
                        <Col
                            xl="2"
                            lg="3"
                            className={`col-md-4 col-5ths ${(this.context.installationType === "Commercial") &&
                                " mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0"
                                }`}
                            sm="6"
                        >
                            <FormGroup
                                className={
                                    this.state.errorsAddProducts.brandName ? "form-error" : ""
                                }
                            >
                                <label for="mfgLabour" className="form-label">
                                    {mfgLaborLabel}
                                </label>
                                <span aria-hidden="true" className="required-indicator">
                                    *
                                </span>
                                <DropDownAutoComplete
                                    name={"mfgLabor"}
                                    id="mfgLaborDropDown"
                                    options={mfgLaborOptions}
                                    value={this.state.mfgLaborDropDown}
                                    defaultValue={this.state.mfgLaborDropDown}
                                    disabled={this.state.disableMFGControls}
                                />
                                {this.state.errorsAddProducts.brandName === true && (
                                    <div className="errorMsg">{`MFG ${mfgLaborError} warranty is required.`}</div>
                                )}
                            </FormGroup>
                        </Col>}
                    {this.isLennoxLayout() == true && (
                        <Col
                            xl="2"
                            lg="3"
                            sm="6"
                            className="col-md-4 col-5ths mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0"
                        >
                            <FormGroup
                                className={
                                    this.state.errorsAddProducts.series ? "form-error" : ""
                                }
                            >
                                <label for="Series" className="form-label">
                                    Series
                                </label>
                                <span aria-hidden="true" className="required-indicator">
                                    *
                                </span>
                                <DropDownAutoComplete
                                    name={"Series"}
                                    id={"SeriesDropdown"}
                                    options={seriesOptions} //"hello"mfgOptions
                                    onChangedValue={this.handleSeriesDropDown}
                                    value={this.state.seriesDropDown}
                                    disabled={this.state.disableControls && this.state.disableSeries}
                                />
                                {this.state.errorsAddProducts.series === true && (
                                    <div className="errorMsg">Series is required.</div>
                                )}
                            </FormGroup>
                        </Col>
                    )}
                </Row>
                <div className="align-items-end d-flex justify-content-between">
                    {/*
                    {!onCancel && <div className="validationSummary">We do not recognize this product or it may not be eligibile for coverage. Please verify the information is correct.</div>} 
                    {onCancel && <div className="validationSummary mb-0">We do not recognize this product or it may not be eligibile for coverage. Please verify the information is correct.</div>}
                    {!onCancel && <div className="validationSummary mb-0"><span>&nbsp;</span></div>}
                    */}
                    <div className="d-flex align-items-flex-start">
                        {this.state.showProductNotFound === true ? (
                            <div className="validationSummary">
                                We do not recognize this product or it may not be eligible for
                                coverage. Please verify the information is correct.
                            </div>
                        ) : (
                            <div className="validationSummary mb-0">
                                <span>&nbsp;</span>
                            </div>
                        )}
                        {this.state.showAddSerialError &&
                            !this.state.serialEditMode &&
                            !this.state.showProductNotFound ? (
                            <div className="validationSummary d-flex align-self-left">
                                This serial number has already been added to the order.
                            </div>
                        ) : (
                            <div className="validationSummary mb-0">
                                <span>&nbsp;</span>
                            </div>
                        )}
                    </div>
                    <BottomButtons
                        rightButtons={[
                            onCancel && (
                                <button className="btnLink" onClick={onCancel}>
                                    Cancel
                                </button>
                            ),
                            <button
                                onClick={this.handleAddClick}
                                className={classNames(
                                    "btn-secondary iconBtn",
                                    onCancel ? addBtnNew : addBtn
                                )}
                                aria-labelledby="Add Product"
                                // disabled={(!this.state.shouldValidateSerial ?!this.state.addDisabled : this.state.addDisabled) }
                                disabled={this.props.fromPlanDetails == true ? false : this.state.addDisabled}
                            >
                                <span className={""}>
                                    <AddIcon style={{ height: "11px", width: "11px" }} />
                                </span>
                                <span>Add</span>
                            </button>,
                        ]}
                    />
                </div>
                <div>
                    {gridDisplay && this.props.productTableData.length > 0 && (
                        <ProductTable
                            prodDataArray={toChild}
                            brandOptions={brandOptions}
                            productOptions={productOptions}
                            mfgOptions={mfgOptions}
                            assetNameArrProps={this.state.assetNameArrProps}
                            allAssetNameArr={this.state.allAssetNameArr}
                            allOemArr={this.state.partsOptions}
                            getProductionDescriptionFromProd={
                                this.getProductionDescriptionFromProd
                            }
                            masterProgramOptions={this.state.masterProgramArr}
                            handleDelete={this.handleDelete}
                            handleEdit={this.handleEdit}
                            handleProdModelInputChange={this.handleProdModelInputChange}
                            handleProdSerialInputChange={this.handleProdSerialInputChange}
                            handleProdCheckChange={this.handleProdCheckChange}
                            handleProdBrandNameDropDown={this.handleProdBrandNameDropDown}
                            handleProdSizeDropDown={this.handleProdSizeDropDown}
                            handleProdProdDescDropDown={this.handleProdProdDescDropDown}
                            handleProdMfgDropDown={this.handleProdMfgDropDown}
                            handleProdSerialOnBlur={this.handleProdSerialOnBlur}
                            handleProdSeriesDropDown={this.handleProdSeriesDropDown}
                            checkIfCanContinue={this.props.checkIfCanContinue}
                            refreshProductTable={this.refreshProductTable}
                            productTableData={this.props.productTableData}
                            selected={selected}
                            installationType={this.context.installationType}
                            isLennox={isLennox}
                            allowEdit={this.state.allowEdit}
                            enableEdit={this.enableEdit}
                            assetUpdated={this.props.assetUpdated}
                        />
                    )}
                </div>
            </div>
        );
    }
}
AddProductInputs.contextType = OrderInfoContext;
