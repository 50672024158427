import React, { Component, Fragment } from "react";
import { ProductTable } from "./ProductTable";
import { AddProductInputs } from "./AddProductInputs";
import { Card, CardBody } from "reactstrap";

export class AddProducts extends Component {

    constructor(props) {
        super(props);

        this.state = { unknownError: false }
    }

    validateProductTable() {
        try {
            const ret = this.refs.addProductInputs.validateProductTable();
            return ret;
        } catch (e) {
            this.handleGenericError(e.status);
        }
    }

    handleGenericError = (responseText) => {
        let validResponse = ['100', '200', '300'];
        this.setState({ unknownError: false });

        if (validResponse.includes(responseText.toString()) === false)
            this.setState({ unknownError: true });
    }

    checkAvailablePlans = () => {
        return this.refs.addProductInputs.checkAvailablePlans();
    }

    clearControlsOnInstallTypeChange() {
        return this.refs.addProductInputs.clearControlsOnInstallTypeChange();
    }

    loadDescriptionsFromSavedSale = (productTableData) => {
        if (Object.keys(productTableData).length !== 0) {
            this.refs.addProductInputs.state.isSavedSale = true;
        }
    }

    updateSavedSaleTab = () => {
        this.props.updateSavedSaleTab();
    }

    render() {

        return (
            <Card>
                <CardBody>
                    <AddProductInputs
                        checkIfCanContinue={this.props.checkIfCanContinue}
                        ref="addProductInputs"
                        allowEdit={this.props.allowEdit}
                        updateProductTable={this.props.updateProductTable}
                        productTableData={this.props.productTableData}
                        handleGenericError={this.handleGenericError}
                        setOffers={this.props.setOffers}
                        getOffers={this.props.getOffers}
                        updateSavedSaleTab={this.updateSavedSaleTab}
                        assetUpdated={this.props.assetUpdated}
                        updateOrderParty={this.props.updateOrderParty}
                        serialNum={this.props.serialNum}
                        modelNum={this.props.modelNum}
                        prodDescDropDown={this.props.prodDescDropDown}
                    />

                    {/*<ProductTable />*/}
                    <div className="scriptBox">
                        <div className="msg">Must be an Air-conditioning, Heating, and Refrigeration Institute (AHR) certified matching system in order for the contract to be valid. Equipment that does not meet these specifications may result in contract cancellation and claim denial.</div>
                    </div>

                    {this.state.unknownError && (
                        <div className="errorMsg d-flex mt-3">
                            <div>
                                Our system cannot process your request at this time. Please try again or
                                <button className="btnLink d-inline mx-1"><u>save your order</u></button>
                                for later.
                            </div>
                        </div>
                    )
                    }
                </CardBody>
            </Card>
        );
    }
}