import React, { useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next'
import {
    DeleteRowButton,
    QtyInput,
    SelectCoverage,
    UnitPriceInput,
    CaptionComponent,
    widthFormatter,
    amountHeaderFormatter,
    AuthInput,
    RateType,
    HeaderFormatter,
    AddRowButton
} from '../util/FormatterComponents'
import styles from '../Adjudication.module.css'
import { newEntry, currencyFormat } from '../util/util'
import { AdjudicationContext } from '../util/store/context';


const LaborTable = (props) => {
    const { state, dispatch } = useContext(AdjudicationContext)
    const { labor, preapprovedAmount, totals } = state;

    const cols = [
        {
            text: '',
            dataField: '',
            isDummyColumn: true,
            style: widthFormatter('deleteCol'),
            headerStyle: widthFormatter('deleteCol'),
            formatter: (col, row, rowIndex) => <DeleteRowButton rowId={row.id} rowIndex={rowIndex} table='labor' />,
            footer: <></>,
            footerClasses: [styles.footer, styles.noborder]
        },
        {
            dataField: 'rateType',
            text: 'Rate Type',
            style: widthFormatter('rateTypeCol'),
            headerStyle: widthFormatter('rateTypeCol'),
            classes: styles.containsInput,
            headerFormatter: (col) => HeaderFormatter({ col }),
            formatter: (_, row, rowIndex) => <RateType row={row} rowIndex={rowIndex} noDropDownBorder={true}/>,
            footer: () => props?.eventTypeId == 6 ? "Buyout" : <AddRowButton table='labor' />,
            footerClasses: [styles.addRow, styles.noborder, styles.footer]
        },
        {
            dataField: 'qty',
            text: 'Qty',
            style: widthFormatter('qtyCol'),
            headerStyle: widthFormatter('qtyCol'),
            headerFormatter: (col) => HeaderFormatter({ col, centered: true }),
            formatter: (_, row, rowIndex) => <QtyInput row={row} rowIndex={rowIndex} table='labor' />,
            footer: <></>,
            footerClasses: styles.noborder,
            classes: [styles.containsInput]
        },
        {
            dataField: null,
            text: null,
            footer: <></>,
            footerClasses: styles.noborder
        },
        {
            dataField: 'unitPrice',
            text: 'Rate',
            style: widthFormatter('amountCol'),
            headerStyle: widthFormatter('amountCol'),
            headerFormatter: (col) => HeaderFormatter({ col, centered: true, toolTipRequired: true, toolTipMessage: "The rate is default to pre-authorized labor rate, but can be updated to request additional labor when applicable." }),
            align: 'right',
            footer: 'Labor Total',
            formatter: (col, row, rowIndex) => <UnitPriceInput row={row} rowIndex={rowIndex} table={'labor'} />,
            classes: styles.containsInput,
            footerClasses: styles.noborder
        },
        {
            dataField: null,
            isDummyColumn: true,
            text: 'Total',
            style: { ...widthFormatter('amountCol'), padding: '4px 16px' },
            headerStyle: { ...widthFormatter('amountCol'), padding: '4px 16px' },
            headerFormatter: (col) => HeaderFormatter({ col, centered: true }),
            align: 'right',
            formatter: (_, row) => currencyFormat(row.unitPrice * row.qty),
            footer: currencyFormat(totals.labor.requested),
        },
    ]
    return (!!labor &&
        <BootstrapTable
            bootstrap4
            condensed
            data={props?.eventTypeId == 6 ? [] : labor}
            columns={cols}
            keyField='id'
            caption={<CaptionComponent title="Labor" preapprovedAmt={preapprovedAmount} style={{ borderRadius: '4px 4px 0 0' }} />}
            classes={['mb-0', styles.padCells, styles.AdjudicationTable, styles.topTable, styles.removeBottomBorder]}
            footerClass={styles.tableFooter}
            wrapperClasses={[styles.AdjudicationTableContainer]}
        />
    )
}

export default LaborTable;