import React from 'react'
import { useState, useEffect } from "react";
import Cleave from "cleave.js/react";
import styles from "./Formatters.module.css";
import { calc } from "../Calculators";
import { useClaimInvoiceStore } from "../lib/state";
import { PartsMarkupModal } from '../../Modals/PartsMarkupModal/PartsMarkupModal';
import { useRef } from 'react';
import { useContext } from 'react';
import { SessionContext } from '../../../context/SessionContext';
import NumberFormat from 'react-number-format';
import { currencyInputFormatter } from './CurrencyInputFormatter';

export const PriceInput = ({ cell, row, rowIndex, fieldName, tableType }) => {
    const [value, setValue] = useState(cell || 0);
    const { tabDispatch, getCurrentTabAndSubtab, getProfile } = useContext(SessionContext);
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const cleaveRef = useRef();
    const { contractNumber } = getCurrentTabAndSubtab().tab;
    const currentInvoice = useClaimInvoiceStore((state) => state[invoiceId]);
    const [showError, setShowError] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const displayMarkupModal = row.showMarkupError && showModal;
    const [
        updateRow,
        updateTaxRow,
        updateLaborRow,
        updatePartsRow,
        updatePartsMarkupRow,
        updateRefrigerantRow,
        contract,
        getCurrentInvoice,
        taxError,
        setTaxError,
        resetTaxError
    ] = useClaimInvoiceStore((state) => [
        state.updateRow,
        state.updateTaxRow,
        state.updateLaborRow,
        state.updatePartsRow,
        state.updatePartsMarkupRow,
        state.updateRefrigerantRow,
        state.getContract,
        state.currentInvoice,
        state.getTaxError(),
        state.setTaxError,
        state.resetTaxError
    ]);

    const showMarkupModal = async (markupPrice, partPrice, total) => {
        const accountId = getProfile()?.accountId
        const model = {
            "accountId": accountId,
            "contractNumber": contractNumber,
            "markupPrice": markupPrice,
            "partPrice": partPrice
        }

        const response = await fetch("contract/MarkupCheck/", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        });

        if (response?.ok) {
            const result = await response.json();
            setShowError(result)
            setShowModal(result)
            updatePartsMarkupRow(invoiceId, tableType, rowIndex, {
                showMarkupError: result
            });
        }
    }


    const acceptChanges = async (rawValue) => {
        const num = Number(rawValue);

        if (cleaveRef && cleaveRef.current) {
            cleaveRef.current.parentElement.style.boxShadow = "none";
        }
       
        const currentContract = typeof contract === 'function' ? contract() : contract;

        const coverageType = currentContract?.riskProfile?.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 28)?.[0]?.value;
        const total = calc[tableType]({ ...row, [fieldName]: num }, coverageType);


        const currentInvoice = getCurrentInvoice(invoiceId);
        const currentTableData = currentInvoice[tableType]?.data;

        if (tableType === 'tax') {
            if (fieldName === 'total') {
                const currentRowName = row?.name;
                const currentRowInvoiceData = currentInvoice?.[currentRowName]?.data;
                const currentRowInvoiceDataTotal = currentRowInvoiceData?.reduce((acc, e) => acc = acc + parseFloat(e.total), 0) * 0.15;
                const checkValue = fieldName === 'total' ? num : total;

                if (checkValue > currentRowInvoiceDataTotal) {
                    setTaxError(taxError, {
                        name: row.name,
                        error: true
                    });
                    if (cleaveRef && cleaveRef.current && cleaveRef.current.element) {
                        cleaveRef.current.element.parentElement.style.boxShadow = "inset 0px 0px 1px 1px red";
                        cleaveRef.current.element.focus();
                    }
                    return;
                } else {
                    setTaxError(taxError, {
                        name: row.name,
                        error: false
                    });
                    updateTaxRow(invoiceId, tableType, rowIndex, { total, [fieldName]: parseFloat(num), });
                    return
                }
            } else if (fieldName === 'gst' || fieldName === 'pst' || fieldName === 'qst' || fieldName === 'hst') {
                const currentRowName = row?.name;
                const currentRowInvoiceData = currentInvoice?.[currentRowName]?.data;
                const currentRowInvoiceDataTotal = currentRowInvoiceData?.reduce((acc, e) => acc = acc + parseFloat(e.total), 0) * 0.15;
                const checkValue = fieldName === 'total' ? num : total;

                if (checkValue > currentRowInvoiceDataTotal) {
                    setTaxError(taxError, {
                        name: row.name,
                        error: true
                    });
                } else {
                    setTaxError(taxError, {
                        name: row.name,
                        error: false
                    });
                    updateTaxRow(invoiceId, tableType, rowIndex, { total, [fieldName]: parseFloat(num), });
                    return
                }
            }
            updateTaxRow(invoiceId, tableType, rowIndex, { [fieldName]: num, total });
            return
        }
        else if (tableType === "parts") {
            if (fieldName === 'total') {
                updatePartsRow(invoiceId, tableType, rowIndex, { [fieldName]: num });
                return
            }
            updatePartsRow(invoiceId, tableType, rowIndex, { [fieldName]: num, total });
            return
        } else if (tableType === "partsMarkup") {
            if (fieldName === 'total') {
                updatePartsMarkupRow(invoiceId, tableType, rowIndex, { [fieldName]: num });
                return
            }
            if (fieldName === "markup" || fieldName === "price") {
                const price = fieldName === 'price' ? num :row?.price;
                const markup = fieldName === 'markup' ? num : row?.markup;
                updatePartsMarkupRow(invoiceId, tableType, rowIndex, {
                    [fieldName]: fieldName === 'price' ? price : markup,
                    total: fieldName === 'price' ? price : markup ,
                    showMarkupError: showError
                });
                await showMarkupModal(markup, price)  
                return;
            }
            updatePartsMarkupRow(invoiceId, tableType, rowIndex, {
                [fieldName]: num,
                total,
                showMarkupError: false
            });
            return
        } else if (tableType === "labor") {
            if (fieldName === 'total') {
                updateLaborRow(invoiceId, tableType, rowIndex, { [fieldName]: num, total });
                return
            }
            updateLaborRow(invoiceId, tableType, rowIndex, { [fieldName]: num, total });
            return
        } else if (tableType === "refrigerant") {
            if (fieldName === 'price') {
                const {country, accountId} = getProfile();
                let maxPrice;
                const { refrigerantType } = row;
                if (refrigerantType?.selected) {
                    const { selected } = refrigerantType;
                    if (selected.length && (selected[0]?.value === "1" || selected[0]?.value === "3")) {//R410A & R-454B
                        maxPrice = country.toLowerCase() === 'canada' ? 25 : 30;
                    } else if (selected.length && selected[0]?.value === "2") { //R22
                        maxPrice = 40
                    }

                    //HVAC other than Lennox and GE
                    if (accountId !== 18 && accountId !== 201 && accountId !== 202) {
                        const currentContract = typeof contract === 'function' ? contract() : contract;
                        //For Time and Material max price should be 10
                        const claimReimbursement = currentContract?.riskProfile?.riskProfileConfigurationModel?.find(r => r.pricingAttributeId == 44)?.value;
                        maxPrice = claimReimbursement === "2" ? maxPrice : 10; //Other than 2 would be Time and Material
                    }
                    const showMaxPriceError = num > maxPrice ? true : false;
                    updateRefrigerantRow(invoiceId, tableType, rowIndex, {
                        [fieldName]: num,
                        showMaxPriceError: showMaxPriceError
                    });
                    return;
                }

                if (refrigerantType === 'Reclaim') {
                    const showMaxPriceError = num > 45 ? true : false;
                    updateRefrigerantRow(invoiceId, tableType, rowIndex, {
                        [fieldName]: num,
                        showMaxPriceError: showMaxPriceError
                    });
                    return;
                }
            }
            updateRefrigerantRow(invoiceId, tableType, rowIndex, { 
                [fieldName]: num, 
                total,
                showMaxPriceError: false 
            });
            return
        }
        else {
            updateRow(invoiceId, tableType, rowIndex, { [fieldName]: num, total });
        }
    };

    useEffect(() => {
        if (row.showMarkupError) {
            if (cleaveRef && cleaveRef.current && fieldName === 'markup') {
                cleaveRef.current.parentElement.style.boxShadow = "inset 0px 0px 1px 1px red";
            }
        } else {
            if (cleaveRef && cleaveRef.current) {
                cleaveRef.current.parentElement.style.boxShadow = "none";
            }
        }
    }, [row.showMarkupError])

    useEffect(() => {
        if (row.showMaxPriceError) {
            if (cleaveRef && cleaveRef.current && fieldName === 'price') {
                cleaveRef.current.parentElement.style.boxShadow = "inset 0px 0px 1px 1px red";
            }
        } else {
            if (cleaveRef && cleaveRef.current) {
                cleaveRef.current.parentElement.style.boxShadow = "none";
            }
        }
    }, [row.showMaxPriceError])

    return (
        <>
            <NumberFormat
                prefix='$'
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                className={styles.tableInput}
                key={[row.id, 'unit'].join('_')}
                value={Number(value).toFixed(2) || 0}
                onChange={e => setValue(Number(e.target.value.replace(/[^0-9.-]+/g, "")))}
                format={currencyInputFormatter}
                onBlur={() => acceptChanges(value)}
                style={{
                    border: 'none',
                    textAlign: 'right'

                }}
                getInputRef={cleaveRef}
            />
        </>
    );
};

