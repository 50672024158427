import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { ClaimApproved } from "./ClaimApproved";
import { ClaimRejected } from "./ClaimRejected";
import { SessionContext } from "../../context/SessionContext";
import { ConfirmationReview } from "./ConfirmationReview";

export const ClaimConfirmation = (props) => {
    const history = useHistory();
    const [claimData, setClaimData] = useState([]);
    const claimNumber = typeof props.claimResponse?.claimNumber == "undefined" || props.claimResponse?.claimNumber == null ? '' : props?.claimResponse?.claimNumber;
    const responseStatus = typeof props.claimResponse?.responseStatus == "undefined" || props.claimResponse?.responseStatus == null ? '' : props?.claimResponse?.responseStatus;
    const ruleMessage = typeof props.claimResponse?.ruleMessage == "undefined" || props.claimResponse?.ruleMessage == null ? '' : props?.claimResponse?.ruleMessage;
    const sessionContext = useContext(SessionContext);

    const { accountName } = sessionContext;

    return (
        <div>
            {responseStatus.toLowerCase() === 'approved' && 
                <ClaimApproved accountName={accountName} claimNumber={claimNumber}/>
            }
            {responseStatus.toLowerCase() === 'denied' &&
                <ClaimRejected accountName={accountName} claimNumber={claimNumber} ruleMessage={ruleMessage} />
            }
            {responseStatus.toLowerCase() === 'exception' &&
                <ConfirmationReview accountName={accountName} claimNumber={claimNumber} />
            }
        </div>
    );
}
